import { OK, GET, POST, ApiClient } from 'Api';

import { CrewInvitationsJSON } from './JSON/Community';

export interface CommunityAPI {
  sendCrewInvitation(recipientEmail: string): OK

  getCrewInvitations(): Promise<CrewInvitationsJSON>

  acceptCrewInvitation(fromUserId: string): OK

  declineCrewInvitation(fromUserId: string): OK
}

export function makeApi(client: ApiClient): CommunityAPI {
  return {
    sendCrewInvitation,
    getCrewInvitations,
    acceptCrewInvitation,
    declineCrewInvitation
  };

  function sendCrewInvitation(email: string) {
    return client.requestJSON(POST, '/invitations', { email });
  }

  function getCrewInvitations(): Promise<CrewInvitationsJSON> {
    return client.requestJSON(GET, '/invitations');
  }

  function acceptCrewInvitation(fromUserId: string): OK {
    return client.request(POST, `/connection_requests/accept/${fromUserId}`)
  }

  function declineCrewInvitation(fromUserId: string): OK {
    return client.request(POST, `/connection_requests/deny/${fromUserId}`);
  }
}
