import * as React from 'react';

import { defaultConnect } from 'Shared/ReduxComponent';

import { challengesUrl } from 'Shared/Urls';

import { State, StoreDispatch } from 'Store';
import {
  NormalizableChallengeObject,
  ChallengeMetric,
  MetricType,
  normalizedData
} from 'Challenges/Data';
import { ActionCreators } from 'Challenges/ActionCreator';

import { HUD } from 'HUD';
import { NavBarLayout } from 'Nav';
import { UI as SpecsUI } from 'Specs';

export type Props = State & {
  dispatch: StoreDispatch,
  enrollAction: () => void,
  item: NormalizableChallengeObject | undefined
}

interface ComponentState {
  actionWasTaken: boolean
}

class PreJoinSetup extends React.Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = { actionWasTaken: false };
    this.afterActionTaken = this.afterActionTaken.bind(this);
  }

  componentDidMount() {
    if (this.props.item === undefined) {
      this.props.dispatch(ActionCreators.loadInvitations());
      this.props.dispatch(ActionCreators.loadChallenges());
    }
  }

  afterActionTaken() {
    this.setState({ actionWasTaken: true });
    this.props.enrollAction();
  }

  render() {
    const item = this.props.item;

    if (this.state.actionWasTaken || !item) {
      return <HUD state="loading"></HUD>;
    } else {
      const data = normalizedData(item);
      return (
        <NavBarLayout title="Challenges" initBreadcrumb={challengesUrl()}>
          <div className="challenge-unenrolled">
            <img
              className="challenge-unenrolled__graphic"
              src={data.graphicUrl}
            />

            <h3>{data.name}</h3>

            {renderSetupForm(this.afterActionTaken, data.metric)}
          </div>
        </NavBarLayout>
      );
    }
  }
}

export default defaultConnect(PreJoinSetup);


function renderSetupForm(
  enrollAction: () => void,
  metric: ChallengeMetric
): React.ReactNode {
  switch(metric.type) {
    case MetricType.Spec:
      return (
        <div>
          <p className="challenge__action-message">
            Ok, great, please enter your current {metric.specType} to start:
          </p>
          <SpecsUI.ConnectedSpecForms
            show={[metric.specType]}
            afterSpecUpdate={enrollAction}
          >
          </SpecsUI.ConnectedSpecForms>
        </div>
      )
    case MetricType.Steps:
      return [];
    case MetricType.Habit:
      return [];
  }
}
