import * as M from './Maybe';
import { Maybe } from './Maybe';

export function lookup<T>(ary: T[], index: number): T | undefined {
  return ary[index];
}

export function head<T>(ary: T[]): T | undefined {
  return ary[0];
}

export function maybeHead<T>(ary: T[]): Maybe<T> {
  return M.fromNilable(ary[0]);
}

/**
 * Using R.find like so `return M.fromNilable(R.find(fn, ts));` won't work if
 * T is a type that includes undefined, so we have to actually implmenet find
 * here
 */
export function maybeFind<T>(fn: (t: T) => boolean, ts: T[]): Maybe<T> {
  for (var t of ts) {
    if (fn(t)) { return M.just(t); }
  }
  return M.nothing();
}
