import * as moment from 'moment-timezone';
import * as React from 'react';
import * as Urls from 'Shared/Urls';
import { RewardPlan } from 'Rewards/Data';
import ProgressBar from 'Rewards/UI/ProgressBar';
import Tile from './Tile';
import Icon from './Icon';

interface Props {
  rewardPlan: RewardPlan;
}

export default function renderRewardsTile(
  props: Props
): React.ReactElement<Props> {

  const plan = props.rewardPlan;
  return (
    <Tile title="rewards" linkUrl={Urls.rewardsUrl()}>
      <div className="dashboard-tile__left-content rewards-tile__left">
        <div className="dashboard-tile__icon">
          <Icon name="gift-card" />
        </div>
        <div className="rewards-tile__right">
          <span className="simple-dashboard-tile__title">
            {date(plan.startDate)} - {date(plan.endDate)}
          </span>
          <br/>
          <small className="rewards-tile__points">
            {plan.currentPoints} out of {plan.maxPoints} points
          </small>
          <ProgressBar rewardPlan={plan}/>
        </div>
      </div>
    </Tile>
  );
}

function date(m: moment.Moment): string {
  return m.format('MMM D, YYYY');
}
