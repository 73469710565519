import * as React from 'react';
import * as classnames from 'classnames';
import { bem } from 'css-util';
import * as Urls from 'Shared/Urls';
import Icon from 'Shared/UI/Icon';
import ProfPic from 'Shared/UI/ProfPic';
import Spinner from 'Shared/UI/Spinner';
import Link from 'Shared/UI/Link';
import { Biopost,
         Comment,
         BiopostId,
         hasPropsFrom,
         formatPostTime } from 'Post/Data';

import CommentThread from 'Comments/UI/CommentThread';
import CommentForm from 'Comments/UI/CommentForm';

interface Props {
  biopost: Biopost,
  showUser?: boolean,
  currentUserId: string,
  showScore: boolean,
  loadComments?: (postId: BiopostId) => void,
  comments?: Comment[] | undefined,
  postComment?: (
    postId: BiopostId, comment: string, onComplete?: () => void
  ) => void,
  giveProps?: (postId: BiopostId) => void,
  refreshDynamicPosts?: () => void
}

interface State {
  showComments: boolean
}

const CSS = bem('biopost');

class BiopostDiv extends React.Component<Props, State> {
  state = { showComments: false }

  toggleNotes = () => {
    if (this.props.loadComments && !this.props.biopost.dynamic) {
      if  (!this.state.showComments) {
        this.props.loadComments(this.props.biopost.id);
      }
      this.setState({ showComments: !this.state.showComments });
    }
  }

  canGiveProps(): boolean {
    return !!this.props.giveProps &&
           !!this.props.showUser &&
           !hasPropsFrom(this.props.currentUserId, this.props.biopost);
  }

  giveProps = () => {
    if (this.canGiveProps() && this.props.giveProps) {
      this.props.giveProps(this.props.biopost.id);
    }
  }

  postComment = (comment: string, onComplete?: () => void) => {
    if (this.props.postComment) {
      this.props.postComment(this.props.biopost.id, comment, onComplete);
    }
  }

  refreshDynamicPosts = () => {
    if (this.props.refreshDynamicPosts) {
      this.props.refreshDynamicPosts();
    }
  }

  render() {
    const {showScore, showUser } = this.props;

    const post = this.props.biopost;
    const section = CSS('section');
    return (
      <div className="biopost">
        <div className={section(['left'])}>
          {this.renderUserProfPic()}
          <span className={classWithModifier('biopost__icon', showUser)}>
            {this.renderPostIcon(post)}
          </span>
        </div>
        <div className={section(['main'])}>
          {this.renderUserName()}
          <div className="biopost__title">
            {post.title}
            {showUser && renderSource(post.source)}
          </div>
          <div className="biopost__time">
            {formatPostTime(post.time)}
            {showUser && renderScoreIncrement(
              post.scoreIncrement, showScore, showUser
            )}
            {!showUser && renderSource(post.source)}
          </div>
        </div>
        <div
          className={section(['props', this.showUserModifier()])}
          onClick={this.giveProps}
        >
          {!showUser && renderScoreIncrement(
            post.scoreIncrement, showScore, showUser
          )}
          {showUser && this.renderPropsButton()}
          Props {post.props.length}
        </div>
        <div
          className={section(['notes', this.showUserModifier()])}
          onClick={this.toggleNotes}
        >
          {renderNotesIcon(post)}
          Notes {post.commentCount}
        </div>
        {this.state.showComments && this.renderComments()}
      </div>
    );
  }
  renderUserName() {
    if (this.props.showUser) {
      return (
        <div className="biopost__user-name">
          <Link to={Urls.crewMemberDashboardUrl(this.props.biopost.userId)}>
            {this.props.biopost.userName}
          </Link>
        </div>
      );
    }
  }

  renderUserProfPic() {
    if (this.props.showUser) {
      return (
        <div className="biopost__prof-pic">
          <ProfPic userId={this.props.biopost.userId} variant="round"/>
        </div>
      );
    }
  }

  renderPropsButton() {
    const gaveProps =
      hasPropsFrom(this.props.currentUserId, this.props.biopost);
    const iconName = gaveProps ? 'thumbs' : 'thumbs-o';
    return (
      <div className="biopost__props-icon">
        <Icon name={iconName} />
      </div>
    );
  }

  renderComments() {
    const comments = this.props.comments;
    let content;
    if (comments === undefined) {
      content = <Spinner wrap="center"/>
    } else {
      content = [
        <CommentThread key="thread" comments={comments} />,
        this.props.postComment ?
        <CommentForm
          key="form"
          submit={this.postComment}/> :
        null
      ];
    }
    return (
      <div className="biopost__notes-thread">
        {content}
      </div>
    )
  }

  renderPostIcon(post: Biopost) {
    if (post.dynamic) {
      return (
        <Link to={this.refreshDynamicPosts}
          themed={true}
          className={CSS('dynamic-post-refresh')()}>
          <Icon name="refresh" />
        </Link>
      );
    } else {
      return <Icon name={post.icon} />;
    }
  }

  showUserModifier() {
    return this.props.showUser ? 'show-user' : 'no-show-user';
  }
}

export default BiopostDiv;

/**------------------------------------------------------------**/
/** export functions are also used by Dashboard/UI/PostTile.tsx **/

export function renderSource(source: string | undefined) {
  if (source) {
    return (
      <span className="biopost__source"> via {formatSource(source)}</span>
    );
  }
}

export function renderScoreIncrement(
  scoreIncrement: number | undefined,
  showScore: boolean,
  showUser: boolean | undefined
) {
  const increment = scoreIncrement === undefined ? 0 : scoreIncrement;
  const signModifier = incrementSignModifier(increment);
  const classes = classnames(
    classWithModifier('biopost__score-increment', showUser),
    `biopost__score-increment--${signModifier}`,
    { 'biopost__score-increment--hide': !showScore }
  );
  const formattedIncrement =
    showScore ? formatIncrement(increment) : '--';
  return (
    <div className={classes}>
      {formattedIncrement}
    </div>
  );
}

export function renderNotesIcon(post: Biopost) {
  const iconName = post.commentCount > 0 ? 'bubble' : 'bubble-o';
  return (
    <div className="biopost__notes-icon">
      <Icon name={iconName} />
    </div>
  );
}

function classWithModifier(
  className: string, showUser: boolean | undefined
): string {
  const modifier = showUser ? 'show-user' : 'no-show-user';
  return [className, `${className}--${modifier}`].join(' ');
}


function incrementSignModifier(increment: number): string {
  if (increment < 0) {
    return 'negative';
  } else if (increment > 0) {
    return 'positive';
  } else {
    return 'neutral';
  }
}

function formatIncrement(increment: number): string {
  if (increment === 0) {
    return '--';
  } else if (increment > 0) {
    return `+${increment}`;
  } else {
    return increment.toString();
  }
}

function formatSource(source: string) {
  return source.replace(/_/g, ' ');
}
