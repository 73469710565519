import './Icon.scss';
import * as React from 'react';

interface Props {
  name: IconName,
  className?: string
}

export type IconName
= 'arrow-circle-up'
| 'bubble'
| 'bubble-o'
| 'caret-down'
| 'caret-left'
| 'caret-right'
| 'check-circle-o'
| 'email4'
| 'exclamation-triangle'
| 'gears'
| 'info-circle'
| 'link'
| 'lock'
| 'pencil-square'
| 'pencil-square-o'
| 'print'
| 'question-circle'
| 'refresh'
| 'rocket'
| 'ruler'
| 'shield'
| 'star'
| 'star-o'
| 'thumbs'
| 'thumbs-o'
| 'unlock'
| 'user'
| 'users'
| 'wifi'
;

export default function renderIcon(
  props: Props
): React.ReactElement<Props> {
  return (
    <i className={`icon icon-${props.name} ${props.className || ''}`}></i>
  );
}
