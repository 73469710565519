import d3 from 'd3';

export default function biosTimeSeries(container, data, opts) {

  var xExtent = [opts.startDate, opts.endDate],
      scoreLabel = opts.scoreLabel;
  var dateFormat = d3.time.format('%a, %-m/%d'),
      incrementFormat = d3.format('+.2f'),
      scoreFormat = d3.format('.2f');

  // Build Graph
  var margin = {top: 20, right: 70, bottom: 30, left: 50},
      betweenPadding = 15,
      width = 1050 - margin.left - margin.right,
      height = 500 - margin.top - margin.bottom,
      scoresHeight = Math.floor(0.7 * height),
      postsHeight = height - scoresHeight - betweenPadding,
      postBarMaxCount = 9;

  var svg = d3.select(container).append("svg")
      .attr('viewBox',
            "0 0 " +
            (width + margin.left + margin.right) +
            " " +
            (height + margin.top + margin.bottom))
      .attr('preserveAspectRatio', 'xMinYMin meet')
    .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  // Defs
  svg.append('defs').append('filter')
    .attr('id', 'glow')
    .call(function(filter) {
      filter.append('feGaussianBlur')
        .attr('in', 'SourceGraphic')
        .attr('stdDeviation', 0.8);
    });

  function dayOfObject(obj) {
    return d3.time.day(obj.time);
  }

  function latestScore(scores) {
    return scores[0];
  }

  function biopostCountLabel(data) {
    var count = data.bioposts.length;
    return "posts: "+ count;
  }

  function scoreIncrement(data) {
    if (data.bioposts.length > 0) {
      var last = data.bioposts[0],
          first = data.bioposts[data.bioposts.length - 1],
          firstScore = first.old_score.value,
          lastScore = last.new_score.value;
      return lastScore - firstScore;
    } else {
      return 0;
    }
  }

  function formattedScoreIncrement(data) {
    return incrementFormat(scoreIncrement(data));
  }

  function scoreIncrementClass(data) {
    var dir = 'none',
        delta = scoreIncrement(data);
    if (delta > 0) {
      dir = 'up';
    } else if (delta < 0) {
      dir = 'down';
    } else {
      dir = 'none';
    }

    return "increment " + dir;
  }

  function hudBoxLeftEdge(d, xScale, padding, hudWidth, graphWidth) {
    var normal = xScale(d.time) + padding;
    if (normal + hudWidth > graphWidth) {
      return graphWidth - hudWidth;
    } else {
      return normal;
    }
  }

  function truncateText(text, length) {
    text = text.trim();
    var trunc = text.substr(0, length);
    if (trunc != text) {
      trunc = text.substr(0, length - 3) + '...';
    }
    return trunc;
  }

  function prepareData(rawData) {
    // Munge data
    var rawScores = data.scores.map(function(d) {
      d.time = new Date(Date.parse(d.time));
      return d;
    });
    var rawBioposts = data.bioposts.map(function(d) {
      d.time = new Date(Date.parse(d.timestamp));
      return d;
    });

    var scoreDataNest = d3.nest()
          .key(dayOfObject)
          .map(rawScores);

    var postDataNest = d3.nest()
          .key(dayOfObject)
          .map(rawBioposts);

    // xExtent = d3.extent(d3.keys(scoreDataNest).concat(d3.keys(postDataNest)), function(d) { return new Date(Date.parse(d)); });
    // xExtent[1] = d3.time.day.offset(xExtent[1], 1);

    var lastLatestScore;
    return d3.time.day.range(xExtent[0], xExtent[1]).reduce(function(ary, date) {
      var d = {
        time: date,
        scores: (scoreDataNest[date.toString()] || []),
        bioposts: (postDataNest[date.toString()] || [])
      };
      d.latestScore = latestScore(d.scores) || lastLatestScore;
      lastLatestScore = d.latestScore;
      ary.push(d);
      return ary;
    }, []);

  }





  data = prepareData(data);
  var dataByTime = data.reduce(function(obj, datum) {
    obj[datum.time] = datum;
    return obj;
  }, {});

  var postData = data.filter(function(d) { return d.bioposts.length > 0; }),
      scoreData = data.filter(function(d) { return d.scores.length > 0; });


  // Setup scales
  var xItemWidth = width / d3.time.day.range(xExtent[0], xExtent[1]).length;

  var xScale = d3.time.scale()
        .domain(xExtent)
        .range([0, width]);

  var yScaleScores = d3.scale.linear()
        .domain([0, 100])
        .range([scoresHeight, 0]);

  var maxPostsInADay = d3.max(data, function(d) { return d.bioposts.length; });
  var yScalePosts = d3.scale.linear()
        .domain([-1, postBarMaxCount])
        .range([postsHeight, 0]);

  var hud,
      hudMargin = 10,
      hudPadding = { left: 10, top: 3 },
      postHudHeight = 97,
      scoreHudHeight = 60,
      hudWidth = 170,
      textLineHeight = 15;



  // Drawing Functions

  function drawAxes() {
    var xAxis = d3.svg.axis()
          .scale(xScale)
          .orient('bottom')
          .ticks(d3.time.months)
          .tickPadding(3)
          .tickSize(5, 0);

    var yAxisScores = d3.svg.axis()
          .scale(yScaleScores)
          .orient('left')
          .tickPadding(9)
          .tickSize(8,8)
          .ticks(5);

    var yAxisPosts = d3.svg.axis()
          .scale(yScalePosts)
          .ticks(0)
          .orient('left');

    svg.append('g')
      .attr('transform', 'translate(0, '+height+')')
      .attr('class', 'x axis posts')
      .call(xAxis);
    xAxis.tickValues([]);
    svg.append('g')
      .attr('transform', 'translate(0, '+scoresHeight+')')
      .attr('class', 'x axis scores')
      .call(xAxis);
    svg.append('g')
      .attr('class', 'y axis scores')
      .call(yAxisScores);
    svg.append('g')
      .attr('transform', 'translate(0, '+(scoresHeight+betweenPadding)+')')
      .attr('class', 'y axis posts')
      .call(yAxisPosts);
  }

  function drawAxesLabels() {
    svg.append('text')
      .attr('class', 'axis-label')
      .attr('x', 5)
      .attr('y', -5)
      .text(scoreLabel);

    svg.append('text')
      .attr('class', 'axis-label')
      .attr('x', 5)
      .attr('y', scoresHeight + 13)
      .text('posts');
  }
  // Display score data
  function drawScoreLine() {
    var interpolationStyle = 'monotone';
    var interpolatedLine = d3.svg.line()
          .x(function(d) { return xScale(d.time); })
          .y(function(d) { return yScaleScores(d.latestScore.value); })
          .interpolate(interpolationStyle);
    var areaUnderLine = d3.svg.area()
          .x(function(d) { return xScale(d.time); })
          .y0(scoresHeight)
          .y1(function(d) { return yScaleScores(d.latestScore.value); })
          .interpolate(interpolationStyle);

    // Append area under line
    svg.append('path')
      .datum(scoreData)
      .attr('class', 'score-area')
      .attr('d', areaUnderLine);

    // Append interpolated line
    svg.append('path')
      .attr('d', interpolatedLine(scoreData))
      .attr('class', 'score-line');


  }

  function drawTodaysScore() {
    var todaysScoreData = scoreData[scoreData.length - 1],
        todaysScoreContainer = svg.append('g').attr('class', 'current-score');

    // Append today's score dotted line
    svg.append('line')
      .datum(todaysScoreData)
      .attr('x1', function(d) { return xScale(xExtent[0]); })
      .attr('x2', function(d) { return xScale(d.time); })
      .attr('y1', function(d) { return yScaleScores(d.latestScore.value); })
      .attr('y2', function(d) { return yScaleScores(d.latestScore.value); })
      .attr('class', 'score-current-line');

    // Append circle for today's score
    svg.append('circle')
      .datum(todaysScoreData)
      .attr('cx', function(d) { return xScale(d.time); })
      .attr('cy', function(d) { return yScaleScores(d.latestScore.value); })
      .attr('r', 5)
      .attr('class', 'score-current-dot');

    // Display current score text
    svg.append('text')
      .datum(todaysScoreData)
      .attr('x', function(d) { return xScale(d.time) - 5; })
      .attr('y', function(d) { return yScaleScores(d.latestScore.value) - 15; })
      .text(function(d) { return scoreFormat(d.latestScore.value); })
      .attr('class', 'score-current-text');

  }

  function drawBiopostBars() {
    // Display post data
    svg.append('g')
        .attr('transform', 'translate(0, '+(scoresHeight + betweenPadding)+')')
        .classed('posts', true)
      .selectAll('g')
        .data(postData)
      .enter().append('g')
        .attr('transform', function(d) { return 'translate('+xScale(d.time)+', 0)'; })
      .append('line')
        .attr('x1', 0)
        .attr('x2', 0)
        .attr('y1', yScalePosts(0))
        .attr('y2', function(d) { return yScalePosts(d3.min([d.bioposts.length, postBarMaxCount])); });

  }
  // Mouseover effects

  function mouseover(d) {
    if (!d.latestScore) {
      return;
    }
    var hud = svg.select('g.hud'),
        el = hud.selectAll('g').data([d], function(d) { return d.time; }),
        enter = el.enter().append('g'),
        hudLine = enter.append('line').attr('class', 'hud-line'),
        postHighlight = enter.append('g'),
        scoreHud = enter.append('g')
          .attr('transform', function(d) {
            return 'translate('+hudBoxLeftEdge(d, xScale, hudMargin, hudWidth, width) +', 0)';
          })
          .attr('class', 'score-hud'),
        postHud = enter.append('g')
          .attr('transform', function(d) {
            return 'translate('+hudBoxLeftEdge(d, xScale, hudMargin, hudWidth, width) +', '+(height - postHudHeight - 20)+')';
          })
          .attr('class', 'posts-hud');

    hudLine
      .attr('y1', 0)
      .attr('y2', height)
      .attr('x1', function(d) { return xScale(d.time); })
      .attr('x2', function(d) { return xScale(d.time); });

    scoreHud.append('rect')
      .attr('width', hudWidth)
      .attr('height', scoreHudHeight)
      .attr('stroke-width', 1)
      .attr('stroke', 'black')
      .attr('fill', 'white');
    scoreHud.append('rect')
      .attr('x', 118)
      .attr('y', hudPadding.top + textLineHeight + 5)
      .attr('width', 48)
      .attr('height', textLineHeight + 5)
      .attr('class', function(d) { return scoreIncrementClass(d); });


    scoreHud.append('rect')
      .attr('class', 'out-of-hud')
      .attr('x', 0)
      .attr('y', -20)
      .attr('width', hudWidth)
      .attr('height', 20);
    var text = scoreHud.append('text');
    text.append('tspan')
      .attr('class', 'out-of-hud')
      .attr('x', hudPadding.left)
      .attr('y', -5)
      .text(function(d) {
        var str = dateFormat(d.time);
        return str;
      });
    text.append('tspan')
      .attr('x', hudPadding.left)
      .attr('y', hudPadding.top + textLineHeight)
      .text(function(d) {
        return scoreLabel+":";
      });
    text.append('tspan')
      .attr('x', 160)
      .attr('y', hudPadding.top + textLineHeight)
      .attr('text-anchor', 'end')
      .text(function(d) { return scoreFormat(d.latestScore.value); });
    text.append('tspan')
      .attr('x', 160)
      .attr('dy', textLineHeight + 5)
      .attr('text-anchor', 'end')
      .text(function(d) { return formattedScoreIncrement(d); });

    // Highligh the selected line in the biopost graph
    postHighlight.append('line')
      .attr('x1', function(d) { return xScale(d.time); })
      .attr('x2', function(d) { return xScale(d.time); })
      .attr('y1', function(d) { return yScalePosts(0) + scoresHeight + betweenPadding; })
      .attr('y2', function(d) {
        var barCount = d3.min([d.bioposts.length, postBarMaxCount]);
        return yScalePosts(barCount) + scoresHeight + betweenPadding;
      })
      .attr('filter', 'url(#glow)')
      .attr('class', 'post-line-highlight');

    // Append post HUD
    postHud.append('rect')
      .attr('y', 0)
      .attr('x', 0)
      .attr('width', hudWidth)
      .attr('height', postHudHeight);

    text = postHud.append('text');
    text.append('tspan')
      .attr('x', hudPadding.left)
      .attr('y', hudPadding.top + textLineHeight)
      .attr('class', 'biopost-count')
      .text(function(d) {
        return biopostCountLabel(d);
      });
    text.selectAll('tspan.post')
        .data(function(d) { return d.bioposts.slice(0, 3); })
      .enter().append('tspan')
        .attr('class', 'post')
        .attr('x', hudPadding.left)
        .attr('dy', function(d, i) {
          if (i === 0) { return textLineHeight + 5; }
          else { return textLineHeight; }
        })
        .text(function(d) { return truncateText(d.title, 25); });
    text.append('tspan')
        .datum(function(d) { return d.bioposts; })
        .attr('x', hudPadding.left)
        .attr('dy', textLineHeight + 5)
        .text(function(posts) {
          if (posts.length > 3) {
            return "and "+(posts.length - 3)+" more";
          } else { return ''; }
        });

  }

  // Add mouseover buckets and setup handlers
  function addMouseover() {
    var drag = d3.behavior.drag(),
        previousDatum,
        clearAllHuds = function() {
          svg.select('g.hud').selectAll('g').remove();
        };
    var eventHandler = function(dataArray, xOffset) {
      var datum = dataByTime[d3.time.day(xScale.invert(xOffset))];

      if (datum === previousDatum) { return; }
      if (previousDatum) {
        clearAllHuds();
      }

      previousDatum = datum;
      mouseover(datum);
    };

    drag.on('drag', function(d) {
      eventHandler(d, d3.event.x);
    });
    drag.on('dragend', function() {
      clearAllHuds();
    });

    svg.append('g').attr('class', 'hud');
    svg.append('g')
        .datum(data)
        .call(drag)
      .selectAll('rect')
        .data(data)
      .enter().append('rect')
        .attr('stroke', 'none')
        .attr('fill-opacity', 0.0)
        .attr('x', function(d) { return xScale(d.time); })
        .attr('width', xItemWidth)
        .attr('height', height)
      .on('mouseover', mouseover)
      .on('mouseout', function(d) {
        svg.select('g.hud').selectAll('g').remove();
      });
  }


  drawScoreLine();
  drawTodaysScore();
  drawBiopostBars();
  drawAxes();
  drawAxesLabels();
  addMouseover();
}
