import {
  ApiUrl,
  OK,
  GET,
  POST,
  PUT,
  ApiClient
} from 'Api';

import {
  NewBiopostResponseJSON,
  SpecUpdateJSON,
  GetActivitiesJSON,
  ActivityFeedJSON,
  GetGeniusRecommendationsJSON,
  CommentsResponseJSON,
  DynamicPostsJSON
} from './JSON/Post';

export interface PostAPI {
  getActivities(): Promise<GetActivitiesJSON>,

  getActivitiesForOnboard(): Promise<GetActivitiesJSON>,

  getGeniusRecommendations(): Promise<GetGeniusRecommendationsJSON>,

  postActivity(activityId: string): Promise<NewBiopostResponseJSON>,

  postActivityWithNote(
    activityId: string, note: string | undefined
  ): Promise<NewBiopostResponseJSON>,

  postSpecUpdate(specUpdate: SpecUpdateJSON): Promise<NewBiopostResponseJSON>

  addFavoriteActivity(activityId: string): OK,

  removeFavoriteActivity(activityId: string): OK,

  getMyActivityFeed(page?: number): Promise<ActivityFeedJSON>

  getMyDynamicPosts(): Promise<DynamicPostsJSON>

  getCrewActivityFeed(page?: number): Promise<ActivityFeedJSON>

  getCrewDynamicPosts(): Promise<DynamicPostsJSON>

  getUserActivityFeed(userId: string, page?: number): Promise<ActivityFeedJSON>

  getUserDynamicPosts(userId: string): Promise<DynamicPostsJSON>

  getComments(biopostId: string): Promise<CommentsResponseJSON>

  postComment(biopostId: string, commentText: string): OK,

  giveProps(biopostId: string): OK
}

export function makeApi(client: ApiClient): PostAPI {
  return {
    getActivities,
    getActivitiesForOnboard,
    getGeniusRecommendations,
    postActivity,
    postActivityWithNote,
    postSpecUpdate,
    addFavoriteActivity,
    removeFavoriteActivity,

    getMyActivityFeed,
    getMyDynamicPosts,
    getCrewActivityFeed,
    getCrewDynamicPosts,
    getUserActivityFeed,
    getUserDynamicPosts,

    getComments,
    postComment,
    giveProps
  };

  function getActivities(): Promise<GetActivitiesJSON> {
    return client.requestJSON(GET, { version: 2, path: '/activities' });
  }

  function getActivitiesForOnboard(): Promise<GetActivitiesJSON> {
    return client.requestJSON(GET, '/onboard_activities');
  }

  function getGeniusRecommendations(): Promise<GetGeniusRecommendationsJSON> {
    return client.requestJSON(GET, '/genius/recommendations');
  }

  function postActivity(activityId: string): Promise<NewBiopostResponseJSON> {
    return postActivityWithNote(activityId, undefined);
  }

  function postActivityWithNote(
    activityId: string, note: string | undefined
  ): Promise<NewBiopostResponseJSON> {
    let url: ApiUrl = { path: `/activity/${activityId}` };
    if (note) { url.query = { note }; }
    return client.requestJSON(POST, url);
  }

  function postSpecUpdate(
    specUpdate: SpecUpdateJSON
  ): Promise<NewBiopostResponseJSON> {
    return client.requestJSON(
      PUT,
      { path: '/user_specs', version: 3 },
      { spec_update: specUpdate }
    );
  }

  function addFavoriteActivity(activityId: string): OK {
    return client.request(POST, `/favorites/${activityId}/add`);
  }

  function removeFavoriteActivity(activityId: string): OK {
    return client.request(POST, `/favorites/${activityId}/remove`);
  }

  function getMyActivityFeed(page?: number): Promise<ActivityFeedJSON> {
    return client.requestJSON(GET, { path: `/feed/me`, query: { page: page }});
  }

  function getMyDynamicPosts(): Promise<DynamicPostsJSON> {
    return client.requestJSON(GET, '/dynamic_posts/me');
  }

  function getCrewActivityFeed(page?: number): Promise<ActivityFeedJSON> {
    return client.requestJSON(
      GET, { path: `/feed/crew`, query: { page: page }}
    );
  }

  function getCrewDynamicPosts(): Promise<DynamicPostsJSON> {
    return client.requestJSON(GET, '/dynamic_posts/crew');
  }

  function getUserActivityFeed(
    userId: string, page?: number
  ): Promise<ActivityFeedJSON> {
    return client.requestJSON(
      GET, { path: `/feed/user/${userId}`, query: { page: page }}
    );
  }

  function getUserDynamicPosts(userId: string): Promise<DynamicPostsJSON> {
    return client.requestJSON(GET, `/dynamic_posts/user/${userId}`);
  }

  function getComments(
    biopostId: string
  ): Promise<CommentsResponseJSON> {
    return client.requestJSON(
      GET,
      { path: '/comments', query: { biopost_id: biopostId }}
    );
  }

  function postComment(biopostId: string, commentText: string): OK {
    return client.requestJSON(
      POST,
      '/comments',
      { comment: { text: commentText, biopost_id: biopostId }}
    );
  }

  function giveProps(biopostId: string): OK {
    return client.request(POST, `/props/${biopostId}`);
  }
}
