import { SpecUpdate } from 'Specs';
import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';
import {
  NSType, NS, Activity, Category, Biopost, CategoryInfo, RecommendedActivity,
  ActivityId, BiopostId, Comment, Prop
} from './Data';
import { Paging } from 'Shared/Paging';

/** Action types **/

export enum ActionType {
  LOAD_ACTIVITIES__SUCCESS = 'LOAD_ACTIVITIES__SUCCESS',
  RESET_ACTIVITIES = 'RESET_ACTIVITIES',
  LOAD_GENIUS_RECOMMENDATIONS__SUCCESS = 'LOAD_GENIUS_RECOMMENDATIONS__SUCCESS',
  LOAD_LAST_BIOPOST__SUCCESS = 'LOAD_LAST_BIOPOST__SUCCESS',
  POST_ACTIVITY__BEGIN = 'POST_ACTIVITY__BEGIN',
  POST_ACTIVITY__SUCCESS = 'POST_ACTIVITY__SUCCESS',
  POST_SPEC_UPDATE__SUCCESS = 'POST_SPEC_UPDATE__SUCCESS',
  ADD_FAVORITE__SUCCESS = 'ADD_FAVORITE__SUCCESS',
  REMOVE_FAVORITE__SUCCESS = 'REMOVE_FAVORITE__SUCCESS',
  LOAD_FEED__BEGIN = 'LOAD_FEED__BEGIN',
  LOAD_MY_FEED__SUCCESS = 'LOAD_MY_FEED__SUCCESS',
  LOAD_MY_DYNAMIC_POSTS__SUCCESS = 'LOAD_MY_DYNAMIC_POSTS__SUCCESS',
  LOAD_USER_FEED__SUCCESS = 'LOAD_USER_FEED__SUCCESS',
  LOAD_CREW_FEED__SUCCESS = 'LOAD_CREW_FEED__SUCCESS',
  LOAD_COMMENTS__SUCCESS = 'LOAD_COMMENTS__SUCCESS',
  POST_COMMENT__SUCCESS = 'POST_COMMENT__SUCCESS',
  GIVE_PROPS__BEGIN = 'GIVE_PROPS__BEGIN',
  GIVE_PROPS__SUCCESS = 'GIVE_PROPS__SUCCESS',
  GIVE_PROPS__ERROR = 'GIVE_PROPS__ERROR'
}

/** Action payload union type **/

export type Action
  = LoadActivities_Success
  | ResetActivities
  | LoadGeniusRecommendations_Success
  | LoadLastBiopost_Success
  | PostActivity_Begin
  | PostActivity_Success
  | PostSpecUpdate_Success
  | AddFavorite_Success
  | RemoveFavorite_Success
  | LoadFeed_Begin
  | LoadMyFeed_Success
  | LoadMyDynamicPosts_Success
  | LoadUserFeed_Success
  | LoadCrewFeed_Success
  | LoadComments_Success
  | PostComment_Success
  | GiveProps_Begin
  | GiveProps_Success;

export type NSAction = WithNS<NSType, Action>;
export const withNS = originalWithNS<NSType, Action>(NS);

export interface LoadActivities_Success {
  NS: NSType,
  type: ActionType.LOAD_ACTIVITIES__SUCCESS,
  activities: Activity[],
  categories: Category[],
  favorites: CategoryInfo | undefined,
  favoritesActivities: Activity[],
  genius: CategoryInfo | undefined,
  geniusRecommendations: RecommendedActivity[],
  specsCategoryName: string | undefined
}

export interface ResetActivities {
  type: ActionType.RESET_ACTIVITIES
}

export interface LoadGeniusRecommendations_Success {
  NS: NSType,
  type: ActionType.LOAD_GENIUS_RECOMMENDATIONS__SUCCESS,
  recommendations: RecommendedActivity[]
}

export interface LoadLastBiopost_Success {
  type: ActionType.LOAD_LAST_BIOPOST__SUCCESS,
  biopost: Biopost | undefined
}

export interface PostActivity_Begin {
  NS: NSType,
  type: ActionType.POST_ACTIVITY__BEGIN,
  activity: Activity
}

export interface PostActivity_Success {
  NS: NSType,
  type: ActionType.POST_ACTIVITY__SUCCESS
  activity: Activity,
  biopost: Biopost
}

export interface PostSpecUpdate_Success {
  type: ActionType.POST_SPEC_UPDATE__SUCCESS,
  specUpdate: SpecUpdate,
  biopost: Biopost
}

export interface AddFavorite_Success {
  type: ActionType.ADD_FAVORITE__SUCCESS,
  activityId: ActivityId
}

export interface RemoveFavorite_Success {
  type: ActionType.REMOVE_FAVORITE__SUCCESS,
  activityId: ActivityId
}

export interface LoadFeed_Begin {
  type: ActionType.LOAD_FEED__BEGIN
  page: number
}

export interface LoadMyFeed_Success {
  type: ActionType.LOAD_MY_FEED__SUCCESS
  bioposts: Biopost[]
  paging: Paging
}

export interface LoadMyDynamicPosts_Success {
  type: ActionType.LOAD_MY_DYNAMIC_POSTS__SUCCESS
  bioposts: Biopost[]
}

export interface LoadUserFeed_Success {
  type: ActionType.LOAD_USER_FEED__SUCCESS
  bioposts: Biopost[]
  paging: Paging
  userId: string
}

export interface LoadCrewFeed_Success {
  type: ActionType.LOAD_CREW_FEED__SUCCESS
  bioposts: Biopost[]
  paging: Paging
}

export interface LoadComments_Success {
  type: ActionType.LOAD_COMMENTS__SUCCESS
  comments: Comment[],
  biopostId: BiopostId
}

export interface PostComment_Success {
  NS: NSType,
  type: ActionType.POST_COMMENT__SUCCESS,
  biopostId: BiopostId,
  comment: Comment
}

export interface GiveProps_Begin {
  NS: NSType,
  type: ActionType.GIVE_PROPS__BEGIN,
  biopostId: BiopostId,
  prop: Prop
}

export interface GiveProps_Success {
  NS: NSType,
  type: ActionType.GIVE_PROPS__SUCCESS
  biopostId: BiopostId
}

export interface GiveProps_Error {
  NS: NSType,
  type: ActionType.GIVE_PROPS__ERROR
  biopostId: BiopostId
}
