import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { RouteComponentProps } from 'react-router-dom';
import { push } from 'connected-react-router';
import {
  challengeUrl, challengeLeaderboardUrl, challengeTeamLeaderboardUrl
} from 'Shared/Urls';
import { State, DispatchProps } from 'Store';
import {
  ChallengeId,
  ChallengeDetails,
  ChallengePlayer,
  LeaderboardMode,
  LENSES,
  SEL
} from 'Challenges/Data';
import * as Data from 'Challenges/Data';
import { ActionCreators } from 'Challenges/ActionCreator';

import Spinner from 'Shared/UI/Spinner';
import Pager from 'Shared/UI/Pager';

import { NavBarLayout } from 'Nav';
import Leaderboard from 'Challenges/UI/Leaderboard';

import TeamLeaderboardExplanation
  from 'Challenges/UI/Teams/LeaderboardExplanation';

type Props = State & RouteComponentProps<URLParams> & DispatchProps & {
  mode: LeaderboardMode
};

export type URLParams = {
  challengeId: string,
  page: string
}

type Paging = {
  currentPage: number,
  nextPage: number | undefined,
  previousPage: number | undefined
}

class FullLeaderboardPage extends React.Component<Props, {}> {
  challengeId(): ChallengeId {
    return parseInt(this.props.match.params.challengeId);
  }

  page(props?: Props): number {
    if (props === undefined) { props = this.props; }
    return parseInt(props.match.params.page);
  }

  paging(): Paging {
    const currentPage = this.page();
    return {
      currentPage,
      nextPage: this.hasMore() ? currentPage + 1 : undefined,
      previousPage: currentPage === 1 ? undefined : currentPage - 1
    };
  }

  componentDidMount() {
    this.loadLeaderboard();
    this.props.dispatch(ActionCreators.ensureChallenge(this.challengeId()));
  }

  componentDidUpdate(prevProps: Props) {
    if (this.page(prevProps) !== this.page()) {
      this.loadLeaderboard();
    }
  }

  loadLeaderboard() {
    switch(this.leaderboardMode()) {
      case 'players':
        this.props.dispatch(
          ActionCreators.loadLeaderboard(this.challengeId(), this.page())
        );
        break;
      case 'teams':
        this.props.dispatch(
          ActionCreators.loadTeamLeaderboard(this.challengeId(), this.page())
        );
        break;
    }
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  leaderboardMode(): LeaderboardMode {
    return this.props.mode;
  }

  leaderboard(): Data.Leaderboard | undefined {
    return SEL.leaderboard(
      this.challengeId(), this.leaderboardMode(), this.props.challenges
    );
  }

  challengeDetails(): ChallengeDetails | undefined {
    return LENSES.challenge(this.challengeId()).get(this.props.challenges);
  }

  players(): ChallengePlayer[] | undefined {
    const leaderboard = this.leaderboard();
    if (leaderboard) { return leaderboard.players; }
  }

  hasMore(): boolean {
    const leaderboard = this.leaderboard();
    return leaderboard !== undefined && leaderboard.hasMore;
  }

  gotoPage = (page: number) => {
    switch(this.leaderboardMode()) {
      case 'players':
        this.props.dispatch(
          push(challengeLeaderboardUrl(this.challengeId(), page)));
        break;
      case 'teams':
        this.props.dispatch(
          push(challengeTeamLeaderboardUrl(this.challengeId(), page)));
        break;
    }
  }

  ready(): boolean {
    const leaderboard = this.leaderboard();
    return !!leaderboard && leaderboard.challengeId === this.challengeId();
  }

  render() {
    const players = this.players();
    const challenge = this.challengeDetails();
    const paging = this.paging();

    return (
      <NavBarLayout
        title="Leaderboard" initBreadcrumb={challengeUrl(this.challengeId())}
      >
        {this.renderContentsOrLoading(
           this.ready(), players, challenge, paging, this.gotoPage
        )}
      </NavBarLayout>
    );
  }

  renderContentsOrLoading(
    ready: boolean,
    players: ChallengePlayer[] | undefined,
    challenge: ChallengeDetails | undefined,
    paging: Paging,
    gotoPage: (p: number) => void
  ): React.ReactNode {
    if (ready && players && challenge) {
      return [
        <Leaderboard
          mode={this.leaderboardMode()}
          players={players}
          challenge={challenge}
          hideLinkToFull={true}
          key="lb"/>,
        this.renderTeamLeaderboardExplanation(challenge),
        <Pager
          className="challenge-leaderboard__pager"
          nextPage={paging.nextPage}
          nextLabel="Next"
          previousPage={paging.previousPage}
          previousLabel="Previous"
          gotoPage={gotoPage}
          key="pager"/>
      ];
    } else {
      return (
        <Spinner wrap="center"></Spinner>
      );
    }
  }

  renderTeamLeaderboardExplanation(challenge: ChallengeDetails) {
    const metric = challenge.summary.metric;
    if (this.props.mode === 'teams') {
      return (
        <TeamLeaderboardExplanation key="team-explanation" metric={metric} />
      );
    }
  }
}

export default defaultConnect(FullLeaderboardPage);
