import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { defaultConnect } from 'Shared/ReduxComponent';

import { StoreDispatch, State, extractState } from 'Store';
import { SEL } from 'Challenges/Data';
import { ActionCreators } from 'Challenges/ActionCreator';

import PreJoinSetup, { Props as PreJoinSetupProps } from './PreJoinSetup';

type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch
}

type URLParams = {
  templateId: string
}

function renderPreJoinSetupTemplate(props: Props): React.ReactElement<Props> {
  const templateId = props.match.params.templateId;
  const template = SEL.getTemplate(templateId, props.challenges);

  const childProps: PreJoinSetupProps = {
    ...extractState(props),
    dispatch: props.dispatch,
    item: template,
    enrollAction: enrollAction(props)
  };

  return (
    <PreJoinSetup {...childProps}>
    </PreJoinSetup>
  );
}

export default defaultConnect(
  renderPreJoinSetupTemplate as React.StatelessComponent<Props>
);

function enrollAction(props: Props): () => void {
  return () => props.dispatch(
    ActionCreators.startChallenge(props.match.params.templateId)
  );
}
