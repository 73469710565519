import { CORDOVA, CUSTOM_BUILD } from 'Env';
import { ActionCreatorThunk } from 'Store';
import { withNS, ActionType } from './Action';
import { ActionCreators as AuthAC, isLoggedIn } from 'Auth';
import {
  ActionCreators as UserAC, ScreeningStatus
} from 'User';
import { ActionCreators as ScreeningAC } from 'Screenings';
import { ActionCreators as EdgeAC } from 'Edge';
import { watchNetwork } from 'Network/NetworkWatcher';
import { initialize as urlSchemeInit } from './CustomUrlScheme';
import * as Dialog from 'Shared/Dialog';

export interface ActionCreators {
  // Setup app when first loaded, should only be called once
  initializeApp(): ActionCreatorThunk

  // Setup app after login, should be called initially if logged in, but also
  // anytime a succesful login occurs
  initializeAppOnLogin(): ActionCreatorThunk

  // Resets the app state, used to clear out data on logout
  resetApp(): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  initializeApp,
  initializeAppOnLogin,
  resetApp
}

function initializeApp(): ActionCreatorThunk {
  return (dispatch, getState) => {
    watchNetwork({ dispatch, getState });
    urlSchemeInit(dispatch);
    return dispatch(AuthAC.initializeAuthState())
      .then(() => {
        if (isLoggedIn(getState().auth)) {
          dispatch(initializeAppOnLogin());
        }
      });
  }
}

const WRONG_APP_MESSAGE = 'Hi, your account is not enrolled in this program. For support, you can e-mail pro@1bios.co';

function initializeAppOnLogin(): ActionCreatorThunk {
  return (dispatch, getState) =>
    dispatch(UserAC.loadCurrentUser()).then(
      ({ program, screeningStatus, nativeAppSlug }) => {
        if (CORDOVA && nativeAppSlug !== CUSTOM_BUILD.SLUG) {
          Dialog.alert(WRONG_APP_MESSAGE).then(() => dispatch(AuthAC.logout()));
        } else if (screeningStatus === ScreeningStatus.ACTIVE) {
          dispatch(ScreeningAC.startScreening());
        } else if (!program.features.engagement) {
          dispatch(AuthAC.logout());
        }

        if (isLoggedIn(getState().auth)) {
          // If still logged in after sequence above
          dispatch(EdgeAC.init());
        }
      });
}

function resetApp(): ActionCreatorThunk {
  return async (dispatch) => {
    await dispatch(EdgeAC.stopAndDisconnect());
    dispatch(withNS({ type: ActionType.RESET_APP }));
  }
}
