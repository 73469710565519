import * as React from 'react';
import { State, StoreDispatch } from 'Store';
import { NavBarLayout } from 'Nav';
import * as Urls from 'Shared/Urls';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { defaultConnect } from 'Shared/ReduxComponent';

import { SEL, RecordId, Report } from 'Screenings/Data';
import { SEL as UserSEL } from 'User/Data';
import { ActionCreators } from 'Screenings/ActionCreator';

import Spinner from 'Shared/UI/Spinner';
import Icon from 'Shared/UI/Icon';

import ProgramLogo from 'User/UI/ProgramLogo';
import SummaryTable from 'Screenings/UI/ReportSummaryTable';
import RiskChart from 'Screenings/UI/ReportRiskChart';
import HistoricalTable from 'Screenings/UI/HistoricalTable';
import SurveyResponses from 'Screenings/UI/SurveyResponses';
import ReportPrintFooter from 'Screenings/UI/ReportPrintFooter';

type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch
}

interface URLParams {
  recordId: string
}

class ScreeningReport extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(ActionCreators.loadReport(this.recordId()));
  }

  recordId(): RecordId {
    return this.props.match.params.recordId;
  }

  report(): Report | undefined {
    return SEL.report(this.recordId(), this.props.screenings);
  }

  programName(): string {
    return UserSEL.programName(this.props.currentUser);
  }

  print = () => {
    window.print();
  }

  render() {
    const report = this.report();
    return (
      <NavBarLayout
        title="Screening Report"
        initBreadcrumb={Urls.screeningReportsUrl()}>
        <div className="simple-container">
          {report ? this.renderReport(report) : <Spinner wrap="center"/>}
        </div>
      </NavBarLayout>
    );
  }

  renderReport(report: Report) {
    const surveyMultipleChoiceOnly =
       report.survey.filter(survey => survey.options.length > 0);
    return (
      <div>
        <div className="screen-report__branding-image
                        print-only">
          <div>
            <ProgramLogo />
          </div>
        </div>

        <h2>{report.title}</h2>
        <p className="screen-report__date">
          Date of screening: {report.date.format('ll')}

          <a className="screen-report__print-button"
            onClick={this.print}>
            <Icon name="print" />
            Print Report
          </a>
        </p>
        <SummaryTable report={report} />
        {report.riskCharts.map((c, i) => <RiskChart key={i} riskChart={c} />)}
        <HistoricalTable rows={report.historicalTable} />
        <SurveyResponses responses={surveyMultipleChoiceOnly} />
        <div dangerouslySetInnerHTML={{__html: report.infoContent}}/>
        <ReportPrintFooter programName={this.programName()}/>
      </div>
    );
  }
}

export default defaultConnect(withRouter(ScreeningReport));
