import * as React from 'react';

import * as Dialog from 'Shared/Dialog';
import noop from 'Shared/Noop';
import ActionButton from './ActionButton';

interface Props {
  submit: (stepCount: number) => void
}

interface State {
  opened: boolean
}

class AddSteps extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { opened: false };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open() {
    this.setState({ ...this.state, opened: true });
  }

  close() {
    this.setState({ ...this.state, opened: false });
  }

  render() {
    return this.state.opened ?
           renderOpened(this.close, this.props.submit) :
           renderClosed(this.open);
  }
}


export default AddSteps;


function renderOpened(
  close: () => void,
  submit: (value: number) => void
) {
  let input: HTMLInputElement;
  const onSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();
    const stepCount = parseInt(input.value);
    if (isNaN(stepCount)) {
      Dialog.alert('Please enter a valid number of steps.');
    } else {
      Dialog.confirm(`Are you sure you want to add ${stepCount} steps?`).then(
        () => {
          submit(stepCount);
          close();
        },
        noop
      );
    }
  };

  return (
    <form onSubmit={onSubmit}
      className="challenge__button-list">
      <input type="number"
        placeholder="Add steps..."
        className="challenge__add-steps-input"
        ref={i => { if (i) { input = i; } }}/>
      <button
        className={['button',
                    'theme__primary-bg',
                    'theme__border-color',
                    'challenge__add-steps-submit'].join(' ')}
        type="submit">
        save
      </button>
    </form>
  );
}

function renderClosed(open: () => void) {
  return (
    <div className="challenge__button-list">
      <ActionButton
        label="manually add steps"
        action={open}
        modifiers={['primary']}
        appendClasses="theme__primary-bg"
      />
    </div>
  );
}
