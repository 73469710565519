import * as React from 'react';
import * as R from 'ramda';
import * as Edge from 'Edge/Data';
import { StoreDispatch } from 'Store';
import * as Urls from 'Shared/Urls';

import { ActionCreators as EdgeAC } from 'Edge/ActionCreator';

import FAIcon from 'Shared/UI/IconFontAwesome';
import Tile from './Tile';
import NoContent from './NoContent';
import SimpleTileContent from './SimpleTileContent';

interface Props {
  edgeState: Edge.State,
  dispatch: StoreDispatch
}

function DeviceTiles(props: Props) {
  const userDevices = props.edgeState.userDevices || [];
  const deviceProviders = (props.edgeState.providers || []).filter(
    p => p.integrationType === Edge.ProviderIntegrationType.Bluetooth
  );

  if (userDevices.length === 0) {
    return renderNoConnectedDevices(props, deviceProviders);
  } else {
    return renderConnectedDevices(props, userDevices);
  }
}

export default DeviceTiles;

function renderNoConnectedDevices(_props: Props, providers: Edge.Provider[]) {
  const tiles = R.sortBy(p => p.label.toLowerCase(), providers).map(
    provider =>
      <UnconnectedProviderTile key={provider.key} provider={provider} />
  );

  return <>{tiles}</>;
}

function renderConnectedDevices(props: Props, userDevices: Edge.UserDevice[]) {
  const tiles = userDevices.map(userDevice => {
    const provider =
      Edge.getProviderForDeviceTypeId(props.edgeState, userDevice.deviceTypeId);
    const deviceType =
      Edge.getDeviceTypeForUserDevice(props.edgeState, userDevice);
    if (provider && deviceType) {
      return (
        <UserDeviceTile
          key={userDevice.deviceId}
          userDevice={userDevice}
          syncingDevices={props.edgeState.syncingDevices}
          provider={provider}
          deviceType={deviceType}
          dispatch={props.dispatch}
        />
      );
    }
  });

  return <>{tiles}</>;
}

/*------------------------------------------------------------*/

interface UnconnectedProviderTileProps {
  provider: Edge.Provider
}

const UnconnectedProviderTile = (props: UnconnectedProviderTileProps) => {
  return (
    <Tile title={props.provider.label}
          linkUrl={Urls.edgeUrl()}
          linkLabel="connect device"
    >
      <NoContent iconName="sync">no device connected</NoContent>
    </Tile>
  );
};

/*------------------------------------------------------------*/

interface UserDeviceTileProps {
  userDevice: Edge.UserDevice,
  provider: Edge.Provider,
  deviceType: Edge.DeviceType,
  syncingDevices: Edge.UserDevice[],
  dispatch: StoreDispatch
}

function UserDeviceTile(props: UserDeviceTileProps) {
  const sync = () => props.dispatch(EdgeAC.syncDevice(props.userDevice));

  const userDevice = props.userDevice;
  const isSyncing = R.any(
    d => d.deviceId === userDevice.deviceId,
    props.syncingDevices
  );

  const settingsUrl =
    Urls.edgeDeviceSettingsUrl(userDevice.deviceTypeId, userDevice.deviceId);

  return (
    <Tile title={props.deviceType.shortName}
          linkLabel={buttonLabel(isSyncing)}
          linkUrl={sync}>
      <SimpleTileContent
        title="Last sync completed"
        badge={lastSyncLabel(userDevice)}
        icon="sync"
        url={settingsUrl}
      />
    </Tile>
  );
}

function buttonLabel(isSyncing: boolean) {
  if (isSyncing) {
    return (
      <span>
        syncing <FAIcon name="sync" style="solid" animate="spin" />
      </span>
    );
  } else {
    return 'sync device';
  }
}

function lastSyncLabel(userDevice: Edge.UserDevice) {
  if (userDevice.lastSyncAt) {
    return userDevice.lastSyncAt.format('LT l');
  } else {
    return 'never';
  }
}
