interface PluralOpts {
  textOnly?: boolean
}

const DEFAULT_PLURAL_OPTS: PluralOpts = {
};

export function plural(str: string, count: number, opts?: PluralOpts): string {
  opts = opts || DEFAULT_PLURAL_OPTS;
  const textOnly = opts.textOnly === undefined ? false : opts.textOnly;

  const text = `${str}${count === 1 ? '' : 's'}`;
  if (textOnly) {
    return text;
  } else {
    return `${count} ${text}`;
  }
}
