import * as React from 'react';
import { useState } from 'react';

interface Props {
  submit: (systolic: number, diastolic: number) => Promise<void>
}

const BloodPressureCalibration = (props: Props) => {
  const [systolic, setSystolic] = useState<string>('');
  const [diastolic, setDiastolic] = useState<string>('');

  const submit = async (e: React.MouseEvent<any>) => {
    e.preventDefault();
    const systolicInt = parseInt(systolic);
    const diastolicInt = parseInt(diastolic);
    if (!isNaN(systolicInt) && !isNaN(diastolicInt)) {
      await props.submit(systolicInt, diastolicInt);
      setSystolic('');
      setDiastolic('');
    }
  };

  return (
    <div className="simple-container">
      <p>
        Enter a blood pressure calibration measurement to send to your device.
      </p>
      <div className="mb-10">
        <input
          type="number"
          inputMode="numeric"
          className="text-field text-field--block"
          placeholder="systolic"
          value={systolic}
          onChange={e => setSystolic(e.target.value)}
        />
      </div>
      <div className="mb-10">
        <input
          type="number"
          inputMode="numeric"
          className="text-field text-field--block"
          placeholder="diastolic"
          value={diastolic}
          onChange={e => setDiastolic(e.target.value)}
        />
      </div>
      <div className="mb-10">
        <button
          className="button button-fixed-width theme__primary-bg"
          onClick={submit}
        >
          send
        </button>
      </div>
    </div>
  )
};

export default BloodPressureCalibration;
