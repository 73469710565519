import { ActionCreatorThunk } from 'Store';
import { ActionType, withNS } from './Action';

import { configFromJSON } from './JSON';

export interface ActionCreators {
  loadOnboardConfig(): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  loadOnboardConfig
}

function loadOnboardConfig(): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(withNS({ type: ActionType.LOAD_CONFIG__BEGIN }));
    return api.onboard.getConfig().then(result => {
      dispatch(withNS({
        type: ActionType.LOAD_CONFIG__SUCCESS,
        config: configFromJSON(result.config)
      }));
    });
  }
}
