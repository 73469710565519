import * as React from 'react';

import { defaultConnect } from 'Shared/ReduxComponent';
import { State, StoreDispatch } from 'Store';
import { hudState } from 'App/Data';

import * as Urls from 'Shared/Urls';
import Link from 'Shared/UI/Link';
import { HUD } from 'HUD';
import { NavBarLayout } from 'Nav';

import { UI as EdgeUI } from 'Edge'
const EdgeConnectionList = EdgeUI.ConnectionList;

type Props = State & {
  dispatch: StoreDispatch
}

class EdgePage extends React.Component<Props, {}> {
  render() {
    const userGuideLink = (
      <Link to={Urls.userGuideUrl()} themed={true} breadcrumb="push">
        User Guide
      </Link>
    );
    return (
      <NavBarLayout
        className="settings-edge-page"
        title="Devices and Apps"
        initBreadcrumb={Urls.dashboardUrl()}>
        <div className="simple-container">
          <p className="settings__section-explanation">
            Learn more about how connected device and app data work in
            the {userGuideLink}.
          </p>
          <EdgeConnectionList>
          </EdgeConnectionList>
        </div>
        <HUD state={hudState(this.props)}></HUD>
      </NavBarLayout>
    );
  }
}

export default defaultConnect(EdgePage);
