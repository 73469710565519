import * as moment from 'moment-timezone';

export interface DeviceInfo {
  manufacturerName: string,
  modelNumber: string,
  serialNumber: string,
  hwVersion: string,
  fwVersion: string,
  swVersion: string
}

export enum Unit {
  KG = 0x00,
  LB = 0x01
}

export interface Weight {
  weight: number,
  time: moment.Moment
}

export interface BloodPressure {
  systolic: number,
  diastolic: number,
  heartRate: number,
  time: moment.Moment
}
