import * as R from 'ramda';
import * as moment from 'moment-timezone';
import {
  IDMap, newIDMap
} from 'Shared/Data/IDMap';
import {
  Feed,
  makeFeed,
  feedIds,
  feedIsLoading,
  feedPaging
} from 'Shared/Data/Feed';
import { Paging } from 'Shared/Paging';
import { MinimalUser } from 'User';

/*
  ------------------------------------------------------------
  NS
  ------------------------------------------------------------
*/

export type NSType = 'INBOX';
export const NS: NSType = 'INBOX';

/*
  ------------------------------------------------------------
  Inbox data types
  ------------------------------------------------------------
*/

export type ThreadId = string;

export type ThreadInfoFeed = Feed<ThreadId>;

export interface State {
  threadInfos: IDMap<ThreadInfo>,
  // Maps thread id -> message list
  messages: IDMap<Message[]>,
  // Feed of threads used for thread index ("My Inbox" page)
  threadInfoFeed: ThreadInfoFeed
}

export interface ThreadInfo {
  id: ThreadId
  participants: MinimalUser[]
  lastMessageAt: moment.Moment
  unreadCount: number
  subject: string
  closed: boolean
}

export interface Message {
  userId: string
  postedAt: moment.Moment
  text: string
}

export interface Thread {
  info: ThreadInfo
  messages: Message[]
}

export type ThreadType = 'crew' | 'care_pro';

export function initialState(): State {
  return {
    threadInfos: newIDMap(),
    messages: newIDMap(),
    threadInfoFeed: makeFeed()
  };
}

export function validThreadType(value: string): value is ThreadType {
  return value === 'crew' || value === 'care_pro';
}
/*
  ------------------------------------------------------------
  Selectors
  ------------------------------------------------------------
*/

export const SEL = {
  getThreadInfo(id: ThreadId, state: State): ThreadInfo | undefined {
    return state.threadInfos[id];
  },

  getFeedThreadInfos(state: State): ThreadInfo[] {
    return R.reject(
      R.isNil,
      feedIds(state.threadInfoFeed).map(id => SEL.getThreadInfo(id, state))
    );
  },

  getFeedLoading(state: State): boolean {
    return feedIsLoading(state.threadInfoFeed);
  },

  getFeedPaging(state: State): Paging {
    return feedPaging(state.threadInfoFeed);
  },

  getThreadMessages(id: ThreadId, state: State): Message[] | undefined {
    return state.messages[id];
  }
};
