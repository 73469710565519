import { CrewMember, MinimalUser } from 'User/Data';
import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';
import { NSType, NS } from './Data';

export enum ActionType {
  REMOVE_FROM_CREW__SUCCESS,
  LOAD_CREW_INVITATIONS__SUCCESS
}

interface RemoveFromCrew_Success {
  type: ActionType.REMOVE_FROM_CREW__SUCCESS,
  crewMember: CrewMember
}

interface LoadCrewInvitations_Success {
  type: ActionType.LOAD_CREW_INVITATIONS__SUCCESS,
  invites: MinimalUser[]
}

export type Action
  = RemoveFromCrew_Success
  | LoadCrewInvitations_Success;

export type NSAction = WithNS<NSType, Action>;
export const withNS = originalWithNS<NSType, Action>(NS);
