import { ApiClient, GET, PUT, OK } from 'Api';

import { NotificationSettingsJSON } from './JSON/Notifications';

export interface NotificationsAPI {
  getSettings(): Promise<{ settings: NotificationSettingsJSON }>,

  putNotificationSettings(settings: NotificationSettingsJSON): OK
}

const NOTIFICATION_SETTINGS_URL =
  { path: '/notification_settings', version: 2 };

export function makeApi(client: ApiClient): NotificationsAPI {
  return {
    getSettings,
    putNotificationSettings
  };

  function getSettings(): Promise<{ settings: NotificationSettingsJSON }> {
    return client.requestJSON(
      GET,
      NOTIFICATION_SETTINGS_URL
    );
  }

  function putNotificationSettings(settings: NotificationSettingsJSON): OK {
    return client.request(
      PUT,
      NOTIFICATION_SETTINGS_URL,
      JSON.stringify({ settings })
    );
  }
}
