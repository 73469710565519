import * as React from 'react';
import GoalList from 'Goals/UI/ConnectedGoalList';

const GoalsContents = () => {
  return (
    <div>
      <h2>Goals</h2>
      <GoalList />
    </div>
  );
}

export default GoalsContents;
