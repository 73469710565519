import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';
import { NS, NSType, Device } from './Data';
import { Version } from 'Shared/Data/Version';

export enum ActionType {
  BLE_AVAILABLE = 'BLE_AVAILABLE',
  BLE_NOT_AVAILABLE = 'BLE_NOT_AVAILABLE',
  BLE_SCAN_START = 'BLE_SCAN_START',
  BLE_SCAN_STOP = 'BLE_SCAN_STOP',
  BLE_SCAN_FOUND_DEVICE = 'BLE_SCAN_FOUND_DEVICE',
  BLE_CHECK_FIRMWARE__COMPLETE = 'BLE_CHECK_FIRMWARE__COMPLETE',
  BLE_UPDATE_PROGRESS = 'BLE_UPDATE_PROGRESS',
  BLE_DFU_BEGIN = 'BLE_DFU_BEGIN',
  BLE_DFU_COMPLETE = 'BLE_DFU_COMPLETE'
}

export type Action
  = BLEAvailable
  | BLENotAvailable
  | BLEScanStart
  | BLEScanStop
  | BLEScanFoundDevice
  | BLECheckFirmware_Complete
  | BLEUpdateProgress
  | BLEDFUBegin
  | BLEDFUComplete;

export type NSAction = WithNS<NSType, Action>;
export const withNS = originalWithNS<NSType, Action>(NS);

export interface BLEAvailable {
  type: ActionType.BLE_AVAILABLE
}

export interface BLENotAvailable {
  type: ActionType.BLE_NOT_AVAILABLE
}

export interface BLEScanFoundDevice {
  type: ActionType.BLE_SCAN_FOUND_DEVICE,
  device: Device
}

export interface BLEScanStart {
  type: ActionType.BLE_SCAN_START
}

export interface BLEScanStop {
  type: ActionType.BLE_SCAN_STOP
}

export interface BLECheckFirmware_Complete {
  type: ActionType.BLE_CHECK_FIRMWARE__COMPLETE,
  address: string,
  // currentVersion of undefined implies we could not connect to device
  currentVersion: Version | undefined,
  // latestVersion of undefined implies no firmware versions are known on the
  // server
  latestVersion: Version | undefined
}

export interface BLEUpdateProgress {
  type: ActionType.BLE_UPDATE_PROGRESS,
  progress: number
}

export interface BLEDFUBegin {
  type: ActionType.BLE_DFU_BEGIN
}

export interface BLEDFUComplete {
  type: ActionType.BLE_DFU_COMPLETE
}
