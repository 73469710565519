import { GET, ApiClient } from 'Api';

import {
  GetPageResponseJSON
} from './JSON/CMS';

export interface CMSAPI {
  getPage(url: string): Promise<GetPageResponseJSON>
}

export function makeApi(client: ApiClient): CMSAPI {
  return {
    getPage
  };

  function getPage(url: string): Promise<GetPageResponseJSON> {
    return client.requestJSON(GET, `/pages/${encodeURIComponent(url)}`);
  }
}

