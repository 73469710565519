import * as R from 'ramda';
import {
  RewardPlanJSON, UserActionJSON, RewardJSON, ActionJSON, ActionStatusJSON
} from 'Api/JSON/Rewards';
import {
  RewardPlan, UserAction, Reward, Action, ActionType, ActionStatus
} from 'Rewards/Data';
import { dateFromJSON } from 'Shared/JSON';

export function rewardPlanFromJSON(json: RewardPlanJSON): RewardPlan {
  return {
    startDate: dateFromJSON(json.start_date),
    endDate: dateFromJSON(json.end_date),
    maxPoints: json.max_points,
    currentPoints: json.current_points
  };
}


export function userActionsFromJSON(json: UserActionJSON[]): UserAction[] {
  return R.reject(
    a => a.reward.action === undefined,
    json.map(userActionFromJSON)
  );
}

export function userActionFromJSON(json: UserActionJSON): UserAction {
  return {
    reward: rewardFromJSON(json.reward),
    status: actionStatusFromJSON(json.status)
  };
}

function rewardFromJSON(json: RewardJSON): Reward {
  return {
    id: json.id,
    name: json.name,
    points: json.points,
    action: actionFromJSON(json.action_type),
    endDate: dateFromJSON(json.end_date),
    rules: json.rules
  };
}

function actionFromJSON(json: ActionJSON): Action {
  switch(json.setfield) {
  case 'screening_complete':
    return { type: ActionType.ScreeningComplete };
  case 'manual_action_pro':
    return { type: ActionType.ManualActionPro };
  case 'manual_action_app':
    return { type: ActionType.ManualActionApp };
  case 'device_connected':
    return { type: ActionType.DeviceConnected };
  case 'challenge_joined':
    return {
      type: ActionType.ChallengeJoined, challengeId: json.value.challenge_id
    };
  case 'challenge_template_joined':
    return {
      type: ActionType.ChallengeTemplateJoined,
      templateId: json.value.template_id
    };
  }
}

function actionStatusFromJSON(json: ActionStatusJSON): ActionStatus {
  switch(json) {
  case ActionStatusJSON.AVAILABLE:
    return ActionStatus.AVAILABLE;
  case ActionStatusJSON.IN_PROGRESS:
    return ActionStatus.IN_PROGRESS;
  case ActionStatusJSON.COMPLETE:
    return ActionStatus.COMPLETE;
  }
}
