import * as React from 'react';
import { bem } from 'css-util';
import * as Urls from 'Shared/Urls';
import { Appt, slotLabel } from 'Appt/Data';
import Link from 'Shared/UI/Link';

interface Props {
  appts: Appt[]
  userZoneId: string
}

const CSS = bem('appt');

const MyApptsList = (props: Props) => {
  return (
    <ul className={CSS('appts-list')()}>
      {props.appts.map(appt => listItem(appt, props.userZoneId))}
    </ul>
  );
};

export default MyApptsList;


function listItem(appt: Appt, tzId: string) {
  return (
    <li key={appt.id} className={CSS('appts-list-item')()}>
      <Link to={Urls.apptUrl(appt.id)}
        className={CSS('appts-list-item__link')()}>
        <div className={CSS('appts-list-item__half')()}>
          {appt.type.name}
        </div>
        <div className={CSS('appts-list-item__half')()}>
          {slotLabel(appt.slot, tzId)}
        </div>
        <div className="appt__appts-list-item__caret">▸</div>
      </Link>
    </li>
  );
}
