import { push } from 'connected-react-router';
import * as Urls from 'Shared/Urls';
import { ActionCreatorThunk } from 'Store';

import { withNS, ActionType } from './Action';

export interface ActionCreators {
  goOnline(): ActionCreatorThunk,
  goOffline(): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  goOnline,
  goOffline
}

function goOnline(): ActionCreatorThunk {
  return (dispatch, getState) => {
    const previousLocation = getState().network.previousLocation;
    dispatch(withNS({ type: ActionType.GO_ONLINE }));
    dispatch(
      push(previousLocation ? previousLocation.pathname : Urls.rootUrl())
    );
    return Promise.resolve();
  }
}

function goOffline(): ActionCreatorThunk {
  return (dispatch, getState) => {
    const routerState = getState().router;
    const currentLocation = routerState && routerState.location;
    dispatch(withNS({ type: ActionType.GO_OFFLINE, currentLocation }));
    dispatch(push(Urls.offlineModeUrl()));
    return Promise.resolve();
  };
}
