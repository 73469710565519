import * as React from 'react';
import * as classnames from 'classnames';
import './Badge.scss';

interface Props {
  children?: React.ReactNode
  className?: string
}

const Badge = (props: Props) => {
  return (
    <div className={classnames('badge', props.className)}>
      {props.children}
    </div>
  );
}

export default Badge;
