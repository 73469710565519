import * as R from 'ramda';
import * as React from 'react';
import * as classnames from 'classnames';
import * as DOW from 'Shared/Data/DayOfWeek';

interface Props {
  selected: DOW.DayOfWeek[],
  onChange: (selected: DOW.DayOfWeek[]) => void
}

export default function renderDayOfWeekSelector(
  props: Props
): React.ReactElement<Props> {
  const day = renderDay(props);
  return (
    <div className="day-selector">
      {DOW.all(DOW.DayOfWeek.MONDAY).map(day)}
    </div>
  );
}

const renderDay = R.curry(_renderDay);
function _renderDay(props: Props, day: DOW.DayOfWeek): React.ReactNode {
  const classes = classnames(
    'day-selector__day',
    { 'day-selector__day-selected theme__primary-bg':
      R.contains(day, props.selected)
    });
  const handleClick = () => props.onChange(toggleDay(day, props.selected));

  return (
    <div className={classes} onClick={handleClick} key={day}>
      {DOW.initial(day)}
    </div>
  );
}

function toggleDay(
  day: DOW.DayOfWeek,
  selected: DOW.DayOfWeek[]
): DOW.DayOfWeek[] {
  if (R.contains(day, selected)) {
    return R.without([day], selected);
  } else {
    return R.sortBy(R.identity, R.append(day, selected));
  }
}
