import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { State, StoreDispatch } from 'Store';
import ErrorPage from 'Shared/UI/ErrorPage';

type Props = State & {
  dispatch: StoreDispatch
}

class OfflineMode extends React.Component<Props, {}> {
  tryAgain = () => {
    window.location.reload();
  }

  render() {
    return (
      <ErrorPage
        icon="wifi"
        title="Cannot Connect"
        detail="Please check your network connection and try again later."
        tryAgainTarget={this.tryAgain} />
    );
  }
}

export default defaultConnect(OfflineMode);
