export type NSType = 'MAINTENANCE';
export const NS: NSType = 'MAINTENANCE';

export interface State {
  enabled: boolean,
  pollInterval?: number | undefined
}

export function initialState(): State {
  return { enabled: false };
}

export function currentlyPolling(state: State): boolean {
  return !!state.pollInterval;
}
