import * as React from 'react';

import { StoreDispatch } from 'Store';
import { UserAction, completedActions } from 'Rewards/Data';
import RewardListItem from 'Rewards/UI/RewardListItem';
import * as Urls from 'Shared/Urls';
import Link from 'Shared/UI/Link';

interface Props {
  userActions: UserAction[],
  dispatch: StoreDispatch
}

const RewardActivity = (props: Props) => {
  const completed = completedActions(props.userActions);
  return (
    <div>
      <h4 className="section-title">
        Reward Activity
      </h4>

      <div className="simple-container">
        {completed.map((action, i) => renderRewardItem(props, action, i))}
        {completed.length === 0 && renderNoActivityMessage()}
      </div>
    </div>
  );
};

export default RewardActivity;


function renderRewardItem(
  props: Props, action: UserAction, i: number
): React.ReactNode {
  return (
    <RewardListItem
      key={i}
      reward={action.reward}
      status={action.status}
      dispatch={props.dispatch}/>
  );
}

function renderNoActivityMessage() {
  return (
    <p className="text-center">
      Looks like you don't have any reward activity yet.
      {' '}
      <Link to={Urls.availableRewardsUrl()} themed={true} breadcrumb="push">
        Get started!
      </Link>
    </p>
  );
}
