import { NotificationSettingsJSON } from 'Api/JSON/Notifications';
import { dayOfWeekFromJSON, dayOfWeekToJSON } from 'Shared/JSON';
import { NotificationSettings } from './Data';


export function notificationSettingsFromJSON(
  settingsJSON: NotificationSettingsJSON
): NotificationSettings {
  const fatlineJSON = settingsJSON.fatline;
  const dcJSON = settingsJSON.daily_coach;
  return {
    fatline: {
      apptCancellation: fatlineJSON.appt_cancellation,
      apptConfirmation: fatlineJSON.appt_confirmation,
      apptReminder: fatlineJSON.appt_reminder,
      commentPosted: fatlineJSON.comment_posted,
      commentReplyPosted: fatlineJSON.comment_reply_posted,
      gotProps: fatlineJSON.got_props,
      newInboxMessage: fatlineJSON.new_inbox_message,
      alertNotificationUserCompliance: fatlineJSON.alert_notification_user_compliance,
      alertNotificationUserMeasurement: fatlineJSON.alert_notification_user_measurement,
      alertNotificationUserTrend: fatlineJSON.alert_notification_user_trend,
      alertNotificationUserHie: fatlineJSON.alert_notification_user_hie
    },
    dailyCoach: {
      days: dcJSON.days.map(dayOfWeekFromJSON),
      enabled: dcJSON.enabled,
      hour: dcJSON.hour
    }
  };
}

export function notificationSettingsToJSON(
  settings: NotificationSettings
): NotificationSettingsJSON {
  const { fatline, dailyCoach } = settings;
  return {
    fatline: {
      appt_cancellation: fatline.apptCancellation,
      appt_confirmation: fatline.apptConfirmation,
      appt_reminder: fatline.apptReminder,
      comment_posted: fatline.commentPosted,
      comment_reply_posted: fatline.commentReplyPosted,
      got_props: fatline.gotProps,
      new_inbox_message: fatline.newInboxMessage,
      alert_notification_user_compliance: fatline.alertNotificationUserCompliance,
      alert_notification_user_measurement: fatline.alertNotificationUserMeasurement,
      alert_notification_user_trend: fatline.alertNotificationUserTrend,
      alert_notification_user_hie: fatline.alertNotificationUserHie
    },
    daily_coach: {
      days: dailyCoach.days.map(dayOfWeekToJSON),
      enabled: dailyCoach.enabled,
      hour: dailyCoach.hour
    }
  }
}
