import * as React from 'react';
import * as classnames from 'classnames';
import { connect } from 'react-redux';
import { DispatchProps } from 'Store';
import { HTML } from 'Shared/Data/HTML';
import { ActionCreators as NavAC } from 'Nav';

interface OwnProps {
  html: HTML,
  className?: string
}

type Props = OwnProps & DispatchProps;

const DynamicHTML = (props: Props) => {
  const handleClick = (event: React.MouseEvent<any>) => {
    const el = event.target as HTMLElement;
    if (el.tagName === 'A') {
      event.preventDefault();
      const anchor = el as HTMLAnchorElement;
      if (externalLink(anchor)) {
        const target = anchor.target === '' ? '_blank' : anchor.target;
        window.open(anchor.href, target);
      } else {
        const url = anchor.hash.substr(1);
        props.dispatch(NavAC.pushWithBreadcrumb(url));
      }
    }
  }
  return (
    <div
      className={classnames(props.className)}
      dangerouslySetInnerHTML={{__html: props.html}}
      onClick={handleClick}/>
  );
};

export default connect<{}, DispatchProps, OwnProps, {}>(
  null,
  dispatch => ({ dispatch })
)(DynamicHTML);

function externalLink(el: HTMLAnchorElement): boolean {
  return el.host !== window.location.host;
}
