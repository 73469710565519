import * as React from 'react';

interface Props {
  onSubmit: (password: string, cb?: () => void) => void
}

interface State {
  password: string,
  passwordConfirmation: string,
  error?: string
}

const INITIAL_STATE: State = {
  password: '',
  passwordConfirmation: '',
  error: undefined
}

class ChangePasswordForm extends React.Component<Props, State> {
  onPasswordChange: (e: React.FormEvent<HTMLInputElement>) => void

  onPasswordConfirmationChange: (e: React.FormEvent<HTMLInputElement>) => void

  constructor(props: Props) {
    super(props);
    this.state = INITIAL_STATE;

    this.onSubmit = this.onSubmit.bind(this);
    this.onPasswordChange = this.onFieldChange('password').bind(this);
    this.onPasswordConfirmationChange =
      this.onFieldChange('passwordConfirmation').bind(this);
  }

  onFieldChange(
    fieldName: keyof State
  ): (e: React.FormEvent<HTMLInputElement>) => void {
    return e => {
      const el = e.target as HTMLInputElement;
      this.setState({
        ...this.state,
        [fieldName]: el.value
      });
    };
  }

  onSubmit(e: React.FormEvent<any>) {
    e.preventDefault();
    const error = passwordError(this.state);

    if (error) {
      this.setState({ ...this.state, error });
    } else {
      this.setState({ ...this.state, error: undefined });
      this.props.onSubmit(this.state.password, this.clearFields);
    }
  }

  clearFields = () => this.setState(INITIAL_STATE)

  render() {
    return (
      <form
        onSubmit={this.onSubmit} className="change-pwd-form simple-container">
        {renderTips(this.state)}

        <div className="mb-10">
          <input type="password"
            value={this.state.password}
            onChange={this.onPasswordChange}
            placeholder="New password"
            autoComplete="new-password"
            required
            className="text-field text-field--block"/>
        </div>
        <div className="mb-10">
          <input type="password"
            value={this.state.passwordConfirmation}
            onChange={this.onPasswordConfirmationChange}
            required
            placeholder="Confirm password"
            autoComplete="new-password"
            className="text-field text-field--block"/>
        </div>
        <div className="mb-10 clearfix">
          <button type="submit"
            className="button button-fixed-width button-gray">
            change password
          </button>
        </div>
      </form>
    );
  }
}

export default ChangePasswordForm;

function passwordError(
  { password, passwordConfirmation }: {
    password: string, passwordConfirmation: string
  }
): string | undefined {
  if (password !== passwordConfirmation) {
    return 'Password confirmation does not match';
  } else if (password.length < 8) {
    return 'Password must be at least 8 characters';
  }
}

function renderTips(state: State): React.ReactNode {
  const classNames = state.error ? 'error-text' : '';
  const content =
    state.error ? state.error : 'Password must be at least 8 characters';
  return (
    <p className={classNames}>
      {content}
    </p>
  );
}
