import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { defaultConnect } from 'Shared/ReduxComponent';
import * as Scroll from 'Shared/Scroll';
import { State, StoreDispatch } from 'Store';
import { NavBarLayout } from 'Nav';
import * as Urls from 'Shared/Urls';
import { LENS, Page } from 'CMS/Data';
import { ActionCreators } from 'CMS/ActionCreator';
import Spinner from 'Shared/UI/Spinner';
import DynamicHTML from 'Shared/UI/DynamicHTML';

interface OwnProps {}

type StateProps = State;
type DispatchProps = { dispatch: StoreDispatch };

interface URLParams {
  section?: string
}

type Props =
  OwnProps & StateProps & DispatchProps & RouteComponentProps<URLParams>;

export default function makeCMSPage(
  url: string
): React.ComponentType<OwnProps> {
  const CMSPage = class extends React.Component<Props, {}> {
    componentDidMount() {
      if (this.page() === undefined) {
        this.props.dispatch(ActionCreators.loadPage(url)).then(
          this.scrollToSection
        );
      }
    }

    componentDidUpdate(prevProps: Props) {
      if (this.section() !== this.section(prevProps)) {
        this.scrollToSection();
      }
    }

    scrollToSection = () => {
      const section = this.section();
      if (section) {
        const sel = `#${section}-section`;
        Scroll.toElement(sel);
      }
    }

    section(props?: Props): string | undefined {
      props = props || this.props;
      return props.match.params.section;
    }

    page(): Page | undefined {
      return LENS.page(url).get(this.props.cms);
    }

    render() {
      const page = this.page();
      return (
        <NavBarLayout
          title={page ? page.title : ''}
          initBreadcrumb={Urls.dashboardUrl()}>
          {page ? this.renderContent(page) : <Spinner wrap="center" />}
        </NavBarLayout>
      );
    }

    renderContent(page: Page) {
      return (
        <DynamicHTML
          className="simple-container cms-content"
          html={page.body}
        />
      );
    }
  }

  return defaultConnect(withRouter(CMSPage));
}
