export * from './Data';
export * from './Action';
export { reducer } from './Store';
export { ActionCreators } from './ActionCreator';

import ValueTable from './UI/ValueTable';

export const UI = {
  ValueTable
}

import ScreeningReports from './Page/ScreeningReports';
import ScreeningReport from './Page/ScreeningReport';
import ScreeningForm from './Page/ScreeningForm';

export const Pages = {
  ScreeningReports,
  ScreeningReport,
  ScreeningForm
};
