import * as R from 'ramda';

import { ActionCreatorThunk, State } from 'Store';
import { LENSES as APP_LENSES } from 'App/Data';
import { AppAPI } from 'App/Api';

import { ActionCreators as HUD } from 'HUD';

import { ActionType } from './Action';
import * as Action from './Action';

import {
  NS, NotificationSettings, FatlineAlert, FatlineTransmitter, DailyCoachSettings
} from './Data';

import * as JSON from './JSON';

export interface ActionCreators {
  loadNotificationSettings(): ActionCreatorThunk,

  toggleFatlineAlert(
    alert: FatlineAlert, transmitter: FatlineTransmitter
  ): ActionCreatorThunk

  updateDailyCoach(dc: DailyCoachSettings): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  loadNotificationSettings,
  toggleFatlineAlert,
  updateDailyCoach
};


function loadNotificationSettings(): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch({
      NS, type: ActionType.LOAD_NOTIFICATION_SETTINGS__BEGIN
    } as Action.LoadNotificationSettings_Begin);

    return api.notifications.getSettings().then(r => {
      const settings = JSON.notificationSettingsFromJSON(r.settings);
      dispatch({
        NS,
        type: ActionType.LOAD_NOTIFICATION_SETTINGS__SUCCESS,
        settings
      } as Action.LoadNotificationSettings_Success);
    });
  };
}

function toggleFatlineAlert(
  alert: FatlineAlert,
  transmitter: FatlineTransmitter
): ActionCreatorThunk {
  return (dispatch, getState, { api }) => {
    dispatch(HUD.loading());
    dispatch({
      NS,
      type: ActionType.TOGGLE_FATLINE_ALERT__BEGIN,
      alert, transmitter
    } as Action.ToggleFatlineAlert_Begin)

    return saveNotificationSettings(getState, api).then(() => {
      dispatch({
        NS,
        type: ActionType.TOGGLE_FATLINE_ALERT__SUCCESS,
        alert, transmitter
      } as Action.ToggleFatlineAlert_Success)
      dispatch(HUD.success());
    });
  };
}

function updateDailyCoach(dailyCoach: DailyCoachSettings): ActionCreatorThunk {
  return (dispatch, getState, { api }) => {
    dispatch(HUD.loading());
    dispatch({
      NS, type: ActionType.UPDATE_DAILY_COACH__BEGIN, dailyCoach
    } as Action.UpdateDailyCoach_Begin)

    return saveNotificationSettings(getState, api).then(() => {
      dispatch({
        NS, type: ActionType.UPDATE_DAILY_COACH__SUCCESS, dailyCoach
      } as Action.UpdateDailyCoach_Success);
      dispatch(HUD.success());
    });
  };
}


function saveNotificationSettings(
  getState: () => State,
  api: AppAPI
): Promise<{}> {
  const updatedSettings = R.view(
    APP_LENSES.settings.notificationSettings,
    getState()
  ) as NotificationSettings;

  return api.notifications.putNotificationSettings(
    JSON.notificationSettingsToJSON(updatedSettings)
  );
}
