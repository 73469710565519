import { Paging } from 'Shared/Paging';
import { NSType, NS, ThreadInfo, Thread } from './Data';
import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';

export enum ActionType {
  LOAD_THREAD_LIST__SUCCESS,
  LOAD_THREAD__SUCCESS,
  CREATE_THREAD__SUCCESS
}

export type Action
  = LoadThreadList_Success
  | LoadThread_Success
  | CreateThread_Success;

export interface LoadThreadList_Success {
  type: ActionType.LOAD_THREAD_LIST__SUCCESS
  threadInfos: ThreadInfo[],
  paging: Paging
}

export interface LoadThread_Success {
  type: ActionType.LOAD_THREAD__SUCCESS
  thread: Thread
}

export interface CreateThread_Success {
  type: ActionType.CREATE_THREAD__SUCCESS,
  thread: Thread
}

export type NSAction = WithNS<NSType, Action>;
export const withNS: (a: Action) => NSAction = originalWithNS(NS);
