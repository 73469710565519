import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';
import { NS, NSType, Metric, MetricKey, TrendData, DataRequest } from './Data';

export enum ActionType {
  LOAD_AVAILABLE_METRICS__SUCCESS = 'LOAD_AVAILABLE_METRICS__SUCCESS',
  LOAD_TREND_DATA__BEGIN = 'LOAD_TREND_DATA__BEGIN',
  LOAD_TREND_DATA__SUCCESS = 'LOAD_TREND_DATA__SUCCESS',
  CLEAR_TREND_DATA = 'CLEAR_TREND_DATA'
}

export type Action
  = LoadAvailableMetrics_Success
  | LoadTrendData_Begin
  | LoadTrendData_Success
  | ClearTrendData;


export interface LoadAvailableMetrics_Success {
  type: ActionType.LOAD_AVAILABLE_METRICS__SUCCESS,
  metrics: Metric[],
  initialMetricKey: MetricKey | null
}

export interface LoadTrendData_Success {
  type: ActionType.LOAD_TREND_DATA__SUCCESS,
  trendData: TrendData
}

export interface LoadTrendData_Begin {
  type: ActionType.LOAD_TREND_DATA__BEGIN,
  request: DataRequest
}

export interface ClearTrendData {
  type: ActionType.CLEAR_TREND_DATA
}

export type NSAction = WithNS<NSType, Action>;
export const withNS = originalWithNS<NSType, Action>(NS);
