import * as React from 'react';

import { goalsUrl } from 'Shared/Urls';

import { NavBarLayout } from 'Nav';

import FeatureGate from 'App/UI/FeatureGate';
import { UI as GoalsUI } from 'Goals';
const ConnectedGoalList = GoalsUI.ConnectedGoalList;

function renderGoalsPage(): React.ReactElement<{}> {
  return (
    <NavBarLayout title="goal settings" initBreadcrumb={goalsUrl()}>
      <p className="settings__section-explanation">
        Click or tap goal(s) below to activate. Manage active goals from the
        "Goals" page.
      </p>

      <ConnectedGoalList />
    </NavBarLayout>
  );
}

export default FeatureGate(renderGoalsPage, ['goals']);
