import * as React from 'react';
import AlertBox from 'Shared/UI/AlertBox';

interface Props {
  errors: string[]
}

const Errors = (props: Props) => {
  if (props.errors.length > 0) {
    return (
      <div>
        {props.errors.map((error, i) => (
           <AlertBox modifiers="error" key={i}>{error}</AlertBox>
         ))}
      </div>
    );
  } else {
    return null;
  }
}

export default Errors;
