import * as React from 'react';
import * as Urls from 'Shared/Urls';
import { bem } from 'css-util';
import ProgramLogo from 'User/UI/ProgramLogo';
import Link from 'Shared/UI/Link';

interface Props {
  allowExit: boolean,
  children?: React.ReactNode
  isDone?: boolean
}

const CSS = bem('screening-form');

const Layout = (props: Props) => {
  return (
    <div className={`${CSS()()} simple-container`}>
      <div className={CSS('container')()}>
        <div className={CSS('header')()}>
          <div className={CSS('header-logo')()}>
            <ProgramLogo />
          </div>
        </div>
        {props.isDone && renderThankYou()}
        {props.children}
        {!props.isDone && props.allowExit && renderExitButtonRow()}
      </div>
    </div>
  );
}

export default Layout;


function renderThankYou() {
  return (
    <p className="screening-form__intro__text">
      Thank you!
    </p>
  );
}

function renderExitButtonRow() {
  return (
    <div className="screening-form__bottom-bar">
      <div className="screening-form__container
                      screening-form__container--no-pad">
        <Link to={Urls.rootUrl()} themed={true}>
          exit HRA
        </Link>
      </div>
    </div>
  );
}
