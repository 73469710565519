import { ActionCreatorThunk } from 'Store';

import { NS, HUDState } from './Data';

import { ActionType } from './Action';
import * as Action from './Action';
import { sleep } from 'Shared/Timeout';

export interface ActionCreators {
  loading(): Action.SetHUDState,
  success(closeDelay?: number): ActionCreatorThunk,
  error(closeDelay?: number): ActionCreatorThunk
  close(delay?: number): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  loading,
  success,
  error,
  close
};

const DEFAULT_DELAY = 800;
/*------------------------------------------------------------*/
/* private */

function loading(): Action.SetHUDState {
  return setHUDState('loading');
}

function success(closeDelay = DEFAULT_DELAY): ActionCreatorThunk {
  return (dispatch) => {
    dispatch(setHUDState('success'));
    return dispatch(close(closeDelay));
  };
}

function error(closeDelay = DEFAULT_DELAY): ActionCreatorThunk {
  return (dispatch) => {
    dispatch(setHUDState('error'));
    return dispatch(close(closeDelay));
  };
}

function close(delay = DEFAULT_DELAY): ActionCreatorThunk {
  return (dispatch) => {
    return sleep(delay).then(() => dispatch(setHUDState('closed')));
  };
}


function setHUDState(hudState: HUDState): Action.SetHUDState {
  return {
    NS,
    type: ActionType.SET_HUDSTATE,
    hudState
  };
}
