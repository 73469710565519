import * as React from 'react';
import { bem } from 'css-util';
import { CrewMember } from 'User';

import ProfPic from 'Shared/UI/ProfPic';
import Spinner from 'Shared/UI/Spinner';

interface Props {
  showScore?: boolean
  crew?: CrewMember[] | undefined
  rightDetail?: RenderFn
  onClick?: CrewClickFn
  noCrew?: React.ReactNode
}

type RenderFn = (member: CrewMember) => React.ReactNode
type CrewClickFn = (member: CrewMember) => void

const CrewList = (props: Props) => {
  const noCrew = (props.crew && props.crew.length === 0) ? props.noCrew : null;
  return (
    <div className="tile">
      <h3 className="tile__title">Community</h3>
      {props.crew ? renderList(props) : <Spinner wrap="center" />}
      {noCrew}
    </div>
  )
}

export default CrewList;

function renderList(props: Props) {
  const rightDetail = props.rightDetail || defaultRightDetail(props);
  return (
    <ul className="crew-list">
      {(props.crew || []).map(
         member => renderListItem(member, props.onClick, rightDetail))}
    </ul>
  );
}

const LI = bem('crew-list-item');
function renderListItem(
  member: CrewMember, onClick: CrewClickFn | undefined, rightDetail: RenderFn
) {
  const clickHandler = onClick ? () => onClick(member) : undefined;
  return (
    <li key={member.user.id}
      className={LI()({ clickable: !!clickHandler })}
      onClick={clickHandler}>
      <div className={LI('left')()}>
        <ProfPic userId={member.user.id} variant="round"/>
      </div>
      <div className={LI('middle')()}>
        {member.user.name}
      </div>
      <div className={LI('right')()}>
        {rightDetail(member)}
      </div>
    </li>
  );
}

function defaultRightDetail(
  props: Props
): (member: CrewMember) => React.ReactNode {
  return (member: CrewMember) => {
    if (props.showScore) {
      return (
        <span className={`${LI('score')()} theme__primary-bg`}>
          {member.healthScore}
        </span>
      );
    }
  };
}
