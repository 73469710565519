import * as React from 'react';

const GoalsInstructions = () => {
  return (
    <div>
      <p>
        <strong className="onboard-instructions__why-how">Why? </strong>
        We'll help you track progress and reach your objectives.
      </p>
      <p>
        <strong className="onboard-instructions__why-how">How? </strong>
        Manage your goals from the "My Dashboard" page - you can activate one
        or more below.
      </p>
    </div>
  );
};

export default GoalsInstructions;
