import { StoreDispatch } from 'Store';
import * as Edge from 'Edge/Data';
import * as BLE from 'BLE';

export function makeCollector(
  dispatch: StoreDispatch,
  ble: BLE.Service,
  deviceType: Edge.DeviceType,
  device: Edge.UserDevice
): Edge.Collector {
  const makeCollector =
    require(`Edge/Collector/${deviceType.protocol}`).makeCollector;
  return makeCollector(dispatch, ble, deviceType, device);
}
