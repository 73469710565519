import * as React from 'react';
import TextareaAutosize from 'react-autosize-textarea';

interface Props {
  submit: (comment: string, onComplete: () => void) => void,
  placeholder?: string,
  buttonLabel?: string,
  disabled?: boolean
}

interface State {
  text: string
  isSubmitting: boolean
}

const DEFAULT_PLACEHOLDER = 'Add a comment...';
const DEFAULT_BUTTON_LABEL = 'save';

class CommentForm extends React.Component<Props, State> {
  state = { text: '', isSubmitting: false }

  onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ text: e.target.value });
  }

  onSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();
    if (this.state.text !== '') {
      this.setState({ isSubmitting: true });
      this.props.submit(
        this.state.text,
        () => this.setState({ text: '', isSubmitting: false })
      );
    }
  }

  disabled(): boolean {
    return this.props.disabled || this.state.isSubmitting;
  }

  render() {
    const placeholder =
      this.props.placeholder === undefined ?
      DEFAULT_PLACEHOLDER : this.props.placeholder;
    const buttonLabel =
      this.props.buttonLabel === undefined ?
      DEFAULT_BUTTON_LABEL : this.props.buttonLabel;

    return (
      <form className="comment-form clearfix" onSubmit={this.onSubmit}>
        <TextareaAutosize placeholder={placeholder}
          value={this.state.text}
          onChange={this.onTextChange}
          disabled={this.disabled()}
          className="comment-form__textarea"
          rows={1}
          required>
        </TextareaAutosize>
        <label className="submit comment-form__submit-label">
          <button type="submit"
            className="comment-form__submit-button button theme__primary-bg"
            disabled={this.disabled()}>
            {buttonLabel}
          </button>
        </label>
      </form>
    );
  }
}

export default CommentForm;
