import * as R from 'ramda';

import {
  State,
  ChallengeId,
  ChallengeDetails, ChallengeSummary, Team, TeamId, Player,
  TeamLeaderboard,
  TaskChallengeDayDetail,
  LENSES
} from 'Challenges/Data';
import * as Data from 'Challenges/Data';
import { Loadable, loadable, loadableMap } from 'misc/Data/Loadable';
import { Action, ActionTypes } from './Action';
import {
  IDMapWithKey, idMapInsert, newIDMap, idMapLookup, idMapDelete
} from 'Shared/Data/IDMap';
import * as moment from 'moment-timezone';
import { TaskId } from 'Tasks/Data';

export function reducer(state: State, action: Action): State {
  switch(action.type) {
  case ActionTypes.JOIN_CHALLENGE_SUCCESS:
    return putChallenge(action.challenge, state);

  case ActionTypes.START_CHALLENGE_SUCCESS:
    return putChallenge(action.challenge, state);

  case ActionTypes.LOAD_LEADERBOARD_START:
    return LENSES.leaderboardPlayers.set(state, undefined);

  case ActionTypes.LOAD_LEADERBOARD_SUCCESS:
    return LENSES.leaderboard.set(state, {
      challengeId: action.challengeId,
      players: action.players,
      hasMore: action.hasMore,
      page: LENSES.leaderboardPage(state)
    });

  case ActionTypes.LOAD_CHALLENGES_SUCCESS:
    const { enrolled, available, templates } = action.data;
    return {
        ...state,
      enrolled: sortEnrolledChallenges(loadable(enrolled)),
      available: loadable(available),
      templates: templates
    };

  case ActionTypes.LOAD_COMPLETED_CHALLENGES_START:
    return {
        ...state,
      completed: {
        challenges: loadable(),
        hasMorePages: false
      }
    };

  case ActionTypes.LOAD_COMPLETED_CHALLENGES_SUCCESS:
    const { completed, hasMorePages } = action.data;
    return {
        ...state,
      completed: {
        challenges: loadable(completed),
        hasMorePages: hasMorePages
      }
    };

  case ActionTypes.POLL_CHALLENGE_START:
    return LENSES.challenge(action.challengeId).update(
      state, c => c ? { ...c, reloading: true } : c
    );

  case ActionTypes.LOAD_CHALLENGE_START:
    return removeChallenge(action.challengeId, state);

  case ActionTypes.LOAD_CHALLENGE_SUCCESS:
    return putChallenge(action.challenge, state);

  case ActionTypes.LEAVE_CHALLENGE_SUCCESS:
    return state;

  case ActionTypes.DECLINE_INVITATION_SUCCESS:
    return removeInvitation(action.invitationId, state);

  case ActionTypes.LOAD_INVITATIONS_SUCCESS:
    return { ...state, invitations: loadable(action.invitations) };

  case ActionTypes.ACCEPT_INVITATION_SUCCESS:
    return removeInvitation(action.invitationId, state);

  case ActionTypes.LOAD_CHALLENGE_RULES_SUCCESS:
    return LENSES.rules('challenge', action.id).set(state, action.rules);

  case ActionTypes.LOAD_TEMPLATE_RULES_SUCCESS:
    return LENSES.rules('template', action.id).set(state, action.rules);

  case ActionTypes.LOAD_TASK_CHALLENGE_DAY_DETAIL_START:
    return LENSES.currentTaskChallengeDayDetail.set(state, undefined);
  case ActionTypes.LOAD_TASK_CHALLENGE_DAY_DETAIL_SUCCESS:
    return LENSES.currentTaskChallengeDayDetail.set(state, {
      date: action.date,
      completedTaskIds: action.completedTaskIds,
      availableTaskIds: action.availableTaskIds,
      locked: action.locked
    });
  case ActionTypes.LOAD_TASK_CHALLENGE_DAY_SUMMARIES_START:
    return LENSES.currentTaskChallengeDaySummaries.set(state, undefined);
  case ActionTypes.LOAD_TASK_CHALLENGE_DAY_SUMMARIES_SUCCESS:
    return LENSES.currentTaskChallengeDaySummaries.set(
      state, action.daySummaries
    );
  case ActionTypes.COMPLETE_TASK_FOR_CHALLENGE_SUCCESS:
    state =
      updateDayDetail(
        state, action.taskId, action.date, Data.withTaskCompleted
      );

    state =
      updateProgressDailyPoints(
        state, action.challengeId, action.taskId, action.date,
        Data.withChallengeProgressPointsAdded
      );

    return state;
  case ActionTypes.UNDO_TASK_FOR_CHALLENGE_SUCCESS:
    state =
      updateDayDetail(
        state, action.taskId, action.date, Data.withTaskUndone
      );

    state =
      updateProgressDailyPoints(
        state, action.challengeId, action.taskId, action.date,
        Data.withChallengeProgressPointsSubtracted
      );

    return state;
  case ActionTypes.LOAD_TEAMS_SUCCESS:
    return setTeams(action.challengeId, action.teams, state);
  case ActionTypes.LOAD_TEAM_WITH_PLAYERS_SUCCESS:
    return setTeamPlayers(
      action.team.id,
      action.players,
      addTeam(action.challengeId, action.team, state)
    );
  case ActionTypes.LOAD_TEAM_LEADERBOARD_START:
    return setTeamLeaderboard(action.challengeId, undefined, state);
  case ActionTypes.LOAD_TEAM_LEADERBOARD_SUCCESS:
    return setTeamLeaderboard(
      action.challengeId,
      {
        challengeId: action.challengeId,
        players: action.teamPlayers,
        page: action.page,
        hasMore: action.hasMore
      },
      state
    );
  }
}

function putChallenge(
  c: ChallengeDetails,
  state: State
): State {
  return LENSES.challenge(c.summary.id).set(state, c);
}

function removeChallenge(
  cid: ChallengeId,
  state: State
): State {
  return LENSES.challenge(cid).set(state, undefined);
}

function sortEnrolledChallenges(
  enrolled: Loadable<ChallengeSummary[]>
): Loadable<ChallengeSummary[]> {
  return loadableMap(challenges => R.sortBy(
    c => c.endDate.unix().toString(),
    challenges
  ), enrolled);
}

function removeInvitation(
  invitationId: string, state: State
): State {
  return LENSES.invitations.update(
    state,
    loadableMap(invites => R.reject(i => i.id === invitationId, invites))
  );
}

function setTeams(
  challengeId: ChallengeId, teams: Team[], state: State
): State {
  return LENSES.teams(challengeId).set(state, teams);
}

function addTeam(challengeId: ChallengeId, team: Team, state: State): State {
  let teamsMap = idMapLookup(challengeId, state.teams) || newIDMap();
  teamsMap = idMapInsert(team.id.toString(), team, teamsMap);
  return {
    ...state,
    teams: idMapInsert(challengeId, teamsMap, state.teams)
  };
}

function setTeamPlayers(
  teamId: TeamId, players: Player[], state: State
): State {
  return {
    ...state,
    teamPlayers: idMapInsert(teamId, players, state.teamPlayers)
  };
}

function setTeamLeaderboard(
  challengeId: ChallengeId,
  leaderboard: TeamLeaderboard | undefined,
  state: State
): State {
  let teamLeaderboards: IDMapWithKey<ChallengeId, TeamLeaderboard>;
  if (leaderboard) {
    teamLeaderboards = idMapInsert(
      challengeId, leaderboard, state.teamLeaderboards
    );
  } else {
    teamLeaderboards = idMapDelete(challengeId, state.teamLeaderboards);
  }
  return { ...state, teamLeaderboards };
}

function updateDayDetail(
  state: State,
  taskId: TaskId,
  day: moment.Moment,
  withTaskUpdate: (
    taskId: TaskId, dayDetail: TaskChallengeDayDetail
  ) => TaskChallengeDayDetail
) {
  return LENSES.currentTaskChallengeDayDetail.update(
    state,
    dayDetail => {
      if (
        dayDetail === undefined || !dayDetail.date.isSame(day, 'day')
      ) {
        return dayDetail;
      } else {
        return withTaskUpdate(taskId, dayDetail);
      }
    }
  );
}

function updateProgressDailyPoints(
  state: State,
  challengeId: ChallengeId,
  taskId: TaskId,
  day: moment.Moment,
  withProgressDailyPointsUpdated: (
    challenge: ChallengeDetails, taskId: TaskId, day: moment.Moment
  ) => ChallengeDetails
) {
  return LENSES.challenge(challengeId).update(
    state,
    challenge => {
      if ( challenge === undefined ) {
        return challenge;
      } else {
        return withProgressDailyPointsUpdated(challenge, taskId, day)
      }
    }
  );
}
