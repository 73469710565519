import * as R from 'ramda';
import * as moment from 'moment-timezone';
import * as BLE from 'BLE/Data';
export { DeviceProtocol } from 'BLE/Data';
import { Platform, getPlatform } from 'Shared/Device';

/*------------------------------------------------------------*/

export type NSType = 'EDGE';
export const NS: NSType = 'EDGE';

/*------------------------------------------------------------*/

export interface State {
  connections?: Connections,
  providers?: Provider[]
  deviceConnectionStatuses: DeviceConnectionStatuses
  userDevices?: UserDevice[]
  // List of devices currently being synced
  syncingDevices: UserDevice[]
  backgroundSyncInitialized: boolean
  syncOnResumeInitialized: boolean
  syncOnResumePendingShutdown: boolean
}

export type ProviderId = number;
export type ProviderKey = string;
export type DeviceTypeId = number;
// Corresponds to actual bluetooth uuid/address
export type DeviceId = string;

export enum ProviderIntegrationType {
  ServerFetch = 'ServerFetch',
  ServerPush = 'ServerPush',
  Bluetooth = 'Bluetooth',
  HealthKit = 'HealthKit',
  None = 'None'
}

export interface Provider {
  id: ProviderId,
  key: ProviderKey,
  label: string,
  description: string,
  integrationType: ProviderIntegrationType,
  deviceTypes: DeviceType[]
  iconUrl: string
  connectIconUrl: string
  hidden: boolean
}

export interface UserDevice {
  deviceTypeId: DeviceTypeId,
  deviceId: DeviceId,
  lastSyncAt?: moment.Moment
}

export interface DeviceType {
  id: DeviceTypeId,
  name: string,
  shortName: string,
  protocol: BLE.DeviceProtocol | undefined,
  scanMatchers: RegExp[],
  providerId: ProviderId,
  formFactor: DeviceFormFactor
}

export type DeviceFormFactor
  = 'scale'
  | 'bp_cuff'
  | 'glucometer'
  | 'watch'
  | 'other';

/** bluetooth/external device data **/

export interface Collector {
  sync(): Promise<void>
}

type BPM = number;
type Fahrenheit = number;
type Inches = number;
type Pounds = number
type Minutes = number;

export interface HRDatum {
  time: moment.Moment,
  heartRate: BPM
}

export interface BPDatum {
  time: moment.Moment,
  systolic: number,
  diastolic: number
}

export interface SpO2Datum {
  time: moment.Moment,
  spo2: number
}

export interface BodyTempDatum {
  time: moment.Moment,
  bodyTemp: Fahrenheit
}

export interface StepsDatum {
  time: moment.Moment,
  stepCount: number
}

export interface ActiveMinutesDatum {
  time: moment.Moment,
  activeMinutes: Minutes
}

export interface GlucoseDatum {
  time: moment.Moment,
  glucose: number
}

export interface BodyFatDatum {
  time: moment.Moment,
  bodyFat: number
}

export interface HeightDatum {
  time: moment.Moment,
  height: Inches
}

export interface WeightDatum {
  time: moment.Moment,
  weight: Pounds
}

export interface SleepDatum {
  time: moment.Moment,
  asleepDuration: Minutes
}

export interface HRVDatum {
  time: moment.Moment,
  hrv: number
}

export interface StressScoreDatum {
  time: moment.Moment,
  stressScore: number
}

export interface DeviceData {
  restingHeartRates?: HRDatum[],
  continuousHeartRates?: HRDatum[],
  bloodPressures?: BPDatum[],
  spo2s?: SpO2Datum[],
  bodyTemps?: BodyTempDatum[],
  steps?: StepsDatum[],
  glucoses?: GlucoseDatum[],
  bodyFats?: BodyFatDatum[],
  heights?: HeightDatum[],
  weights?: WeightDatum[],
  sleeps?: SleepDatum[],
  activeMinutes?: ActiveMinutesDatum[],
  hrvs?: HRVDatum[],
  stressScores?: StressScoreDatum[]
}

type Datum
  = HRDatum
  | BPDatum
  | SpO2Datum
  | BodyTempDatum
  | StepsDatum
  | GlucoseDatum
  | BodyFatDatum
  | HeightDatum
  | WeightDatum
  | SleepDatum
  | ActiveMinutesDatum
  | HRVDatum
  | StressScoreDatum;

export type DataType
  = 'steps'
  | 'sleep'
  | 'weight'
  | 'height'
  | 'gender'
  | 'waist'
  | 'blood_pressure'
  | 'body_fat'
  | 'resting_heart_rate'
  | 'continuous_heart_rate'
  | 'active_minutes'
  | 'stress_score'
  | 'hrv'
  | 'respiratory_rate'
  | 'spo2'
  | 'body_temp';

export type Timestamp = number;

export type ConnectionStatus = 'not_connected' | 'pending' | 'connected';

export type Connections = { [P in ProviderKey]: ConnectionStatus }
export type DeviceConnectionStatuses =
  { [deviceTypeId: number]: ConnectionStatus | undefined };

/*
  ------------------------------------------------------------
  initializers
  ------------------------------------------------------------
*/

export function initialState(): State {
  return {
    connections: undefined,
    providers: undefined,
    syncingDevices: [],
    deviceConnectionStatuses: {},
    backgroundSyncInitialized: false,
    syncOnResumeInitialized: false,
    syncOnResumePendingShutdown: false
  };
}

export function newConnections(
  providers: Provider[], val?: ConnectionStatus
): Connections {
  val = val || 'not_connected';
  return providers.reduce(
    (conns, provider) => ({ ...conns, [provider.key]: val }),
    {}
  );
}

/*
  ------------------------------------------------------------
  lenses
  ------------------------------------------------------------
*/

interface Lenses {
  connections: R.Lens,
  providers: R.Lens
}

export const LENSES: Lenses = {
  connections: R.lensProp('connections'),
  providers: R.lensProp('providers')
};

/*
  ------------------------------------------------------------
  other helper functions
  ------------------------------------------------------------
*/

export const setConnection = R.curry(_setConnection);
export function _setConnection(
  key: ProviderKey,
  status: ConnectionStatus,
  connections: Connections
): Connections {
  return R.assoc(key, status, connections);
}

export const setAllConnections = R.curry(_setAllConnections);
export function _setAllConnections(
  keys: ProviderKey[],
  status: ConnectionStatus,
  connections: Connections
): Connections {
  return R.reduce(
    (conns: Connections, key: ProviderKey) => setConnection(key, status, conns),
    connections,
    keys
  );
}

export function isEnabled(p: Provider): boolean {
  const platforms = providerPlatforms(p.key)
  return platforms === undefined || R.contains(getPlatform(), platforms);
}

function providerPlatforms(key: ProviderKey): Platform[] | undefined {
  if (key === 'health_kit') { return ['iOS']; }
}

export const isDisabled = R.compose(R.not, isEnabled);

export function getProvider(
  state: State, providerKey: ProviderKey
): Provider | undefined {
  if (state.providers === undefined) { return; }
  return getProviderFromList(state.providers, providerKey);
}

export function getProviderFromList(
  providers: Provider[], providerKey: ProviderKey
): Provider | undefined {
  return R.find(p => p.key === providerKey, providers);
}
export function getDeviceTypeForUserDevice(
  state: State, userDevice: UserDevice
): DeviceType | undefined {
  if (state.providers === undefined) { return; }
  const types = R.chain(p => p.deviceTypes, state.providers);

  return R.find(
    deviceType => deviceType.id === userDevice.deviceTypeId,
    types
  );
}

export function getProviderForDeviceTypeId(
  state: State, deviceTypeId: DeviceTypeId
): Provider | undefined {
  if (state.providers === undefined) { return; }
  return R.find(
    provider => R.any(dt => dt.id === deviceTypeId, provider.deviceTypes),
    state.providers
  );
}

export function filterUserDevicesByProvider(
  userDevices: UserDevice[], provider: Provider
): UserDevice[] {
  const deviceTypeIds = provider.deviceTypes.map(t => t.id);
  return userDevices.filter(
    ud => R.contains(ud.deviceTypeId, deviceTypeIds)
  );
}

export function userDeviceToBLEDevice(
  userDevice: UserDevice,
  name?: string
): BLE.Device {
  name = name || `Device Type ${userDevice.deviceTypeId}`;
  return { address: userDevice.deviceId, name };
}

export function millisSinceLastSync(device: UserDevice): number | undefined {
  if (device.lastSyncAt === undefined) { return; }
  return moment().diff(device.lastSyncAt);
}

export function matchesDeviceType(
  deviceType: DeviceType, deviceName: string
): boolean {
  return R.any(
    matcher => matcher.test(deviceName), deviceType.scanMatchers
  );
}

export function matchesAnyDeviceType(
  deviceTypes: DeviceType[], deviceName: string
): boolean {
  return R.any(
    deviceType => matchesDeviceType(deviceType, deviceName), deviceTypes
  );
}

export function findMatchingDeviceType(
  deviceTypes: DeviceType[], deviceName: string
): DeviceType | undefined {
  return R.find(
    deviceType => matchesDeviceType(deviceType, deviceName),
    deviceTypes
  );
}

export function deviceDataIsEmpty(data: DeviceData): boolean {
  return R.all(k => R.isEmpty(data[k] || []), R.keys(data));
}

export function mergeDeviceData(d1: DeviceData, d2: DeviceData): DeviceData {
  return R.keys(d2).reduce(
    (result: DeviceData, key: keyof DeviceData) => {
      const current = result[key] || [];
      const additions = d2[key] || [];
      return {
        ...result,
        [key]: R.concat<Datum>(current, additions)
      };
    },
    d1
  );
}

export function dedupByTime(data: DeviceData): DeviceData {
  return R.keys(data).reduce(
    (result: DeviceData, key: keyof DeviceData) => {
      return {
        ...result,
        [key]: R.uniqBy<Datum, number>(p => p.time.unix(), (data[key] || []))
      };
    },
    {}
  );
}

export function markLastSyncTime(
  userDevice: UserDevice, time: moment.Moment, state: State
): State {
  const devices = R.reject(
    d => d.deviceId === userDevice.deviceId,
    state.userDevices || []
  );
  const updated = { ...userDevice, lastSyncAt: time };
  return { ...state, userDevices: R.append(updated, devices) };
}

export function isConnectedToDevices(state: State): boolean {
  return (state.userDevices || []).length > 0;
}

export function isSyncing(
  state: State, device: UserDevice | DeviceId
): boolean {
  const targetDeviceId = typeof(device) === 'object' ? device.deviceId : device;
  return R.any(
    d => d.deviceId === targetDeviceId,
    state.syncingDevices
  );
}
