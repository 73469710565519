import * as React from 'react';
import { bem } from 'css-util';
import { Props } from './FieldProps';

const CSS = bem('screening-field');

const HTMLField = (props: Props) => {
  const field = props.field;
  return (
    <div className={CSS()()}>
      <div className={CSS('label')(['html'])}
        dangerouslySetInnerHTML={{__html: field.label}}>
      </div>
    </div>
  );
};

export default HTMLField;
