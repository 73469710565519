import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { Loadable, fromLoadable } from 'misc/Data/Loadable';
import { StoreDispatch, State } from 'Store';

import { State as PostState, Biopost, SEL, ActivityId } from 'Post/Data';
import {
  CurrentUser, UI as UserUI, SEL as UserSEL,
  loadableFeaturesEnabled
} from 'User';
import { ExpandedSpecType } from 'Specs';
import { ActionCreators } from 'Post/ActionCreator';

import { HUD } from 'HUD';
import { NavBarLayout } from 'Nav';
import PostMenu from 'Post/UI/PostMenu';
import LastBiopost from 'Post/UI/LastBiopost';

type Props = State & {
  dispatch: StoreDispatch
}

class Post extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.addFavorite = this.addFavorite.bind(this);
    this.removeFavorite = this.removeFavorite.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(ActionCreators.loadActivities());
    this.props.dispatch(ActionCreators.loadLastBiopost());
  }

  postState(): PostState {
    return this.props.post;
  }

  currentUser(): CurrentUser {
    return this.props.currentUser;
  }

  userExposedSpecs(): ExpandedSpecType[] {
    return fromLoadable(
      s => s,
      () => [],
      UserSEL.exposedSpecs(this.currentUser())
    );
  }

  lastBiopost(): Loadable<Biopost | undefined> {
    return SEL.getLastBiopost(this.postState());
  }

  showScore(): boolean {
    return loadableFeaturesEnabled(
      UserSEL.appFeatures(this.props.currentUser),
      ['healthScore']
    );
  }

  addFavorite(activityId: ActivityId) {
    this.props.dispatch(ActionCreators.addFavorite(activityId));
  }

  removeFavorite(activityId: ActivityId) {
    this.props.dispatch(ActionCreators.removeFavorite(activityId));
  }

  render() {
    return (
      <NavBarLayout title="Post Activity">
        <HUD state={this.props.hud} />
        {this.renderUserBanner()}
        <LastBiopost
          showScore={this.showScore()} biopost={this.lastBiopost()} />
        <PostMenu
          state={this.postState()}
          onFavorite={this.addFavorite}
          onUnfavorite={this.removeFavorite}
          showSpecs={this.userExposedSpecs()}
        />
      </NavBarLayout>
    );
  }

  renderUserBanner() {
    const profile = this.currentUser().profile;
    if (profile) {
      return (
        <UserUI.UserBanner
          user={profile}
          variant={this.showScore() ? 'score' : 'prof_pic'} />
      );
    }
  }
}

export default defaultConnect(Post);
