import * as React from 'react';
import { safeLink } from 'Shared/SafeLink';

import { ApptType, Slot, slotEndTime } from 'Appt/Data';

interface Props {
  apptType: ApptType,
  slot?: Slot,
  userZoneId: string
}

const EventDetails = (props: Props) => {
  const { apptType, slot, userZoneId } = props;
  return (
    <div className="appt__event-details mb-1r">
      <p className="appt__appt-attr">
        <strong>Event:</strong> {apptType.name}
      </p>

      <p className="appt__appt-attr">
        <strong>More details: </strong>
        <span dangerouslySetInnerHTML={{__html: safeLink(apptType.details)}} />
      </p>

      {renderSlot(slot, userZoneId)}
    </div>
  )
};

export default EventDetails;

function renderSlot(slot: Slot | undefined, userZoneId: string) {
  if (slot) {
    return (
      <p className="appt__appt-attr">
        <strong>Time:</strong> {formatSlotTime(slot, userZoneId)}
      </p>
    );
  }
}

function formatSlotTime(slot: Slot, userZoneId: string): string {
  const startTime = slot.startTime;
  const endTime = slotEndTime(slot);
  return startTime.format('M/D/YYYY h:mm A - ') +
         endTime.tz(userZoneId).format('h:mm A ') +
         startTime.tz(userZoneId).format('z');
}
