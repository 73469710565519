import { DateJSON, HTMLJSON } from './Shared';

export interface RewardPlanResponseJSON {
  reward_plan: RewardPlanJSON | undefined
}

export interface RewardPlanJSON {
  start_date: DateJSON
  end_date: DateJSON
  max_points: number
  current_points: number
}

export interface RewardJSON {
  id: number
  name: string
  points: number
  action_type: ActionJSON
  end_date: DateJSON
  rules: HTMLJSON
}

export type ActionTypeJSON
  = 'manual_action_pro'
  | 'manual_action_app'
  | 'device_connected'
  | 'screening_complete'
  | 'challenge_joined'
  | 'challenge_template_joined';

export type ActionJSON
  = ManualActionProJSON
  | ManualActionAppJSON
  | DeviceConnectedJSON
  | ScreeningCompleteJSON
  | ChallengeJoinedJSON
  | ChallengeTemplateJoinedJSON;

interface ManualActionProJSON {
  setfield: 'manual_action_pro'
}

interface ManualActionAppJSON {
  setfield: 'manual_action_app'
}

interface DeviceConnectedJSON {
  setfield: 'device_connected'
}

interface ScreeningCompleteJSON {
  setfield: 'screening_complete'
}

interface ChallengeJoinedJSON {
  setfield: 'challenge_joined',
  value: { challenge_id: number }
}

interface ChallengeTemplateJoinedJSON {
  setfield: 'challenge_template_joined',
  value: { template_id: string }
}

export interface UserActionsResponseJSON {
  user_actions: UserActionJSON[]
}

export enum ActionStatusJSON {
  AVAILABLE = 0,
  IN_PROGRESS = 1,
  COMPLETE = 2
}

export interface UserActionJSON {
  reward: RewardJSON,
  status: ActionStatusJSON
}
