import { push } from 'connected-react-router';
import { ActionCreatorThunk, ActionCreatorThunkThen } from 'Store';
import { currentlyPolling } from './Data';
import { ActionType, NSAction, withNS } from './Action';
import * as Either from 'Shared/Data/Either';
import * as Urls from 'Shared/Urls';

export interface ActionCreators {
  // Set maint mode and goto maint page
  gotoMaintenanceMode(): ActionCreatorThunk
  // Set maint mode in state
  enableMaintenanceMode(): NSAction
  // Un-set maint mode in state
  disableMaintenanceMode(): NSAction
  // Setup polling to watch for when we are out of maintenance mode
  pollForMaintenanceMode(): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  gotoMaintenanceMode,
  enableMaintenanceMode,
  disableMaintenanceMode,
  pollForMaintenanceMode,
}

function gotoMaintenanceMode(): ActionCreatorThunk {
  return dispatch => {
    dispatch(enableMaintenanceMode());
    dispatch(push(Urls.maintenanceModeUrl()));
    return Promise.resolve();
  }
}

function enableMaintenanceMode(): NSAction {
  return withNS({ type: ActionType.ENABLE_MAINTENANCE_MODE });
}

function disableMaintenanceMode(): NSAction {
  return withNS({ type: ActionType.DISABLE_MAINTENANCE_MODE });
}

function pollForMaintenanceMode(): ActionCreatorThunk {
  return (dispatch, getState) => {
    if (currentlyPolling(getState().maintenance)) {
      return Promise.resolve();
    }

    const interval = window.setInterval(
      () => {
        dispatch(checkIfWeAreBack()).then(
          isMaint => !isMaint && dispatch(stopPolling())
        );
      },
      15000
    )
    dispatch(withNS({ type: ActionType.BEGIN_POLLING, interval }));
    return Promise.resolve();
  }
}

function stopPolling(): ActionCreatorThunk {
  return (dispatch, getState) => {
    const interval = getState().maintenance.pollInterval;
    if (interval) {
      window.clearInterval(interval);
      dispatch(withNS({ type: ActionType.STOP_POLLING }));
    }
    return Promise.resolve();
  }
}

/**
 * Resolves "true" if we are back, false if we are still in maint mode
 */
function checkIfWeAreBack(): ActionCreatorThunkThen<boolean> {
  return (dispatch, _, { api }) =>
    api.maintenance.healthCheck().then(
      result => {
        if (Either.isRight(result)) {
          dispatch(disableMaintenanceMode());
          dispatch(push(Urls.rootUrl()));
          window.location.reload();
          return true;
        } else {
          dispatch(enableMaintenanceMode());
          return false;
        }
      },
      error => {
        dispatch(stopPolling());
        return Promise.reject(error);
      }
    );
}
