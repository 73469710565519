import * as React from 'react';
import Logo from 'Shared/UI/Logo';

const PostInstructions = () => {
  return (
    <div>
      <p>
        <strong className="onboard-instructions__why-how">Why? </strong>
        You can self-report to participate in this program.
      </p>
      <p>
        <strong className="onboard-instructions__why-how">How? </strong>
        Self-post by clicking or tapping
        the <Logo className="onboard-instructions__small-logo" /> next to any
        activity - try it below.
      </p>
    </div>
  );
};

export default PostInstructions;
