import * as React from 'react';
import { State, StoreDispatch } from 'Store';
import { defaultConnect } from 'Shared/ReduxComponent';
import { NavBarLayout } from 'Nav';
import * as Urls from 'Shared/Urls';
import { SEL, Appt, ApptType, ApptTypeId } from 'Appt/Data';
import { SEL as UserSEL, ActionCreators as UserAC } from 'User';

import { ActionCreators as NavAC } from 'Nav';
import { HUD } from 'HUD';
import { ActionCreators } from 'Appt/ActionCreator';
import FeatureGate from 'App/UI/FeatureGate';

import NewApptTypeSelect from 'Appt/UI/NewApptTypeSelect';
import TimezoneNotice from 'Appt/UI/TimezoneNotice';
import MyApptsList from 'Appt/UI/MyApptsList';

type Props = State & {
  dispatch: StoreDispatch
}

class ApptRoot extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(UserAC.loadCurrentUser());
    this.props.dispatch(ActionCreators.loadApptTypes());
    this.props.dispatch(ActionCreators.loadMyAppts());
  }

  myAppts(): Appt[] {
    return SEL.appts(this.props.appt);
  }

  apptTypes(): ApptType[] {
    return SEL.apptTypes(this.props.appt);
  }

  tzId(): string {
    return UserSEL.userTzId(this.props.currentUser);
  }

  gotoNewAppt = (apptTypeId: ApptTypeId) => {
    this.props.dispatch(
      NavAC.pushWithBreadcrumb(Urls.newApptUrl(apptTypeId))
    );
  }

  render() {
    return (
      <NavBarLayout title="appointments" initBreadcrumb={Urls.dashboardUrl()}>
        <div className="simple-container">
          <NewApptTypeSelect
            apptTypes={this.apptTypes()} gotoNewAppt={this.gotoNewAppt}/>
          <TimezoneNotice userZoneId={this.tzId()}/>
          <MyApptsList appts={this.myAppts()} userZoneId={this.tzId()} />
        </div>
        <HUD state={this.props.hud} />
      </NavBarLayout>
    );
  }
}

export default FeatureGate(
  defaultConnect(ApptRoot),
  ['appts']
);
