import { Specs } from 'Specs';
// import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';
import {
  NSType, Profile, Program, CrewMember, MinimalUser,
  ScreeningStatus, CurrentUserPayload
} from './Data';

export enum ActionType {
  LOAD_CURRENT_USER__START = 'LOAD_CURRENT_USER__START',
  LOAD_CURRENT_USER__SUCCESS = 'LOAD_CURRENT_USER__SUCCESS',
  LOAD_SPECS__SUCCESS = 'LOAD_SPECS__SUCCESS',
  LOAD_CREW__SUCCESS = 'LOAD_CREW__SUCCESS',
  SAVE_PROFILE__START = 'SAVE_PROFILE__START',
  SAVE_PROFILE__SUCCESS = 'SAVE_PROFILE__SUCCESS',
  CHANGE_PASSWORD__SUCCESS = 'CHANGE_PASSWORD__SUCCESS'
}

export type Action
  = LoadCurrentUser_Start
  | LoadCurrentUser_Success
  | LoadSpecs_Success
  | LoadCrew_Success
  | SaveProfile_Start
  | SaveProfile_Success
  | ChangePassword_Success;

export interface LoadCurrentUser_Success {
  NS: NSType,
  type: ActionType.LOAD_CURRENT_USER__SUCCESS,
  profile: Profile,
  program: Program,
  carePros: MinimalUser[],
  screeningStatus: ScreeningStatus,
  nativeAppSlug: string
}

export interface LoadCurrentUser_Start {
  NS: NSType,
  type: ActionType.LOAD_CURRENT_USER__START,
  loader: Promise<CurrentUserPayload>
}

export interface LoadSpecs_Success {
  NS: NSType,
  type: ActionType.LOAD_SPECS__SUCCESS,
  specs: Specs
}

export interface LoadCrew_Success {
  NS: NSType,
  type: ActionType.LOAD_CREW__SUCCESS,
  crew: CrewMember[]
}

export interface SaveProfile_Start {
  NS: NSType,
  type: ActionType.SAVE_PROFILE__START,
  profile: Profile
}

export interface SaveProfile_Success {
  NS: NSType,
  type: ActionType.SAVE_PROFILE__SUCCESS,
  profile: Profile
}

export interface ChangePassword_Success {
  NS: NSType,
  type: ActionType.CHANGE_PASSWORD__SUCCESS
}
