import * as React from 'react';
import * as R from 'ramda';
import * as moment from 'moment-timezone';
import { Line as LineChart } from 'react-chartjs-2';
import { ChartData, ChartOptions, ChartDataSets } from 'chart.js';
import * as chartjs from 'chart.js';

import * as List from 'misc/Data/List';
import * as M from 'misc/Data/Maybe';
import {
  TrendData, Dataset, DataPoint, DataRequest, TrendWindow, trendDataIsEmpty,
  formatValueWithMetricKey, MetricKey
} from '1bios/UserTrends/Data';

import NoContent from 'misc/UI/NoContent';

interface Props {
  data: TrendData,
  request: DataRequest
}

const BIOS_GREEN = '#97c21c';
const DATASET_COLORS = [
  '#8cb2c6',
  '#d7d7d7'
];
const BORDER_WIDTH = 2;
const POINT_RADIUS = 1;
const NO_DATA_TO_DISPLAY = (
  <NoContent className="trends-graph__no-data">No data to display</NoContent>
);

const TrendsGraph = (props: Props) => {
  const chartData = toChartData(props.data, props.request);
  const chartOptions = toChartOptions(props.data, props.request);

  return (
    <div className="trends-graph">
      <LineChart data={chartData} options={chartOptions} />
      {trendDataIsEmpty(props.data) && NO_DATA_TO_DISPLAY}
    </div>
  );
}

export default TrendsGraph;

function toChartOptions(data: TrendData, request: DataRequest): ChartOptions {
  const firstDs = List.maybeHead(data.datasets)
  const units = M.map(ds => ds.units, firstDs);
  const yAxisTicks = M.maybe(
    () => ({}),
    (ds: Dataset) => (
      {
        min: ds.min,
        max: ds.max,
        stepSize: ds.stepSize,
        callback: function(value: string, _index: number, _values: string[]) {
          return formatYAxisTicks(data, value);
        }
      }
    ),
    firstDs
  );
  return {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        generateLabels(chart) {
          return (chart.data.datasets || []).map((ds: any) => ({
            text: ds.label,
            fillStyle: ds.backgroundColor,
            strokeStyle: ds.backgroundColor,
            borderWidth: BORDER_WIDTH
          }));
        }
      }
    },
    animation: {
      duration: 500
    },
    tooltips: {
      mode: 'index',
      position: 'nearest',
      intersect: false,
      callbacks: {
        label(
          item: chartjs.ChartTooltipItem, chartData: ChartData
        ): string | string[] {
          const dsLabel =
            (chartData.datasets || [])[item.datasetIndex || 0].label;
          const valueLabel = formatValue(data, item.yLabel);
          return `${dsLabel}: ${valueLabel}`;
        },
        title: (points: chartjs.ChartTooltipItem[]) => {
          const point = points[0];
          if (point) {
            const time = moment(point.xLabel, 'MMM D, YYYY h:m:s a').clone();
            time.local();
            return time.format('MMM D h:mm A');
          } else {
            return '';
          }
        }
      }
    },
    scales: {
      xAxes: [
        {
          id: 'time-axis',
          type: 'time',
          ticks: {
            display: true,
            min: data.startDate.toDate(),
            max: data.endDate.toDate(),
          },
          scaleLabel: {
            display: false
          },
          time: {
            unit: timeScaleUnit(request.window),
            isoWeekday: true,
            displayFormats: {
              month: 'MMM',
              week: 'MMM D',
              day: 'MMM D'
            }
          },
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          ticks: yAxisTicks,
          scaleLabel: {
            display: M.maybe(() => false, () => true, units),
            labelString: M.fromMaybe('', units)
          },
          afterBuildTicks(scale) {
            const count = scale.ticks.length;
            const { min, max } = scale;
            const delta = (max - min) / (count - 1);
            scale.ticks = R.times(
              i => min + i * delta,
              count
            );
          }
        }
      ]
    }
  };
}

function toChartData(data: TrendData, request: DataRequest): ChartData {
  const datasets =
    data.datasets.map(toChartDataset(request)).concat(goalDatasets(data));
  return {
    labels: data.datasets.map(ds => ds.label),
    datasets
  }
}

const toChartDataset =
  (request: DataRequest) => (ds: Dataset, index: number): ChartDataSets => {
    const color = DATASET_COLORS[index % DATASET_COLORS.length];
    return {
      label: ds.label,
      data: downsample(ds.points, request.window).map(pt => (
        {
          x: pt.time.toDate(),
          y: pt.value
        }
      )),
      lineTension: 0,
      /* cubicInterpolationMode: 'monotone', */
      fill: false,
      xAxisID: 'time-axis',
      pointHitRadius: 3,
      borderColor: color,
      backgroundColor: color,
      borderWidth: BORDER_WIDTH,
      pointRadius: POINT_RADIUS
    };
  }

function goalDatasets(data: TrendData): ChartDataSets[] {
  return R.reject(
    R.isNil,
    data.datasets.map(ds => goalDataset(data, ds))
  ) as ChartDataSets[];
}

function goalDataset(
  data: TrendData, dataset: Dataset
): chartjs.ChartDataSets | undefined {
  if (dataset.goal) {
    return {
      label: `${dataset.label} goal: < ${dataset.goal}`,
      fill: false,
      data: [
        { x: data.startDate.toDate(), y: dataset.goal },
        { x: data.endDate.toDate(), y: dataset.goal }
      ],
      borderColor: BIOS_GREEN,
      backgroundColor: BIOS_GREEN,
      borderWidth: BORDER_WIDTH,
      pointRadius: POINT_RADIUS
    };
  }
}

function timeScaleUnit(window: TrendWindow): chartjs.TimeUnit {
  switch(window) {
    case TrendWindow.YEAR:
      return 'month';
    case TrendWindow.MONTH:
      return 'week';
    case TrendWindow.WEEK:
      return 'day';
  }
}

function formatValue(
  data: TrendData, yValue: string | number | undefined
): string {
  const maybeMetricKey = M.map(
    ds => ds.key, List.maybeHead(data.datasets)
  );

  return M.maybe(
    () => (yValue === undefined ? '' : yValue.toString()),
    key => formatValueWithMetricKey(key, yValue),
    maybeMetricKey
  );
}

function formatYAxisTicks(
  data: TrendData, val: string
): string {
  const maybeMetricKey = M.map(
    ds => ds.key, List.maybeHead(data.datasets)
  );

  return M.maybe(
    () => val,
    key => formatYAxisTickWithMetricKey(key, val),
    maybeMetricKey
  );
}

function formatYAxisTickWithMetricKey(key: MetricKey, val: string): string {
  switch(key) {
    case MetricKey.STEPS:
      return val.toLocaleString();
    default:
      return val;
  }
}

function downsample(points: DataPoint[], window: TrendWindow): DataPoint[] {
  // short circuit since we are not downsampling week view for now
  if (window === TrendWindow.WEEK) { return points; }
  const grouped = R.groupBy(downsampleGroupingFunction(window), points);
  return R.values(grouped).map(bucket => ({
    time: downsamplingTimeBucket(window, bucket[0].time),
    value: R.mean(bucket.map(p => p.value))
  }));
}

function downsampleGroupingFunction(
  window: TrendWindow
): (p: DataPoint) => string {
  return p => downsamplingTimeBucket(window, p.time).format();
}

function downsamplingTimeBucket(
  window: TrendWindow, time: moment.Moment
): moment.Moment {
  switch(window) {
    case TrendWindow.YEAR:
      return time.clone().startOf('day');
    case TrendWindow.MONTH:
      const b = 6; // buckets per day
      const hour = Math.floor(time.hour() / 24 * b) * (24 / b)
      return time.clone().startOf('day').hour(hour);
    case TrendWindow.WEEK:
      return time;
  }
}
