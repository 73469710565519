import * as React from 'react';

const InboxInstructions = () => {
  return (
    <div>
      <p>
        <strong className="onboard-instructions__why-how">Why? </strong>
        Stay in close touch with your care team.
      </p>
      <p>
        <strong className="onboard-instructions__why-how">How? </strong>
        Tap "MENU", then "MESSAGES" from anywhere.
      </p>
    </div>
  );
};

export default InboxInstructions;
