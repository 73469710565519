import * as React from 'react';
import './Spinner.scss';

interface Props {
  variant?: 'default' | 'hud' | 'dark-bg',
  wrap?: 'center'
}

export default function renderSpinner(props: Props): React.ReactElement<Props> {
  const variant = props.variant || 'default';
  const src = require(
    `images/ajax-loader-${variant}.gif`
  ).default;

  const classes = `spinner spinner--${variant}`;
  const spinner = <img className={classes} src={src} />;

  if (props.wrap) {
    return <div className="spinner__wrap--center">{spinner}</div>;
  } else {
    return spinner;
  }
}
