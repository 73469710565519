import * as React from 'react';

import {
  SpecType, SpecUpdate, specTypeLabel, specTypeUnitLabel
} from '../Data';

import { Status } from './SpecFormLayout';
import SpecFormLayout from './SpecFormLayout';

interface Props {
  spec: SpecType,
  value: number | undefined,
  submit: (update: SpecUpdate) => void
}

interface State {
  inputValue: string,
  status: Status,
  timeout?: number
}

class SingleValueSpecForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      inputValue: props.value ? props.value.toString() : '',
      status: 'default'
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.value !== prevProps.value ||
        this.props.spec !== prevProps.spec) {
      const inputValue = (this.props.value || '').toString();
      this.setState({ inputValue });
    }

    if (this.state.status === 'loading') {
      const timeout: number = window.setTimeout(
        () => this.setState({ ...this.state, status: 'default' }),
        3000
      );
      this.setState({ ...this.state, status: 'success', timeout })
    }
  }

  componentWillUnmount() {
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }
  }

  onChangeValue = (e: React.FormEvent<HTMLInputElement>) => {
    const el = e.target as HTMLInputElement;
    this.setState({ ...this.state, inputValue: el.value });
  }

  submit = () => {
    const newValue = parseValue(this.state.inputValue);
    if (validValue(newValue)) {
      this.setState({ ...this.state, status: 'loading' });
      this.props.submit({ spec: this.props.spec, value: newValue });
    }
  }

  submitIfChanged = () => {
    const newValue = parseValue(this.state.inputValue);
    if (validValue(newValue) && this.props.value !== newValue) {
      this.submit();
    }
  }

  render() {
    const spec = this.props.spec;
    const value = this.state.inputValue;

    return (
      <SpecFormLayout
        label={specTypeLabel(spec)}
        labelFor={`spec_${spec}`}
        status={this.state.status}
        onSubmit={this.submit}
      >
        <input type="text"
          value={value}
          id={`spec_${spec}`}
          className="measurement-form__text-input"
          placeholder={specTypeUnitLabel(spec)}
          onChange={this.onChangeValue}
          onBlur={this.submitIfChanged} />
      </SpecFormLayout>
    );
  }
}

export default SingleValueSpecForm;

function parseValue(inputVal: string): number {
  return parseFloat(inputVal);
}

function validValue(val: number): boolean {
  return !isNaN(val) && val >= 0;
}
