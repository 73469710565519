import * as Data from '../Data';
import { chunks, decodeHighByteInBack, decodeMoment } from './Util';

// Command response (can lengthen the command):
// 0x55 ID1 ID2 YY MM DD HH mm SS HH
// The minimum unit is 10 bytes, the total number of pieces of total data
// contained in a BLE packet can be dynamically controlled according to the
// actual maximum length of the transmitted packet.
// ID1 ID2: read the data number, high byte in the back.
// YY MM DD HH mm SS: stands for the date, year / month / day / hour / minute /
//   second.
// HH: heart rate value.
export function decode(packet: Uint8Array): Data.HR[] {
  return chunks(10, packet).map(chunk => {
    const id = decodeHighByteInBack(chunk.slice(1, 3))
    const time = decodeMoment(chunk.slice(3, 9));
    const heartRate = chunk[9]
    return {
      id,
      time,
      heartRate
    }
  });
}
