import * as R from 'ramda';

function isArray<T, U extends Object>(
  value: undefined | T[] | U
): value is T[] {
  return (value as Array<T>).length !== undefined;
}

function isObject<T, U extends Object>(
  value: undefined | T[] | U
): value is U {
  return (value as Array<T>).length === undefined;
}

export interface BEMFn {
  (block: string): EMFn
}

export interface EMFn {
  (element?: string):
  (modifiers?: (string | undefined)[] | { [k: string]: boolean }) =>
    string
}

const bem: BEMFn =
  (block: string) =>
  (element?: string) =>
  (modifiers?: (string | undefined)[] | { [k: string]: boolean }) => {
    const base = element ? `${block}__${element}` : block;

    if (modifiers === undefined) {
      return base;
    } else if (isObject(modifiers)) {
      // modifiers is object
      return R.append(
        base,
        R.reject(
          R.isNil,
          R.keys(modifiers).map(
            k => (modifiers as any)[k] ? `${base}--${k}` : null
          )
        )
      ).join(' ');
    } else if (isArray(modifiers)){
      // modifiers is array of strings and undefineds
      const nonNilModifiers = R.reject(R.isNil, modifiers || []);
      return R.append(
        base, (nonNilModifiers || []).map(m => `${base}--${m}`)
      ).join(' ');
    } else {
      return base;
    }
  }

export { bem };
