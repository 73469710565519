import * as React from 'react';
import * as qs from 'query-string';
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DispatchProps } from 'Store';
import { AuthSettings } from 'Auth/Data';
import { ActionCreators } from 'Auth/ActionCreator';


import Link from 'Shared/UI/Link';
import Icon from 'Shared/UI/Icon';
import AlertBox from 'Shared/UI/AlertBox';
import * as Urls from 'Shared/Urls';
import AuthPage from './AuthPage';
import ForgotPasswordForm from 'Auth/UI/ForgotPasswordForm';

type Props = DispatchProps & RouteComponentProps<URLParams>;

interface URLParams {
  programSlug?: string
}

interface State {
  notice: string | undefined
}

const SUCCESS_MESSAGE =
  'Thanks! We sent you an email with a link to reset your password.';

class ForgotPassword extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { notice: undefined };
  }

  submitForgotPassword = (email: string) => {
    return this.props.dispatch(ActionCreators.forgotPassword(email)).then(
      () => this.setState({ notice: SUCCESS_MESSAGE })
    );
  }

  codeIsExpired(): boolean {
    return qs.parse(this.props.location.search).code_expired;
  }

  codeIsInvalid(): boolean {
    return qs.parse(this.props.location.search).code_invalid;
  }

  render() {
    return (
      <AuthPage contents={this.renderForm} />
    );
  }

  renderForm = (_settings: AuthSettings) => {
    return (
      <div>
        {this.state.notice && this.renderNotice(this.state.notice)}
        <ForgotPasswordForm
          onSubmit={this.submitForgotPassword}
          codeExpired={this.codeIsExpired()}
          codeInvalid={this.codeIsInvalid()}
        />
        <p className="auth__note">
          Remembered? <Link to={Urls.loginUrl()}>Login</Link>
        </p>
      </div>
    );
  }

  renderNotice(message: string) {
    return (
      <AlertBox modifiers="success">
        <Icon name="check-circle-o" /> {message}
      </AlertBox>
    );
  }
}

export default withRouter(
  connect(null, dispatch => ({ dispatch }))(ForgotPassword)
);
