import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import { State, DispatchProps } from 'Store';
import { defaultConnect } from 'Shared/ReduxComponent';
import { Loadable, isLoaded } from 'misc/Data/Loadable';
import * as Urls from 'Shared/Urls';
import * as Dialog from 'Shared/Dialog'
import {
  ChallengeId,
  ChallengeDetails,
  Team,
  TeamId,
  LENSES,
  SEL,
  userIsEnrolled,
  teamsEnabled
} from 'Challenges/Data'
import { ActionCreators } from 'Challenges/ActionCreator';

import Spinner from 'Shared/UI/Spinner';
import Link, { LinkTarget } from 'Shared/UI/Link';
import { NavBarLayout } from 'Nav';
import { HUD } from 'HUD';

import TeamSelect from 'Challenges/UI/Teams/TeamSelect';
import GraphicHeader from 'Challenges/UI/GraphicHeader';

type Props = State & RouteComponentProps<URLParams> & DispatchProps;

interface URLParams {
  challengeId: string
}

class JoinTeam extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(
      ActionCreators.loadChallenge(this.challengeId())
    ).then(details => {
      if (!userIsEnrolled(details) || !teamsEnabled(details)) {
        this.props.dispatch(push(Urls.challengeUrl(this.challengeId())));
      } else {
        this.props.dispatch(ActionCreators.loadTeams(this.challengeId()));
      }
    });
  }

  selectTeamForJoin = (details: ChallengeDetails) => (teamId: TeamId) => {
    const currentTeamId = details.summary.myTeamId;
    const challengeId = details.summary.id;
    if (currentTeamId === teamId) {
      Dialog.alert('You are already on this team').then(() =>
        this.props.dispatch(push(Urls.challengeTeamUrl(challengeId, teamId)))
      );
    } else if (currentTeamId) {
      Dialog.alert(
        'You are already on a team. You would need to leave your current ' +
        'team to join a new one.'
      ).then(() =>
        this.props.dispatch(
          push(Urls.challengeTeamUrl(challengeId, currentTeamId))
        )
      );
    } else {
      Dialog.confirm_(
        'Are you sure you want to join this team?'
      ).then(() =>
        this.props.dispatch(ActionCreators.joinTeam(teamId))
      ).then(() =>
        this.props.dispatch(push(Urls.challengeTeamUrl(challengeId, teamId)))
      );
    }
  }

  gotoTeam(teamId: TeamId) {
    this.props.dispatch(
      push(Urls.challengeTeamUrl(this.challengeId(), teamId))
    );
  }

  challengeId(): ChallengeId {
    return parseInt(this.props.match.params.challengeId);
  }

  challengeDetails(): ChallengeDetails | undefined {
    return LENSES.challenge(this.challengeId()).get(this.props.challenges);
  }

  teams(): Loadable<Team[]> {
    return SEL.teams(this.challengeId(), this.props.challenges);
  }

  render() {
    const details = this.challengeDetails();
    const loadableTeams = this.teams();
    return (
      <NavBarLayout
        title="Challenge"
        initBreadcrumb={Urls.challengeUrl(this.challengeId())}>
        <HUD state={this.props.hud} />
        <div className="simple-container">
          {details && isLoaded(loadableTeams) ?
           this.renderContent(details, loadableTeams.value) :
           <Spinner wrap="center" />
          }
        </div>
      </NavBarLayout>
    );
  }

  renderContent(details: ChallengeDetails, teams: Team[]) {
    return (
      <div>
        <GraphicHeader challenge={details} />
        <h4 className="section-title">Join A Team</h4>
        {this.renderTeamSelect(details, teams)}
        {this.renderCreateTeamButton(details)}
      </div>
    )
  }

  renderTeamSelect(details: ChallengeDetails, teams: Team[]) {
    return (
      <div>
        <TeamSelect teams={teams} onSelect={this.selectTeamForJoin(details)} />
        {teams.length > 0 ? <p className="text-center">OR</p> : null}
      </div>
    )
  }

  renderCreateTeamButton(details: ChallengeDetails) {
    const classes = [
      'button',
      'challenge__action-button',
      'challenge__action-button--emphasize'
    ].join(' ');
    return (
      <Link
        to={this.createTeamAction(details)}
        className={classes}
      >
        create a new team
      </Link>
    );
  }

  createTeamAction(details: ChallengeDetails): LinkTarget {
    const teamId = details.summary.myTeamId;
    if (teamId) {
      return () => {
        Dialog.alert(
          'You are already on a team. You would need to leave your current ' +
          'team to create a new one.'
        ).then(() => this.gotoTeam(teamId));
      };
    } else {
      return Urls.challengeNewTeamUrl(this.challengeId());
    }
  }
}

export default withRouter(defaultConnect(JoinTeam));
