import './Points.scss';
import * as React from 'react';
import { bem } from 'css-util';

interface Props {
  children?: React.ReactNode,
  modifier?: string
}

const CSS = bem('points')();
const Points = (props: Props) => {
  return <span className={CSS([props.modifier])}>{props.children}</span>;
};

export default Points;
