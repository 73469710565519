import * as moment from 'moment-timezone';
import { decodeHighByteInBack, bcdDecode, chunks } from './Util';
import { DailyActivitySummary } from 'BLE/Protocols/JStyle1963YH/Data';

// Command response (can lengthen the command):
//  0x51
//  1 ID - 0-29 days, starting w/ 0 today going backwards
//  2 YY
//  3 MM
//  4 DD
//  5 S1 - 5-8 step count, high byte in back
//  6 S2
//  7 S3
//  8 S4
//  9 T1 - time of activity in seconds ("active minutes")
// 10 T2
// 11 T3
// 12 T4
// 13 D1 - distance in 0.01 km
// 14 D2
// 15 D3
// 16 D4
// 17 K1 - calories in 0.01KCAL
// 18 K2
// 19 K3
// 20 K4
// 21 DA - exercise target?
// 22 DB
// 23 F1 - intensive exercise minutes, in minutes
// 24 F2
// 25 F3
// 26 F4
export function decode(packet: Uint8Array): DailyActivitySummary[] {
  return chunks(27, packet).map(chunk => {
    const year = bcdDecode(chunk[2]) + 2000;
    const month = bcdDecode(chunk[3]) - 1;
    const day = bcdDecode(chunk[4]);

    return {
      id: chunk[1],
      date: moment([year, month, day]),
      stepCount: decodeHighByteInBack(chunk.slice(5, 9)),
      activeMinutes: decodeHighByteInBack(chunk.slice(9, 13)) / 60,
      distance: decodeHighByteInBack(chunk.slice(13, 17)) / 100,
      calories: decodeHighByteInBack(chunk.slice(17, 21)) / 100,
      intensiveMinutes: decodeHighByteInBack(chunk.slice(23, 26))
    };
  });
}
