import * as React from 'react';

import { defaultConnect } from 'Shared/ReduxComponent';
import * as Urls from 'Shared/Urls';

import Spinner from 'Shared/UI/Spinner';
import { HUD } from 'HUD';

import { State, StoreDispatch } from 'Store';
import { hudState } from 'App/Data';
import { Profile, ActionCreators as UserAC } from 'User';

import { NavBarLayout } from 'Nav';
import ProfileForm from '../UI/ProfileForm';
import ChangePasswordForm from '../UI/ChangePasswordForm';

type Props = State & {
  dispatch: StoreDispatch
}

class ProfilePage extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.saveProfile = this.saveProfile.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(UserAC.loadCurrentUser());
  }

  profile(): Profile | undefined {
    return this.props.currentUser.profile;
  }

  ready(): boolean {
    return !!this.profile();
  }

  saveProfile(profile: Profile, onError: (msg: string) => void) {
    this.props.dispatch(UserAC.saveProfile(profile, onError));
  }

  changePassword(password: string, onSuccess?: () => void) {
    this.props.dispatch(UserAC.changePassword(password, onSuccess));
  }

  render() {
    return (
      <NavBarLayout
        title="Profile"
        initBreadcrumb={Urls.dashboardUrl()}>
        {pageContent(this)}
        <HUD state={hudState(this.props)}></HUD>
      </NavBarLayout>
    );
  }
}

export default defaultConnect(ProfilePage);

function pageContent(el: ProfilePage) {
  const profile = el.profile();
  if (profile === undefined) {
    return <div className="mt-10"><Spinner wrap="center"></Spinner></div>;
  } else {
    return (
      <div className="settings-profile-page">
        <h4 className="section-title">Profile</h4>
        <ProfileForm
          initialProfile={profile}
          onSubmit={el.saveProfile}>
        </ProfileForm>
        <hr/>
        <h4 className="section-title">Change Password</h4>
        <ChangePasswordForm
          onSubmit={el.changePassword}>
        </ChangePasswordForm>
      </div>
    );
  }
}
