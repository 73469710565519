import * as moment from 'moment-timezone';

import {
  ApiClient, ApiEither, GET, POST, PUT, DELETE, OK, ApiUrl
} from 'Api';

import { makeApi as makeEdgeApi } from 'Api/Edge';

import { DateJSON, dateToJSON, ErrorMessageJSON } from './JSON/Shared';

import { TaskIdJSON } from './JSON/Tasks';

import {
  ChallengeInvitationJSON,
  ChallengeDetailsJSON,
  ChallengeSummaryJSON,
  ChallengeTemplateJSON,
  PlayerJSON,
  TaskChallengeDaySummaryJSON,
  TeamListResponseJSON,
  TeamResponseJSON,
  TeamPlayersResponseJSON,
  TeamFormDataJSON,
  TeamLeaderboardResponseJSON
} from './JSON/Challenges';

type ChallengeDetailsResponse = { challenge: ChallengeDetailsJSON };
type ChallengeInvitationsResponse = { invitations: ChallengeInvitationJSON[] };
type RulesResponse = { rules: string };
type TaskChallengeDayDetailResponse = {
  date: DateJSON,
  completed_task_ids: TaskIdJSON[],
  available_task_ids: TaskIdJSON[],
  locked: boolean
}
type TaskChallengeDailySummaryResponse = {
  day_summaries: TaskChallengeDaySummaryJSON[]
}

export interface TeamLeaderboardOpts {
  perPage?: number
  includeMyTeam?: boolean
}

export interface ChallengesAPI {
  getChallenges(): Promise<GetChallengesResponse>,

  getCompletedChallenges(page: number): Promise<GetCompletedChallengesResponse>,

  getChallenge(
    challengeId: number
  ): Promise<ChallengeDetailsResponse>,

  getLeaderboard(
    challengeId: number, page: number
  ): Promise<{ leaderboard: PlayerJSON[], has_more: boolean }>

  createChallenge(
    templateId: string
  ): Promise<ChallengeDetailsResponse>,

  joinChallenge(challengeId: number): OK,

  leaveChallenge(challengeId: number): OK,

  getInvitations(): Promise<ChallengeInvitationsResponse>,

  acceptInvitation(inviteId: string): OK,

  declineInvitation(inviteId: string): OK,

  getChallengeRules(challengeId: number): Promise<RulesResponse>,

  getTemplateRules(templateId: string): Promise<RulesResponse>

  addSteps(stepCount: number): OK

  inviteToChallenge(challengeId: number, userId: string): OK

  getTaskChallengeDayDetail(
    challengeId: number, date: moment.Moment
  ): Promise<TaskChallengeDayDetailResponse>,

  completeTaskForChallenge(
    challengeId: number, taskId: number, date: moment.Moment
  ): OK

  undoTaskForChallenge(
    challengeId: number, taskId: number, date: moment.Moment
  ): OK

  getTaskChallengeDailySummary(
    challengeId: number
  ): Promise<TaskChallengeDailySummaryResponse>,

  getTeamList(challengeId: number): Promise<TeamListResponseJSON>,

  getTeamPlayers(teamId: number): Promise<TeamPlayersResponseJSON>,

  createTeam(
    challengeId: number, data: TeamFormDataJSON
  ): Promise<TeamResponseJSON>,

  updateTeam(
    challengeId: number, teamId: number, data: TeamFormDataJSON
  ): Promise<ApiEither<ErrorMessageJSON, TeamResponseJSON>>,

  joinTeam(teamId: number): OK,
  leaveTeam(teamId: number): OK,

  getTeamLeaderboard(
    challengeId: number, page: number, opts?: TeamLeaderboardOpts
  ): Promise<TeamLeaderboardResponseJSON>
}

export interface GetChallengesResponse {
  enrolled: ChallengeSummaryJSON[],
  available: ChallengeSummaryJSON[],
  templates: ChallengeTemplateJSON[]
}

export interface GetCompletedChallengesResponse {
  completed: ChallengeSummaryJSON[],
  has_more_pages: boolean
}

/** Implementation **/

export function makeApi(client: ApiClient): ChallengesAPI {
  const edge = makeEdgeApi(client);

  return {
    getChallenges,
    getCompletedChallenges,
    getChallenge,
    getLeaderboard,
    createChallenge,
    joinChallenge,
    leaveChallenge,
    getInvitations,
    acceptInvitation,
    declineInvitation,
    getChallengeRules,
    getTemplateRules,
    addSteps,
    inviteToChallenge,
    getTaskChallengeDayDetail,
    completeTaskForChallenge,
    undoTaskForChallenge,
    getTaskChallengeDailySummary,
    getTeamList,
    getTeamPlayers,
    createTeam,
    updateTeam,
    joinTeam,
    leaveTeam,
    getTeamLeaderboard
  };

  function getChallenges(): Promise<GetChallengesResponse> {
    return client.requestJSON(GET, apiPath('/challenges'));
  }

  function getCompletedChallenges(
    page: number
  ): Promise<GetCompletedChallengesResponse> {
    return client.requestJSON(GET,
      apiPath(`/challenges/completed?page=${page}`));
  }

  function getChallenge(
    challengeId: number
  ): Promise<ChallengeDetailsResponse> {
    return client.requestJSON(GET, apiPath(`/challenges/${challengeId}`));
  }

  function getLeaderboard(
    challengeId: number, page: number
  ): Promise<{ leaderboard: PlayerJSON[], has_more: boolean }> {
    return client.requestJSON(
      GET,
      apiPath(`/challenges/${challengeId}/leaderboard?page=${page}`)
    );
  }

  function createChallenge(
    templateId: string
  ): Promise<ChallengeDetailsResponse> {
    return client.requestJSON(POST, apiPath(`/challenges`), { templateId });
  }

  function joinChallenge(challengeId: number): OK {
    return client.request(PUT, apiPath(`/challenges/${challengeId}/join`));
  }

  function leaveChallenge(challengeId: number): OK {
    return client.request(DELETE, apiPath(`/challenge_players/${challengeId}`));
  }

  function getInvitations(): Promise<ChallengeInvitationsResponse> {
    return client.requestJSON(GET, apiPath('/challenge_invitations'));
  }

  function acceptInvitation(inviteId: string): OK {
    return client.request(
      POST,
      apiPath(`/challenge_invitations/${inviteId}/accept`)
    );
  }

  function declineInvitation(inviteId: string): OK {
    return client.request(
      POST,
      apiPath(`/challenge_invitations/${inviteId}/deny`)
    );
  }

  function getChallengeRules(
    challengeId: number
  ): Promise<RulesResponse> {
    return client.requestJSON(
      GET, apiPath(`/challenges/${challengeId}/rules?type=challenge`)
    );
  }

  function getTemplateRules(templateId: string): Promise<RulesResponse> {
    return client.requestJSON(
      GET, apiPath(`/challenges/${templateId}/rules?type=template`)
    );
  }

  function addSteps(stepCount: number): OK {
    return edge.sendData(
      'self_report',
      { steps: [{ stepCount, time: moment().unix() }] },
      { append: true }
    );
  }

  function inviteToChallenge(challengeId: number, userId: string): OK {
    return client.requestJSON(POST, '/challenge_invitations', {
      challenge_id: challengeId,
      user_id: userId
    });
  }

  function getTaskChallengeDayDetail(
    challengeId: number, date: moment.Moment
  ): Promise<TaskChallengeDayDetailResponse> {
    return client.requestJSON(
      GET,
      apiPath(
        `/challenges/${challengeId}/task_challenge_day_detail`,
        { date: dateToJSON(date) }
      )
    );
  }

  function undoTaskForChallenge(
    challengeId: number, taskId: number, date: moment.Moment
  ): OK {
    return client.requestJSON(
      DELETE,
      apiPath(`/challenges/${challengeId}/undo_task`),
      { task_id: taskId, date: dateToJSON(date) }
    );
  }

  function completeTaskForChallenge(
    challengeId: number, taskId: number, date: moment.Moment
  ): OK {
    return client.requestJSON(
      POST,
      apiPath(`/challenges/${challengeId}/complete_task`),
      { task_id: taskId, date: dateToJSON(date) }
    );
  }

  function getTaskChallengeDailySummary(
    challengeId: number
  ): Promise<TaskChallengeDailySummaryResponse> {
    return client.requestJSON(
      GET,
      apiPath(`/challenges/${challengeId}/task_challenge_daily_summary`)
    );
  }

  function getTeamList(challengeId: number): Promise<TeamListResponseJSON> {
    return client.requestJSON(
      GET,
      { path: `/challenges/${challengeId}/teams`, version: 3 }
    );
  }

  function createTeam(
    challengeId: number, data: TeamFormDataJSON
  ): Promise<TeamResponseJSON> {
    return client.requestJSON(
      POST,
      apiPath(`/challenges/${challengeId}/teams`),
      { team: data }
    );
  }

  function updateTeam(
    challengeId: number, teamId: number, data: TeamFormDataJSON
  ): Promise<ApiEither<ErrorMessageJSON, TeamResponseJSON>> {
    return client.eitherJSON(
      PUT,
      apiPath(`/challenges/${challengeId}/teams/${teamId}`),
      { team: data }
    );
  }

  function joinTeam(teamId: number): OK {
    return client.requestJSON(
      POST,
      apiPath(`/challenge_teams/${teamId}/join_team`)
    );
  }

  function leaveTeam(teamId: number): OK {
    return client.requestJSON(
      DELETE,
      apiPath(`/challenge_teams/${teamId}/leave_team`)
    );
  }

  function getTeamPlayers(teamId: number): Promise<TeamPlayersResponseJSON> {
    return client.requestJSON(
      GET, apiPath(`/challenge_teams/${teamId}`)
    );
  }

  type PagingQuery = {
    page: number
    per_page?: number
    include_my_team?: 'true' | 'false'
  }

  function getTeamLeaderboard(
    challengeId: number, page: number, opts?: TeamLeaderboardOpts
  ): Promise<TeamLeaderboardResponseJSON> {
    opts = opts || {};
    let query: PagingQuery = { page };
    if (opts.perPage) { query.per_page = opts.perPage; }
    if (opts.includeMyTeam) { query.include_my_team = 'true'; }
    return client.requestJSON(
      GET,
      apiPath(
        `/challenges/${challengeId}/leaderboard/teams`,
        query
      )
    );
  }

  function apiPath(path: string, query?: Object): ApiUrl {
    return { path, version: 3, query };
  }
}
