import { UserDashboardJSON, MyDashboardJSON } from 'Api/JSON/Dashboard';
import { minimalUserFromJSON, screeningStatusFromJSON,
  inboxStatusFromJSON } from 'User/JSON';
import { reportListItemFromJSON } from 'Screenings/JSON';
import { latestBiopostFromJSON } from 'Post/JSON';
import { activeGoalFromJSON } from 'Goals/JSON';
import { challengeSummary, challengeInvitation } from 'Challenges/JSON';
import { rewardPlanFromJSON } from 'Rewards/JSON';
import { dailyStatusFromJSON } from 'CarePlans/JSON';
import { UserDashboard, MyDashboard } from './Data';

export function userDashboardFromJSON(json: UserDashboardJSON): UserDashboard {
  return {
    user: minimalUserFromJSON(json.user),
    lastPost: json.last_post && latestBiopostFromJSON(json.last_post),
    activeGoal: json.active_goal && activeGoalFromJSON(json.active_goal),
    challenge: json.challenge && challengeSummary(json.challenge)
  };
}

export function myDashboardFromJSON(json: MyDashboardJSON): MyDashboard {
  const userDashboard = userDashboardFromJSON(json);
  const lastScreening =
    json.last_screening && reportListItemFromJSON(json.last_screening);
  return {
      ...userDashboard,
    lastScreening,
    crewRequests: json.crew_requests.map(minimalUserFromJSON),
    challengeInvitations: json.challenge_invitations.map(challengeInvitation),
    screeningStatus: screeningStatusFromJSON(json.has_active_screening),
    inboxStatus: inboxStatusFromJSON(json.has_unread_messages),
    rewardPlan: json.reward_plan ?
      rewardPlanFromJSON(json.reward_plan) : undefined,
    carePlanStatus: json.care_plan_status ?
      dailyStatusFromJSON(json.care_plan_status) : undefined
  };
}
