import { GoalJSON, GoalStatusJSON, ActiveGoalJSON } from 'Api/JSON/Goals';
import { expandedSpecTypeFromJSON } from 'Specs';
import { Goal, GoalStatus, ActiveGoal } from './Data'

export function activeGoalFromJSON(json: ActiveGoalJSON): ActiveGoal {
  return {
    goal: goalFromJSON(json.goal),
    status: goalStatusFromJSON(json.status)
  };
}

export function goalFromJSON(json: GoalJSON): Goal {
  return {
    id: json.id,
    name: json.name,
    details: json.details || '',
    specTypes: json.spec_types.map(expandedSpecTypeFromJSON),
    edgeTypes: json.edge_types
  };
}

export function goalStatusFromJSON(json: GoalStatusJSON): GoalStatus {
  return {
    goalId: json.goal_id,
    postCount: json.biopost_count,
    summary: json.summary,
    days: json.days,
    scoreChange: json.score_change
  };
}
