import {
  NSType,
  NotificationSettings,
  FatlineAlert,
  FatlineTransmitter,
  DailyCoachSettings
} from './Data';

export enum ActionType {
  LOAD_NOTIFICATION_SETTINGS__BEGIN,
  LOAD_NOTIFICATION_SETTINGS__SUCCESS,
  TOGGLE_FATLINE_ALERT__BEGIN,
  TOGGLE_FATLINE_ALERT__SUCCESS,
  UPDATE_DAILY_COACH__BEGIN,
  UPDATE_DAILY_COACH__SUCCESS
};

export type Action
  = LoadNotificationSettings_Begin
  | LoadNotificationSettings_Success
  | ToggleFatlineAlert_Begin
  | ToggleFatlineAlert_Success
  | UpdateDailyCoach_Begin
  | UpdateDailyCoach_Success;

export interface LoadNotificationSettings_Begin {
  NS: NSType,
  type: ActionType.LOAD_NOTIFICATION_SETTINGS__BEGIN
}

export interface LoadNotificationSettings_Success {
  NS: NSType,
  type: ActionType.LOAD_NOTIFICATION_SETTINGS__SUCCESS,
  settings: NotificationSettings
}

export interface ToggleFatlineAlert_Begin {
  NS: NSType,
  type: ActionType.TOGGLE_FATLINE_ALERT__BEGIN,
  alert: FatlineAlert,
  transmitter: FatlineTransmitter
}

export interface ToggleFatlineAlert_Success {
  NS: NSType,
  type: ActionType.TOGGLE_FATLINE_ALERT__SUCCESS,
  alert: FatlineAlert,
  transmitter: FatlineTransmitter
}

export interface UpdateDailyCoach_Begin {
  NS: NSType,
  type: ActionType.UPDATE_DAILY_COACH__BEGIN,
  dailyCoach: DailyCoachSettings
}

export interface UpdateDailyCoach_Success {
  NS: NSType,
  type: ActionType.UPDATE_DAILY_COACH__SUCCESS,
  dailyCoach: DailyCoachSettings
}
