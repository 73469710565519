import * as moment from 'moment-timezone';
import * as R from 'ramda';

export function lsbDecode(data: Uint8Array): number {
  return data.reduce(
    (sum, byte, i) => sum + (byte << (i * 8)),
    0
  );
}

/**
 * Encode a number as a byte array with least significant bytes first.
 * Ex:
 * lsbEncode(0x9f123) => Uint8Array[0x23, 0xf1, 0x09]
 * lsbEncode(0x9f123, 4) => Uint8Array[0x23, 0xf1, 0x09, 0x00]
 */
export function lsbEncode(value: number, zeroPad=0): Uint8Array {
  const byteLength = Math.ceil(Math.log2(value) / 8);
  const bufferLength = Math.max(byteLength, zeroPad);
  let result = new Uint8Array(bufferLength);
  for (let i = 0; i < byteLength; i += 1) {
    result.set([(value >> (8 * i)) & 0xff], i)
  }
  return result;
}

/**
 * Encode a number as a byte array with most-significant bytes first.
 *
 * Ex:
 * msbEncode(0x521f) => Uint8Array[0x52, 0x1f]
 * msbEncode(0x521f, 3) => Uint8Array[0x00, 0x52, 0x1f]
 */
export function msbEncode(value: number, zeroPad=0): Uint8Array {
  const lsb = lsbEncode(value, zeroPad);
  let result = new Uint8Array(lsb.length);
  for (let i = 0; i < lsb.length; i += 1) {
    result.set([lsb[lsb.length - i - 1]], i);
  }
  return result;
}

/**
 * Decode array of bytes with most-significant byte first into a number
 * Ex:
 * msbDecode(Uint8Array.of(0xd5, 0x32)) === 0xd532
 */
export function msbDecode(data: Uint8Array): number {
  return data.reduce(
    (sum, byte, i) => {
      const val = byte << ((data.length - i - 1) * 8)
      return sum + val;
    },
    0
  )
}

/**
 * Decodes binary-encoded decimal number, for example:
 * bcdDecode(0x54) === 54
 */
export function bcdDecode(value: number): number {
  return parseInt(value.toString(16));
}

/**
 * Encodes binary-encoded decimal, for example:
 * bcdEncode(72) === 0x72 === 114
 */
export function bcdEncode(value: number): number {
  return parseInt(value.toString(), 16);
}

export function chunks(size: number, packet: Uint8Array): Uint8Array[] {
  return R.times(
    i => packet.slice(i * size, i * size + size),
    Math.floor(packet.length / size)
  );
}

export function decodeMoment(data: Uint8Array): moment.Moment {
  const decoded = data.map(bcdDecode);
  const year = decoded[0] + 2000; // convert to full year
  const month = decoded[1] - 1; // convert to 0-index
  const day = decoded[2];
  const hour = decoded[3];
  const minute = decoded[4];
  const second = decoded[5];

  return moment([year, month, day, hour, minute, second]);
}

export function stringFromBytes(bytes: Uint8Array): string {
  if (window['TextDecoder'] !== undefined) {
    const d = new TextDecoder();
    return d.decode(bytes);
  } else {
    return Array.from(bytes).map(i => String.fromCodePoint(i)).join('');
  }
}

export function equalBytes(b1: Uint8Array, b2: Uint8Array): boolean {
  if (b1.length !== b2.length) { return false; }
  for (let i = 0; i < b1.length; i += 1) {
    if (b1[i] !== b2[i]) { return false; }
  }
  return true;
}

export function concatBytes(...byteArrays: Uint8Array[]): Uint8Array {
  const length = R.sum(byteArrays.map(i => i.length));
  let result = new Uint8Array(length);
  let offset = 0;
  byteArrays.forEach(ary => {
    result.set(ary, offset);
    offset += ary.length;
  })
  return result;
}
