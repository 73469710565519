import { Location } from 'history';
import { withNS as originalWithNS, WithNS } from 'Shared/NSAction';
import { NS, NSType } from './Data';

export enum ActionType {
  GO_ONLINE = 'GO_ONLINE',
  GO_OFFLINE = 'GO_OFFLINE'
}

export type Action
  = GoOnline
  | GoOffline;

interface GoOnline {
  type: ActionType.GO_ONLINE
}

interface GoOffline {
  type: ActionType.GO_OFFLINE,
  currentLocation: Location | undefined
}

export type NSAction = WithNS<NSType, Action>;
export const withNS = originalWithNS<NSType, Action>(NS);
