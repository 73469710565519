import * as React from 'react';

import HorizontalFormField from './HorizontalFormField';
import Label from './HorizontalFieldLabel';

type Props = {
  label: React.ReactNode,
  twoLineLabel?: boolean,
  children?: React.ReactNode
}

function renderHorizontalGenericField(props: Props): React.ReactElement<Props> {
  const label = (
    <Label content={props.label} twoLine={props.twoLineLabel}></Label>
  );

  return (
    <HorizontalFormField label={label} field={props.children}/>
  )
}

export default renderHorizontalGenericField;
