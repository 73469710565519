import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { ActionCreators } from 'Challenges/ActionCreator';
import { push } from 'connected-react-router';

import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { challengesUrl, completedChallengesUrl } from 'Shared/Urls';
import Spinner from 'Shared/UI/Spinner';
import { Paging } from 'Shared/Paging';
import { isLoaded } from 'misc/Data/Loadable';
import Pager from 'Shared/UI/Pager';
import TileList from 'Challenges/UI/TileList';
import SectionTitle from 'Challenges/UI/SectionTitle';

import { NavBarLayout } from 'Nav';

import { State, StoreDispatch } from 'Store';

type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch
}

type URLParams = {
  page: string
}

class CompletedChallenges extends React.Component<Props, {}> {

  componentDidMount() {
    this.loadCompletedChallenges();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.page(prevProps) !== this.page()) {
      this.loadCompletedChallenges();
    }
  }

  loadCompletedChallenges() {
    this.props.dispatch(
      ActionCreators.loadCompletedChallenges(this.page())
    );
  }

  gotoPage = (page: number) => {
    this.props.dispatch(
      push(completedChallengesUrl(page))
    );
  }

  hasMorePages(): boolean {
    return this.props.challenges.completed.hasMorePages;
  }

  page(props?: Props): number {
    if (props === undefined) { props = this.props; }
    return parseInt(props.match.params.page);
  }

  paging(): Paging {
    const currentPage = this.page();
    return {
      currentPage,
      nextPage: this.hasMorePages() ? currentPage + 1 : undefined,
      previousPage: currentPage === 1 ? undefined : currentPage - 1
    };
  }

  render() {
    const completed = this.props.challenges.completed.challenges;
    const paging = this.paging();

    return (
      <NavBarLayout title="Challenges"
                    initBreadcrumb={challengesUrl()}
                    className="my-challenges">
        <SectionTitle title='Complete' />
        {isLoaded(completed) ?
          <div>
            <TileList
              challenges={completed.value}
              noDataMessage={'no completed challenges in the last 2 weeks'}
            />
            <Pager
              className="completed-challenges__pager"
              nextPage={paging.nextPage}
              nextLabel="older"
              previousPage={paging.previousPage}
              previousLabel="newer"
              gotoPage={this.gotoPage}
              key="pager"
            />
          </div>
          :
          <Spinner wrap="center" />
        }
      </NavBarLayout>
    );
  }
}

export default withRouter(defaultConnect(CompletedChallenges));
