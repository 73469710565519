import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as Urls from 'Shared/Urls';
import { State, DispatchProps } from 'Store';
import { isLoaded } from 'misc/Data/Loadable';
import {
  ActionCreators as UserAC,
  SEL as UserSEL,
  AppFeatureKey,
  featuresEnabled,
  CurrentUser
} from 'User';


type ConnectedProps = {
  currentUser: CurrentUser
}

type OwnProps = {}

type FeatureGateProps = ConnectedProps & DispatchProps & OwnProps

function featureGate<P>(
  Component: React.ComponentType<P>, requiredFeatures: AppFeatureKey[]
) {
  const Wrapped = class extends React.Component<P & FeatureGateProps> {
    componentDidMount() {
      this.props.dispatch(UserAC.loadCurrentUser());
      this.checkRequiredFeatures();
    }

    componentDidUpdate() {
      this.checkRequiredFeatures();
    }

    checkRequiredFeatures() {
      const appFeatures = UserSEL.appFeatures(this.props.currentUser);
      if (isLoaded(appFeatures) &&
          !featuresEnabled(appFeatures.value, requiredFeatures)
      ) {
        this.props.dispatch(push(Urls.rootUrl()));
      }
    }

    render() {
      return <Component {...this.props}/>;
    }
  };

  return connect<ConnectedProps, DispatchProps, OwnProps, State>(
    ({ currentUser }) => ({ currentUser })
  )(Wrapped);
}

export default featureGate;
