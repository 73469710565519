import { Prism } from '@atomic-object/lenses';
import {
  IDMap, newIDMap, idMapInsert, idMapLookup
} from 'Shared/Data/IDMap';
import { HTML } from 'Shared/Data/HTML';


export type NSType = 'CMS';
export const NS: NSType = 'CMS';

export { HTML };

export interface State {
  // Maps URLs to HTML
  pages: IDMap<Page>
}

export interface Page {
  body: HTML,
  title: string
}

export function initialState(): State {
  return { pages: newIDMap() };
}

const pageLens = (url: string) =>
  Prism.of<State, Page>({
    get: (s: State) => getContent(url, s),
    set: (s: State, p: Page) => setContent(url, p, s)
  });

function setContent(url: string, page: Page, state: State): State {
  return {
      ...state,
    pages: idMapInsert(url, page, state.pages) as IDMap<Page>
  };
}

function getContent(url: string, state: State): Page | undefined {
  return idMapLookup(url, state.pages);
}

export const LENS = { page: pageLens };
