import { Location } from 'history';

export type NSType = 'NETWORK';
export const NS: NSType = 'NETWORK';

export interface State {
  isOffline: boolean
  previousLocation: Location | undefined
}

export function initialState(): State {
  return { isOffline: false, previousLocation: undefined };
}

export const SEL = {
  isOffline(state: State): boolean {
    return state.isOffline;
  },

  isOnline(state: State): boolean {
    return !SEL.isOffline(state);
  }
};
