import * as React from 'react';
import * as BLE from 'BLE/Data';
import * as Version from 'Shared/Data/Version';

interface Props {
  firmwareStatus: BLE.FirmwareStatus | undefined,
  upgrade: () => void,
  refresh: () => void
}

const FirmwareUpdate = (props: Props) => {
  const status = props.firmwareStatus;
  if (status) {
    if (status.currentVersion === undefined) {
      return renderUnknownCurrentVersion(props, status);
    } else if (BLE.needsUpgrade(status)) {
      return renderNeedsUpgrade(props, status);
    } else {
      return renderUpToDate(props, status);
    }
  } else {
    return <></>;
  }
}

export default FirmwareUpdate;

// private

function container(...content: React.ReactNode[]) {
  return (
    <div className="simple-container">
      {content}
    </div>
  );
}

function renderUnknownCurrentVersion(
  props: Props, status: BLE.FirmwareStatus
) {
  return container(
    renderCurrentVersion(status),
    renderLatestVersion(status),
    upgradeButton(props, 'upgrade anyway'),
    refreshButton(props)
  );
}

function renderUpToDate(props: Props, status: BLE.FirmwareStatus) {
  return container(
    renderCurrentVersion(status),
    <p>You are all up to date!</p>,
    upgradeButton(props, 'upgrade anyway'),
    refreshButton(props)
  );
}

function renderNeedsUpgrade(props: Props, status: BLE.FirmwareStatus) {
  return container(
    renderCurrentVersion(status),
    renderLatestVersion(status),
    upgradeButton(props, 'upgrade now'),
    refreshButton(props)
  );
}

function renderCurrentVersion(status: BLE.FirmwareStatus) {
  return (
    <p>
      Current Version:{' '}
      {status.currentVersion ?
       Version.display(status.currentVersion) :
       `Could not determine device's current version.`
      }
    </p>
  );
}

function renderLatestVersion(status: BLE.FirmwareStatus) {
  if (status.latestVersion) {
    return (
      <p>Latest Version: {Version.display(status.latestVersion)}</p>
    );
  }
}

function upgradeButton(props: Props, label: string) {
  if (props.firmwareStatus && props.firmwareStatus.latestVersion) {
    return (
      <p>
        <button className="button button-fixed-width theme__primary-bg"
                onClick={performUpgradeHandler(props)}>
          {label}
        </button>
      </p>
    );
  }
}

function refreshButton(props: Props) {
  return (
    <p>
      <button className="button button-fixed-width theme__primary-bg"
              onClick={props.refresh}>
        refresh
      </button>
    </p>
  );
}

function performUpgradeHandler(props: Props) {
  return (e: React.MouseEvent<any>) => {
    e.preventDefault();
    props.upgrade();
  }
}
