import * as EventEmitter from 'eventemitter3';
import * as BLE from 'BLE/Data';
import { DeviceAccess, makeDeviceAccess } from 'BLE/DeviceAccess';
import {
  SERVICE_UUID, CONTROL_UUID, PACKET_UUID,
  Op, OpName, Result, ObjectType, SelectData, CRCData, MTUData, VersionData
} from './Data';
import { lsbDecode } from 'BLE/Util';
import CommandBuilder from './CommandBuilder';
import * as Timeout from 'Shared/Timeout';
import { isAndroid } from 'Cordova';
import { makeLogger, Logger } from 'Shared/Log';

const PACKET_SIZE = 40;

export const RESPONSE = 0x60;

interface ProtocolInterface {
  close(): Promise<void>,
  select(type: ObjectType): Promise<SelectData>,
  create(type: ObjectType, size: number): Promise<void>
  crc(): Promise<CRCData>,
  abort(): Promise<void>,
  execute(): Promise<void>,
  mtu(): Promise<MTUData>,
  version(): Promise<VersionData>,
  sendData(
    data: Uint8Array,
    progress?: (percent: number) => void
  ): Promise<void>
}

class Protocol implements ProtocolInterface {
  private readonly events: EventEmitter<OpName>
  private readonly commands: CommandBuilder;
  private access: DeviceAccess | undefined;
  private readonly log: Logger;

  constructor(
    private readonly device: BLE.Device,
    private readonly bluetooth: BluetoothlePlugin.Bluetoothle
  ) {
    this.events = new EventEmitter();
    this.commands = new CommandBuilder();
    this.log = makeLogger('NordicDFU::Protocol', 'BLUETOOTH_LOG');
  }

  private async init(): Promise<DeviceAccess> {
    if (this.access !== undefined) { return this.access; }
    this.access = makeDeviceAccess(this.bluetooth, this.device);
    await this.access.withConnection();
    await this.access.withDiscovery();
    if (isAndroid()) {
      await this.access.requestMTU(PACKET_SIZE + 10);
      await this.access.requestConnectionPriority('high');
    }
    await this.access.subscribe(SERVICE_UUID, CONTROL_UUID, packet => {
      this.log('Received packet from CONTROL characteristic:', packet);
      if (packet[0] != RESPONSE) {
        this.log.warn('Unexpected op on response:', packet);
      } else if (packet[2] != Result.SUCCESS) {
        this.log.warn('Non success result:', packet);
      } else {
        let maxSize, offset, crc32, mtu;
        switch(packet[1]) {
          case Op.SELECT:
            maxSize = lsbDecode(packet.slice(3, 7));
            offset = lsbDecode(packet.slice(7, 11));
            crc32 = lsbDecode(packet.slice(11, 15));
            this.events.emit(OpName.SELECT, { maxSize, offset, crc32 });
            break;
          case Op.CREATE:
            this.events.emit(OpName.CREATE);
            break;
          case Op.CRC:
            offset = lsbDecode(packet.slice(3, 7));
            crc32 = lsbDecode(packet.slice(7, 11));
            this.events.emit(OpName.CRC, { offset, crc32 });
            break;
          case Op.EXECUTE:
            this.events.emit(OpName.EXECUTE);
            break;
          case Op.ABORT:
            this.events.emit(OpName.ABORT);
            break;
          case Op.MTU:
            mtu = lsbDecode(packet.slice(3, 7));
            this.events.emit(OpName.MTU, { mtu });
            break;
          case Op.PROTOCOL_VERSION:
            break;
        }
      }
    });
    return this.access;
  }

  public async close() {
    if (!this.access) { return; }
    await this.access.unsubscribe(SERVICE_UUID, CONTROL_UUID)
      .catch(console.warn);
    await this.access.disconnect().catch(console.warn);
    this.access = undefined;
  }

  public async select(type: ObjectType): Promise<SelectData> {
    const access = await this.init();
    return this.sendControl(access, this.commands.select(type), OpName.SELECT);
  }

  public async create(type: ObjectType, size: number): Promise<void> {
    const access = await this.init();
    return this.sendControl(
      access, this.commands.create(type, size), OpName.CREATE
    );
  }

  public async execute(): Promise<void> {
    const access = await this.init();
    return this.sendControl(access, this.commands.execute(), OpName.EXECUTE);
  }

  public async crc(): Promise<CRCData> {
    const access = await this.init();
    return this.sendControl(access, this.commands.crc(), OpName.CRC);
  }

  public async mtu(): Promise<MTUData> {
    const access = await this.init();
    return this.sendControl(access, this.commands.mtu(), OpName.MTU);
  }

  public async version(): Promise<VersionData> {
    const access = await this.init();
    return this.sendControl(
      access, this.commands.protocolVersion(), OpName.PROTOCOL_VERSION
    );
  }

  public async abort(): Promise<void> {
    const access = await this.init();
    return this.sendControl(access, this.commands.abort(), OpName.ABORT);
  }

  public async sendData(
    data: Uint8Array,
    onProgress?: (percent: number) => void
  ): Promise<void> {
    const access = await this.init();
    await access.silenceLogsIn(async () => {
      for (let i = 0; i < data.length; i += PACKET_SIZE) {
        const end = i + PACKET_SIZE;
        let packet: Uint8Array = data.slice(i, end);
        await access.writeWithoutResponse(SERVICE_UUID, PACKET_UUID, packet);
        onProgress && onProgress(i / data.length);
        // delay between packets
        await Timeout.sleep(20);
      }
    });
  }

  private async sendControl<R>(
    access: DeviceAccess, command: Uint8Array, event: OpName
  ): Promise<R> {
    this.log('Sending CONTROL operation:', command, event);
    access.write(SERVICE_UUID, CONTROL_UUID, command);
    const result: R = await this.waitFor(event);
    this.log('Recevied CONTROL result:', result);
    return result;
  }

  private async waitFor<R>(event: OpName): Promise<R> {
    return Timeout.timeout(
      10000,
      done => this.events.once(event, done)
    );
  }
}

export default Protocol;
