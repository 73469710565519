import * as React from 'react';

interface Props {
  title: string
}

export default function SectionTitle(props: Props): React.ReactElement<Props> {
  return (
    <h4 className="section-title my-challenges__section-title">
      {props.title}
    </h4>
  );
}
