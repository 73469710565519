export interface Paging {
  currentPage: number
  nextPage: number | null
  previousPage: number | null
}

export interface PagingJSON {
  current_page: number,
  next_page: number | null,
  previous_page: number | null
}


export function pagingFromJSON(json: PagingJSON): Paging {
  return {
    currentPage: json.current_page,
    nextPage: json.next_page,
    previousPage: json.previous_page
  };
}

export function pagingFromArray<T>(
  array: T[], currentPage: number, perPage: number = 50
): Paging {
  const pageCount = Math.ceil(array.length / perPage);
  return {
    currentPage: currentPage,
    nextPage: nextPage(currentPage, pageCount),
    previousPage: previousPage(currentPage)
  };
}

function nextPage(currentPage: number, pageCount: number): number | null {
  return currentPage === pageCount ? null : currentPage + 1;
}

function previousPage(currentPage: number): number | null {
  return currentPage === 1 ? null : currentPage - 1;
}
