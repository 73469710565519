import { Version, version } from 'Shared/Data/Version';
import { bcdDecode } from './Util'
/**
Command response:
Check right and execute OK, then return:
0x27 AA BB CC DD EE FF GG 00 00 00 00 00 ID CRC
Check error and execute Fail, then return:
0xA7 00 00 00 00 00 00 00 00 00 00 00 00 00 00 CRC
Description:
AA BB CC DD are software version number (in Hexadecimal BCD code format).
EE FF GG are time.
**/

export function decode(packet: Uint8Array): Version {
  return version(Array.from(packet.slice(1, 8).map(bcdDecode)));
}
