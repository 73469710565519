import * as React from 'react';
import { NavBarLayout } from 'Nav';
import ProgressBar from 'Shared/UI/ProgressBar';

type Props = {};

class OnboardComplete extends React.Component<Props, {}> {
  render() {
    return (
      <NavBarLayout title="Onboard">
        <div className="simple-container">
          <div className="onboard-instructions">
            <h3 className="text-center">
              Nice, you're onboard!
            </h3>

            <ProgressBar percent={1} />

            <p className="no-content">Next: click or tap "menu"</p>
          </div>
        </div>
      </NavBarLayout>
    );
  }
}

export default OnboardComplete;
