import * as React from 'react';
import * as classnames from 'classnames';
import Spinner from 'Shared/UI/Spinner';
import Icon from 'Shared/UI/Icon';

export type Status = 'default' | 'loading' | 'success';

interface Props {
  label: string,
  labelFor?: string,
  children?: React.ReactNode,
  status: Status,
  onSubmit?: (e: React.MouseEvent<HTMLButtonElement>) => void
};

export default function renderSpecFormLayout(
  props: Props
): React.ReactElement<Props> {
  const { label, labelFor, children } = props;
  const labelClass = classnames(
    'measurement-form__label',
    label.length > 15 ?
    'measurement-form__label--two-line' : 'measurement-form__label--one-line'
  );

  return (
    <div className="measurement-form">
      <div className="measurement-form__left">
        <label className={labelClass}
          htmlFor={labelFor}>
          {label}
        </label>
      </div>
      <div className="measurement-form__middle">
        {children}
      </div>

      <div className="measurement-form__right">
        {renderButton(props)}
      </div>
    </div>
  );
}

function renderButton(props: Props): React.ReactNode {
  if (props.onSubmit) {
    const classes = classnames(
      'button', 'button-gray', 'measurement-form__submit-button',
      `measurement-form__submit-button--${props.status}`
    );
    return (
      <button className={classes}
        disabled={props.status !== 'default'}
        onClick={props.onSubmit}
        tabIndex={-1}
      >
        {buttonContent(props.status)}
      </button>
    );
  }
}

function buttonContent(status: Status): React.ReactNode {
  switch(status) {
    case 'default':
      return 'update';
    case 'loading':
      return <Spinner variant="dark-bg"></Spinner>;
    case 'success':
      return (
        <span className="measurement-form__button-success-label">
          <Icon name="check-circle-o"></Icon>
        </span>
      );
  }
}
