import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { defaultConnect } from 'Shared/ReduxComponent';
import { State, StoreDispatch } from 'Store';
import { NavBarLayout } from 'Nav';
import * as Urls from 'Shared/Urls';
import {
  Loadable, isLoaded, loadableMap
} from 'misc/Data/Loadable';

import { SEL, UserDashboard } from 'Dashboard/Data';
import { ActionCreators } from 'Dashboard/ActionCreator';

import { ActionCreators as NavAC } from 'Nav';
import { ActionCreators as CommunityAC } from 'Community';
import { HUD } from 'HUD';

import Spinner from 'Shared/UI/Spinner';
import {
  SEL as UserSEL,
  CrewMember,
  Profile,
  UserId,
  ActionCreators as UserAC,
  userName,
  AppFeatures, AppFeatureKey, loadableFeaturesEnabled,
  withLoadableFeatures
} from 'User';
import UncrewButton from 'Community/UI/UncrewButton';
import UserBanner from 'User/UI/UserBanner';

import PostTile from 'Dashboard/UI/PostTile';
import GoalsTile from 'Dashboard/UI/GoalsTile';
import TrendsTile from 'Dashboard/UI/TrendsTile';
import ChallengesTile from 'Dashboard/UI/ChallengesTile';

type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch
}

interface URLParams {
  userId: UserId
}

class CrewMemberDashboard extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(UserAC.loadCurrentUser());
    this.props.dispatch(UserAC.loadCrew());
    this.props.dispatch(ActionCreators.loadUserDashboard(this.userId()));
    this.redirectIfUserNotExists();
  }

  componentDidUpdate() {
    this.redirectIfUserNotExists();
  }

  redirectIfUserNotExists() {
    const u = this.user();
    if (isLoaded(u) && u.value === undefined) {
      this.props.dispatch(NavAC.goBack(Urls.communityUrl()));
    }
  }

  uncrew = () => {
    loadableMap(
      member => {
        if (member) {
          this.props.dispatch(CommunityAC.removeFromCrew(member));
        }
      },
      this.user()
    );
  }

  currentUserId(): UserId | undefined {
    return UserSEL.userId(this.props.currentUser);
  }

  userId(): UserId {
    return this.props.match.params.userId;
  }

  user(): Loadable<CrewMember | undefined> {
    return UserSEL.crewMember(this.userId(), this.props.currentUser);
  }

  userDashboard(): Loadable<UserDashboard> {
    return SEL.userDashboard(this.userId(), this.props.dashboard);
  }

  appFeatures(): Loadable<AppFeatures> {
    return UserSEL.appFeatures(this.props.currentUser);
  }

  withFeature<R>(key: AppFeatureKey, yes: R, no?: R) {
    return withLoadableFeatures(this.appFeatures(), [key], yes, no);
  }

  render() {
    const dashboard = this.userDashboard();
    const crewMember = this.user();
    const currentUserId = this.currentUserId();
    return (
      <NavBarLayout title="Community Member"
        initBreadcrumb={Urls.communityUrl()}>
        {currentUserId && isLoaded(dashboard) && crewMember.value ?
         this.renderUserContent(
           currentUserId, dashboard.value
         ) :
         this.renderNotLoaded()
        }
        <HUD state={this.props.hud} />
      </NavBarLayout>
    );
  }

  renderNotLoaded(): React.ReactNode {
    return <Spinner wrap="center" />;
  }

  renderUserContent(
    currentUserId: UserId, dashboard: UserDashboard
  ) {
    const uid = dashboard.user.id;
    return (
      <div>
        <UserBanner
          user={dashboard.user}
          variant="prof_pic"
          contentFn={this.renderUserBannerContent} />
        <PostTile
          linkUrl={Urls.userFeedUrl(uid)}
          showScore={
            loadableFeaturesEnabled(this.appFeatures(), ['healthScore'])}
          lastPost={dashboard.lastPost}
          currentUserId={currentUserId} />
        {this.withFeature(
           'goals',
           (
             <GoalsTile
               activeGoal={dashboard.activeGoal}
               isMe={false}
               userId={uid}/>
           )
        )}
        {<TrendsTile userId={uid} />}
        <ChallengesTile challenge={dashboard.challenge} isMe={false}/>
      </div>
    );
  }

  renderUserBannerContent = (u: Profile | CrewMember) => {
    const name = userName(u);
    return [
      name,
      <br key="br"/>,
      <small key="small" className="dashboard-user-banner__uncrew-button">
        <UncrewButton onClick={this.uncrew} />
      </small>
    ];
  }
}

export default defaultConnect(withRouter(CrewMemberDashboard));
