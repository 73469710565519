import * as React from 'react';
import { connect } from 'react-redux';
import { State, StoreDispatch } from 'Store';
import { State as NavState, peekBreadcrumb } from 'Nav/Data';
import { CurrentUser, SEL as UserSEL } from 'User/Data';
import { ActionCreators } from 'Nav/ActionCreator';
import Link from 'Shared/UI/Link';
import NavMenu from 'Nav/UI/NavMenu';

interface OwnProps {
  title?: string
  initBreadcrumb?: string
  postPageLabel: string
}

interface StateProps {
  nav: NavState
  currentUser: CurrentUser
}

interface DispatchProps {
  dispatch: StoreDispatch
}

type Props = OwnProps & StateProps & DispatchProps;

class NavBar extends React.Component<Props, {}> {
  componentDidMount() {
    if (this.props.initBreadcrumb) {
      this.props.dispatch(
        ActionCreators.initBreadcrumb(this.props.initBreadcrumb)
      );
    }
  }

  render() {
    return (
      <div className="nav-bar">
        {this.renderGoBack(this.props.nav)}
        <h1 className="nav-bar__title">{this.props.title}</h1>
        <NavMenu
          navState={this.props.nav}
          appFeatures={UserSEL.appFeatures(this.props.currentUser)}
          dispatch={this.props.dispatch}
          postPageLabel={this.props.postPageLabel}/>
      </div>
    );
  }

  renderGoBack(nav: NavState) {
    const crumb = peekBreadcrumb(nav);
    if (crumb) {
      return (
        <Link
          className="nav-bar__go-back"
          to={crumb.location.pathname}
          breadcrumb="pop"
          themed={false}
        >
          &larr; go back
        </Link>
      );
    }
  }
}

export default connect(
  (state: State) => ({ nav: state.nav, currentUser: state.currentUser })
)(NavBar);
