import * as React from 'react';

import Tile, { Props } from 'Shared/UI/Tile';

export default function renderDashboardTile(
  props: Props
): React.ReactElement<Props> {
  if (props.breadcrumb === undefined) {
    props = { ...props, breadcrumb: 'push' };
  }
  return <Tile {...props}/>;
}
