import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import * as R from 'ramda';
import { ActionCreators } from 'Challenges/ActionCreator';

import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { challengesUrl } from 'Shared/Urls';
import Spinner from 'Shared/UI/Spinner';
import { isLoaded } from 'misc/Data/Loadable';
import TileList from 'Challenges/UI/TileList';
import SectionTitle from 'Challenges/UI/SectionTitle';

import {
  ChallengeSummary,
  ChallengeTemplate
} from 'Challenges/Data';

import { NavBarLayout } from 'Nav';

import { State, StoreDispatch } from 'Store';

type Props = State & RouteComponentProps<{}> & {
  dispatch: StoreDispatch
}

class AvailableChallenges extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(ActionCreators.loadChallenges());
  }

  startOrJoin(
    available: ChallengeSummary[], templates: ChallengeTemplate[]
  ):(ChallengeSummary | ChallengeTemplate)[] {
    return R.concat(
      available as (ChallengeSummary | ChallengeTemplate)[],
      templates
    );
  }

  render() {
    const available = this.props.challenges.available;
    const templates = this.props.challenges.templates;

    return (
      <NavBarLayout title="Challenges"
                    initBreadcrumb={challengesUrl()}
                    className="my-challenges">
        <SectionTitle title='Start or Join' />
          {isLoaded(available) ?
            <TileList
              challenges={this.startOrJoin(available.value, templates)}
              noDataMessage={'no challenges to start or join'}
            />
            :
            <Spinner wrap="center" />
          }

      </NavBarLayout>
    );
  }
}

export default withRouter(defaultConnect(AvailableChallenges));
