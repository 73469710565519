import * as EventEmitter from 'eventemitter3';
import * as R from 'ramda';
import * as moment from 'moment-timezone';
import * as BLE from 'BLE/Data';
import * as Ad from 'BLE/Advertisement';
import { isAndroid } from 'Cordova';
import { makeLogger, Logger } from 'Shared/Log';
import { noop } from 'Shared/Noop';
import * as Timeout from 'Shared/Timeout';
import * as Random from 'Shared/Random';
import { DeviceAccess, makeDeviceAccess } from 'BLE/DeviceAccess';
import * as Util from 'BLE/Util';
import * as Data from './Data';

interface ProtocolInterface {
  init(): Promise<Connection>,
  close(): Promise<void>,
  getDeviceInfo(): Promise<Data.DeviceInfo>
  getBattery(): Promise<number>,
  setUnit(unit: Data.Unit): Promise<void>,
  register(alreadyRegistered?: boolean): Promise<void>,
  bindDevice(): Promise<void>,
  initializeDevice(): Promise<void>,
  synchronizeWeights(enable?: boolean): Promise<void>,
  synchronizeBPs(enable?: boolean): Promise<void>,
  onWeight(fn: (weight: Data.Weight) => void): void,
  onBloodPressure(fn: (bp: Data.BloodPressure) => void): void
}

// Service UUIDs
const DEVICE_INFO_UUID = '180A';

// Characteristic UUIDs
const DEVICE_TO_APP_DATA_INDICATE = 'A620';
const DEVICE_TO_APP_DATA_NOTIFY = 'A621';
const DEVICE_TO_APP_ACK = 'A625';
const APP_TO_DEVICE_ACK = 'A622';
const APP_TO_DEVICE_COMMAND_TX = 'A624';
const BATTERY_CHARACTERISTIC = 'A640';

const ACK_OK = Uint8Array.of(0x00, 0x01, 0x01);

/**
 * BP Cuff times are given as the number of seconds since 1/1/2010
 */
const BP_CUFF_EPOCH = moment.utc([2010, 0, 1])

// Representation of operation systems recognized by protocol
enum OS {
  ANDROID = 0x02,
  IOS = 0x01
}

enum CMD {
  REGISTER_DEVICE_RESPONSE = 0x0002,
  BIND_DEVICE_RESPONSE = 0x0004,
  LOGIN_DEVICE_REQUEST = 0x0007,
  INITIALIZE_DEVICE_REQUEST = 0x0009,
  SYNCHRONIZE_MEASUREMENT_WEIGHT = 0x4802,
  SYNCHRONIZE_MEASUREMENT_BP = 0x4902,
  SET_UNIT_NOTIFICATION = 0x2004,
  SETTINGS_RESPONSE_PREFIX = 0x1000,
  SET_TIME = 0x1002,
  SET_UNITS = 0x1004
}

enum InboundEvent {
  ACK = 'ACK',
  NACK = 'NACK',
  BATTERY = 'BATTERY',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  REGISTER_ERROR = 'REGISTER_ERROR',
  BIND_SUCCESS = 'BIND_SUCCESS',
  BIND_ERROR = 'BIND_ERROR',
  SET_UNIT_SUCCESS = 'SET_UNIT_SUCCESS',
  SET_UNIT_ERROR = 'SET_UNIT_ERROR',
  SET_UNIT_NOTIFICATION = 'SET_UNIT_NOTIFICATION',
  WEIGHT = 'WEIGHT',
  BLOOD_PRESSURE = 'BLOOD_PRESSURE'
}

type GGEvent = InboundEvent;

const UTC_TIMEZONE = 0x30;

interface Connection {
  access: DeviceAccess,
  service: BluetoothlePlugin.Service
}

export function makeProtocol(
  ble: BluetoothlePlugin.Bluetoothle,
  device: BLE.Device
): ProtocolInterface {
  return new Protocol(device, ble);
}

class Protocol implements ProtocolInterface {
  private readonly log: Logger;
  private connection: Connection | undefined;
  private events: EventEmitter<GGEvent>;
  private bpFrame0: Uint8Array | undefined;

  constructor (
    private readonly device: BLE.Device,
    private readonly bluetooth: BluetoothlePlugin.Bluetoothle
  ) {
    this.log = makeLogger('GreaterGoodsA6:Protocol', 'BLUETOOTH_LOG');
    this.events = new EventEmitter();
  }

  public async init(): Promise<Connection> {
    try {
      if (this.connection) { return this.connection; }
      const access = makeDeviceAccess(this.bluetooth, this.device);
      if (await access.isConnected()) {
        this.log('Already connected, disconnecting for a fresh start.')
        await access.close();
      }

      const alreadyRegistered = await this.determineRegistrationStatus();
      this.log(
        'Found device and determined registration status:', alreadyRegistered
      );

      await access.withConnection(
        () => {
          this.log('Disconnected!');
          if (this.connection) { this.connection = undefined; }
        },
        30000
      );
      const discovery = await access.discover();
      const service = this.selectA6ServiceUUID(discovery);
      if (service === undefined) {
        throw new Error('Could not find service compatible with A6 protocol');
      }
      this.connection = { access, service };
      access.silenceLogs();
      await this.setupSubscriptions(this.connection);
      if (!alreadyRegistered) {
        await this.register();
      }
      return this.connection;
    } catch (e) {
      this.log.error('Error during init:', e);
      if (this.connection && !(await this.connection.access.isConnected())) {
        this.connection = undefined;
      }
      throw e;
    }
  }

  public async close() {
    if (this.connection) {
      await this.connection.access.close()
      this.connection = undefined;
    }
  }

  public onWeight(fn: (weight: Data.Weight) => void) {
    this.events.on(InboundEvent.WEIGHT, fn);
  }

  public onBloodPressure(fn: (bp: Data.BloodPressure) => void) {
    this.events.on(InboundEvent.BLOOD_PRESSURE, fn);
  }

  public async getDeviceInfo(): Promise<Data.DeviceInfo> {
    const { access } = await this.init();
    const manufacturerName = await access.read(DEVICE_INFO_UUID, '2A29');
    const modelNumber = await access.read(DEVICE_INFO_UUID, '2A24');
    const serialNumber = await access.read(DEVICE_INFO_UUID, '2A25');
    const hwVersion = await access.read(DEVICE_INFO_UUID, '2A27')
    const fwVersion = await access.read(DEVICE_INFO_UUID, '2A26')
    const swVersion = await access.read(DEVICE_INFO_UUID, '2A28')
    return {
      manufacturerName: Util.stringFromBytes(manufacturerName),
      modelNumber: Util.stringFromBytes(modelNumber),
      serialNumber: Util.stringFromBytes(serialNumber),
      hwVersion: Util.stringFromBytes(hwVersion),
      fwVersion: Util.stringFromBytes(fwVersion),
      swVersion: Util.stringFromBytes(swVersion)
    };
  }

  public async getBattery(): Promise<number> {
    const { access, service } = await this.init();
    const result = await access.read(service.uuid, BATTERY_CHARACTERISTIC);
    this.events.emit(InboundEvent.BATTERY, result);
    return result[0];
  }

  // This doesn't seem to work.
  async setTime(time?: moment.Moment): Promise<void> {
    time ||= moment();
    this.log(`SET TIME: ${time.format()}`);
    await this.sendCommand(
      0x10, 0x02, // command
      0x04, // flag?
      Util.msbEncode(time.unix()),
      this.timezoneByte(time.utcOffset())
    );
  }

  public async setUnit(unit: Data.Unit): Promise<void> {
    this.log(`SET UNIT: ${unit} (${Data.Unit[unit]})`)
    await this.sendCommand(
      0x10, 0x04, // command
      unit
    );
    await this.waitFor(
      InboundEvent.SET_UNIT_SUCCESS,
      InboundEvent.SET_UNIT_ERROR
    );
  }

  public async register(already=false): Promise<void> {
    this.log('REGISTER: already registered: ', already);
    await this.sendCommand(
      0x00, 0x01, // command,
      Random.randomBytesUint8Array(6), // device id, random number 6 bytes?
      already ? 0x02 : 0x01 // device is unregistred, 0x02 = is reg?
    );
    await this.waitFor(
      InboundEvent.REGISTER_SUCCESS, InboundEvent.REGISTER_ERROR
    );
  }

  public async bindDevice(): Promise<void> {
    this.log('BIND DEVICE');
    await this.sendCommand(
      0x00, 0x03, // command
      0x01, // user number, 0x00 => guest
      0x01 // bind result? 0x01 = success not sure bout this one
    );
    await this.waitFor(InboundEvent.BIND_SUCCESS, InboundEvent.BIND_ERROR);
  }

  public async initializeDevice(time?: moment.Moment): Promise<void> {
    time ||= moment();
    this.log('INITIALIZE DEVICE');
    await this.sendCommand(
      0x00, 0x0A, // command
      0x18, // flag, always 0x18
      Util.msbEncode(time.unix()),
      this.timezoneByte(time.utcOffset())
    );
    await this.waitForAck();
  }

  async sendLoginResponse(code: Uint8Array): Promise<void> {
    this.log('LOGIN RESPONSE');
    await this.sendCommand(
      0x00, 0x08, // command
      0x01, // "login result" 0x01 = success
      code, // verification code we received
      0x00, // "work type" reserved byte (??)
      this.operatingSystemByte()
    );
    await this.waitForAck();
  }

  public async synchronizeWeights(enable=true): Promise<void> {
    this.log('SYNCHRONIZE WEIGHTS');
    await this.sendCommand(
      0x48, 0x01, // command
      0x01, // user selection (0 = guest)
      enable ? 0x01 : 0x00 // turn sync on or off
    );
    await this.waitForAck();
  }

  public async synchronizeBPs(enable=true): Promise<void> {
    this.log('SYNCHRONIZE BPS');
    await this.sendCommand(
      0x49, 0x01, // command
      0x01, // user selection (0 = guest)
      enable ? 0x01 : 0x00
    );
    await this.waitForAck();
  }

  private async sendCommand(...data: (Uint8Array | number)[]): Promise<void> {
    const { access, service } = await this.init();
    const length = R.sum(data.map(this.byteLength));
    let command = new Uint8Array(length + 2);
    command[0] = 0x10; // frame control byte
    command[1] = length;
    let offset = 2;

    data.forEach(aryOrNum => {
      if (typeof aryOrNum === 'number') {
        command[offset] = aryOrNum;
        offset += 1;
      } else {
        command.set(aryOrNum, offset);
        offset += aryOrNum.length;
      }
    })

    this.log('SENDING COMMAND:', command, this.toHexArray(command));
    await access.writeWithoutResponse(
      service.uuid,
      APP_TO_DEVICE_COMMAND_TX,
      command
    );
  }

  private async sendAck(success=true): Promise<void> {
    this.log('SEND ACK');
    const { access, service } = await this.init();
    await access.writeWithoutResponse(
      service.uuid,
      APP_TO_DEVICE_ACK,
      Uint8Array.of(
        0x00,
        0x01,
        success ? 0x01 : 0x02
      )
    )
  }

  private async setupSubscriptions(connection: Connection): Promise<void> {
    const { access, service } = connection;
    await access.subscribe(service.uuid, DEVICE_TO_APP_ACK, packet => {
      this.log(
        `Recevied Device->App ACK Packet (${DEVICE_TO_APP_ACK}): `,
        packet,
        this.toHexArray(packet)
      );

      if (Util.equalBytes(packet, ACK_OK)) {
        this.log.debug('Got ACK response.');
        this.events.emit(InboundEvent.ACK);
      } else {
        this.log.error('Got NACK response.');
        this.events.emit(InboundEvent.NACK);
      }
    });
    this.log('Ok, subscribed to device ACK.')

    await access.subscribe(
      service.uuid, DEVICE_TO_APP_DATA_INDICATE,
      async packet => {
        this.log(
          `Recevied Device->App DATA INDICATE Packet `+
            `(${DEVICE_TO_APP_DATA_INDICATE}): `,
          packet,
          this.toHexArray(packet)
        );

        this.handleMultiFrameDeviceData(packet);
      }
    );

    await access.subscribe(
      service.uuid, DEVICE_TO_APP_DATA_NOTIFY,
      async packet => {
        const cmd = (new DataView(packet.buffer)).getUint16(2);
        this.log(
          `Recevied Device->App DATA Packet (${DEVICE_TO_APP_DATA_NOTIFY}): `,
          packet,
          this.toHexArray(packet),
          CMD[cmd]
        );
        this.handleDeviceData(packet);
      }
    )
    this.log('Ok, subscribed to device data notification');
  }

  private async handleDeviceData(packet: Uint8Array) {
    const view = new DataView(packet.buffer)
    const cmd = view.getUint16(2);
    let result: number;

    switch(cmd) {
      case CMD.REGISTER_DEVICE_RESPONSE:
        await this.sendAck();
        result = view.getUint8(4);
        if (result === 0x01) {
          this.log('Registration success!');
          this.events.emit(InboundEvent.REGISTER_SUCCESS);
        } else {
          this.log.error('Registration error!');
          this.events.emit(InboundEvent.REGISTER_ERROR);
        }
        break;
      case CMD.BIND_DEVICE_RESPONSE:
        await this.sendAck();
        result = view.getUint8(4);
        if (result === 0x01) {
          this.log('Bind device success!');
          this.events.emit(InboundEvent.BIND_SUCCESS);
        } else {
          this.log.error('Bind device error!');
          this.events.emit(InboundEvent.BIND_ERROR);
        }
        break;
      case CMD.INITIALIZE_DEVICE_REQUEST:
        await this.sendAck();
        await this.initializeDevice();
        break;
      case CMD.LOGIN_DEVICE_REQUEST:
        await this.sendAck();
        const verificationCode = packet.slice(4, 10);
        await this.sendLoginResponse(verificationCode);
        break;
      case CMD.SYNCHRONIZE_MEASUREMENT_WEIGHT:
        await this.sendAck();
        const weight: Data.Weight = {
          weight: this.parseWeight(view.getUint16(10)),
          time: this.parseUnixTime(view.getUint32(12))
        }
        this.events.emit(InboundEvent.WEIGHT, weight);
        break;
      case CMD.SYNCHRONIZE_MEASUREMENT_BP:
        // Comes in 2 frames, numbered 0 and 1, in first frame we just store the
        // data into a buffer for processing when we get the rest. The second
        // frame is sent over the other characteristic (A620), and so is not
        // handled in this handler
        this.bpFrame0 = packet;
        await this.sendAck();
        break;
      case CMD.SET_UNIT_NOTIFICATION:
        await this.sendAck();
        const unit: Data.Unit = packet[4];
        this.log(
          `Set Units Notification - set to ${unit} (${Data.Unit[unit]})`
        );
        this.events.emit(
          InboundEvent.SET_UNIT_NOTIFICATION,
          { unit, name: Data.Unit[unit] }
        );
        break;
      case CMD.SETTINGS_RESPONSE_PREFIX:
        switch(view.getUint16(4)) {
          case CMD.SET_TIME:
            this.sendAck();
            if (packet[6] === 0x01) {
              this.log('SET_TIME success!')
            } else {
              this.log.error('SET_TIME error!');
            }
            break;
          case CMD.SET_UNITS:
            this.log('SET_UNITS response!');
            this.sendAck();
            if (packet[6] === 0x01) {
              this.log('SET_UNITS success!')
              this.events.emit(InboundEvent.SET_UNIT_SUCCESS);
            } else {
              this.log.error('SET_UNITS error!');
              this.events.emit(InboundEvent.SET_UNIT_ERROR);
            }
            break;
        }
        break;
    }
  }

  /**
   * The only current use case for multi-frame data is receiveing BP
   * measurements, which are sent across 2 frames.  The first is sent over the
   * "notify" characteristic A621, the second over the "indicate" characteristic
   * A620.  This function handles the second frame, combining it with data
   * presumably first received in the first frame.
   */
  private async handleMultiFrameDeviceData(packet: Uint8Array) {
    if (this.bpFrame0 === undefined) {
      this.log.error(
        'Received second frame of BP data without ever seeing the first! '+
          'Could not parse data.'
      );
      return;
    }

    const frame0 = new DataView(this.bpFrame0.buffer);
    const frame1 = new DataView(packet.buffer);
    const bp: Data.BloodPressure = {
      systolic: frame0.getUint16(10),
      diastolic: frame0.getUint16(12),
      heartRate: frame0.getUint16(18),
      time: this.parseBPCuffTime(frame1.getUint32(3))
    }
    this.events.emit(InboundEvent.BLOOD_PRESSURE, bp);
    this.bpFrame0 = undefined;
    await this.sendAck();
  }

  private toHexArray(data: Uint8Array): string[] {
    return Array.from(data).map(i => i.toString(16));
  }

  private timezoneByte(utcOffsetMinutes: number): number {
    return UTC_TIMEZONE + utcOffsetMinutes/15;
  }

  private operatingSystemByte(): number {
    return isAndroid() ? OS.ANDROID : OS.IOS;
  }

  private byteLength(aryOrNum: Uint8Array | number): number {
    if (typeof aryOrNum === 'number') {
      return 1;
    } else {
      return aryOrNum.length;
    }
  }

  /**
   * weight data comes in uint16, and represents the weight in 0.01 kg
   * For examples:
   * [0x1c, 0x34] => 0x1c34 => 7220 => 72.2 kg => 159.2 lbs
   * Returns weight in Pounds
   */
  private parseWeight(decagrams: number): number {
    return decagrams * 0.01 * 2.205;
  }

  /**
   * uint32 representing a unix timestamp
   * example:
   * [0x60, 0xf0, 0x77, 0xdf] => 0x60f077df => 1626372063 =>
   *   2021-7-15 18:01:03Z
   */
  private parseUnixTime(time: number): moment.Moment {
    return moment.unix(time);
  }

  /**
   * BP Cuff returns time as number of seconds since 1/1/2010.  Additionally,
   * there are no timezones, so we just interpret the time in the current
   * "local" timezone
   */
  private parseBPCuffTime(time: number): moment.Moment {
    const utc = BP_CUFF_EPOCH.clone().add(time, 'seconds');
    return moment(
      [
        utc.year(), utc.month(), utc.date(),
        utc.hour(), utc.minute(), utc.second()
      ]
    );
  }

  private waitFor(event: GGEvent, failOn?: GGEvent): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const successCallback = () => {
        if (failOn) { this.events.off(failOn, failCallback); }
        resolve();
      }

      const failCallback = () => {
        this.events.off(event, successCallback);
        reject();
      }

      this.events.once(event, successCallback)
      if (failOn) {
        this.events.once(failOn, failCallback);
      }
    });
  }

  private waitForAck() {
    return this.waitFor(InboundEvent.ACK, InboundEvent.NACK);
  }

  private selectA6ServiceUUID(
    device: BluetoothlePlugin.Device
  ): BluetoothlePlugin.Service | undefined {
    return R.find(
      service => R.any(
        char => char.uuid === DEVICE_TO_APP_DATA_NOTIFY,
        service.characteristics
      ),
      device.services
    );
  }

  private async determineRegistrationStatus(): Promise<boolean> {
    return Timeout.timeout<boolean>(
      10000,
      (resolve, reject) => {
        this.bluetooth.startScan(
          result => {
            if (
              result.status === 'scanResult' &&
                result.address === this.device.address
            ) {
              let manufacturerData: Uint8Array | undefined;
              if (typeof(result.advertisement) === 'string') {
                let ad = Ad.parse(
                  this.bluetooth.encodedStringToBytes(result.advertisement)
                );
                manufacturerData = ad.manufacturerData;
              } else if (
                typeof(result.advertisement) === 'object' &&
                  result.advertisement.manufacturerData &&
                  result.advertisement.manufacturerData.length > 0
              ) {
                manufacturerData = this.bluetooth.encodedStringToBytes(
                  result.advertisement.manufacturerData
                )
              }

              if (manufacturerData) {
                this.log('Found device in scan:', result);
                this.bluetooth.stopScan(noop, noop);
                resolve(manufacturerData[4] === 0x01);
              }
            }
          },
          reject
        )
      },
      'Timed out while scanning for device.'
    ).finally(() => this.bluetooth.stopScan(noop, noop));
  }
}

export default Protocol;
