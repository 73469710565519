import { connect } from 'react-redux';
import { State as StoreState, StoreDispatch, DispatchProps } from 'Store';

export function defaultConnect<P>(
  component: React.ComponentType<P & StoreState & DispatchProps>
): React.ComponentType<P> {
  // it will require a greater mind to understand connect's type and why this no
  // longer works.
  /* return connect<StoreState, DispatchProp<StoreState>, P>( */
  return (connect as any)(
    (state: StoreState) => state,
    (dispatch: StoreDispatch) => ({ dispatch })
  )(component);
}
