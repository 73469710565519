import { StoreDispatch } from 'Store';
import * as Edge from 'Edge/Data';
import * as BLE from 'BLE';
import { DeviceFamily } from 'BLE/Protocols/JStyle1963YH/Data';
import { makeCollector as make1963Collector } from './JStyle1963YH';

export function makeCollector(
  dispatch: StoreDispatch,
  bluetooth: BLE.Service,
  deviceType: Edge.DeviceType,
  device: Edge.UserDevice
): Edge.Collector {
  return make1963Collector(
    dispatch, bluetooth, deviceType, device, DeviceFamily.JStyle1790
  );
}
