import * as React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { State as NavState, ActionCreators } from 'Nav';
import { State, StoreDispatch } from 'Store';
import * as Cordova from 'Cordova';

export type LinkFn = (event: React.MouseEvent<HTMLAnchorElement>) => void;

export type BreadcrumbAction = 'push' | 'pop' | 'clear';

export type ExternalType = boolean | '_system' | '_blank'

export type LinkTarget = string | LinkFn;

export interface Props {
  to: LinkTarget,
  key?: any,
  className?: string,
  children?: React.ReactNode,
  themed?: boolean
  breadcrumb?: BreadcrumbAction
  onClick?: LinkFn,
  external?: ExternalType
}

interface StateProps { navState: NavState }

interface DispatchProps { dispatch: StoreDispatch }

type AllProps = Props & StateProps & DispatchProps;

type AnchorTagProps =
  React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

function renderLink(
  props: AllProps
): React.ReactElement<AllProps> {
  if (typeof props.to === 'string') {
    if (props.external) {
      let attrs: AnchorTagProps =
        { className: className(props), key: props.key };
      if (Cordova.isCordova()) {
        attrs.onClick = gotoExternalUrlFn(props.external, props.to);
      } else {
        attrs.href = props.to;
        attrs.target = externalTypeToLinkTarget(props.external)
      }
      return (
        <a {...attrs}>
          {props.children}
        </a>
      )
    } else {
      return (
        <RouterLink
          to={props.to}
          className={className(props)}
          onClick={routerLinkOnClick(props)}
        >
          {props.children}
        </RouterLink>
      );
    }
  } else {
    return (
      <a onClick={functionLinkOnClick(props, props.to)}
        className={className(props)}
        key={props.key}
      >
        {props.children}
      </a>
    );
  }
}

export default connect(
  (state: State) => ({ navState: state.nav })
)(renderLink);

function className(props: Props): string | undefined {
  /* const themed = props.themed === undefined ? true : props.themed;*/
  const themed = props.themed;
  if (themed) {
    return `theme__default-link ${props.className || ''}`;
  } else {
    return props.className;
  }
}

function routerLinkOnClick(props: AllProps): LinkFn {
  return e => {
    saveCrumb(props);
    if (props.onClick) { props.onClick(e); }
  }
}

function functionLinkOnClick(props: AllProps, fn: LinkFn): LinkFn {
  return e => {
    saveCrumb(props);
    if (props.onClick) { props.onClick(e); }
    fn(e);
  }
}

function saveCrumb(props: AllProps): void {
  if (props.breadcrumb === 'push') {
    props.dispatch(ActionCreators.saveBreadcrumb());
  } else if (props.breadcrumb === 'pop') {
    props.dispatch(ActionCreators.popBreadcrumb());
  } else if (props.breadcrumb === 'clear') {
    props.dispatch(ActionCreators.clearBreadcrumbs());
  }
}

function gotoExternalUrlFn(type: ExternalType, url: string): () => void {
  const target = externalTypeToLinkTarget(type);
  return () => window.open(url, target);
}

function externalTypeToLinkTarget(type: ExternalType): '_blank' | '_system' {
  return (typeof type === 'string') ? type : '_blank';
}
