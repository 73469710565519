import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';

/**
 * Define union of Actions from various modules, this is the final Action type
 * defined for the store
 **/


/**
 * Global "app" actions
 */

export type NSType = 'APP';
export const NS: NSType = 'APP';

export enum ActionType {
  INITIALIZE_APP = 'INITIALIZE_APP',
  RESET_APP = 'RESET_APP'
}

export type Action
  = InitializeApp
  | ResetApp;

interface InitializeApp {
  type: ActionType.INITIALIZE_APP
}

interface ResetApp {
  type: ActionType.RESET_APP
}

export type NSAction = WithNS<NSType, Action>;
export const withNS = originalWithNS<NSType, Action>(NS);
