import * as React from 'react';
import { bem } from 'css-util';
import Icon, { IconName } from 'Shared/UI/Icon';

const CSS = bem('auth-form');

interface Props {
  type: 'text' | 'password' | 'email'
  icon: IconName
  placeholder?: string
  value: string
  onChange: (value: string) => void
  inputModifier?: string
}

const AuthFormTextField = (props: Props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    props.onChange(e.target.value);

  return (
    <div className={CSS('field-row')(['input'])}>
      <Icon name={props.icon} className={CSS('field-icon')()}/>
      <input
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={onChange}
        className={CSS('field')([props.inputModifier])} />
    </div>
  )
};

export default AuthFormTextField;
