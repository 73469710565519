export type Platform = 'browser' | 'iOS' | 'Android';

export interface Device {
  platform: Platform
}

const BROWSER_DEVICE = {
  platform: 'browser'
}

export function getDevice(): Device {
  const device: Device = (window as any).device;
  return device || BROWSER_DEVICE;
}

export function getPlatform(): Platform {
  return getDevice().platform;
}
