import * as moment from 'moment-timezone';
import * as R from 'ramda';
import { IconName } from 'Shared/UI/Icon';
import { Loadable, loadable, loadableMap } from 'misc/Data/Loadable'
import {
  IDMap, newIDMap, idMapLookupPrism, idMapValues
} from 'Shared/Data/IDMap';
import { Feed, makeFeed } from 'Shared/Data/Feed';
import { Lens, Prism } from '@atomic-object/lenses';
import { UserId } from 'User/Data';
import { Comment } from 'Comments/Data';
export { Comment }

/** Types **/

export type NSType = 'POST';
export const NS: NSType = 'POST';

export type ActivityId = string;
export type BiopostId = string;

export type BiopostFeed = Feed<BiopostId>

export interface State {
  activities: IDMap<Activity>,
  categories: Category[],
  bioposts: IDMap<Biopost>,
  // Maps BiopostId => Comment[]
  comments: IDMap<Comment[]>,
  lastBiopostId: Loadable<string | undefined>,
  feed: BiopostFeed,
  myDynamicPosts: Biopost[],
  // When undefined, favorites section is not shown in post menu
  favorites: Category | undefined,
  // When undefined, measurements section is not shown in post menu
  specsCategory: CategoryInfo | undefined
  // When undefined, genius section is not shown
  genius: CategoryInfo | undefined,
  geniusRecommendations: RecommendedActivity[]
}

export interface CategoryInfo {
  name: string,
  icon: IconName
}
export type Category = CategoryInfo & {
  topActivityIds: ActivityId[],
  bottomActivityIds?: ActivityId[]
}

export interface Activity {
  id: ActivityId,
  name: string,
  loading?: boolean
}

export type RecommendedActivity = Activity & {
  scoreChange: number
};

export interface Biopost {
  id: string,
  title: string
  newScore: Score,
  oldScore?: Score | undefined,
  scoreIncrement?: number | undefined,
  props: Prop[],
  icon: IconName,
  time: moment.Moment,
  commentCount: number,
  userId: UserId,
  userName: string,
  source: string | undefined,
  dynamic?: boolean
}

export interface Score {
  value: number,
  time: moment.Moment
}

export interface Prop {
  giverId: UserId
}

/** data functions **/

export function initialState(): State {
  return {
    activities: newIDMap() as IDMap<Activity>,
    comments: newIDMap(),
    categories: [],
    favorites: undefined,
    bioposts: newIDMap() as IDMap<Biopost>,
    lastBiopostId: loadable(),
    feed: makeFeed([]),
    specsCategory: undefined,
    genius: undefined,
    geniusRecommendations: [],
    myDynamicPosts: []
  };
}

export { makeFeed };

export function insertBiopost(post: Biopost, state: State): State {
  return { ...state, bioposts: { ...state.bioposts, [post.id]: post } };
}

export function insertBioposts(posts: Biopost[], state: State): State {
  return posts.reduce((s, post) => insertBiopost(post, s), state);
}

export function insertFavorite(activityId: ActivityId, state: State): State {
  if (state.favorites === undefined) {
    throw new Error('favorites are unavailable');
  }
  const newIds = R.uniq(R.append(activityId, state.favorites.topActivityIds));
  return {
      ...state,
    favorites: {
        ...state.favorites,
      topActivityIds: newIds
    }
  };
}

export function removeFavorite(activityId: ActivityId, state: State): State {
  if (state.favorites === undefined) {
    throw new Error('favorites are unavailable');
  }

  return {
      ...state,
    favorites: {
        ...state.favorites,
      topActivityIds: R.without([activityId], state.favorites.topActivityIds)
    }
  };
}

export function hasPropsFrom(userId: UserId, biopost: Biopost): boolean {
  return R.any((p: Prop) => p.giverId === userId, biopost.props);
}

/** Lenses **/

function makeLenses() {
  const base = Lens.from<State>();
  const activities: Lens<State, IDMap<Activity>> = base.prop('activities');

  const activity = (id: string): Prism<State, Activity> => {
    return Prism.comp<State, IDMap<Activity>, Activity>(
      activities,
      idMapLookupPrism<Activity>(id)
    );
  };

  return {
    activities,

    activity,

    activityIsLoading(id: string): Prism<State, boolean> {
      return Prism.comp(
        activity(id),
        Lens.of<Activity, boolean>({
          get: (a: Activity) => !!a.loading,
          set: (a: Activity, val: boolean) => ({ ...a, loading: val })
        })
      );
    },

    biopost(biopostId: BiopostId): Prism<State, Biopost> {
      return Prism.comp<State, IDMap<Biopost>, Biopost>(
        Lens.from<State>().prop('bioposts'),
        idMapLookupPrism(biopostId)
      );
    },

    comments(biopostId: string): Prism<State, Comment[]> {
      return Prism.comp<State, IDMap<Comment[]>, Comment[]>(
        Lens.from<State>().prop('comments'),
        idMapLookupPrism(biopostId)
      );
    },

    props(biopostId: BiopostId): Prism<State, Prop[]> {
      return Prism.comp(
        Lens.from<State>().prop('bioposts'),
        idMapLookupPrism(biopostId),
        Lens.from<Biopost>().prop('props')
      );
    }
  };
}

export const LENSES = makeLenses();

/** Selectors **/

export const SEL = {
  activities(s: State): Activity[] {
    return idMapValues(LENSES.activities.get(s));
  },

  activity(id: string, s: State): Activity | undefined {
    return LENSES.activity(id).get(s);
  },

  activityIsLoading(id: string): (s: State) => boolean {
    return s => {
      return !!LENSES.activityIsLoading(id).get(s);
    };
  },

  favoriteActivityIds(s: State): ActivityId[] {
    return s.favorites ? categoryActivityIds(s.favorites) : [];
  },

  isSpecsEnabled(s: State): boolean {
    return !!s.specsCategory;
  },

  specsCategory(s: State): CategoryInfo | undefined {
    return s.specsCategory;
  },

  getBiopost(id: string, s: State): Biopost | undefined {
    return s.bioposts[id];
  },

  getLastBiopost(s: State): Loadable<Biopost | undefined> {
    return loadableMap(
      id => id ? SEL.getBiopost(id, s) : undefined,
      s.lastBiopostId
    );
  },

  comments(biopostId: BiopostId, s: State): Comment[] | undefined {
    return LENSES.comments(biopostId).get(s);
  }
}

export function isRecommendedActivity(
  a: Activity | RecommendedActivity
): a is RecommendedActivity {
  return (a as RecommendedActivity).scoreChange !== undefined;
}

export function categoryActivityIds(cat: Category): ActivityId[] {
  return R.concat(cat.topActivityIds, cat.bottomActivityIds || []);
}

export function formatPostTime(time: moment.Moment): string {
  return time.format('h:mm A M/D/YY');
}
