import * as React from 'react';
import * as classnames from 'classnames';
import { connect } from 'react-redux';
import { bem } from 'css-util';
import { State } from 'Store';

import NavBar from 'Nav/UI/NavBar';

interface OwnProps {
  title?: string
  children?: React.ReactNode,
  className?: string
  initBreadcrumb?: string
}

interface StateProps {
  isOpen: boolean,
  showBanner: boolean,
  programName: string,
  postPageLabel: string
}

type Props = OwnProps & StateProps;

const CSS = bem('nav-bar-layout');

const NavBarLayout = (props: Props) => {
  return (
    <div className={classnames(props.className, CSS()())}>
      {props.showBanner &&
       <div className={CSS('program-banner')()}>{props.programName}</div>
      }
      <div className={CSS('nav-bar')()}>
        <NavBar
          title={props.title} initBreadcrumb={props.initBreadcrumb}
          postPageLabel={props.postPageLabel}
        />
      </div>
      <div className={CSS('content-wrap')()}>
        <div className={CSS('content')({ blur: props.isOpen })}>
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state: State) => {
    const program = state.currentUser.program;
    const showBanner = program && program.features.displayBanner;
    const programName = program ? program.name : '';
    const postPageLabel = program ? program.postPageLabel : 'Post Activity';
    return {
      isOpen: state.nav.open,
      programName,
      showBanner,
      postPageLabel
    };
  }
)(NavBarLayout);
