import * as React from 'react';

import * as Urls from 'Shared/Urls';
import * as Clipboard from 'Shared/Clipboard'

import { ChallengeId, Team } from 'Challenges/Data';

interface Props {
  challengeId: ChallengeId,
  team: Team,
  onSuccess: () => void
}

const TeamUrl = (props: Props) => {
  const url = buildUrl(props.challengeId, props.team);
  const copyText = `${props.team.name}: ${url}`;
  const copyUrl = () => {
    Clipboard.copy(copyText);
    props.onSuccess();
  }

  return (
    <div className="text-center">
      <p>
        copy the link to my team page (so you can email or text it to others)
      </p>
      <p className="challenge-team__team-link">
        {copyText}
      </p>
      <p>
        <button onClick={copyUrl}
          className="button theme__primary-bg">
          copy team name and link
        </button>
      </p>
    </div>
  );
};

export default TeamUrl;

function buildUrl(challengeId: ChallengeId, team: Team): string {
  const path = Urls.challengeTeamUrl(challengeId, team.id)
  return Urls.absoluteAppUrl(path);
}
