import { OK, POST, ApiClient } from 'Api';


export interface ContactAPI {
  submitContactForm(message: string): OK
}

export function makeApi(client: ApiClient): ContactAPI {
  return {
    submitContactForm
  };

  function submitContactForm(message: string): OK {
    return client.requestJSON(
      POST, '/feedback', { feedback: { text: message }}
    );
  }
}
