import * as R from 'ramda';
import * as React from 'react';

import {
  Biopost, Activity, RecommendedActivity, ActivityId, CategoryInfo
} from 'Post/Data';

import ActivityUI from './Activity';

interface Props {
  activities: (Activity | RecommendedActivity)[],
  category?: CategoryInfo,
  favoriteIds?: ActivityId[],
  onFavorite?: (activityId: ActivityId) => void
  onUnfavorite?: (activityId: ActivityId) => void
  afterPost?: (b: Biopost, a: Activity) => void
  // Only allow each activity to be posted once
  oneShot?: boolean
}

export default function renderActivityList(
  props: Props
): React.ReactElement<Props> {
  const activity = renderActivity(props);
  return (
    <table className="activity-list">
      {props.activities.map(activity)}
    </table>
  );
}


function renderActivity(p: Props): (a: Activity) => React.ReactNode {
  const afterPost = (b: Biopost, a: Activity) => {
    if (p.afterPost) { p.afterPost(b, a); }
  }
  return a => {
    const isFavorite =
      p.favoriteIds ? R.contains(a.id, p.favoriteIds) : false;
    return (
      <ActivityUI
        activity={a}
        category={p.category}
        afterPost={afterPost}
        key={a.id}
        isFavorite={isFavorite}
        onFavorite={p.onFavorite}
        onUnfavorite={p.onUnfavorite}
        oneShot={p.oneShot}
      >
      </ActivityUI>
    );
  };
}
