import * as R from 'ramda';
import * as Cordova from 'Cordova';

/*------------------------------------------------------------*/

interface NativePluginPublic {
  available: (onSuccess: () => void, onFailure: () => void) => void

  requestAuthorization: (
    types: { readTypes?: HKSampleType[], writeTypes?: HKSampleType[] },
    onAccept: () => void,
    onReject: () => void
  ) => void,

  monitorSampleType(
    types: { sampleType: HKSampleType },
    cb: (sampleType: HKSampleType) => void
  ): void,

  queryCorrelationType(
    params: HKCorrelationTypeQuery,
    onSuccess: (samples: HKCorrelation[]) => void,
    onError: (error: any) => void
  ): void,

  sumQuantityType(
    params: HKSumQuantityTypeQuery<HKQuantityType>,
    onSuccess: (sum: HKSum) => void,
    onError: (error: any) => void
  ): void
}

interface NativePluginPrivate {
  querySampleType(
    params: HKSampleTypeQuery<HKSampleType>,
    onSuccess: (samples: HKSample[]) => void,
    onError: (error: any) => void
  ): void,
}

type NativePlugin = NativePluginPublic & NativePluginPrivate;

export type Plugin = NativePluginPublic & {
  queryQuantityType(
    params: HKSampleTypeQuery<HKQuantityType>,
    onSuccess: (samples: HKQuantitySample[]) => void,
    onError: (error: any) => void
  ): void,

  querySleepAnalysis(
    params: DateRangeQuery,
    onSuccess: (samples: HKSleepCategorySample[]) => void,
    onError: (error: any) => void
  ): void
}

export interface HKSampleTypeQuery<T> {
  sampleType: T,
  unit?: HKUnit,
  startDate: Date,
  endDate: Date,
  limit: number,
  ascending?: boolean
}

export interface HKSumQuantityTypeQuery<T> {
  sampleType: T,
  unit?: HKUnit,
  startDate: Date,
  endDate: Date
}

export interface HKCorrelationTypeQuery {
  correlationType: HKCorrelationType,
  unit: HKUnit | undefined,
  startDate: Date,
  endDate: Date
}

export interface DateRangeQuery {
  startDate: Date,
  endDate: Date
}

export type HKQuantityType
  = 'HKQuantityTypeIdentifierBodyMass'
  | 'HKQuantityTypeIdentifierHeight'
  | 'HKQuantityTypeIdentifierStepCount'
  | 'HKQuantityTypeIdentifierBloodPressureSystolic'
  | 'HKQuantityTypeIdentifierBloodPressureDiastolic'
  | 'HKQuantityTypeIdentifierBodyFatPercentage'
  | 'HKQuantityTypeIdentifierBloodGlucose'
  | 'HKQuantityTypeIdentifierOxygenSaturation'
  | 'HKQuantityTypeIdentifierBodyTemperature'
  | 'HKQuantityTypeIdentifierRestingHeartRate';

export type HKCharacteristicType
  = 'HKCharacteristicTypeIdentifierBiologicalSex';

export type HKCorrelationType
  = 'HKCorrelationTypeIdentifierBloodPressure';

export type HKCategoryType
  = 'HKCategoryTypeIdentifierSleepAnalysis';

export type HKSampleType
  = HKQuantityType | HKCharacteristicType | HKCorrelationType | HKCategoryType;

export type HKUnit
  = 'count'
  | 'lb'
  | 'in'
  | 'mmHg'
  | 'gender'
  | '%'
  | 'mg/dL'
  | 'count/min'
  | 'degF';

export type HKSample
  = HKQuantitySample
  | HKCorrelation
  | HKSleepCategorySample
  | HKSum;

/**
 * These are what come out of querySampleType
 */
export interface HKQuantitySample {
  UUID: string,
  quantity: number,
  startDate: string,
  endDate: string,
  metadata: any,
  sourceBundleId: string,
  sourceName: string
}

export interface HKSum {
  sampleType: HKQuantityType,
  sum: number,
  startDate: string,
  endDate: string
}

/**
 * These come out of queryCorrelationType
 */
export interface HKCorrelation {
  UUID: string,
  correlationType: HKCorrelationType,
  startDate: string,
  endDate: string,
  metadata: any,
  samples: HKCorrelationSample[]
}

export interface HKCorrelationSample {
  UUID: string,
  sampleType: HKQuantityType,
  startDate: string,
  endDate: string,
  unit: HKUnit,
  value: number,
  metadata: any
}

export interface HKCategorySample<C> {
  UUID: string,
  'categoryType.identifier': HKCategoryType,
  'categoryType.description': string,
  sourceBundleId: string,
  sourceName: string,
  startDate: string,
  endDate: string,
  metadata: any,
  value: C
}

export type HKSleepCategorySample = HKCategorySample<SleepAnalysisCategory>;

/*------------------------------------------------------------*/
// Sleep

export enum SleepAnalysisCategory {
  InBed = 0,
  Asleep = 1,
  Awake = 2
}



/*------------------------------------------------------------*/

// This weird construct gets the type-checker to tell us when we've forgotten an
// HKQuantityType, whereas there is no way to simply have an array that will be
// type checked to contain ALL quantity types.
const _QUANTITY_TYPES: { [T in HKQuantityType]: {} } = {
  'HKQuantityTypeIdentifierBodyMass': {},
  'HKQuantityTypeIdentifierHeight': {},
  'HKQuantityTypeIdentifierStepCount': {},
  'HKQuantityTypeIdentifierBodyFatPercentage': {},
  'HKQuantityTypeIdentifierBloodPressureSystolic': {},
  'HKQuantityTypeIdentifierBloodPressureDiastolic': {},
  'HKQuantityTypeIdentifierBloodGlucose': {},
  'HKQuantityTypeIdentifierOxygenSaturation': {},
  'HKQuantityTypeIdentifierBodyTemperature': {},
  'HKQuantityTypeIdentifierRestingHeartRate': {}
};

export const QUANTITY_TYPES: HKQuantityType[]
  = R.keys(_QUANTITY_TYPES) as HKQuantityType[];


const _CORRELATION_TYPES: { [T in HKCorrelationType]: {} } = {
  'HKCorrelationTypeIdentifierBloodPressure': {}
}

export const CORRELATION_TYPES: HKCorrelationType[]
  = R.keys(_CORRELATION_TYPES) as HKCorrelationType[];


const _CHARACTERISTIC_TYPES: { [T in HKCharacteristicType]: {} } = {
  'HKCharacteristicTypeIdentifierBiologicalSex': {}
};

export const CHARACTERISTIC_TYPES: HKCharacteristicType[]
  = R.keys(_CHARACTERISTIC_TYPES) as HKCharacteristicType[];

const _CATEGORY_TYPES: { [T in HKCategoryType]: {} } = {
  'HKCategoryTypeIdentifierSleepAnalysis': {}
};

export const CATEGORY_TYPES: HKCategoryType[]
  = R.keys(_CATEGORY_TYPES) as HKCategoryType[];

export const SAMPLE_TYPES: HKSampleType[] =
  (
    QUANTITY_TYPES as HKSampleType[]
  ).concat(
    CHARACTERISTIC_TYPES as HKSampleType[]
  ).concat(
    CORRELATION_TYPES as HKSampleType[]
  ).concat(
    CATEGORY_TYPES as HKSampleType[]
  );

const UNIT_MAP: { [T in HKSampleType]?: HKUnit | undefined } = {
  'HKQuantityTypeIdentifierBodyMass': 'lb',
  'HKQuantityTypeIdentifierHeight': 'in',
  'HKQuantityTypeIdentifierStepCount': 'count',
  'HKQuantityTypeIdentifierBodyFatPercentage': '%',
  'HKQuantityTypeIdentifierBloodPressureSystolic': 'mmHg',
  'HKQuantityTypeIdentifierBloodPressureDiastolic': 'mmHg',
  'HKQuantityTypeIdentifierBloodGlucose': 'mg/dL',
  'HKCategoryTypeIdentifierSleepAnalysis': undefined,
  'HKCorrelationTypeIdentifierBloodPressure': 'mmHg',
  'HKCharacteristicTypeIdentifierBiologicalSex': undefined,
  'HKQuantityTypeIdentifierOxygenSaturation': '%',
  'HKQuantityTypeIdentifierBodyTemperature': 'degF',
  'HKQuantityTypeIdentifierRestingHeartRate': 'count/min'
};

export const SAMPLE_TYPES_FOR_READ_AUTHORIZATION: HKSampleType[] = [
  'HKQuantityTypeIdentifierBodyMass',
  'HKQuantityTypeIdentifierHeight',
  'HKQuantityTypeIdentifierStepCount',
  'HKQuantityTypeIdentifierBodyFatPercentage',
  'HKQuantityTypeIdentifierBloodPressureSystolic',
  'HKQuantityTypeIdentifierBloodPressureDiastolic',
  'HKQuantityTypeIdentifierBloodGlucose',
  'HKCharacteristicTypeIdentifierBiologicalSex',
  'HKCategoryTypeIdentifierSleepAnalysis',
  'HKQuantityTypeIdentifierOxygenSaturation',
  'HKQuantityTypeIdentifierBodyTemperature',
  'HKQuantityTypeIdentifierRestingHeartRate'
];

export const SAMPLE_TYPES_FOR_MONITORING: HKSampleType[] = [
  'HKQuantityTypeIdentifierBodyMass',
  'HKQuantityTypeIdentifierHeight',
  'HKQuantityTypeIdentifierStepCount',
  'HKQuantityTypeIdentifierBodyFatPercentage',
  'HKQuantityTypeIdentifierBloodGlucose',
  'HKCorrelationTypeIdentifierBloodPressure',
  'HKCharacteristicTypeIdentifierBiologicalSex',
  'HKCategoryTypeIdentifierSleepAnalysis',
  'HKQuantityTypeIdentifierOxygenSaturation',
  'HKQuantityTypeIdentifierBodyTemperature',
  'HKQuantityTypeIdentifierRestingHeartRate'
]

export function unitsFor(s: HKSampleType): HKUnit | undefined {
  return UNIT_MAP[s];
}

export function isCharacteristicType(
  t: HKSampleType
): t is HKCharacteristicType {
  return R.contains(t, CHARACTERISTIC_TYPES);
}

export function isQuantityType(t: HKSampleType): t is HKQuantityType {
  return R.contains(t, QUANTITY_TYPES);
}

export function isCorrelationType(t: HKSampleType): t is HKCorrelationType {
  return R.contains(t, CORRELATION_TYPES);
}

export function isCategoryType(t: HKSampleType): t is HKCategoryType {
  return R.contains(t, CATEGORY_TYPES);
}

export function isSleepAnalysisType(t: HKSampleType): boolean {
  return t === 'HKCategoryTypeIdentifierSleepAnalysis';
}

export function isQuantitySample(s: HKSample): s is HKQuantitySample {
  return (s as HKQuantitySample).quantity !== undefined;
}

export function isCorrelation(s: HKSample): s is HKCorrelation {
  return (s as HKCorrelation).correlationType !== undefined;
}

export function isSum(s: HKSample): s is HKSum {
  return (s as HKSum).sum !== undefined;
}

export function isSleepCategorySample(s: HKSample): s is HKSleepCategorySample {
  return (s as HKSleepCategorySample)['categoryType.identifier']
    === 'HKCategoryTypeIdentifierSleepAnalysis';
}

/*------------------------------------------------------------*/

/*------------------------------------------------------------*/

/**
 * Check if healthkit is available. Note that promise is resolved w/ a boolean
 * value that indicates if it is available, as opposed to being rejected, which
 * only happens in the case of an error.
 */
function available(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    Cordova.ready()
      .then(() => {
        const healthkit = attemptToGetPlugin();

        if (healthkit) {
          healthkit.available(() => resolve(true), reject);
        } else {
          return false;
        }
      });
  });
}

/**
 * Promise resolved w/ the HKPlugin instance if available, otherwise the
 * promise is rejected.
 */
function plugin(): Promise<NativePlugin> {
  return available()
    .then(isAvailable => {
      const hk = attemptToGetPlugin();
      if (isAvailable && hk) {
        return hk;
      } else {
        throw new Error('HealthKit is not available');
      }
    });
}

/**
 * Request permissions from healthkit.  Promise is resolved if permissions are
 * accepted, and rejected in permissions are not accepted
 *
 * This is how you should acqurie the plugin
 */
export function requestPermissions(): Promise<Plugin> {
  return new Promise((resolve, reject) => {
    plugin()
      .then(hk => {
        hk.requestAuthorization(
          { readTypes: SAMPLE_TYPES_FOR_READ_AUTHORIZATION },
          () => resolve(wrappedPlugin(hk)),
          reject
        );
      })
  });
}

function attemptToGetPlugin(): NativePlugin | undefined {
  const plugins = (window as any).plugins;
  return plugins.healthkit;
}


/*------------------------------------------------------------*/

function wrappedPlugin(plugin: NativePlugin): Plugin {
  return {
    available: plugin.available,
    requestAuthorization: plugin.requestAuthorization,
    monitorSampleType: plugin.monitorSampleType,
    queryCorrelationType: plugin.queryCorrelationType,
    sumQuantityType: plugin.sumQuantityType,
    queryQuantityType,
    querySleepAnalysis
  };

  function querySleepAnalysis(
    params: DateRangeQuery,
    onSuccess: (samples: HKSleepCategorySample[]) => void,
    onError: (error: any) => void
  ) {
    plugin.querySampleType(
      {
          ...params,
        sampleType: 'HKCategoryTypeIdentifierSleepAnalysis',
        limit: 0,
        ascending: false
      },
      onSuccess,
      onError
    );
  }

  function queryQuantityType(
    params: HKSampleTypeQuery<HKQuantityType>,
    onSuccess: (samples: HKQuantitySample[]) => void,
    onError: (error: any) => void
  ): void {
    plugin.querySampleType(params, onSuccess, onError);
  }
}
