import * as React from 'react';
import { Loadable, isLoaded } from 'misc/Data/Loadable';
import { Biopost } from 'Post/Data';
import Spinner from 'Shared/UI/Spinner';
import BiopostUI from './Biopost';

interface Props {
  biopost: Loadable<Biopost | undefined>
  showScore: boolean
}
interface State {}

class LastBiopost extends React.Component<Props, State> {
  render() {
    const post = this.props.biopost;
    return (
      <div className="last-biopost">
        {isLoaded(post) ?
         this.renderLoaded(post.value) :
         this.renderNotLoaded()}
      </div>
    );
  }

  renderLoaded(post: Biopost | undefined) {
    return post ? this.renderBiopost(post) : this.renderNoBiopost();
  }

  renderNotLoaded() {
    return <Spinner wrap="center" />
  }

  renderBiopost(post: Biopost) {
    return (
      <BiopostUI
        showScore={this.props.showScore}
        biopost={post}
        currentUserId={post.userId}/>
    );
  }

  renderNoBiopost() {
    return (
      <div className="no-content">
        Choose an Activity below
      </div>
    );
  }
}

export default LastBiopost;
