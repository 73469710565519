import * as R from 'ramda';
import { Loadable, loadable } from 'misc/Data/Loadable';
export type NSType = 'ONBOARD';
export const NS: NSType = 'ONBOARD';


export interface State {
  config: Loadable<Config>,
  steps: Loadable<Step[]>
}

export interface Step {
  number: number,
  key: StepKey,
  title: string,
  helpText: string
}

export type StepKey = keyof Config;

export interface Config {
  edge: boolean,
  post: boolean,
  goals: boolean,
  specs: boolean,
  careTasks: boolean,
  inbox: boolean
}


export function totalSteps(steps: Step[]): number {
  return steps.length;
}

export function getStep(steps: Step[], step: number): Step | undefined {
  return steps[step - 1];
}

export function isLastStep(steps: Step[], step: Step): boolean {
  return R.equals(step, steps[steps.length - 1]);
}

export function initialState(): State {
  return {
    config: loadable(),
    steps: loadable()
  };
}

export const ALL_STEPS: ({ [T in keyof Config]: Step }) = {
  edge: {
    number: 0,
    key: 'edge',
    title: 'Add devices and apps',
    helpText: '(don\'t worry if you don\'t have a device to add --'
    + ' when you\'re ready just tap "next")'
  },
  careTasks: {
    number: 0,
    key: 'careTasks',
    title: 'Learn to complete "Care Tasks"',
    helpText: '(complete one or more (or none) of today\'s care tasks, then ' +
      'tap "next")'
  },
  post: {
    number: 0,
    key: 'post',
    title: 'Learn to self-report or "Post"',
    helpText: '(tap one or more items that reflect your recent health activity,'
    + ' then tap "next")'
  },
  goals: {
    number: 0,
    key: 'goals',
    title: 'Set a goal',
    helpText: '(adding goals is optional -- when you\'re ready just'
    + ' tap "next")'
  },
  specs: {
    number: 0,
    key: 'specs',
    title: 'Update measurements',
    helpText: '(you can update all, some or none; and when you\'re'
    + ' ready just tap "next")'
  },
  inbox: {
    number: 0,
    key: 'inbox',
    title: 'View and send secure Messages',
    helpText: '(here is a sample view of a "MESSAGES" thread)'
  }
};

export function steps(config: Config): Step[] {
  return R.values<{ [k: string]: Step }, string>(
    R.pickBy(
      (_v, k: keyof Config) => config[k],
      ALL_STEPS
    )
  ).map<Step>((step: Step, i) => ({ ...step, number: i + 1 }));
}
