import { ActionType, withNS } from 'Home/Action'
import { ActionCreatorThunk } from 'Store';
import { Quicklink } from 'Home/Data'

export interface ActionCreators {
  loadQuicklinks(): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  loadQuicklinks
}

function loadQuicklinks(): ActionCreatorThunk {
  return (dispatch, _, { api }) =>
    api.home.getQuicklinks().then(
      ({ quicklinks: quicklinksJSON }) => {
        const quicklinks: Quicklink[] = quicklinksJSON;

        dispatch(withNS({
          type: ActionType.LOAD_QUICKLINKS__SUCCESS,
          quicklinks
        }));
      },
      () => dispatch(withNS({ type: ActionType.LOAD_QUICKLINKS__FAILURE }))
    );
}
