import { WithNS, withNS } from 'Shared/NSAction';

import { NS, NSType, Breadcrumb } from './Data';

export enum ActionType {
  OPEN_NAV_MENU = 'OPEN_NAV_MENU',
  CLOSE_NAV_MENU = 'CLOSE_NAV_MENU',
  SAVE_BREADCRUMB = 'SAVE_BREADCRUMB',
  POP_BREADCRUMB = 'POP_BREADCRUMB',
  CLEAR_BREADCRUMBS = 'CLEAR_BREADCRUMBS'
}

export interface OpenNavMenu {
  type: ActionType.OPEN_NAV_MENU
}

export interface CloseNavMenu {
  type: ActionType.CLOSE_NAV_MENU
}

export interface SaveBreadcrumb {
  type: ActionType.SAVE_BREADCRUMB,
  breadcrumb: Breadcrumb
}

export interface PopBreadcrumb {
  type: ActionType.POP_BREADCRUMB
}

export interface ClearBreadcrumbs {
  type: ActionType.CLEAR_BREADCRUMBS
}

export type Action
  = OpenNavMenu
  | CloseNavMenu
  | SaveBreadcrumb
  | PopBreadcrumb
  | ClearBreadcrumbs
;

export type NSAction = WithNS<NSType, Action>;
export const withNavNS: (a: Action) => NSAction = withNS(NS);
