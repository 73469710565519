import { HEALTH_KIT_LOG } from 'Env';
import * as moment from 'moment-timezone';
import * as R from 'ramda';
import * as Edge from 'Edge/Data';
import * as HK from 'iOS/HealthKit';
import { StoreDispatch } from 'Store';
import { ActionCreators as EdgeAC } from 'Edge/ActionCreator';

const PROVIDER_KEY = 'health_kit';

/* Amount of time in ms that we wait after receiving data before sending it on,
 * which gives time for other data to arrive and be sent as a batch
 */
const FLUSH_DELAY = 5000;

type HKCollector = Edge.Collector & {
  start: () => Promise<void>,
  stop: () => Promise<void>
}

export function makeCollector(
  dispatch: StoreDispatch,
  healthKitCollector: HK.Fetcher,
): HKCollector {
  let dataBuffer: Edge.DeviceData = {};
  let flushTimeout: number | undefined;

  return { sync, start, stop };

  async function sync() {
    return healthKitCollector.fetchOnce(
      data => handleHealthKitSamples(data),
      error => console.error('Error beginning HealthKit collection:', error)
    );
  }

  async function start() {
    return healthKitCollector.beginMonitoring(
      data => handleHealthKitSamples(data),
      error => console.error('Error beginning HealthKit collection:', error)
    );
  }
  async function stop() {
    return healthKitCollector.stopMonitoring();
  }

  function handleHealthKitSamples(
    data: { sampleType: HK.HKSampleType, samples: HK.HKSample[] }
  ): void {
    const { sampleType, samples } = data;
    log(
      'Received samples from healthkit:', sampleType, samples
    );
    if (samples.length > 0) {
      const eligibleSamples = filterEligibleSamples(samples);
      const data = hkSamplesToDeviceData(sampleType, eligibleSamples);
      dataBuffer = Edge.mergeDeviceData(dataBuffer, data);
      queueDataFlush();
    }
  }

  function queueDataFlush() {
    if (flushTimeout) {
      window.clearTimeout(flushTimeout);
    }
    flushTimeout = window.setTimeout(flushDataBuffer, FLUSH_DELAY);
  }

  function flushDataBuffer() {
    flushTimeout = undefined;
    dispatch(EdgeAC.receiveDeviceData(PROVIDER_KEY, dataBuffer));
    dataBuffer = {};
  }
}


function filterEligibleSamples(
  samples: HK.HKSample[]
): HK.HKSample[] {
  return R.filter(eligible, samples);

  function eligible(s: HK.HKSample): boolean {
    if (HK.isSleepCategorySample(s)) {
      return s.value === HK.SleepAnalysisCategory.Asleep;
    } else {
      return true;
    }
  }
}

function hkSamplesToDeviceData(
  sampleType: HK.HKSampleType, samples: HK.HKSample[]
): Edge.DeviceData {
  if (HK.isQuantityType(sampleType)) {
    return hkQuantitySamplesToDeviceData(sampleType, samples);
  } else if (HK.isCorrelationType(sampleType)) {
    return hkCorrelationSamplesToDeviceData(sampleType, samples);
  } else if (HK.isCategoryType(sampleType)) {
    return hkCategorySamplesToDeviceData(sampleType, samples);
  } else {
    return {};
  }
}

function hkQuantitySamplesToDeviceData(
  sampleType: HK.HKQuantityType, samples: HK.HKSample[]
): Edge.DeviceData {
  // Step data comes as HKSum values, so we need both
  const quantitySamples = samples.filter(HK.isQuantitySample);
  const sumSamples = samples.filter(HK.isSum);
  const quantityAndSumSamples = R.concat<HK.HKSum | HK.HKQuantitySample>(
    quantitySamples, sumSamples
  );

  switch(sampleType) {
    case 'HKQuantityTypeIdentifierBodyMass':
      return {
        weights: quantityAndSumSamples.map(sample => ({
          time: sampleTime(sample), weight: quantityValue(sample)
        }))
      };
    case 'HKQuantityTypeIdentifierHeight':
      return {
        heights: quantityAndSumSamples.map(sample => ({
          time: sampleTime(sample), height: quantityValue(sample)
        }))
      };
    case 'HKQuantityTypeIdentifierStepCount':
      return {
        steps: quantityAndSumSamples.map(sample => ({
          time: sampleTime(sample), stepCount: quantityValue(sample)
        }))
      };
    case 'HKQuantityTypeIdentifierBodyFatPercentage':
      return {
        bodyFats: quantityAndSumSamples.map(sample => ({
          time: sampleTime(sample), bodyFat: quantityValue(sample)
        }))
      };
    case 'HKQuantityTypeIdentifierBloodGlucose':
      return {
        glucoses: quantityAndSumSamples.map(sample => ({
          time: sampleTime(sample), glucose: quantityValue(sample)
        }))
      };
    case 'HKQuantityTypeIdentifierOxygenSaturation':
      return {
        spo2s: quantityAndSumSamples.map(sample => ({
          time: sampleTime(sample), spo2: quantityValue(sample)
        }))
      };
    case 'HKQuantityTypeIdentifierBodyTemperature':
      return {
        bodyTemps: quantityAndSumSamples.map(sample => ({
          time: sampleTime(sample), bodyTemp: quantityValue(sample)
        }))
      };
    case 'HKQuantityTypeIdentifierRestingHeartRate':
      return {
        restingHeartRates: quantityAndSumSamples.map(sample => ({
          time: sampleTime(sample), heartRate: quantityValue(sample)
        }))
      };
  }
  return {};
}

function hkCorrelationSamplesToDeviceData(
  sampleType: HK.HKCorrelationType, samples: HK.HKSample[]
): Edge.DeviceData {
  const corrSamples = samples.filter(HK.isCorrelation);
  switch(sampleType) {
    case 'HKCorrelationTypeIdentifierBloodPressure':
      return {
        bloodPressures: corrSamples.map(correlation => {
          const value = correlationValue(correlation)
          if (value) {
            return {
              time: moment(correlation.endDate),
              systolic: value[0],
              diastolic: value[1]
            }
          }
        }).filter(notUndefined)
      }
  }
}

function hkCategorySamplesToDeviceData(
  sampleType: HK.HKCategoryType, samples: HK.HKSample[]
): Edge.DeviceData {
  if (sampleType !== 'HKCategoryTypeIdentifierSleepAnalysis') { return {}; }

  const catSamples = samples.filter(HK.isSleepCategorySample);
  return {
    sleeps: catSamples.map(sample => ({
      time: sampleTime(sample), asleepDuration: asleepMinutes(sample)
    }))
  };
}


// to make typescript happy
function notUndefined<R>(x: R | undefined): x is R {
  return x !== undefined;
}

function asleepMinutes(sample: HK.HKSleepCategorySample): number {
  return moment(sample.endDate).diff(moment(sample.startDate), 'minutes');
}

function quantityValue(
  samp: HK.HKQuantitySample | HK.HKSum
): number {
  if (HK.isQuantitySample(samp)) {
    return samp.quantity;
  } else {
    return samp.sum;
  }
}

function correlationValue(
  corr: HK.HKCorrelation
): [number, number] | undefined {
  switch(corr.correlationType) {
    case 'HKCorrelationTypeIdentifierBloodPressure':
      const result =
        R.sortBy(correlationSampleSorter, corr.samples).map(s => s.value);
      if (result.length === 2) {
        return [result[0], result[1]];
      }
  }
}

/**
 * this function is used with `sortBy` to ensure that systolic/diastolic values
 * are sorted systolic followed by diastolic
 */
function correlationSampleSorter(s: HK.HKCorrelationSample): string {
  switch(s.sampleType) {
  case 'HKQuantityTypeIdentifierBloodPressureSystolic':
    return '0'
  case 'HKQuantityTypeIdentifierBloodPressureDiastolic':
    return '1';
  default:
    return '9'
  }
}

function sampleTime(sample: HK.HKSample): moment.Moment {
  return moment(sample.endDate);
}

function log(msg: string, ...more: any[]) {
  if (HEALTH_KIT_LOG) {
    console.log(`[HealthKit] ${msg}`, ...more);
  }
}
