import { ActionCreatorThunk } from 'Store';

import { ActionCreators as HUD } from 'HUD';

export interface ActionCreators {
  submitContactForm(
    message: string,
    onSuccess?: () => void
  ): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  submitContactForm
};

function submitContactForm(
  message: string, onSuccess?: () => void
): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.contact.submitContactForm(message).then(() => {
      dispatch(HUD.success());
      if (onSuccess) { onSuccess(); }
    });
  };
}
