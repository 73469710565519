import { Paging, defaultPaging } from 'Shared/Paging';

export interface Feed<IDType> {
  objectIds: IDType[]
  paging: Paging
  loading: boolean
}

export function makeFeed<IDType>(
  objectIds?: IDType[], paging?: Paging
): Feed<IDType> {
  return {
    objectIds: objectIds || [],
    paging: paging || defaultPaging(),
    loading: false
  };
}

export function feedSetPaging<T>(paging: Paging, feed: Feed<T>): Feed<T> {
  return { ...feed, paging };
}

export function feedSetIds<T>(ids: T[], feed: Feed<T>): Feed<T> {
  return { ...feed, objectIds: ids };
}

export function feedPaging<T>(feed: Feed<T>): Paging {
  return feed.paging;
}
export function feedIds<T>(feed: Feed<T>): T[] {
  return feed.objectIds;
}
export function feedIsLoading<T>(feed: Feed<T>): boolean {
  return feed.loading;
}
