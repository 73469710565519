import './StatusCircle.scss';
import * as React from 'react';
import { bem } from 'css-util';
import Icon from 'Shared/UI/Icon';

export enum Status {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  COMPLETE = 'complete'
}

interface Props {
  status: Status
}

const CSS = bem('status-circle');

const StatusIcon = (props: Props) => {
  switch(props.status) {
    case Status.AVAILABLE:
      return AVAILABLE_ICON;
    case Status.UNAVAILABLE:
      return UNAVAILABLE_ICON;
    case Status.COMPLETE:
      return COMPLETE_ICON;
  }
}

export default StatusIcon;

const AVAILABLE_ICON = (
  <div className={CSS()(['available'])}></div>
);
const UNAVAILABLE_ICON = (
  <div className={CSS()(['unavailable'])}>✖</div>
);
const COMPLETE_ICON = (
  <Icon className={CSS()(['complete'])} name="check-circle-o" />
);
