import { CORDOVA } from 'Env';
import * as React from 'react';
import { NativeAppLink, NativeAppPlatform } from 'Auth/Data';

interface Props {
  links: NativeAppLink[]
}

const NativeAppLinks = (props: Props) => {
  if (CORDOVA || props.links.length === 0) {
    return null;
  } else {
    return (
      <div className="auth__note">
        {props.links.map(nativeAppLink)}
      </div>
    );
  }
}

export default NativeAppLinks;

function nativeAppLink(link: NativeAppLink, i: number) {
  return (
    <a key={i} href={link.downloadUrl} className="login-page__native-app-link">
      <img src={nativeAppImageSrc(link.platform)} />
    </a>
  )
}

const IOS_LINK_IMAGE_SRC = require('images/app-store/ios.png').default;
const ANDROID_LINK_IMAGE_SRC = require('images/app-store/android.png').default;

function nativeAppImageSrc(platform: NativeAppPlatform) {
  switch(platform) {
    case NativeAppPlatform.IOS:
      return IOS_LINK_IMAGE_SRC;
    case NativeAppPlatform.ANDROID:
      return ANDROID_LINK_IMAGE_SRC;
  }
}
