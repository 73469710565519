import * as React from 'react';

const EdgeInstructions = () => {
  return (
    <div>
      <p>
        <strong className="onboard-instructions__why-how">Why? </strong>
        Automatically post and participate in this program.
      </p>
      <p>
        <strong className="onboard-instructions__why-how">How? </strong>
        Manage your connected devices from the "Connected Devices" page - try
        it below.
      </p>
    </div>
  );
};

export default EdgeInstructions;
