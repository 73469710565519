import { NSType, HUDState } from './Data';

export enum ActionType {
  SET_HUDSTATE = 'SET_HUDSTATE'
}

export type Action = SetHUDState;

export type SetHUDState = {
  NS: NSType,
  type: ActionType.SET_HUDSTATE,
  hudState: HUDState
};
