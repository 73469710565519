import * as React from 'react';
import * as Urls from 'Shared/Urls';
import Logo from 'Shared/UI/Logo';

interface Props {
  programName: string
}
const ReportPrintFooter = (props: Props) => {
  return (
    <div>
      <div className="screen-report__pre-footer
                      theme__primary-bg
                      print-only">
        <strong>Tip</strong>: You can access this report and more in
    the {props.programName} app. Download it for your Apple or Android
    smartphone, or get it on the web at {Urls.externalLoginUrl()}. Need help
    logging in? E-mail us at hello@1bios.co.
      </div>

      <div className="screen-report__footer print-only">
        <div className="screen-report__footer__column">
          <span className="screen-report__footer-text">
            © 2014 - {new Date().getFullYear()} 1bios, Inc.
          </span>
        </div>
        <div className="screen-report__footer__column text-center">
          <span className="screen-report__footer-text">https://1bios.co</span>
        </div>
        <div className="screen-report__footer__column
                        text-right
                        screen-report__footer-logo">
          <Logo variant="text" />
        </div>
      </div>
    </div>
  );
}

export default ReportPrintFooter;
