import * as React from 'react';
import Link from 'Shared/UI/Link';
import { BreadcrumbAction } from 'Shared/UI/Link';
import { bem } from 'css-util';

interface Props {
  label: string,
  // Pass either a URL, or an onClick action
  action: string | (() => void),

  themed?: boolean,
  modifiers?: string[],
  appendClasses?: string
  breadcrumb?: BreadcrumbAction
}

const CSS = bem('challenge')('action-button');
export default function ActionButton(
  props: Props
): React.ReactElement<Props> {
  const classNames = [
    'button',
    props.themed ? 'theme__border-color theme__primary-color' : '',
    CSS(props.modifiers),
    props.appendClasses || ''
  ].join(' ');

  const linkProps = {
    className: classNames,
    to: props.action,
    breadcrumb: props.breadcrumb
  };
  return (
    <Link {...linkProps}>
      {props.label}
    </Link>
  );
}
