import * as moment from 'moment-timezone';
import * as React from 'react';
import * as Urls from 'Shared/Urls';
import { plural } from 'Shared/Text';
import Tile from './Tile';
import { DailyStatus, totalTasks, completedTasks } from 'CarePlans/Data';
import SimpleTileContent from './SimpleTileContent';

interface Props {
  dailyStatus: DailyStatus
}

const CarePlanTile = (props: Props) => {
  return (
    <Tile title="care tasks" linkLabel="complete tasks"
      linkUrl={Urls.carePlanCareTasksUrl(moment())}>
      {renderStatus(props.dailyStatus)}
    </Tile>
  );
}

export default CarePlanTile;

function renderStatus(status: DailyStatus) {
  const stat =
    `${completedTasks(status)} of ${plural('task', totalTasks(status))}`;
  return (
    <SimpleTileContent
      title="Completed today"
      badge={status.date.format('M/D/YYYY')}
      url={Urls.carePlanCareTasksUrl(moment())}
      quickStat={stat}
      icon="badge-check"
    />
  );
}
