import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bem } from 'css-util';
import { DispatchProps } from 'Store';
import {
  AuthSettings,
  LoginSubmission
} from 'Auth/Data';
import * as Urls from 'Shared/Urls';
import Link from 'Shared/UI/Link';
import { ActionCreators } from 'Auth/ActionCreator';

import AuthPage from './AuthPage';
import LoginForm from 'Auth/UI/LoginForm';
import NativeAppLinks from 'Auth/UI/NativeAppLinks';
import AuthHelp from 'Auth/UI/AuthHelp';
import AuthTermsAccept from 'Auth/UI/AuthTermsAccept';

type Props = DispatchProps & RouteComponentProps<URLParams>;

interface URLParams {
  programSlug?: string
}

const CSS = bem('login-page');

class LoginPage extends React.Component<Props, {}> {
  login = (creds: LoginSubmission) => {
    return this.props.dispatch(ActionCreators.login(creds));
  }

  render() {
    return (
      <AuthPage contents={this.renderForm} />
    );
  }

  renderForm = (settings: AuthSettings) => {
    return (
      <div>
        {settings.tagline &&
         <p className={CSS('tagline')()}>{settings.tagline}</p>}
        <LoginForm onLogin={this.login} />
        <NativeAppLinks links={settings.nativeApps} />
        {this.renderForgotPasswordLink(settings)}
        {this.renderJoinLink(settings)}
        <AuthHelp settings={settings} />
        <AuthTermsAccept settings={settings} actionText="logging in" />
      </div>
    );
  }

  renderForgotPasswordLink(settings: AuthSettings) {
    return (
      <p className="auth__note">
        <Link to={Urls.forgotPasswordUrl(settings.programSlug)}>
          Forgot your password?
        </Link>
      </p>
    );
  }

  renderJoinLink(settings: AuthSettings) {
    if (settings.publicSignup) {
      return (
        <p className="auth__note">
          Don't have {settings.programName}?
          {' '}
          <Link to={Urls.signupUrl(settings.programSlug)}>
            Sign up
          </Link>
        </p>
      )
    }
  }
}

export default withRouter(
  connect(null, dispatch => ({ dispatch }))(LoginPage)
);
