import * as ENV from 'Env';
import * as Timeout from 'Shared/Timeout';
import * as Dev from 'Shared/Developer';
import { store } from 'Auth/LocalStorageTokenStore';

type LogFunction = (msg: string, ...more: any[]) => void

export type Logger = LogFunction & {
  debug: LogFunction,
  log: LogFunction,
  warn: LogFunction,
  error: LogFunction,
  silence: () => void,
  unsilence: () => void
}

type LogLevel = 'debug' | 'log' | 'warn' | 'error'
export function makeLogger(
  label: string,
  enabledKey: keyof typeof ENV,
  defaultLevel: LogLevel = 'log'
): Logger {
  let silence: boolean = false;

  return Object.assign(
    logFn(defaultLevel),
    {
      debug: logFn('debug'),
      log: logFn('log'),
      warn: logFn('warn'),
      error: logFn('error'),
      silence: () => { silence = true; },
      unsilence: () => { silence = false; }
    }
  );

  function logFn(l: LogLevel): LogFunction {
    return (msg: string, ...more: any[]) => {
      if (!silence && (ENV[enabledKey] || Dev.enabled(enabledKey))) {
        console[l](`[${label}] ${msg}`, ...more);
      }
    }
  }
}


const GLOBAL_REMOTE_LOGGER = remoteLogger(10000);

function remoteLogEnabled(): boolean {
  return Dev.enabled('REMOTE_LOG');
}

export function globalRemoteLogger() {
  return GLOBAL_REMOTE_LOGGER;
}

export function remoteLog(...msg: any[]) {
  if (remoteLogEnabled()) {
    console.log('[REMOTE LOG]', ...msg);
    const msgString = JSON.stringify(msg.map(o => o.toString()));
    const token = store.get();
    const headers: Record<string, string> =
      token ? { 'X-JWT-Token': token } : {};
    return fetch(
      `${ENV.API_URL_BASE}/api/v3/log?message=${encodeURIComponent(msgString)}`,
      { method: 'GET', headers }
    );
  }
}

interface RemoteLogger {
  log(...msg: any[]): void
  flush(): void
}

type TaggableRemoteLogger = RemoteLogger & {
  withTag(tag: string): RemoteLogger
}

export function remoteLogger(timeout?: number): TaggableRemoteLogger {
  let batch: string[] = [];

  let timeoutResolve: (() => void) | undefined;

  return { log, withTag, flush };

  function withTag(newTag: string): RemoteLogger {
    return {
      log: (...msg: any[]) => {
        log(...msg.map(tagged(newTag)))
      },
      flush
    };
  }

  function log(...msg: any[]): void {
    if (timeout) {
      if (timeoutResolve) { timeoutResolve(); }
      Timeout.timeout_(
        timeout,
        done => {
          timeoutResolve = done;
          concatMessage(...msg)
        }
      ).catch(() => {
        timeoutResolve = undefined;
        log('**FLUSH BY TIMEOUT**');
        flush();
      })
    } else {
      concatMessage(...msg);
    }
  }

  function flush(): void {
    if (timeoutResolve) {
      timeoutResolve();
      timeoutResolve = undefined;
    }
    remoteLog(...batch);
    batch = [];
  }

  function concatMessage(...msg: any[]) {
    batch = batch.concat(msg.map(timestamped));
  }

  function timestamped(m: any): string {
    const ts = (new Date()).toISOString();
    return `[${ts}] ${m}`;
  }

  function tagged(t: string | undefined): (msg: any) => string  {
    return msg => {
      return t ? `[${t}] ${msg}` : msg;
    }
  }
}
