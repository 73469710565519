import * as React from 'react';
import { validEmail } from 'Shared/Data/Email';
import AuthFormTextField from 'Auth/UI/AuthFormTextField';
import AuthFormSubmit from 'Auth/UI/AuthFormSubmit';
import AuthFormErrors from 'Auth/UI/AuthFormErrors';

interface Props {
  onSubmit: (email: string) => Promise<void>,
  codeExpired?: boolean
  codeInvalid?: boolean
}

interface State {
  email: string,
  error?: string
}

class ForgotPasswordForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { email: '' };
  }

  onEmailChange = (email: string) => this.setState({ email })

  isValid(): boolean {
    return this.state.email !== '' && validEmail(this.state.email);
  }

  onSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();
    this.setState({ error: undefined });
    if (this.isValid()) {
      this.props.onSubmit(this.state.email).then(
        () => this.setState({ error: undefined, email: '' })
      );
    } else {
      this.setState({ error: 'Please enter a valid email' });
    }
  }

  helperText(): string {
    if (this.props.codeExpired) {
      return `Hmmm – it looks like the link in your email has expired (we do
      that to help keep your account secure). No problem, enter your email
      here, and we’ll send you instructions on how to set a new password so
      you can login. If you have more than one email address, be sure to use
      the one where we sent the email you clicked on to get here!`;
    } else if (this.props.codeInvalid) {
      return `Hmmm – it looks like the link in your email is invalid. No
      problem, enter your email here, and we'll send you instructions on how
      to set a new password so you can login. If you have more than one email
      address, be sure to use the one where we sent the email you clicked on
      to get here!`
    } else {
      return `No password? No worries. Enter your email below and we'll send
      you instructions to reset.`;
    }
  }

  render() {
    return (
      <form className="auth-form" onSubmit={this.onSubmit}>
        <p className="auth__note">
          {this.helperText()}
        </p>
        <AuthFormErrors error={this.state.error} />
        <AuthFormTextField
          type="email"
          icon="email4"
          placeholder="email"
          value={this.state.email}
          onChange={this.onEmailChange} />
        <AuthFormSubmit>Submit</AuthFormSubmit>
      </form>
    );
  }
}

export default ForgotPasswordForm;
