import * as React from 'react';
import Badge from 'Shared/UI/Badge';
import ArrowLink from 'Shared/UI/ArrowLink';
import Icon from './Icon';
import ArrowLinkCaret from 'Shared/UI/ArrowLinkCaret';

interface Props {
  title: string
  badge: string
  url?: string
  quickStat?: string
  icon: string
}

const SimpleTileContent = (props: Props) => {
  return (
    <div className="simple-dashboard-tile__main-text">
      {props.url ? renderTileLink(props, props.url) : renderTileContents(props)}
    </div>
  );
}

export default SimpleTileContent;

function renderTileLink(props: Props, url: string) {
  return (
    <ArrowLink to={url} breadcrumb="push">
      {renderTileContents(props)}
    </ArrowLink>
  )
}

function renderTileContents(props: Props) {
  return (
    <div className="dashboard-tile__left">
      <div className="dashboard-tile__left-content">
        <div className="dashboard-tile__icon">
          <Icon name={props.icon}></Icon>
        </div>
        <div className="simple-dashboard-tile__line2">
          <span className="simple-dashboard-tile__title">{props.title}</span>
          <br/>
          <Badge>{props.badge}</Badge>
          <span className="simple-dashboard-tile__quick-stat">
            {props.quickStat}
          </span>
        </div>
      </div>
      {props.url && <ArrowLinkCaret />}
    </div>
  );
}
