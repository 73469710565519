import * as moment from 'moment-timezone';
import * as R from 'ramda';
import {
  DeviceTypeJSON, ProviderJSON, ProviderIntegrationTypeJSON, UserDeviceJSON,
  DeviceDataJSON
} from 'Api/JSON/Edge';
import {
  DeviceType, Provider, ProviderIntegrationType, UserDevice, DeviceData,
  DeviceProtocol
} from 'Edge/Data';

export function providerFromJSON(json: ProviderJSON): Provider {
  return {
    ...R.pick(['id', 'key', 'label', 'description', 'hidden'], json),
    deviceTypes: json.device_types.map(deviceTypeFromJSON),
    integrationType: integrationTypeFromJSON(json.integration_type),
    iconUrl: json.icon_url,
    connectIconUrl: json.connect_icon_url
  };
}

function integrationTypeFromJSON(
  json: ProviderIntegrationTypeJSON
): ProviderIntegrationType {
  return ProviderIntegrationType[json]
}

function deviceTypeFromJSON(json: DeviceTypeJSON): DeviceType {
  return {
    id: json.id,
    providerId: json.provider_id,
    name: json.name,
    shortName: json.short_name,
    protocol: deviceProtocolFromJSON(json.protocol),
    scanMatchers: json.scan_matchers.map(str => new RegExp(str)),
    formFactor: json.form_factor
  }
}

function deviceProtocolFromJSON(json: string): DeviceProtocol | undefined {
  switch(json) {
    case 'JStyle1963YH':
      return DeviceProtocol.JStyle1963YH;
    case 'JStyle1790':
      return DeviceProtocol.JStyle1790;
    case 'GreaterGoodsA6':
      return DeviceProtocol.GreaterGoodsA6;
  }
}

export function userDeviceFromJSON(json: UserDeviceJSON): UserDevice {
  return {
    deviceId: json.device_id,
    deviceTypeId: json.device_type_id,
    lastSyncAt: json.last_data_at ? moment.unix(json.last_data_at) : undefined
  };
}

export function deviceDataToJSON(data: DeviceData): DeviceDataJSON {
  const restingHeartRates =
    data.restingHeartRates && timesToTimestamps(data.restingHeartRates);
  const bloodPressures =
    data.bloodPressures && timesToTimestamps(data.bloodPressures);
  const bodyTemps = data.bodyTemps && timesToTimestamps(data.bodyTemps);
  const spo2s = data.spo2s && timesToTimestamps(data.spo2s);
  const steps = data.steps && timesToTimestamps(data.steps);
  const glucoses = data.glucoses && timesToTimestamps(data.glucoses);
  const bodyFats = data.bodyFats && timesToTimestamps(data.bodyFats);
  const heights = data.heights && timesToTimestamps(data.heights);
  const weights = data.weights && timesToTimestamps(data.weights);
  const sleeps = data.sleeps && timesToTimestamps(data.sleeps);
  const activeMinutes =
    data.activeMinutes && timesToTimestamps(data.activeMinutes);
  const hrvs = data.hrvs && timesToTimestamps(data.hrvs);
  const stressScores =
    data.stressScores && timesToTimestamps(data.stressScores);
  const continuousHeartRates =
    data.continuousHeartRates && timesToTimestamps(data.continuousHeartRates);

  return {
    restingHeartRates,
    continuousHeartRates,
    bloodPressures,
    bodyTemps,
    spo2s,
    steps,
    glucoses,
    bodyFats,
    heights,
    weights,
    sleeps,
    activeMinutes,
    hrvs,
    stressScores
  };
}

function timesToTimestamps<D extends { time: moment.Moment }>(
  data: D[]
): (D & { time: number })[] {
  return data.map(datum => ({ ...datum, time: datum.time.unix() }));
}
