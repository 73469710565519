import * as React from 'react';
import { bem } from 'css-util';
import { Props } from './FieldProps';

const CSS = bem('screening-field');
const TextField = (props: Props) => {
  const field = props.field;
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(field, e.target.value);
  };

  return (
    <div className={CSS()()}>
      <div className={CSS('label')(['inline'])}>
        <label>
          {props.field.label}
        </label>
      </div>
      <div className={CSS('field')(['inline'])}>
        <input type="text"
          className={CSS('input')(['text'])}
          placeholder={field.hint}
          value={props.value || ''}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default TextField;
