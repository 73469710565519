import * as R from 'ramda';
import * as moment from 'moment-timezone';
import * as React from 'react';

import noop from 'Shared/Noop';
import DailyNav from 'Shared/UI/DailyNav';
import ConnectedCareTasks from 'CarePlans/UI/ConnectedActionStatusList';

const CareTasksContents: React.SFC<{}> = () => {
  const date = moment();
  return (
    <div>
      <DailyNav
        currentDate={date} gotoDate={noop} validDate={R.always(false)}
      />
      <ConnectedCareTasks date={date} />
    </div>
  );
};

export default CareTasksContents;
