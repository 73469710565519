import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Loadable, fromLoadable, isLoaded } from 'misc/Data/Loadable';
import * as List from 'misc/Data/List';
import { defaultConnect } from 'Shared/ReduxComponent';
import * as Urls from 'Shared/Urls';
import { State, DispatchProps } from 'Store';
import { ActionCreators as UserAC } from 'User';
import { ActionCreators as PostAC } from 'Post/ActionCreator';
import { ActionCreators as OnboardAC } from 'Onboard/ActionCreator';
import { Step } from 'Onboard/Data';
import Spinner from 'Shared/UI/Spinner';
import Layout from 'Onboard/UI/Layout';
import StepContents from 'Onboard/UI/StepContents';

type Props = State & DispatchProps & RouteComponentProps<URLParams>;

interface URLParams {
  step: string
}

class OnboardStep extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(UserAC.loadCurrentUser());
    this.props.dispatch(OnboardAC.loadOnboardConfig());

    this.setupStep();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.stepNumber() !== this.stepNumber(prevProps) ||
      this.steps() !== this.steps(prevProps)
    ) {
      this.setupStep();
    }
  }

  setupStep() {
    if (!this.verifyStepNumber()) { return; }
    const step = this.step();
    if (step) {
      switch(step.key) {
        case 'post':
          this.props.dispatch(PostAC.resetActivities());
          this.props.dispatch(PostAC.loadOnboardActivities());
          break;
        case 'edge':
          break;
        case 'specs':
          break;
        case 'goals':
          break;
      }
    }
  }

  verifyStepNumber() {
    const steps = this.steps();
    if (isLoaded(steps)) {
      if (this.stepNumber() > steps.value.length) {
        this.props.dispatch(push(Urls.onboardingCompleteUrl()));
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  steps(props?: Props): Loadable<Step[]> {
    props = props || this.props;
    return props.onboard.steps;
  }

  step(): Step | undefined {
    return fromLoadable(
      steps => List.lookup(steps, this.stepIndex()),
      () => undefined,
      this.steps()
    );
  }

  stepNumber(props?: Props): number {
    props = props || this.props;
    return parseInt(props.match.params.step);
  }

  stepIndex(): number {
    return this.stepNumber() - 1;
  }

  render() {
    return fromLoadable(
      steps => this.renderWithLoadedSteps(steps),
      () => <Spinner />,
      this.steps()
    );
  }

  renderWithLoadedSteps(steps: Step[]) {
    const step = this.step();
    if (step) {
      return this.renderStep(steps, step);
    } else {
      return '';
    }
  }

  renderStep(steps: Step[], step: Step) {
    return (
      <Layout steps={steps} step={step} dispatch={this.props.dispatch}>
        <StepContents stepKey={step.key} appState={this.props} />
      </Layout>
    );
  }
}

export default defaultConnect(withRouter(OnboardStep));
