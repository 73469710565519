import { LocationListener } from 'history';
import { push } from 'connected-react-router';
import { AppStore } from 'Store';
import { isLoaded } from 'misc/Data/Loadable';
import * as Urls from 'Shared/Urls';
import { SEL as UserSEL, ScreeningStatus } from 'User';

/**
 * Implements a listener for `history.listen` which prevents users from leaving
 * the screening form on programs configured to not let you exit the HRA without
 * completing it
 */
export default function makeGuard(store: AppStore): LocationListener {
  return location => {
    const state = store.getState();
    const screeningStatus = UserSEL.screeningStatus(state.currentUser);
    const appFeatures = UserSEL.appFeatures(state.currentUser);
    if (isLoaded(screeningStatus) &&
        isLoaded(appFeatures) &&
        screeningStatus.value === ScreeningStatus.ACTIVE &&
        !appFeatures.value.allowExitHRA &&
        location.pathname !== Urls.screeningFormUrl()
       ) {
      store.dispatch(push(Urls.screeningFormUrl()))
    }
  }
}
