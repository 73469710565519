import * as EventEmitter from 'eventemitter3';
import * as BLE from 'BLE/Data';
import * as Data from './Data';
import Protocol from './Protocol';
import DFUPackage from './DFUPackage';
import * as CRC32 from 'crc-32';
import { makeLogger, Logger } from 'Shared/Log';

interface DFUInterface {
  update(rawPackageData: ArrayBuffer): Promise<void>
  onProgress(fn: (progress: number) => void): void
}

enum Events {
  PROGRESS = 'PROGRESS'
}

export default class DFU implements DFUInterface {
  private readonly events: EventEmitter<Events>;
  private readonly log: Logger;

  constructor(
    private readonly device: BLE.Device,
    private readonly bluetooth: BluetoothlePlugin.Bluetoothle
  ) {
    this.events = new EventEmitter();
    this.log = makeLogger('BLE::NordicDFU::DFU', 'DFU_LOG');
  }

  public onProgress(fn: (progress: number) => void) {
    this.events.on(Events.PROGRESS, fn);
  }

  public async update(rawPackageData: ArrayBuffer) {
    const pkg = new DFUPackage(rawPackageData);
    this.log('Verifying data package contents.');
    await pkg.verify();
    const protocol = new Protocol(this.device, this.bluetooth);

    try {
      this.log('Sending init packet');
      await this.sendInitPacket(protocol, pkg);

      this.log('Sending firmware image');
      await this.sendFirmwareImage(protocol, pkg);

      this.log('Complete!');
    } catch(e) {
      this.log('Error during update:', e);
      await protocol.abort();
    } finally {
      protocol.close();
      this.updateProgress(1);
    }
  }

  private async sendInitPacket(protocol: Protocol, pkg: DFUPackage) {
    const data = await pkg.initPacket();
    return this.sendObject(
      protocol,
      data,
      Data.ObjectType.INIT_PACKET,
      this.progressHandler(0, 0.05)
    );
  }

  private async sendFirmwareImage(protocol: Protocol, pkg: DFUPackage) {
    const data = await pkg.firmwareImage();
    return this.sendObject(
      protocol,
      data,
      Data.ObjectType.FIRMWARE_IMAGE,
      this.progressHandler(0.05, 1)
    );
  }

  /**
   * Invoke the commands to send binary data to the device.  Data may be init
   * packet or firmware image depending on object type.  The process is broken
   * down into "chunks" of a maximum size reported by the "select command".  For
   * each chunk, we invoke CREATE, send binary data, then invoked EXECUTE.  The
   * process is further broken down in that each chunk is sent in many packets
   * of the size of the MTU, which is currently fixed at 20 bytes.
   * After sending each chunk, we check the CRC and only execute and continue if
   * it is a match, otherwise we attempt some retries before aborting the whole
   * process.
   */
  private async sendObject(
    protocol: Protocol,
    data: Uint8Array,
    objectType: Data.ObjectType,
    progressHandler: (p: number) => void
  ) {
    const response: Data.SelectData = await protocol.select(objectType);
    const maxSize = response.maxSize;

    for (let chunkStart = 0; chunkStart < data.length; chunkStart += maxSize) {
      await this.sendObjectChunk(
        protocol, data, objectType, progressHandler,
        maxSize, chunkStart
      )
    }
  }

  private async sendObjectChunk(
    protocol: Protocol,
    data: Uint8Array,
    objectType: Data.ObjectType,
    progressHandler: (p: number) => void,
    maxSize: number,
    chunkStart: number,
    retries=8
  ): Promise<void> {
    let chunkEnd: number, chunkLength: number;
    if (data.length - chunkStart > maxSize) {
      chunkEnd = chunkStart + maxSize;
      chunkLength = maxSize;
    } else {
      chunkEnd = data.length;
      chunkLength = data.length % maxSize;
    }
    this.log(
      `Sending chunk ${chunkStart/maxSize}, start: ${chunkStart}, end: `+
        `${chunkEnd}, length: ${chunkLength}`
    );

    await protocol.create(objectType, chunkLength);
    await protocol.sendData(
      data.slice(chunkStart, chunkEnd),
      percent => {
        const totalSent = chunkStart + percent * chunkLength;
        const totalProgress = totalSent / data.length
        progressHandler(totalProgress);
      }
    );

    const crc: Data.CRCData = await protocol.crc();
    if (this.verifyCRC(data, crc)) {
      this.log(`Running EXECUTE on update for object type ${objectType}`);
      await protocol.execute();
    } else if (retries === 0) {
      this.log.error('No more retries, aborting.');
      throw new Error('Could not reliably send data.');
    } else {
      this.log.warn(`Retrying (${retries} retries left)`);
      await this.sendObjectChunk(
        protocol, data, objectType, progressHandler, maxSize, chunkStart,
        retries - 1
      );
    }
  }

  /**
   * Return a handler that reports the progress of the total process, starting
   * at some point partway thru, for a sub process that only takes up part of
   * the total.
   * In other words - if we say the "init packet" send takes up 5% of the total
   * process, and the "firmware image" send takes up the remaining 95%, then we
   * create a handler for the init packet with `progressHandler(0, 0.05)`, and
   * then a second handler for the firmware image with
   * `progressHandler(0.05, 1)`.
   * TODO: probably a more generalized way to "stack progresses"
   */
  private progressHandler(
    start: number, total: number
  ): (progress: number) => void {
    return progress => {
      const finalCalculatedProgress = start + (total - start) * progress;
      this.updateProgress(finalCalculatedProgress);
    }
  }

  private updateProgress(progress: number) {
    this.events.emit(Events.PROGRESS, progress);
  }

  private verifyCRC(data: Uint8Array, crc: Data.CRCData): boolean {
    if (crc.crc32 != CRC32.buf(data.slice(0, crc.offset))) {
      this.log.error('CRC does not match.')
      return false;
    } else {
      this.log('CRC successfully verified!');
      return true;
    }
  }
}
