import * as moment from 'moment-timezone';

import { DayOfWeekJSON, DateJSON, ISOTimeJSON } from 'Api/JSON/Shared';

import { DayOfWeek } from './Data/DayOfWeek';


export function dayOfWeekFromJSON(dowJSON: DayOfWeekJSON): DayOfWeek {
  switch(dowJSON) {
  case DayOfWeekJSON.SUNDAY:
    return DayOfWeek.SUNDAY;
  case DayOfWeekJSON.MONDAY:
    return DayOfWeek.MONDAY;
  case DayOfWeekJSON.TUESDAY:
    return DayOfWeek.TUESDAY;
  case DayOfWeekJSON.WEDNESDAY:
    return DayOfWeek.WEDNESDAY;
  case DayOfWeekJSON.THURSDAY:
    return DayOfWeek.THURSDAY;
  case DayOfWeekJSON.FRIDAY:
    return DayOfWeek.FRIDAY;
  case DayOfWeekJSON.SATURDAY:
    return DayOfWeek.SATURDAY;
  }
}

export function dayOfWeekToJSON(dow: DayOfWeek): DayOfWeekJSON {
  switch(dow) {
  case DayOfWeek.SUNDAY:
    return DayOfWeekJSON.SUNDAY;
  case DayOfWeek.MONDAY:
    return DayOfWeekJSON.MONDAY;
  case DayOfWeek.TUESDAY:
    return DayOfWeekJSON.TUESDAY;
  case DayOfWeek.WEDNESDAY:
    return DayOfWeekJSON.WEDNESDAY;
  case DayOfWeek.THURSDAY:
    return DayOfWeekJSON.THURSDAY;
  case DayOfWeek.FRIDAY:
    return DayOfWeekJSON.FRIDAY;
  case DayOfWeek.SATURDAY:
    return DayOfWeekJSON.SATURDAY;
  }
}

export function dateFromJSON(json: DateJSON): moment.Moment {
  return moment(json);
}

export function dateToJSON(date: moment.Moment): DateJSON {
  return date.format('YYYY-MM-DD');
}

export function isoTimeToJSON(time: moment.Moment): ISOTimeJSON {
  return time.format();
}

export function isoTimeFromJSON(json: ISOTimeJSON): moment.Moment {
  return moment(json);
}
