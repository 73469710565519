import { BPCalibration } from 'BLE/Protocols/JStyle1963YH/Data';

// Command reply:
// The check is correct and the execution OK returns:
// 0x6c AA BB CC DD 00 00 00 00 00 00 00 00 00 00 00 CRC
// Description:
// AA: The minimum value of low blood pressure. (The default is 60)
// BB: The maximum value of low blood pressure. (The default is 80)
// CC: The minimum value of high blood pressure. (The default is 110)
// DD: The maximum value of high blood pressure. (The default is 130)
export function decode(packet: Uint8Array): BPCalibration {
  return {
    minDiastolic: packet[1],
    maxDiastolic: packet[2],
    minSystolic: packet[3],
    maxSystolic: packet[4]
  };
}
