import { Loadable, loadable } from 'misc/Data/Loadable';
import { Maybe } from 'misc/Data/Maybe';
import * as M from 'misc/Data/Maybe';
export type NSType = 'TRENDS';
export const NS: NSType = 'TRENDS';

import {
  Metric,
  MetricKey,
  DataRequest,
  TrendData
} from '1bios/UserTrends/Data';
export * from '1bios/UserTrends/Data';

export interface State {
  availableMetrics: Loadable<Metric[]>,
  initialMetricKey: Loadable<Maybe<MetricKey>>,
  currentRequest: Maybe<DataRequest>,
  currentData: Maybe<TrendData>
}

export function initialState(): State {
  return {
    availableMetrics: loadable(),
    initialMetricKey: loadable(),
    currentRequest: M.nothing(),
    currentData: M.nothing()
  };
}
