import * as React from 'react';
import { push } from 'connected-react-router';
import * as Urls from 'Shared/Urls';
import { defaultConnect } from 'Shared/ReduxComponent';
import { State, StoreDispatch } from 'Store';
import { ActionCreators } from 'Maintenance/ActionCreator';

import ErrorPage from 'Shared/UI/ErrorPage';

type Props = State & {
  dispatch: StoreDispatch
}

class MaintenanceMode extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(ActionCreators.enableMaintenanceMode());
    this.props.dispatch(ActionCreators.pollForMaintenanceMode());
  }

  tryAgain = () => {
    this.props.dispatch(push(Urls.rootUrl()));
    window.location.reload();
  }

  render() {
    return (
      <ErrorPage
        icon="gears"
        title="1bios is down for maintenance"
        detail="Thanks for your patience, we'll be back again soon!"
        tryAgainTarget={this.tryAgain} />
    );
  }
}

export default defaultConnect(MaintenanceMode);
