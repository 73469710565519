import { State } from './Data';
import { Action, ActionType } from './Action';
import { loadable } from 'misc/Data/Loadable';

export function reducer(state: State, action: Action): State {
  switch(action.type) {
    case ActionType.LOAD_ACTION_STATUSES__BEGIN:
    case ActionType.CLEAR_ACTION_STATUSES:
      return {
        ...state, dailyStatus: loadable()
      };
    case ActionType.LOAD_ACTION_STATUSES__SUCCESS:
      return { ...state, dailyStatus: loadable(action.dailyStatus) };
  }
}
