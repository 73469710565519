import * as React from 'react';
import Link from 'Shared/UI/Link';

import { Team, TeamFormData, newTeamFormData } from 'Challenges/Data';

interface Props {
  team: Team | undefined,
  onSubmit: (data: TeamFormData) => void
}

interface State {
  data: TeamFormData
}

class TeamForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { data: newTeamFormData(props.team) }
  }

  componentDidUpdate(oldProps: Props) {
    if (oldProps.team !== this.props.team) {
      this.setState({ ...this.state, data: newTeamFormData(this.props.team) });
    }
  }

  onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ data: { ...this.state.data, name: e.target.value }});
  }

  submit = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    this.props.onSubmit(this.state.data);
  }

  isEdit(): boolean {
    return this.props.team !== undefined;
  }

  render() {
    const data = this.state.data;
    return (
      <div>
        <div>
          <label className="label" htmlFor="challenge-team-name">
            {this.isEdit() ? 'edit ' : ''}team name (50 char max)
          </label>
          <input type="text"
            id="challenge-team-name"
            className="text-field text-field--block"
            maxLength={50}
            value={data.name}
            onChange={this.onNameChange}
          />
        </div>
        <div className="text-right pt-1r">
          <Link
            themed={true}
            to={this.submit}
            className="button theme__primary-bg"
          >
            save
          </Link>
        </div>
      </div>
    );
  }
}

export default TeamForm;
