import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { push } from 'connected-react-router';
import { defaultConnect } from 'Shared/ReduxComponent';

import {
  dashboardUrl, challengeTemplateSetupUrl, challengesUrl
} from 'Shared/Urls';
import { State, StoreDispatch } from 'Store';

import {
  ChallengeTemplate, requiresPreJoinSetup, SEL
} from 'Challenges/Data';
import { ActionCreators } from 'Challenges/ActionCreator';
import UnenrolledDetails from 'Challenges/UI/UnenrolledDetails';
import { NavBarLayout } from 'Nav';
import { HUD } from 'HUD';

type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch,
};

type URLParams = {
  templateId: string
};

class TemplatePage extends React.Component<Props, {}> {
  componentDidMount() {
    if (this.templateId() === '') {
      this.props.dispatch(push(dashboardUrl()));
    } else if (!this.template()) {
      this.props.dispatch(ActionCreators.loadChallenges());
    }
  }

  templateId(): string {
    return this.props.match.params.templateId;
  }

  template(): ChallengeTemplate | undefined {
    return SEL.getTemplate(this.templateId(), this.props.challenges);
  }

  enrollAction(template: ChallengeTemplate): () => void {
    if (requiresPreJoinSetup(template)) {
      return () => this.props.dispatch(
        push(challengeTemplateSetupUrl(template.id))
      );
    } else {
      return () =>
        this.props.dispatch(ActionCreators.startChallenge(template.id));
    }
  }

  render() {
    const template = this.template();
    return (
      <NavBarLayout initBreadcrumb={challengesUrl()}>
        {template ?
         <UnenrolledDetails
           item={template}
           enrollAction={this.enrollAction(template)} />
         :
         <HUD state="loading" />
        }
      </NavBarLayout>
    );
  }
}

export default defaultConnect(TemplatePage);
