import * as React from 'react';
import { Team, TeamId } from 'Challenges/Data';
import NoContent from 'misc/UI/NoContent';

interface Props {
  teams: Team[],
  onSelect: (teamId: TeamId) => void
}

interface State {
  selectedTeamId: TeamId | undefined
}

class TeamSelect extends React.Component<Props, State> {
  state = { selectedTeamId: undefined }

  onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const teamId = parseInt(e.target.value)
    if (isNaN(teamId)) {
      this.setState({ selectedTeamId: undefined });
    } else {
      this.setState({ selectedTeamId: teamId });
    }
  }

  onSubmit = () => {
    const teamId = this.state.selectedTeamId;
    if (teamId !== undefined) {
      this.props.onSelect(teamId);
    }
  }

  render() {
    const teams = this.props.teams;
    if (teams.length === 0) {
      return this.renderWithoutTeams();
    } else {
      return this.renderWithTeams();
    }
  }

  renderWithoutTeams() {
    return (
      <NoContent>
        No teams have been created yet. Click "create a new team" below to
        start your own
      </NoContent>
    );
  }

  renderWithTeams() {
    const teams = this.props.teams;
    return (
      <div>
        <div>
          <select
            className="select select--block"
            value={this.state.selectedTeamId}
            onChange={this.onChange}
          >
            <option>&mdash; Select team &mdash;</option>
            {teams.map(team => (
              <option value={team.id} key={team.id}>
                {team.name}
              </option>
            ))}
          </select>
        </div>
        <div className="text-right pt-1r">
          <button
            onClick={this.onSubmit}
            className="button theme__primary-bg"
          >
            go
          </button>
        </div>
      </div>
    );
  }
}

export default TeamSelect;
