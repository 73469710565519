import * as React from 'react';
import * as classnames from 'classnames';

interface Props {
  children?: React.ReactNode,
  className?: string
}

const PostMenuListItem = (props: Props) => {
  const classes = classnames('post-menu__item', props.className);

  return (
    <li className={classes}>
      {props.children}
    </li>
  );
}

export default PostMenuListItem;
