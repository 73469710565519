import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';
import { NS, NSType } from './Data'
import { Quicklink } from 'Home/Data'

export enum ActionType {
  LOAD_QUICKLINKS__SUCCESS = 'LOAD_QUICKLINKS__SUCCESS',
  LOAD_QUICKLINKS__FAILURE = 'LOAD_QUICKLINKS__FAILURE'
}

export type Action
  = LoadQuicklinks_Success
  | LoadQuicklinks_Failure

export interface LoadQuicklinks_Success {
  type: ActionType.LOAD_QUICKLINKS__SUCCESS,
  quicklinks: Quicklink[]
}

export interface LoadQuicklinks_Failure {
  type: ActionType.LOAD_QUICKLINKS__FAILURE
}

export type NSAction = WithNS<NSType, Action>;
export const withNS = originalWithNS<NSType, Action>(NS);
