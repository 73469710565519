import * as React from 'react';
import * as moment from 'moment-timezone';
import * as R from 'ramda';

import DataTable from 'misc/UI/DataTable/DataTable';
import { ColumnData } from 'misc/UI/DataTable/Data';
import { Paging, pagingFromArray  } from 'misc/Data/Paging';
import {
  TrendData,
  Dataset,
  MetricKey,
  formatValueWithMetricKey,
  defaultSortDir,
} from '1bios/UserTrends/Data';

import { SortDir } from 'misc/UI/DataTable/Data';

interface Props {
  data: TrendData,
  currentSortKey: string,
  currentSortDir: SortDir,
  onSortSelected: (sortKey: string, sortDir: SortDir) => void
  currentPage: number,
  perPage: number,
  gotoPage: (page: number) => void
}

interface RowData {
  time: moment.Moment,
  values: { [key: string]: number }
}

const DataPointTable = (props: Props): React.ReactElement => {
  const {
    data, onSortSelected, currentSortKey, currentSortDir, currentPage, perPage,
    gotoPage
  } = props;
  const datasets = data.datasets;
  const cells = renderTableData(
    datasets, currentSortKey, currentSortDir, currentPage, perPage
  );
  return (
    <DataTable
      currentSortKey={currentSortKey}
      currentSortDir={currentSortDir}
      setSort={onSortSelected}
      columns={columns(datasets)}
      cells={cells}
      paging={paging(datasets, currentPage, perPage)}
      gotoPage={gotoPage}
    />
  );
}

function paging(
  datasets: Dataset[], currentPage: number, perPage: number
): Paging {
  const points = datasets[0].points;
  return pagingFromArray(points, currentPage, perPage);
}

function columns(datasets: Dataset[]): ColumnData[] {
  const dateAndTimeCols =
    [
      {
        key: 'date',
        label: 'date',
        defaultSortDir: defaultSortDir,
        isSortable: true
      },
      {
        key: 'time',
        label: 'time',
        defaultSortDir: defaultSortDir,
        isSortable: true
      }
    ];

  const metricCols = datasets.map(dataset => {
    return {
      key: dataset.key,
      label: dataset.label,
      defaultSortDir: defaultSortDir,
      isSortable: true
    };
  });
  return [...dateAndTimeCols, ...metricCols];
}

function renderTableData(
  datasets: Dataset[],
  currentSortKey: string,
  currentSortDir: SortDir,
  currentPage: number,
  perPage: number
): React.ReactElement[][] {
  const metricKeys = datasets.map(ds => ds.key);

  return R.compose(
    rowDataToTableRows(metricKeys),
    paginate(currentPage, perPage),
    sortRowData(currentSortKey, currentSortDir),
    datasetsToRowData
  )(datasets);
}

function datasetsToRowData(datasets: Dataset[]): RowData[] {
  return datasets[0].points.map((datum, index) => (
    {
      time: datum.time,
      values: datasets.reduce(
        (result, ds) => ({ ...result, [ds.key]: ds.points[index].value }),
        {}
      )
    }
  ));
}

const sortRowData = R.curry(_sortRowData);
function _sortRowData(
  sortKey: string, sortDir: SortDir, data: RowData[]
): RowData[] {
  const sortFn = (sortDir === 'asc') ? R.ascend : R.descend;
  return R.sort(
    sortFn(
      point => {
        switch(sortKey) {
          case 'date':
            return point.time;
          case 'time':
            return formatTime(point.time, 'HHmm')
          default:
            return point.values[sortKey]
        }
      }
    ),
    data
  );
}

const paginate = R.curry(_paginate);
function _paginate(
  currentPage: number, perPage: number, data: RowData[]
): RowData[] {
  return data.slice((currentPage - 1) * perPage, currentPage * perPage);
}

const rowDataToTableRows = R.curry(_rowDataToTableRows);
function _rowDataToTableRows(
  keys: MetricKey[], rows: RowData[]
): React.ReactElement[][] {
  return rows.map(point => rowDatumToTableRow(point, keys));
}

function rowDatumToTableRow(
  row: RowData, keys: MetricKey[]
): React.ReactElement[] {
  const valueCells = keys.map(key =>
    <div>{formatValueWithMetricKey(key, row.values[key])}</div>
  );

  return [
    <div>{formatTime(row.time, 'MMMM D, YYYY')}</div>,
    <div>{formatTime(row.time, 'h:mm A')}</div>,
    ...valueCells
  ];
}

function formatTime(pointTime: moment.Moment,  format: string): string {
  const  time = pointTime.clone();
  time.local();
  return time.format(format);
}

export default DataPointTable;
