import * as React from 'react';

import { defaultConnect } from 'Shared/ReduxComponent';
import { State, StoreDispatch } from 'Store';
import { hudState, currentProgram } from 'App/Data';

import * as Urls from 'Shared/Urls';
import Link from 'Shared/UI/Link';
import { NavBarLayout } from 'Nav';

import { HUD } from 'HUD';
import { ActionCreators } from '../ActionCreator';
import { ActionCreators as UserActionCreators, AppFeatures } from 'User';

import {
  NotificationSettings, FatlineAlert, FatlineTransmitter, DailyCoachSettings
} from '../Data';

import FatlineSettingsForm from '../UI/FatlineSettings';
import DailyCoachSettingsForm from '../UI/DailyCoachSettings';

type Props = State & {
  dispatch: StoreDispatch
}

class NotificationsPage extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.toggleFatlineAlert = this.toggleFatlineAlert.bind(this);
    this.updateDailyCoach = this.updateDailyCoach.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(ActionCreators.loadNotificationSettings());
    this.props.dispatch(UserActionCreators.loadCurrentUser());
  }

  settings(): NotificationSettings | undefined {
    return this.props.settings.notifications;
  }

  appFeatures(): AppFeatures | undefined {
    const program = currentProgram(this.props);
    return program ? program.features : undefined;
  }

  toggleFatlineAlert(
    alert: FatlineAlert, transmitter: FatlineTransmitter
  ): void {
    this.props.dispatch(ActionCreators.toggleFatlineAlert(alert, transmitter));
  }

  updateDailyCoach(dc: DailyCoachSettings) {
    this.props.dispatch(ActionCreators.updateDailyCoach(dc));
  }

  render() {
    const settings = this.settings();
    const features = this.appFeatures();
    const profileLink = (
      <Link to={Urls.profileSettingsUrl()} themed={true} breadcrumb="push">
        Profile
      </Link>
    );
    if (settings && features) {
      return (
        <NavBarLayout
          className="settings-notifications-page"
          initBreadcrumb={Urls.dashboardUrl()}
          title="Notifications"
        >
          <p className="settings__section-explanation">
            Notifications are sent to the email and/or mobile number in
            your {profileLink}.
          </p>
          <FatlineSettingsForm
            settings={settings.fatline}
            features={features}
            toggleAlert={this.toggleFatlineAlert}/>
          {features.displayDailyCoach &&
            <DailyCoachSettingsForm
              update={this.updateDailyCoach}
              settings={settings.dailyCoach}/>
          }
          <HUD state={hudState(this.props)}></HUD>
        </NavBarLayout>
      );
    } else {
      return <HUD state="loading"></HUD>;
    }
  }
}

export default defaultConnect(NotificationsPage);
