import { ActionCreatorThunk, ActionCreatorThunkThen } from 'Store';
import { push } from 'connected-react-router';
import * as Cordova from 'Cordova';

import * as Dialog from 'Shared/Dialog';
import * as Timeout from 'Shared/Timeout';
import * as Urls from 'Shared/Urls';
import * as Version from 'Shared/Data/Version';
import * as Edge from 'Edge/Data';
import * as BLE from 'BLE/Data';

import { withNS, ActionType } from './Action';
import { ActionCreators as HUD } from 'HUD';

import {
  JStyle1963YHProtocol, makeProtocol as makeJStyle1963YHProtocol
} from './Protocols/JStyle1963YH/Protocol';
import * as JStyleData from './Protocols/JStyle1963YH/Data';
import { DFU as JStyleDFU } from './Protocols/JStyle1963YH/DFU';

export interface ActionCreators {
  withBluetooth(): ActionCreatorThunkThen<BluetoothlePlugin.Bluetoothle>,
  withBluetoothOrFail(): ActionCreatorThunkThen<BluetoothlePlugin.Bluetoothle>,
  startScan(duration?: number): ActionCreatorThunk,
  stopScan(): ActionCreatorThunk,
  JStyle1963YH: JStyle1963YHActionCreators
}

interface JStyle1963YHActionCreators {
  sendBPCalibration(
    deviceType: Edge.DeviceType,
    device: BLE.Device,
    systolic: number,
    diastolic: number
  ): ActionCreatorThunk,
  checkFirmware(
    deviceType: Edge.DeviceType, device: BLE.Device
  ): ActionCreatorThunk,
  upgradeFirmware(
    deviceType: Edge.DeviceType, device: BLE.Device
  ): ActionCreatorThunk
}

const JStyle1963YHActionCreators: JStyle1963YHActionCreators = {
  sendBPCalibration: jstyle1963SendBPCalibration,
  checkFirmware: jstyle1963CheckFirmware,
  upgradeFirmware: jstyle1963UpgradeFirmware
}

export const ActionCreators: ActionCreators = {
  withBluetooth,
  withBluetoothOrFail,
  startScan,
  stopScan,
  JStyle1963YH: JStyle1963YHActionCreators
}

/**
 * Resolves if bluetooth is available. When bluetooth is not available, neither
 * resolves nor rejects. Use this if you want code to run only with bluetooth,
 * and just want to ignore when theres is no bluetooth
 */
function withBluetooth():
ActionCreatorThunkThen<BluetoothlePlugin.Bluetoothle> {
  return async (dispatch, getState) => new Promise(async resolve => {
    await dispatch(initBluetooth());
    if (BLE.hasBLE(getState().ble)) {
      resolve(bluetoothle);
    }
  })
}

function withBluetoothOrFail():
ActionCreatorThunkThen<BluetoothlePlugin.Bluetoothle> {
  return async (dispatch, getState) => {
    if (!Cordova.isCordova()) {
      return Promise.reject('Bluetooth is not available on this platform.');
    }
    await dispatch(initBluetooth());
    if (BLE.hasBLE(getState().ble)) {
      return bluetoothle;
    } else {
      return Promise.reject('Could not access bluetooth.');
    }
  }
}

function initBluetooth(): ActionCreatorThunk {
  return async (dispatch, _, { bluetooth }) => {
    await Cordova.ready();
    await bluetooth.withBluetooth(
      status => {
        if (status === 'enabled') {
          dispatch(withNS({ type: ActionType.BLE_AVAILABLE }));
        } else {
          dispatch(withNS({ type: ActionType.BLE_NOT_AVAILABLE }));
        }
      }
    ).catch(console.warn);
  };
}

function startScan(duration=15000): ActionCreatorThunk {
  return async (dispatch, _, { bluetooth }) => {
    await dispatch(withBluetooth());

    await bluetooth.startScan(device => {
      dispatch(withNS({
        type: ActionType.BLE_SCAN_FOUND_DEVICE,
        device
      }));
    });
    dispatch(withNS({ type: ActionType.BLE_SCAN_START }));
    await Timeout.sleep(duration);

    dispatch(stopScan());
  };
}

function stopScan(): ActionCreatorThunk {
  return async (dispatch, getState, { bluetooth }) => {
    await dispatch(withBluetooth());
    const ble = getState().ble;
    if (ble.scanStatus === BLE.ScanStatus.SCANNING) {
      dispatch(withNS({ type: ActionType.BLE_SCAN_STOP }));
      await bluetooth.stopScan().catch();
    }
  };
}

function jstyle1963SendBPCalibration(
  deviceType: Edge.DeviceType,
  device: BLE.Device,
  systolic: number,
  diastolic: number
): ActionCreatorThunk {
  return async (dispatch) => {
    dispatch(HUD.loading());
    try {
      const ble = await dispatch(withBluetoothOrFail());
      const protocol = jstyle1963Protocol(ble, device, deviceType);
      await protocol.init();

      const supportsBPCalibration = await protocol.supportsBPCalibration();
      if (!supportsBPCalibration) {
        Dialog.alert('This device does not support BP calibration.');
        dispatch(HUD.error());
        return;
      }

      if (
        isNaN(systolic) || isNaN(diastolic) || systolic <= 0 || diastolic <= 0
      ) {
        await Dialog.alert(
          `Please enter a valid systolic and diastolic value.`
        );
        dispatch(HUD.error());
        return;
      }
      await protocol.setBPCalibration(systolic, diastolic);
      await dispatch(HUD.success());
    } catch(e) {
      await Dialog.alert('Bluetooth is not currently available.');
      await dispatch(HUD.error());
    }
  };
}

function jstyle1963CheckFirmware(
  deviceType: Edge.DeviceType,
  device: BLE.Device
): ActionCreatorThunk {
  return async (dispatch, getState, { api }) => {
    dispatch(HUD.loading());
    try {
      const ble = await dispatch(withBluetoothOrFail());
      let currentVersion: Version.Version | undefined;
      let latestVersion: Version.Version | undefined;

      if (Edge.isSyncing(getState().edge, device.address)) {
        await Dialog.alert(
          'Please wait for device to finish syncing before checking for '+
            'firmware updates.'
        );
      } else {
        const protocol = jstyle1963Protocol(ble, device, deviceType);
        currentVersion = await Timeout.timeout<Version.Version | undefined>(
          10000,
          async resolve => {
            await protocol.init();
            const v = protocol.getState().version;
            resolve(v && v.version);
          }
        ).catch(() => undefined);
      }

      const json = await api.edge.checkForFirmwareUpdates(deviceType.id);
      if (json.latest_version !== undefined) {
        latestVersion = Version.fromString(json.latest_version);
      }

      dispatch(withNS({
        type: ActionType.BLE_CHECK_FIRMWARE__COMPLETE,
        address: device.address,
        currentVersion,
        latestVersion
      }))

      await dispatch(HUD.close());
    } catch(e) {
      await(
        Dialog.alert(
          `Error while checking for firmware version: ${JSON.stringify(e)}`
        )
      );
      await dispatch(HUD.error());
    }
  }
}

function jstyle1963UpgradeFirmware(
  deviceType: Edge.DeviceType,
  device: BLE.Device
): ActionCreatorThunk {
  return async (dispatch, getState, { api }) => {
    if (Edge.isSyncing(getState().edge, device.address)) {
      await Dialog.alert('Please wait for device to finish syncing.');
      return;
    }
    dispatch(withNS({ type: ActionType.BLE_DFU_BEGIN }));
    dispatch(
      push(Urls.firmwareUpdateProgressUrl(deviceType.id, device.address))
    );
    try {
      const ble = await dispatch(withBluetoothOrFail());

      const status = getState().ble.firmwareStatuses[device.address];

      if (status && status.currentVersion && !BLE.needsUpgrade(status)) {
        const continueAnyway = await Dialog.confirmValue(
          'It appears that your firmware does not need to be upgraded.  '+
            'Perform upgrade anyway?'
        );
        if (!continueAnyway) { return; }
      }

      const rawFirmwareData = await api.edge.getLatestFirmware(deviceType.id);

      const dfu = new JStyleDFU(device, ble);
      dfu.onProgress(progress => dispatch(updateProgress(progress)))
      await dfu.update(rawFirmwareData);
      // Let some time pass before continuing to give the device a chance to
      // restart with the new firmware
      await Timeout.sleep(10000);
      await dispatch(HUD.success());
    } catch (e) {
      await Dialog.alert(
        e ? e.toString() : 'An error occurred. Please try again later.'
      )
      await dispatch(HUD.error());
    } finally {
      dispatch(withNS({ type: ActionType.BLE_DFU_COMPLETE }));
      dispatch(
        push(Urls.edgeDeviceSettingsUrl(deviceType.id, device.address))
      );
      dispatch(jstyle1963CheckFirmware(deviceType, device));
    }
  }
}

function jstyle1963Protocol(
  ble: BluetoothlePlugin.Bluetoothle,
  device: BLE.Device,
  deviceType: Edge.DeviceType
): JStyle1963YHProtocol {
  let family: JStyleData.DeviceFamily;
  if (deviceType.protocol) {
    family = JStyleData.familyFromProtocol(deviceType.protocol);
  } else {
    family = JStyleData.DeviceFamily.JStyle1963YH;
  }
  return makeJStyle1963YHProtocol(ble, device, family);
}

/**
 * Update progress during ongoing firmware update.  Prevents the "progress"
 * number from ever going backwards
 */
function updateProgress(progress: number): ActionCreatorThunk {
  return async (dispatch, getState) => {
    const lastProgress = getState().ble.progress;
    const rounded = Math.floor(progress * 100);
    if (lastProgress !== progress) {
      dispatch(withNS({
        type: ActionType.BLE_UPDATE_PROGRESS,
        progress: Math.max(rounded, lastProgress)
      }));
    }
  };
}
