import * as React from 'react';
import * as moment from 'moment-timezone';
import * as R from 'ramda';
import { RouteComponentProps } from 'react-router-dom';
import { push } from 'connected-react-router';
import * as qs from 'query-string';
import { defaultConnect } from 'Shared/ReduxComponent';
import {
  Loadable,
  isLoaded,
  loadableMap,
  loadableSequence
} from 'misc/Data/Loadable';
import * as M from 'misc/Data/Maybe';
import { Maybe } from 'misc/Data/Maybe';
import { maybeFind, maybeHead } from 'misc/Data/List';
import { State, StoreDispatch } from 'Store';
import { NavBarLayout } from 'Nav';
import { UserId, SEL as UserSEL, UserType } from 'User';
import { HUD } from 'HUD';
import * as Urls from 'Shared/Urls';
import {
  Metric,
  MetricKey,
  TrendWindow,
  DataRequest,
  TrendData,
  windowFromString,
  nextDate,
  previousDate,
  metricKeyFromString,
  defaultSortDir,
  defaultSortKey,
  defaultPerPage
} from 'Trends/Data';
import Spinner from 'Shared/UI/Spinner';
import UserBanner from 'User/UI/UserBanner';
import { ActionCreators } from 'Trends/ActionCreator';
import TrendsControls from '1bios/UserTrends/UI/TrendsControls';
import TrendsGraph from '1bios/UserTrends/UI/TrendsGraph';
import TrendsTable from '1bios/UserTrends/UI/TrendsTable';

import { SortDir, sortDirFromString } from 'misc/UI/DataTable/Data';

type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch
}

interface URLParams {
  userId: string,
  metricKey?: string,
  window?: string,
  date?: string,
  sortKey?: string,
  sortDir?: string,
  page?: string,
  perPage?: string
}

interface URLReplacements {
  key: MetricKey,
  window?: TrendWindow,
  date?: moment.Moment,
  sortKey?: string,
  sortDir?: string,
  page?: number,
  perPage?: number
}

class TrendsGraphPage extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(ActionCreators.loadAvailableTrends(this.userId()));
    this.loadData();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.params !== prevProps.match.params) {
      this.loadData();
    }

    if (this.props.trends.availableMetrics.state !==
      prevProps.trends.availableMetrics.state) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(ActionCreators.clearTrendData());
  }

  loadData() {
    M.exec(
      request => this.props.dispatch(ActionCreators.loadTrendData(request)),
      this.dataRequest()
    );
  }

  dataRequest(): Maybe<DataRequest> {
    return M.map(
      key => ({
        key, window: this.window(), date: this.date(), userId: this.userId()
      }),
      this.selectedMetricKey()
    );
  }

  userId(): UserId {
    return this.props.match.params.userId;
  }

  user(): UserType | undefined {
    const currentUserProfile = UserSEL.profile(this.props.currentUser);
    const crewMember =
      UserSEL.crewMember(this.userId(), this.props.currentUser);
    if (currentUserProfile && currentUserProfile.id === this.userId()) {
      return currentUserProfile;
    } else if (isLoaded(crewMember) && crewMember.value) {
      return crewMember.value;
    }
  }

  metrics(): Loadable<Metric[]> {
    return this.props.trends.availableMetrics;
  }

  initialMetricKey(): Loadable<Maybe<MetricKey>> {
    return this.props.trends.initialMetricKey;
  }

  selectedMetricKey(): Maybe<MetricKey> {
    const loadableFirstAndInitial =
      loadableSequence([this.initialMetricKey(), this.firstMetricKey()]);

    if (isLoaded(loadableFirstAndInitial)) {
      const initial: Maybe<MetricKey> = loadableFirstAndInitial.value[0];
      const first: Maybe<MetricKey> = loadableFirstAndInitial.value[1];

      return M.or(this.metricKeyFromParams(), initial, first);
    }
    else {
      return this.metricKeyFromParams();
    }
  }

  metricKeyFromParams(): Maybe<MetricKey> {
    return M.chain(
      metricKeyFromString,
      M.fromNilable(this.props.match.params.metricKey)
    );
  }

  date(): moment.Moment {
    const urlDateStr = this.props.match.params.date;
    const urlDate = moment(urlDateStr, 'YYYY-MM-DD', true);
    return urlDate.isValid() ? urlDate : moment();
  }

  window(): TrendWindow {
    return windowFromString(
      TrendWindow.MONTH,
      this.props.match.params.window || ''
    );
  }

  tableSortDir(): SortDir {
    const sortDirParam = qs.parse(this.props.location.search).sort_dir;
    return sortDirFromString( sortDirParam || defaultSortDir);
  }

  tableSortKey(): string {
    const sortKeyParam = qs.parse(this.props.location.search).sort_key;
    return sortKeyParam || defaultSortKey;
  }

  tablePage(): number {
    const pageParam = qs.parse(this.props.location.search).page;
    return parseInt(pageParam) || 1;
  }

  tablePerPage(): number {
    const perPageParam = qs.parse(this.props.location.search).per_page;
    return parseInt(perPageParam) || defaultPerPage;
  }

  /**
   * This is "loadable" because metrics need to be loaded first. However the
   * value is then a `Maybe` because the selected metric key could be `Nothing`,
   * or it could be an invalid metric key
   */
  selectedMetric(): Loadable<Maybe<Metric>> {
    return loadableMap(
      metrics => M.chain(
        selectedKey => maybeFind(m => m.key === selectedKey, metrics),
        this.selectedMetricKey()
      ),
      this.metrics()
    );
  }

  /**
   * This is "loadable" because metrics need to be loaded first. However the
   * value is then a `Maybe` because if the array is empty then the first value
   * would be `Nothing` or it could be an invalid metric key
   */
  firstMetricKey(): Loadable<Maybe<MetricKey>> {
    return loadableMap(
      (metrics) => {
        const maybeFirstMetric =
          maybeHead(metrics)
        return M.map(metric => R.prop('key', metric), maybeFirstMetric)
      },
      this.metrics()
    );
  }

  selectMetric = (key: MetricKey) => {
    const url = this.urlReplace({ key });
    this.props.dispatch(push(url));
  }

  selectWindow = (window: TrendWindow) => {
    M.exec(
      key => {
        const url = this.urlReplace({ key, window });
        this.props.dispatch(push(url));
      },
      this.selectedMetricKey()
    );
  }

  gotoNext = () => {
    M.exec(
      key => {
        const url =
          this.urlReplace({ key, date: nextDate(this.date(), this.window()) });
        this.props.dispatch(push(url));
      },
      this.selectedMetricKey()
    );
  }

  gotoPrevious = () => {
    M.exec(
      key => {
        const url =
          this.urlReplace({
            key, date: previousDate(this.date(), this.window())
          });
        this.props.dispatch(push(url));
      },
      this.selectedMetricKey()
    );
  }

  tableSelectSort = (sortKey: string, sortDir: SortDir): void => {
    M.exec(
      key => {
        const url =
          this.urlReplace({
            key, sortKey: sortKey, sortDir: sortDir
          });
        this.props.dispatch(push(url));
      },
      this.selectedMetricKey()
    );
  }

  tableGotoPage = (page: number): void => {
    M.exec(
      key => {
        const url =
          this.urlReplace({
            key, page: page
          });
        this.props.dispatch(push(url));
      },
      this.selectedMetricKey()
    );
  }

  urlReplace(parts: URLReplacements): string {
    return Urls.trendsUrl(
      this.userId(),
      parts.key,
      parts.window || this.window(),
      parts.date || this.date(),
      parts.sortKey,
      parts.sortDir,
      parts.page
    );
  }

  render() {
    const metrics = this.metrics();
    const selectedMetric = this.selectedMetric();
    const user = this.user();
    return (
      <NavBarLayout title="Trends">
        <div className="simple-container">
          {user && <UserBanner variant="prof_pic" user={user} />}
          {isLoaded(metrics) && isLoaded(selectedMetric) ?
           this.renderLoaded(metrics.value, selectedMetric.value) :
           this.renderNotLoaded()
          }
        </div>
        <HUD state={this.props.hud} />
      </NavBarLayout>
    );
  }

  renderNotLoaded() {
    return <Spinner wrap="center" />;
  }

  currentData(): Maybe<TrendData> {
    return this.props.trends.currentData;
  }

  renderLoaded = (metrics: Metric[], selectedMetric: Maybe<Metric>) => {
    return (
      <div>
        <TrendsControls
          availableMetrics={metrics}
          selectedMetric={selectedMetric}
          selectedWindow={this.window()}
          selectedDate={this.date()}
          currentData={this.currentData()}
          onMetricSelected={this.selectMetric}
          onWindowSelected={this.selectWindow}
          gotoNext={this.gotoNext}
          gotoPrevious={this.gotoPrevious}/>
        {M.maybe(
           () => '',
           this.renderGraph,
           M.both(this.dataRequest(), this.currentData())
         )}

        {M.maybe(() => '', this.renderTable, this.currentData())}
      </div>
    );
  }

  renderGraph([request, data]: [DataRequest, TrendData]): React.ReactNode {
    return <TrendsGraph request={request} data={data} />;
  }

  renderTable = (data: TrendData): React.ReactNode => {
    return (
      <TrendsTable
        data={data}
        currentSortKey={this.tableSortKey()}
        currentSortDir={this.tableSortDir()}
        onSortSelected={this.tableSelectSort}
        currentPage={this.tablePage()}
        perPage={this.tablePerPage()}
        gotoPage={this.tableGotoPage}/>
    )
  }
}

export default defaultConnect(TrendsGraphPage);
