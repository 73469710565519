import * as React from 'react';
import * as classnames from 'classnames';
import { ApptType, ApptTypeId } from 'Appt/Data';

import * as Urls from 'Shared/Urls';
import NoContent from 'misc/UI/NoContent';
import Button from 'Shared/UI/Button';
import Link from 'Shared/UI/Link';

interface Props {
  apptTypes: ApptType[],
  gotoNewAppt: (typeId: ApptTypeId) => void
}

const NewApptTypeSelect = (props: Props) => {
  const empty = props.apptTypes.length === 0;
  let select: HTMLSelectElement | null;
  const submitHandler = (e: React.FormEvent<any>) => {
    e.preventDefault();
    if (select && select.value !== '') {
      props.gotoNewAppt(select.value);
    }
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="mb-1r">
        <select className="select select--block" ref={s => select = s}>
          <option value="">+ new appointment</option>
          {props.apptTypes.map(apptTypeOption)}
        </select>
      </div>
      <div className={classnames('text-right', { 'mb-1r': !empty })}>
        <Button type="submit" className="button button-gray">GO</Button>
      </div>
      {empty && renderNoApptTypes()}
    </form>
  );
};

export default NewApptTypeSelect;

export function apptTypeOption(apptType: ApptType) {
  return (
    <option value={apptType.id} key={apptType.id}>
      {apptType.name}
    </option>
  );
}

function renderNoApptTypes() {
  const userGuideLink = (
    <Link to={Urls.userGuideUrl()} themed={true} breadcrumb="push">
      User Guide
    </Link>
  );
  return (
    <NoContent>
      Looks like there's nothing for you to schedule here right now. Learn more
      about how to make appointments in
      the {userGuideLink}.
    </NoContent>
  );
}
