import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { State, DispatchProps } from 'Store';
import { withRouter } from 'react-router-dom';
import { defaultConnect } from 'Shared/ReduxComponent';
import { NavBarLayout } from 'Nav';
import * as Urls from 'Shared/Urls';
import JStyle1963YHSettings from './BLEDeviceSettings/JStyle1963YH';
import { DeviceType, UserDevice, getDeviceTypeForUserDevice } from 'Edge/Data';
import { ActionCreators as AC } from 'Edge/ActionCreator';
import * as BLE from 'BLE';
import { HUD } from 'HUD';

interface URLParams {
  deviceTypeId: string
  deviceId: string
}

type Props = State & DispatchProps & RouteComponentProps<URLParams>;

class BLEDeviceSettingsPage extends React.Component<Props, {}> {
  async componentDidMount() {
    await this.props.dispatch(AC.loadConfigurationOnce());
  }

  userDevice(): UserDevice {
    return { deviceTypeId: this.deviceTypeId(), deviceId: this.deviceId() };
  }

  deviceTypeId(): number {
    return parseInt(this.props.match.params.deviceTypeId);
  }

  deviceId(): string {
    return this.props.match.params.deviceId;
  }

  pageTitle(): string {
    const deviceType = getDeviceTypeForUserDevice(
      this.props.edge,
      this.userDevice()
    )

    return deviceType ? deviceType.name : 'Device Settings';
  }

  deviceType(): DeviceType | undefined {
    return getDeviceTypeForUserDevice(this.props.edge, this.userDevice());
  }

  render() {
    const deviceType = this.deviceType();
    return (
      <NavBarLayout
        title={this.pageTitle()}
        initBreadcrumb={Urls.dashboardUrl()}
      >
        <HUD state={this.props.hud} />
        {deviceType && this.renderDeviceSettings(deviceType)}
      </NavBarLayout>
    );
  }

  renderDeviceSettings(deviceType: DeviceType) {
    const props = {
      ...this.props,
      deviceType,
      deviceId: this.deviceId(),
      deviceTypeId: this.deviceTypeId()
    };
    switch(deviceType.protocol) {
      case BLE.DeviceProtocol.JStyle1963YH:
      case BLE.DeviceProtocol.JStyle1790:
        return <JStyle1963YHSettings { ...props}/>;
      default:
        return `Unknown device type: ${deviceType.name}`
    }
  }
}

export default defaultConnect(withRouter(BLEDeviceSettingsPage));
