import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';
import {
  NS, NSType, ProviderKey, Provider, UserDevice, DeviceType
} from './Data';

export enum ActionType {
  LOAD_CONFIGURATION__BEGIN = 'LOAD_CONFIGURATION__BEGIN',
  LOAD_CONFIGURATION__SUCCESS = 'LOAD_CONFIGURATION__SUCCESS',
  CONNECT__BEGIN = 'CONNECT__BEGIN',
  CONNECT__SUCCESS = 'CONNECT__SUCCESS',
  DISCONNECT__BEGIN = 'DISCONNECT__BEGIN',
  DISCONNECT__SUCCESS = 'DISCONNECT__SUCCESS',
  DISCONNECT_FROM_DEVICES = 'DISCONNECT_FROM_DEVICES',

  REMOVE_DEVICE__BEGIN = 'REMOVE_DEVICE__BEGIN',
  REMOVE_DEVICE__COMPLETE = 'REMOVE_DEVICE__COMPLETE',

  BLE_SYNC_START = 'BLE_SYNC_START',
  BLE_SYNC_STOP__SUCCESS = 'BLE_SYNC_STOP__SUCCESS',
  BLE_SYNC_STOP__ERROR = 'BLE_SYNC_STOP__ERROR',
  BLE_BACKGROUND_SYNC_START = 'BLE_BACKGROUND_SYNC_START',
  BLE_BACKGROUND_SYNC_STOP = 'BLE_BACKGROUND_SYNC_STOP',
  BLE_SYNC_ON_RESUME_START = 'BLE_SYNC_ON_RESUME_START',
  BLE_SYNC_ON_RESUME_PENDING_STOP = 'BLE_SYNC_ON_RESUME_PENDING_STOP',
  BLE_SYNC_ON_RESUME_STOP = 'BLE_SYNC_ON_RESUME_STOP',
  BLE_CONNECT_TO_DEVICE = 'BLE_CONNECT_TO_DEVICE'
}

export type Action
  = LoadConfiguration_Begin
  | LoadConfiguration_Success
  | Connect_Begin
  | Connect_Success
  | Disconnect_Begin
  | Disconnect_Success
  | DisconnectFromDevices
  | RemoveDevice_Begin
  | RemoveDevice_Complete
  | BLEConnectToDevice
  | BLESyncStart
  | BLESyncStop_Success
  | BLESyncStop_Error
  | BLEBackgroundSyncStart
  | BLEBackgroundSyncStop
  | BLESyncOnResumeStart
  | BLESyncOnResumePendingStop
  | BLESyncOnResumeStop;

export type NSAction = WithNS<NSType, Action>;
export const withNS = originalWithNS<NSType, Action>(NS);

export interface LoadConfiguration_Begin {
  type: ActionType.LOAD_CONFIGURATION__BEGIN
}

export interface LoadConfiguration_Success {
  type: ActionType.LOAD_CONFIGURATION__SUCCESS,
  connectedProviderKeys: ProviderKey[],
  availableProviders: Provider[],
  userDevices: UserDevice[]
}

export interface Connect_Begin {
  type: ActionType.CONNECT__BEGIN,
  provider: Provider
}

export interface Connect_Success {
  type: ActionType.CONNECT__SUCCESS,
  provider: Provider
}

export interface Disconnect_Begin {
  type: ActionType.DISCONNECT__BEGIN,
  provider: Provider
}

export interface Disconnect_Success {
  type: ActionType.DISCONNECT__SUCCESS,
  provider: Provider
}

export interface DisconnectFromDevices {
  type: ActionType.DISCONNECT_FROM_DEVICES,
  devices: UserDevice[]
}

export interface BLEConnectToDevice {
  type: ActionType.BLE_CONNECT_TO_DEVICE
  userDevice: UserDevice
}

export interface BLESyncStart {
  type: ActionType.BLE_SYNC_START
  device: UserDevice
}

export interface BLESyncStop_Success {
  type: ActionType.BLE_SYNC_STOP__SUCCESS
  device: UserDevice
}

export interface BLESyncStop_Error {
  type: ActionType.BLE_SYNC_STOP__ERROR,
  device: UserDevice,
  error?: string
}

export interface BLEBackgroundSyncStart {
  type: ActionType.BLE_BACKGROUND_SYNC_START
}

export interface BLEBackgroundSyncStop {
  type: ActionType.BLE_BACKGROUND_SYNC_STOP
}

export interface BLESyncOnResumeStart {
  type: ActionType.BLE_SYNC_ON_RESUME_START
}

export interface BLESyncOnResumePendingStop {
  type: ActionType.BLE_SYNC_ON_RESUME_PENDING_STOP
}

export interface BLESyncOnResumeStop {
  type: ActionType.BLE_SYNC_ON_RESUME_STOP
}

export interface RemoveDevice_Begin {
  type: ActionType.REMOVE_DEVICE__BEGIN,
  provider: Provider,
  deviceType: DeviceType
}
export interface RemoveDevice_Complete {
  type: ActionType.REMOVE_DEVICE__COMPLETE,
  provider: Provider,
  deviceType: DeviceType
}
