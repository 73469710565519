import * as React from 'react';
import { CurrentUser, HealthScore } from 'User'
import { Biopost } from 'Post';
import * as Urls from 'Shared/Urls';
import Spinner from 'Shared/UI/Spinner';
import Tile from './Tile';
import SimpleTileContent from './SimpleTileContent';

interface Props {
  currentUser: CurrentUser,
  lastPost: Biopost | undefined
}

export default function renderTrendsTile(
  props: Props
): React.ReactElement<Props> {
  const score = healthScore(props.currentUser);
  return (
    <Tile title="health score"
      linkUrl={Urls.timeSeriesUrl()}
      linkLabel="view graph">
      {score ? renderScore(score, props.lastPost) : renderNotLoaded()}
    </Tile>
  );
}

function renderScore(score: HealthScore, lastPost: Biopost | undefined) {
  const title =
    lastPost ?
    `Last updated: ${lastPost.time.format('MMM D, YYYY')}` :
    "What's this?";
  return (
    <SimpleTileContent
      title={title}
      url={Urls.timeSeriesUrl()}
      badge={`score: ${score}`}
      icon="tally" />
  );
}

function renderNotLoaded() {
  return <Spinner wrap="center" />;
}

function healthScore(user: CurrentUser): HealthScore {
  return user.profile && user.profile.healthScore;
}
