import { State, Thread, ThreadInfo, Message } from './Data';
import { Action, ActionType } from './Action';
import {
  IDMap, idMapConcat, idMapFromList, idMapInsert
} from 'Shared/Data/IDMap';
import { feedSetPaging, feedSetIds } from 'Shared/Data/Feed';

export function reducer(state: State, action: Action): State {
  switch(action.type) {
  case ActionType.LOAD_THREAD_LIST__SUCCESS:
    const newThreadInfosMap = idMapFromList(t => t.id, action.threadInfos);
    const threadIds = action.threadInfos.map(i => i.id);
    return {
        ...state,
      threadInfoFeed: feedSetPaging(
        action.paging,
        feedSetIds(threadIds, state.threadInfoFeed)
      ),
      threadInfos: idMapConcat(state.threadInfos, newThreadInfosMap)
    };

  case ActionType.LOAD_THREAD__SUCCESS:
    return insertThread(action.thread, state);

  case ActionType.CREATE_THREAD__SUCCESS:
    return insertThread(action.thread, state);
  }
}

function insertThread(thread: Thread, state: State): State {
  const { info, messages } = thread;
  return {
      ...state,
    threadInfos: idMapInsert(
      info.id, info, state.threadInfos
    ) as IDMap<ThreadInfo>,
    messages: idMapInsert(
      info.id, messages, state.messages
    ) as IDMap<Message[]>
  };
}
