import * as moment from 'moment-timezone';

import {
  OK, GET, POST, DELETE, ApiClient
} from 'Api';

import { DateJSON, dateToJSON } from './JSON/Shared';

import {
  ApptTypesResponseJSON, AvailabilityResponseJSON, ApptsResponseJSON,
  ApptResponseJSON, SlotJSON
} from './JSON/Appt';

export interface ApptAPI {
  getApptTypes(): Promise<ApptTypesResponseJSON>

  getAvailability(
    apptTypeId: string, startDate?: moment.Moment, endDate?: moment.Moment
  ): Promise<AvailabilityResponseJSON>

  createAppt(apptTypeId: string, slot: SlotJSON): Promise<ApptResponseJSON>

  getAppts(): Promise<ApptsResponseJSON>

  cancelAppt(apptId: string): OK
}

interface GetAvailabilityParams {
  start_date?: DateJSON,
  end_date?: DateJSON,
  include_dates: boolean
}

export function makeApi(client: ApiClient): ApptAPI {
  return {
    getApptTypes,
    getAvailability,
    createAppt,
    getAppts,
    cancelAppt
  };

  function getApptTypes(): Promise<ApptTypesResponseJSON> {
    return client.requestJSON(GET, { path: '/appt_types', version: 2 });
  }

  function getAvailability(
    apptTypeId: string, startDate?: moment.Moment, endDate?: moment.Moment
  ): Promise<AvailabilityResponseJSON> {
    let params: GetAvailabilityParams;
    if (startDate && endDate) {
      params = {
        start_date: dateToJSON(startDate),
        end_date: dateToJSON(endDate),
        include_dates: true
      };
    } else {
      params = { include_dates: true };
    }
    return client.requestJSON(
      GET, { path: `/appt_types/${apptTypeId}/availability`, query: params }
    );
  }

  function createAppt(
    apptTypeId: string, slot: SlotJSON
  ): Promise<ApptResponseJSON> {
    return client.requestJSON(
      POST, '/appts', { appt_type_id: apptTypeId, slot }
    );
  }

  function getAppts(): Promise<ApptsResponseJSON> {
    return client.requestJSON(GET, '/appts');
  }

  function cancelAppt(apptId: string): OK {
    return client.request(DELETE, `/appts/${apptId}/cancel`);
  }
}
