import * as React from 'react';
import AlertBox from 'Shared/UI/AlertBox';

interface Props {
  error?: string
}

const AuthFormErrors = (props: Props) => {
  if (props.error && props.error.length > 0) {
    return <AlertBox modifiers="error">{props.error}</AlertBox>
  } else {
    return null;
  }
}

export default AuthFormErrors;
