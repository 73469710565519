import * as React from 'react';
import { Paging } from 'misc/Data/Paging';
import 'misc/UI/Pager/pager.scss';

type GotoPageFn = (page: number) => void;

interface Props {
  paging: Paging,
  gotoPage: GotoPageFn
}

const Pager = (props: Props) => {
  const { paging, gotoPage } = props;
  return (
    <nav>
      <ul className="pill-pager">
        {pagingLink('← Previous', paging.previousPage, gotoPage)}
        {pagingLink('Next →', paging.nextPage, gotoPage)}
      </ul>
    </nav>
  );
};

export default Pager;
export { Pager, Paging };


function disabledClass(page: number | null): string {
  if (page === null) {
    return 'pill-pager__li--disabled';
  } else {
    return '';
  }
}

function pagingLink(label: string, page: number | null, gotoPage: GotoPageFn) {
  const handler = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    if (page) { gotoPage(page); }
  }
  return (
    <li className={`pill-pager__li ${disabledClass(page)}`}>
      <a onClick={handler} href="#">
        {label}
      </a>
    </li>
  );
}
