import { Action, ActionType } from 'Home/Action';
import { State, setQuicklinksWithDefaults } from 'Home/Data';

export function reducer(state: State, action: Action): State {
  switch(action.type) {
    case ActionType.LOAD_QUICKLINKS__SUCCESS:
      return setQuicklinksWithDefaults(action.quicklinks, state);
    case ActionType.LOAD_QUICKLINKS__FAILURE:
      return state;
  }
}
