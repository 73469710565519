import * as R from 'ramda';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { defaultConnect } from 'Shared/ReduxComponent';
import { State, StoreDispatch } from 'Store';
import { NavBarLayout } from 'Nav';
import { HUD } from 'HUD';
import { hudState } from 'App/Data';

import * as Urls from 'Shared/Urls';
import { SECONDS } from 'Shared/Data/Duration';
import * as BLE from 'BLE/Data';
import * as Edge from 'Edge/Data';
import { ActionCreators as EdgeAC } from 'Edge/ActionCreator';
import { ActionCreators as BLEAC } from 'BLE/ActionCreator';

import Spinner from 'Shared/UI/Spinner';
import NoBluetooth from 'BLE/UI/NoBluetooth';

type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch
}

interface URLParams {
  providerKey: string,
  deviceTypeId?: string
}

class DeviceScan extends React.Component<Props, {}> {
  async componentDidMount() {
    await this.props.dispatch(EdgeAC.loadConfigurationOnce());
    this.startScan();
  }

  startScan() {
    this.props.dispatch(BLEAC.startScan(15 * SECONDS));
  }

  provider(): Edge.Provider | undefined {
    const key = this.props.match.params.providerKey;
    const provider = Edge.getProvider(this.props.edge, key);
    return provider;
  }

  deviceTypes(): Edge.DeviceType[] {
    const provider = this.provider();
    if (provider === undefined) {
      return [];
    } else if (this.props.match.params.deviceTypeId) {
      const dtid = parseInt(this.props.match.params.deviceTypeId);
      return R.filter(dt => dt.id === dtid, provider.deviceTypes);
    } else {
      return provider.deviceTypes;
    }
  }

  devices(): BLE.Device[] {
    return this.props.ble.peripherals;
  }

  deviceTypeForDevice(device: BLE.Device): Edge.DeviceType | undefined {
    return Edge.findMatchingDeviceType(this.deviceTypes(), device.name);
  }

  hasBLE(): boolean {
    return BLE.hasBLE(this.props.ble);
  }

  isScanning(): boolean {
    return BLE.isScanning(this.props.ble);
  }

  async selectDevice(device: BLE.Device, deviceType: Edge.DeviceType) {
    const provider = this.provider();
    if (provider) {
      await this.props.dispatch(BLEAC.stopScan());
      this.props.dispatch(EdgeAC.connectToDevice(device, deviceType));
    }
  }

  pageTitle() {
    const deviceTypes = this.deviceTypes();
    const provider = this.provider();
    if (deviceTypes.length === 1) {
      return deviceTypes[0].name;
    } else if (provider) {
      return provider.label;
    } else {
      return 'scan';
    }
  }

  render() {
    const provider = this.provider();

    let innerContent;
    if (!this.hasBLE()) {
      innerContent = this.renderNoBluetooth();
    } else if (provider) {
      innerContent = this.renderDeviceList();
    } else {
      innerContent = this.renderLoading();
    }

    return (
      <NavBarLayout
        title={this.pageTitle()}
        initBreadcrumb={Urls.edgeUrl()}>
        <div className="simple-container">
          {innerContent}
        </div>
        <HUD state={hudState(this.props)} />
      </NavBarLayout>
    );

  }

  renderLoading() {
    return <HUD state="loading" />;
  }

  renderNoBluetooth() {
    return <NoBluetooth />;
  }

  renderDeviceList() {
    const devices = this.devices();

    return (
      <div>
        <h1>Select a device to connect</h1>
        {this.renderScanButton()}
        <ul className="edge-scan-results">
          {devices.map(d => this.renderDeviceListItem(d))}
        </ul>
      </div>
    );
  }

  renderScanButton() {
    if (this.isScanning()) {
      return <Spinner wrap="center" />;
    } else {
      return (
        <div className="text-center">
          <button className="button button-gray"
                  onClick={() => this.startScan()}>
            scan again
          </button>
        </div>
      );
    }
  }

  renderDeviceListItem(device: BLE.Device) {
    const deviceType = this.deviceTypeForDevice(device);
    if (deviceType === undefined) { return; }

    return (
      <li className="edge-scan-results__item" key={device.address}
          onClick={() => this.selectDevice(device, deviceType)}>
        <div className="edge-scan-results__pair-button theme__primary-bg">
          pair
        </div>
        <div className="edge-scan-results__item-name">
          {deviceType.name}
        </div>
        <div className="edge-scan-results__item-address">
          {device.name}
          <br/>
          id: {device.address}
        </div>
      </li>
    );
  }
}


export default defaultConnect(withRouter(DeviceScan));
