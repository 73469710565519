import * as R from 'ramda';

export type NSType = 'NAV';
export const NS: NSType = 'NAV';

export interface State {
  open: boolean,
  breadcrumbs: Breadcrumb[]
}

interface NavLocation {
  pathname: string
}
export interface Breadcrumb {
  location: NavLocation
  label?: string
}

export function breadcrumbUrl(crumb: Breadcrumb): string {
  return crumb.location.pathname;
}

export function initialState(): State {
  return { open: false, breadcrumbs: [] };
}

export function pushBreadcrumb(b: Breadcrumb, nav: State): State {
  return { ...nav, breadcrumbs: R.prepend(b, nav.breadcrumbs) };
}

export function peekBreadcrumb(nav: State): Breadcrumb | null {
  return nav.breadcrumbs[0];
}

export function popBreadcrumb(nav: State): State {
  return { ...nav, breadcrumbs: R.drop(1, nav.breadcrumbs) };
}

export function clearBreadcrumbs(nav: State): State {
  return { ...nav, breadcrumbs: [] };
}

export const SEL = {
  breadcrumbs(nav: State): Breadcrumb[] {
    return nav.breadcrumbs;
  }
};
