import * as React from 'react';

import { healthScoreWhatsThisUrl } from 'Shared/Urls';
import Link from 'Shared/UI/Link';

interface Props {
  value: number | undefined
}

interface State {
  showWhatsThis: boolean
}

class Score extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { showWhatsThis: false };
    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler() {
    this.setState({ showWhatsThis: true });
  }

  render() {
    const formattedScore = this.props.value;
    return (
      <div className="health-score" onClick={this.clickHandler}>
        <span className="health-score__value">{formattedScore}</span>
        <br/>
        {this.renderWhatsThis()}
        <span className="health-score__range">score (0-100)</span>
      </div>
    )
  }

  renderWhatsThis() {
    if (this.state.showWhatsThis) {
      return (
        <span>
          <Link
            className="health-score__whats-this"
            to={healthScoreWhatsThisUrl()}
          >
            what's this?
          </Link>
          <br/>
        </span>
      )
    }
  }
}

export default Score;
