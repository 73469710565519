import * as R from 'ramda';
import * as moment from 'moment-timezone';
import { push } from 'connected-react-router';

import { challengesUrl, challengeUrl, challengeTeamUrl } from 'Shared/Urls';
import * as Dialog from 'Shared/Dialog';
import noop from 'Shared/Noop';
import * as Either from 'Shared/Data/Either';
import { TeamLeaderboardOpts } from 'Api/Challenges';
import { ActionCreatorThunk, ActionCreatorThunkThen } from 'Store';
import { ActionTypes, withNS } from './Action';
import * as A from './Action';
import * as FromJSON from './JSON';
import {
  LENSES,
  NS,
  ChallengeId,
  TemplateId,
  ChallengeDetails,
  isSameProgress,
  TeamFormData,
  TeamId
} from './Data';
import { CrewMember } from 'User/Data';
import { TaskId } from 'Tasks/Data';
import { ActionCreators as HUD } from 'HUD';

export interface ActionCreators {
  loadChallenges(): ActionCreatorThunk,
  loadCompletedChallenges(page: number): ActionCreatorThunk,
  loadChallenge(
    challengeId: ChallengeId
  ): ActionCreatorThunkThen<ChallengeDetails>,
  ensureChallenge(challengeId: ChallengeId): ActionCreatorThunk,
  loadLeaderboard(challengeId: ChallengeId, page: number): ActionCreatorThunk,
  joinChallenge(challengeId: ChallengeId): ActionCreatorThunk,
  startChallenge(templateId: TemplateId): ActionCreatorThunk,
  leaveChallenge(challengeId: ChallengeId): ActionCreatorThunk,
  loadInvitations(): ActionCreatorThunk,
  acceptInvitation(
    inviteId: string, challengeId: ChallengeId
  ): ActionCreatorThunk,
  declineInvitation(inviteId: string): ActionCreatorThunk,
  loadChallengeRules(challengeId: ChallengeId): ActionCreatorThunk,
  loadTemplateRules(templateId: TemplateId): ActionCreatorThunk,
  addSteps(challengeId: ChallengeId, stepCount: number): ActionCreatorThunk,
  pollForUpdate(challengeId: ChallengeId): ActionCreatorThunk,
  inviteCrewMember(
    challengeId: ChallengeId, member: CrewMember
  ): ActionCreatorThunk,
  loadTaskChallengeDayDetail(
    challengeId: ChallengeId, date: moment.Moment
  ): ActionCreatorThunk,
  completeTaskForChallenge(
    challengeId: ChallengeId, taskId: TaskId, date: moment.Moment
  ): ActionCreatorThunk,
  undoTaskForChallenge(
    challengeId: ChallengeId, taskId: TaskId, date: moment.Moment
  ): ActionCreatorThunk,
  loadTaskChallengeDailySummary(challengeId: ChallengeId): ActionCreatorThunk,
  loadTeams(challengeId: ChallengeId): ActionCreatorThunk,
  createTeam(challengeId: ChallengeId, data: TeamFormData): ActionCreatorThunk,
  updateTeam(
    challengeId: ChallengeId, teamId: TeamId, data: TeamFormData
  ): ActionCreatorThunk,
  joinTeam(teamId: TeamId): ActionCreatorThunk,
  loadTeamWithPlayers(
    challengeId: ChallengeId, teamId: TeamId
  ): ActionCreatorThunk,
  leaveTeam(teamId: TeamId): ActionCreatorThunk,
  /**
   * Load leaderboard of teams within a challenge (not to be confused with the
   * players of a specific team)
   */
  loadTeamLeaderboard(
    challengeId: ChallengeId, page: number, opts?: TeamLeaderboardOpts
  ): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  loadChallenges,
  loadCompletedChallenges,
  loadChallenge,
  ensureChallenge,
  loadLeaderboard,
  joinChallenge,
  startChallenge,
  leaveChallenge,
  loadInvitations,
  acceptInvitation,
  declineInvitation,
  loadChallengeRules,
  loadTemplateRules,
  addSteps,
  pollForUpdate,
  inviteCrewMember,
  loadTaskChallengeDayDetail,
  completeTaskForChallenge,
  undoTaskForChallenge,
  loadTaskChallengeDailySummary,
  loadTeams,
  createTeam,
  updateTeam,
  joinTeam,
  loadTeamWithPlayers,
  leaveTeam,
  loadTeamLeaderboard
};

function loadChallenges(): ActionCreatorThunk {
  return (dispatch, _getState, { api }) => {
    return api.challenges.getChallenges().then(
      response => {
        dispatch({
          NS,
          type: ActionTypes.LOAD_CHALLENGES_SUCCESS,
          data: {
            enrolled: response.enrolled.map(FromJSON.challengeSummary),
            available: response.available.map(FromJSON.challengeSummary),
            templates: response.templates.map(FromJSON.challengeTemplate)
          }
        } as A.LoadChallenges_Success);
      }
    );
  };
}

function loadCompletedChallenges(page: number): ActionCreatorThunk {
  return (dispatch, _getState, { api }) => {
    dispatch(withNS({
      NS,
      type: ActionTypes.LOAD_COMPLETED_CHALLENGES_START
    }));
    return api.challenges.getCompletedChallenges(page).then(
      response => {
        dispatch({
          NS,
          type: ActionTypes.LOAD_COMPLETED_CHALLENGES_SUCCESS,
          data: {
            completed: response.completed.map(FromJSON.challengeSummary),
            hasMorePages: response.has_more_pages,
          }
        } as A.LoadCompletedChallenges_Success);
      }
    );
  };
}

function loadChallenge(
  challengeId: ChallengeId
): ActionCreatorThunkThen<ChallengeDetails> {
  return (dispatch, _getState, { api }) => {
    dispatch({
      NS, challengeId,
      type: ActionTypes.LOAD_CHALLENGE_START
    } as A.LoadChallenge_Start);
    return api.challenges.getChallenge(challengeId).then(
      response => {
        const challenge = FromJSON.challengeDetails(response.challenge);
        dispatch({
          NS,
          type: ActionTypes.LOAD_CHALLENGE_SUCCESS,
          challenge
        } as A.LoadChallenge_Success);
        return challenge;
      }
    );
  }
}

function ensureChallenge(challengeId: ChallengeId): ActionCreatorThunk {
  return (dispatch, getState) => {
    const c = LENSES.challenge(challengeId).get(getState().challenges);
    return c ? Promise.resolve() : dispatch(loadChallenge(challengeId));
  };
}

function loadLeaderboard(
  challengeId: ChallengeId, page: number
): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(R.identity<A.LoadLeaderboard_Start>({
      NS, challengeId, page,
      type: ActionTypes.LOAD_LEADERBOARD_START
    }));

    return api.challenges.getLeaderboard(challengeId, page).then(
      ({ leaderboard, has_more }) => {
        dispatch(R.identity<A.LoadLeaderboard_Success>({
          NS,
          type: ActionTypes.LOAD_LEADERBOARD_SUCCESS,
          challengeId, page,
          players: leaderboard.map(FromJSON.player),
          hasMore: has_more
        }));
      }
    );
  };
}

function joinChallenge(challengeId: ChallengeId): ActionCreatorThunk {
  return (dispatch, _getState, { api, history }) => {
    return api.challenges.joinChallenge(challengeId).then(
      () => {
        dispatch(loadChallenge(challengeId));
        history.push(challengeUrl(challengeId));
      }
    )
  };
}

function startChallenge(templateId: TemplateId): ActionCreatorThunk {
  return (dispatch, _getState, { api }) => {
    return api.challenges.createChallenge(templateId).then(
      response => {
        const challenge = FromJSON.challengeDetails(response.challenge);
        dispatch({
          NS,
          type: ActionTypes.START_CHALLENGE_SUCCESS,
          challenge
        } as A.StartChallenge_Success);
        dispatch(push(challengeUrl(challenge.summary.id)));
      }
    );
  }
};

function leaveChallenge(challengeId: ChallengeId): ActionCreatorThunk {
  return (dispatch, _getState, { api }) => {
    return Dialog.confirm('Are you sure you want to leave this challenge?')
      .then(
        () => {
          api.challenges.leaveChallenge(challengeId).then(
            () => {
              dispatch({
                NS,
                type: ActionTypes.LEAVE_CHALLENGE_SUCCESS,
                challengeId
              } as A.LeaveChallenge_Success);
              dispatch(loadChallenges());
              dispatch(push(challengesUrl()));
            });
        },
        noop
      );
  };
}

function loadInvitations(): ActionCreatorThunk {
  return (dispatch, _getState, { api }) => {
    return api.challenges.getInvitations().then(
      response => {
        const invitations =
          response.invitations.map(FromJSON.challengeInvitation);

        dispatch({
          NS,
          type: ActionTypes.LOAD_INVITATIONS_SUCCESS,
          invitations
        } as A.LoadInvitations_Success);
      }
    )
  };
}

function acceptInvitation(
  invitationId: string, challengeId: ChallengeId
): ActionCreatorThunk {
  return (dispatch, _getState, { api }) => {
    return api.challenges.acceptInvitation(invitationId).then(() => {
      dispatch({
        NS,
        type: ActionTypes.ACCEPT_INVITATION_SUCCESS,
        invitationId
      });
      dispatch(push(challengeUrl(challengeId)));
    });
  };
}

function declineInvitation(invitationId: string): ActionCreatorThunk {
  return (dispatch, _getState, { api }) => {
    return api.challenges.declineInvitation(invitationId).then(() => {
      dispatch({
        NS,
        type: ActionTypes.DECLINE_INVITATION_SUCCESS,
        invitationId
      });
    });
  };
}

function loadChallengeRules(challengeId: ChallengeId): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    return api.challenges.getChallengeRules(challengeId).then(
      ({ rules }) => dispatch({
        NS,
        type: ActionTypes.LOAD_CHALLENGE_RULES_SUCCESS,
        id: challengeId,
        rules
      } as A.LoadChallengeRules_Success)
    );
  };
}

function loadTemplateRules(templateId: TemplateId): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    return api.challenges.getTemplateRules(templateId).then(
      ({ rules }) => dispatch({
        NS,
        type: ActionTypes.LOAD_TEMPLATE_RULES_SUCCESS,
        id: templateId,
        rules
      } as A.LoadTemplateRules_Success)
    );
  };
}

function addSteps(
  challengeId: ChallengeId,
  stepCount: number
): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    return api.challenges.addSteps(stepCount).then(() => {
      dispatch(pollForUpdate(challengeId));
    });
  };
}

function pollForUpdate(
  challengeId: ChallengeId,
  current?: ChallengeDetails
): ActionCreatorThunk {
  return (dispatch, getState, { api }) => {
    if (current === undefined) {
      current = LENSES.challenge(challengeId).get(getState().challenges);
    }
    const initProgress = current ? current.summary.progress : undefined;

    dispatch({
      NS, type: ActionTypes.POLL_CHALLENGE_START, challengeId
    } as A.PollChallenge_Start);

    return poll(8);

    function poll(retries: number) {
      return api.challenges.getChallenge(challengeId).then(
        response => {
          const next = FromJSON.challengeDetails(response.challenge);
          const nextProgress = next.summary.progress;

          if (retries > 0 && isSameProgress(initProgress, nextProgress)) {
            setTimeout(() => poll(retries - 1), 1000);
          } else {
            dispatch({
              NS,
              type: ActionTypes.LOAD_CHALLENGE_SUCCESS,
              challenge: FromJSON.challengeDetails(response.challenge)
            } as A.LoadChallenge_Success);
          }
        }
      );
    }
  };
}

function inviteCrewMember(
  challengeId: ChallengeId, crewMember: CrewMember
): ActionCreatorThunk {
  return (_dispatch, _getState, { api }) => {
    return api.challenges.inviteToChallenge(challengeId, crewMember.user.id);
  };
}

function loadTaskChallengeDayDetail(
  challengeId: ChallengeId, date: moment.Moment
): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch({
      NS,
      type: ActionTypes.LOAD_TASK_CHALLENGE_DAY_DETAIL_START
    });
    return api.challenges.getTaskChallengeDayDetail(challengeId, date).then(
      result => {
        const action: A.LoadTaskChallengeDayDetail_Success = {
          NS,
          type: ActionTypes.LOAD_TASK_CHALLENGE_DAY_DETAIL_SUCCESS,
          date,
          locked: result.locked,
          completedTaskIds: result.completed_task_ids,
          availableTaskIds: result.available_task_ids
        };
        dispatch(action);
      }
    );
  };
}

function completeTaskForChallenge(
  challengeId: ChallengeId, taskId: TaskId, date: moment.Moment
): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.challenges.completeTaskForChallenge(challengeId, taskId, date)
      .then(() => {
        dispatch(withNS({
          NS,
          type: ActionTypes.COMPLETE_TASK_FOR_CHALLENGE_SUCCESS,
          challengeId,
          taskId,
          date
        }));
        dispatch(HUD.success());
      });
  }
}

function undoTaskForChallenge(
  challengeId: ChallengeId, taskId: TaskId, date: moment.Moment
): ActionCreatorThunk {
  return (dispatch, _, { api }) =>
    Dialog.confirm('Are you sure you want to undo this task?').then(() => {
      dispatch(HUD.loading());
      return api.challenges.undoTaskForChallenge(challengeId, taskId, date)
        .then(() => {
          dispatch(withNS({
            NS,
            type: ActionTypes.UNDO_TASK_FOR_CHALLENGE_SUCCESS,
            challengeId,
            taskId,
            date
          }));
          dispatch(HUD.success());
        });
    });
}

function loadTaskChallengeDailySummary(
  challengeId: ChallengeId
): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    const startAction: A.LoadTaskChallengeDaySummaries_Start = {
      NS, type: ActionTypes.LOAD_TASK_CHALLENGE_DAY_SUMMARIES_START
    };
    dispatch(startAction);
    return api.challenges.getTaskChallengeDailySummary(challengeId)
      .then(result => {
        const daySummaries =
          result.day_summaries.map(FromJSON.taskChallengeDaySummaryFromJSON);
        const successAction: A.LoadTaskChallengeDaySummaries_Success = {
          NS, type: ActionTypes.LOAD_TASK_CHALLENGE_DAY_SUMMARIES_SUCCESS,
          daySummaries
        };
        dispatch(successAction);
      });
  };
}

function loadTeams(challengeId: ChallengeId): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading())
    return api.challenges.getTeamList(challengeId).then(({ teams }) => {
      dispatch(withNS({
        NS,
        type: ActionTypes.LOAD_TEAMS_SUCCESS,
        challengeId,
        teams: teams.map(FromJSON.teamFromJSON)
      }));
      dispatch(HUD.close());
    });
  }
}

function createTeam(
  challengeId: ChallengeId, data: TeamFormData
): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.challenges.createTeam(challengeId, data).then(result => {
      dispatch(HUD.success());
      const team = FromJSON.teamFromJSON(result.team);
      return dispatch(push(challengeTeamUrl(challengeId, team.id)));
    });
  };
}

function updateTeam(
  challengeId: ChallengeId, teamId: TeamId, data: TeamFormData
): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.challenges.updateTeam(challengeId, teamId, data).then(result => {
      Either.either(
        error => {
          Dialog.alert('Error: ' + error.data.message);
          dispatch(HUD.error());
        },
        () => { dispatch(HUD.success()) },
        result
      );
    })
  }
}

function joinTeam(teamId: TeamId): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.challenges.joinTeam(teamId).then(
      () => dispatch(HUD.success())
    );
  }
}

function loadTeamWithPlayers(
  challengeId: ChallengeId, teamId: TeamId
): ActionCreatorThunk {
  return (dispatch, _, { api }) =>
    api.challenges.getTeamPlayers(teamId).then(response => {
      const team = FromJSON.teamFromJSON(response.team);
      const players = response.players.map(FromJSON.player);
      dispatch(withNS({
        NS,
        type: ActionTypes.LOAD_TEAM_WITH_PLAYERS_SUCCESS,
        team,
        players,
        challengeId
      }));
    });
}

function leaveTeam(teamId: TeamId): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.challenges.leaveTeam(teamId).then(
      () => dispatch(HUD.success())
    );
  };
}

function loadTeamLeaderboard(
  challengeId: ChallengeId, page: number, opts?: TeamLeaderboardOpts
): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(withNS({
      NS,
      type: ActionTypes.LOAD_TEAM_LEADERBOARD_START,
      challengeId
    }));
    return api.challenges.getTeamLeaderboard(challengeId, page, opts).then(
      result => {
        dispatch(withNS({
          NS,
          type: ActionTypes.LOAD_TEAM_LEADERBOARD_SUCCESS,
          challengeId,
          page,
          teamPlayers: result.teams.map(FromJSON.teamPlayerFromJSON),
          hasMore: result.has_more
        }));
      });
  }
}
