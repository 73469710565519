import GGA6 from 'BLE/Protocols/GreaterGoodsA6/Protocol';
import * as Util from 'BLE/Util';
import * as Advertisement from 'BLE/Advertisement'
import { makeDeviceAccess } from 'BLE/DeviceAccess';
import * as CRC32 from 'crc-32';
import * as NordicDFUProtocol from 'BLE/Protocols/NordicDFU/Protocol';

export function setup() {
  Object.assign(window, {
    GGA6,
    makeDeviceAccess,
    BLEUtil: Util,
    uintToHex,
    CRC32,
    Advertisement,
    NordicDFUProtocol
  });
}

function uintToHex(ary: Uint8Array): string[] {
  return Array.from(ary).map(i => {
    const v = i.toString(16);
    return v.length === 1 ? `0${v}` : v;
  });
}
