import './ArrowLink.scss';
import * as React from 'react';

import Link from './Link';
import { Props as LinkProps } from './Link';

export default function renderArrowLink(
  props: LinkProps
): React.ReactElement<LinkProps> {
  const classes = `arrow-link ${props.className || ''}`;
  const linkProps = { ...props, className: 'arrow-link__link' };
  return (
    <div className={classes}>
      <Link {...linkProps}>
        {props.children}
      </Link>
    </div>
  );
}
