import { Gender, expandedSpecTypeFromJSON } from 'Specs';
import {
  Profile, Program, MinimalUser, CrewMember, ScreeningStatus,
  AppFeatures, InboxStatus
} from './Data';
import {
  ProfileJSON, GenderJSON, ProgramJSON, MinimalUserJSON,
  CrewMemberJSON, AppFeaturesJSON
} from 'Api/JSON/User';

export { CurrentUserJSON } from 'Api/JSON/User';

export function screeningStatusFromJSON(json: boolean): ScreeningStatus {
  return json ? ScreeningStatus.ACTIVE : ScreeningStatus.INACTIVE;
}

export function inboxStatusFromJSON(hasUnreadMessages: boolean): InboxStatus {
  return hasUnreadMessages ? InboxStatus.NOT_ALL_READ : InboxStatus.ALL_READ;
}

export function profileFromJSON(json: ProfileJSON): Profile {
  return {
    id: json.id,
    name: json.name || '',
    email: json.email || '',
    mobilePhone: json.mobile_phone || '',
    gender: genderFromJSON(json.specs.gender),
    tzId: json.tz_id,
    healthScore: json.health_score
  };
}

export function minimalUserFromJSON(json: MinimalUserJSON): MinimalUser {
  return {
    id: json.user_id,
    name: json.name,
    email: json.email
  };
}

export function crewMemberFromJSON(json: CrewMemberJSON): CrewMember {
  return {
    user: {
      id: json.id,
      name: json.name,
      email: json.email,
    },
    healthScore: json.score
  };
}

export function genderFromJSON(json: GenderJSON): Gender {
  switch(json) {
  case 1:
    return 'male';
  default:
    return 'female';
  }
}

export function programFromJSON(
  json: ProgramJSON
): Program {
  return {
    id: json.id,
    name: json.name,
    slug: json.slug,
    logoUrl: json.logo_url,
    backgroundImageUrl: json.background_image_url,
    exposedSpecs: json.exposed_specs.map(s => expandedSpecTypeFromJSON(s)),
    edgeProviders: json.edge_providers,
    features: appFeaturesFromJSON(json.features),
    postPageLabel: json.post_page_label
  };
}

function appFeaturesFromJSON(json: AppFeaturesJSON): AppFeatures {
  return {
    engagement: json.engagement,
    appts: json.appts,
    crew: json.crew,
    inbox: json.inbox,
    onboard: json.onboard,
    healthScore: json.health_score,
    displayBanner: json.display_banner,
    allowExitHRA: json.allow_exit_hra,
    carePlans: json.care_plans,
    goals: json.goals,
    screeningReports: json.screening_reports,
    challenges: json.challenges,
    userAppTrends: json.user_app_trends,
    displayDailyCoach:json.display_daily_coach
  };
}

export function profileToJSON(profile: Profile): ProfileJSON {
  return {
    id: profile.id,
    name: profile.name,
    email: profile.email,
    mobile_phone: profile.mobilePhone,
    specs: { gender: genderToJSON(profile.gender) },
    tz_id: profile.tzId,
    health_score: profile.healthScore
  };
}


function genderToJSON(gender: Gender): GenderJSON {
  return {
    male: 1,
    female: 2
  }[gender];
}
