export * from './Data';
export { NSAction, Action, ActionType } from './Action';

export { reducer } from './Store';

import MyInbox from './Page/MyInbox';
import ShowThread from './Page/ShowThread';
import NewThread from './Page/NewThread';

export const Pages = {
  MyInbox,
  ShowThread,
  NewThread
};
