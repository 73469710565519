import * as R from 'ramda';

export type Version = number[];

export function fromString(versionString: string): Version {
  return versionString.split('.').map(i => parseInt(i));
}

export function version(parts: number[]): Version {
  return parts;
}

export function display(v: Version): string {
  return v.join('.');
}

export const toString = display;

export function compare(v1: Version, v2: Version): number {
  const first = compareParts(part(0, v1), part(0, v2));

  if ((v1.length === 0 && v2.length === 0) || first !== 0) {
    return first;
  } else {
    return compare(R.drop(1, v1), R.drop(1, v2));
  }
}

function compareParts(p1: number, p2: number): number {
  if (p1 < p2) { return -1; }
  else if (p2 < p1) { return 1; }
  else { return 0; }
}

function part(i: number, v: Version): number {
  return v[i] || 0;
}
