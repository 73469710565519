import * as React from 'react';
import * as R from 'ramda';

import {
  ChallengeId,
  ChallengeDetails,
  isCurrent
} from 'Challenges/Data';

import AddSteps from './AddSteps';

interface Props {
  challenge: ChallengeDetails,
  submitSteps: (challengeId: ChallengeId, stepCount: number) => void,
  leaderboard: React.ReactNode
}

export default function renderStepDetails(
  props: Props
): React.ReactElement<Props> {
  return (
    <div>
      {props.leaderboard}
      {renderAddSteps(props)}
    </div>
  );
}

function renderAddSteps(
  props: Props
): React.ReactNode {
  const summary = props.challenge.summary;
  if (isCurrent(summary)) {
    return (
      <AddSteps submit={R.curry(props.submitSteps)(summary.id)}>
      </AddSteps>
    );
  } else {
    return null;
  }
}
