import * as ENV from 'Env';
import * as R from 'ramda';
import * as humps from 'humps';
import * as Either from 'Shared/Data/Either';

export type OK = Promise<any>

export interface TokenProvider {
  get(): string | undefined
  set(token: string, persistent?: boolean): void
}

export interface ApiClient {
  request(
    method: HTTPMethod,
    url: string | ApiUrl,
    body?: BodyInit,
    headers?: { [key: string]: string }
  ): Promise<Response>,

  requestJSON<R>(
    method: HTTPMethod,
    url: string | ApiUrl,
    data?: Object
  ): Promise<R>,

  requestData(
    method: HTTPMethod,
    url: string | ApiUrl,
    data?: Object
  ): Promise<ArrayBuffer>,

  eitherRawJSON<Result>(
    method: HTTPMethod,
    url: string | ApiUrl,
    data?: Object
  ): Promise<Either.Either<Response, Result>>,

  eitherJSON<ErrorData, SuccessData>(
    method: HTTPMethod,
    url: string | ApiUrl,
    data?: Object
  ): Promise<ApiEither<ErrorData, SuccessData>>,

  addMiddleware(m: ApiClientMiddleware): void
}

export type FetchParams = [RequestInfo, RequestInit | undefined];

export interface ApiClientMiddleware {
  request(input: RequestInfo, init?: RequestInit | undefined): FetchParams
  response(result: Promise<Response>): Promise<Response>
}

export const GET = 'GET';

export const POST = 'POST';

export const PUT = 'PUT';

export const DELETE = 'DELETE';

export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

const DEFAULT_API_URL_VERSION = ENV.API_VERSION;

export interface ApiUrl {
  path: string,
  version?: number,
  query?: Object
}


export function apiClient(token: TokenProvider): ApiClient {
  let middlewares: ApiClientMiddleware[] = [];

  return {
    request, requestJSON, requestData, eitherRawJSON, eitherJSON, addMiddleware
  };

  function addMiddleware(m: ApiClientMiddleware) {
    middlewares.push(m);
  }

  function request(
    method: HTTPMethod,
    url: string | ApiUrl,
    body?: BodyInit,
    headers?: { [key: string]: string }
  ): Promise<Response> {
    if (ENV.DEBUG) {
      console.log(`API > ${method} ${apiUrl(url)}`);
    }
    const tokenStr = token.get();
    const initialRequestInfo = apiUrl(url);
    const initialRequestInit = {
      method,
      headers: {
          ...headers,
          ...(tokenStr ? {'X-JWT-Token': tokenStr } : {}),
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body
    }

    const [requestInfo, requestInit] = applyRequestMiddleware(
      middlewares, initialRequestInfo, initialRequestInit
    );

    const baseResult = fetch(requestInfo, requestInit);

    return middlewares.reduce(
      (result, middleware) => middleware.response(result),
      baseResult
    );
  }

  async function requestJSON<R>(
    method: HTTPMethod,
    url: string | ApiUrl,
    data?: Object
  ): Promise<R> {
    const r = await request(method, url, jsonRequestData(data))
    if (r.ok) {
      // Ideally we would just use r.json(), but raises an error on empty
      // responses
      return parseResponseJSON<R>(r);
    } else {
      return Promise.reject({ response: r });
    }
  }

  async function requestData(
    method: HTTPMethod, url: string | ApiUrl, data?: Object
  ): Promise<ArrayBuffer> {
    const response = await request(
      method,
      url,
      jsonRequestData(data),
      {
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream'
      }
    )
    if (response.ok) {
      return response.arrayBuffer();
    } else {
      return Promise.reject({ response });
    }
  }

  function eitherRawJSON<Result>(
    method: HTTPMethod,
    url: string | ApiUrl,
    data?: Object
  ): Promise<Either.Either<Response, Result>> {
    return requestJSON(method, url, data).then(
      (result: Result) => Either.right<Response, Result>(result),
      (failed) => {
        if (isResponse(failed.response)) {
          const value = Either.left<Response, Result>(failed.response);
          return Promise.resolve(value);
        } else {
          return Promise.reject(failed);
        }
      }
    );
  }

  function eitherJSON<ErrorData, SuccessData>(
    method: HTTPMethod,
    url: string | ApiUrl,
    data?: Object
  ): Promise<ApiEither<ErrorData, SuccessData>> {
    return requestJSON(method, url, data).then(
      (result: SuccessData) => rightApiEither<SuccessData>(result),
      (failed) => {
        if (isResponse(failed.response)) {
          return parseResponseJSON(failed.response).then(
            (data: ErrorData) => leftApiEither(failed.response.status, data)
          );
        } else {
          return Promise.reject(failed)
        }
      }
    );
  }
}

export type ApiEither<ErrorData, SuccessData> =
  Either.Either<ErrorResponse<ErrorData>, SuccessData>;

function rightApiEither<SuccessData>(
  value: SuccessData
): ApiEither<any, SuccessData> {
  return Either.right(value);
}

function leftApiEither<ErrorData>(
  status: number,
  data: ErrorData
): ApiEither<ErrorData, any> {
  return Either.left({ status, data });
}

interface ErrorResponse<R> {
  status: number,
  data: R
}

function isResponse(obj: any): obj is Response {
  const test = obj as Response;
  return (typeof obj === 'object' &&
          typeof test.headers === 'object' &&
          typeof test.ok === 'boolean' &&
          typeof test.status === 'number' &&
          typeof test.url === 'string'
         )
}

function parseResponseJSON<T>(response: Response): Promise<T> {
  return response.text().then(
    body => Promise.resolve(body === '' ? {} : JSON.parse(body))
  );
}

function jsonRequestData(data?: Object): string | undefined {
  if (data) {
    return JSON.stringify(humps.decamelizeKeys(data));
  }
}

export function apiUrl(url: string | ApiUrl): string {
  const base = ENV.API_URL_BASE;

  let version, path, qs;
  if (isString(url)) {
    version = DEFAULT_API_URL_VERSION;
    path = url;
    qs = '';
  } else {
    version = url.version || DEFAULT_API_URL_VERSION;
    path = url.path;
    qs = url.query ? '?' + objectToQs(url.query) : '';
  }
  return `${base}/api/v${version || DEFAULT_API_URL_VERSION}${path}${qs}`;

  function isString(url: string | ApiUrl): url is string {
    return typeof url === 'string';
  }
}

const LBRACK = encodeURIComponent('[');
const RBRACK = encodeURIComponent(']');
function objectToQs(obj: { [k: string]: any }, prefix?: string): string {
  return R.keys(obj).map(
    k => {
      const key = encodeURIComponent(k.toString());
      const prefixedKey = prefix ? `${prefix}${LBRACK}${key}${RBRACK}` : key;
      if (typeof obj[k] === 'object') {
        return objectToQs(obj[k], prefixedKey);
      } else {
        const value = encodeURIComponent(obj[k]);
        return `${prefixedKey}=${value}`;
      }
    }
  ).join('&')
}

function applyRequestMiddleware(
  middlewares: ApiClientMiddleware[],
  initialInfo: RequestInfo,
  initialInit: RequestInit
): FetchParams {
  return middlewares.reduce<FetchParams>(
    ([info, init], middleware) => middleware.request(info, init),
    [initialInfo, initialInit]
  );
}
