import { NATIVE_APP_SLUG } from 'Env';
import * as Cordova from 'Cordova';
import * as BLE from 'BLE/Data';
import { makeLogger } from 'Shared/Log';
import { DFU } from 'BLE/Protocols/JStyle1963YH/DFU';

export interface Service {
  withBluetooth: (
    cb?: (status: InitializeResult) => void
  ) => Promise<BluetoothlePlugin.Bluetoothle>,
  startScan: (
    cb: (device: BluetoothlePlugin.ScanStatus) => void,
    services?: string[]
  ) => Promise<void>,
  stopScan: () => Promise<void>,
  protocol: <P>(name: string, device: BLE.Device) => Promise<P>,
  dfu: (device: BLE.Device) => Promise<DFU>
}

type InitializeResult = 'enabled' | 'disabled'

const RESTORE_KEY = `1bios:bluetooth:${NATIVE_APP_SLUG}`;

export function makeService(
  bluetoothle?: BluetoothlePlugin.Bluetoothle | undefined
): Service {
  let initialized = false;
  const log = makeLogger('BLE.Service', 'BLUETOOTH_LOG');

  return {
    withBluetooth,
    startScan,
    stopScan,
    protocol,
    dfu
  };

  async function withBluetooth(
    cb?: (status: InitializeResult) => void
  ): Promise<BluetoothlePlugin.Bluetoothle> {
    if (Cordova.isCordova()) {
      await Cordova.ready();
    }
    const ble = bluetoothle || window.bluetoothle
    if (ble === undefined) {
      if (cb) { cb('disabled'); }
      return Promise.reject('Bluetooth is not available on this platform');
    }

    if (initialized) {
      return Promise.resolve(ble);
    }

    if (Cordova.isAndroid()) {
      await requestAndroidLocationPermissions(ble);
    }

    return new Promise((resolve, reject) => {
      ble.initialize(
        result => {
          log(
            'Bluetooth initialization callback:', result, result.status
          )
          if (cb) { cb(result.status) }
          if (result.status === 'enabled') {
            initialized = true;
            resolve(ble);
          } else {
            reject('Could not initialize Bluetooth');
          }
        },
        {
          request: true,
          statusReceiver: true,
          restoreKey: RESTORE_KEY
        }
      )
    });
  }

  async function startScan(
    cb: (device: BluetoothlePlugin.ScanStatus) => void,
    services?: string[]
  ): Promise<void> {
    const ble = await withBluetooth();

    return new Promise((resolve, reject) => {
      ble.startScan(
        result => {
          if (result.status === 'scanStarted') {
            resolve();
          } else if (result.status === 'scanResult') {
            log('Scan result:', result.name, result);
            cb(result);
          }
        },
        handleError('startScan', reject),
        { services }
      );
    });
  }

  async function stopScan(): Promise<void> {
    const ble = await withBluetooth();

    return new Promise((resolve, reject) => {
      ble.stopScan(
        result => {
          log('stop scan result:', result);
          resolve();
        },
        handleError('stopScan', reject)
      );
    });
  }

  async function protocol<P>(
    name: BLE.DeviceProtocol, device: BLE.Device
  ): Promise<P> {
    const ble = await withBluetooth();
    const makeProtocol = require(`BLE/Protocols/${name}/Protocol`).makeProtocol;
    return Promise.resolve(makeProtocol(ble, device));
  }

  async function dfu(device: BLE.Device): Promise<DFU> {
    const ble = await withBluetooth();
    return new DFU(device, ble);
  }

  async function requestAndroidLocationPermissions(
    ble: BluetoothlePlugin.Bluetoothle
  ): Promise<void> {
    let authed = await isLocationEnabled(ble);
    if (!authed) {
      return new Promise((resolve, reject) => {
        ble.requestPermission(
          result => {
            if (result.requestPermission) {
              return resolve();
            } else {
              return reject('Location permissions were not granted.');
            }
          }
        );
      });
    } else {
      return Promise.resolve();
    }
  }

  function isLocationEnabled(
    ble: BluetoothlePlugin.Bluetoothle
  ): Promise<boolean> {
    return new Promise(resolve => {
      ble.hasPermission(result => resolve(result.hasPermission));
    });
  }

  function handleError(label: string, reject?: (e: any) => void) {
    return (error: BluetoothlePlugin.Error) => {
      console.error(`[BLE.Service] Error on ${label}: `, error);
      if (reject) { reject(error) }
    }
  }
}
