import * as React from 'react';
import * as R from 'ramda';
import * as moment from 'moment-timezone';
import * as classnames from 'classnames';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bem } from 'css-util';
import * as Urls from 'Shared/Urls';
import { safeLink } from 'Shared/SafeLink';

import { State, StoreDispatch } from 'Store';

import { HUD } from 'HUD';
import { plural } from 'Shared/Text';
import Link from 'Shared/UI/Link';
import Points from 'Shared/UI/Points';
import AlertBox from 'Shared/UI/AlertBox';
import Icon from 'Shared/UI/Icon';
import NoContent from 'misc/UI/NoContent';
import StatusCircle, { Status } from 'Shared/UI/StatusCircle';

import {
  LENSES, ChallengeId, ChallengeDetails, isTaskActionDetails, isTaskProgress,
  TaskActionDetails, TaskChallengeProgress, TaskChallengeDayDetail,
  isTaskMetricWithDailyCompletion
} from 'Challenges/Data';
import * as Data from 'Challenges/Data';
import { Task, TaskId } from 'Tasks/Data';
import { ActionCreators } from 'Challenges/ActionCreator';

import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { NavBarLayout } from 'Nav';

type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch
};

type URLParams = {
  challengeId: string,
  date?: string
}


class TaskChallengeDailyDetails extends React.Component<Props, {}> {
  componentDidMount() {
    this.validateDate();
    this.props.dispatch(ActionCreators.loadChallenge(this.challengeId()));
    this.loadDailyProgressData()
  }

  componentDidUpdate(prevProps: Props) {
    this.validateDate();
    if (
      this.dateInURL(prevProps) !== this.dateInURL(this.props) ||
      this.challenge(prevProps) !== this.challenge()
    ) {
      this.loadDailyProgressData();
    }
  }

  loadDailyProgressData() {
    const challenge = this.challenge();
    if (challenge && !Data.isUpcoming(challenge)) {
      this.props.dispatch(
        ActionCreators.loadTaskChallengeDayDetail(
          this.challengeId(), this.date()
        )
      );
    }
  }

  validateDate() {
    const challenge = this.challenge();

    if (challenge) {
      const urlDate = this.date();
      const today = moment.tz(challenge.summary.tzId).startOf('day');
      const { startDate, endDate } = challenge.summary;
      const maxDate = moment.min(today, endDate);
      if (!Data.isUpcoming(challenge)) {
        /**
         * current or completed challenge, just make sure the date is >= the
         * challenge start date and <= either today or the end date, whichever
         * came first
         */
        if (urlDate.isBefore(startDate, 'day')) {
          this.gotoDate(startDate);
        } else if (urlDate.isAfter(maxDate, 'day')) {
          this.gotoDate(maxDate);
        }
      }
    }
  }

  challengeId(): ChallengeId {
    return parseInt(this.props.match.params.challengeId);
  }

  dateInURL(props?: Props): string | undefined {
    props = props || this.props;
    return props.match.params.date;
  }

  date(props?: Props): moment.Moment {
    props = props || this.props;
    const urlDate = this.dateInURL(props);
    const challenge = this.challenge(props);
    const tzId = challenge ? challenge.summary.tzId : undefined;
    if (urlDate && tzId) {
      return moment.tz(urlDate, 'YYYY-MM-DD', tzId);
    } else if (urlDate) {
      return moment(urlDate, 'YYYY-MM-DD');
    } else if (tzId) {
      return moment.tz(tzId);
    } else {
      return moment();
    }
  }

  challenge(props?: Props): ChallengeDetails | undefined {
    props = props || this.props;
    return LENSES.challenge(this.challengeId()).get(props.challenges);
  }

  dayProgress(): TaskChallengeDayDetail | undefined {
    return LENSES.currentTaskChallengeDayDetail(this.props.challenges);
  }

  gotoDate = (date: moment.Moment) => {
    this.props.dispatch(
      push(Urls.taskChallengeDailyDetailsUrl(this.challengeId(), date))
    );
  }

  completeTask = (taskId: TaskId) => {
    this.props.dispatch(
      ActionCreators.completeTaskForChallenge(
        this.challengeId(), taskId, this.date()
      )
    );
  }

  undoTask = (taskId: TaskId) => {
    this.props.dispatch(
      ActionCreators.undoTaskForChallenge(
        this.challengeId(), taskId, this.date()
      )
    );
  }

  render() {
    const challenge = this.challenge();
    const dayProgress = this.dayProgress();
    return (
      <NavBarLayout
        title="Challenge"
        initBreadcrumb={Urls.challengeUrl(this.challengeId())}>
        <div className="simple-container">
          {this.renderContent(challenge, dayProgress)}
        </div>
      </NavBarLayout>
    );
  }

  renderContent(
    challenge: undefined | ChallengeDetails,
    dayDetail: undefined | TaskChallengeDayDetail
  ) {
    if (challenge === undefined) {
      return this.renderLoading();
    } else if (Data.isUpcoming(challenge)) {
      return this.renderNotStarted(challenge);
    } else if (
      isTaskActionDetails(challenge.actionDetails) &&
      isTaskProgress(challenge.summary.progress) &&
      dayDetail
    ) {
      return this.renderLoaded(
        challenge,
        challenge.actionDetails,
        challenge.summary.progress,
        dayDetail
      );
    } else {
      return this.renderLoading();
    }
  }

  renderLoading() {
    return <div className="simple-container"><HUD state="loading"/></div>;
  }

  renderNotStarted(_c: ChallengeDetails) {
    return (
      <div>
        <h2 className="text-center">Daily Points</h2>
        <NoContent>This challenge has not yet started</NoContent>
      </div>
    );
  }

  renderLoaded(
    challenge: ChallengeDetails,
    actionDetails: TaskActionDetails,
    progress: TaskChallengeProgress,
    dayDetails: TaskChallengeDayDetail
  ) {
    const totalCompleted = this.completedPoints(progress)
    const totalAvailable = Data.totalAvailableDailyPoints(actionDetails);
    const isDaily = isTaskMetricWithDailyCompletion(challenge.summary.metric);
    return (
      <div>
        <HUD state={this.props.hud} />
        {isDaily && (
           <DateNav
             currentDate={this.date()}
             gotoDate={this.gotoDate}
             challenge={challenge}
           />
        )}
        <h2 className="text-center">
          {isDaily && 'Daily '}Points:{' '}
          <Points>{totalCompleted} of {totalAvailable}</Points>
        </h2>

        {dayDetails.locked && renderLockedNotice()}
        <TaskList
          progress={progress}
          actionDetails={actionDetails}
          dayDetails={dayDetails}
          challenge={challenge}
          completeTask={this.completeTask}
          undoTask={this.undoTask} />
      </div>
    );
  }

  completedPoints(progress: TaskChallengeProgress): number {
    return Data.dailyPointsMapLookup(this.date(), progress.dailyPoints);
  }
}

export default withRouter(connect(
  state => state,
  dispatch => ({ dispatch })
)(TaskChallengeDailyDetails));


/** private **/

interface DateNavProps {
  currentDate: moment.Moment,
  gotoDate: (date: moment.Moment) => void,
  challenge: ChallengeDetails
}

const DateNavCSS = bem('task-challenge-date-nav');
const DateNav = (props: DateNavProps) => {
  const { challenge, currentDate } = props;
  const summary = challenge.summary;
  const previousDate = currentDate.clone().subtract(1, 'day');
  const nextDate = currentDate.clone().add(1, 'day');

  const leftClasses = classnames(
    'button', 'button-gray', DateNavCSS('button')(['left'])
  );
  const rightClasses = classnames(
    'button', 'button-gray', DateNavCSS('button')(['right'])
  );

  const today = moment();
  const earliestDate = summary.startDate;
  const latestDate =
    summary.endDate.isAfter(today, 'day') ? today : summary.endDate;

  return (
    <div className={DateNavCSS()()}>
      {previousDate.isSameOrAfter(earliestDate, 'day') ?
       (
         <Link
           to={() => props.gotoDate(previousDate)}
           className={leftClasses}>
         &lt; Older
         </Link>
       ) : <span className={DateNavCSS('button')()}></span>
      }
      <h3 className={DateNavCSS('date')()}>
        {props.currentDate.format('MMM D, YYYY')}
      </h3>
      {nextDate.isSameOrBefore(latestDate, 'day') ?
       (
         <Link
           to={() => props.gotoDate(nextDate)}
           className={rightClasses}>
           Newer &gt;
         </Link>
       ) : <span className={DateNavCSS('button')()}></span>
      }
    </div>
  );
};


interface TaskListProps {
  actionDetails: TaskActionDetails,
  challenge: ChallengeDetails,
  dayDetails: TaskChallengeDayDetail,
  progress: TaskChallengeProgress,
  completeTask: (taskId: TaskId) => void,
  undoTask: (taskId: TaskId) => void
}

const TaskListCSS = bem('task-list');
const TaskList = (props: TaskListProps) => {
  return (
    <ul className="list--unstyled">
      {props.actionDetails.task.categorizedTasks.map(ct => (
        <li key={ct.category.id}>
          <h4 className="section-title section-title--mb0">
            {ct.category.name}
          </h4>
          <ul className="list--unstyled">
            {ct.tasks.map(task => (
              <li key={task.id} className={TaskListCSS('list-item')()}>
                <div className={TaskListCSS('task-details')()}>
                  <span className={TaskListCSS('task-name')()}
                    dangerouslySetInnerHTML={{__html: safeLink(task.name)}}>
                  </span>
                  <br/>
                  <Points>
                    ({plural(
                        'point',
                        Data.taskPointsLookup(
                          task.id, props.actionDetails.task.taskPoints
                        )
                    )})
                  </Points>
                </div>
                <div className={TaskListCSS('task-status')()}>
                  {renderStatusCircle(
                     props.completeTask, props.undoTask, task, props.dayDetails
                  )}
                </div>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  )
}

function renderStatusCircle(
  completeTask: (taskId: TaskId) => void,
  undoTask: (taskId: TaskId) => void,
  task: Task,
  dayDetails: TaskChallengeDayDetail
) {
  const isComplete = Data.taskIsCompleted(task.id, dayDetails);
  let status;
  if (isComplete) {
    status = Status.COMPLETE;
  } else if (R.contains(task.id, dayDetails.availableTaskIds)) {
    status = Status.AVAILABLE;
  } else {
    status = Status.UNAVAILABLE;
  }

  const circle = <StatusCircle status={status} />;
  if (status === Status.AVAILABLE) {
    return (
      <div onClick={() => completeTask(task.id)} className="pointer">
        {circle}
      </div>
    );
  } else {
    return (
      <div>
        {circle}
        {renderTaskUndoButton(undoTask, task, dayDetails)}
      </div>
    );
  }
}

function renderTaskUndoButton(
  undoTask: (taskId: TaskId) => void,
  task: Task,
  dayDetails: TaskChallengeDayDetail
) {
  const isComplete = Data.taskIsCompleted(task.id, dayDetails);
  if (isComplete && !dayDetails.locked) {
    return (
      <Link to={() => undoTask(task.id)}
        className="task-challenge-tasks__undo"
        themed={true}>
        undo
      </Link>
    );
  }
}

function renderLockedNotice() {
  return (
    <AlertBox modifiers={['info', 'icon']}>
      <Icon className="alert-box__icon-left" name="info-circle"/>
      <span className="alert-box__icon-text">
        The window to complete tasks for this day is closed
      </span>
    </AlertBox>
  );
}
