import * as React from 'react';
import * as moment from 'moment-timezone';
import * as Urls from 'Shared/Urls';

import Link from 'Shared/UI/Link';
import {
  ChallengeDetails, TaskMetric, TaskCompletionFrequency,
  isCurrent, isComplete, isTaskMetric
} from 'Challenges/Data';

interface Props {
  challenge: ChallengeDetails,
  metric: TaskMetric,
  leaderboard: React.ReactNode
}

export default function renderTaskDetails(
  props: Props
): React.ReactElement<Props> {
  const challenge = props.challenge;
  return (
    <div>
      {canPost(challenge) ? renderCompleteTasksLink(challenge) : null}
      {props.leaderboard}
      {renderViewChallengeSummary(challenge)}
      {renderViewDayByDayDetail(challenge)}
    </div>
  );
}

function canPost(challenge: ChallengeDetails) {
  const yesterday = moment().subtract(1, 'day');
  return (
    isCurrent(challenge) ||
    (isComplete(challenge) &&
     challenge.summary.endDate.isSame(yesterday, 'day')
    )
  );
}

function renderCompleteTasksLink(
  challenge: ChallengeDetails
): React.ReactNode {
  return button(
    'COMPLETE CHALLENGE TASKS',
    Urls.taskChallengeDailyDetailsUrl(challenge.summary.id)
  );
}

function renderViewChallengeSummary(
  challenge: ChallengeDetails
): React.ReactNode {
  return button(
    'VIEW MY CHALLENGE SUMMARY',
    Urls.taskChallengeSummaryUrl(challenge.summary.id)
  );
}

function renderViewDayByDayDetail(
  challenge: ChallengeDetails
): React.ReactNode {
  const metric = challenge.summary.metric;
  if (
    isTaskMetric(metric) &&
    metric.completionFrequency === TaskCompletionFrequency.DAILY
  ) {
    return button(
      'VIEW MY DAY BY DAY DETAIL',
      Urls.taskChallengeDailyDetailsUrl(
        challenge.summary.id, challenge.summary.startDate
      )
    );
  }
}

function button(text: string, action: string): React.ReactNode {
  return (
    <Link
      to={action}
      className="button button--block theme__primary-bg"
      breadcrumb="push">
      {text}
    </Link>
  );
}
