import * as moment from 'moment-timezone';
import { UserId } from 'User/Data';
import {
  IDMap, newIDMap, idMapValuesIsomorphism, idMapValuesDefaultIsomorphism
} from 'Shared/Data/IDMap';
import { TimeRange } from 'Shared/Data/Range';
import { Lens, Prism } from '@atomic-object/lenses';

export type NSType = 'APPTS';
export const NS: NSType = 'APPTS';

export type ApptId = string;

export type ApptTypeId = string;

type Minutes = number;

export interface State {
  apptTypes: IDMap<ApptType>,
  appts: IDMap<Appt>,
  availableSlots: AvailableSlots | undefined
}

export interface AvailableSlots {
  slots: Slot[],
  dates: TimeRange
}

export interface ApptType {
  id: ApptTypeId
  name: string
  details: string
  reservableStart: moment.Moment | undefined,
  reservableEnd: moment.Moment | undefined
}

export interface Appt {
  id: ApptId,
  type: ApptType,
  userId: UserId,
  slot: Slot
}

export interface Slot {
  startTime: moment.Moment,
  duration: Minutes
}


export function initialState(): State {
  return {
    apptTypes: newIDMap(),
    appts: newIDMap(),
    availableSlots: undefined
  };
}

export function slotEndTime(slot: Slot): moment.Moment {
  return slot.startTime.clone().add(slot.duration, 'minutes');
}

export function slotLabel(slot: Slot, userZoneId: string): string {
  return slot.startTime.tz(userZoneId).format('h:mm A - ') +
    slotEndTime(slot).tz(userZoneId).format('h:mm A');
}

export function nextPageDates(
  apptType: ApptType, range: TimeRange
): TimeRange | undefined {
  const next = {
    start: range.start.clone().add(1, 'week'),
    end: range.end.clone().add(1, 'week')
  };

  if (apptType.reservableEnd && next.start.isAfter(apptType.reservableEnd)) {
    return undefined;
  } else {
    return next;
  }
}

export function previousPageDates(
  apptType: ApptType, range: TimeRange
): TimeRange | undefined {
  if (moment().isAfter(range.start)) { return undefined; }

  const prev = {
    start: range.start.clone().subtract(1, 'week'),
    end: range.end.clone().subtract(1, 'week')
  };

  if (prev.end.isBefore(apptType.reservableStart)) {
    return undefined;
  } else {
    return prev;
  }
}
/*------------------------------------------------------------*/
/* Lenses and selectors */
/*------------------------------------------------------------*/

interface ApptLenses {
  apptTypes: Lens<State, ApptType[]>

  apptType(id: ApptTypeId): Prism<State, ApptType>

  appts: Lens<State, Appt[]>

  appt(id: ApptId): Prism<State, Appt>

  availableSlots: Lens<State, AvailableSlots | undefined>
}

function makeLenses(): ApptLenses {
  const base = Lens.from<State>();

  return {
    apptTypes: Lens.map(
      base.prop('apptTypes'), idMapValuesIsomorphism(t => t.id)
    ),
    apptType(id: ApptTypeId) {
      return base.prop('apptTypes', id);
    },
    appts: Lens.map(
      base.prop('appts'), idMapValuesDefaultIsomorphism()
    ),
    appt(id: ApptId) {
      return base.prop('appts', id);
    },
    availableSlots: base.prop('availableSlots')
  };
}

export const LENSES = makeLenses();

export const SEL = {
  apptTypes(s: State): ApptType[] {
    return LENSES.apptTypes.get(s);
  },

  apptType(id: ApptTypeId, s: State): ApptType | undefined {
    return LENSES.apptType(id).get(s);
  },

  appts(s: State): Appt[] {
    return LENSES.appts.get(s);
  },

  appt(id: ApptId, s: State): Appt | undefined {
    return LENSES.appt(id).get(s);
  },

  availableSlots(s: State): AvailableSlots | undefined {
    return LENSES.availableSlots.get(s);
  }
};
