import * as React from 'react';
import { trendsUrl } from 'Shared/Urls';
import { push } from 'connected-react-router';
import { State, StoreDispatch } from 'Store';
import { defaultConnect } from 'Shared/ReduxComponent';
import Spinner from 'Shared/UI/Spinner';
import { NavBarLayout  } from 'Nav';
import { HUD } from 'HUD';

type Props = State & {
  dispatch: StoreDispatch
}

class MyTrendsRedirect extends React.Component<Props, {}> {
  componentDidMount() {
    this.componentDidUpdate();
  }
  componentDidUpdate() {
    const {currentUser, dispatch} = this.props;
    if (!!currentUser) {
      const profile =  currentUser.profile;
      if (!!profile) {
        dispatch(push(trendsUrl(profile.id)));
      }
    }
  }

  render() {
    return(
      <NavBarLayout title="Trends">
        <HUD state='loading' />
         <Spinner wrap="center" />
      </NavBarLayout>
    )
  }
}

export default defaultConnect(MyTrendsRedirect);
