import * as React from 'react';
import { bem } from 'css-util';
import { SurveyResponse, SurveyResponseOption } from 'Screenings/Data';

interface Props {
  responses: SurveyResponse[]
}

const SurveyResponses = (props: Props) => {
  const [column1, column2] = surveyColumns(props.responses);
  return (
    <div>
      <div className="survey__column">
        {column1.map(renderResponse)}
      </div>
      <div className="survey__column__gutter"></div>
      <div className="survey__column">
        {column2.map(renderResponse)}
      </div>
    </div>
  );
}

export default SurveyResponses;

function renderResponse(response: SurveyResponse, i: number): React.ReactNode {
  return (
    <div className="survey__question" key={i}>
      <p className="survey__question-text">
        {response.text}
      </p>
      <ul className="survey__options">
        {response.options.map(renderResponseOption)}
      </ul>
    </div>
  );
}

function renderResponseOption(option: SurveyResponseOption, i: number) {
  const css = bem('survey');
  const modifier = option.selected ? 'selected' : 'not-selected';
  const liClasses = css('option')([modifier]);
  const liStyle = option.selected ? { color: option.color } : {};
  const bboxClasses = css('ballot-box')([modifier]);
  const bboxContent = option.selected ? '&#x2717' : '&#9744;';
  return (
    <li className={liClasses} style={liStyle} key={i}>
      <span className={bboxClasses}
        dangerouslySetInnerHTML={{__html: bboxContent}} />
      <span className="survey__option-text">
        {option.label}
      </span>
    </li>
  );
}

function surveyColumns(
  responses: SurveyResponse[]
): [SurveyResponse[], SurveyResponse[]] {
  const col1size = Math.ceil(responses.length / 2);
  const col1 = responses.slice(0, col1size);
  const col2 = responses.slice(col1size, responses.length);
  return [col1, col2];
}
