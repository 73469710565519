import { MiddlewareAPI } from 'redux';
import { State } from 'Store';
import { ready } from 'Cordova';

import { ActionCreators } from './ActionCreator';

type StoreAPI = MiddlewareAPI<State>;

export function watchNetwork(store: StoreAPI) {
  ready().then(() => {
    checkNetworkStatus(store);
    window.document.addEventListener('offline', () => goOffline(store), false);
    window.document.addEventListener('online', () => goOnline(store), false);
  });
}

function checkNetworkStatus(store: StoreAPI) {
  if (isOffline()) {
    goOffline(store);
  } else {
    goOnline(store);
  }
}

function isOffline() {
  return !isOnline();
}

function isOnline() {
  return window.navigator.connection.type !== Connection.NONE;
}

function goOffline(store: StoreAPI) {
  store.dispatch(ActionCreators.goOffline());
}

function goOnline(store: StoreAPI) {
  store.dispatch(ActionCreators.goOnline());
}
