import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { State } from 'Store';
import * as Auth from 'Auth';

type PrivateRouteProps = RouteProps & {
  authState: Auth.State
};

type PrivateRoute = React.SFC<PrivateRouteProps>;

const PrivateRoute: PrivateRoute =
  ({ component: Component, render, authState, ...rest }) => {
    const renderFunc = (props: any) => {
      if (authState && Auth.isLoggedIn(authState)) {
        if (Component) {
          return <Component {...props} />;
        } else if (render) {
          return render(props);
        }
      } else {
        return <Redirect to="/login" />;
      }
    };
    return (
      <Route {...rest} render={renderFunc} />
    );
  };

export default connect(
  (state: State) => ({ authState: state.auth })
)(PrivateRoute as React.SFC<PrivateRouteProps>);
