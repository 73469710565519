import { connect } from 'react-redux';
import { State } from 'Store';
import ThemeStyles from './ThemeStyles';

export default connect(
  (state: State) => {
    const slug =
      state.currentUser && state.currentUser.program ?
      state.currentUser.program.slug :
      undefined;
    return { slug };
  },
  null
)(ThemeStyles);
