import * as React from 'react';
import * as classnames from 'classnames';
import { Loadable, isLoaded } from 'misc/Data/Loadable';
import Link from 'Shared/UI/Link';
import { StoreDispatch } from 'Store';
import { State as NavState } from 'Nav/Data';
import { AppFeatures, AppFeatureKey, featuresEnabled } from 'User/Data';
import * as Urls from 'Shared/Urls';
import { bem } from 'css-util';
import { ActionCreators } from 'Nav/ActionCreator';
import BodyOverlay from 'Nav/UI/NavBarBodyOverlay';

interface Props {
  navState: NavState
  appFeatures: Loadable<AppFeatures>,
  dispatch: StoreDispatch
  onOpen?: () => void
  onClose?: () => void
  modifier?: string
  postPageLabel: string
}

const NAVBEM = bem('main-nav');

class NavMenu extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.state = { open: false };
    this.toggleState = this.toggleState.bind(this);
  }

  isOpen(): boolean {
    return this.props.navState.open;
  }

  withFeatures(keys: AppFeatureKey[], content: React.ReactNode) {
    const features = this.props.appFeatures;
    if (isLoaded(features) && featuresEnabled(features.value, keys)) {
      return content;
    }
  }

  toggleState() {
    if (this.isOpen()) {
      this.props.dispatch(ActionCreators.closeNav());
      if (this.props.onClose) { this.props.onClose(); }
    } else {
      this.props.dispatch(ActionCreators.openNav());
      if (this.props.onOpen) { this.props.onOpen(); }
    }
  }

  render() {
    return (
      <div className={NAVBEM()([this.props.modifier])}>
        <BodyOverlay blur={this.isOpen()} />

        <div
          className={classnames(
              NAVBEM('toggle')([this.props.modifier]), 'theme__primary-bg')}
          onClick={this.toggleState}>
          {this.renderToggle()}
        </div>
        {this.isOpen() && this.renderNav()}
      </div>
    );
  }

  renderToggle() {
    return (
      <div className="hamburger">
        <hr className="hamburger-line"/>
        <hr className="hamburger-line"/>
        <hr className="hamburger-line"/>
        <span className="hamburger-label">menu</span>
      </div>
    );
  }
  renderNav() {
    return (
      <div className="main-nav__nav-wrapper theme__primary-bg">
        <nav className="main-nav__nav">
          <ul className="main-nav__big-links-list">
            {bigLink('Home', Urls.homeUrl(), this.toggleState)}
            {bigLink('My Dashboard', Urls.dashboardUrl(), this.toggleState)}
            {bigLink(
               'Connected Devices', Urls.edgeSettingsUrl(), this.toggleState)}
            {bigLink(this.props.postPageLabel, Urls.postUrl(), this.toggleState)}
            {this.withFeatures(
               ['crew'],
               bigLink('Community', Urls.communityUrl(), this.toggleState)
             )
            }
            {this.withFeatures(
               ['inbox'],
               bigLink('Messages', Urls.inboxUrl(), this.toggleState)
             )
            }
            {this.withFeatures(
               ['appts'],
               bigLink(
                 'Appointments', Urls.apptsUrl(), this.toggleState, ['last'])
             )
            }
          </ul>
          <ul className="main-nav__small-links-list">
            {littleLink('User Guide', Urls.userGuideUrl(), this.toggleState)}
            {littleLink('Profile', Urls.profileSettingsUrl(), this.toggleState)}
            {littleLink(
               'Notification Settings',
               Urls.notificationSettingsUrl(),
               this.toggleState)}
            {littleLink('Contact', Urls.contactUrl(), this.toggleState)}
            {littleLink('Logout', Urls.logoutUrl(), this.toggleState, ['last'])}
          </ul>
        </nav>
      </div>
    );
  }
}

(NavMenu as React.ComponentClass<Props>).displayName = 'App/UI/NavMenu';
export default NavMenu;

function bigLink(
  label: string, url: string, onClick: () => void, modifiers?: string[]
): React.ReactNode {
  const css = NAVBEM('big-link-item');
  return (
    <li className={css(modifiers)}>
      <Link
        to={url} onClick={onClick} breadcrumb="clear"
        className={NAVBEM('big-link')()}>
        {label}
      </Link>
    </li>
  );
}

function littleLink(
  label: string, url: string, onClick: () => void, modifiers?: string[]
): React.ReactNode {
  const css = NAVBEM('small-link-item');
  return (
    <li className={css(modifiers)}>
      <Link
        to={url}
        onClick={onClick}
        breadcrumb="clear"
        className={NAVBEM('small-link')()}
      >
        {label}
      </Link>
    </li>
  );
}
