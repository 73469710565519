import * as R from 'ramda';
import * as moment from 'moment-timezone';
import * as React from 'react';
import * as qs from 'query-string';
import { push } from 'connected-react-router';
import { State, StoreDispatch } from 'Store';
import { SEL as UserSEL, loadableFeaturesEnabled } from 'User';
import {
  SEL, Biopost, State as PostState, BiopostFeed, BiopostId, Comment
} from 'Post/Data';
import { HUD } from 'HUD';
import { ActionCreators } from 'Post/ActionCreator';
import { Paging, defaultPaging } from 'Shared/Paging';
import Spinner from 'Shared/UI/Spinner';
import Pager from 'Shared/UI/Pager';
import NoContent from 'misc/UI/NoContent';
import BiopostUI from 'Post/UI/Biopost';

type FeedType = 'me' | 'crew' | 'user';
type Props = State & {
  dispatch: StoreDispatch
  feedType: FeedType
  showUser?: boolean
  loadPage: (page: number) => void
  loadDynamicPosts?: () => void
}

class ActivityFeed extends React.Component<Props, {}> {
  componentDidMount() {
    this.loadPosts();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.page() !== this.page(prevProps)) {
      this.loadPosts();
    }
    const currentIds = this.props.post.feed.objectIds;
    const previousIds = prevProps.post.feed.objectIds;
    if (currentIds != previousIds) {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  loadPosts() {
    this.props.loadPage(this.page());
    if (this.page() === 1) {
      this.refreshDynamicPosts();
    }
  }

  refreshDynamicPosts = () => {
    if (this.props.loadDynamicPosts) {
      this.props.loadDynamicPosts();
    }
  }

  postState(): PostState {
    return this.props.post;
  }

  page(props?: Props): number {
    props = props || this.props;
    const location = props.router ? props.router.location : { search: '' };
    const qsPageStr = qs.parse(location.search).page;
    const qsPage = parseInt(qsPageStr);
    return isNaN(qsPage) ? 1 : qsPage;
  }

  feed(): BiopostFeed {
    return this.postState().feed;
  }

  dynamicPosts(): Biopost[] {
    if (this.props.feedType === 'me' && this.page() === 1) {
      return this.props.post.myDynamicPosts;
    } else {
      return [];
    }
  }

  bioposts(): Biopost[] | undefined {
    const feed = this.feed();
    if (!feed) { return; }

    const currentPostIds = feed.objectIds;
    if (currentPostIds) {
      return R.reject(
        R.isNil,
        currentPostIds.map(
          postId => SEL.getBiopost(postId, this.postState())
        )
      );
    } else {
      return undefined;
    }
  }

  comments(biopost: Biopost): Comment[] | undefined {
    return SEL.comments(biopost.id, this.postState());
  }

  currentUserId(): string | undefined {
    return UserSEL.userId(this.props.currentUser);
  }

  paging(): Paging {
    const feed = this.feed();
    return feed ? feed.paging : defaultPaging();
  }

  showScore(): boolean {
    return loadableFeaturesEnabled(
      UserSEL.appFeatures(this.props.currentUser),
      ['healthScore']
    );
  }

  loading(): boolean {
    return this.feed().loading;
  }

  gotoPage = (page: number) => {
    this.props.dispatch(push({ search: qs.stringify({ page }) }));
  }

  loadComments = (biopostId: BiopostId) => {
    this.props.dispatch(ActionCreators.loadComments(biopostId));
  }

  giveProps = (biopostId: BiopostId) => {
    this.props.dispatch(ActionCreators.giveProps(biopostId));
  }

  postComment = (
    biopostId: BiopostId, comment: string, onComplete?: () => void
  ) => {
    this.props.dispatch(
      ActionCreators.postComment(biopostId, comment, onComplete)
    );
  }

  render() {
    const posts = this.bioposts();
    const userId = this.currentUserId();

    return (
      <div>
        {posts &&
         userId &&
         !this.loading() &&
         this.renderFeed(posts, userId)}
        {this.loading() &&
         <div className="activity-feed__spinner">
           <Spinner />
         </div>
        }
        <HUD state={this.props.hud} />
      </div>
    );
  }

  renderNoData() {
    return (
      <div className="simple-container">
        <NoContent message="no data to display" />
      </div>
    );
  }

  renderFeed(posts: Biopost[], userId: string): React.ReactNode {
    if (posts.length === 0) { return this.renderNoData(); }

    const grouped: { [date: string]: Biopost[] } =
      R.groupBy(
        post => formatPostTime(post.time),
        R.concat(this.dynamicPosts(), posts)
      );

    const paging = this.paging();

    return (
      <div className="activity-feed">
        <ul className="activity-feed__day-list">
          {R.keys(grouped).map(date =>
            this.renderGroupedPosts(grouped[date], date.toString(), userId)
           )}
        </ul>
        <Pager
          nextPage={paging.nextPage}
          nextLabel="older"
          previousPage={paging.previousPage}
          previousLabel="newer"
          gotoPage={this.gotoPage}
        />
      </div>
    );
  }

  renderGroupedPosts(
    posts: Biopost[], formattedDate: string, userId: string
  ): React.ReactNode {
    return (
      <li key={formattedDate}>
        <h5 className="activity-feed__day">{formattedDate}</h5>
        <ul className="activity-feed__post-list">
          {sortDynamicInFront(posts)
            .map(post => this.renderBiopost(post, userId))}
        </ul>
      </li>
    );
  }

  renderBiopost(post: Biopost, userId: string): React.ReactNode {
    return (
      <li key={post.id} className="activity-feed__post-list-item">
        <BiopostUI
          biopost={post}
          showScore={this.showScore()}
          loadComments={this.loadComments}
          postComment={this.postComment}
          giveProps={this.giveProps}
          comments={this.comments(post)}
          currentUserId={userId}
          showUser={this.props.showUser}
          refreshDynamicPosts={this.refreshDynamicPosts} />
      </li>
    );
  }
}

export default ActivityFeed;


function formatPostTime(time: moment.Moment): string {
  if (time.isSame(moment(), 'day')) {
    return 'today';
  } else {
    return time.format('MMM D, YYYY');
  }
}

function sortDynamicInFront(posts: Biopost[]): Biopost[] {
  return R.sort(
    (a, b) => {
      if (a.dynamic && b.dynamic) {
        return 0;
      } else if (a.dynamic) {
        return -1;
      } else if (b.dynamic) {
        return 1;
      } else {
        return 0;
      }
    },
    posts
  );
}
