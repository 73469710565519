import * as Version from 'Shared/Data/Version';

export type NSType = 'BLE';
export const NS: NSType = 'BLE';

export interface State {
  availability: Availability,
  scanStatus: ScanStatus,
  peripherals: Device[],
  firmwareStatuses: { [address: string]: FirmwareStatus | undefined },
  progress: number,
  // Is currently engaged in DFU? (device firmware update)
  isDFU: boolean
}

export function initialState(): State {
  return {
    availability: Availability.UNKNOWN,
    scanStatus: ScanStatus.NOT_SCANNING,
    peripherals: [],
    firmwareStatuses: {},
    progress: 0,
    isDFU: false
  };
}

export interface FirmwareStatus {
  currentVersion: Version.Version | undefined,
  latestVersion: Version.Version | undefined
}

export interface Device {
  name: string,
  address: string
}

export interface ProtocolCommon {
  init: () => Promise<void>,
  close: () => Promise<void>,
  supportsBPCalibration: () => Promise<boolean>,
  setBPCalibration?: (systolic: number, diastolic: number) => Promise<void>
}

export enum Availability {
  UNKNOWN = 'UNKNOWN',
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE'
}

export enum ScanStatus {
  NOT_SCANNING = 'NOT_SCANNING',
  SCANNING = 'SCANNING'
}

export enum DeviceProtocol {
  JStyle1963YH = 'JStyle1963YH',
  JStyle1790 = 'JStyle1790',
  GreaterGoodsA6 = 'GreaterGoodsA6'
}

export enum SyncMode {
  Active = 'Active',
  Passive = 'Passive'
}

export function syncMode(protocol: DeviceProtocol): SyncMode {
  switch(protocol) {
    case DeviceProtocol.JStyle1963YH:
    case DeviceProtocol.JStyle1790:
      return SyncMode.Passive;
    case DeviceProtocol.GreaterGoodsA6:
      return SyncMode.Active;
  }
}

export function isPassiveSync(protocol: DeviceProtocol): boolean {
  return syncMode(protocol) === SyncMode.Passive;
}

export function isActiveSync(protocol: DeviceProtocol): boolean {
  return syncMode(protocol) === SyncMode.Active;
}

export function hasBLE(state: State): boolean {
  return state.availability === Availability.AVAILABLE;
}

export function isScanning(state: State): boolean {
  return state.scanStatus === ScanStatus.SCANNING;
}

export function needsUpgrade(status: FirmwareStatus): boolean {
  return status.currentVersion !== undefined &&
    status.latestVersion !== undefined &&
    Version.compare(status.currentVersion, status.latestVersion) < 0;
}
