import { createLogger } from 'redux-logger'

import * as Developer from 'Shared/Developer';
import { State, initialState } from 'App/Data';

import { Action, ActionType } from './Action';


/**
 * Reducer for "global" app actions
 */
export function reducer(state: State, action: Action): State {
  switch(action.type) {
  case ActionType.INITIALIZE_APP:
    return state;
  case ActionType.RESET_APP:
    return { ...initialState(), router: state.router };
  }
}

/**------------------------------------------------------------**/
/** Middleware **/

export const loggerMiddleware = createLogger({
  predicate: () => Developer.enabled('STORE_LOGGER')
});
