import './ProfPic.scss';
import * as React from 'react';

import { profPicUrl } from 'Shared/ProfPic'

type ProfPicVariant = 'default' | 'round';

interface Props {
  userId: string,
  variant?: ProfPicVariant,
  size?: number,
  className?: string
};

export default function renderProfPic(props: Props): React.ReactElement<Props> {
  const variant = props.variant || 'default';
  const size = props.size || 100;
  const classes = `${classesFor(variant)} ${props.className || ''}`;
  return (
    <img src={profPicUrl(props.userId, size)} className={classes}/>
  );
}

function classesFor(variant: ProfPicVariant): string {
  return `prof-pic prof-pic--${variant}`;
}
