import {
  ApiClient,
  GET, POST, OK
} from 'Api';
import { Either } from 'Shared/Data/Either';

import {
  AuthSettingsResponseJSON,
  LoginResponseJSON,
  CheckResetCodeResponseJSON
} from './JSON/Auth';

export interface AuthAPI {
  getAuthSettings(
    programSlug?: string,
    domain?: string
  ): Promise<AuthSettingsResponseJSON>,

  login(
    email: string, password: string
  ): Promise<Either<Response, LoginResponseJSON>>,

  signup(
    email: string, password: string, name: string, programSlug: string
  ): Promise<Either<Response, LoginResponseJSON>>

  forgotPassword(email: string): OK

  checkResetCode(code: string): Promise<CheckResetCodeResponseJSON>

  resetPassword(code: string, password: string): OK
}

export function makeApi(client: ApiClient): AuthAPI {
  return {
    getAuthSettings,
    signup,
    login,
    forgotPassword,
    resetPassword,
    checkResetCode
  }

  function getAuthSettings(
    slug?: string,
    domain?: string
  ): Promise<AuthSettingsResponseJSON> {
    return client.requestJSON(
      GET,
      {
        path: '/auth_settings',
        version: 3,
        query: { program_slug: slug, domain }
      }
    );
  }

  function login(
    email: string, password: string
  ): Promise<Either<Response, LoginResponseJSON>> {
    return client.eitherRawJSON(POST, '/login', { user: { email, password }});
  }

  function signup(
    email: string, password: string, name: string, programSlug: string
  ): Promise<Either<Response, LoginResponseJSON>> {
    return client.eitherRawJSON(
      POST,
      '/signup',
      { user: { email, password, name, program_slug: programSlug }}
    );
  }

  function forgotPassword(email: string): OK {
    return client.requestJSON(
      POST,
      { path: '/forgot_password', version: 3 },
      { email }
    );
  }

  function resetPassword(code: string, password: string): OK {
    return client.requestJSON(
      POST,
      { path: '/reset_password', version: 3 },
      { code, password }
    );
  }

  function checkResetCode(code: string): Promise<CheckResetCodeResponseJSON> {
    return client.requestJSON(
      GET,
      { path: '/check_reset_password_code', version: 3, query: { code } }
    );
  }
}
