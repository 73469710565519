import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { State, StoreDispatch } from 'Store';
import { NavBarLayout } from 'Nav';
import { SEL as UserSEL } from 'User';
import * as Urls from 'Shared/Urls';
import featureGate from 'App/UI/FeatureGate';
import { ActionCreators } from 'Community/ActionCreator';
import EmailInviteForm from 'Community/UI/EmailInviteForm';
import { Status as ButtonStatus } from 'Shared/UI/LoadSuccessButton';

type Props = State & {
  dispatch: StoreDispatch
}

interface UIState {
  status: ButtonStatus
}

class InvitePage extends React.Component<Props, UIState> {
  constructor(props: Props) {
    super(props);
    this.state = { status: 'default' };
  }

  submitEmailInvite = (email: string) => {
    return this.props.dispatch(ActionCreators.sendCrewInviteByEmail(email));
  }

  programName(): string {
    return UserSEL.programName(this.props.currentUser);
  }

  render() {
    return (
      <NavBarLayout
        title="Community Invite"
        initBreadcrumb={Urls.communityUrl()}>
        <div className="simple-container">
          <h2>Invite</h2>

          <p>
            Connect with others on {this.programName()}
          </p>

          <ul className="invite-methods">
            <li className="invite email">
              <EmailInviteForm onSubmit={this.submitEmailInvite} />
            </li>
          </ul>
        </div>
      </NavBarLayout>
    );
  }
}

export default featureGate(
  defaultConnect(InvitePage),
  ['crew']
);
