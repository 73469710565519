import * as R from 'ramda';
import { Prism, Isomorphism } from '@atomic-object/lenses';

export type IDMap<T> = { [id: string]: T };

/**
 * Add a "Key" type parameter.  This would ideally be used in the index
 * signature of the IDMap, but typescript doesn't support type aliases as object
 * keys in index signatures, so its basically ignored.  Still very useful to
 * make ID map declarations more clear as to what type of ID is expected
 */
export type IDMapWithKey<_Key, Value> = IDMap<Value>;

export function newIDMap<T>(): IDMap<T> {
  return {};
}

export function idMapFromList<T>(
  idFunc: (t: T) => string,
  list: T[]
): IDMap<T> {
  return R.reduce(
    (idMap, item) => idMapInsert(idFunc(item), item, idMap),
    newIDMap(),
    list
  ) as IDMap<T>;
}

export function idMapLookup<T>(
  id: number | string
): (idMap: IDMap<T>) => T | undefined;
export function idMapLookup<T>(
  id: number | string, idMap: IDMap<T>
): T | undefined;
export function idMapLookup<T>(id: number | string, idMap?: IDMap<T>) {
  id = id.toString();
  if (idMap === undefined) {
    return (m: IDMap<T>) => m[id];
  } else {
    return idMap[id];
  }
}

// export function idMapLookupLens(id: string): R.Lens {
//   return R.lensProp(id);
// }

export function idMapLookupPrism<T>(id: string): Prism<IDMap<T>, T> {
  return Prism.of({
    get: idMapLookup(id),
    set: (map: IDMap<T>, a: T) => idMapInsert(id, a, map)
  })
}

export function idMapValuesIsomorphism<T>(
  idFn: (t: T) => string
): Isomorphism<IDMap<T>, T[]> {
  return {
    to: (idMap: IDMap<T>) => idMapValues(idMap),
    from: (objs: T[]) => idMapFromList(idFn, objs)
  };
}

export function idMapValuesDefaultIsomorphism<T extends {id: string}>(): Isomorphism<IDMap<T>, T[]> {
  return idMapValuesIsomorphism(t => t.id);
}

export function idMapValues<T>(idMap: IDMap<T>): T[] {
  return R.values(idMap);
}

export function idMapInsert<T>(
  id: string | number, obj: T, idMap: IDMap<T>
): IDMap<T> {
  return {
      ...idMap,
    [id.toString()]: obj
  };
}

export function idMapInsertAll<T>(
  idFn: (obj: T) => string, objs: T[], idMap: IDMap<T>
): IDMap<T> {
  return objs.reduce(
    (result, obj) => idMapInsert(idFn(obj), obj, result) as IDMap<T>,
    idMap
  );
}

export function idMapConcat<T>(map1: IDMap<T>, map2: IDMap<T>): IDMap<T> {
  return { ...map1, ...map2 };
}

export function idMapDelete<T>(id: string | number, map: IDMap<T>): IDMap<T> {
  return R.dissoc(id.toString(), map);
}
