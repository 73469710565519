import { stringFromBytes } from './Util';

export interface AdvertisementData {
  bytes: Uint8Array,
  shortName?: string,
  fullName?: string,
  manufacturerData?: Uint8Array,
  incompleteServiceUuids16bit?: string[]
}

enum ADTypes {
  SHORT_NAME = 0x08,
  FULL_NAME = 0x09,
  MANUFACTURER_DATA = 0xFF,
  // Incomplete List of 16-bit Service Class UUIDs
  INCOMPLETE_SERVICE_UUIDS_16BIT = 0x02
}
export function parse(bytes: Uint8Array): AdvertisementData {
  let i = 0;

  let result: AdvertisementData = { bytes };

  while (i < bytes.length) {
    let length = bytes[i];
    i += 1
    if (length > 0) {
      let section = bytes.slice(i, i + length);
      result = parseAdvertisementSection(result, section)
      i += length;
    }
  }

  return result;
}

function parseAdvertisementSection(
  result: AdvertisementData, section: Uint8Array
): AdvertisementData {
  const adType = section[0];
  const data = section.slice(1);
  switch(adType) {
    case ADTypes.SHORT_NAME:
      return { ...result, shortName: stringFromBytes(data) };
    case ADTypes.FULL_NAME:
      return { ...result, fullName: stringFromBytes(data) };
    case ADTypes.MANUFACTURER_DATA:
      return { ...result, manufacturerData: data }
    case ADTypes.INCOMPLETE_SERVICE_UUIDS_16BIT:
      return {
        ...result, incompleteServiceUuids16bit: parse16BitServiceUuids(data)
      };
  }
  return result;
}

function parse16BitServiceUuids(data: Uint8Array): string[] {
  let result: string[] = [];
  let i = 0;
  const view = new DataView(data.buffer);
  while (i < data.length) {
    result.push(view.getUint16(i, true).toString(16));
    i += 2;
  }
  return result;
}
