import * as moment from 'moment-timezone';
import { ActionCreatorThunk } from 'Store';
import { TaskId } from './Data';

export interface ActionCreators {
  completeTask: (taskId: TaskId) => ActionCreatorThunk
  completeTaskAtTime: (
    taskId: TaskId, time: moment.Moment
  ) => ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  completeTask,
  completeTaskAtTime
};


function completeTask(taskId: TaskId): ActionCreatorThunk {
  return (_dispatch, _getState, { api }) =>
    api.tasks.completeTask(taskId);
}

function completeTaskAtTime(
  taskId: TaskId, time: moment.Moment
): ActionCreatorThunk {
  return (_dispatch, _getState, { api }) =>
    api.tasks.completeTaskAtTime(taskId, time);
}
