import { ApiClient, OK, GET, POST } from 'Api';
import { HTML } from 'Shared/Data/HTML';

import {
  ReportListItemJSON, ScreeningReportResponseJSON, ScreenResponseJSON,
  ScreenDataJSON
} from './JSON/Screenings';

export interface ScreeningsAPI {
  /**
   * Pull list of current user's available screening reports
   */
  getReportList(): Promise<{ screenings: ReportListItemJSON[] }>

  /**
   * Load screening report
   */
  getReport(recordId: string): Promise<ScreeningReportResponseJSON>

  /**
   * Load user screen definition.  Returns 404 if the current user does not have
   * an open screening
   */
  getScreen(): Promise<ScreenResponseJSON>

  /**
   * Submit screening data for current user's current screening.
   */
  submit(data: ScreenDataJSON): OK

  /**
   * Load dynamic "thank you" page content to show after screening is completed
   */
  getThankYouContent(): Promise<{ html: HTML }>
}

export function makeApi(client: ApiClient): ScreeningsAPI {
  return {
    getReportList,
    getReport,
    getScreen,
    submit,
    getThankYouContent
  };

  function getReportList(): Promise<{ screenings: ReportListItemJSON[] }> {
    return client.requestJSON(GET, '/screenings');
  }

  function getReport(recordId: string): Promise<ScreeningReportResponseJSON> {
    return client.requestJSON(GET, `/screenings/${recordId}`);
  }

  function getScreen(): Promise<ScreenResponseJSON> {
    return client.requestJSON(GET, `/screens`);
  }

  function submit(data: ScreenDataJSON): OK {
    return client.requestJSON(POST, '/screens/submit', {
      screen_data: data
    });
  }

  function getThankYouContent(): Promise<{ html: HTML }> {
    return client.requestJSON(GET, '/screens/thank_you');
  }
}
