import { push } from 'connected-react-router';
import { ActionCreatorThunk } from 'Store';
import { reportListItemFromJSON, reportFromJSON, screenFromJSON } from './JSON';

import * as Urls from 'Shared/Urls';
import {
  SEL, RecordId, ScreenField, FieldValue, screeningIsDone
} from './Data';
import { NSAction, ActionType, withNS } from './Action';
import { ActionCreators as HUD } from 'HUD'
import { ActionCreators as UserAC } from 'User';

export interface ActionCreators {
  loadReportList(): ActionCreatorThunk
  loadReport(recordId: RecordId): ActionCreatorThunk

  /**
   * Redirect to screening form and begin screening
   */
  startScreening(): ActionCreatorThunk
  /**
   * Loads the current screening for the current user.  If none is found,
   * redirects out of screening form
   */
  loadScreen(): ActionCreatorThunk

  /**
   * Validates current step in screening form, proceeding to next if there are
   * no errors.
   */
  gotoNextStep(): ActionCreatorThunk

  /**
   * Go back to previous step in screening form
   */
  gotoPreviousStep(): NSAction,

  setFieldValue(field: ScreenField, value: FieldValue): NSAction
}

export const ActionCreators: ActionCreators = {
  loadReportList,
  loadReport,
  startScreening,
  loadScreen,
  gotoNextStep,
  gotoPreviousStep,
  setFieldValue
};

/*------------------------------------------------------------*/

function loadReportList(): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    return api.screenings.getReportList().then(({ screenings: reportsJSON }) => {
      const reports = reportsJSON.map(reportListItemFromJSON);
      dispatch(withNS({
        type: ActionType.LOAD_REPORT_LIST__SUCCESS,
        reports
      }));
    });
  };
}

function loadReport(recordId: RecordId): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.screenings.getReport(recordId).then(result => {
      dispatch(HUD.close(0));
      const report = reportFromJSON(result.report);

      dispatch(withNS({
        type: ActionType.LOAD_REPORT__SUCCESS,
        report,
        recordId
      }));
    });
  };
}

function startScreening(): ActionCreatorThunk {
  return (dispatch, getState) => {
    const routerState = getState().router;
    const location = routerState && routerState.location;
    const formUrl = Urls.screeningFormUrl();

    if (location === undefined || location.pathname !== formUrl) {
      dispatch(push(formUrl));
    }
    return Promise.resolve();
  };
}

function loadScreen(): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    dispatch(withNS({ type: ActionType.LOAD_SCREEN__BEGIN }));
    return api.screenings.getScreen().then(
      result => {
        dispatch(HUD.close(0))
        const screen = screenFromJSON(result.screen);
        dispatch(withNS({
          type: ActionType.LOAD_SCREEN__SUCCESS,
          screen
        }));
      },
      error => {
        dispatch(HUD.close(0))
        if (error.response.status === 404) {
          dispatch(push(Urls.homeUrl()));
        } else {
          console.error('Unknown error:', error);
        }
      });
  };
}

function gotoNextStep(): ActionCreatorThunk {
  return (dispatch, getState) => {
    dispatch(withNS({ type: ActionType.VALIDATE_CURRENT_STEP }));
    const errors = SEL.errors(getState().screenings);
    if (errors.length === 0) {
      dispatch(withNS({ type: ActionType.GOTO_NEXT_STEP }));
      if (screeningIsDone(getState().screenings)) {
        return dispatch(submitScreening());
      }
    }
    return Promise.resolve();
  };
}

function gotoPreviousStep(): NSAction {
  return withNS({ type: ActionType.GOTO_PREVIOUS_STEP });
}

function setFieldValue(field: ScreenField, value: FieldValue): NSAction {
  return withNS({ type: ActionType.SET_FIELD_VALUE, field, value });
}

function submitScreening(): ActionCreatorThunk {
  return (dispatch, getState, { api }) => {
    const data = SEL.screenData(getState().screenings);
    if (data.value) {
      dispatch(HUD.loading());
      return api.screenings.submit(data.value)
        .then(() => api.screenings.getThankYouContent())
        .then(
          ({ html }) => {
            dispatch(UserAC.loadCurrentUser(true));
            dispatch(HUD.success());
            dispatch(withNS({ type: ActionType.SET_THANK_YOU_CONTENT, html }));
          },
          () => dispatch(HUD.error())
        );
    } else {
      return Promise.resolve();
    }
  };
}
