import * as moment from 'moment-timezone';
import * as React from 'react';
import * as classnames from 'classnames';
import { bem } from 'css-util';
import { Frequency, ActionStatus, ActionType, ActionId } from 'CarePlans/Data';
import { TaskId } from 'Tasks/Data';
import TaskListItem from 'Tasks/UI/TaskListItem';
import SpecForms from 'Specs/UI/ConnectedSpecForms';
import { Status } from 'Shared/UI/StatusCircle';
import * as Dialog from 'Shared/Dialog';

interface Props {
  date: moment.Moment,
  status: ActionStatus
  completeTask: (taskId: TaskId, time: moment.Moment) => Promise<void>
  undoTaskAction: (actionId: ActionId) => Promise<void>
  reload: () => void
}

interface State {
  openedSpecForm: boolean
}
const CSS = bem('task-list');

class ActionStatusListItem extends React.Component<Props, State> {
  state = { openedSpecForm: false }

  onCompleteItem = () => {
    if (this.disabled()) {
      Dialog.alert('It is too early to complete this action.');
      return;
    }
    const action = this.props.status.action;
    switch(action.details.type) {
      case ActionType.TASK:
        this.props.completeTask(action.details.task.id, this.actionTime());
        break;
      case ActionType.MEASUREMENT:
        this.setState({ openedSpecForm: true });
        break;
    }
  }

  actionTime(): moment.Moment {
    const action = this.props.status.action;
    const date = this.props.date;
    let calculatedTime: moment.Moment;
    switch(action.frequency) {
      case Frequency.DAILY_AM:
        calculatedTime = date.clone().hour(6);
        break;
      case Frequency.DAILY_PM:
        calculatedTime = date.clone().hour(18);
        break;
      default:
        calculatedTime = date.clone().hour(12);
        break;
    }
    return moment.min(moment(), calculatedTime);
  }

  disabled(): boolean {
    const action = this.props.status.action;
    const date = this.props.date.clone();
    const minTime =
      action.frequency === Frequency.DAILY_PM ? date.hour(12) : date;

    return moment().isBefore(minTime);
  }

  afterAction = () => {
    this.setState({ openedSpecForm: false });
    this.props.reload();
  }

  undoFn = (): (() => void) | undefined => {
    const actionDetails = this.props.status.action.details;
    if (actionDetails.type === ActionType.TASK) {
      return () => this.props.undoTaskAction(this.props.status.action.id);
    }
  }

  render() {
    const status = completionStatus(this.props);
    const classes =
      classnames({ [CSS('care-action')(['disabled'])]: this.disabled() });
    return (
      <div className={classes}>
        <TaskListItem
          status={status}
          complete={this.onCompleteItem}
          undo={this.undoFn()}
        >
          <span className={CSS('task-name')()}>
            {this.props.status.text}
          </span>
        </TaskListItem>
        {this.state.openedSpecForm && this.renderSpecForm()}
      </div>
    );
  }

  renderSpecForm() {
    const action = this.props.status.action;
    const actionDetails = action.details;
    if (actionDetails.type !== ActionType.MEASUREMENT) { return; }
    return (
      <div>
        <SpecForms
          show={[actionDetails.specType]}
          afterSpecUpdate={this.afterAction}
          postTime={this.actionTime()}
        />
      </div>
    );
  }
}

export default ActionStatusListItem;


function completionStatus(props: Props): Status {
  if (props.status.complete) {
    return Status.COMPLETE;
  } else {
    return Status.AVAILABLE;
  }
}
