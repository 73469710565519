import * as React from 'react';
import { Provider } from 'react-redux';
import {
  Route as PublicRoute, Switch, Redirect, RouteComponentProps
} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { createHashHistory as createHistory } from 'history';

import { makeStore, AppStore } from 'Store';
import * as Urls from 'Shared/Urls';
import Footer from 'App/UI/Footer';
import * as Auth from 'Auth';
const Route = Auth.PrivateRoute;
import { store as tokenStore } from 'Auth/TokenStore';
import { ActionCreators } from 'App';

import ScrollToTop from 'Shared/UI/ScrollToTopOnLocationChange';
import ThemeStyles from 'User/UI/ConnectedThemeStyles';

/**============================================================
 * Pages
 */
import { Pages as HomePages } from 'Home';
import { Pages as DashboardPages } from 'Dashboard';
import { Pages as AuthPages } from 'Auth';
import { Pages as ChallengePages } from 'Challenges';
import { Pages as SettingsPages } from 'Settings';
import { Pages as EdgePages } from 'Edge';
import { Pages as PostPages } from 'Post';
import { Pages as CMSPages } from 'CMS';
import { Pages as GoalPages } from 'Goals';
import { Pages as InboxPages } from 'Inbox';
import { Pages as ApptPages } from 'Appt';
import { Pages as ScreeningPages } from 'Screenings';
import { Pages as TrendsPages } from 'Trends';
import { Pages as TimeSeriesPages } from 'TimeSeries';
import { Pages as CommunityPages } from 'Community';
import { Pages as ContactPages } from 'Contact';
import { Pages as RewardsPages } from 'Rewards';
import { Pages as OnboardPages } from 'Onboard';
import { Pages as MaintenancePages } from 'Maintenance';
import { Pages as NetworkPages } from 'Network';
import { Pages as VersionGuardPages } from 'VersionGuard';
import { Pages as CarePlansPages } from 'CarePlans';
import { Pages as BLEPages } from 'BLE';

/*============================================================*/

export function makeRoot(): React.ReactElement<{}> {
  const history = createHistory();
  const store = makeStore(history, tokenStore);

  store.dispatch(ActionCreators.initializeApp());

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ScrollToTop>
          <div>
            <ThemeStyles />
            <div className="main-content">
              {routes(store)}
            </div>
            <Footer />
          </div>
        </ScrollToTop>
      </ConnectedRouter>
    </Provider>
  );
}

function routes(_store: AppStore): React.ReactNode {
  return (
    <Switch>
      <PublicRoute key="logout"
        exact path="/logout" component={AuthPages.Logout} />
      <PublicRoute key="login"
        exact path={Urls.loginUrl(':programSlug?')}
        component={AuthPages.Login} />
      <PublicRoute key="signup"
        exact path={Urls.signupUrl(':programSlug')}
        component={AuthPages.Signup} />
      <PublicRoute key="forgot-password"
        exact path={Urls.forgotPasswordUrl(':programSlug?')}
        component={AuthPages.ForgotPassword} />
      <PublicRoute key="reset-password"
        exact path={Urls.resetPasswordUrl(':code', ':programSlug?')}
        component={AuthPages.ResetPassword} />

      <PublicRoute exact key="forced-upgrade"
        path={Urls.forcedUpgradeUrl()}
        component={VersionGuardPages.ForcedUpgrade} />

      <Route key="home"
        exact path={Urls.rootUrl()} component={HomePages.Home} />
      <Route key="dashboard"
        exact path={Urls.dashboardUrl()}
        component={DashboardPages.MyDashboard}/>

      /** Challenges **/

      <Route exact key="my-challenges"
        path={Urls.challengesUrl()}
        component={ChallengePages.MyChallenges}/>
      <Route exact key="challenge-template"
        path={Urls.challengeTemplateUrl(':templateId')}
        component={ChallengePages.Template} />
      <Route exact key="challenge-details"
        path={Urls.challengeUrl(':challengeId')}
        render={
          props => <ChallengePages.ChallengeDetails {...props} mode="players" />
        }
      />
      <Route exact key="challenge-details-team-mode"
        path={Urls.challengeUrl(':challengeId', 'teams')}
        render={
          props => <ChallengePages.ChallengeDetails {...props} mode="teams" />
        }
      />
      <Route exact key="challenge-leaderboard"
        path={Urls.challengeLeaderboardUrl(':challengeId', ':page?')}
        render={
          (props: RouteComponentProps<{ challengeId: string, page: string }>) =>
            <ChallengePages.FullLeaderboard {...props} mode="players" />
        }
      />
      <Route exact key="challenge-rules"
        path={Urls.challengesRulesUrl(':type', ':id')}
        component={ChallengePages.Rules} />
      <Route exact key="available-challenges"
        path={Urls.availableChallengesUrl()}
        component={ChallengePages.AvailableChallenges}/>
      <Route exact key="completed-challenges"
        path={Urls.completedChallengesUrl(':page?')}
        component={ChallengePages.CompletedChallenges}/>

      <Route exact key="challenge-prejoin-setup"
        path={Urls.challengeSetupUrl(':challengeId')}
        component={ChallengePages.PreJoinSetupChallenge} />
      <Route exact key="challenge-template-prejoin-setup"
        path={Urls.challengeTemplateSetupUrl(':templateId')}
        component={ChallengePages.PreJoinSetupTemplate} />
      <Route exact key="challenge-invitation-prejoin-setup"
        path={Urls.challengeInvitationSetupUrl(':invitationId')}
        component={ChallengePages.PreJoinSetupInvitation} />

      <Route exact key="challenge-invite"
        path={Urls.challengeInviteUrl(':challengeId')}
        component={ChallengePages.Invite} />

      <Route exact key="challenge-task-daily-details"
        path={Urls.taskChallengeDailyDetailsUrl(':challengeId', ':date?')}
        component={ChallengePages.TaskChallengeDailyDetails} />

      <Route exact key="challenge-task-summary"
        path={Urls.taskChallengeSummaryUrl(':challengeId')}
        component={ChallengePages.TaskChallengeSummary} />

      <Route exact key="challenge-join-team"
        path={Urls.challengeJoinTeamUrl(':challengeId')}
        component={ChallengePages.JoinTeam} />
      <Route exact key="challenge-new-team"
        path={Urls.challengeNewTeamUrl(':challengeId')}
        component={ChallengePages.ManageTeam} />
      <Route exact key="challenge-edit-team"
        path={Urls.challengeEditTeamUrl(':challengeId', ':teamId')}
        component={ChallengePages.ManageTeam} />
      <Route exact key="challenge-show-team"
        path={Urls.challengeTeamUrl(':challengeId', ':teamId')}
        component={ChallengePages.ShowTeam} />
      <Route exact key="challenge-teams-leaderboard"
        path={Urls.challengeTeamLeaderboardUrl(':challengeId', ':page?')}
        render={
          (props: RouteComponentProps<{ challengeId: string, page: string }>) =>
            <ChallengePages.FullLeaderboard {...props} mode="teams" />
        }
      />

      /** Goals **/

      <Route exact key="goals-dashboard"
        path={Urls.goalsUrl(':userId?')}
        component={GoalPages.Dashboard} />

      <Route exact key="goal-details"
        path={Urls.goalUrl(':goalId')}
        component={GoalPages.GoalDetails} />

      <Route exact key="goal-settings"
        path={Urls.goalsSettingsUrl()}
        component={SettingsPages.GoalsPage} />

      /** Settings **/

      <Route exact key="manage-profile"
        path={Urls.profileSettingsUrl()}
        component={SettingsPages.ProfilePage} />

      <Route exact key="notification-settings"
        path={Urls.notificationSettingsUrl()}
        component={SettingsPages.NotificationsPage} />

      /** Edge **/

      <Route
        exact key="edge-connections"
        path={Urls.edgeUrl()}
        component={EdgePages.ConnectedDevices} />

      <Route
        exact key="edge-connect-to-device"
        path={Urls.edgeConnectToDeviceUrl(':providerKey', ':deviceTypeId?')}
        component={EdgePages.DeviceScan} />

      <Route
        exact key="edge-connect-callback"
        path={Urls.edgeConnectCallbackUrl()}
        render={EdgePages.ConnectCallbackRenderFn} />

      <Route
        exact key="edge-device-settings"
        path={Urls.edgeDeviceSettingsUrl(':deviceTypeId', ':deviceId')}
        component={EdgePages.BLEDeviceSettings} />

      <Route
        exact
        key="firmware-update-progress"
        path={Urls.firmwareUpdateProgressUrl(':deviceTypeId', ':deviceId')}
        component={BLEPages.FirmwareUpdateProgress}
        />
      <Redirect
        exact key="edge-settings-redirect"
        path="/settings/edge"
        to={Urls.edgeUrl()} />

      /** Post **/

      <Route exact key="post"
        path={Urls.postUrl()}
        component={PostPages.Post} />

      /** Feed **/

      <Route exact key="my-feed"
        path={Urls.myFeedUrl()}
        component={PostPages.MyActivityFeed} />
      <Route exact key="crew-feed"
        path={Urls.crewFeedUrl()}
        component={PostPages.CrewActivityFeed} />
      <Route exact key="user-feed"
        path={Urls.userFeedUrl(':userId')}
        component={PostPages.UserActivityFeed} />

      /** Inbox **/

      <Route exact key="my-inbox"
        path={Urls.inboxUrl()}
        component={InboxPages.MyInbox} />
      <Route exact key="inbox-thread"
        path={Urls.inboxNewThreadUrl(':type', ':userId')}
        component={InboxPages.NewThread} />
      <Route exact key="inbox-thread"
        path={Urls.inboxThreadUrl(':threadId')}
        component={InboxPages.ShowThread} />

      /** Community **/

      <Route exact key="community"
        path={Urls.communityUrl()}
        component={CommunityPages.Dashboard}/>

      <Route exact key="crew-member-dashboard"
        path={Urls.crewMemberDashboardUrl(':userId')}
        component={DashboardPages.CrewMemberDashboard}/>

      <Route exact key="community-invite"
        path={Urls.communityInviteUrl()}
        component={CommunityPages.Invite}/>

      /** Appts **/
      <Route exact key="appts"
        path={Urls.apptsUrl()}
        component={ApptPages.ApptRoot}/>

      <Route exact key="new-appt"
        path={Urls.newApptUrl(':apptTypeId')}
        component={ApptPages.NewAppt}/>

      <Route exact key="appt-details"
        path={Urls.apptUrl(':apptId')}
        component={ApptPages.ApptDetails}/>

      /** Screenings **/

      // This route has to go first or it will be matched as a screening report
      <Route exact key="screening-form"
        path={Urls.screeningFormUrl()}
        component={ScreeningPages.ScreeningForm} />

      <Route exact key="screening-reports"
        path={Urls.screeningReportsUrl()}
        component={ScreeningPages.ScreeningReports}/>

      <Route exact key="screening-report"
        path={Urls.screeningReportUrl(':recordId')}
        component={ScreeningPages.ScreeningReport}/>

      /** Trends **/

      <Route exact key="my-trends"
        path={Urls.myTrendsUrl()}
        component={TrendsPages.MyTrendsRedirect}/>

      <Route exact key="trends"
        path={Urls.trendsUrl(':userId', ':metricKey?', ':window?', ':date?')}
        component={TrendsPages.TrendsGraph}/>

      <Route exact key="time-series"
        path={Urls.timeSeriesUrl()}
        component={TimeSeriesPages.GraphPage} />

      /** Rewards **/

      <Route exact key="rewards-dashboard"
        path={Urls.rewardsUrl()}
        component={RewardsPages.Dashboard} />

      <Route exact key="rewards-available"
        path={Urls.availableRewardsUrl()}
        component={RewardsPages.AvailableRewards} />

      <Route exact key="reward-page"
        path={Urls.rewardUrl(':rewardId')}
        component={RewardsPages.RewardPage} />

      <Route exact key="about-rewards"
        path={Urls.aboutRewardsUrl()}
        component={CMSPages.CMSPage('/about-rewards')} />

      /** Care Plans **/

      <Route exact key="care-plan-todays-care-tasks"
        path={Urls.carePlanCareTasksUrl()}
        component={CarePlansPages.DailyCareTasks}
      />

      <Route exact key="care-plan-care-tasks"
        path={Urls.carePlanCareTasksUrl(':date')}
        component={CarePlansPages.DailyCareTasks}
      />

      /** Onboard **/

      <Route exact key="onboard-step"
        path={Urls.onboardingCompleteUrl()}
        component={OnboardPages.OnboardComplete}
      />

      <Route exact key="onboard-step"
        path={Urls.onboardingUrl(':step')}
        component={OnboardPages.OnboardStep}
      />

      <Redirect exact key="onboard-welcome"
        path="/welcome"
        to={Urls.onboardingUrl()} />

      /** Random **/

      <Route exact key="health-score-whats-this"
        path={Urls.healthScoreWhatsThisUrl()}
        component={CMSPages.CMSPage('/bioscore-scale')} />

      <Route exact key="user-guide"
        path={Urls.userGuideUrl(':section?')}
        component={CMSPages.CMSPage('/user-guide')} />

      <Route exact key="feedback"
        path={Urls.contactUrl()}
        component={ContactPages.ContactPage} />

      <PublicRoute exact key="maintenance-mode"
        path={Urls.maintenanceModeUrl()}
        component={MaintenancePages.MaintenanceMode} />

      <PublicRoute exact key="offline-mode"
        path={Urls.offlineModeUrl()}
        component={NetworkPages.OfflineMode} />

      <Redirect to={Urls.rootUrl()} />
    </Switch>
  );
}
