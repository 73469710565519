import * as R from 'ramda';
import * as React from 'react';
import * as classname from 'classnames';

import noop from 'Shared/Noop';

import {
  FatlineAlert, FatlineAlertSettings, FatlineTransmitter, fatlineAlertLabel
} from '../Data';

interface Props {
  alert: FatlineAlert,
  settings: FatlineAlertSettings,
  toggle(t: FatlineTransmitter): void
}

type Element = React.ReactElement<Props>;
export default function renderFatlineAlert(
  props: Props
): Element {
  const { alert, settings } = props;
  const handler = makeHandler(props.toggle);
  return (
    <li className="fatline-settings__item">
      <div className="fatline-settings__item">
        <label className="fatline-settings__label">
          {fatlineAlertLabel(alert)}
        </label>
        <div className="fatline-settings__checkboxes">
          <div className={classname(
              "fatline-settings__checkbox",
              { 'selected theme__primary-bg': settings.email }
            )}
            onClick={handler('email')}>
            <input
              type="checkbox"
              checked={settings.email}
              onChange={noop}/>
          </div>
          <div className={classname(
              "fatline-settings__checkbox",
              { 'selected theme__primary-bg': settings.sms }
            )}
            onClick={handler('sms')}>
            <input
              type="checkbox"
              checked={settings.sms}
              onChange={noop}/>
          </div>
        </div>
      </div>
    </li>
  );
}


const makeHandler = R.curry(_makeHandler);
function _makeHandler(
  f: (t: FatlineTransmitter) => void,
  t: FatlineTransmitter
): (e: React.FormEvent<any>) => void {
  return () => f(t);
};
