import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { Loadable, fromLoadable, loadableMap } from 'misc/Data/Loadable';
import { NavBarLayout } from 'Nav';
import { State, DispatchProps } from 'Store';
import { UserAction, isComplete } from 'Rewards/Data';
import { ActionCreators } from 'Rewards/ActionCreator';

import * as Urls from 'Shared/Urls';
import Spinner from 'Shared/UI/Spinner';

import RewardListItem from 'Rewards/UI/RewardListItem';

type Props = State & DispatchProps;

class AvailableRewards extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(ActionCreators.loadUserActions());
  }

  loadableUserActions(): Loadable<UserAction[]> {
    const findIncomplete = (userActions: UserAction[]) => {
      return userActions.filter(a => !isComplete(a.status));
    }
    return loadableMap(
      findIncomplete,
      this.props.rewards.userActions
    );
  }

  render() {
    return (
      <NavBarLayout title="Rewards" initBreadcrumb={Urls.rewardsUrl()}>
        <div className="simple-container">
          {fromLoadable<UserAction[], React.ReactNode>(
             this.renderRewards,
             this.renderSpinner,
             this.loadableUserActions()
           )}
        </div>
      </NavBarLayout>
    );
  }

  renderRewards = (actions: UserAction[]) => {
    if (actions.length === 0) {
      return (
        <p className="text-center">
          Looks like there aren't any more rewards to earn right now.
        </p>
      );
    } else {
      return actions.map(this.renderReward);
    }
  }

  renderReward = (action: UserAction, i: number) => {
    return (
      <RewardListItem
        key={i}
        dispatch={this.props.dispatch}
        reward={action.reward}
        status={action.status} />
    );
  }

  renderSpinner = () => {
    return <Spinner wrap="center" />;
  }
}

export default defaultConnect(AvailableRewards);
