export { Service, makeService } from './Service';
export * from './Data';
export * from './Action';
export { reducer } from './Store';
export { ActionCreators } from './ActionCreator';

import FirmwareUpdateProgress from './Page/FirmwareUpdateProgress';

export const Pages = {
  FirmwareUpdateProgress
}
