import * as R from 'ramda';
import { idMapFromList, idMapInsertAll } from 'Shared/Data/IDMap';
import { loadable } from 'misc/Data/Loadable';
import { Paging } from 'Shared/Paging';
import {
  State, Activity, Biopost, BiopostFeed, insertBiopost, insertBioposts,
  makeFeed, insertFavorite, removeFavorite, LENSES, initialState
} from './Data';
import { feedSetIds } from 'Shared/Data/Feed';
import { Action, ActionType, LoadActivities_Success } from './Action';


export function reducer(state: State, action: Action): State {
  switch(action.type) {
  case ActionType.LOAD_ACTIVITIES__SUCCESS:
    state = {
        ...state,
      activities: idMapFromList((a: Activity) => a.id, action.activities),
      categories: action.categories
    };
    state = addFavorites(action, state);
    state = addGenius(action, state);
    state = addSpecsCategory(action, state);
    return state;
  case ActionType.RESET_ACTIVITIES:
    return initialState();

  case ActionType.LOAD_GENIUS_RECOMMENDATIONS__SUCCESS:
    return { ...state, geniusRecommendations: action.recommendations };
  case ActionType.POST_ACTIVITY__BEGIN:
    return state;
  case ActionType.POST_ACTIVITY__SUCCESS:
    return setLastBiopost(action.biopost, state);
  case ActionType.POST_SPEC_UPDATE__SUCCESS:
    return setLastBiopost(action.biopost, state);
  case ActionType.LOAD_LAST_BIOPOST__SUCCESS:
    return setLastBiopost(action.biopost, state);

  case ActionType.ADD_FAVORITE__SUCCESS:
    return insertFavorite(action.activityId, state);
  case ActionType.REMOVE_FAVORITE__SUCCESS:
    return removeFavorite(action.activityId, state);

  case ActionType.LOAD_FEED__BEGIN:
    return setFeedLoading(true, state);
  case ActionType.LOAD_MY_FEED__SUCCESS:
  case ActionType.LOAD_CREW_FEED__SUCCESS:
  case ActionType.LOAD_USER_FEED__SUCCESS:
    const s1 = setFeedPosts(action.bioposts, state);
    const s2 = setFeedPaging(action.paging, s1);
    return setFeedLoading(false, s2);

  case ActionType.LOAD_MY_DYNAMIC_POSTS__SUCCESS:
    return { ...state, myDynamicPosts: action.bioposts };

  case ActionType.LOAD_COMMENTS__SUCCESS:
    return LENSES.comments(action.biopostId).set(state, action.comments);

  case ActionType.POST_COMMENT__SUCCESS:
    const addComment = LENSES.comments(action.biopostId).update(comments => {
      return R.append(action.comment, comments || []);
    })
    const updateCount = LENSES.biopost(action.biopostId).update(post => {
      if (post) {
        return { ...post, commentCount: post.commentCount + 1 };
      }
    });
    return R.compose(addComment, updateCount)(state);

  case ActionType.GIVE_PROPS__BEGIN:
    return LENSES.props(action.biopostId).update(state, props => {
      return R.append(action.prop, props || []);
    });
  default:
    return state;
  }
}

function setLastBiopost(post: Biopost | undefined, state: State): State {
  if (post) {
    return {
      ...insertBiopost(post, state),
      lastBiopostId: loadable(post.id)
    };
  } else {
    return { ...state, lastBiopostId: loadable(undefined) };
  }
}

function setFeedPosts(bioposts: Biopost[], state: State): State {
  const withPosts = insertBioposts(bioposts, state);
  return overFeed(
    f => feedSetIds(bioposts.map(p => p.id), f),
    withPosts
  );
}

function setFeedPaging(paging: Paging, state: State): State {
  return overFeed(f => ({ ...f, paging }), state);
}

function setFeedLoading(loading: boolean, state: State): State {
  return overFeed(f => ({ ...f, loading }), state);
}


function overFeed(
  fn: (feed: BiopostFeed) => BiopostFeed, state: State
): State {
  return { ...state, feed: fn(state.feed || makeFeed([])) };
}

function addGenius(
  action: LoadActivities_Success, state: State
): State {
  if (action.genius) {
    return {
        ...state,
      genius: action.genius,
      geniusRecommendations: action.geniusRecommendations
    };
  } else {
    return state;
  }
}

function addFavorites(
  action: LoadActivities_Success, state: State
): State {
  if (action.favorites) {
    const activities =
      idMapInsertAll(o => o.id, action.favoritesActivities, state.activities);
    const favoriteActivityIds = action.favoritesActivities.map(o => o.id);
    return {
        ...state,
      activities,
      favorites: {
          ...action.favorites,
        topActivityIds: favoriteActivityIds
      }
    };
  } else {
    return state;
  }
}

function addSpecsCategory(
  action: LoadActivities_Success, state: State
): State {
  if (action.specsCategoryName) {
    return {
        ...state,
      specsCategory: {
        name: action.specsCategoryName, icon: 'ruler'
      }
    };
  } else {
    return state;
  }
}
