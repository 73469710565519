import { loadable } from 'misc/Data/Loadable';
import * as M from 'misc/Data/Maybe';
import { State } from './Data';
import { Action, ActionType } from './Action';

export function reducer(state: State, action: Action): State {
  switch(action.type) {
  case ActionType.LOAD_AVAILABLE_METRICS__SUCCESS:
    return {
      ...state,
      availableMetrics: loadable(action.metrics),
      initialMetricKey: loadable(M.fromNilable(action.initialMetricKey))
    };
  case ActionType.LOAD_TREND_DATA__BEGIN:
    return { ...state, currentRequest: M.just(action.request) };
  case ActionType.LOAD_TREND_DATA__SUCCESS:
    return { ...state, currentData: M.just(action.trendData) };
  case ActionType.CLEAR_TREND_DATA:
    return { ...state, currentRequest: M.nothing(), currentData: M.nothing() };
  }
}
