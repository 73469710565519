import * as React from 'react';

const NoBluetooth = () => {
  return (
    <>
      <p>Hmmm &mdash; we need to use bluetooth to connect to this device.</p>
      <p>Troubleshooting tips:</p>
      <ol>
        <li>
          Make sure you are attempting to connect from a bluetooth enabled
          smartphone or tablet.
        </li>
        <li>
          Make sure bluetooth is "on" in your device "Settings".
        </li>
        <li>
          Make sure you are using the "Apple or Android App" downloaded from
          your App Store &mdash; you can't connect to this device from a web
          browser.
        </li>
        <li>
          Make sure you have "allowed" the App to use Bluetooth.
        </li>
        <li>
          Still stuck? Tap "Menu &rarr; User Guide" for details on how to
          contact Customer Support.
        </li>
      </ol>
    </>
  );
};

export default NoBluetooth;
