import './ProgressBar.scss';
import * as React from 'react';
import { bem } from 'css-util';

interface Props {
  // Percent as number between 0-1
  percent: number
  showText?: boolean,
  modifier?: string
}

const css = bem('progress-bar');

const ProgressBar = (props: Props) => {
  const formattedPercent = (props.percent * 100).toFixed();
  return (
    <div className={css()([props.modifier])}>
      {props.showText && (
         <div className={css('text')([props.modifier])}>
           {formattedPercent}% complete
         </div>
      )}
      <div className={css('bar')([props.modifier])}>
        <div className={css('meter')([props.modifier])}
          style={{width: `${formattedPercent}%`}}>
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
