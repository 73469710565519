import { makeLogger, globalRemoteLogger } from 'Shared/Log';
import { ready } from 'Cordova';
import BackgroundFetch, {
  BackgroundFetchStatus
} from 'cordova-plugin-background-fetch';

export async function isAvailable(): Promise<boolean> {
  await ready();
  return (window as any).BackgroundFetch !== undefined;
}

export async function plugin(): Promise<BackgroundFetch> {
  const available = await isAvailable();
  if (available) {
    return BackgroundFetch;
  } else {
    throw new Error('BackgroundFetch not available');
  }
}

export async function configure(
  onEvent: () => Promise<void>,
  onTimeout: () => Promise<void>
): Promise<BackgroundFetchStatus> {
  const available = await isAvailable();
  const logger = globalRemoteLogger().withTag('BackgroundFetch');

  if (available) {
    return BackgroundFetch.configure(
      {
        minimumFetchInterval: 60,
        stopOnTerminate: false,
        startOnBoot: true,
        requiredNetworkType: BackgroundFetch.NETWORK_TYPE_ANY
      },
      async taskId => {
        logger.log(`Background Task invoked: ${taskId}`);
        await onEvent().catch(
          e => {
            logger.log('An error occurred during background fetch:', e);
            logError('An error occurred during background fetch:', e);
          }
        );
        logger.log(`Background task complete: ${taskId}`)
        BackgroundFetch.finish(taskId);
      },
      async taskId => {
        logger.log('Task timed out:', taskId);
        onTimeout();
        BackgroundFetch.finish(taskId);
      }
    );
  } else {
    throw new Error('BackgroundFetch not available.');
  }
}

export async function stop(): Promise<void> {
  const available = await isAvailable();
  if (available) {
    BackgroundFetch.stop(
      () => log('Background fetch stopped'),
      error => logError('Error while stopping BackgroundFetch:', error)
    );
  }
}

const log = makeLogger('BackgroundFetch', 'BACKGROUND_FETCH_LOG');
const logError =
  makeLogger('BackgroundFetch', 'BACKGROUND_FETCH_LOG' ,'error');
