import * as R from 'ramda';
import { ActionCreatorThunk } from 'Store';
import { AppAPI } from 'App/Api';
import { push } from 'connected-react-router';
import * as Urls from 'Shared/Urls';
import * as Timeout from 'Shared/Timeout';
import * as Dialog from 'Shared/Dialog';
import { makeLogger, globalRemoteLogger } from 'Shared/Log';
import { SECONDS } from 'Shared/Data/Duration';
import * as Cordova from 'Cordova';
import * as BG from 'Cordova/BackgroundFetch';

import * as BLE from 'BLE';
import {
  State, Provider, DeviceType, UserDevice,
  ProviderIntegrationType, isConnectedToDevices, millisSinceLastSync,
  isSyncing
} from './Data';
import * as Edge from 'Edge/Data';

import { makeCollector } from 'Edge/Collector';
import { makeCollector as makeHKCollector } from 'Edge/Collector/HealthKit';

import { ActionType, withNS } from './Action';
import { providerFromJSON, userDeviceFromJSON, deviceDataToJSON } from './JSON';
import { ActionCreators as HUD } from 'HUD';
import { ActionCreators as NavAC } from 'Nav/ActionCreator';
import * as OAuth from 'Auth/OAuth';
import { HealthKit } from 'iOS';

import { getPlatform } from 'Shared/Device';

/**
 * Amount of time to wait before assuming a sync has failed.
 */
const SYNC_TIMEOUT = 45 * SECONDS;

/**
 * When resuming operation, if we havn't synced in this much time or more,
 * trigger a sync
 */
const SYNC_THROTTLE_ON_RESUME = 0;

export interface ActionCreators {
  init(): ActionCreatorThunk,

  setupHealthKitCollection(): ActionCreatorThunk,

  stopHealthKitCollection(): ActionCreatorThunk,

  /**
   * Stop all data collection, background syncs, disconnect from all devices
   */
  stopAndDisconnect(): ActionCreatorThunk,

  loadConfiguration(
    onSuccess?: (s: State) => void,
    onError?: () => void
  ): ActionCreatorThunk,

  loadConfigurationOnce(): ActionCreatorThunk,

  connect(p: Provider): ActionCreatorThunk,

  disconnect(p: Provider): ActionCreatorThunk,

  removeDevice(p: Provider, device: UserDevice): ActionCreatorThunk,

  connectToDevice(
    device: BLE.Device, deviceType: DeviceType
  ): ActionCreatorThunk,

  syncDevice(device: Edge.UserDevice): ActionCreatorThunk

  receiveDeviceData(
    providerId: Edge.ProviderId | Edge.ProviderKey,
    data: Edge.DeviceData,
    userDevice?: Edge.UserDevice
  ): ActionCreatorThunk,
}

export const ActionCreators: ActionCreators = {
  init,
  setupHealthKitCollection,
  stopHealthKitCollection,
  stopAndDisconnect,
  loadConfiguration,
  loadConfigurationOnce,
  connect,
  disconnect,
  connectToDevice,
  removeDevice,
  syncDevice,
  receiveDeviceData
}

/* implementations */

function init(): ActionCreatorThunk {
  return async (dispatch) => {
    await dispatch(loadConfiguration());
    dispatch(setupHealthKitCollection());
    dispatch(setupDeviceCollection());
  };
}

function setupHealthKitCollection(): ActionCreatorThunk {
  return async (dispatch, getState, { healthKitFetcher }) => {
    await Cordova.ready();
    if (getPlatform() === 'iOS') {
      await dispatch(loadConfigurationOnce());
      const provider = Edge.getProvider(getState().edge, 'health_kit');
      if (provider) {
        const collector = makeHKCollector(dispatch, healthKitFetcher)

        if (healthKitConnected(getState().edge)) {
          collector.start();
        } else {
          collector.stop();
        }
      }
    }
  }
}

function stopHealthKitCollection(): ActionCreatorThunk {
  return (dispatch, _getState, { healthKitFetcher }) => {
    makeHKCollector(dispatch, healthKitFetcher).stop();
    return Promise.resolve();
  };
}

function setupDeviceCollection(syncNow = true): ActionCreatorThunk {
  return async (dispatch, getState) => {
    if (isConnectedToDevices(getState().edge)) {
      await dispatch(ensureBackgroundSyncingEnabled());
      await dispatch(ensureSyncOnResumeEnabled());
      if (syncNow) await dispatch(syncAllPassiveDevices());
    } else {
      await dispatch(stopBackgroundSyncing());
      await dispatch(stopSyncOnResume());
    }
  };
}

function stopAndDisconnect(): ActionCreatorThunk {
  return async dispatch => {
    await Promise.all(
      [
        dispatch(stopHealthKitCollection()),
        dispatch(stopBackgroundSyncing()),
        dispatch(closeAllBluetoothConnections())
      ]
    );
  }
}

function loadConfiguration(
  onSuccess?: (s: State) => void,
  onError?: () => void
): ActionCreatorThunk {
  return (dispatch, getState, { api }) => {
    dispatch(withNS({ type: ActionType.LOAD_CONFIGURATION__BEGIN }));
    return api.edge.getConfiguration().then(
      response => {
        const connectedProviderKeys = response.connected_providers;
        const availableProviders =
          response.available_providers.map(providerFromJSON);
        const userDevices = response.user_devices.map(userDeviceFromJSON);

        dispatch(withNS({
          type: ActionType.LOAD_CONFIGURATION__SUCCESS,
          connectedProviderKeys,
          availableProviders,
          userDevices
        }));

        if (onSuccess) { onSuccess(getState().edge); }
      },
      onError
    )
  };
}

// Loads configuration only if they have not been loaded
function loadConfigurationOnce(): ActionCreatorThunk {
  return async (dispatch, getState) => {
    if (getState().edge.connections !== undefined) {
      return Promise.resolve();
    }
    return dispatch(loadConfiguration());
  };
}

function connect(provider: Provider): ActionCreatorThunk {
  return (dispatch, _getState, { api }) => {
    dispatch(withNS({
      type: ActionType.CONNECT__BEGIN, provider
    }));

    let promise;
    switch(provider.integrationType) {
      case ProviderIntegrationType.HealthKit:
        promise = connectToHealthKit(api, provider).then(() => {
          dispatch(setupHealthKitCollection());
          return {};
        });
        break;
      case ProviderIntegrationType.Bluetooth:
        dispatch(
          NavAC.pushWithBreadcrumb(Urls.edgeConnectToDeviceUrl(provider.key))
        );
        break;
      default:
        promise = connectViaOauth(api, provider);
        break;
    }

    if (promise) {
      return promise.then(
        () => dispatch(loadConfiguration()),
        () => dispatch(loadConfiguration())
      );
    } else {
      return Promise.resolve();
    }
  };
}

function disconnect(provider: Provider): ActionCreatorThunk {
  return async (dispatch, _, { api }) => {
    dispatch(withNS({
      type: ActionType.DISCONNECT__BEGIN, provider
    }));

    await api.edge.disconnect(provider.key);

    dispatch(withNS({
      type: ActionType.DISCONNECT__SUCCESS, provider
    }));

    if (provider.key === 'health_kit') {
      dispatch(stopHealthKitCollection());
    }
    dispatch(disconnectFromDevices(provider));
  };
}

function removeDevice(
  provider: Provider, userDevice: UserDevice
): ActionCreatorThunk {
  return async (dispatch, _, { api }) => {
    const deviceType =
      R.find(dt => dt.id === userDevice.deviceTypeId, provider.deviceTypes);
    if (deviceType === undefined) { return; }

    dispatch(withNS({
      type: ActionType.REMOVE_DEVICE__BEGIN, provider, deviceType
    }));

    await api.edge.removeDevice(userDevice.deviceTypeId);
    await dispatch(loadConfiguration());
    dispatch(withNS({
      type: ActionType.REMOVE_DEVICE__COMPLETE, provider, deviceType
    }));
    await dispatch(closeDeviceConnection(userDevice));
    await dispatch(setupDeviceCollection(false));
  }
}

function disconnectFromDevices(provider: Provider): ActionCreatorThunk {
  return async (dispatch, getState, { bluetooth }) => {
    await dispatch(loadConfigurationOnce());
    const edgeState = getState().edge;
    const allUserDevices = edgeState.userDevices;
    if (allUserDevices) {
      const relevantUserDevices = Edge.filterUserDevicesByProvider(
        allUserDevices, provider
      )
      if (relevantUserDevices.length > 0) {
        await dispatch(BLE.ActionCreators.withBluetooth());

        relevantUserDevices.forEach(userDevice => {
          const deviceType =
            Edge.getDeviceTypeForUserDevice(edgeState, userDevice)
          if (deviceType === undefined) { return; }
          makeBluetoothProtocol(bluetooth, deviceType, userDevice)
            .then(p => p.close());
        });
        dispatch({
          type: ActionType.DISCONNECT_FROM_DEVICES,
          devices: relevantUserDevices
        });

        // If there are no more connected devices, turn off background sync
        const remainingDevices = getState().edge.userDevices || [];
        if (remainingDevices.length === 0) {
          dispatch(stopBackgroundSyncing());
        }
      }
    }
  }
}

function closeDeviceConnection(userDevice: UserDevice): ActionCreatorThunk {
  return async (dispatch, getState, { bluetooth }) => {
    await dispatch(BLE.ActionCreators.withBluetooth());
    const deviceType =
      Edge.getDeviceTypeForUserDevice(getState().edge, userDevice)
    if (deviceType === undefined) { return; }
    await makeBluetoothProtocol(bluetooth, deviceType, userDevice)
      .then(p => p.close());
  }
}

function closeAllBluetoothConnections(): ActionCreatorThunk {
  return async (dispatch, getState) => {
    const devices = getState().edge.userDevices || [];
    if (devices.length === 0) { return; }
    try {
      await dispatch(BLE.ActionCreators.withBluetoothOrFail())
      await Promise.all(devices.map(
        userDevice => dispatch(closeDeviceConnection(userDevice))
      ));
    } catch {
      // no bluetooth, ignore and still return
    }
  }
}

async function makeBluetoothProtocol(
  bluetooth: BLE.Service, deviceType: DeviceType, userDevice: Edge.UserDevice
): Promise<BLE.ProtocolCommon> {
  return new Promise(resolve => {
    if (deviceType.protocol) {
      bluetooth.protocol(
        deviceType.protocol,
        { address: userDevice.deviceId, name: deviceType.name }
      ).then(resolve);
    }
  });
}

function connectToDevice(
  device: BLE.Device, deviceType: DeviceType
): ActionCreatorThunk {
  return async (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    try {
      await dispatch(BLE.ActionCreators.withBluetoothOrFail());

      await api.edge.addDevice(deviceType.id, device.address);

      const userDevice =
        { deviceId: device.address, deviceTypeId: deviceType.id };
      dispatch(withNS({ type: ActionType.BLE_CONNECT_TO_DEVICE, userDevice }));

      if (deviceType.protocol && BLE.isPassiveSync(deviceType.protocol)) {
        dispatch(syncDevice(userDevice));
      }
      dispatch(ensureSyncOnResumeEnabled());
      dispatch(ensureBackgroundSyncingEnabled());

      dispatch(HUD.success());
    } catch(e) {
      dispatch(HUD.error());
    }

    dispatch(push(Urls.dashboardUrl()));
  }
}

function syncDevice(device: Edge.UserDevice): ActionCreatorThunk {
  return async (dispatch, getState, { bluetooth }) => {
    const rl = globalRemoteLogger().withTag('Edge#syncDevice');

    if (getState().ble.isDFU) {
      rl.log('Currently performing DFU, skipping sync.');
      return;
    }

    await dispatch(loadConfigurationOnce())
    rl.log('Configuration loaded.');

    try {
      let edgeState = getState().edge;
      if (isSyncing(edgeState, device)) {
        rl.log('Is already syncing, return.');
        return;
      }

      await dispatch(BLE.ActionCreators.withBluetoothOrFail());
      rl.log('Bluetooth initialized.');
      edgeState = getState().edge;

      const deviceType = Edge.getDeviceTypeForUserDevice(edgeState, device);
      if (deviceType === undefined) { return; }

      rl.log('Found device type:', deviceType.name);
      dispatch(withNS({ type: ActionType.BLE_SYNC_START, device }));
      await Timeout.timeout_(
        SYNC_TIMEOUT,
        async (resolve, reject) => {
          try {
            const collector =
              makeCollector(dispatch, bluetooth, deviceType, device);
            rl.log('Beginning collector sync.');
            await collector.sync()
            rl.log('Collector sync complete.');
            resolve();
            dispatch(
              withNS({ type: ActionType.BLE_SYNC_STOP__SUCCESS, device })
            );
            rl.log('Sync stop success action dispatched.');
          } catch (e) {
            reject(e);
          }
        }
      )
    } catch (e) {
      rl.log('Error during sync:', e);
      await Dialog.alert(
        'Sync failed. Ensure your device is in close range and try ' +
          `again. (Detail: ${e})`
      );
      dispatch(withNS({
        type: ActionType.BLE_SYNC_STOP__ERROR, device, error: e.toString()
      }));
    }
  }
}

function syncAllPassiveDevices(delay=0): ActionCreatorThunk {
  return async (dispatch, getState) => {
    await dispatch(loadConfigurationOnce());
    const edgeState = getState().edge;
    return Promise.all(
      (edgeState.userDevices || []).map(
        device => {
          const deviceType = Edge.getDeviceTypeForUserDevice(edgeState, device);
          if (
            deviceType === undefined ||
              deviceType.protocol === undefined ||
              BLE.isActiveSync(deviceType.protocol)
          ) {
            return Promise.resolve();
          }
          const timePassed = millisSinceLastSync(device);
          if (timePassed === undefined || timePassed >= delay) {
            return dispatch(syncDevice(device));
          } else {
            return Promise.resolve();
          }
        }
      )
    );
  }
}

function ensureBackgroundSyncingEnabled(): ActionCreatorThunk {
  return async (dispatch, getState) => {
    const logger =
      globalRemoteLogger().withTag('Edge#ensureBackgroundSyncingEnabled');
    try {
      if (getState().edge.backgroundSyncInitialized) { return; }
      await dispatch(BLE.ActionCreators.withBluetooth());
      const status = await BG.configure(
        async () => {
          logger.log('Beginning background sync.');
          await dispatch(loadConfiguration());
          logger.log('Edge configuration loaded.');
          if (isConnectedToDevices(getState().edge)) {
            logger.log('Still connected to device.');
            log('BG Sync - Still connected to devices, initiating sync');
            await dispatch(syncAllPassiveDevices());
          } else {
            logger.log('No longer connected to devices.');
            log('BG Sync - No longer connected to devices!');
            dispatch(stopBackgroundSyncing());
          }
          logger.log('Done.')
        },
        async () => {
          logError('BG Sync - Timed out before sync could complete.');
        }
      );
      log(`BG Sync - configured with status: ${status}`);
      dispatch(withNS({ type: ActionType.BLE_BACKGROUND_SYNC_START }));
    } catch (e) {
      console.warn('Could not start background sync: ', e);
    }
  }
}

function stopBackgroundSyncing(): ActionCreatorThunk {
  return (dispatch) => {
    BG.stop();
    dispatch(withNS({ type: ActionType.BLE_BACKGROUND_SYNC_STOP }));
    return Promise.resolve();
  }
}

function ensureSyncOnResumeEnabled(): ActionCreatorThunk {
  return async (dispatch, getState) => {
    if (getState().edge.syncOnResumeInitialized) { return; }
    await Cordova.ready();
    document.addEventListener('resume', function handler() {
      if (getState().edge.syncOnResumePendingShutdown) {
        document.removeEventListener('resume', handler);
        dispatch(withNS({ type: ActionType.BLE_SYNC_ON_RESUME_STOP }));
      } else {
        window.setTimeout(
          () => dispatch(syncAllPassiveDevices(SYNC_THROTTLE_ON_RESUME)),
          1000
        );
      }
    }, false);
    dispatch(withNS({ type: ActionType.BLE_SYNC_ON_RESUME_START }));
  }
}

function stopSyncOnResume(): ActionCreatorThunk {
  return async (dispatch) => {
    await Cordova.ready();
    dispatch(withNS({ type: ActionType.BLE_SYNC_ON_RESUME_PENDING_STOP }));
  }
}

function receiveDeviceData(
  provider: Edge.ProviderId | Edge.ProviderKey,
  data: Edge.DeviceData,
  userDevice?: Edge.UserDevice
): ActionCreatorThunk {
  return async (_dis, _, { api }) => {
    if (Edge.deviceDataIsEmpty(data)) { return; }
    await api.edge.sendData(provider, deviceDataToJSON(data), { userDevice });
  }
}

function edgeToOAuthProvider(p: Provider): OAuth.Provider {
  switch(p.key) {
  case 'fitbit':
  case 'google_fit':
  case 'withings':
  case 'misfit':
  case 'ihealth':
  case 'dexcom':
  case 'garmin':
    return p.key
  default:
    throw new Error('Can not convert edge provider to oauth: ' + p.key);
  }
}

async function connectToHealthKit(
  api: AppAPI,
  provider: Provider
): Promise<{}> {
  await HealthKit.requestPermissions();
  return api.edge.connect(provider.key);
}

function connectViaOauth(
  api: AppAPI,
  provider: Provider
): Promise<{}> {
  const oauthProvider = edgeToOAuthProvider(provider);
  return OAuth.login(api.token, oauthProvider, { goal: 'edge_connection' });
}

function healthKitConnected(s: State): boolean {
  return s.connections !== undefined &&
    s.connections.health_kit === 'connected';
}

const log = makeLogger('Edge', 'EDGE_LOG');
const logError = makeLogger('Edge', 'EDGE_LOG', 'error');
