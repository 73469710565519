import { AUTH_URL_BASE } from 'Env';
import { TokenProvider } from 'Api';
import { qs } from 'Shared/Urls';
import { getPlatform } from 'Shared/Device';

export type Goal = 'edge_connection' | 'token';

export type Provider
  = 'fitbit'
  | 'google_fit'
  | 'facebook'
  | 'google'
  | 'withings'
  | 'misfit'
  | 'ihealth'
  | 'dexcom'
  | 'garmin';

type OAuthOpts = {
  goal?: Goal,
  native?: boolean
};

export function login(
  tokenProvider: TokenProvider,
  oauthProvider: Provider,
  opts: OAuthOpts
): Promise<any> {
  let url;
  if (getPlatform() === 'browser') {
    url = providerUrl(tokenProvider, oauthProvider, opts);
    const oauthWindow = window.open(
      url, '_blank', 'menubar=0,resizable=1,scrollbars=1'
    );
    if (oauthWindow) {
      return monitorWindow(oauthWindow);
    } else {
      return Promise.reject('could not open window');
    }
  } else {
    url = providerUrl(tokenProvider, oauthProvider, { ...opts, native: true });
    window.open(url, '_system');
    return new Promise(() => {});
  }
}


/*------------------------------------------------------------*/
/* private */

function providerUrl(
  token: TokenProvider,
  p: Provider,
  opts: OAuthOpts
): string {
  const goal = opts.goal || 'token';
  const query = qs({ goal, current_user_token: token.get() })
    + (opts.native ? '&native=1' : '');
  return AUTH_URL_BASE + '/auth/' + p + '/start?' + query;
}

function monitorWindow(w: Window): Promise<any> {
  return new Promise((resolve) => {
    let i: number;
    const messageListener = function(e: MessageEvent) {
      if (e.origin === AUTH_URL_BASE) {
        window.removeEventListener('message', messageListener);
        clearInterval(i);
        w.close();
        resolve(e.data);
      }
    };
    window.addEventListener('message', messageListener);

    i = window.setInterval(() => {
      w.postMessage('done?', AUTH_URL_BASE);
    }, 300);
  });
}
