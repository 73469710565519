import * as React from 'react';
import * as classnames from 'classnames';
import { inboxThreadUrl } from 'Shared/Urls';
import { ThreadInfo } from 'Inbox/Data';
import Link from 'Shared/UI/Link';
import { safeLink } from 'Shared/SafeLink';

interface Props {
  threadInfo: ThreadInfo
}

const renderThreadListItem =
  (props: Props) => {
    const thread = props.threadInfo;
    const isUnread = thread.unreadCount > 0;

    const classes = classnames(
      'inbox__thread-list-item',
      { 'inbox__thread-list-item--unread': isUnread }
    );
    return (
      <li className={classes}>
        <Link to={inboxThreadUrl(thread.id)}
          className="inbox__thread-list-item__item-link"
          breadcrumb="push"
          themed={false}
        >
          <div className="inbox__thread-list-item__top-row">
            {thread.participants.map(u => u.name).join(', ')}
          </div>
          <div className="inbox__thread-list-item__middle-row">
            <div className="inbox__thread-list-bullet-column">
              <div className="inbox__bullet">
              </div>
            </div>
            <div className="inbox__deemphasize
                            inbox__thread-list-item__timestamp-column">
              {thread.lastMessageAt.format('h:mm A M/D/YYYY')}
            </div>
            <div className="inbox__thread-list-item__caret-column
                            inbox__deemphasize">
              <span dangerouslySetInnerHTML={{__html: '&rtrif;'}}></span>
            </div>
          </div>
          <div className="small-11 small-offset-1 columns
                          inbox__deemphasize
                          inbox__thread-list-item__bottom-row
                          inbox__thread-list-item__subject">
            {safeLink(thread.subject)}
          </div>
        </Link>
      </li>
    );
  }

export default renderThreadListItem;
