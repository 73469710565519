import * as React from 'react';
import * as moment from 'moment-timezone';

interface Props {
  startDate: moment.Moment,
  endDate: moment.Moment,
  style?: DateStyle
}

export type DateStyle = 'verbose' | 'abbreviate';

const FORMAT_VERBOSE = 'MMMM D, YYYY';
const FORMAT_ABBREVIATE = 'MMM D, YYYY';

export default function renderChallengeDates(
  props: Props
): React.ReactElement<Props> {
  const style: DateStyle = props.style || 'verbose';

  switch(style) {
    case 'verbose':
      return renderVerbose(props.startDate, props.endDate);
    case 'abbreviate':
      return renderAbbreviate(props.startDate, props.endDate);
  }
}

function renderVerbose(
  startDate: moment.Moment,
  endDate: moment.Moment
): React.ReactElement<Props> {
  const formattedStart = startDate.format(FORMAT_VERBOSE);
  const formattedEnd = endDate.format(FORMAT_VERBOSE);
  const contents =
    formattedStart === formattedEnd ?
    [ formattedStart ] :
    [ `Starts ${formattedStart}`, <br key="br"/>, `Finishes ${formattedEnd}` ];

  return <p className="challenge-dates">{contents}</p>;
}

function renderAbbreviate(
  startDate: moment.Moment,
  endDate: moment.Moment
): React.ReactElement<Props> {
  const formattedStart = startDate.format(FORMAT_ABBREVIATE);
  const formattedEnd = endDate.format(FORMAT_ABBREVIATE);
  const contents =
    formattedStart === formattedEnd ?
    [ startDate.format(FORMAT_VERBOSE) ] :
    [ formattedStart, ' - ', formattedEnd ];
  return <p className="challenge-dates">{contents}</p>;
}
