import * as React from 'react';

import HorizontalGenericField from './HorizontalGenericField';

type RadioValue = string | number;

interface Props {
  label: React.ReactNode,
  name: string,
  value: RadioValue,
  options: RadioValue[],
  optionLabels?: { [option: string]: string },
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
};

export default function renderHorizontalRadioField(
  props: Props
): React.ReactElement<Props> {
  return (
    <HorizontalGenericField label={props.label}>
      <div className="horizontal-form-field__vertical-align">
        {props.options.map(o => renderRadio(props, o))}
      </div>
    </HorizontalGenericField>
  );
}


function renderRadio(props: Props, option: RadioValue): React.ReactNode {
  const optionLabel =
    (props.optionLabels && props.optionLabels[option]) || option;
  return (
    <label className="bios d-ib" key={option}>
      <input
        type="radio"
        name={props.name}
        value={option}
        checked={props.value === option}
        onChange={props.onChange}/>
      <span className="horizontal-form-field__radio-option-label">
        {optionLabel}
      </span>
    </label>
  );
}
