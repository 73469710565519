import * as React from 'react';
import { Goal } from 'Goals/Data';
import { DataType as EdgeType } from 'Edge';
import {
  State as PostState, Activity, Biopost, CategoryInfo, SEL as PostSEL
} from 'Post';
import { edgeSettingsUrl } from 'Shared/Urls';
import Link from 'Shared/UI/Link';
import ConnectedSpecForms from 'Specs/UI/ConnectedSpecForms';
import PostMenuCategory from 'Post/UI/PostMenuCategory';
import PostMenuActivityCategory from 'Post/UI/PostMenuActivityCategory';
import PostMenuList from 'Post/UI/PostMenuList';
import PostMenuListItem from 'Post/UI/PostMenuListItem';

interface Props {
  goal: Goal,
  activities: Activity[],
  postState: PostState,
  afterPost?: (b: Biopost) => void
}

export default function renderGoalPostMenu(
  props: Props
): React.ReactElement<Props> {
  return (
    <PostMenuList>
      <PostMenuListItem>
        <DevicesAndAppsCategory {...props} />
      </PostMenuListItem>
      <PostMenuListItem>
        <GoalActivitiesPostMenu {...props} />
      </PostMenuListItem>
      <PostMenuListItem>
        <MeasurementsCategory {...props} />
      </PostMenuListItem>
    </PostMenuList>
  );
}

const DevicesAndAppsCategory = (props: Props) => {
  const edgeTypes = props.goal.edgeTypes;

  if (edgeTypes.length === 0) {
    return null;
  } else {
    const category: CategoryInfo = {
      name: 'devices and apps',
      icon: 'link'
    };

    const settingsLink = (
      <Link to={edgeSettingsUrl()} themed={true} breadcrumb="push">
        device settings
      </Link>
    );
    return (
      <PostMenuCategory category={category} collapsable={false}>
        <p className="text-center p-1r m-0">
          This goal includes automatic tracking from your connected devices and
          apps for: {formatEdgeTypes(edgeTypes)}.
          You can manage your connections
          in {settingsLink}.
        </p>
      </PostMenuCategory>
      );
  }
}

const GoalActivitiesPostMenu = (props: Props) => {
  if (props.activities.length > 0) {
    const category: CategoryInfo = { name: 'post', icon: 'rocket' };
    return (
      <PostMenuActivityCategory
        category={category}
        topActivities={props.activities}
        collapsable={false}
        afterPost={props.afterPost}
      />
    );
  } else {
    return null;
  }
};

const DEFAULT_SPEC_CATEGORY: CategoryInfo = {
  name: 'measurements',
  icon: 'ruler'
};
const MeasurementsCategory = (props: Props) => {
  if (props.goal.specTypes.length > 0) {
    const category: CategoryInfo =
      PostSEL.specsCategory(props.postState) || DEFAULT_SPEC_CATEGORY;
    return (
      <PostMenuCategory category={category} collapsable={false}>
        <ConnectedSpecForms
          show={props.goal.specTypes}
          afterSpecUpdate={props.afterPost}
        />
      </PostMenuCategory>
    );
  } else {
    return null;
  }
}

function formatEdgeTypes(edgeTypes: EdgeType[]): React.ReactNode {
  const names = edgeTypes.map(t => t.replace(/_/g, ' ')).join(', ');
  return (
    <span className="goal-details__edge-types">{names}</span>
  );
}
