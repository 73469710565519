import { ApiClient, OK, GET, PUT, DELETE } from 'Api';
import {
  CurrentUserResponseJSON, ProfileJSON, SpecsJSON,
  CrewResponseJSON
} from './JSON/User';
import { LastBiopostResponseJSON } from './JSON/Post';

export interface UserAPI {
  getCurrentUser(): Promise<CurrentUserResponseJSON>

  getCurrentUserSpecs(): Promise<{ specs: SpecsJSON }>

  getCurrentUserLastBiopost(): Promise<LastBiopostResponseJSON>

  getCurrentUserCrew(): Promise<CrewResponseJSON>

  updateProfile(profile: ProfileJSON): Promise<{ errors: Array<string> }>

  changePassword(password: string): OK

  removeFromCrew(crewMemberId: string): OK
}


/*
  ------------------------------------------------------------
  Implementation
  ------------------------------------------------------------
*/

export function makeApi(client: ApiClient): UserAPI {
  return {
    getCurrentUser,
    getCurrentUserSpecs,
    getCurrentUserLastBiopost,
    getCurrentUserCrew,
    updateProfile,
    changePassword,
    removeFromCrew
  };

  function getCurrentUser(): Promise<CurrentUserResponseJSON> {
    return client.requestJSON(
      GET,
      { path: currentUserUrl(), version: 2 }
    );
  }

  function getCurrentUserSpecs(): Promise<{ specs: SpecsJSON }> {
    return client.requestJSON(
      GET,
      { path: `/user_specs`, version: 3 }
    );
  }

  function getCurrentUserLastBiopost(): Promise<LastBiopostResponseJSON> {
    return client.requestJSON(
      GET, { path: currentUserUrl('last_biopost'), version: 2 }
    );
  }

  function getCurrentUserCrew(): Promise<CrewResponseJSON> {
    return client.requestJSON(GET, `/crew_members`);
  }

  function updateProfile(
    profile: ProfileJSON
  ): Promise<{ errors: Array<string> }> {
    return client.requestJSON(
      PUT,
      currentUserUrl(),
      { profile }
    );
  }

  function changePassword(password: string): OK {
    return client.requestJSON(
      PUT,
      '/change_password',
      { password }
    );
  }

  function removeFromCrew(crewMemberId: string): OK {
    return client.request(DELETE, `/crew_members/${crewMemberId}`);
  }
}

type CurrentUserEndpoint
  = 'profile' | 'specs' | 'last_biopost' | 'notifications';
function currentUserUrl(endpoint?: CurrentUserEndpoint): string {
  const suffix = endpoint ? `/${endpoint}` : '';
  return `/current_user${suffix}`;
}
