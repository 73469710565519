import * as Dialog from 'Shared/Dialog';
import { CrewMember, MinimalUser, userName } from 'User/Data';
import { ActionCreators as UserAC } from 'User/ActionCreator';
import { ActionCreatorThunk } from 'Store';
import { ActionCreators as HUD } from 'HUD';
import { withNS, ActionType } from './Action';

export interface ActionCreators {
  sendCrewInviteByEmail(email: string): ActionCreatorThunk
  removeFromCrew(member: CrewMember): ActionCreatorThunk
  acceptCrewInvitation(from: MinimalUser): ActionCreatorThunk
  declineCrewInvitation(from: MinimalUser): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  sendCrewInviteByEmail,
  removeFromCrew,
  acceptCrewInvitation,
  declineCrewInvitation
}

function sendCrewInviteByEmail(email: string): ActionCreatorThunk {
  return (_dispatch, _, { api }) => {
    return api.community.sendCrewInvitation(email);
  };
}

function removeFromCrew(crewMember: CrewMember): ActionCreatorThunk {
  return (dispatch, _, { api }) =>
    Dialog.confirm_(removeFromCrewConfirmationText(crewMember)).then(
      () => {
        dispatch(HUD.loading());
        return api.user.removeFromCrew(crewMember.user.id).then(
          () => {
            dispatch(
              withNS({ type: ActionType.REMOVE_FROM_CREW__SUCCESS, crewMember })
            );
            dispatch(HUD.success());
          },
          () => dispatch(HUD.error())
        );
      }
    );
}

function removeFromCrewConfirmationText(crewMember: CrewMember): string {
  return `Are you sure you want to remove ${userName(crewMember)} from your ` +
    'crew?';
}

function acceptCrewInvitation(from: MinimalUser): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.community.acceptCrewInvitation(from.id)
      .then(() => dispatch(UserAC.loadCrew()))
      .then(
        () => dispatch(HUD.success()),
        () => dispatch(HUD.error())
      );
  }
}

function declineCrewInvitation(from: MinimalUser): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.community.declineCrewInvitation(from.id)
      .then(
        () => dispatch(HUD.success()),
        () => dispatch(HUD.error())
      );
  }
}
