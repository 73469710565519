import './index.scss';

export * from './Data';
export { ActionTypes, Action } from './Action';
export { ActionCreators } from './ActionCreator';
export { reducer } from './Store';

import ChallengeDetails from './Page/ChallengeDetails';
import MyChallenges from './Page/MyChallenges';
import Rules from './Page/Rules';
import Template from './Page/Template';
import Invite from './Page/Invite';
import FullLeaderboard from './Page/FullLeaderboard';
import PreJoinSetupChallenge from './Page/PreJoinSetupChallenge';
import PreJoinSetupTemplate from './Page/PreJoinSetupTemplate';
import PreJoinSetupInvitation from './Page/PreJoinSetupInvitation';
import TaskChallengeDailyDetails from './Page/TaskChallengeDailyDetails';
import TaskChallengeSummary from './Page/TaskChallengeSummary';
import AvailableChallenges from './Page/AvailableChallenges';
import CompletedChallenges from './Page/CompletedChallenges';
import JoinTeam from './Page/JoinTeam';
import ShowTeam from './Page/ShowTeam';
import ManageTeam from './Page/ManageTeam';

export const Pages = {
  ChallengeDetails,
  MyChallenges,
  Rules,
  Template,
  FullLeaderboard,
  PreJoinSetupChallenge,
  PreJoinSetupTemplate,
  PreJoinSetupInvitation,
  Invite,
  TaskChallengeDailyDetails,
  TaskChallengeSummary,
  AvailableChallenges,
  CompletedChallenges,
  JoinTeam,
  ShowTeam,
  ManageTeam
};
