import * as R from 'ramda';
import * as moment from 'moment-timezone';
import { Version } from 'Shared/Data/Version';
import { DeviceProtocol } from 'BLE/Data';

type Kilometers = number;
type KCalories = number;
type Minutes = number;

export interface State {
  version?: DeviceVersion | undefined
  time?: moment.Moment | undefined,
  battery?: number | undefined,
  dailySummaries?: DailyActivitySummary[] | undefined,
  dailyDetails?: DailyActivityDetail[] | undefined,
  hrs?: HR[] | undefined,
  hrvs?: HRV[] | undefined,
  bps?: BP[] | undefined,
  spo2s?: SpO2[] | undefined,
  bodyTemps?: BodyTemp[] | undefined,
  sleepDetails?: SleepDetail[] | undefined,
  autoMonitoring: {
    [dataType in AutoMonitoringType]?: AutoMonitoring | undefined
  },
  deviceSettings?: DeviceSettings | undefined,
  bpCalibration?: BPCalibration | undefined
}

export interface DailyActivitySummary {
  id: number,
  date: moment.Moment,
  stepCount: number,
  distance: Kilometers,
  calories: KCalories,
  activeMinutes: Minutes,
  intensiveMinutes: Minutes
}

export interface DailyActivityDetail {
  id: number,
  time: moment.Moment,
  stepCount: number,
  distance: Kilometers,
  calories: KCalories,
  // 1 step count per minute, starting at time
  steps: Uint8Array
}

export interface SleepDetail {
  id: number,
  // Starting time of this detail. Multiple "details" will be generated for a
  // single night of sleep, since the maximum length of the detail array is 24
  time: moment.Moment,
  // length of the details array (a bit redundant to store separate, but part of
  // the raw data that comes in)
  length: number,
  // sleep quality every 5 minutes
  details: Uint8Array
}

export interface HR {
  id: number,
  time: moment.Moment,
  heartRate: number
}

export interface HRV {
  id: number,
  time: moment.Moment,
  hrv: number,
  vascularAgingValue: number,
  heartRate: number,
  stressLevel: number,
  systolicBP: number,
  diastolicBP: number
}

export interface BP {
  id: number,
  time: moment.Moment,
  systolicBP: number,
  diastolicBP: number
}

export interface SpO2 {
  id: number,
  time: moment.Moment,
  spo2: number
}

export interface BodyTemp {
  id: number,
  time: moment.Moment,
  bodyTemp: number
}

export interface DailyEnabledMap {
  sunday: boolean
  monday: boolean
  tuesday: boolean
  wednesday: boolean
  thursday: boolean
  friday: boolean
  saturday: boolean
}

export type AutoMonitoringMode = 'off' | 'period' | 'interval';

export enum AutoMonitoringType {
  HR = 'HR',
  BP = 'BP',
  SPO2 = 'SPO2',
  BODY_TEMP = 'BODY_TEMP'
}

export interface AutoMonitoring {
  mode: AutoMonitoringMode
  startHour: number
  startMinute: number
  endHour: number
  endMinute: number
  dailyEnabled: DailyEnabledMap
  interval: number
  dataType: AutoMonitoringType
}

export interface DeviceSettings {
  distanceUnit: 'mile' | 'km'
  timeFormat: 12 | 24
  raiseWristEnabled: boolean
  temperatureUnit: 'F' | 'C'
  nightModeEnabled: boolean,
  basicHeartRate: number,
  screenDarkness: number,
  watchFace: number,
  socialDistanceAlertEnabled: boolean
}

export interface BPCalibration {
  minSystolic: number
  maxSystolic: number
  minDiastolic: number
  maxDiastolic: number
}

export enum DeviceFamily {
  JStyle1963YH = 'JStyle1963YH',
  JStyle1790 = 'JStyle1790'
}

export interface DeviceVersion {
  family: DeviceFamily,
  version: Version
}

export function initState(): State {
  return {
    autoMonitoring: {}
  };
}

export function clearData(key: keyof State, state: State): State {
  return { ...state, [key]: undefined };
}

export function addDailyActivitySummaries(
  summaries: DailyActivitySummary[], state: State
): State {
  const newSummaries = R.concat(state.dailySummaries || [], summaries);
  return { ...state, dailySummaries: newSummaries };
}

export function clearSummaries(state: State): State {
  return { ...state, dailySummaries: [] };
}

export function addDailyActivityDetails(
  details: DailyActivityDetail[], state: State
): State {
  const newDetails = R.concat(state.dailyDetails || [], details);
  return { ...state, dailyDetails: newDetails };
}

export function addHRs(hrs: HR[], state: State): State {
  return { ...state, hrs: R.concat(state.hrs || [], hrs) };
}

export function addHRVs(hrvs: HRV[], state: State): State {
  return { ...state, hrvs: R.concat(state.hrvs || [], hrvs) };
}

export function addSpO2s(spo2s: SpO2[], state: State): State {
  return { ...state, spo2s: R.concat(state.spo2s || [], spo2s) };
}

export function addBodyTemps(bodyTemps: BodyTemp[], state: State): State {
  return { ...state, bodyTemps: R.concat(state.bodyTemps || [], bodyTemps) };
}

export function addSleepDetails(
  sleepDetails: SleepDetail[], state: State
): State {
  return {
    ...state, sleepDetails: R.concat(state.sleepDetails || [], sleepDetails)
  };
}

export function addBPs(bps: BP[], state: State): State {
  return { ...state, bps: R.concat(state.bps || [], bps) };
}

export function autoMonitoringTypeToByte(t: AutoMonitoringType): number {
  switch(t) {
    case AutoMonitoringType.HR:
      return 1;
    case AutoMonitoringType.SPO2:
      return 2;
    case AutoMonitoringType.BODY_TEMP:
      return 3;
    case AutoMonitoringType.BP:
      return 4;
  }
}

export function byteToAutoMonitoringType(b: number): AutoMonitoringType {
  switch(b) {
    case 0: // firmware < 1.5.9 doesn't suport this and is always 0 -> HR
    case 1:
      return AutoMonitoringType.HR;
    case 2:
      return AutoMonitoringType.SPO2;
    case 3:
      return AutoMonitoringType.BODY_TEMP;
    case 4:
      return AutoMonitoringType.BP;
    default:
      throw new Error(`Unrecognized auto monitoring type byte: ${b}`);
  }
}

export function familyFromProtocol(protocol: DeviceProtocol): DeviceFamily {
  switch(protocol) {
    case DeviceProtocol.JStyle1963YH:
      return DeviceFamily.JStyle1963YH;
    case DeviceProtocol.JStyle1790:
      return DeviceFamily.JStyle1790;
    default:
      throw new Error(`Protocol is not a known JStyle device: ${protocol}`);
  }
}
