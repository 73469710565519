import * as React from 'react';
import { CUSTOM_BUILD } from 'Env';
import { platform } from 'Cordova';
import * as Urls from 'Shared/Urls';
import ErrorPage from 'Shared/UI/ErrorPage';

const ForcedUpgrade = () => {
  const url = Urls.nativeAppDownloadUrl(
    platform(), CUSTOM_BUILD.CANONICAL_PROGRAM_SLUG || '1bios'
  );

  const gotoStore = () => window.open(url, '_system');

  return (
    <ErrorPage
      icon="arrow-circle-up"
      title="Please upgrade"
      detail="Sorry, this version is no longer supported"
      tryAgainLabel="Upgrade now"
      tryAgainTarget={gotoStore}
    />
  )
};

export default ForcedUpgrade;
