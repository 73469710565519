import { withNS as originalWithNS } from 'Shared/NSAction';
import * as moment from 'moment-timezone';
import { TaskId } from 'Tasks/Data';

import {
  NSType,
  NS,
  ChallengeId,
  ChallengeInvitation,
  ChallengeDetails,
  ChallengeSummary,
  ChallengeTemplate,
  Player,
  TaskChallengeDaySummary,
  Team,
  TeamPlayer
} from 'Challenges/Data';


/** Action creators **/

export enum ActionTypes {
  LOAD_CHALLENGES_SUCCESS = 'LOAD_CHALLENGES_SUCCESS',
  LOAD_COMPLETED_CHALLENGES_START = 'LOAD_COMPLETED_CHALLENGES_START',
  LOAD_COMPLETED_CHALLENGES_SUCCESS = 'LOAD_COMPLETED_CHALLENGES_SUCCESS',
  LOAD_CHALLENGE_START = 'LOAD_CHALLENGE_START',
  LOAD_CHALLENGE_SUCCESS = 'LOAD_CHALLENGE_SUCCESS',
  LOAD_LEADERBOARD_START = 'LOAD_LEADERBOARD_START',
  LOAD_LEADERBOARD_SUCCESS = 'LOAD_LEADERBOARD_SUCCESS',
  POLL_CHALLENGE_START = 'POLL_CHALLENGE_START',
  JOIN_CHALLENGE_SUCCESS = 'JOIN_CHALLENGE_SUCCESS',
  START_CHALLENGE_SUCCESS = 'START_CHALLENGE_SUCCESS',
  LEAVE_CHALLENGE_SUCCESS = 'LEAVE_CHALLENGE_SUCCESS',
  LOAD_INVITATIONS_SUCCESS = 'LOAD_INVITATIONS_SUCCESS',
  ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS',
  DECLINE_INVITATION_SUCCESS = 'DECLINE_INVITATION_SUCCESS',
  LOAD_CHALLENGE_RULES_SUCCESS = 'LOAD_CHALLENGE_RULES_SUCCESS',
  LOAD_TEMPLATE_RULES_SUCCESS = 'LOAD_TEMPLATE_RULES_SUCCESS',
  ADD_STEPS_SUCCESS = 'ADD_STEPS_SUCCESS',
  POST_ACTIVITY_SUCCESS = 'POST_ACTIVITY_SUCCESS',
  LOAD_TASK_CHALLENGE_DAY_DETAIL_START =
    'LOAD_TASK_CHALLENGE_DAY_DETAIL_START',
  LOAD_TASK_CHALLENGE_DAY_DETAIL_SUCCESS =
    'LOAD_TASK_CHALLENGE_DAY_DETAIL_SUCCESS',
  LOAD_TASK_CHALLENGE_DAY_SUMMARIES_START =
    'LOAD_TASK_CHALLENGE_DAY_SUMMARIES_START',
  LOAD_TASK_CHALLENGE_DAY_SUMMARIES_SUCCESS =
    'LOAD_TASK_CHALLENGE_DAY_SUMMARIES_SUCCESS',
  COMPLETE_TASK_FOR_CHALLENGE_SUCCESS =
    'COMPLETE_TASK_FOR_CHALLENGE_SUCCESS',
  UNDO_TASK_FOR_CHALLENGE_SUCCESS =
    'UNDO_TASK_FOR_CHALLENGE_SUCCESS',
  LOAD_TEAMS_SUCCESS = 'LOAD_TEAMS_SUCCESS',
  LOAD_TEAM_WITH_PLAYERS_SUCCESS = 'LOAD_TEAM_WITH_PLAYERS_SUCCESS',
  LOAD_TEAM_LEADERBOARD_START = 'LOAD_TEAM_LEADERBOARD_START',
  LOAD_TEAM_LEADERBOARD_SUCCESS = 'LOAD_TEAM_LEADERBOARD_SUCCESS'
};

export interface LoadChallenges_Success {
  NS: NSType,
  type: ActionTypes.LOAD_CHALLENGES_SUCCESS,
  data: {
    enrolled: ChallengeSummary[],
    available: ChallengeSummary[],
    templates: ChallengeTemplate[]
  }
}

export interface LoadCompletedChallenges_Start {
  NS: NSType,
  type: ActionTypes.LOAD_COMPLETED_CHALLENGES_START,
}

export interface LoadCompletedChallenges_Success {
  NS: NSType,
  type: ActionTypes.LOAD_COMPLETED_CHALLENGES_SUCCESS,
  data: {
    completed: ChallengeSummary[],
    hasMorePages: boolean
  }
}

export interface LoadLeaderboard_Start {
  NS: NSType,
  type: ActionTypes.LOAD_LEADERBOARD_START,
  challengeId: ChallengeId,
  page: number
}

export interface LoadLeaderboard_Success {
  NS: NSType,
  type: ActionTypes.LOAD_LEADERBOARD_SUCCESS,
  challengeId: ChallengeId,
  page: number,
  players: Player[],
  hasMore: boolean
}

export interface PollChallenge_Start {
  NS: NSType,
  type: ActionTypes.POLL_CHALLENGE_START,
  challengeId: ChallengeId
}

export interface LoadChallenge_Start {
  NS: NSType,
  type: ActionTypes.LOAD_CHALLENGE_START,
  challengeId: ChallengeId
};

export interface LoadChallenge_Success {
  NS: NSType,
  type: ActionTypes.LOAD_CHALLENGE_SUCCESS,
  challenge: ChallengeDetails
}

export interface JoinChallenge_Success {
  NS: NSType,
  type: ActionTypes.JOIN_CHALLENGE_SUCCESS,
  challenge: ChallengeDetails
}

export interface StartChallenge_Success {
  NS: NSType,
  type: ActionTypes.START_CHALLENGE_SUCCESS,
  challenge: ChallengeDetails
}

export interface LeaveChallenge_Success {
  NS: NSType,
  type: ActionTypes.LEAVE_CHALLENGE_SUCCESS,
  challengeId: ChallengeId
}

export interface LoadInvitations_Success {
  NS: NSType,
  type: ActionTypes.LOAD_INVITATIONS_SUCCESS,
  invitations: ChallengeInvitation[]
}

export interface AcceptInvitation_Success {
  NS: NSType,
  type: ActionTypes.ACCEPT_INVITATION_SUCCESS,
  invitationId: string
}

export interface DeclineInvitation_Success {
  NS: NSType,
  type: ActionTypes.DECLINE_INVITATION_SUCCESS,
  invitationId: string
}

export interface LoadChallengeRules_Success {
  NS: NSType,
  type: ActionTypes.LOAD_CHALLENGE_RULES_SUCCESS,
  id: ChallengeId,
  rules: string
}

export interface LoadTemplateRules_Success {
  NS: NSType,
  type: ActionTypes.LOAD_TEMPLATE_RULES_SUCCESS,
  id: string,
  rules: string
}

export interface LoadTaskChallengeDayDetail_Start {
  NS: NSType,
  type: ActionTypes.LOAD_TASK_CHALLENGE_DAY_DETAIL_START
}

export interface LoadTaskChallengeDayDetail_Success {
  NS: NSType,
  type: ActionTypes.LOAD_TASK_CHALLENGE_DAY_DETAIL_SUCCESS,
  date: moment.Moment,
  locked: boolean,
  completedTaskIds: TaskId[],
  availableTaskIds: TaskId[]
}

export interface LoadTaskChallengeDaySummaries_Start {
  NS: NSType
  type: ActionTypes.LOAD_TASK_CHALLENGE_DAY_SUMMARIES_START
}
export interface LoadTaskChallengeDaySummaries_Success {
  NS: NSType
  type: ActionTypes.LOAD_TASK_CHALLENGE_DAY_SUMMARIES_SUCCESS,
  daySummaries: TaskChallengeDaySummary[]
}

export interface CompleteTaskForChallenge_Success {
  NS: NSType,
  type: ActionTypes.COMPLETE_TASK_FOR_CHALLENGE_SUCCESS,
  challengeId: ChallengeId,
  taskId: TaskId,
  date: moment.Moment
}

export interface UndoTaskForChallenge_Success {
  NS: NSType,
  type: ActionTypes.UNDO_TASK_FOR_CHALLENGE_SUCCESS,
  challengeId: ChallengeId,
  taskId: TaskId,
  date: moment.Moment
}

export interface LoadTeams_Success {
  NS: NSType,
  type: ActionTypes.LOAD_TEAMS_SUCCESS,
  challengeId: ChallengeId,
  teams: Team[]
}

export interface LoadTeamWithPlayers_Success {
  NS: NSType,
  type: ActionTypes.LOAD_TEAM_WITH_PLAYERS_SUCCESS,
  team: Team,
  players: Player[],
  challengeId: ChallengeId
}

export interface LoadTeamLeaderboard_Start {
  NS: NSType,
  type: ActionTypes.LOAD_TEAM_LEADERBOARD_START,
  challengeId: ChallengeId
}

export interface LoadTeamLeaderboard_Success {
  NS: NSType,
  type: ActionTypes.LOAD_TEAM_LEADERBOARD_SUCCESS,
  challengeId: ChallengeId,
  page: number,
  teamPlayers: TeamPlayer[],
  hasMore: boolean
}

export type Action
  = LoadChallenges_Success
  | LoadCompletedChallenges_Start
  | LoadCompletedChallenges_Success
  | PollChallenge_Start
  | LoadChallenge_Start
  | LoadChallenge_Success
  | LoadLeaderboard_Start
  | LoadLeaderboard_Success
  | JoinChallenge_Success
  | StartChallenge_Success
  | LeaveChallenge_Success
  | LoadInvitations_Success
  | AcceptInvitation_Success
  | DeclineInvitation_Success
  | LoadChallengeRules_Success
  | LoadTemplateRules_Success
  | LoadTaskChallengeDayDetail_Start
  | LoadTaskChallengeDayDetail_Success
  | LoadTaskChallengeDaySummaries_Start
  | LoadTaskChallengeDaySummaries_Success
  | CompleteTaskForChallenge_Success
  | UndoTaskForChallenge_Success
  | LoadTeams_Success
  | LoadTeamWithPlayers_Success
  | LoadTeamLeaderboard_Start
  | LoadTeamLeaderboard_Success;

export const withNS = originalWithNS<NSType, Action>(NS);
