export * from './Data';
export { ActionCreators } from './ActionCreator';
export { ActionType, Action, NSAction } from './Action';

export { reducer } from './Store';

import ConnectedGoalList from './UI/ConnectedGoalList';

export const UI = { ConnectedGoalList };

import Dashboard from './Page/Dashboard';
import GoalDetails from './Page/GoalDetails';

export const Pages = {
  Dashboard,
  GoalDetails
};
