import './ArrowLinkCaret.scss';
import * as React from 'react';

interface Props {
  className?: string
}

const CARET_SRC = require('images/arrow-link-caret-transparent.png').default;

export default function renderArrowLink(
  props: Props
): React.ReactElement<Props> {
  const classes = `arrow-link__caret ${props.className || ''}`;
  return (
      <div className={classes}>
        <img src={CARET_SRC} />
      </div>
  );
}
