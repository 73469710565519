import * as React from 'react';
import * as moment from 'moment-timezone';
import { trendsUrl } from 'Shared/Urls';
import { UserId } from 'User';
import Tile from './Tile';
import ArrowLink from 'Shared/UI/ArrowLink';
import Spinner from 'Shared/UI/Spinner';
import Icon from './Icon';
import ArrowLinkCaret from 'Shared/UI/ArrowLinkCaret';

interface Props {
  userId: UserId
}

export default function renderTrendsTile(
  props: Props
): React.ReactElement<Props> {
  const url = trendsUrl(props.userId);

  return (
    <Tile title="trends" linkUrl={url}>
      {url ? renderLink(url) : <Spinner wrap="center" />}
    </Tile>
  );
}

function renderLink(url: string) {
  const date = moment().format('MMMM YYYY');
  return (
    <div className="simple-dashboard-tile__main-text">
      <ArrowLink to={url} breadcrumb="push">
        <div className ="dashboard-tile__left">
          <div className="dashboard-tile__left-content">
            <div className="dashboard-tile__icon">
              <Icon name="chart-line"></Icon>
            </div>
            <div>
              {date}
            </div>
          </div>
          <ArrowLinkCaret />
        </div>
      </ArrowLink>
    </div>
  );
}
