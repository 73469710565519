import { dateFromJSON } from 'Shared/JSON';
import {
  ActionStatusJSON, ActionJSON, ActionDetailsJSON, DailyStatusJSON,
  FrequencyJSON
} from 'Api/JSON/CarePlans';
import {
  ActionStatus, Action, ActionDetails, ActionType, DailyStatus, Frequency
} from 'CarePlans/Data';

import { expandedSpecTypeFromJSON } from 'Specs/JSON';

export function dailyStatusFromJSON(json: DailyStatusJSON): DailyStatus {
  return {
    date: dateFromJSON(json.date),
    statuses: json.statuses.map(actionStatusFromJSON)
  };
}

export function actionStatusFromJSON(json: ActionStatusJSON): ActionStatus {
  return {
    complete: json.complete,
    text: json.text,
    action: actionFromJSON(json.action)
  };
}

function actionFromJSON(json: ActionJSON): Action {
  return {
    id: json.id,
    details: actionDetailsFromJSON(json.details),
    frequency: frequencyFromJSON(json.frequency)
  };
}

function actionDetailsFromJSON(json: ActionDetailsJSON): ActionDetails {
  switch(json.type) {
    case 'Task':
      return {
        type: ActionType.TASK,
        task: json.task
      };
    case 'Measurement':
      return {
        type: ActionType.MEASUREMENT,
        specType: expandedSpecTypeFromJSON(json.spec_type)
      };
  }
}

function frequencyFromJSON(json: FrequencyJSON): Frequency {
  switch(json) {
    case 'daily':
      return Frequency.DAILY;
    case 'daily_am':
      return Frequency.DAILY_AM;
    case 'daily_pm':
      return Frequency.DAILY_PM;
    case 'weekly':
      return Frequency.WEEKLY;
    case 'monthly':
      return Frequency.MONTHLY;
  }
}
