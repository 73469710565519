import * as React from 'react';
import { RewardPlan } from 'Rewards/Data';
import ProgressBar from 'Shared/UI/ProgressBar';

interface Props {
  rewardPlan: RewardPlan
}

const RewardProgressBar = (props: Props) => {
  const plan = props.rewardPlan;
  return (
    <ProgressBar percent={progressPercent(plan)} modifier="rewards"/>
  );
};

export default RewardProgressBar;

function progressPercent(plan: RewardPlan): number {
  return plan.currentPoints / plan.maxPoints;
}
