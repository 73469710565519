import * as React from 'react';
import * as classnames from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import { bem } from 'css-util';
import { Loadable, loadableMap, fromLoadable } from 'misc/Data/Loadable';
import { Maybe, maybe } from 'misc/Data/Maybe';
import * as List from 'misc/Data/List';
import { plural } from 'Shared/Text';
import { defaultConnect } from 'Shared/ReduxComponent';
import { NavBarLayout } from 'Nav';
import { State, DispatchProps } from 'Store';
import { UserAction, ActionStatus, ActionType, isComplete } from 'Rewards/Data';
import { ActionCreators } from 'Rewards/ActionCreator';
import * as Urls from 'Shared/Urls';
import DynamicHTML from 'Shared/UI/DynamicHTML';
import Spinner from 'Shared/UI/Spinner';
import Link, { LinkFn } from 'Shared/UI/Link';
import Points from 'Rewards/UI/Points';
import RewardsIcon from 'Rewards/UI/Icon';
import { ScreeningStatus } from 'User/Data';
import AlertBox from 'Shared/UI/AlertBox';
import Icon from 'Shared/UI/Icon';

type Props = State & DispatchProps & RouteComponentProps<URLParams>;

interface URLParams {
  rewardId: string
}

const CSS = bem('rewards-summary');

class RewardPage extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(ActionCreators.loadUserActions());
  }

  rewardId(): number {
    return parseInt(this.props.match.params.rewardId);
  }

  loadableUserAction(): Loadable<Maybe<UserAction>> {
    return loadableMap(
      actions => List.maybeFind(
        a => a.reward.id === this.rewardId(),
        actions
      ),
      this.props.rewards.userActions
    );
  }

  userMissingActiveScreening(action: UserAction): boolean {
    return (
      this.props.currentUser.screeningStatus.value !== ScreeningStatus.ACTIVE &&
      action.status === ActionStatus.AVAILABLE &&
      action.reward.action.type === ActionType.ScreeningComplete
    );
  }

  onPerformManualAction = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    this.props.dispatch(ActionCreators.performAction(this.rewardId()));
  }

  render() {
    return (
      <NavBarLayout title="Rewards" initBreadcrumb={Urls.rewardsUrl()}>
        <div className="simple-container">
          {fromLoadable(
             this.renderMaybeAction,
             this.renderSpinner,
             this.loadableUserAction()
           )}
        </div>
      </NavBarLayout>
    );
  }

  renderMaybeAction = (action: Maybe<UserAction>) => {
    return maybe(
      () => <span />,
      this.renderAction,
      action
    );
  }

  renderAction = (action: UserAction) => {
    return (
      <div>
        {this.userMissingActiveScreening(action)
          && this.renderNoActionNotice()}
        <div className={CSS('icon')()}>
          <RewardsIcon />
        </div>
        <div className={CSS('reward-name')()}>
          {action.reward.name}
        </div>
        {!isComplete(action.status) &&
         (
           <div className={CSS('point-summary')()}>
             <Points>{plural('point', action.reward.points)}</Points>
           </div>
         )
        }

        {this.renderRewardActionButton(action)}

        {!isComplete(action.status) &&
         <p className="text-center mb-2r">
           Available until {action.reward.endDate.format('MMMM D, YYYY')}
         </p>
        }

        <h4 className="section-title">Learn more about this reward</h4>
        <DynamicHTML html={action.reward.rules} />
      </div>
    );
  }

  renderRewardActionButton = (userAction: UserAction) => {
    if (userAction.status === ActionStatus.COMPLETE) {
      return (
        <div className={CSS('points-earned')()}>
          <Points modifier="earned">
            Got it!<br/>
            You earned {plural('point', userAction.reward.points)}
          </Points>
        </div>
      );
    } else {
      switch(userAction.reward.action.type) {
        case ActionType.ManualActionApp:
          return this.actionButton(
            `YES, I'VE EARNED THIS REWARD!`,
            this.onPerformManualAction
          );
        case ActionType.DeviceConnected:
          return this.actionButton(
            `YES, I'D LIKE TO GET STARTED`,
            Urls.edgeSettingsUrl()
          );
        case ActionType.ScreeningComplete:
          return this.conditionalActionButton(
            `YES, I'D LIKE TO GET STARTED`,
            Urls.screeningFormUrl(),
            this.userMissingActiveScreening(userAction)
          );
        case ActionType.ChallengeJoined:
          return this.actionButton(
            `YES, I'D LIKE TO GET STARTED`,
            Urls.challengeUrl(userAction.reward.action.challengeId)
          );
        case ActionType.ChallengeTemplateJoined:
          return this.actionButton(
            `YES, I'D LIKE TO GET STARTED`,
            Urls.challengeTemplateUrl(userAction.reward.action.templateId)
          );
        default:
          return '';
      }
    }
  }

  actionButton(label: string, action: string | LinkFn) {
    return (
      <Link
        to={action}
        breadcrumb="push"
        className="button button--block theme__primary-bg">
        {label}
      </Link>
    );
  }

  conditionalActionButton(
    label: string, action: string | LinkFn, notActionable: boolean
  ) {
    return (
      <Link
        to={action}
        breadcrumb="push"
        className={this.conditionalActionButtonClassNames(notActionable)}>
        {label}
      </Link>
    );
  }

  conditionalActionButtonClassNames(notActionable: boolean): string {
    return classnames(
      'button button--block theme__primary-bg',
      {'disabled rewards-summary__link--disabled': notActionable}
    );
  }

  renderSpinner = () => {
    return <Spinner wrap="center" />;
  }

  renderNoActionNotice(): React.ReactElement<{}> {
    return (
      <AlertBox modifiers="warning" className={CSS('alert-box')()}>
        <Icon name="exclamation-triangle"/>{' '}
        Hmmm... It looks like you've done everything you can here in the app.
        Please contact your program adminstrator for further support.
      </AlertBox>
    )
  }
}

export default defaultConnect(RewardPage);
