import * as React from 'react';
import { State as PostState } from 'Post/Data';
import PostMenu from 'Post/UI/PostMenu';

interface Props {
  postState: PostState
}

const PostContents = (props: Props) => {
  return (
    <PostMenu state={props.postState} collapsable={false} oneShot={true} />
  );
}

export default PostContents;
