import { link } from 'autolinker';
import * as sanitize from 'sanitize-html';

export function safeLink(text: string): string {
  return link(
    sanitize(text, {
      allowedAttributes: {
        div: ['class'],
        a: [ 'href', 'name', 'target' ],
        img: ['src']
      }
    }),
  { className: 'theme__default-link' });
}
