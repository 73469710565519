import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import { State, DispatchProps } from 'Store';
import { defaultConnect } from 'Shared/ReduxComponent';

import { ActionCreators } from 'Challenges/ActionCreator';
import { ActionCreators as UserAC, SEL as UserSEL } from 'User';
import * as Urls from 'Shared/Urls';
import noop from 'Shared/Noop';
import * as Dialog from 'Shared/Dialog';
import * as UUID from 'Shared/Data/UUID';
import Spinner from 'Shared/UI/Spinner';
import { NavBarLayout } from 'Nav';
import { HUD } from 'HUD';
import {
  LENSES, SEL, ChallengeDetails, ChallengeId, Team, TeamId, Player
} from 'Challenges/Data';

import GraphicHeader from 'Challenges/UI/GraphicHeader';
import Leaderboard from 'Challenges/UI/Leaderboard';
import SecondaryButton from 'Challenges/UI/SecondaryButton';

type Props = State & RouteComponentProps<URLParams> & DispatchProps;

interface URLParams {
  challengeId: string
  teamId: string
}

const CAPTAIN_LEAVE_CONFIRMATION_MESSAGE =
  'You created this team. If you leave this team it will be removed ' +
  'completely from this challenge. This action can’t be undone. Anyone ' +
  'currently on this team besides you will NOT be automatically notified ' +
  '(so you might want to do that if anyone besides yourself is on this team!).';

const NON_CAPTAIN_LEAVE_CONFIRMATION_MESSAGE =
  'Are you sure you want to leave this team?';

class ShowTeam extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(UserAC.loadCurrentUser());
    this.loadChallengeAndTeam();
  }

  loadChallengeAndTeam() {
    this.props.dispatch(ActionCreators.loadChallenge(this.challengeId()));
    this.props.dispatch(
      ActionCreators.loadTeamWithPlayers(this.challengeId(), this.teamId())
    ).catch(() => this.gotoChallengePage());
  }

  gotoChallengePage() {
    this.props.dispatch(push(Urls.challengeUrl(this.challengeId())));
  }

  challengeId(): ChallengeId {
    return parseInt(this.props.match.params.challengeId);
  }

  teamId(): TeamId {
    return parseInt(this.props.match.params.teamId);
  }

  challengeDetails(): ChallengeDetails | undefined {
    return LENSES.challenge(this.challengeId()).get(this.props.challenges);
  }

  team(): Team | undefined {
    return SEL.team(this.challengeId(), this.teamId(), this.props.challenges);
  }

  teamPlayers(): Player[] | undefined {
    return SEL.teamPlayers(this.teamId(), this.props.challenges);
  }

  myTeamId(): TeamId | undefined {
    const details = this.challengeDetails();
    return details ? details.summary.myTeamId : undefined;
  }

  iAmOnATeam(): boolean {
    return this.myTeamId() !== undefined;
  }

  isMyTeam(): boolean {
    const myTeamId = this.myTeamId();
    if (myTeamId === undefined) {
      return false;
    } else {
      return myTeamId === this.teamId();
    }
  }

  isCaptain(): boolean {
    const currentUserId = UserSEL.userId(this.props.currentUser);
    const team = this.team();
    return team !== undefined &&
           currentUserId !== undefined &&
           UUID.equal(team.captainId, currentUserId);
  }

  joinTeam = () => {
    const msg = 'Are you sure you want to join this team?';
    Dialog.confirm(msg).then(
      () => this.props.dispatch(ActionCreators.joinTeam(this.teamId()))
    ).then(() => this.loadChallengeAndTeam());
  }

  leaveTeam = () => {
    const msg =
      this.isCaptain() ?
      CAPTAIN_LEAVE_CONFIRMATION_MESSAGE :
      NON_CAPTAIN_LEAVE_CONFIRMATION_MESSAGE;
    Dialog.confirm(msg).then(
      () => this.props.dispatch(ActionCreators.leaveTeam(this.teamId()))
    ).then(
      () => this.gotoChallengePage()
    ).catch(noop);
  }

  render() {
    const details = this.challengeDetails();
    const team = this.team();
    const players = this.teamPlayers();
    return (
      <NavBarLayout
        title="Challenge"
        initBreadcrumb={Urls.challengeUrl(this.challengeId())}>
        <HUD state={this.props.hud} />
        <div className="simple-container">
          {details && team && players?
           this.renderContent(details, team, players) :
           <Spinner wrap="center" />
          }
        </div>
      </NavBarLayout>
    );
  }

  renderContent(details: ChallengeDetails, team: Team, players: Player[]) {
    return (
      <div>
        <GraphicHeader challenge={details} />
        <h2 className="challenge-team__team-name theme__primary-color">
          <div className="challenge-team__team-name-label">Team</div>
          {team.name}
        </h2>
        <Leaderboard
          mode="players"
          challenge={details}
          players={players}
          hideLinkToFull={true} />
        {this.renderJoinTeamButton()}
        {this.renderLeaveButton()}
        {this.renderEditTeamButton()}
      </div>
    );
  }

  renderJoinTeamButton() {
    if (!this.iAmOnATeam()) {
      return (
        <SecondaryButton label="join this team" action={this.joinTeam} />
      );
    }
  }

  renderLeaveButton() {
    if (this.isMyTeam()) {
      return (
        <SecondaryButton label="leave this team" action={this.leaveTeam} />
      );
    }
  }

  renderEditTeamButton() {
    if (this.isCaptain()) {
      return (
        <SecondaryButton
          label="edit team name / get invite link"
          action={Urls.challengeEditTeamUrl(this.challengeId(), this.teamId())}
        />
      );
    }
  }
}


export default withRouter(defaultConnect(ShowTeam));
