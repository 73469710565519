import * as React from 'react';
import * as moment from 'moment-timezone';
import * as R from 'ramda';
import { TimeRange } from 'Shared/Data/Range';
import {
  ApptType, AvailableSlots, Slot, nextPageDates, previousPageDates, slotLabel
} from 'Appt/Data';

import Pager from 'Shared/UI/Pager';
import Link from 'Shared/UI/Link';

type SelectSlotFn = (s: Slot) => void
interface Props {
  apptType: ApptType,
  availableSlots: AvailableSlots,
  gotoPage: (dates: TimeRange) => void,
  selectSlot: SelectSlotFn,
  userZoneId: string
}

const SlotSelector = (props: Props) => {
  const { slots, dates } = props.availableSlots;

  const grouped = R.groupBy(
    s => formatDay(s.startTime.clone().startOf('day')),
    slots
  );

  return (
    <div>
      {R.keys(grouped).map(
        date => renderSlotDateGroup(
          props.selectSlot, date.toString(), grouped[date], props.userZoneId
        )
       )}
      <SlotSelectorPaging
        apptType={props.apptType} dates={dates} gotoPage={props.gotoPage}/>
    </div>
  );
};

export default SlotSelector;

function formatDay(day: moment.Moment): string {
  return day.format('dddd, MMMM D');
}

const renderSlotDateGroup = (
  selectSlot: SelectSlotFn, date: string, slots: Slot[], userZoneId: string
) => {
  return (
    <div key={date}>
      <h4 className="section-title text-center">{date}</h4>
      <ul className="appt__slot-list">
        {slots.map(renderSlotChoice(selectSlot, userZoneId))}
      </ul>
    </div>
  );
};

const renderSlotChoice = (selectSlot: (s: Slot) => void, userZoneId: string) => (slot: Slot) => {
  const classes =
    "appt__slot-button theme__primary-color theme__border-color--dark5";

  return (
    <li key={slot.startTime.toString()}>
      <Link className={classes} to={() => selectSlot(slot)}>
        {slotLabel(slot, userZoneId)}
      </Link>
    </li>
  );
}

interface PagingProps {
  apptType: ApptType,
  dates: TimeRange,
  gotoPage: (dates: TimeRange) => void
}

const SlotSelectorPaging = (props: PagingProps) => {
  const nextDates = nextPageDates(props.apptType, props.dates);
  const prevDates = previousPageDates(props.apptType, props.dates);
  return (
    <Pager
      nextPage={nextDates}
      nextLabel={pagingLabel(nextDates)}
      previousPage={prevDates}
      previousLabel={pagingLabel(prevDates)}
      gotoPage={props.gotoPage}
    />
  );
}


function pagingLabel(dateRange: TimeRange | undefined) {
  if (dateRange) {
    const { start, end } = dateRange;
    return `${start.format('MMM D')} - ${end.format('MMM D')}`;
  }
}
