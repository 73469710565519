import * as R from 'ramda';
import { CurrentUser } from 'User/Data';
import { Action, ActionType } from './Action';
import { loadable } from 'misc/Data/Loadable';

export function reducer(currentUser: CurrentUser, action: Action): CurrentUser {
  switch(action.type) {
  case ActionType.REMOVE_FROM_CREW__SUCCESS:
    if (currentUser.crew) {
      return {
          ...currentUser,
        crew: R.without([action.crewMember], currentUser.crew)
      };
    } else {
      return currentUser;
    }
  case ActionType.LOAD_CREW_INVITATIONS__SUCCESS:
    return { ...currentUser, crewInvites: loadable(action.invites) };
  }
}
