import { push } from 'connected-react-router';
import { ActionCreatorThunk } from 'Store';
import { threadInfoFromJSON, threadFromJSON } from './JSON';
import { pagingFromJSON } from 'Shared/Paging';
import * as Urls from 'Shared/Urls';
import { ActionCreators as HUD } from 'HUD';
import { ActionType, withNS } from './Action';
import { UserId } from 'User';
import { ThreadId, ThreadType } from './Data';

export interface ActionCreators {
  loadThreadList(page: number): ActionCreatorThunk
  loadThread(threadId: ThreadId): ActionCreatorThunk
  createThread(
    recipientType: ThreadType, recipientId: UserId, message: string
  ): ActionCreatorThunk

  sendMessage(
    threadId: ThreadId, message: string, callbacks?: { onSuccess?: () => void }
  ): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  loadThreadList,
  loadThread,
  createThread,
  sendMessage
};


function loadThreadList(page: number): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.inbox.getInboxThreads(page).then(result => {
      const threadInfos = result.threads.map(threadInfoFromJSON);
      const paging = pagingFromJSON(result.paging);
      dispatch(withNS({
        type: ActionType.LOAD_THREAD_LIST__SUCCESS,
        threadInfos,
        paging
      }));
      dispatch(HUD.close(0));
    });
  };
}

function loadThread(threadId: ThreadId): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    return api.inbox.getThread(threadId).then(result => {
      const thread = threadFromJSON(result.thread);
      dispatch(withNS({
        type: ActionType.LOAD_THREAD__SUCCESS,
        thread
      }));
    });
  };
}

function createThread(
  type: ThreadType, recipientId: UserId, message: string
): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    return api.inbox.createThread(type, recipientId, message).then(result => {
      const thread = threadFromJSON(result.thread);
      dispatch(withNS({
        type: ActionType.CREATE_THREAD__SUCCESS,
        thread
      }));
      dispatch(push(Urls.inboxThreadUrl(thread.info.id)));
    });
  };
}

function sendMessage(
  threadId: ThreadId, message: string,
  cbs?: { onSuccess?: () => void }
): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    return api.inbox.sendMessageInThread(threadId, message).then(() => {
      dispatch(loadThread(threadId));
      if (cbs && cbs.onSuccess) { cbs.onSuccess(); }
    });
  };
}
