import { GET, ApiClient } from 'Api';

import {
  TrendDataResponseJSON, TrendMetricsResponseJSON, MetricKeyJSON, PeriodJSON
} from './JSON/Trends';

export interface TrendsAPI {
  getAvailableMetrics(userId: string): Promise<TrendMetricsResponseJSON>

  getTrendData(
    userId: string, metricKey: MetricKeyJSON, period: PeriodJSON
  ): Promise<TrendDataResponseJSON>
}

export function makeApi(client: ApiClient): TrendsAPI {
  return {
    getAvailableMetrics,
    getTrendData
  };

  function getAvailableMetrics(
    userId: string
  ): Promise<TrendMetricsResponseJSON> {
    return client.requestJSON(
      GET,
      {
        version: 3,
        path: `/user_trends/${userId}/metrics`
      }
    );
  }

  function getTrendData(
    userId: string, metricKey: MetricKeyJSON, period: PeriodJSON
  ): Promise<TrendDataResponseJSON> {
    return client.requestJSON(
      GET, {
        version: 3,
        path: `/user_trends/${userId}/data`,
        query: {
          metric: metricKey,
          period
        }
      }
    );
  }
}
