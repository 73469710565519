export interface SettingsStore {
  getSlug(): string | undefined
  setSlug(slug: string): void
}

const SLUG_KEY = 'auth:program-slug';

const store: SettingsStore = {
  getSlug() {
    return window.localStorage.getItem(SLUG_KEY) || undefined;
  },

  setSlug(slug: string) {
    window.localStorage.setItem(SLUG_KEY, slug);
  }
}

export default store;
