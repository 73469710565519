export * from './Data';
export { NSAction, Action, ActionType } from './Action';
export { ActionCreators } from './ActionCreator';
export { reducer } from './Store';

import ActivityUI from './UI/Activity';
import ActivityListUI from './UI/ActivityList';
import BiopostUI from './UI/Biopost';
import LastBiopost from './UI/LastBiopost';

export const UI = {
  Activity: ActivityUI,
  ActivityList: ActivityListUI,
  Biopost: BiopostUI,
  LastBiopost
};

import PostPage from './Page/Post';
import MyActivityFeed from './Page/MyActivityFeed';
import CrewActivityFeed from './Page/CrewActivityFeed';
import UserActivityFeed from './Page/UserActivityFeed';

export const Pages = {
  Post: PostPage,
  MyActivityFeed,
  CrewActivityFeed,
  UserActivityFeed
};

