export { reducer } from './Store';
export * from './Data';
export * from './Action';

import OnboardStep from './Page/OnboardStep';
import OnboardComplete from './Page/OnboardComplete';

export const Pages = {
  OnboardStep, OnboardComplete
};
