export * from './Data'
export { ActionCreators } from './ActionCreator';
export * from './Action';
export { reducer } from './Store';

import ApptRoot from './Page/ApptRoot';
import NewAppt from './Page/NewAppt';
import ApptDetails from './Page/ApptDetails';

export const Pages = {
  ApptRoot, NewAppt, ApptDetails
};
