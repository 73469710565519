import { State, pushBreadcrumb, popBreadcrumb, clearBreadcrumbs } from './Data';
import { Action, ActionType } from './Action';

export function reducer(state: State, action: Action): State {
  switch(action.type) {
  case ActionType.OPEN_NAV_MENU:
    return { ...state, open: true };
  case ActionType.CLOSE_NAV_MENU:
    return { ...state, open: false };
  case ActionType.SAVE_BREADCRUMB:
    return pushBreadcrumb(action.breadcrumb, state);
  case ActionType.POP_BREADCRUMB:
    return popBreadcrumb(state);
  case ActionType.CLEAR_BREADCRUMBS:
    return clearBreadcrumbs(state);
  }
}
