import * as React from 'react';
import { State as AppState } from 'App/Data';
import { StepKey } from 'Onboard/Data';
import EdgeContents from './StepContents/Edge';
import PostContents from './StepContents/Post';
import SpecsContents from './StepContents/Specs';
import GoalsContents from './StepContents/Goals';
import CareTasksContents from './StepContents/CareTasks';
import InboxContents from './StepContents/Inbox';

interface Props {
  stepKey: StepKey
  appState: AppState
}

const StepContents: React.SFC<Props> = (props: Props) => {
  switch(props.stepKey) {
    case 'edge':
      return <EdgeContents />;
    case 'post':
      return <PostContents postState={props.appState.post} />;
    case 'specs':
      return <SpecsContents />;
    case 'goals':
      return <GoalsContents />;
    case 'careTasks':
      return <CareTasksContents />;
    case 'inbox':
      return <InboxContents/>;
  }
}

export default StepContents;
