import * as React from 'react';

import { ActiveGoal } from 'Goals/Data';
import NoContent from 'misc/UI/NoContent';
import ListItem from './ActiveGoalListItem';

interface Props {
  activeGoals: ActiveGoal[]
  isMe: boolean
}

export default function renderActiveGoalList(
  props: Props
): React.ReactElement<Props> {
  return (
    <div>
      <h4 className="section-title">Active</h4>
      {props.activeGoals.map(ag => renderActiveGoalTile(ag, props.isMe))}
      {props.activeGoals.length === 0 && renderNoGoals()}
    </div>
  );
}

function renderActiveGoalTile(
  activeGoal: ActiveGoal, isMe: boolean
): React.ReactNode {
  return (
    <ListItem key={activeGoal.goal.id} activeGoal={activeGoal} isMe={isMe} />
  );
}

function renderNoGoals() {
  return (
    <NoContent message="no goals to display" />
  );
}
