import { Action, ActionType } from './Action';
import { State } from './Data';

export function reducer(state: State, action: Action): State {
  switch(action.type) {
  case ActionType.GO_OFFLINE:
    return {
        ...state, isOffline: true, previousLocation: action.currentLocation
    };
  case ActionType.GO_ONLINE:
    return { ...state, isOffline: false, previousLocation: undefined };
  }
}
