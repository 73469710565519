import * as moment from 'moment-timezone';

import {
  ChallengeInvitationJSON,
  ChallengeSummaryJSON,
  ChallengeDetailsJSON,
  ChallengeTemplateJSON,
  ChallengeProgressJSON,
  ChallengeMetricJSON,
  DeltaTypeJSON,
  TaskCompletionFrequencyJSON,
  PlayerJSON,
  ActionDetailsJSON,
  TaskChallengeDaySummaryJSON,
  TeamJSON,
  TeamPlayerJSON,
  isStepProgressJSON,
  isHabitProgressJSON,
  isSpecProgressJSON,
  isTaskProgressJSON,
  isTaskActionDetailsJSON
} from 'Api/JSON/Challenges';

import {
  ChallengeInvitation,
  ChallengeSummary,
  ChallengeTemplate,
  ChallengeProgress,
  ChallengeDetails,
  ChallengeMetric,
  ActionDetails,
  MetricType,
  DeltaType,
  TaskCompletionFrequency,
  Player,
  TaskChallengeDaySummary,
  Team,
  TeamPlayer
} from 'Challenges/Data';

import { specTypeFromJSON } from 'Specs';

export function challengeInvitation(
  json: ChallengeInvitationJSON
): ChallengeInvitation {
  return {
    id: json.id,
    challengeId: parseInt(json.challenge_id),
    challengeName: json.challenge_name,
    metric: challengeMetric(json.metric),
    startDate: date(json.start_date),
    endDate: date(json.end_date),
    graphicUrl: json.graphic_url
  };
}

export function challengeSummary(
  json: ChallengeSummaryJSON
): ChallengeSummary {
  return {
    id: parseInt(json.id),
    name: json.name,
    graphicUrl: json.graphic_url,
    metric: challengeMetric(json.metric),
    progress: json.progress ? challengeProgress(json.progress) : undefined,
    startDate: date(json.start_date, json.tz_id).startOf('day'),
    endDate: date(json.end_date, json.tz_id).endOf('day'),
    joinStartDate: date(json.join_start_date, json.tz_id).startOf('day'),
    joinEndDate: date(json.join_end_date, json.tz_id).endOf('day'),
    tzId: json.tz_id,
    playerCount: json.player_count,
    myTeamId: json.my_team_id,
    leaderboardPrivacy: json.leaderboard_privacy,
    challengeStepCount: json.challenge_step_count
  };
}

export function challengeTemplate(
  json: ChallengeTemplateJSON
): ChallengeTemplate {
  return {
    id: json.id,
    name: json.name,
    graphicUrl: json.graphic_url,
    metric: challengeMetric(json.metric),
    description: json.time_period_description,
    startDateRightNow: date(json.start_date),
    endDateRightNow: date(json.end_date)
  };
}

export function challengeDetails(
  json: ChallengeDetailsJSON
): ChallengeDetails {
  return {
    reloading: false,
    summary: challengeSummary(json.summary),
    actionDetails: actionDetails(json.action_details),
    topPlayers: json.top_players.map(player),
    milestones: json.milestones
  };
}

function actionDetails(json: ActionDetailsJSON): ActionDetails {
  if (isTaskActionDetailsJSON(json)) {
    return {
      task: {
        categorizedTasks: json.task.categorized_tasks,
        taskPoints: json.task.task_points
      }
    };
  } else {
    return json;
  }
}

export function player(
  json: PlayerJSON
): Player {
  return {
    rank: json.rank,
    userId: json.user_id,
    userName: json.user_name,
    progress: challengeProgress(json.progress)
  };
}

/** Helper json functions **/

function challengeMetric(
  json: ChallengeMetricJSON
): ChallengeMetric {
  switch(json.type) {
  case 'Steps':
    return { type: MetricType.Steps };
  case 'Habit':
    return { type: MetricType.Habit };
  case 'Spec':
    return {
      type: MetricType.Spec,
      specType: specTypeFromJSON(json.spec_type),
      deltaType: deltaTypeFromJSON(json.delta_type)
    };
  case 'Task':
    return {
      type: MetricType.Task,
      completionFrequency: taskCompletionFrequencyFromJSON(
        json.completion_frequency
      )
    };
  }
}

function deltaTypeFromJSON(json: DeltaTypeJSON): DeltaType {
  switch(json) {
  case 'Absolute':
    return DeltaType.ABSOLUTE;
  case 'Relative':
    return DeltaType.RELATIVE;
  }
}

function taskCompletionFrequencyFromJSON(
  json: TaskCompletionFrequencyJSON
): TaskCompletionFrequency {
  switch(json) {
    case 'Once':
      return TaskCompletionFrequency.ONCE;
    case 'Daily':
      return TaskCompletionFrequency.DAILY;
  }
}

function challengeProgress(
  json: ChallengeProgressJSON
): ChallengeProgress {
  if (isStepProgressJSON(json)) {
    return {
      stepCount: json.step_count,
      source: json.source || 'self_report',
      sourceIcon: json.source_icon
    };
  } else if (isHabitProgressJSON(json)) {
    return {
      postCount: json.post_count,
      lastPostTime: json.last_post_time ?
        time(json.last_post_time) :
        undefined
    };
  } else if (isSpecProgressJSON(json)) {
    return {
      delta: json.delta,
      currentValue: json.current_value,
      lastPostTime: json.last_post_time ?
        time(json.last_post_time) :
        undefined
    };
  } else if (isTaskProgressJSON(json)) {
    return {
      totalPoints: json.total_points,
      dailyPoints: json.daily_points
    }
  } else {
    throw new Error(
      `unhandled challenge progress JSON: ${JSON.stringify(json)}`
    );
  }
}

const DATE_FORMAT = 'YYYY-MM-DD';
function date(json: string, tzId?: string): moment.Moment {
  if (tzId) {
    return moment.tz(json, DATE_FORMAT, true, tzId);
  } else {
    return moment(json, DATE_FORMAT, true);
  }
}

function time(json: string, tzId?: string): moment.Moment {
  if (tzId) {
    return moment.tz(json, tzId);
  } else {
    return moment(json);
  }
}

export function taskChallengeDaySummaryFromJSON(
  json: TaskChallengeDaySummaryJSON
): TaskChallengeDaySummary {
  return {
    date: date(json.date, json.tz_id),
    categoryPoints: json.category_points
  };
}

export function teamFromJSON(json: TeamJSON): Team {
  return {
    id: json.id,
    name: json.name,
    captainId: json.captain_id
  };
}

export function teamPlayerFromJSON(json: TeamPlayerJSON): TeamPlayer {
  return {
    rank: json.rank,
    team: teamFromJSON(json.team),
    progress: challengeProgress(json.progress)
  };
}
