export type NSType = 'TASKS';
export const NS: NSType = 'TASKS';

export type TaskId = number
export type TaskCategoryId = number

export interface TaskCategory {
  id: TaskCategoryId
  name: string
}

export interface Task {
  id: TaskId
  name: string
}

export interface CategoryWithTasks {
  category: TaskCategory
  tasks: Task[]
}


export function isCategoryWithTasks(
  item: Task | CategoryWithTasks
): item is CategoryWithTasks {
  return (item as CategoryWithTasks).category !== undefined;
}

export function isTask(
  item: Task | CategoryWithTasks
): item is Task {
  return (item as Task).name !== undefined;
}
