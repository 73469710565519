import * as React from 'react';
import { bem } from 'css-util';
import { validEmail } from 'Shared/Data/Email';
import * as Maybe from 'misc/Data/Maybe';

import AuthFormTextField from 'Auth/UI/AuthFormTextField';
import AuthFormRememberMe from 'Auth/UI/AuthFormRememberMe';
import AuthFormSubmit from 'Auth/UI/AuthFormSubmit';
import AuthFormErrors from 'Auth/UI/AuthFormErrors';

import { LoginSubmission } from 'Auth/Data';

interface Props {
  onLogin: (login: LoginSubmission) => Promise<Maybe.Maybe<string>>
}

type State = LoginSubmission & { error?: string };

const CSS = bem('auth-form');

class LoginForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { email: '', password: '', rememberMe: true };
  }

  onEmailChange = (email: string) => this.setState({ email })

  onPasswordChange = (password: string) => this.setState({ password })

  onRememberMeChange = (rememberMe: boolean) => this.setState({ rememberMe });

  isValid(): boolean {
    return this.state.email !== '' &&
           this.state.password !== '' &&
           validEmail(this.state.email);
  }

  onSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();
    this.setState({ error: undefined });
    if (this.isValid()) {
      this.props.onLogin(this.state).then(
        Maybe.exec(error => this.setState({ error }))
      );
    } else {
      this.setState({ error: 'Please enter an email and password' });
    }
  }

  render() {
    return (
      <form className={CSS()()} onSubmit={this.onSubmit}>
        <AuthFormErrors error={this.state.error} />

        <AuthFormTextField
          type="email"
          icon="email4"
          placeholder="email"
          value={this.state.email}
          onChange={this.onEmailChange} />
        <AuthFormTextField
          type="password"
          icon="lock"
          placeholder="password"
          value={this.state.password}
          onChange={this.onPasswordChange} />
        <AuthFormRememberMe
          checked={this.state.rememberMe}
          onChange={this.onRememberMeChange} />
        <AuthFormSubmit>Login</AuthFormSubmit>
      </form>
    );
  }
}

export default LoginForm;
