import {
  State, LENSES, nextStepIndex, previousStepIndex, addErrorsOnCurrentStep,
  screeningIsDone, currentStepFields, isArrayType, toggleValueInArrayFieldValue
} from './Data';
import { Action, ActionType } from './Action';
import { idMapInsert } from 'Shared/Data/IDMap';
import { loadable } from 'misc/Data/Loadable';

export function reducer(state: State, action: Action): State {
  switch(action.type) {

  case ActionType.LOAD_REPORT_LIST__SUCCESS:
    return { ...state, reportList: action.reports };

  case ActionType.LOAD_REPORT__SUCCESS:
    return {
        ...state,
      reports: idMapInsert(action.recordId, action.report, state.reports)
    };

  case ActionType.LOAD_SCREEN__BEGIN:
    return { ...state, currentStepIndex: 0, thankYouContent: '' };

  case ActionType.LOAD_SCREEN__SUCCESS:
    return {
        ...state,
      screen: loadable(action.screen)
    };

  case ActionType.VALIDATE_CURRENT_STEP:
    return addErrorsOnCurrentStep(state);
  case ActionType.GOTO_NEXT_STEP:
    return gotoNextStep(state);

  case ActionType.GOTO_PREVIOUS_STEP:
    return gotoPreviousStep(state);

  case ActionType.SET_FIELD_VALUE:
    const fieldValueLens = LENSES.fieldValue(action.field.key);
    if (isArrayType(action.field.type)) {
      // Lens library doesn't run "update" if value is currently undefined, so
      // we have to initialize it to empty array first
      if (fieldValueLens.get(state) === undefined) {
        state = fieldValueLens.set(state, [])
      }
      return fieldValueLens.update(
        state,
        currentValue => toggleValueInArrayFieldValue(currentValue, action.value)
      );
    } else {
      return fieldValueLens.set(state, action.value);
    }
  case ActionType.SET_THANK_YOU_CONTENT:
    return { ...state, thankYouContent: action.html };
  default:
    return state;
  }
}


function gotoNextStep(state: State): State {
  const nextState = gotoStep(state, nextStepIndex(state));
  if (screeningIsDone(nextState) || currentStepFields(nextState).length > 0) {
    return nextState;
  } else {
    return gotoNextStep(nextState);
  }
}

function gotoPreviousStep(state: State): State {
  const prevState = gotoStep(state, previousStepIndex(state));
  if (currentStepFields(prevState).length > 0) {
    return prevState;
  } else {
    return gotoPreviousStep(prevState);
  }
}

function gotoStep(state: State, step: number): State {
  return { ...state, currentStepIndex: step };
}
