import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { defaultConnect } from 'Shared/ReduxComponent';

import { challengeUrl, challengeTemplateUrl } from 'Shared/Urls';
import { State, StoreDispatch } from 'Store';
import { SEL } from 'Challenges/Data';
import { ActionCreators } from 'Challenges/ActionCreator';

import { NavBarLayout } from 'Nav';

type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch
}

type URLParams = {
  type: 'challenge' | 'template',
  id: string
}

class Rules extends React.Component<Props, {}> {
  componentDidMount() {
    const { type, id } = this.urlParams();
    switch(type) {
      case 'challenge':
        return this.props.dispatch(
          ActionCreators.loadChallengeRules(parseInt(id)));
      case 'template':
        return this.props.dispatch(ActionCreators.loadTemplateRules(id));
    }
  }

  urlParams(): URLParams {
    return this.props.match.params;
  }

  rulesHtml(): string {
    const { type, id } = this.urlParams();
    return SEL.getRules(type, id, this.props.challenges) || '';
  }

  goBackUrl(): string {
    const { type, id } = this.urlParams();
    if (type === 'challenge') {
      return challengeUrl(parseInt(id));
    } else {
      return challengeTemplateUrl(id);
    }
  }

  render() {
    return (
      <NavBarLayout title="Challenge Rules" initBreadcrumb={this.goBackUrl()}>
        <div className="simple-container">
          <div dangerouslySetInnerHTML={{ __html: this.rulesHtml() }} />
        </div>
      </NavBarLayout>
    );
  }
}

export default defaultConnect(Rules);
