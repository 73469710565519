import './Logo.scss';
import * as React from 'react';
import * as R from 'ramda';
import * as classnames from 'classnames';

type Modifier = 'small';

type Variant = 'default' | 'text';

interface Props {
  modifier?: Modifier
  modifiers?: Modifier[]
  variant?: Variant,
  className?: string
}

export default function renderLogo(
  props: Props
): React.ReactElement<Props> {
  const modifiers = resolveModifiers(props);
  const classes = classnames(
    'logo ' + modifiers.map(v => `logo--${v}`).join(' '),
    props.className
  );
  const variant: Variant = props.variant || 'default';
  switch(variant) {
    case 'default':
      return renderDefaultLogo(classes);
    case 'text':
      return renderTextLogo(classes);
  }
}

function renderDefaultLogo(classNames: string): React.ReactElement<Props> {
  return (
    <svg className={classNames}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 74.4 74.4"
      enableBackground="new 0 0 74.4 74.4"
      role="img">
      <title>1bios</title>
      <desc>1bios logo</desc>
      <path className="darkest" d="M37.2 7.4C53.6 7.4 67 20.8 67 37.2S53.6 67 37.2 67S7.4 53.6 7.4 37.2S20.8 7.4 37.2 7.4 M37.2 0 C16.7 0 0 16.7 0 37.2c0 20.6 16.7 37.2 37.2 37.2s37.2-16.7 37.2-37.2C74.4 16.7 57.8 0 37.2 0L37.2 0z"/>
      <path className="lightest" d="M37.2 16.5c11.4 0 20.7 9.3 20.7 20.7s-9.3 20.7-20.7 20.7s-20.7-9.3-20.7-20.7S25.8 16.5 37.2 16.5 M37.2 9 C21.6 9 9 21.6 9 37.2c0 15.6 12.6 28.2 28.2 28.2s28.2-12.6 28.2-28.2C65.4 21.6 52.8 9 37.2 9L37.2 9z"/>
      <path className="lightest" d="M37.2 25.5c3.1 0 6.1 1.2 8.3 3.4c4.6 4.6 4.6 12 0 16.6c-2.2 2.2-5.2 3.4-8.3 3.4c-3.1 0-6.1-1.2-8.3-3.4 c-4.6-4.6-4.6-12 0-16.6C31.1 26.7 34.1 25.5 37.2 25.5 M37.2 18.1c-4.9 0-9.8 1.9-13.5 5.6c-7.5 7.5-7.5 19.6 0 27.1 c3.7 3.7 8.6 5.6 13.5 5.6c4.9 0 9.8-1.9 13.5-5.6c7.5-7.5 7.5-19.6 0-27.1C47 19.9 42.1 18.1 37.2 18.1L37.2 18.1z"/>
      <path className="dark" d="M28.9 45.5c-4.6-4.6-4.6-12 0-16.6s12-4.6 16.6 0l5.3-5.3c-7.5-7.5-19.6-7.5-27.1 0s-7.5 19.6 0 27.1 L28.9 45.5z"/>
      <path className="light" d="M22.6 51.9c-3.8-3.8-6.1-8.9-6.1-14.7c0-11.4 9.3-20.7 20.7-20.7V9C21.6 9 9 21.6 9 37.2 C9 45 12.2 52 17.3 57.1L22.6 51.9z"/>
    </svg>
  );
}

function renderTextLogo(classNames: string): React.ReactElement<Props> {
  return (
    <svg className={classNames}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 255.5 99.2"
      enableBackground="new 0 0 255.5 99.2"
      role="img"
      aria-label="1bios">
      <title>1bios</title>
      <desc>1bios logo</desc>

      <path fill="#D9E6AB" d="M63.5 50.3c3.1 0 6.1 1.2 8.3 3.4c4.6 4.6 4.6 12 0 16.6c-2.2 2.2-5.2 3.4-8.3 3.4c-3.1 0-6.1-1.2-8.3-3.4 c-2.2-2.2-3.4-5.2-3.4-8.3s1.2-6.1 3.4-8.3C57.5 51.5 60.4 50.3 63.5 50.3 M63.5 42.9c-4.9 0-9.8 1.9-13.5 5.6 c-7.5 7.5-7.5 19.6 0 27.1c3.7 3.7 8.6 5.6 13.5 5.6s9.8-1.9 13.5-5.6c7.5-7.5 7.5-19.6 0-27.1C73.3 44.7 68.4 42.9 63.5 42.9 L63.5 42.9z"></path>
      <path fill="#AFCE57" d="M55.3 70.3c-4.6-4.6-4.6-12 0-16.6s12-4.6 16.6 0l5.3-5.3C69.6 41 57.5 41 50 48.5s-7.5 19.6 0 27.1 L55.3 70.3z"></path>

      <path fill="#D9E6AB" d="M63.5 41.3c11.4 0 20.7 9.3 20.7 20.7S75 82.8 63.5 82.8S42.8 73.5 42.8 62S52.1 41.3 63.5 41.3 M63.5 33.8 C48 33.8 35.3 46.5 35.3 62c0 15.6 12.6 28.2 28.2 28.2S91.7 77.6 91.7 62C91.7 46.5 79.1 33.8 63.5 33.8L63.5 33.8z"></path>
      <path fill="#C5DA82" d="M48.9 76.7c-3.8-3.8-6.1-8.9-6.1-14.7c0-11.4 9.3-20.7 20.7-20.7v-7.4C48 33.8 35.3 46.5 35.3 62 c0 7.8 3.2 14.8 8.3 19.9L48.9 76.7z"></path>

      <path className="letter" fill="#97c21c" d="M110.7 25.4c-2.1 0-3.7 1.7-3.7 3.7v66.4c0 2.1 1.7 3.7 3.7 3.7s3.7-1.7 3.7-3.7V29.1 C114.4 27 112.7 25.4 110.7 25.4z"></path>
      <circle className="letter" fill="#97c21c" cx="110.7" cy="14.8" r="4.4"></circle>
      <path className="letter" fill="#97c21c" d="M19.6 3c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.1-0.1-0.2-0.1-0.3 c-0.3-0.4-0.6-0.8-1-1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1 0-0.2-0.1-0.4-0.1c-0.1 0-0.2-0.1-0.3-0.1 C16.5 0 16.2 0 16 0c0 0 0 0 0 0h0c-0.2 0-0.5 0-0.7 0.1c-0.1 0-0.2 0.1-0.3 0.1c-0.1 0-0.3 0.1-0.4 0.1c-0.1 0.1-0.2 0.1-0.4 0.2 c-0.1 0.1-0.2 0.1-0.3 0.2c-0.2 0.1-0.4 0.3-0.6 0.5L1.1 13.4c-1.5 1.5-1.5 3.8 0 5.3s3.8 1.5 5.3 0l5.9-5.9v82.8 c0 2.1 1.7 3.7 3.7 3.7s3.7-1.7 3.7-3.7V3.7c0 0 0 0 0 0C19.7 3.5 19.7 3.2 19.6 3z"></path>
      <path className="letter" fill="#97c21c" d="M63.5 24.8c-12.4 0-23.5 6.1-30.2 15.5V3.7c0-2.1-1.7-3.7-3.7-3.7s-3.7 1.7-3.7 3.7v91.8 c0 2.1 1.7 3.7 3.7 3.7s3.7-1.7 3.7-3.7V83.7c6.8 9.4 17.8 15.5 30.2 15.5c20.6 0 37.2-16.7 37.2-37.2S84.1 24.8 63.5 24.8z M63.5 91.8c-16.4 0-29.8-13.3-29.8-29.8c0-16.4 13.3-29.8 29.8-29.8c16.4 0 29.8 13.4 29.8 29.8C93.3 78.4 79.9 91.8 63.5 91.8z"></path>
      <path className="letter" fill="#97c21c" d="M250.2 64.9c-5.3-5.6-14.3-7.1-22.9-8.6c-12.7-2.2-18.7-3.9-18.7-10.1c0-5 1.9-8.4 5.8-10.6 c8.9-4.9 25.4-1.7 31 1.8c1.7 1.1 4 0.6 5.1-1.1c1.1-1.7 0.6-4-1.1-5.1c-6.7-4.2-26.3-8.8-38.6-2c-6.3 3.5-9.7 9.4-9.7 17.1 c0 13.2 13.8 15.5 24.9 17.4c7.5 1.3 15.2 2.6 18.8 6.4c2.8 3 3.3 6.2 3.2 8.4c-0.1 3.4-1.6 6.2-3.1 7.5 c-14.9 13.4-37.8-0.8-38.1-1c-1.7-1.1-4-0.6-5.1 1.1c-1.1 1.7-0.6 4 1.1 5.1c0.8 0.5 12.8 8 26.3 8c6.9 0 14.3-2 20.7-7.8 c3.2-2.9 5.3-7.8 5.5-12.8C255.6 75 254.8 69.7 250.2 64.9z"></path>
      <path className="letter" fill="#97c21c" d="M157.8 24.8c-20.6 0-37.2 16.7-37.2 37.2s16.7 37.2 37.2 37.2c20.6 0 37.2-16.7 37.2-37.2 S178.4 24.8 157.8 24.8z M157.8 91.8c-16.4 0-29.8-13.3-29.8-29.8c0-16.4 13.4-29.8 29.8-29.8c16.4 0 29.8 13.4 29.8 29.8 C187.6 78.4 174.2 91.8 157.8 91.8z"></path>
    </svg>
  );
}

function resolveModifiers(
  props: Props
): Modifier[] {
  const { modifier, modifiers } = props;
  if (modifier && modifiers) {
    return R.append(modifier, modifiers);
  } else if (modifier) {
    return [ modifier ];
  } else if (modifiers) {
    return modifiers;
  } else {
    return [];
  }
}
