import * as React from 'react';
import { CategoryInfo } from 'Post/Data';
import Icon from 'Shared/UI/Icon';
import { IconName } from 'Shared/UI/Icon';

interface Props {
  category: CategoryInfo,
  children?: React.ReactNode,
  initialOpen?: boolean,
  collapsable?: boolean
};

interface State {
  menuIsShown: boolean
}

class PostMenuCategory extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { menuIsShown: this.initialOpen() };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    if (this.collapsable()) {
      this.setState({ menuIsShown: !this.state.menuIsShown });
    }
  }

  initialOpen(): boolean {
    if (this.props.initialOpen === undefined) {
      return !this.collapsable();
    } else {
      return this.props.initialOpen;
    }
  }

  collapsable(): boolean {
    if (this.props.collapsable === undefined) {
      return true;
    } else {
      return this.props.collapsable;
    }
  }

  render() {
    const props = this.props;
    return (
      <div className="post-menu-category">
        <div onClick={this.toggleMenu}
          className="post-menu-category__category-toggle"
        >
          <div className="post-menu-category__icon">
            <Icon name={props.category.icon as IconName} />
          </div>
          <div className="post-menu-category__name">
            {props.category.name}
          </div>
          {this.collapsable() && this.renderCaret()}
        </div>
        {this.state.menuIsShown && props.children}
      </div>
    );
  }

  renderCaret() {
    const caret =
      this.state.menuIsShown ?
      <Icon name="caret-down" /> :
      <Icon name="caret-right" />;
    return (
      <div className="post-menu-category__caret">{caret}</div>
    );
  }

}

export default PostMenuCategory;
