import * as React from 'react';
import * as R from 'ramda';

import { HistoricalTableRow } from 'Screenings/Data';

interface Props {
  rows: HistoricalTableRow[]
}

type RowWithChange = HistoricalTableRow & {
  weightChangeFormatted?: string,
  heightChangeFormatted?: string
}

const HistoricalTable = (props: Props) => {
  if (allRowsZeroOrEmpty(props.rows)) {
    return <span></span>
  } else {
    return historicalTable(props);
  }
};

function nilOr0(row: HistoricalTableRow) {
  const equal0 = R.equals(0)
  const _nilOr0 = R.either(R.isNil, equal0)
  return _nilOr0(row.height) && _nilOr0(row.weight)
}

function allRowsZeroOrEmpty(list: HistoricalTableRow[]) {
  return R.all(nilOr0)(list)
}

function historicalTable(props: Props) {
  const rowsWithChange = calculateRowsWithChange(props.rows);

  return (
    <table className="table screen-field-table">
      <thead>
        <tr>
          <th></th>
          <th>Weight</th>
          <th>Height</th>
        </tr>
      </thead>
      <tbody>
        {rowsWithChange
          .filter(row => !nilOr0(row))
          .map(
           (row, i) => <TableRow row={row} index={i} key={i} />)}
      </tbody>
    </table>
  );
}

export default HistoricalTable;

interface RowProps {
  row: RowWithChange,
  index: number
}
const TableRow = (props: RowProps) => {
  const row = props.row;
  const rowClass =
    `screen-field-table__row--${props.index % 2 === 0 ? 'even' : 'odd'}`;
  return (
    <tr className={rowClass}>
      <td className="screen-field-table__date">
        {row.date.format('LL')}
      </td>
      <td>
        {row.weight} {row.weightChangeFormatted}
      </td>
      <td>
        {row.height} {row.heightChangeFormatted}
      </td>
    </tr>
  );
}

function calculateRowsWithChange(rows: HistoricalTableRow[]): RowWithChange[] {
  return rows.reduce<RowWithChange[]>((result, row, i) => {
    if (i < rows.length - 1) {
      const prev = rows[i + 1];
      result.push({
        ...row,
        weightChangeFormatted: formattedChange(row.weight, prev.weight),
        heightChangeFormatted: formattedChange(row.height, prev.height)
      });
    } else {
      result.push(row);
    }
    return result;
  }, []);
}

function formattedChange(
  current: number | undefined, previous: number | undefined
): string {
  if (current === undefined || previous === undefined || previous === 0) {
    return '';
  } else {
    const diff = current - previous;
    const percent = previous === 0 ? 0 : diff / previous;
    const sign = percent > 0 ? '+' : '';
    return `(${sign}${(percent * 100).toFixed(1)}%)`;
  }
}
/* function insertChange(obj, key, val, prevVal) {
 *   obj[key] = val - prevVal;
 *   const percent = obj[key] / prevVal;
 *   obj[key + 'Percent'] = percent;
 *   const sign = percent > 0 ? '+' : '';
 *   if (percent === Infinity) {
 *     obj[key + 'PercentFormatted'] = '';
 *   } else {
 *     obj[key + 'PercentFormatted'] =
 *       '(' + sign + roundTo(percent * 100, 1) + '%)';
 *   }
 * }
 * */
/* function roundTo(value, decimalPoints) {
 *   const a = Math.pow(10, decimalPoints);
 *   return Math.round(value * a) / a;
 * }*/
