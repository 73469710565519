import * as React from 'react';
import * as classnames from 'classnames';

import {
  RiskChart, RiskChartRange, RiskChartDatum
} from 'Screenings/Data';

interface Props {
  riskChart: RiskChart
}

const RiskChart = (props: Props) => {
  const chart = props.riskChart;
  return (
    <div className="risk-chart">
      <h3 className="risk-chart__title">
        {chart.title}
      </h3>

      <div className="risk-chart__right">
        {riskChartScale(chart.range)}
        {chart.data.map((d, i) => riskChartSlider(chart, d, i))}
      </div>
    </div>
  );
};

export default RiskChart;

const riskChartScale = (range: RiskChartRange) => {
  return (
    <div className="risk-chart__scale" key="scale">
      <div className="risk-chart__scale-label
                      risk-chart__scale-label--worst">
        {range.worstLabel}
      </div>
      <div className="risk-chart__scale-axis">
        <div className="risk-chart__scale-tick">
        </div>
        <div className="risk-chart__scale-tick">
        </div>
      </div>
      <div className="risk-chart__scale-label
                      risk-chart__scale-label--best">
        {range.bestLabel}
      </div>
    </div>
  );
}

const riskChartSlider = (chart: RiskChart, data: RiskChartDatum, i: number) => {
  let content;
  if (data.value) {
    const triangleClasses = classnames(
      'risk-slider__triangle',
      `risk-slider__triangle--${i % 2 === 0 ? 'even' : 'odd'}`
    );
    content = (
      <div className="risk-slider__data">
        <div className="risk-slider__gradient">
        </div>
        <div className="risk-slider__range">
          <div className={triangleClasses}
            style={triangleStyle(chart, data)}>
            <span className="risk-slider__triangle-value">
              {formatSliderValue(data.value)}
            </span>
          </div>
        </div>
      </div>
    );
  } else {
    content = (
      <div className="risk-slider__no-data">
        No data available
      </div>
    );
  }
  return (
    <div className="risk-slider" key={i}>
      <h5 className="risk-slider__title">
        {data.date.format('ll')}
      </h5>
      {content}
    </div>
  );
}

function formatSliderValue(value: number): string {
  return value.toFixed(1);
}

function triangleStyle(chart: RiskChart, datum: RiskChartDatum): {} {
  const range = chart.range;
  if (typeof(range.best) === 'number' &&
      typeof(range.worst) === 'number' &&
      typeof(datum.value) === 'number'
  ) {
    // Sliding scale calculation
    const length = Math.abs(range.best - range.worst);
    const dir = range.best > range.worst ? 1 : -1;
    /* let offset = dir * parseFloat(datum.value - range.worst);*/
    let offset = dir * (datum.value - range.worst);
    offset = Math.min(offset, length);
    offset = Math.max(offset, 0);
    return {
      left: Math.floor(100 * offset/length).toString() + '%'
    };
  } else {
    // For now, this assumes a boolean value.  Eventually this should probably
    // calculate offset based on a set of enum values
    const offset = datum.value === range.worst ? '0' : '100';
    return {
      left: offset + '%'
    };
  }
}
