import { PUBLIC_URL_BASE, URL_BASE } from 'Env';
import * as R from 'ramda';
import * as moment from 'moment-timezone';
// export function navigateTo(url: string): void {
//   // const hash = '#' + url.split('#')[1];
//   // window.location.hash = hash;
//   window.location.replace(url);
// }

/*
  ------------------------------------------------------------
  Rando
  ------------------------------------------------------------
*/

export function rootUrl(): string {
  return toUrl('/');
}
export const homeUrl = rootUrl;
export function dashboardUrl(): string {
  return toUrl('/dashboard');
}

type UserGuideSection
  = 'avatar'
  | ':section?';

export function userGuideUrl(section?: UserGuideSection): string {
  const base = '/user-guide/';
  return toUrl(section ? `${base}${section}` : base);
}

export function trendsUrl(
  userId: string,
  metricKey?: number | string,
  window?: 'week' | 'year' | 'month' | ':window?',
  date?: string | moment.Moment,
  sortKey?: string,
  sortDir?: string,
  page?: number,
  perPage?: number
): string {
  if (date) {
    date = typeof(date) === 'string' ? date : date.format('YYYY-MM-DD');
  }
  const parts = R.reject(
    R.isNil,
    ['trends', userId, metricKey, window, date]
  );
  let url = toUrl('/' + parts.join('/'));

  let queryParams: {[k: string]: any} = {};
  if (sortKey) { queryParams['sort_key'] = sortKey };
  if (sortDir) { queryParams['sort_dir'] = sortDir };
  if (page) { queryParams['page'] = page };
  if (perPage) { queryParams['perPage'] = perPage };

  if (R.isEmpty(queryParams)) {
    return url
  } else {
    return R.join('?', [url, qs(queryParams)])
  }
}

export function myTrendsUrl(): string {
  return '/my-trends';
}

export function timeSeriesUrl(): string {
  return '/time_series';
}

export function healthScoreWhatsThisUrl(): string {
  return toUrl('/what-is-health-score');
}

export function postUrl(): string {
  return toUrl('/post');
}

export function contactUrl(): string {
  return toUrl('/send-feedback');
}

export function maintenanceModeUrl(): string {
  return toUrl('/brb');
}

export function offlineModeUrl(): string {
  return toUrl('/offline');
}

export function forcedUpgradeUrl(): string {
  return toUrl('/please-upgrade');
}

/*
  ------------------------------------------------------------
  Onboarding
  ------------------------------------------------------------
*/

export function onboardingUrl(step?: number | string): string {
  step = step || 1;
  return toUrl(`/welcome/step/${step}`);
}
export function onboardingCompleteUrl(): string {
  return toUrl(`/welcome/done`);
}

/*
  ------------------------------------------------------------
  Feed url
  ------------------------------------------------------------
*/

export function myFeedUrl(): string {
  return toUrl('/feed/me');
}

export function crewFeedUrl(): string {
  return toUrl('/feed/crew');
}

export function userFeedUrl(userId: string): string {
  return toUrl(`/feed/user/${userId}`);
}

/*
  ------------------------------------------------------------
  Community
  ------------------------------------------------------------
*/

export function communityUrl(): string {
  return toUrl('/community');
}

export function communityInviteUrl(): string {
  return toUrl('/community/invite');
}

export function crewMemberDashboardUrl(userId: string): string {
  return toUrl(`/community/member/${userId}`);
}
/*
  ------------------------------------------------------------
  Inbox
  ------------------------------------------------------------
*/

export function inboxUrl(): string {
  return toUrl('/messages');
}

export function inboxThreadUrl(threadId: string): string {
  return toUrl(`/messages/${threadId}`);
}

export function inboxNewThreadUrl(
  type: ':type' | 'care_pro' | 'crew', userId: string
): string {
  return toUrl(`/messages/new/${type}/${userId}`);
}

/*
  ------------------------------------------------------------
  Appointments
  ------------------------------------------------------------
*/

export function apptsUrl(): string {
  return toUrl('/appts');
}

export function newApptUrl(apptTypeId: string): string {
  return toUrl(`/appts/new/${apptTypeId}`);
}

export function apptUrl(apptId: string): string {
  return toUrl(`/appts/${apptId}`);
}

export function apptIcalUrl(apptId: string, token: string): string {
  return publicUrl(`api/v1/appts/${apptId}.ics?token=${token}`);
}
/*
  ------------------------------------------------------------
  Challenges
  ------------------------------------------------------------
*/

export function challengesUrl(): string {
  return toUrl('/challenges');
}

export function availableChallengesUrl(): string {
  return toUrl('/available-challenges');
}

export function completedChallengesUrl(
  page?: number | string
): string {
  page = page || 1;
  return toUrl(`/completed-challenges/${page}`);
}

export function challengeUrl(
  challengeId: number | string,
  mode?: 'teams'
): string {
  const modePart = mode ? `/${mode}` : '';
  return toUrl(`/challenges/${challengeId}${modePart}`);
}

export function challengeTemplateUrl(templateId: number | string): string {
  return toUrl(`/challenge-templates/${templateId}`);
}

export function challengeSetupUrl(challengeId: number | string): string {
  return `${challengeUrl(challengeId)}/setup`;
}

export function challengeTemplateSetupUrl(templateId: number | string): string {
  return `${challengeTemplateUrl(templateId)}/setup`;
}

export function challengeInvitationSetupUrl(inviteId: number | string): string {
  return toUrl(`/challenge-invitations/${inviteId}/setup`);
}

export function challengeInviteUrl(challengeId: number | string): string {
  return toUrl(`/challenges/${challengeId}/invite`);
}

export function challengesRulesUrl(
  objectType: string, id: string | number
): string {
  return toUrl(`/challenges/${id}/${objectType}/rules`);
}

export function challengeRulesUrl(challengeId: number | string): string {
  return challengesRulesUrl('challenge', challengeId);
}

export function templateRulesUrl(templateId: string): string {
  return challengesRulesUrl('template', templateId);
}

export function challengeLeaderboardUrl(
  challengeId: number | string,
  page?: number | string
): string {
  page = page || 1;
  return toUrl(`/challenges/${challengeId}/leaderboard/${page}`);
}

export function challengeTeamLeaderboardUrl(
  challengeId: number | string,
  page?: number | string
): string {
  page = page || 1;
  return toUrl(`/challenges/${challengeId}/leaderboard/teams/${page}`);
}

export function taskChallengeDailyDetailsUrl(
  challengeId: number | string,
  date?: string | moment.Moment
): string {
  let datePart = '';
  if (typeof date === 'string') {
    datePart = `/${date}`;
  } else if (moment.isMoment(date)) {
    datePart = `/${date.format('YYYY-MM-DD')}`;
  }
  return toUrl(`/challenges/${challengeId}/task-daily${datePart}`);
}

export function taskChallengeSummaryUrl(
  challengeId: number | string
): string {
  return toUrl(`/challenges/${challengeId}/task-summary`);
}

// Team pages

export function challengeTeamsUrl(challengeId: number | string): string {
  return toUrl(`/challenges/${challengeId}/teams`);
}

export function challengeJoinTeamUrl(challengeId: number | string): string {
  return toUrl(`/challenges/${challengeId}/join-team`)
}

export function challengeTeamUrl(
  challengeId: number | string, teamId: number | string
): string {
  return toUrl(`/challenges/${challengeId}/teams/${teamId}`);
}

export function challengeNewTeamUrl(challengeId: number | string): string {
  return toUrl(`/challenges/${challengeId}/teams/new`);
}

export function challengeEditTeamUrl(
  challengeId: number | string, teamId: number | string
): string {
  return toUrl(`/challenges/${challengeId}/teams/${teamId}/edit`);
}

/*
  ------------------------------------------------------------
  Goals
  ------------------------------------------------------------
*/

export function goalsUrl(userId?: string): string {
  const base = '/goals';
  return toUrl(
    userId ? `${base}/${userId}` : base
  );
}

export function goalUrl(goalId: string): string {
  return toUrl(`/goal/${goalId}`);
}

/*
  ------------------------------------------------------------
  Screenings
  ------------------------------------------------------------
*/

export function screeningReportsUrl(): string {
  return screeningsSettingsUrl();
}

export function screeningReportUrl(reportId: string): string {
  return toUrl(`/screening/${reportId}`);
}

export function screeningFormUrl(): string {
  return toUrl('/screening/form');
}

/*
  ------------------------------------------------------------
  Rewards
  ------------------------------------------------------------
*/

export function rewardsUrl(): string {
  return toUrl('/rewards');
}

export function availableRewardsUrl(): string {
  return toUrl('/rewards/available');
}

export function rewardUrl(id: number | string): string {
  return toUrl(`/rewards/${id}`);
}

export function aboutRewardsUrl(): string {
  return toUrl('/about-rewards');
}

/*
  ------------------------------------------------------------
  Care plans
  ------------------------------------------------------------
*/

export function carePlanCareTasksUrl(date?: string | moment.Moment): string {
  const datePart = date ? `/${urlDate(date)}` : '';
  return `/care-tasks${datePart}`;
}

/*
  ------------------------------------------------------------
  Settings
  ------------------------------------------------------------
*/

export const settingsUrl = () => _settingsUrl();

export const profileSettingsUrl = () => _settingsUrl('profile');
export const specsSettingsUrl = () => _settingsUrl('measurements');
export const edgeSettingsUrl = () => _settingsUrl('edge');
export const notificationSettingsUrl = () => _settingsUrl('notifications');
export const goalsSettingsUrl = () => _settingsUrl('goals');
export const screeningsSettingsUrl = () => _settingsUrl('screenings');

function _settingsUrl(section?: string): string {
  return toUrl(`/settings${section ? '/' + section : ''}`);
}

/*
  ------------------------------------------------------------
  Edge
  ------------------------------------------------------------
*/

export function edgeUrl(): string {
  return toUrl('/edge');
}

export function edgeConnectCallbackUrl(): string {
  return toUrl('/edge-connect-callback');
}

export function edgeConnectToDeviceUrl(
  providerKey: string,
  deviceTypeId?: number | string | undefined
): string {
  let url = `/edge/connect-to-device/${providerKey}`;
  if (deviceTypeId) {
    url = `${url}/${deviceTypeId}`;
  }
  return toUrl(url);
}

export function edgeDeviceSettingsUrl(
  deviceTypeId: number | string,
  deviceId: string
): string {
  return toUrl(`/edge/device/${deviceTypeId}/${deviceId}`);
}

export function firmwareUpdateProgressUrl(
  deviceTypeId: number | string,
  deviceId: string
): string {
  return toUrl(
    `/edge/device/${deviceTypeId}/${deviceId}/firmware-update-progress`
  );
}

/*
  ------------------------------------------------------------
  Auth urls
  ------------------------------------------------------------
*/

export function publicUrl(path: string): string {
  if (path[0] === '/') { path = path.substr(1); }
  return `${PUBLIC_URL_BASE}/${path}`;
}

export function loginUrl(programSlug?: string): string {
  return toUrl(`/login${programSlug ? '/' + programSlug : ''}`);
}

export function signupUrl(programSlug: string): string {
  return toUrl(`/join/${programSlug}`);
}

export function externalLoginUrl(): string {
  return publicUrl('login');
}

export function logoutUrl(): string {
  return toUrl('/logout');
}

export function nativeAppDownloadUrl(
  platform: string, slug: string
): string {
  return publicUrl(`get-the-app/${platform}/${slug}`);
}

export function forgotPasswordUrl(slug?: string): string {
  return toUrl(slug ? `/forgot-password/${slug}` : '/forgot-password');
}

export function resetPasswordUrl(code: string, slug?: string): string {
  const base = `/reset-password/${code}`;
  return toUrl(slug ? `${base}/${slug}` : base);
}

/*
  ------------------------------------------------------------
  Marketing site
  ------------------------------------------------------------
*/

export function marketingSiteUrl(): string {
  return PUBLIC_URL_BASE;
}

export function termsOfServiceUrl(slug?: string, anchor?: string): string {
  let url = publicUrl('terms')
  if (anchor) { url = `${url}#${anchor}`; }
  if (slug) { url = `${url}?program_slug=${slug}`; }
  return url;
}

export function privacyPolicyUrl(slug?: string): string {
  return termsOfServiceUrl(slug, 'privacy');
}

/*
  ------------------------------------------------------------
  Helper functions
  ------------------------------------------------------------
*/

export function qs(o: {[k: string]: any}): string {
  return Object.keys(o).map(
    k => `${encodeURIComponent(k)}=${encodeURIComponent(o[k].toString())}`
  ).join('&');
}

export function isUrlExternal(url: string): boolean {
  if (window.location.protocol === 'file:') {
    return isHttpUrl(url);
  } else if (!isHttpUrl(url)) {
    return false;
  } else {
    const prefix = `${window.location.protocol}//${window.location.host}`;
    return url.substr(0, prefix.length) !== prefix;
  }
}

function isHttpUrl(url: string): boolean {
  return /^http/.test(url);
}

function toUrl(url: string): string {
  return url;
}

function urlDate(date: string | moment.Moment): string {
  if (typeof date === 'string') {
    return `${date}`;
  } else if (moment.isMoment(date)) {
    return `${date.format('YYYY-MM-DD')}`;
  } else {
    throw new Error('unrecognized date argument' + (<any>date).toString());
  }
}

export function absoluteAppUrl(path: string): string {
  if (isHttpUrl(path)) {
    return path;
  } else {
    if (path.substr(0, 1) !== '/') {
      path = `/${path}`;
    }
    return `${URL_BASE}/#${path}`;
  }
}
