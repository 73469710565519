export class CRCInvalidError extends Error {}

/**
 * Computes the CRC checksum of the packet
 */
export function compute(packet: Uint8Array): number {
  const sum = packet.reduce((a, b) => a + b, 0);
  return sum & 0xff;
}

/**
 * Determines if the packet's CRC checksum is valid
 */
export function check(packet: Uint8Array): boolean {
  const withoutCRC = Uint8Array.from(packet)
  withoutCRC[withoutCRC.length - 1] = 0;
  return compute(withoutCRC) === packet[packet.length - 1];
}

/**
 * Throws a CRCInvalidError if checksum is not correct
 */
export function verify(packet: Uint8Array): void {
  if (check(packet)) { return; }
  throw new CRCInvalidError();
}

/**
 * Mutates the argument, adding a CRC checksum at the end. Packet with CRC added
 * is returned;
 */
export function add(packet: Uint8Array): Uint8Array {
  packet[packet.length - 1] = compute(packet);
  return packet;
}
