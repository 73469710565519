import * as R from 'ramda';
import * as React from 'react';
import * as classnames from 'classnames';

import Spinner from 'Shared/UI/Spinner';

import {
  Provider, ProviderIntegrationType, ConnectionStatus, UserDevice, DeviceType,
  DeviceConnectionStatuses, isDisabled, isEnabled
} from '../Data';
import FormFactorIcon from './FormFactorIcon';

interface Props {
  provider: Provider,
  status: ConnectionStatus,
  deviceStatuses: DeviceConnectionStatuses,
  userDevices: UserDevice[],
  connectProvider: (p: Provider) => void,
  disconnectProvider: (p: Provider) => void,
  connectDevice: (p: Provider, dt: DeviceType) => void,
  disconnectDevice: (p: Provider, ud: UserDevice) => void
}

export default function renderConnectLink(
  props: Props
): React.ReactElement<Props> {
  const { provider, status } = props;
  const disabled: boolean = isDisabled(provider);

  const classes = classnames(
    'edge-settings__connect-link',
    { pending: status === 'pending',
      connected: status === 'connected',
      disabled: disabled,
      'edge-settings__connect-link--first': false,
      'edge-settings__connect-link--last': false
    }
  );
  return (
    <div className={classes}>
      <div className="edge-settings__connect-link__icon">
        <img src={provider.connectIconUrl} className="provider"/>
      </div>

      <div className="edge-settings__connect-link__right">
        {renderProviderButtons(props)}
      </div>

      <div className="edge-settings__provider-info">
        <p className="edge-settings__provider-name">
          {provider.label}
        </p>
        <p className="edge-settings__provider-description">
          {provider.description}
        </p>
      </div>

      <div className="clearfix"></div>
      {renderDeviceList(props)}
    </div>
  );
}

function renderProviderButtons(props: Props) {
  if (bluetoothProvider(props.provider)) { return; }
  return (
    <>
      {isDisabled(props.provider) && disabledButton(props)}
      {props.status === 'not_connected' && connectButton(props)}
      {props.status === 'connected' && disconnectButton(props)}
      {props.status === 'pending' && spinner()}
    </>
  );
}

function renderDeviceList(props: Props) {
  const provider = props.provider;
  if (bluetoothProvider(provider) && provider.deviceTypes.length > 0) {
    return (
      <ul className="edge-settings__device-list">
        {provider.deviceTypes.map(dt => renderDeviceListItem(props, dt))}
      </ul>
    )
  }
}

function renderDeviceListItem(props: Props, deviceType: DeviceType) {
  const userDevice =
    R.find(ud => ud.deviceTypeId === deviceType.id, props.userDevices)
  return (
    <li key={deviceType.id} className="edge-settings__device-list-item">
      <div className="edge-settings__device-form-factor-icon">
        <FormFactorIcon formFactor={deviceType.formFactor} />
      </div>
      <div className="edge-settings__device-name">
        <strong className="bold">{deviceType.name}</strong>
        <br/>
        {userDevice ? `Device ID: ${userDevice.deviceId}` : ''}
      </div>
      {renderDeviceButtons(props, deviceType, userDevice)}
    </li>
  );
}

function renderDeviceButtons(
  props: Props, deviceType: DeviceType, userDevice: UserDevice | undefined
) {
  let button;
  if (props.deviceStatuses[deviceType.id] === 'pending') {
    button = spinner();
  } else if (userDevice) {
    button = (
      <button className="alert connect-button disconnect"
              onClick={() => props.disconnectDevice(props.provider, userDevice)}
      >
        remove
      </button>
    );
  } else {
    button = (
      <button className="default connect-button connect"
              onClick={() => props.connectDevice(props.provider, deviceType)}
      >
        connect
      </button>
    )
  }
  return <div className="edge-settings__device-button">{button}</div>;
}

function disabledButton(props: Props): React.ReactNode {
  if (isDisabled(props.provider)) {
    return (
      <span className="button default grayed-out connect-button disabled">
        {props.status === 'connected' ? 'remove' : 'connect'}
      </span>
    );
  }
}

function connectButton(props: Props): React.ReactNode {
  const provider = props.provider;
  if (isEnabled(provider) && props.status === 'not_connected') {
    return (
      <button className="default connect-button connect"
        onClick={() => props.connectProvider(provider)}>
        connect
      </button>
    );
  }
}

function disconnectButton(props: Props): React.ReactNode {
  const provider = props.provider;
  if (isEnabled(provider) && props.status === 'connected') {
    return (
      <button className="alert connect-button disconnect"
        onClick={() => props.disconnectProvider(provider)}>
        remove
      </button>
    );
  }
}

function spinner(): React.ReactNode {
  return (
    <span className="edge-settings__connect-link__spinner">
      <Spinner></Spinner>
    </span>
  );
}

function bluetoothProvider(p: Provider): boolean {
  return p.integrationType === ProviderIntegrationType.Bluetooth;
}
