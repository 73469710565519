import { ActionCreatorThunk } from 'Store';
import { ActionCreators as HUD } from 'HUD';
import { RewardPlan } from './Data';
import { ActionType, withNS } from './Action';
import { rewardPlanFromJSON, userActionsFromJSON } from './JSON';
import * as Maybe from 'misc/Data/Maybe';

export interface ActionCreators {
  loadRewardPlan(): ActionCreatorThunk,

  loadUserActions(): ActionCreatorThunk,

  /**
   * For a "manual app action" reward, perform the action aka mark it complete
   */
  performAction(rewardId: number): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  loadRewardPlan,
  loadUserActions,
  performAction
}

function loadRewardPlan(): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    return api.rewards.getRewardPlan().then(result => {
      const rewardPlan =
        result.reward_plan ?
        Maybe.just(rewardPlanFromJSON(result.reward_plan)) :
        Maybe.nothing<RewardPlan>();
      dispatch(withNS({
        type: ActionType.LOAD_REWARD_PLAN__SUCCESS,
        rewardPlan
      }));
    });
  };
}

function loadUserActions(): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    return api.rewards.getUserActions().then(result => {
      const userActions = userActionsFromJSON(result.user_actions);
      dispatch(withNS({
        type: ActionType.LOAD_USER_ACTIONS__SUCCESS,
        userActions
      }));
    });
  };
}

function performAction(rewardId: number): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.rewards.performManualAction(rewardId).then(
      () => {
        dispatch(HUD.success());
        dispatch(loadUserActions());
        dispatch(loadRewardPlan());
      }
    );
  };
}
