import * as R from 'ramda';
import { State, Availability, ScanStatus } from './Data';
import {
  Action, ActionType, BLEScanFoundDevice, BLECheckFirmware_Complete
} from './Action';

export function reducer(state: State, action: Action): State {
  switch(action.type) {
    case ActionType.BLE_AVAILABLE:
      return { ...state, availability: Availability.AVAILABLE };
    case ActionType.BLE_NOT_AVAILABLE:
      return { ...state, availability: Availability.UNAVAILABLE };
    case ActionType.BLE_SCAN_START:
      return { ...state, scanStatus: ScanStatus.SCANNING };
    case ActionType.BLE_SCAN_STOP:
      return { ...state, scanStatus: ScanStatus.NOT_SCANNING };
    case ActionType.BLE_SCAN_FOUND_DEVICE:
      return bleScanFoundDevice(state, action);
    case ActionType.BLE_CHECK_FIRMWARE__COMPLETE:
      return bleCheckFirmware(state, action);
    case ActionType.BLE_UPDATE_PROGRESS:
      return { ...state, progress: action.progress };
    case ActionType.BLE_DFU_BEGIN:
      return { ...state, progress: 0, isDFU: true };
    case ActionType.BLE_DFU_COMPLETE:
      return { ...state, isDFU: false };
  }
}

function bleScanFoundDevice(
  state: State, action: BLEScanFoundDevice
): State {
  let ps = R.append(action.device, state.peripherals);
  ps = R.uniqBy(d => d.address, ps)
  ps = R.sortBy(d => d.name, ps);

  return { ...state, peripherals: ps };
}

function bleCheckFirmware(
  state: State, action: BLECheckFirmware_Complete
): State {
  const firmwareStatuses = {
    ...state.firmwareStatuses,
    [action.address]: {
      currentVersion: action.currentVersion,
      latestVersion: action.latestVersion
    }
  };
  return { ...state, firmwareStatuses };
}
