import './StripedTable.scss';
import * as React from 'react';
import * as classnames from 'classnames';
import { bem } from 'css-util';

interface Props {
  headers: React.ReactNode[],
  rows: React.ReactNode[][],
  /**
  * Set colSpan's on header th elements. Defaults to 1. If array is shorter than
  * number of headers, all non-specified get 1
  */
  headerSpans?: number[],
  tfoot?: React.ReactNode,
  className?: string,
  /**
   * When given, classes are used on TDs in order
   */
  rowClasses?: string[],
  /**
   * Like rowClasses for the TH elements instead
   */
  headerClasses?: string[]
}

const CSS = bem('striped-table');

const StripedTable = (props: Props) => {
  const { headers, rows } = props;
  const headerSpans = props.headerSpans || [];
  const rowClasses = props.rowClasses || [];
  const headerClasses = props.headerClasses || [];
  return (
    <table className={classnames('striped-table', props.className)}>
      <thead>
        <tr>
          {headers.map((header, i) =>
            <th key={i}
              colSpan={headerSpans[i] || 1}
              className={classnames(CSS('th')(), headerClasses[i])}
            >
              {header}
            </th>)}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) =>
          <tr key={i} className={classnames(CSS('tr')())}>
            {row.map((col, i) =>
              <td key={i} className={classnames(CSS('td')(), rowClasses[i])}>
                {col}
              </td>)}
          </tr>
        )}
      </tbody>
      {props.tfoot}
    </table>
  );
}

export default StripedTable;
