import * as React from 'react';

import { ChallengeSummary } from 'Challenges';
import ChallengeTile from 'Challenges/UI/Tile';
import { challengesUrl } from 'Shared/Urls';
import NoContent from './NoContent';

import Tile from './Tile';

interface Props {
  challenge?: ChallengeSummary,
  isMe: boolean,
  onDashboard?: boolean
}


const NO_CHALLENGE =
  <NoContent iconName="trophy-alt">no active challenges to display</NoContent>;

export default function renderChallengesTile(
  props: Props
): React.ReactElement<Props> {
  const challenge = props.challenge;
  const linkLabel = props.isMe ? 'view challenges' : 'invite to challenges';

  return (
    <Tile
      title="challenges" linkUrl={challengesUrl()} linkLabel={linkLabel}
      modifier="dashboard-challenge"
    >
      {challenge ? renderChallengeInfo(props, challenge) : NO_CHALLENGE}
    </Tile>
  );
}

function renderChallengeInfo(props: Props, challenge: ChallengeSummary) {
  return (
    <ChallengeTile item={challenge} noLink={!props.isMe} onDashboard={true}/>
  );
}
