import * as React from 'react';
import { bem } from 'css-util';
import StatusCircle, { Status } from 'Shared/UI/StatusCircle';
import Link from 'Shared/UI/Link';

interface Props {
  children?: React.ReactNode
  status: Status
  complete: () => void
  undo?: () => void
}

const CSS = bem('task-list');

const TaskListItem = (props: Props) => {
  return (
    <div className={CSS('list-item')()}>
      <div className={CSS('task-details')()}>
        {props.children}
      </div>
      <div className={CSS('task-status')()}>
        {renderStatusCircle(props)}
      </div>
    </div>
  );
}

export default TaskListItem

function renderStatusCircle(
  props: Props
) {
  const { status, complete } = props;
  const circle = <StatusCircle status={status} />;
  if (status === Status.AVAILABLE) {
    return (
      <div onClick={complete} className="pointer">
        {circle}
      </div>
    );
  } else {
    return (
      <div>
        {circle}
        {renderUndoButton(props)}
      </div>
    );
  }
}

function renderUndoButton(
  props: Props
) {
  if (props.status === Status.COMPLETE && props.undo) {
    return (
      <Link to={props.undo}
        className={CSS('undo')()}
        themed={true}>
        undo
      </Link>
    );
  }
}
