import * as R from 'ramda';

export type Either<L, R>
  = Left<L>
  | Right<R>;

export interface Left<T> { left: T };

export interface Right<T> { right: T};

// Constructors

export function left<L, R>(value: L): Either<L, R> {
  return { left: value };
}

export function right<L, R>(value: R): Either<L, R> {
  return { right: value };
}

// Predicates

export function isLeft<L, R>(either: Either<L, R>): either is Left<L> {
  return (either as Left<L>).left !== undefined;
}

export function isRight<L, R>(either: Either<L, R>): either is Right<R> {
  return (either as Right<R>).right !== undefined;
}

// (bi)Functor

export function map<L, R, R2>(
  rightFn: (right: R) => R2, either: Either<L, R>
): Either<L, R2> {
  return bimap<L, L, R, R2>(R.identity, rightFn, either);
}

export function first<L, L2, R>(
  leftFn: (left: L) => L2, either: Either<L, R>
): Either<L2, R> {
  return bimap<L, L2, R, R>(leftFn, R.identity, either);
}

export const second = map;

export function bimap<L, L2, R, R2>(
  leftFn: (left: L) => L2,
  rightFn: (right: R) => R2,
  either: Either<L, R>
): Either<L2, R2> {
  if (isLeft(either)) {
    return left(leftFn(either.left));
  } else {
    return right(rightFn(either.right));
  }
}

// Other functions

export function either<L, R, X>(
  leftFn: (left: L) => X,
  rightFn: (right: R) => X,
  either: Either<L, R>
): X {
  return isLeft(either) ? leftFn(either.left) : rightFn(either.right);
}

export function fromLeft<L, R>(def: L, either: Either<L, R>): L {
  return isLeft(either) ? either.left : def;
}

export function fromRight<L, R>(def: R, either: Either<L, R>): R {
  return isRight(either) ? either.right : def;
}
