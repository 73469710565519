// import { DEBUG } from 'Env';
import * as Env from 'Env';

type Key = keyof typeof Env;

export function enabled(key?: Key): boolean {
  const storageValue = localStorage.getItem(storageKey(key));
  return (key === undefined && Env.DEBUG) ||
    (key !== undefined && !!Env[key]) ||
    (storageValue !== null && storageValue === 'true');
}

export function enable(key: Key) {
  localStorage.setItem(storageKey(key), 'true');
}

export function disable(key: Key) {
  localStorage.removeItem(storageKey(key));
}

function storageKey(key?: Key): string {
  return key ? `DEVELOPER:${key}` : 'DEVELOPER';
}

(window as any).DEV = { Env, enabled, enable, disable };
