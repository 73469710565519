import * as React from 'react';
import { Prompt } from 'react-router-dom';
import { push } from 'connected-react-router';
import { defaultConnect } from 'Shared/ReduxComponent';
import { State, DispatchProps } from 'Store';
import * as Urls from 'Shared/Urls';
import { ActionCreators } from 'Screenings/ActionCreator';
import { Loadable, isLoaded, fromLoadable } from 'misc/Data/Loadable';
import { HTML } from 'Shared/Data/HTML';
import {
  SEL, State as ScreeningsState, Screen, ScreenStep, ScreenField, FieldValue,
  percentProgress, estimatedMinutes, fieldValue, screeningIsDone,
  currentStepFields
} from 'Screenings/Data';
import { ActionCreators as UserAC, SEL as UserSEL } from 'User';

import { HUD } from 'HUD';
import Layout from 'Screenings/UI/Form/Layout';
import ProgressBar from 'Shared/UI/ProgressBar';
import DynamicHTML from 'Shared/UI/DynamicHTML';
import Field from 'Screenings/UI/Form/Field';
import Pager from 'Screenings/UI/Form/Pager';
import Errors from 'Screenings/UI/Form/Errors';

type Props = State & DispatchProps

const EXIT_CONFIRMATION_MESSAGE =
  'Are you sure you want to exit this Health Risk Assessment, your results will not be saved.';

class ScreeningForm extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(ActionCreators.loadScreen());
    this.props.dispatch(UserAC.loadCurrentUser());
  }

  screeningState(): ScreeningsState {
    return this.props.screenings;
  }

  screen(): Loadable<Screen | undefined> {
    return SEL.screen(this.screeningState());
  }

  currentStep(): ScreenStep {
    return currentStepFields(this.screeningState());
  }

  currentStepIndex(): number {
    return this.screeningState().currentStepIndex;
  }

  showIntro(): boolean {
    const screen = this.screen();
    if (
      isLoaded(screen) && screen.value &&
      this.currentStepIndex() === 0 &&
      !this.isDone()
    ) {
      return true;
    } else {
      return false;
    }
  }

  isDone(): boolean {
    return screeningIsDone(this.screeningState());
  }

  estimatedMinutes(): undefined | number {
    const screen = this.screen();
    return screen.value && estimatedMinutes(screen.value);
  }

  allowExit(): boolean {
    return fromLoadable(
      features => features.allowExitHRA,
      () => false,
      UserSEL.appFeatures(this.props.currentUser)
    );
  }

  thankYouContent(): HTML {
    return SEL.thankYouContent(this.screeningState());
  }

  errors(): string[] {
    return SEL.errors(this.screeningState());
  }

  promptToLeave(): boolean {
    return this.screen().value !== undefined &&
           !this.isDone() &&
           this.allowExit();
  }

  gotoNextStep = () => {
    if (this.isDone()) {
      this.props.dispatch(push(Urls.homeUrl()));
    } else {
      this.props.dispatch(ActionCreators.gotoNextStep());
    }
  }

  gotoPreviousStep = () => {
    this.props.dispatch(ActionCreators.gotoPreviousStep());
  }

  onFieldChange = (field: ScreenField, value: FieldValue) => {
    this.props.dispatch(ActionCreators.setFieldValue(field, value));
  }

  nextStepLabel() {
    return this.isDone() ? 'back to app' : 'next';
  }

  showPreviousStepButton() {
    return this.currentStepIndex() !== 0 && !this.isDone();
  }

  render() {
    const screen = this.screen();
    return (
      <Layout isDone={this.isDone()} allowExit={this.allowExit()}>
        <HUD state={this.props.hud} />
        <Prompt when={this.promptToLeave()}
          message={EXIT_CONFIRMATION_MESSAGE} />

        {this.showIntro() && this.renderIntro()}

        {screen.value && this.renderProgressBar(screen.value)}

        {this.isDone() && this.renderThankYouContent()}

        <Errors errors={this.errors()} />

        {this.currentStep().map(this.renderField)}

        <Pager
          onNext={this.gotoNextStep}
          onPrevious={this.gotoPreviousStep}
          showPrevious={this.showPreviousStepButton()}
          nextLabel={this.nextStepLabel()}/>
      </Layout>
    );
  }

  renderIntro() {
    let estimate: React.ReactNode = undefined;
    const minutes = this.estimatedMinutes();
    if (minutes) {
      estimate = [
        <br key="br"/>,
        <small key="small">
          (estimated time to complete is less
          than {minutes} minute{minutes === 1 ? '' : 's'})
        </small>
      ];
    }
    return (
      <p className="screening-form__intro__text">
        Hi, please take a moment to complete the following Health Risk Assessment (HRA) questionnaire:
        {estimate}
      </p>
    );
  }

  renderProgressBar(screen: Screen) {
    const percent = percentProgress(screen, this.currentStepIndex());

    return <ProgressBar percent={percent} showText={true}/>;
  }

  renderField = (field: ScreenField) => {
    const screen = this.screen();
    const value =
      screen.value ? fieldValue(screen.value.data, field.key) : undefined;
    return (
      <Field
        key={field.key}
        field={field}
        onChange={this.onFieldChange}
        value={value} />
    );
  }

  renderThankYouContent() {
    return (
      <DynamicHTML html={this.thankYouContent()} />
    );
  }
}

export default defaultConnect(ScreeningForm);
