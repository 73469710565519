export * from './Data';
export * from './Action';
export { reducer } from './Store';

import TrendsGraph from './Page/TrendsGraph';
import MyTrendsRedirect from './Page/MyTrendsRedirect';

export const Pages = {
  TrendsGraph,
  MyTrendsRedirect
};
