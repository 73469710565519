import * as React from 'react';
import { apiUrl } from 'Api';

interface Props {
  slug: string | undefined
}

const ThemeStyles = (props: Props) => {
  const slug = props.slug;
  if (slug) {
    const stylesheetUrl = apiUrl(`/themes/${slug}.css`);
    return <link href={stylesheetUrl} rel="stylesheet"/>;
  } else {
    return <span/>;
  }
};

export default ThemeStyles;
