import './Pager.scss';
import * as React from 'react';
import * as classnames from 'classnames';

import Icon from 'Shared/UI/Icon';

interface Props<T> {
  nextPage: T | undefined,
  nextLabel?: string,
  previousPage: T | undefined,
  previousLabel?: string,
  gotoPage: (page: T) => void,
  className?: string
}

export default function renderPager<T>(
  props: Props<T>
): React.ReactElement<Props<T>> {
  const classes = classnames('pager', props.className);
  return (
    <nav className={classes}>
      {renderPreviousLink(props)}
      {renderNextLink(props)}
    </nav>
  );
}

function renderPreviousLink<T>(
  props: Props<T>
): React.ReactNode {
  const label = props.previousLabel || 'Newer';
  return renderLink(
    props.previousPage,
    [ <Icon name="caret-left" key="icon"></Icon>, renderLabel(label) ],
    'previous',
    () => props.previousPage ? props.gotoPage(props.previousPage) : null
  );
}

function renderNextLink<T>(
  props: Props<T>
): React.ReactNode {
  const label = props.nextLabel || 'Older';
  return renderLink(
    props.nextPage,
    [ renderLabel(label), <Icon name="caret-right" key="icon"></Icon> ],
    'next',
    () => props.nextPage ? props.gotoPage(props.nextPage) : null
  );
}

function renderLabel(label: string): React.ReactNode {
  return <span className="pager-label" key={label}>{label}</span>;
}

function renderLink<T>(
  page: T | undefined,
  linkContent: React.ReactNode,
  className: string,
  action: () => void
): React.ReactNode {
  if (page) {
    return (
      <a className={`pager-link ${className}`} onClick={action}>
        {linkContent}
      </a>
    );
  } else {
    return null;
  }
}
