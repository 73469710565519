import * as R from 'ramda';
import { DataType as EdgeDataType } from 'Edge';
import { ExpandedSpecType } from 'Specs';
import { UserId } from 'User';
import { Activity } from 'Post';
import {
  IDMap, idMapValues, idMapLookup, newIDMap
} from 'Shared/Data/IDMap';

/*
  ------------------------------------------------------------
  ns
  ------------------------------------------------------------
*/

export type NSType = 'GOALS';
export const NS: NSType = 'GOALS';

/*
  ------------------------------------------------------------
  state and date types
  ------------------------------------------------------------
*/

export interface State {
  goals: IDMap<Goal>

  // Maps USER ID -> list of goal statuses for the user
  goalStatuses: IDMap<GoalStatus[]>

  // Maps GoalId -> list of activities for that goal
  goalActivities: IDMap<Activity[]>,

  polling: boolean
}

export type GoalId = string;

export interface Goal {
  id: GoalId,
  name: string,
  details: string,
  edgeTypes: EdgeDataType[],
  specTypes: ExpandedSpecType[]
}

export interface GoalStatus {
  goalId: GoalId
  postCount: number
  summary: GoalStatusSummary | undefined
  days: number
  scoreChange: number | undefined
}

export interface ActiveGoal {
  goal: Goal,
  status: GoalStatus
}

export type GoalStatusSummary = 'red' | 'yellow' | 'green';

/*
  ------------------------------------------------------------
  Selectors
  ------------------------------------------------------------
*/
export interface Selectors {
  goals(s: State): Goal[] | undefined,
  statuses(userId: UserId, s: State): GoalStatus[] | undefined,
  goal(goalId: GoalId, s: State): Goal | undefined,
  status(goalId: GoalId, userId: UserId, state: State): GoalStatus | undefined,
  activeGoal(userId: UserId, goalId: GoalId, s: State): ActiveGoal | undefined,
  activeGoals(userId: string, s: State): ActiveGoal[] | undefined,
  goalActivities(goalId: GoalId, state: State): Activity[] | undefined,
  polling(s: State): boolean
}

export const SEL: Selectors = {
  goals(s: State): Goal[] | undefined {
    return s.goals ? idMapValues(s.goals) : undefined;
  },

  statuses(userId: UserId, s: State): GoalStatus[] | undefined {
    return idMapLookup(userId, s.goalStatuses);
  },

  goal(goalId: GoalId, s: State): Goal | undefined {
    return s.goals ? idMapLookup(goalId, s.goals) : undefined;
  },
  status(goalId: GoalId, userId: UserId, state: State): GoalStatus | undefined {
    const statuses = SEL.statuses(userId, state);
    if (statuses) {
      return R.find(R.propEq('goalId', goalId), statuses);
    }
  },
  activeGoal(userId: UserId, goalId: GoalId, s: State): ActiveGoal | undefined {
    const activeGoals = SEL.activeGoals(userId, s);

    if (activeGoals) {
      return R.find(({ goal }) => goal.id === goalId, activeGoals);
    }
  },

  activeGoals(userId: UserId, s: State): ActiveGoal[] | undefined {
    const statuses = idMapLookup(userId, s.goalStatuses);
    if (statuses) {
      return statuses.reduce(
        (ary, status) => {
          const goal = SEL.goal(status.goalId, s);
          if (goal) { return R.append({ status, goal }, ary); }
          else { return ary; }
        },
        []
      );
    } else {
      return undefined;
    }
  },
  goalActivities(goalId: GoalId, s: State): Activity[] | undefined {
    return idMapLookup(goalId, s.goalActivities);
  },

  polling(state: State): boolean {
    return state.polling;
  }
}

/*
  ------------------------------------------------------------
  initializers
  ------------------------------------------------------------
*/

export function initialState(): State {
  return {
    goals: newIDMap(),
    goalStatuses: newIDMap(),
    goalActivities: newIDMap(),
    polling: false
  };
}
