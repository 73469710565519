import * as moment from 'moment-timezone';
import * as R from 'ramda';

export type Gender = 'male' | 'female';

// Legacy data:
export enum LegacyGender {
  MALE = 1,
  FEMALE = 2
}


export interface BloodPressure {
  systolic: number,
  diastolic: number
}

export type Specs = { [K in SpecType]: number | undefined }

export type SpecType
  = 'weight'
  | 'waist'
  | 'heightFeet'
  | 'heightInches'
  | 'restingHeartRate'
  | 'systolicBp'
  | 'diastolicBp'
  | 'bodyFat'
  | 'bmi'
  | 'glucose'
  | 'a1c'
  | 'hdlCholesterol'
  | 'ldlCholesterol'
  | 'bodyTemp'
  | 'spo2'
  | 'hrv'
  | 'respiratoryRate'
  | 'stressScore';

export type ExpandedSpecType = SpecType | 'height' | 'bloodPressure' | 'gender';

export type SpecUpdate
  = StandardSpecUpdate
  | HeightSpecUpdate
  | BloodPressureSpecUpdate;

export interface StandardSpecUpdate {
  spec: SpecType,
  value: number,
  time?: moment.Moment
}

export interface HeightSpecUpdate {
  heightFeet: number,
  heightInches: number,
  time?: moment.Moment
}

export type BloodPressureSpecUpdate = BloodPressure & {
  time?: moment.Moment
}

type AllSpecTypesMap = { [K in SpecType]: K }
const SPEC_TYPES_MAP: AllSpecTypesMap = {
  weight: 'weight',
  waist: 'waist',
  heightFeet: 'heightFeet',
  heightInches: 'heightInches',
  restingHeartRate: 'restingHeartRate',
  systolicBp: 'systolicBp',
  diastolicBp: 'diastolicBp',
  bodyFat: 'bodyFat',
  bmi: 'bmi',
  glucose: 'glucose',
  a1c: 'a1c',
  hdlCholesterol: 'hdlCholesterol',
  ldlCholesterol: 'ldlCholesterol',
  bodyTemp: 'bodyTemp',
  spo2: 'spo2',
  hrv: 'hrv',
  respiratoryRate: 'respiratoryRate',
  stressScore: 'stressScore'
};
export const SPEC_TYPES: SpecType[] = R.values(SPEC_TYPES_MAP);

type AllExpandedSpecTypesMap = { [K in ExpandedSpecType]: K }
const EXPANDED_SPEC_TYPES_MAP: AllExpandedSpecTypesMap = {
    ...SPEC_TYPES_MAP,
  height: 'height',
  bloodPressure: 'bloodPressure',
  gender: 'gender'
};
export const EXPANDED_SPEC_TYPES: ExpandedSpecType[] =
  R.values(EXPANDED_SPEC_TYPES_MAP);

/*------------------------------------------------------------*/
// Constants, labels

type SpecStrings = { [K in SpecType]: string };

const LABELS: SpecStrings = {
  weight: 'weight',
  waist: 'waist',
  heightFeet: 'height',
  heightInches: 'height',
  restingHeartRate: 'resting heart rate',
  systolicBp: 'systolic blood pressure',
  diastolicBp: 'diasolic blood pressure',
  bodyFat: 'body fat %',
  bmi: 'body mass index',
  glucose: 'glucose',
  a1c: 'a1c %',
  hdlCholesterol: 'hdl cholesterol',
  ldlCholesterol: 'ldl cholesterol',
  bodyTemp: 'body temperature',
  spo2: 'SpO2 %',
  hrv: 'heart rate variability',
  respiratoryRate: 'respiratory rate',
  stressScore: 'stress score'
};

const UNIT_LABELS: SpecStrings = {
  weight: 'pounds',
  waist: 'inches',
  heightFeet: 'feet',
  heightInches: 'inches',
  restingHeartRate: 'beats per minute',
  systolicBp: 'mmHg',
  diastolicBp: 'mmHg',
  bodyFat: '%',
  bmi: 'kg/m^2',
  glucose: 'mg/dL',
  a1c: '%',
  hdlCholesterol: 'mg/dL',
  ldlCholesterol: 'mg/dL',
  bodyTemp: '°F',
  spo2: '%',
  hrv: 'ms',
  respiratoryRate: 'bpm',
  stressScore: 'score'
};

export function specTypeLabel(specType: SpecType): string {
  return LABELS[specType];
}

export function specTypeUnitLabel(specType: SpecType): string {
  return UNIT_LABELS[specType];
}

export function legacyGenderToGender(
  legacyGender?: number
): Gender | undefined {
  if (legacyGender === LegacyGender.MALE) {
    return 'male';
  } else if (legacyGender === LegacyGender.FEMALE) {
    return 'female';
  } else {
    return undefined;
  }
}

export function legacyGenderToString(legacyGender?: number): string {
  return legacyGenderToGender(legacyGender) || 'unknown';
}

/*------------------------------------------------------------*/
// Spec Updates

export function isStandardSpecUpdate(u: SpecUpdate): u is StandardSpecUpdate {
  return (u as StandardSpecUpdate).spec !== undefined;
}

export function isHeightSpecUpdate(u: SpecUpdate): u is HeightSpecUpdate {
  return (u as HeightSpecUpdate).heightFeet !== undefined;
}

export function isBloodPressureSpecUpdate(
  u: SpecUpdate
): u is BloodPressureSpecUpdate {
  return (u as BloodPressureSpecUpdate).systolic !== undefined;
}
