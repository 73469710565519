import { State } from './Data';
import { Action, ActionType } from './Action';
import { loadable } from 'misc/Data/Loadable';

export function reducer(state: State, action: Action): State {
  switch(action.type) {
    case ActionType.LOGIN__SUCCESS:
      return { ...state, token: action.token };
    case ActionType.LOGOUT:
      return { ...state, token: undefined };
    case ActionType.LOAD_AUTH_SETTINGS__SUCCESS:
      return { ...state, settings: loadable(action.settings) };
    case ActionType.SET_AUTH_PROGRAM_SLUG:
      return { ...state, programSlug: action.slug };
  }
  return state;
}
