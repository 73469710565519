import * as React from 'react';

import {
  ChallengeSummary,
  ChallengeTemplate
} from 'Challenges/Data';

import Tile from 'Challenges/UI/Tile';

interface Props {
  challenges: (ChallengeSummary | ChallengeTemplate)[],
  noDataMessage: string
}

export default function TileList(props: Props): React.ReactElement<Props> {
  const cs = props.challenges;
  const ndm = props.noDataMessage;

  if (cs.length === 0) {
    return <p className="no-data">{ndm}</p>;
  } else {
    return <span>{cs.map((c, i) => renderTile(c, i))}</span>
  }
}

function renderTile(
  c: ChallengeSummary | ChallengeTemplate,
  index: number
): React.ReactNode {
  return <Tile key={index} item={c}></Tile>;
}
