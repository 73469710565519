import * as React from 'react';
import { bem } from 'css-util';

const CSS = bem('auth-form');

interface Props {
  checked: boolean
  onChange: (value: boolean) => void
}

const AuthFormRememberMe = (props: Props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    props.onChange(e.target.checked);

  return (
    <div className={CSS('field-row')(['remember-me'])}>
      <span className={CSS('remember-me-input')()}>
        <input
          type="checkbox"
          id="remember-me-input"
          checked={props.checked}
          onChange={onChange}
        />
      </span>
      <label className={CSS('remember-me-label')()}
        htmlFor="remember-me-input">
        remember me
      </label>
    </div>
  )
};

export default AuthFormRememberMe;
