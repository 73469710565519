import * as React from 'react';
import * as classnames from 'classnames';
import { Goal, GoalStatus } from 'Goals';

interface Props {
  goal: Goal,
  status: GoalStatus | undefined,
  activateGoal: (goalId: string) => void,
  deactivateGoal: (goalId: string) => void,
  resetGoal: (goalId: string) => void
}

export default function renderGoalListItem(
  props: Props
): (React.ReactElement<Props>) {
  const { goal, status } = props;
  const enabled = !!status;
  const liClasses = classnames(
    'settings-checklist__item',
    { 'selected theme__primary-bg': enabled }
  );

  const labelClasses = classnames(
    'settings-checklist__item-label',
    'settings-checklist__item-label--with-right-button',
    { 'theme__primary-bg': enabled }
  );

  const toggleHandler = () => {
    enabled ? props.deactivateGoal(goal.id) : props.activateGoal(goal.id);
  };

  return (
    <li className={liClasses}>
      <label className={labelClasses}>
        <input
          type="checkbox"
          className="settings-checklist__checkbox"
          checked={enabled}
          onChange={toggleHandler} />
        {goal.name}
      </label>

      {enabled ? resetButton(enabled, goal, props.resetGoal) : null}
    </li>
  );
}

function resetButton(
  enabled: boolean,
  goal: Goal,
  resetGoal: (gid: string) => void
): React.ReactNode {
  const resetClasses = classnames(
    'settings-checklist__right-button small-3 columns',
    { 'theme__primary-bg': enabled }
  );

  const handler = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    resetGoal(goal.id);
  };

  return (
    <a className={resetClasses}
      onClick={handler}>
      reset
    </a>
  );
}
