import { State } from './Data';
import { Action, ActionType } from './Action';
import { loadable } from 'misc/Data/Loadable';
import { idMapInsert } from 'Shared/Data/IDMap';

export function reducer(state: State, action: Action): State {
  switch(action.type) {
  case ActionType.LOAD_USER_DASHBOARD__SUCCESS:
    const newDashboards =
      idMapInsert(action.userId, action.dashboard, state.userDashboards);
    return { ...state, userDashboards: newDashboards };
  case ActionType.LOAD_MY_DASHBOARD__BEGIN:
    return { ...state, myDashboard: loadable() };
  case ActionType.LOAD_MY_DASHBOARD__SUCCESS:
    return { ...state, myDashboard: loadable(action.dashboard) };
  }
}
