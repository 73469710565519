import * as React from 'react';

const CareTasksInstructions = () => {
  return (
    <div>
      <p>
        <strong className="onboard-instructions__why-how">Why? </strong>
        These are prescribed elements of your care plan.
      </p>
      <p>
        <strong className="onboard-instructions__why-how">How? </strong>
        Click or tap on the circle next to any Care Task - you can try it below.
      </p>
    </div>
  );
}

export default CareTasksInstructions;
