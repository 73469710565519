import * as React from 'react';
import * as moment from 'moment-timezone';
import {
  ChallengeDetails,
  ChallengeTemplate,
  ChallengeMetric,
  isChallengeDetails,
  normalizedData
} from 'Challenges/Data';

import {
  challengeRulesUrl,
  templateRulesUrl
} from 'Shared/Urls';

import Link from 'Shared/UI/Link';

import SecondaryButton from './SecondaryButton';
import GraphicHeader from './GraphicHeader';

type Subject = ChallengeDetails | ChallengeTemplate;

type SubjectData = {
  name: string,
  graphicUrl: string,
  startDate: moment.Moment,
  endDate: moment.Moment,
  metric: ChallengeMetric,
  actionButton: React.ReactNode,
  rulesButton: React.ReactNode
}

interface Props {
  item: Subject,
  enrollAction: () => void
}


export default function renderUnenrolledDetails(
  props: Props
): React.ReactElement<Props> {
  const data = itemData(props);

  return (
    <div className="challenge-unenrolled">
      <GraphicHeader challenge={props.item} />
      <div className="challenge-actions challenge__button-list">
        {data.actionButton}
        {data.rulesButton}
      </div>
    </div>
  );
}


function itemData(
  props: Props
): SubjectData {
  if (isChallengeDetails(props.item)) {
    return challengeDetailsData(props.item, props.enrollAction);
  } else {
    return templateSummaryData(props.item, props.enrollAction);
  }
}

function challengeDetailsData(
  chl: ChallengeDetails,
  enrollAction: () => void
): SubjectData {
  const summary = chl.summary;
  return {
    ...normalizedData(summary),
    actionButton: actionButton('Join Now', enrollAction),
    rulesButton: rulesButton(challengeRulesUrl(summary.id))
  };
}

function templateSummaryData(
  tpl: ChallengeTemplate,
  enrollAction: () => void
): SubjectData {
  return {
    ...normalizedData(tpl),
    actionButton: actionButton('Start', enrollAction),
    rulesButton: rulesButton(templateRulesUrl(tpl.id))
  };
}

function actionButton(
  label: string,
  action: () => void
): React.ReactNode {
  const classNames = [
    'button',
    'theme__primary-bg',
    'challenge__action-button'
  ].join(' ');
  return (
    <Link className={classNames} to={action}>
      {label}
    </Link>
  );
}

function rulesButton(
  url: string
): React.ReactNode {
  return (
    <SecondaryButton
      label="learn about this challenge"
      action={url}
      breadcrumb="push"
    />
  );
}
