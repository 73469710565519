import { compare } from 'Shared/Data/Version';
import { DeviceVersion, HRV, DeviceFamily } from '../Data';
import { chunks, decodeHighByteInBack, decodeMoment } from './Util'


// Command response (can lengthen the command):
//   0x56 ID1 ID2 YY MM DD HH mm SS D1 D2 D3 D4 P1 P2
// The minimum unit is 15 bytes, the total number of pieces of total data
// contained in a BLE packet can be dynamically controlled according to the
// actual maximum length of the transmitted packet.
// ID1 ID2: read the data number, high byte in the back.
// YY MM DD HH mm SS: stands for the date, year / month / day / hour / minute /
//   second.
// D1: HRV value.
// D2: Vascular aging value.
// D3: Heart rate value.
// D4: Stress level.
// P1: High blood pressure (SBP)
// P2: Low blood pressure (DBP)
/**
 * In firmware 1.6.7 and higher, the packet includes an additional byte at the
 * end of unknown purpose.  So, we have to increase the "chunk" size from 15 to
 * 16 on this version and above.
 */
export function decode(v: DeviceVersion): (packet: Uint8Array) => HRV[] {
  return packet => {
    const size = chunkSize(v);
    return chunks(size, packet).map(chunk => {
      const id = decodeHighByteInBack(chunk.slice(1, 3));
      const time = decodeMoment(chunk.slice(3, 9));
      const hrv = chunk[9];
      const vascularAgingValue = chunk[10];
      const heartRate = chunk[11];
      const stressLevel = chunk[12];
      const systolicBP = chunk[13];
      const diastolicBP = chunk[14];

      return {
        id,
        time,
        hrv,
        vascularAgingValue,
        heartRate,
        stressLevel,
        systolicBP,
        diastolicBP
      }
    });
  };
}

function chunkSize(v: DeviceVersion): number {
  if (v.family === DeviceFamily.JStyle1963YH) {
    if (compare(v.version, [1, 6, 7]) < 0) {
      return 15;
    } else {
      return 16;
    }
  } else if (v.family === DeviceFamily.JStyle1790) {
    return 17;
  } else {
    throw new Error(`unsupported device family for HRV: ${JSON.stringify(v)}`);
  }
}
