import * as React from 'react';

import * as Urls from 'Shared/Urls';
import Link from 'Shared/UI/Link';
import { ActiveGoal } from 'Goals/Data';


interface Props {
  activeGoal: ActiveGoal
  isMe: boolean
}

export default function renderActiveGoalListItem(
  props: Props
): React.ReactElement<Props> {
  return (
    <div className="goal-tile">
      {props.isMe ? goalLink(props) : itemContents(props)}
    </div>
  );
}

function goalLink(props: Props) {
  return (
    <Link to={Urls.goalUrl(props.activeGoal.goal.id)}
      themed={false}
      className="goal-tile__body-link"
      breadcrumb="push"
    >
      {itemContents(props)}
    </Link>
  );
}

function itemContents(props: Props) {
  const { goal, status } = props.activeGoal;
  return (
    <div>
      <h6 className="goal-tile__goal-name">{goal.name}</h6>
      <p className="goal-tile__goal-detail">{status.postCount} posts</p>
    </div>
  );
}
