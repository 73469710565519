import * as JSZip from 'jszip';

interface PackageManifest {
  manifest: {
    application: {
      bin_file: string,
      dat_file: string
    }
  }
}

class DFUPackage {
  private _initPacket: Uint8Array;
  private _firmwareImage: Uint8Array;

  constructor(private data: ArrayBuffer) {
    this.data = data;
  }

  /**
   * Checks the package to ensure it is the correct format, raising an error
   * (rejecting promise) if it is not
   */
  public async verify(): Promise<void> {
    return this.extract();
  }

  public async initPacket(): Promise<Uint8Array> {
    await this.extract();
    return this._initPacket;
  }

  public async firmwareImage(): Promise<Uint8Array> {
    await this.extract();
    return this._firmwareImage;
  }

  private async extract() {
    if (this._initPacket !== undefined && this._firmwareImage !== undefined) {
      return;
    }
    const zip = await JSZip.loadAsync(this.data);
    // Extract manifest
    const manifestZip = this.getZipFile(zip, 'manifest.json');
    const manifest: PackageManifest =
      await manifestZip.async('string').then(JSON.parse);

    // Extract init file aka "dat file"
    const initZip =
      this.getZipFile(zip, manifest.manifest.application.dat_file);
    this._initPacket = await initZip.async('uint8array');

    // Extract image file aka "bin file"
    const imageZip =
      this.getZipFile(zip, manifest.manifest.application.bin_file);
    this._firmwareImage = await imageZip.async('uint8array');
    console.log(
      `DFU Packet extracted - init length: ${this._initPacket.length}, `+
        `image length: ${this._firmwareImage.length}`
    )
  }

  private getZipFile(zip: JSZip, path: string): JSZip.JSZipObject {
    const file = zip.file(path);
    if (file === null) {
      throw new Error(`Could not read firmware package: ${path}`)
    }
    return file;
  }
}

export default DFUPackage;
