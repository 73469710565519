import { BP } from 'BLE/Protocols/JStyle1963YH/Data';
import { chunks, decodeMoment, decodeHighByteInBack } from './Util';

/**
   Reverse engineered from java sdk
// 0x67 ID1 ID2 YY MM DD HH mm SS BPS BPD
// ID1 ID2: read the data number, high byte in the back.
// YY MM DD HH mm SS: stands for the date, year / month / day / hour / minute /
//   second.
// HH: heart rate value.
// BPS - systolic bp
// BPD - diastolic bp
**/
export function decode(packet: Uint8Array): BP[] {
  return chunks(11, packet).map(chunk => {
    return {
      id: decodeHighByteInBack(chunk.slice(1, 3)),
      time: decodeMoment(chunk.slice(3, 9)),
      systolicBP: chunk[9],
      diastolicBP: chunk[10]
    }
  });
}
