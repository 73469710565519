import * as React from 'react';
import * as classnames from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { State, DispatchProps } from 'Store';
import { NavBarLayout } from 'Nav';
import * as Urls from 'Shared/Urls';
import Spinner from 'Shared/UI/Spinner';
import NoContent from 'misc/UI/NoContent';

import { TimeSeriesAPI, makeApi } from 'Api/TimeSeries';
import { store as tokenStore } from 'Auth/TokenStore';
import {
  CurrentUser,
  UserId,
  ActionCreators as UserAC,
  SEL as UserSEL
} from 'User';
import Link from 'Shared/UI/Link';
import buildGraph from 'TimeSeries/GraphD3Implementation';

type OwnProps = RouteComponentProps<URLParams>;

interface URLParams {
  userId: UserId
}

interface GraphState {
  loaded: boolean,
  noData: boolean
}

interface ConnectedProps {
  currentUser: CurrentUser
}

type Props = ConnectedProps & DispatchProps & OwnProps;

class GraphPage extends React.Component<Props, GraphState> {
  api: TimeSeriesAPI

  constructor(props: Props) {
    super(props);
    this.api = makeApi(tokenStore);
    this.state = { loaded: false, noData: false };
  }

  componentDidMount() {
    this.props.dispatch(UserAC.loadCurrentUser());
    this.api.getBiopostData(this.props.match.params.userId).then(data => {
      this.setState({ loaded: true });
      if (data.bioposts.length === 0) {
        this.setState({ noData: true });
      } else {
        this.setState({ noData: false });
        buildGraph('#graph', data, {
          startDate: new Date(Date.parse(data.start_time)),
          endDate: new Date(Date.parse(data.end_time)),
          scoreLabel: 'score'
        });
      }
    });
  }

  render() {
    const graphClasses =
      classnames({ 'graph--loaded': this.state.loaded && !this.state.noData });
    return (
      <NavBarLayout title="Health score" initBreadcrumb={Urls.dashboardUrl()}>
        <div className="simple-container">
          {this.renderUserInfo()}
          {this.state.loaded || <Spinner wrap="center" />}
          {this.state.loaded && this.state.noData && this.renderNoData()}
          <div id="graph" className={graphClasses}></div>
        </div>
      </NavBarLayout>
    );
  }

  renderNoData() {
    return (
      <NoContent message="no data to display" />
    );
  }

  renderUserInfo() {
    const name = UserSEL.userName(this.props.currentUser);
    if (name) {
      return (
        <div>
          <h3 className="graph-title">
            {name}
          </h3>
          <p className="graph-title graph-title--subtitle">
            last 90 days
          </p>
          <p className="graph-title graph-title--subtitle">
            (tap/scroll for detail)
          </p>
          <p className="graph-title graph-title--subtitle">
            <Link
              to={Urls.healthScoreWhatsThisUrl()}
              themed={true}
              breadcrumb="push"
            >
              learn more about score
            </Link>
          </p>
        </div>
      );
    }
  }
}

export default connect<ConnectedProps, DispatchProps, OwnProps, State>(
  (state: State) => ({ currentUser: state.currentUser }),
)(GraphPage);
