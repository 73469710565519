import * as React from 'react';

import { MinimalUser, UserId } from 'User/Data';
import { ThreadType, validThreadType } from 'Inbox/Data';
import * as Urls from 'Shared/Urls';
import Link from 'Shared/UI/Link';
import Button from 'Shared/UI/Button';
import NoContent from 'misc/UI/NoContent';

interface Props {
  carePros: MinimalUser[],
  crewMembers: MinimalUser[],
  onSelect: (type: ThreadType, userId: UserId) => void
}

interface State {
  selectValue: string | undefined
}

class NewMessageSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleClickGo = this.handleClickGo.bind(this);
    this.state = { selectValue: undefined };
  }

  disabled() {
    return (this.props.carePros.length + this.props.crewMembers.length) == 0;
  }

  handleSelectChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const rawValue = e.target.value;
    const selectValue = rawValue === '' ? undefined : rawValue;
    this.setState({ selectValue });
  }

  handleClickGo() {
    if (this.state.selectValue) {
      const data = unpackOptValue(this.state.selectValue);
      if (data) {
        this.props.onSelect(data.type, data.userId);
      }
    }
  }

  render() {
    const disabled = this.disabled();
    const valueSelected = this.state.selectValue !== undefined;
    return (
      <div className="inbox__new-message-select">
        <select
          disabled={disabled}
          value={this.state.selectValue}
          onChange={this.handleSelectChange}
          className="select inbox__new-message-select__dropdown"
        >
          <option value="">+ new message</option>
          {renderOptGroup(
             'Care Professionals', 'care_pro', this.props.carePros)}
          {renderOptGroup('Community', 'crew', this.props.crewMembers)}
        </select>

        <div className="text-right">
          <Button
            className="button button-gray inbox__new-message-select__go-button"
            disabled={disabled || !valueSelected}
            onClick={this.handleClickGo}
          >
            GO
          </Button>
        </div>

        {disabled && renderNobodyToMessage()}
      </div>
    );
  }
}

export default NewMessageSelect;

function renderOptGroup(
  label: string, type: ThreadType, users: MinimalUser[]
): React.ReactNode {
  if (users.length > 0) {
    return (
      <optgroup label={label}>
        {users.map(
           u => (
             <option key={u.id} value={packOptValue(type, u.id)}>
               {u.name}
             </option>
           )
         )}
      </optgroup>
    );
  }
}

function packOptValue(type: ThreadType, userId: string): string {
  return [type, userId].join('$');
}

function unpackOptValue(
  val: string
): { type: ThreadType, userId: string } | null {
  const [type, userId] = val.split('$');
  if (validThreadType(type)) {
    return { type, userId };
  } else {
    return null;
  }
}

function renderNobodyToMessage() {
  const userGuideLink = (
    <Link themed={true} to={Urls.userGuideUrl()} breadcrumb="push">
      User Guide
    </Link>
  );
  return (
    <NoContent>
      Looks like there's nobody to message. You can message with your Community
      (if any) and Care Professionals (if any). Learn more about Messages in
      the {userGuideLink}.
    </NoContent>
  );
}
