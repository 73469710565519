import * as React from 'react';
import FAIcon from 'Shared/UI/IconFontAwesome';

interface Props {
  name: string,
  className?: string
}

export default function renderIcon(
  props: Props
): React.ReactElement<Props> {
  return (
    <FAIcon
      name={props.name}
      className="fa-fw"/>
  );
}
