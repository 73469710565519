import * as React from 'react';

import {
  Profile, CrewMember, MinimalUser, UserType,
  userName, userId,
  isMinimalUser
} from 'User/Data';
import Score from './Score';
import ProfPic from 'Shared/UI/ProfPic';

type Variant = 'score' | 'prof_pic';

interface Props {
  user: Profile | CrewMember | MinimalUser
  variant: Variant
  contentFn?: (u: Profile | CrewMember | MinimalUser) => React.ReactNode
}

const DEFAULT_CONTENT_FN = userName;

export default function renderUserBanner(
  props: Props
): React.ReactElement<Props> {
  const user = props.user;
  const contentFn = props.contentFn || DEFAULT_CONTENT_FN;

  return (
    <div className="user-banner">
      <h2 className="banner-title user-banner__name">{contentFn(user)}</h2>
      <div className="user-banner__right">
        {renderVariant(props)}
      </div>
    </div>
  );
}

function renderVariant(props: Props): React.ReactNode {
  switch(props.variant) {
    case 'score':
      if (isMinimalUser(props.user)) {
        console.warn(
          'Can not display "score" variant of UserBanner with MinimalUser data',
          'Falling back to "prof_pic" variant'
        );
        return renderProfPicVariant(props.user);
      } else {
        return <Score value={props.user.healthScore} />;
      }
    case 'prof_pic':
      return renderProfPicVariant(props.user);
  }
}

function renderProfPicVariant(user: UserType) {
  return (
    <div className="user-banner__prof-pic">
      <ProfPic userId={userId(user)} size={200} />
    </div>
  );
}
