import * as R from 'ramda';
import * as BLE from 'BLE/Data';
import * as Ad from 'BLE/Advertisement';
import * as Data from './Data';
import * as Timeout from 'Shared/Timeout';

const DFU_DEVICE_NAME_MATCHER = /^Dfu/;

export default class Scanner {
  constructor(
    private bluetooth: BluetoothlePlugin.Bluetoothle
  ) {
    this.bluetooth = bluetooth;
  }

  public async scan(
    timeout=10000
  ): Promise<BLE.Device> {
    await this.stopScan();
    return await Timeout.timeout(
      timeout,
      resolve => {
        this.bluetooth.startScan(
          async result => {
            if (result.status === 'scanResult' && this.isDFUDevice(result)) {
              await this.stopScan();
              resolve(result);
            }
          },
          console.error,
          { services: [Data.SERVICE_UUID] }
        );
      }
    )
  }

  private stopScan(): Promise<void> {
    return new Promise(
      resolve => this.bluetooth.stopScan(
        () => resolve(),
        () => resolve()
      )
    );
  }

  private isDFUDevice(device: BluetoothlePlugin.ScanStatus): boolean {
    if (!DFU_DEVICE_NAME_MATCHER.test(device.name)) { return false; }
    let serviceUuids: string[];
    if (typeof(device.advertisement) === 'string') {
      const ad =
        Ad.parse(this.bluetooth.encodedStringToBytes(device.advertisement));
      serviceUuids = ad.incompleteServiceUuids16bit || [];
    } else {
      serviceUuids = device.advertisement.serviceUuids;
    }
    return R.any(
      uuid => uuid.toUpperCase() === Data.SERVICE_UUID,
      serviceUuids
    );
  }
}
