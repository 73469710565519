import * as R from 'ramda';
import * as React from 'react';
import * as classnames from 'classnames';

import HorizontalFormField from './HorizontalFormField';
import Label from './HorizontalFieldLabel';

type Props = React.HTMLProps<HTMLInputElement> & {
  label: React.ReactNode,
  error?: React.ReactNode,
  twoLineLabel?: boolean
}

function renderHorizontalTextField(props: Props): React.ReactElement<Props> {
  const inputProps: React.HTMLProps<HTMLInputElement> = R.compose(
    R.dissoc('label'), R.dissoc('error'), R.dissoc('twoLineLabel')
  )(props);

  const classes = classnames(
    inputProps.className, 'text-field', 'horizontal-form-field__input');

  const label = (
    <Label content={props.label} twoLine={props.twoLineLabel}/>
  );

  const field = <input {...inputProps} className={classes} />;

  return (
    <HorizontalFormField label={label} field={field} error={props.error} />
  );
}

export default renderHorizontalTextField;
