import { NSType, NS, Goal, GoalStatus, GoalId } from './Data';
import { Activity } from 'Post';
import { UserId } from 'User';
import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';

export enum ActionType {
  LOAD_GOALS__SUCCESS = 'LOAD_GOALS__SUCCESS,',
  LOAD_GOAL_STATUSES__SUCCESS = 'LOAD_GOAL_STATUSES__SUCCESS,',
  LOAD_GOAL_ACTIVITIES__SUCCESS = 'LOAD_GOAL_ACTIVITIES__SUCCESS,',
  INCREMENT_POST_COUNT = 'INCREMENT_POST_COUNT,',
  UPDATE_GOAL_STATUS = 'UPDATE_GOAL_STATUS',
  BEGIN_POLLING = 'BEGIN_POLLING',
  END_POLLING = 'END_POLLING'
}

export type Action
  = LoadGoals_Success
  | LoadGoalStatuses_Success
  | LoadGoalActivities_Success
  | IncrementPostCount
  | UpdateGoalStatus
  | BeginPolling
  | EndPolling;

export interface LoadGoals_Success {
  type: ActionType.LOAD_GOALS__SUCCESS,
  goals: Goal[]
}

export interface LoadGoalStatuses_Success {
  type: ActionType.LOAD_GOAL_STATUSES__SUCCESS,
  userId: string,
  goals: Goal[],
  goalStatuses: GoalStatus[]
}

export interface LoadGoalActivities_Success {
  type: ActionType.LOAD_GOAL_ACTIVITIES__SUCCESS,
  activities: Activity[],
  goalId: GoalId
}

export interface IncrementPostCount {
  type: ActionType.INCREMENT_POST_COUNT,
  userId: UserId,
  goalId: GoalId
}
export interface UpdateGoalStatus {
  type: ActionType.UPDATE_GOAL_STATUS,
  userId: UserId,
  goalId: GoalId,
  status: GoalStatus
}

export interface BeginPolling {
  type: ActionType.BEGIN_POLLING
}

export interface EndPolling {
  type: ActionType.END_POLLING
}

export type NSAction = WithNS<NSType, Action>;
export const withNS: (a: Action) => NSAction = originalWithNS(NS);
