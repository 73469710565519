import * as moment from 'moment-timezone';
import {
  TrendData, Dataset, DataPoint, Metric, MetricKey, TrendWindow
} from './Data';

type UnixTimeJSON = number;

export interface TrendDataResponseJSON {
  trend_data: TrendDataJSON
}

interface TrendDataJSON {
  start_date: string,
  end_date: string,
  user_id: string,
  datasets: DatasetJSON[]
}

interface DatasetJSON {
  key: MetricKeyJSON,
  points: DataPointJSON[],
  units: string,
  min: number,
  max: number,
  step_size?: number,
  goal?: number,
  label: string
}

interface DataPointJSON {
  time: UnixTimeJSON,
  value: number
}

export interface MetricJSON {
  label: string,
  key: MetricKeyJSON
}

export type MetricKeyJSON = MetricKey;

export interface PeriodJSON {
  window: TrendWindow,
  date: string
}

export interface TrendMetricsResponseJSON {
  metrics: MetricJSON[],
  initial_metric_key: MetricKeyJSON | null
}

export function metricFromJSON(json: MetricJSON): Metric {
  return { label: json.label, key: metricKeyFromJSON(json.key) };
}

export function metricKeyFromJSON(json: MetricKeyJSON): MetricKey {
  return json;
}

export function metricKeyToJSON(key: MetricKey): MetricKeyJSON {
  return key;
}

export function trendDataFromJSON(json: TrendDataJSON): TrendData {
  return {
    startDate: moment(json.start_date, 'YYYY-MM-DD').startOf('day'),
    endDate: moment(json.end_date, 'YYYY-MM-DD').endOf('day'),
    datasets: json.datasets.map(datasetFromJSON)
  }
}

function datasetFromJSON(json: DatasetJSON): Dataset {
  return {
    key: metricKeyFromJSON(json.key),
    points: json.points.map(dataPointFromJSON),
    units: json.units,
    min: json.min,
    max: json.max,
    stepSize: json.step_size,
    goal: json.goal,
    label: json.label
  };
}

function dataPointFromJSON(json: DataPointJSON): DataPoint {
  return {
    time: unixTimeFromJSON(json.time),
    value: json.value
  };
}

function unixTimeFromJSON(json: number): moment.Moment {
  return moment.unix(json);
}
