import * as React from 'react';
import { bem } from 'css-util';
import { Props } from './FieldProps';

const CSS = bem('screening-field');

const DateField = (props: Props) => {
  const field = props.field;
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(field, e.target.value);
  };

  return (
    <div className={CSS()()}>
      <div className={CSS('label')(['inline'])}>
        <label>{field.label}</label>
      </div>
      <div className={CSS('field')(['inline'])}>
        <input type="date"
          className={CSS('input')(['date'])}
          placeholder="YYYY-MM-DD"
          onChange={onChange}
          value={props.value || ''}
        />
      </div>
    </div>
  );
};

export default DateField;
