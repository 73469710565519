import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';
import { UserId } from 'User/Data';
import { NS, NSType, MyDashboard, UserDashboard } from 'Dashboard/Data';

export enum ActionType {
  LOAD_MY_DASHBOARD__BEGIN = 'LOAD_MY_DASHBOARD__BEGIN',
  LOAD_MY_DASHBOARD__SUCCESS = 'LOAD_MY_DASHBOARD__SUCCESS',
  LOAD_USER_DASHBOARD__SUCCESS = 'LOAD_USER_DASHBOARD__SUCCESS'
}

export type Action
  = LoadMyDashboard_Begin
  | LoadMyDashboard_Success
  | LoadUserDashboard_Success;

interface LoadMyDashboard_Begin {
  type: ActionType.LOAD_MY_DASHBOARD__BEGIN
}

interface LoadMyDashboard_Success {
  type: ActionType.LOAD_MY_DASHBOARD__SUCCESS
  dashboard: MyDashboard
}

interface LoadUserDashboard_Success {
  type: ActionType.LOAD_USER_DASHBOARD__SUCCESS
  userId: UserId
  dashboard: UserDashboard
}

export type NSAction = WithNS<NSType, Action>;
export const withNS = originalWithNS<NSType, Action>(NS);
