import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { challengesUrl, challengeSetupUrl } from 'Shared/Urls';

import { State, DispatchProps } from 'Store';

import {
  SEL,
  LENSES,
  ChallengeId,
  ChallengeDetails,
  LeaderboardMode,
  requiresPreJoinSetup
} from 'Challenges/Data';
import * as Data from 'Challenges/Data';

import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

import { ActionCreators } from 'Challenges/ActionCreator';

import { HUD } from 'HUD';
import { NavBarLayout } from 'Nav';
import Spinner from 'Shared/UI/Spinner';

import Leaderboard from 'Challenges/UI/Leaderboard';
import EnrolledDetails from 'Challenges/UI/EnrolledDetails';
import UnenrolledDetails from 'Challenges/UI/UnenrolledDetails';

type Props = State & RouteComponentProps<URLParams> & DispatchProps & {
  mode: LeaderboardMode
};

type URLParams = {
  challengeId: string
}

class ChallengePage extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(ActionCreators.loadChallenge(this.challengeId()));
    this.loadTeamLeaderboard();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.leaderboardMode() !== this.leaderboardMode(prevProps)) {
      this.loadTeamLeaderboard();
    }
  }

  loadTeamLeaderboard() {
    if (this.leaderboardMode() === 'teams') {
      this.props.dispatch(
        ActionCreators.loadTeamLeaderboard(
          this.challengeId(), 1, { perPage: 5, includeMyTeam: true }
        )
      );
    }
  }

  challengeId(): ChallengeId {
    return parseInt(this.props.match.params.challengeId);
  }

  challengeDetails(): ChallengeDetails | undefined {
    return LENSES.challenge(this.challengeId()).get(this.props.challenges);
  }

  teamLeaderboard(): Data.Leaderboard | undefined {
    return SEL.leaderboard(this.challengeId(), 'teams', this.props.challenges);
  }

  /**
   * Return a thunk that executes the "enrollment" action for this given
   * challenge.  This is either an action that joins the challenge, or one that
   * navigates to the required pre-join interstitial
   */
  enrollAction(details: ChallengeDetails): () => void {
    if (requiresPreJoinSetup(details.summary)) {
      return () => {
        this.props.dispatch(
          push(challengeSetupUrl(details.summary.id))
        );
      }
    } else {
      return () => this.props.dispatch(
        ActionCreators.joinChallenge(details.summary.id)
      );
    }
  }

  leaderboardMode(props?: Props): LeaderboardMode {
    props = props || this.props;
    return props.mode;
  }

  render() {
    const details = this.challengeDetails();
    const dispatch = this.props.dispatch;
    if (details) {
      const { summary } = details;
      if (summary.progress) {
        return this.renderLayout(
          <EnrolledDetails
            challenge={details}
            leaveChallenge={id => dispatch(ActionCreators.leaveChallenge(id))}
            addSteps={(id, steps) =>
              dispatch(ActionCreators.addSteps(id, steps))}
            pollForUpdate={id => dispatch(ActionCreators.pollForUpdate(id))}
            leaderboardMode={this.leaderboardMode()}
            leaderboard={this.renderLeaderboard(details)}
          />
        );
      } else {
        return this.renderLayout(
          <UnenrolledDetails
            item={details}
            enrollAction={this.enrollAction(details)}/>
        );
      }
    } else {
      return this.renderLayout(<HUD state="loading"></HUD>);
    }
  }

  renderLayout(contents: React.ReactNode): React.ReactElement<Props> {
    return (
      <NavBarLayout
        title="Challenge"
        initBreadcrumb={challengesUrl()}>
        <div className="simple-container">
          {contents}
        </div>
      </NavBarLayout>
    );
  }

  renderLeaderboard(details: ChallengeDetails): React.ReactNode {
    if (this.leaderboardMode() === 'players') {
      return this.renderPlayersLeaderboard(details);
    } else {
      return this.renderTeamsLeaderboard(details);
    }
  }

  renderPlayersLeaderboard(details: ChallengeDetails): React.ReactNode {
    return (
      <Leaderboard
        mode="players"
        players={details.topPlayers}
        challenge={details}
      />
    );
  }

  renderTeamsLeaderboard(details: ChallengeDetails): React.ReactNode {
    const teamLeaderboard = this.teamLeaderboard();
    if (teamLeaderboard && teamLeaderboard.players) {
      return (
        <Leaderboard
          mode="teams"
          challenge={details}
          players={teamLeaderboard.players}
        />
      );
    } else {
      return <Spinner wrap="center" />;
    }
  }
}

export default withRouter(defaultConnect(ChallengePage));
