import * as moment from 'moment-timezone';
import * as React from 'react';
import * as R from 'ramda';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { State as StoreState } from 'Store';
import { ActionCreators as PostActions, Biopost } from 'Post';
import { ActionCreators as UserActions } from 'User';
import { Specs, ExpandedSpecType, SpecUpdate } from '../Data';

import Spinner from 'Shared/UI/Spinner';
import SpecForms from './SpecForms';

interface Props {
  show?: ExpandedSpecType[],
  afterSpecUpdate?: (post: Biopost, specUpdate: SpecUpdate) => void,
  postTime?: moment.Moment
}

interface ConnectedProps {
  specs?: Specs,
  exposedSpecs?: ExpandedSpecType[],
  loadSpecs: () => void,
  loadCurrentUser: () => void,
  submitSpecUpdate: (
    specUpdate: SpecUpdate,
    onSuccess?: (post: Biopost, update: SpecUpdate) => void
  ) => void,
  savePrivateSpecsSetting: (value: boolean) => void
}

type AllProps = Props & ConnectedProps;

class ConnectedSpecForms extends React.Component<AllProps, {}> {
  constructor(props: AllProps) {
    super(props);
    this.submitHandler = this.submitHandler.bind(this);
  }

  componentDidMount() {
    this.props.loadCurrentUser();
    this.props.loadSpecs();
  }

  postTime(): moment.Moment {
    return this.props.postTime || moment();
  }

  submitHandler(specUpdate: SpecUpdate) {
    if (specUpdate.time === undefined) {
      specUpdate = { ...specUpdate, time: this.postTime() };
    }
    this.props.submitSpecUpdate(
      specUpdate,
      this.props.afterSpecUpdate
    );
  }

  render() {
    const { specs, exposedSpecs } = this.props;

    if (specs !== undefined && exposedSpecs !== undefined) {
      return (
        <SpecForms
          specs={specs}
          submitSpecUpdate={this.submitHandler}
          show={R.intersection(this.props.show || exposedSpecs, exposedSpecs)}
        />
      );
    } else {
      return <p className="text-center"><Spinner></Spinner></p>;
    }
  }
}

const connected = connect(
  (state: StoreState) => {
    const currentUser = state.currentUser;
    if (currentUser !== undefined && currentUser.program !== undefined) {
      return {
        specs: currentUser.specs,
        exposedSpecs: currentUser.program.exposedSpecs
      };
    } else {
      return {};
    }
  },
  (dispatch: Dispatch<StoreState>) => ({
    loadSpecs() {
      dispatch(UserActions.loadSpecs());
    },
    loadCurrentUser() {
      dispatch(UserActions.loadCurrentUser());
    },
    submitSpecUpdate(
      specUpdate: SpecUpdate,
      onSuccess?: (post: Biopost, update: SpecUpdate) => void
    ): void {
      dispatch(PostActions.postSpecUpdate(specUpdate, onSuccess));
    }
  })
)(ConnectedSpecForms as any);

export default connected as React.ComponentClass<Props>;
