export type SortDir = 'asc' | 'desc';

export interface ColumnData {
  key: string,
  label: string,
  defaultSortDir: SortDir,
  isSortable: boolean
}

export function sortDirFromString(str: string): SortDir {
  const asc:  SortDir = 'asc';
  const desc: SortDir =  'desc';
  switch(str) {
  case 'asc':
      return asc;
  case 'desc':
      return desc;
  default:
      return asc;
  }
}
