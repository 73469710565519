function scrollTo(Y, duration, easingFunction, callback) {
  var start, elem, from, to, boundingHeight;

  /* Shenanigans below used to work around the fact that some browsers only
   * scroll when using document.body, others document.documentElement, but
   * all browsers seem to define both, so life is basically like a box of
   * chocolates.
   */
  start = Date.now();
  elem = [document.documentElement, document.body];
  from = Math.max.apply(Math, elem.map(function(e) { return e.scrollTop; }));
  boundingHeight = Math.max.apply(Math, elem.map(function(e) {
    return e.getBoundingClientRect().height;
  }));
  to = from + boundingHeight;


  function setScrollTop(value) {
    elem.forEach(function(e) { e.scrollTop = value; });
  }

  if(from < Y && to > Y) {
    if (callback) { callback(); }
    return; /* Prevent scrolling to the Y point if already there */
  }

  function min(a,b) {
    return a<b?a:b;
  }

  function scroll(timestamp) {
    var currentTime = Date.now(),
    time = min(1, ((currentTime - start) / duration)),
    easedT = easingFunction(time);
    setScrollTop((easedT * (Y - from)) + from);

    if(time < 1) { requestAnimationFrame(scroll); }
    else if (callback) { callback(); }
  }

  requestAnimationFrame(scroll);
}

/* bits and bytes of the scrollTo function inspired by the works of Benjamin DeCock */

/*
 * Easing Functions - inspired from http://gizma.com/easing/
 * only considering the t value for the range [0, 1] => [0, 1]
 */
var easing = {
  // no easing, no acceleration
  linear: function (t) { return t; },
  // accelerating from zero velocity
  easeInQuad: function (t) { return t*t; },
  // decelerating to zero velocity
  easeOutQuad: function (t) { return t*(2-t); },
  // acceleration until halfway, then deceleration
  easeInOutQuad: function (t) { return t<0.5 ? 2*t*t : -1+(4-2*t)*t; },
  // accelerating from zero velocity
  easeInCubic: function (t) { return t*t*t; },
  // decelerating to zero velocity
  easeOutCubic: function (t) { return (--t)*t*t+1; },
  // acceleration until halfway, then deceleration
  easeInOutCubic: function (t) { return t<0.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1; },
  // accelerating from zero velocity
  easeInQuart: function (t) { return t*t*t*t; },
  // decelerating to zero velocity
  easeOutQuart: function (t) { return 1-(--t)*t*t*t; },
  // acceleration until halfway, then deceleration
  easeInOutQuart: function (t) { return t<0.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t; },
  // accelerating from zero velocity
  easeInQuint: function (t) { return t*t*t*t*t; },
  // decelerating to zero velocity
  easeOutQuint: function (t) { return 1+(--t)*t*t*t*t; },
  // acceleration until halfway, then deceleration
  easeInOutQuint: function (t) { return t<0.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t; }
};

function elementIsVisible(el) {
  var rect = el.getBoundingClientRect(),
  height = window.document.documentElement.clientHeight;

  return rect.top > 0 && rect.top < height;

}

function getElTop(el) {
  return el.getBoundingClientRect().top + window.pageYOffset;
}


// export const toTop: () => void
export const toTop = () => window.scrollTo(0, 0);

// export const toElement: (sel: string | Element) => void;
export const toElement = sel => {
  const el = document.querySelector(sel);
  // Browsers now support this natively, but not sure how far back that support
  // goes
  // if (el) { el.scrollIntoView(); }
  if (el && !elementIsVisible(el)) {
    scrollTo(getElTop(el) - 30, 600, easing.easeOutQuad);
  }
};
