import './index.scss';

export * from './Data';

export { ActionType, Action } from './Action';
export { ActionCreators } from './ActionCreator';
export { reducer } from './Store';

import ProfilePage from './Page/ProfilePage';
import NotificationsPage from './Page/NotificationsPage';
import GoalsPage from './Page/GoalsPage';

export const Pages = {
  ProfilePage,
  NotificationsPage,
  GoalsPage
};
