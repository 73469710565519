export * from 'BLE/Util';
import { lsbDecode } from 'BLE/Util';

/**
 * Determine if this packet is the end of a variable length sequence response to
 * the provided command
 */
export function isFinalPacket(cmd: number, packet: Uint8Array): boolean {
  const end = packet.slice(packet.length - 2, packet.length);
  return end[0] === cmd && end[1] === 0xff;
}

export const decodeHighByteInBack = lsbDecode;
