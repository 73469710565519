import * as React from 'react';

import { AuthSettings } from 'Auth/Data';

interface Props {
  settings: AuthSettings
}

const AuthHelp = (props: Props) => {
  const settings = props.settings;
  if (settings.helpHtml) {
    return (
      <p className="auth__note">
        <span dangerouslySetInnerHTML={{__html: settings.helpHtml}} />
      </p>
    );
  } else {
    return null;
  }
}

export default AuthHelp;
