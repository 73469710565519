import * as React from 'react';

import { SpecUpdate, BloodPressure } from '../Data';
import { Status } from './SpecFormLayout';
import SpecFormLayout from './SpecFormLayout';

interface Props {
  systolic: number | undefined,
  diastolic: number | undefined,
  submit: (update: SpecUpdate) => void
}

interface State {
  inputValue: string,
  status: Status
}

class BloodPressureForm extends React.Component<Props, State> {
  timeout?: number

  constructor(props: Props) {
    super(props);
    this.state = {
      inputValue: combinedBPString(props),
      status: 'default'
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.systolic !== this.props.systolic ||
        prevProps.diastolic !== this.props.diastolic) {
      this.setState({
        ...this.state,
        inputValue: combinedBPString(this.props)
      });
    }

    if (this.state.status === 'loading') {
      this.setState({ ...this.state, status: 'success' })

      this.timeout = window.setTimeout(
        () => this.setState({ ...this.state, status: 'default' }),
        3000
      );
    }
  }

  componentWillUnmount() {
    if (this.timeout) { window.clearTimeout(this.timeout); }
  }

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const el = e.target as HTMLInputElement;
    this.setState({ ...this.state, inputValue: el.value });
  }

  submit = () => {
    const bp = extractBP(this.state.inputValue);
    if (bp) {
      this.props.submit(bp);
      this.setState({ ...this.state, status: 'loading' });
    }
  }

  submitIfChanged = () => {
    const bp = extractBP(this.state.inputValue);
    if (bp && bpChanged(this.props, bp)) {
      this.submit();
    }
  }

  render() {
    return (
      <div>
        <SpecFormLayout
          label="blood pressure"
          labelFor="spec_blood_pressure"
          status={this.state.status}
          onSubmit={this.submit}
        >
          <input type="text"
            value={this.state.inputValue}
            onChange={this.onChange}
            placeholder="systolic / diastolic"
            className="measurement-form__text-input"
            id="spec_blood_pressure"
            onBlur={this.submitIfChanged} />
        </SpecFormLayout>
      </div>
    );
  }
}

export default BloodPressureForm;

function combinedBPString(props: Props): string {
  if (props.systolic && props.diastolic) {
    return props.systolic.toString() + ' / ' + props.diastolic.toString();
  } else {
    return '';
  }
}

function extractBP(combinedBP: string): BloodPressure | undefined {
  if (validCombinedBP(combinedBP)) {
    const [systolic, diastolic] = combinedBP
      .replace(/\s/g, '')
      .split('/')
      .map(i => parseInt(i));
    return { systolic, diastolic };
  }
};

const VALID_BP = /^\s*\d+\s*\/\s*\d+\s*$/;

function validCombinedBP(combinedBP: string | undefined): boolean {
  return combinedBP !== undefined && VALID_BP.test(combinedBP);
}

function bpChanged(props: Props, bp: BloodPressure): boolean {
  return props.systolic === undefined
      || props.diastolic === undefined
      || props.systolic !== bp.systolic
      || props.diastolic !== bp.diastolic;
}
