import * as React from 'react';

import { ThreadInfo } from 'Inbox/Data';
import ThreadListItem from 'Inbox/UI/ThreadListItem';

interface Props {
  threadInfos: ThreadInfo[]
}

export default function renderThreadList(
  props: Props
): React.ReactElement<Props> {
  return (
    <ul className="inbox__thread-list">
      {props.threadInfos.map(t =>
        <ThreadListItem threadInfo={t} key={t.id}/>
       )}
    </ul>
  );
}
