import { Either } from 'Shared/Data/Either';
import { ApiClient, GET } from 'Api';

type HealthCheckResult = Promise<Either<Response, { ok: true }>>

export interface MaintenanceAPI {
  healthCheck(): HealthCheckResult
}

export function makeApi(client: ApiClient): MaintenanceAPI {
  return { healthCheck };

  function healthCheck(): HealthCheckResult {
    return client.eitherRawJSON(GET, '/health_check');
  }
}
