import * as React from 'react';
import * as classnames from 'classnames';

interface Props {
  children?: React.ReactNode,
  key?: string,
  className?: string
}

const PostMenuList = (props: Props) => {
  const classes = classnames('post-menu', props.className);
  return (
    <ul key={props.key} className={classes}>{props.children}</ul>
  );
}

export default PostMenuList;
