import * as React from 'react';

import { State, StoreDispatch } from 'Store';
import { RouteComponentProps } from 'react-router-dom'
import { defaultConnect } from 'Shared/ReduxComponent';
import { safeLink } from 'Shared/SafeLink';
import { goalsUrl } from 'Shared/Urls';
import Spinner from 'Shared/UI/Spinner';

import { HUD } from 'HUD';
import { ActionCreators } from 'Goals/ActionCreator';
import { SEL, GoalId, ActiveGoal } from 'Goals/Data';
import { UserId, SEL as UserSEL } from 'User';
import { Biopost, Activity } from 'Post';
import { NavBarLayout } from 'Nav';
import FeatureGate from 'App/UI/FeatureGate';
import GoalDetailTable from 'Goals/UI/GoalDetailTable';
import GoalPostMenu from 'Goals/UI/GoalPostMenu';

type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch
}

interface URLParams {
  goalId: string
}

class GoalDetails extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(ActionCreators.loadStatusesForCurrentUser());
    this.props.dispatch(ActionCreators.loadActivitiesForGoal(this.goalId()));
  }

  goalId(): GoalId {
    return this.props.match.params.goalId;
  }

  userId(): UserId | undefined {
    return UserSEL.userId(this.props.currentUser);
  }

  activities(): Activity[] | undefined {
    return SEL.goalActivities(this.goalId(), this.props.goals);
  }

  polling(): boolean {
    return SEL.polling(this.props.goals);
  }

  afterPost = (b: Biopost) => {
    this.props.dispatch(ActionCreators.goalActivityWasPosted(this.goalId(), b));
  }

  render() {
    const userId = this.userId();
    const activeGoal =
      userId ?
      SEL.activeGoal(userId, this.goalId(), this.props.goals) :
      undefined;
    return (
      <NavBarLayout title="goal summary" initBreadcrumb={goalsUrl()}>
        <HUD state={this.props.hud} />
        {activeGoal ?
         this.renderContents(activeGoal) :
         <Spinner wrap="center" />
        }
      </NavBarLayout>
    );
  }

  renderContents(activeGoal: ActiveGoal) {
    const { goal, status } = activeGoal;
    const activities = this.activities();
    return (
      <div>
        <h2 className="goal-details__title">{goal.name}</h2>
        <div className="goal-details">
          <p dangerouslySetInnerHTML={{__html: safeLink(goal.details)}}
            className="goal-details__more-detail"/>
          <GoalDetailTable
            goal={goal}
            status={status}
            polling={this.polling()}/>
          {activities &&
           <GoalPostMenu
             goal={goal}
             postState={this.props.post}
             activities={activities}
             afterPost={this.afterPost}
           />
          }
        </div>
      </div>
    );
  }
}

export default FeatureGate(
  defaultConnect(GoalDetails),
  ['goals']
);
