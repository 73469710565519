export * from './Data';
export * from './Action';
export * from './ActionCreator';
export { reducer } from './Store';

import Dashboard from './Page/Dashboard';
import Invite from './Page/Invite';

const Pages = {
  Dashboard,
  Invite
};

export { Pages };
