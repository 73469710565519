import { ActionCreatorThunk } from 'Store';
import { UserId } from 'User/Data';
import { dateToJSON } from 'Shared/JSON';
import {
  metricFromJSON, metricKeyFromJSON, trendDataFromJSON, metricKeyToJSON
} from 'Trends/JSON';
import { ActionCreators as HUD } from 'HUD';
import { DataRequest } from './Data';
import { NSAction, ActionType, withNS } from './Action';

export interface ActionCreators {
  loadAvailableTrends(userId: UserId): ActionCreatorThunk

  loadTrendData(request: DataRequest): ActionCreatorThunk

  clearTrendData(): NSAction
}

export const ActionCreators: ActionCreators = {
  loadAvailableTrends,

  loadTrendData,

  clearTrendData
}

function loadAvailableTrends(userId: UserId): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.trends.getAvailableMetrics(userId).then(result => {
      dispatch(HUD.close(0));
      dispatch(withNS({
        type: ActionType.LOAD_AVAILABLE_METRICS__SUCCESS,
        metrics: result.metrics.map(metricFromJSON),
        initialMetricKey:
          !!result.initial_metric_key ?
            metricKeyFromJSON(result.initial_metric_key) : null
      }));
    });
  }
}

function loadTrendData(request: DataRequest): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    dispatch(withNS({ type: ActionType.LOAD_TREND_DATA__BEGIN, request }));
    return api.trends.getTrendData(
      request.userId,
      metricKeyToJSON(request.key),
      { window: request.window, date: dateToJSON(request.date) }
    ).then(result => {
      dispatch(HUD.close(0));
      const trendData = trendDataFromJSON(result.trend_data);
      dispatch(
        withNS({ type: ActionType.LOAD_TREND_DATA__SUCCESS, trendData })
      );
    });
  };
}

function clearTrendData(): NSAction {
  return withNS({ type: ActionType.CLEAR_TREND_DATA });
}
