import * as React from 'react';

interface Props {
  className?: string
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  children?: React.ReactNode,
  type?: 'submit' | 'reset' | 'button'
}
export default function renderButton(
  props: Props
): React.ReactElement<Props> {
  const htmlProps = {
    className: props.className,
    disabled: props.disabled,
    onClick: props.onClick,
    type: props.type
  };
  return (
    <button {...htmlProps}>
      {props.children}
    </button>
  );
}
