import * as React from 'react';
import * as classnames from 'classnames';
import { bem } from 'css-util';
import Button from 'Shared/UI/Button';

const CSS = bem('auth-form');

interface Props {
  children: React.ReactNode
}

const AuthFormSubmit = (props: Props) => (
  <div className={CSS('field-row')(['submit'])}>
    <Button
      className={classnames('button', 'theme__primary-bg', CSS('submit')())}>
      {props.children}
    </Button>
  </div>
);

export default AuthFormSubmit;
