interface ENVType {
  // URL base of this app (user app)
  URL_BASE: string,
  // URL base of api servier
  API_URL_BASE: string,
  API_VERSION: string,
  // URL base of server that provides authentication and oauth
  AUTH_URL_BASE: string,
  // URL base of the "public" marketing webiste
  PUBLIC_URL_BASE: string,
  BUILD_NUMBER: string,
  DEBUG?: string,
  AUTH_COOKIE_LOGIN_KEY: string,
  AUTH_COOKIE_DOMAIN: string,
  D3_URL: string,
  SENTRY_DSN: string,
  ENABLE_TRACKING: string,
  CUSTOM_BUILD?: CustomBuildInfo
}

interface CustomBuildInfo {
  // This is the "native app slug", NOT the program slug
  SLUG: string
  CANONICAL_PROGRAM_SLUG?: string
}

const DEFAULT_CUSTOM_BUILD_INFO: CustomBuildInfo = {
  SLUG: '1bios'
};

const ENV: ENVType = (window as any).ENV;

const __CORDOVA__ = !!(window as any).__CORDOVA__;
export const CORDOVA = __CORDOVA__;

export const DEBUG: boolean = !!ENV.DEBUG;

export const URL_BASE: string = ENV.URL_BASE;

export const API_URL_BASE: string = ENV.API_URL_BASE;

export const API_VERSION: string = ENV.API_VERSION;

export const AUTH_URL_BASE: string = ENV.AUTH_URL_BASE;

export const BUILD_NUMBER: string = ENV.BUILD_NUMBER;

export const AUTH_COOKIE_LOGIN_KEY: string = ENV.AUTH_COOKIE_LOGIN_KEY;

export const AUTH_COOKIE_DOMAIN: string = ENV.AUTH_COOKIE_DOMAIN;

export const PUBLIC_URL_BASE: string = ENV.PUBLIC_URL_BASE;

export const CUSTOM_BUILD: CustomBuildInfo =
  ENV.CUSTOM_BUILD || DEFAULT_CUSTOM_BUILD_INFO;

export const NATIVE_APP_SLUG = CUSTOM_BUILD.SLUG;

export const STORE_LOGGER = false;

export const BLUETOOTH_LOG = false;

export const EDGE_LOG = false;

export const HEALTH_KIT_LOG = false;

export const BACKGROUND_FETCH_LOG = false;

export const REMOTE_LOG = false;

export const DFU_LOG = false;
