import * as React from 'react';

import { StepKey } from 'Onboard/Data';
import EdgeInstructions from './StepInstructions/Edge';
import PostInstructions from './StepInstructions/Post';
import SpecsInstructions from './StepInstructions/Specs';
import GoalsInstructions from './StepInstructions/Goals';
import CareTasksInstructions from './StepInstructions/CareTasks';
import InboxInstructions from './StepInstructions/Inbox';


interface Props {
  stepKey: StepKey
}

const StepInstructions: React.SFC<Props> = (props: Props) => {
  switch(props.stepKey) {
    case 'edge':
      return <EdgeInstructions />;
    case 'post':
      return <PostInstructions />;
    case 'specs':
      return <SpecsInstructions />;
    case 'goals':
      return <GoalsInstructions />;
    case 'careTasks':
      return <CareTasksInstructions />;
    case 'inbox':
      return <InboxInstructions/>;
  }
}

export default StepInstructions;
