import * as moment from 'moment-timezone';
import { ApiClient, GET, POST } from 'Api';
import { CarePlanActionStatusesResponseJSON } from './JSON/CarePlans';
import { dateToJSON } from './JSON/Shared';

export interface CarePlansAPI {
  getActionStatuses: (
    date: moment.Moment
  ) => Promise<CarePlanActionStatusesResponseJSON>,

  undoTaskAction: (
    date: moment.Moment,
    actionId: number
  ) => Promise<void>
}

export function makeApi(client: ApiClient): CarePlansAPI {
  return {
    getActionStatuses,
    undoTaskAction
  };

  function getActionStatuses(
    date: moment.Moment
  ): Promise<CarePlanActionStatusesResponseJSON> {
    return client.requestJSON(
      GET,
      { version: 3, path: `/care_plan_action_statuses/${dateToJSON(date)}` }
    );
  }

  function undoTaskAction(
    date: moment.Moment,
    actionId: number
  ): Promise<void> {
    return client.requestJSON(
      POST,
      {
        version: 3,
        path: `/care_plan_action_statuses/${dateToJSON(date)}/undo_task_action`
      },
      { action_id: actionId }
    );
  }
}
