import * as R from 'ramda';
import * as React from 'react';

import * as TOD from 'Shared/Data/TimeOfDay';
import * as DOW from 'Shared/Data/DayOfWeek';
import { DailyCoachSettings } from '../Data';
import DayOfWeekSelector from './DayOfWeekSelector';

interface Props {
  settings: DailyCoachSettings,
  update: (s: DailyCoachSettings) => void
}

export default function renderDailyCoachSettings(
  props: Props
): React.ReactElement<Props> {
  const { settings, update } = props;

  const updateEnabled =
    (val: boolean) => () => update(
      R.assoc( // R.compose wouldn't type check
        'enabled',
        val,
        R.assoc('days', val ? DOW.all() : [], settings)
      )
    );

  const updateHour = (h: TOD.Hour) => update(R.assoc('hour', h, settings));

  const updateDays =
    (days: DOW.DayOfWeek[]) => update(R.assoc('days', days, settings));

  return (
    <div className="daily-coach simple-container">
      <p className="help-block first">
        Send regular email reminder:
      </p>
      <div className="clearfix daily-coach__row">
        <label className="daily-coach__label-radio">
          <input type="radio"
            checked={settings.enabled}
            onChange={updateEnabled(true)}
          /> Yes
        </label>
        <label className="daily-coach__label-radio">
          <input type="radio"
            checked={!settings.enabled}
            onChange={updateEnabled(false)}
          /> No
        </label>
        {hourSelect(settings.hour, updateHour)}
      </div>

      <div className="clearfix daily-coach__row">
        <DayOfWeekSelector
          selected={settings.days}
          onChange={updateDays}/>
      </div>
    </div>
  );
}

function hourSelect(
  selected: TOD.Hour, onChange: (h: TOD.Hour) => void
): React.ReactNode {
  const options = R.range(0, 23).map(h => (
    <option key={h} value={h}>
      {TOD.format(TOD.fromHour(h), { shortTime: true })}
    </option>
  ));
  const handler = (e: React.FormEvent<HTMLSelectElement>) => {
    const el = e.target as HTMLSelectElement;
    const newValue = parseInt(el.value);
    onChange(newValue);
  }
  return (
    <select
      value={selected}
      className="daily-coach__hour-select select"
      onChange={handler}>
      {options}
    </select>
  );
}
