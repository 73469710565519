import * as React from 'react';

import { ChallengeMetric, MetricType } from 'Challenges/Data';

interface Props {
  metric: ChallengeMetric
}

export default function LeaderboardExplanation(props: Props) {
  let parts = [minimumTeamPlayers(), total()];
  const type = props.metric.type;
  if (type === MetricType.Task) {
    parts = [...parts, dailyTotal()];
  }
  return (
    <div>
      {parts}
    </div>
  );
}

function minimumTeamPlayers() {
  return (
    <p key="minimum-team-players">
      * team must have at least 2 players to display on the team leaderboard
    </p>
  );
}

function total() {
  return (
    <p key="total">
      ** average total per player on team
    </p>
  );
}

function dailyTotal() {
  return (
    <p key="daily-total">
      *** average total per player on team for today
    </p>
  );
}
