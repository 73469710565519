import { StoreDispatch } from 'Store';
import { makeLogger } from 'Shared/Log';
import * as Timeout from 'Shared/Timeout';
import * as BLE from 'BLE';
import * as Edge from 'Edge/Data';
import { ActionCreators } from 'Edge/ActionCreator';

import Protocol from 'BLE/Protocols/GreaterGoodsA6/Protocol';
import * as A6Data from 'BLE/Protocols/GreaterGoodsA6/Data';

export function makeCollector(
  dispatch: StoreDispatch,
  bluetooth: BLE.Service,
  deviceType: Edge.DeviceType,
  device: Edge.UserDevice
): Edge.Collector {
  let dataBuffer: Edge.DeviceData = {};
  let protocol: Protocol | undefined;
  let cancelFlush: Timeout.CancellationFunction | undefined;
  const log = makeLogger('Edge.Collector.GreaterGoodsA6', 'EDGE_LOG');

  return { sync }

  async function sync() {
    const protocol = await setup();
    await protocol.init();
    // Give it some time to make login/initialzation requests and send responses
    await Timeout.sleep(3000);
    switch(deviceType.formFactor) {
      case 'scale':
        await protocol.synchronizeWeights();
        break;
      case 'bp_cuff':
        await protocol.synchronizeBPs();
        break;
      default:
        log.error('Unknown GreaterGoods device:', deviceType);
        break;
    }
  }

  async function setup(): Promise<Protocol> {
    if (protocol !== undefined) { return protocol; }
    const ble = await bluetooth.withBluetooth();
    const bleDevice = Edge.userDeviceToBLEDevice(device);
    protocol = new Protocol(bleDevice, ble);
    protocol.onWeight(
      weight => {
        log('Collector Received weight:', weight);
        queueData({
          weights: [weightToEdgeWeight(weight)]
        });
      }
    );
    protocol.onBloodPressure(
      bp => {
        log('Collector Received bp:', bp)
        queueData({
          bloodPressures: [bpToEdgeBp(bp)],
          restingHeartRates: [bpToEdgeHr(bp)]
        });
      }
    );
    return protocol;
  }

  function queueData(newData: Edge.DeviceData) {
    log('queueing data:', newData);
    dataBuffer = Edge.dedupByTime(Edge.mergeDeviceData(dataBuffer, newData));
    if (cancelFlush) { cancelFlush(); }
    cancelFlush = Timeout.cancellableTimeout(
      () => flushQueuedData(),
      5000
    );
  }

  async function flushQueuedData() {
    log('Flushing queued data!', dataBuffer);
    await dispatch(
      ActionCreators.receiveDeviceData(
        deviceType.providerId,
        dataBuffer,
        device
      )
    );
    dataBuffer = {};
  }
}

function weightToEdgeWeight(datum: A6Data.Weight): Edge.WeightDatum {
  return { weight: datum.weight, time: datum.time };
}

function bpToEdgeBp(datum: A6Data.BloodPressure): Edge.BPDatum {
  return {
    systolic: datum.systolic, diastolic: datum.diastolic, time: datum.time
  };
}

function bpToEdgeHr(datum: A6Data.BloodPressure): Edge.HRDatum {
  return { heartRate: datum.heartRate, time: datum.time };
}
