import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';
import {
  NSType, NS, ApptType, Appt, AvailableSlots, ApptTypeId
} from './Data';

export enum ActionType {
  LOAD_APPT_TYPES__SUCCESS = 'LOAD_APPT_TYPES__SUCCESS',
  LOAD_APPTS__SUCCESS = 'LOAD_APPTS__SUCCESS',
  LOAD_AVAILABILITY__BEGIN = 'LOAD_AVAILABILITY__BEGIN',
  LOAD_AVAILABILITY__SUCCESS = 'LOAD_AVAILABILITY__SUCCESS',
  RESERVE_SLOT__SUCCESS = 'RESERVE_SLOT__SUCCESS',
  CANCEL_APPT__SUCCESS = 'CANCEL_APPT__SUCCESS'
}

export type Action
  = LoadApptTypes_Success
  | LoadAppts_Success
  | LoadAvailability_Begin
  | LoadAvailability_Success
  | ReserveSlot_Success
  | CancelAppt_Success;

export interface LoadApptTypes_Success {
  type: ActionType.LOAD_APPT_TYPES__SUCCESS,
  apptTypes: ApptType[]
}

export interface LoadAppts_Success {
  type: ActionType.LOAD_APPTS__SUCCESS
  appts: Appt[]
}
export interface LoadAvailability_Begin {
  type: ActionType.LOAD_AVAILABILITY__BEGIN
  apptTypeId: ApptTypeId
}
export interface LoadAvailability_Success {
  type: ActionType.LOAD_AVAILABILITY__SUCCESS,
  apptTypeId: ApptTypeId,
  availableSlots: AvailableSlots
}

export interface ReserveSlot_Success {
  type: ActionType.RESERVE_SLOT__SUCCESS,
  appt: Appt
}

export interface CancelAppt_Success {
  type: ActionType.CANCEL_APPT__SUCCESS,
  apptId: string
}

export type NSAction = WithNS<NSType, Action>;

export const withNS: (a: Action) => NSAction = originalWithNS(NS);
