import * as React from 'react';
import {
  CategoryInfo, Biopost, Activity, RecommendedActivity, ActivityId
} from 'Post/Data';
import PostMenuCategory from './PostMenuCategory';
import ActivityList from 'Post/UI/ActivityList';
import Link from 'Shared/UI/Link';

interface Props {
  category: CategoryInfo,
  topActivities: (Activity | RecommendedActivity)[],
  bottomActivities?: Activity[] | undefined,
  favoriteIds?: ActivityId[]
  onFavorite?: (aid: ActivityId) => void
  onUnfavorite?: (aid: ActivityId) => void,
  initialOpen?: boolean,
  collapsable?: boolean,
  // Only allow activities to be posted once
  oneShot?: boolean,
  afterPost?: (b: Biopost, a: Activity) => void,
  noActivitiesContent?: React.ReactNode
}

interface State {
  bottomIsShown: boolean
}

class PostMenuActivityCategory extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { bottomIsShown: false };
    this.showBottom = this.showBottom.bind(this);
    this.hideBottom = this.hideBottom.bind(this);
  }

  showBottom() {
    this.setState({ bottomIsShown: true });
  }

  hideBottom() {
    this.setState({ bottomIsShown: false });
  }

  hasActivities(): boolean {
    return this.props.topActivities.length !== 0 ||
           (this.props.bottomActivities !== undefined &&
            this.props.bottomActivities.length !== 0
           );
  }

  render() {
    return (
      <PostMenuCategory
        category={this.props.category}
        initialOpen={this.props.initialOpen}
        collapsable={this.props.collapsable}
      >
        {this.renderContents()}
      </PostMenuCategory>
    );
  }

  renderContents() {
    if (this.hasActivities()) {
      return [
        this.renderActivityList('top-list', this.props.topActivities),
        this.renderBottom()
      ];
    } else {
      return this.props.noActivitiesContent;
    }
  }

  renderBottom() {
    if (this.props.bottomActivities === undefined ||
        this.props.bottomActivities.length === 0
    ) {
      return null;
    }
    if (this.state.bottomIsShown) {
      return this.renderActivityList('btm-list', this.props.bottomActivities);
    } else {
      return (
        <div key="btm-link" className="post-menu-category__load-more-section">
          <Link
            to={this.showBottom}
            className="post-menu-category__load-more button">
            + load more activities
          </Link>
        </div>
      )
    }
  }

  renderActivityList(key: string, activities: Activity[]) {
    return (
      <ActivityList
        key={key}
        activities={activities}
        category={this.props.category}
        favoriteIds={this.props.favoriteIds}
        onFavorite={this.props.onFavorite}
        onUnfavorite={this.props.onUnfavorite}
        afterPost={this.props.afterPost}
        oneShot={this.props.oneShot}
      />
    );
  }
}

export default PostMenuActivityCategory;
