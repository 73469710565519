import * as R from 'ramda';
import { Dispatch } from 'redux';

import { RouterState } from 'connected-react-router';

import {
  State as NavState,
  initialState as initialNavState
} from 'Nav/Data';

import {
  State as AuthState,
  initialState as initialAuthState
} from 'Auth/Data';

import {
  State as ChallengesState,
  initialState as initialChallengesState
} from 'Challenges/Data';

import { HUDState, initialHUDState } from 'HUD/Data';

import {
  State as HomeState,
  initialState as initialHomeState
} from 'Home/Data';

import {
  State as PostState,
  initialState as initialPostState
} from 'Post/Data';

import {
  State as SettingsState,
  initialState as initialSettingsState,
  Lenses as SettingsLenses,
  makeLenses as makeSettingsLenses
} from 'Settings/Data';

import {
  State as EdgeState,
  initialState as initialEdgeState
} from 'Edge/Data';

import {
  State as GoalsState,
  initialState as initialGoalsState
} from 'Goals/Data';

import {
  State as ScreeningsState,
  initialState as initialScreeningsState
} from 'Screenings/Data';

import {
  State as InboxState,
  initialState as initialInboxState
} from 'Inbox/Data';

import {
  State as CMSState,
  initialState as initialCMSState
} from 'CMS/Data';

import {
  State as ApptState,
  initialState as initialApptState
} from 'Appt/Data';

import {
  State as TrendsState,
  initialState as initialTrendsState
} from 'Trends/Data';

import {
  State as OnboardState,
  initialState as initialOnboardState
} from 'Onboard/Data';

import {
  State as DashboardState,
  initialState as initialDashboardState
} from 'Dashboard/Data';

import {
  State as MaintenanceState,
  initialState as initialMaintenanceState
} from 'Maintenance/Data';

import {
  State as NetworkState,
  initialState as initialNetworkState
} from 'Network/Data';

import {
  State as RewardsState,
  initialState as initialRewardsState
} from 'Rewards/Data';

import {
  State as CarePlansState,
  initialState as initialCarePlansState
} from 'CarePlans/Data';

import {
  State as BLEState,
  initialState as initialBLEState
} from 'BLE/Data';

import {
  CurrentUser, Program, initialCurrentUser
} from 'User/Data';

// import { AppAPI } from 'App/Api';

/*
  ------------------------------------------------------------
  App State
  ------------------------------------------------------------
*/
export interface State {
  currentUser: CurrentUser,
  home: HomeState,
  challenges: ChallengesState,
  post: PostState,
  settings: SettingsState,
  hud: HUDState,
  edge: EdgeState,
  goals: GoalsState,
  screenings: ScreeningsState,
  inbox: InboxState,
  auth: AuthState,
  cms: CMSState,
  nav: NavState,
  appt: ApptState,
  trends: TrendsState,
  onboard: OnboardState,
  dashboard: DashboardState,
  maintenance: MaintenanceState,
  network: NetworkState,
  rewards: RewardsState,
  carePlans: CarePlansState,
  ble: BLEState,
  router?: RouterState
}

export function initialState(): State {
  return {
    currentUser: initialCurrentUser(),
    home: initialHomeState(),
    challenges: initialChallengesState(),
    post: initialPostState(),
    settings: initialSettingsState(),
    hud: initialHUDState(),
    edge: initialEdgeState(),
    goals: initialGoalsState(),
    screenings: initialScreeningsState(),
    inbox: initialInboxState(),
    auth: initialAuthState(),
    cms: initialCMSState(),
    nav: initialNavState(),
    appt: initialApptState(),
    trends: initialTrendsState(),
    onboard: initialOnboardState(),
    dashboard: initialDashboardState(),
    maintenance: initialMaintenanceState(),
    network: initialNetworkState(),
    rewards: initialRewardsState(),
    carePlans: initialCarePlansState(),
    ble: initialBLEState()
  };
}

/*
  ------------------------------------------------------------
  Loading
  ------------------------------------------------------------
*/


/** Lenses **/
export type AppStateLens = {
  settings: SettingsLenses,
  currentUser: R.Lens,
  hud: R.Lens
}

export const LENSES: AppStateLens = {
  settings: makeSettingsLenses('settings'),
  currentUser: R.lensProp('currentUser'),
  hud: R.lensProp('hud')
}

export function hudState(state: State): HUDState {
  return R.view(LENSES.hud, state) as HUDState;
}

export function currentProgram(state: State): Program | undefined {
  return state.currentUser.program;
}

/*
  ------------------------------------------------------------
  Store related types
  ------------------------------------------------------------
*/


export type AppDispatch = Dispatch<State>;
