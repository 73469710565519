import { AuthAPI, makeApi as makeAuthApi } from 'Api/Auth';
import { PostAPI, makeApi as makePostApi } from 'Api/Post';
import { ChallengesAPI, makeApi as makeChallengesApi } from 'Api/Challenges';
import { UserAPI, makeApi as makeUserApi } from 'Api/User';
import { EdgeAPI, makeApi as makeEdgeApi } from 'Api/Edge';
import { GoalsAPI, makeApi as makeGoalsApi } from 'Api/Goals';
import { ScreeningsAPI, makeApi as makeScreeningsApi } from 'Api/Screenings';
import {
  NotificationsAPI, makeApi as makeNotificationsApi
} from 'Api/Notifications';
import { InboxAPI, makeApi as makeInboxApi } from 'Api/Inbox';
import { TrendsAPI, makeApi as makeTrendsApi } from 'Api/Trends';
import { CMSAPI, makeApi as makeCMSApi } from 'Api/CMS';
import { ContactAPI, makeApi as makeContactApi } from 'Api/Contact';
import { ApptAPI, makeApi as makeApptApi } from 'Api/Appt';
import { DashboardAPI, makeApi as makeDashboardApi } from 'Api/Dashboard';
import { CommunityAPI, makeApi as makeCommunityApi } from 'Api/Community';
import { RewardsAPI, makeApi as makeRewardsApi } from 'Api/Rewards';
import { CarePlansAPI, makeApi as makeCarePlansApi } from 'Api/CarePlans';
import { TasksAPI, makeApi as makeTasksApi } from 'Api/Tasks';
import { MaintenanceAPI, makeApi as makeMaintenanceApi } from 'Api/Maintenance';
import { HomeAPI, makeApi as makeHomeApi } from 'Api/Home';
import { OnboardAPI, makeApi as makeOnboardApi } from 'Api/Onboard';
import { TokenProvider, ApiClient } from 'Api';

export interface AppAPI {
  token: TokenProvider,
  auth: AuthAPI,
  challenges: ChallengesAPI,
  post: PostAPI,
  user: UserAPI,
  edge: EdgeAPI,
  goals: GoalsAPI,
  notifications: NotificationsAPI,
  screenings: ScreeningsAPI,
  inbox: InboxAPI,
  cms: CMSAPI,
  contact: ContactAPI,
  appt: ApptAPI,
  trends: TrendsAPI,
  community: CommunityAPI,
  dashboard: DashboardAPI,
  maintenance: MaintenanceAPI,
  rewards: RewardsAPI,
  carePlans: CarePlansAPI,
  tasks: TasksAPI,
  home: HomeAPI,
  onboard: OnboardAPI
};

export function makeApi(client: ApiClient, token: TokenProvider): AppAPI {
  return {
    token,
    auth: makeAuthApi(client),
    challenges: makeChallengesApi(client),
    post: makePostApi(client),
    user: makeUserApi(client),
    edge: makeEdgeApi(client),
    goals: makeGoalsApi(client),
    trends: makeTrendsApi(client),
    notifications: makeNotificationsApi(client),
    screenings: makeScreeningsApi(client),
    inbox: makeInboxApi(client),
    cms: makeCMSApi(client),
    contact: makeContactApi(client),
    appt: makeApptApi(client),
    community: makeCommunityApi(client),
    dashboard: makeDashboardApi(client),
    maintenance: makeMaintenanceApi(client),
    rewards: makeRewardsApi(client),
    carePlans: makeCarePlansApi(client),
    tasks: makeTasksApi(client),
    home: makeHomeApi(client),
    onboard: makeOnboardApi(client)
  };
}
