import { SpecTypeJSON, UserIdJSON } from 'Api/JSON/User';
import { ActivityJSON } from 'Api/JSON/Post';
import { TaskCategoryJSON, TaskJSON } from 'Api/JSON/Tasks';
import { DateJSON } from './Shared'

/**
 * TODO: Challenge IDs are actually numbers, but a string is expected in old
 * native clients, so we need to do this for backwards compat
 */
export type ChallengeIdJSON = string;

export interface ChallengeInvitationJSON {
  id: string,
  challenge_id: ChallengeIdJSON,
  challenge_name: string,
  metric: ChallengeMetricJSON,
  start_date: string,
  end_date: string,
  graphic_url: string
}

export type ChallengeMetricJSON
  = StepMetricJSON
  | HabitMetricJSON
  | SpecMetricJSON
  | TaskMetricJSON;

export interface StepMetricJSON { type: 'Steps' }
export interface HabitMetricJSON { type: 'Habit' }
export interface TaskMetricJSON {
  type: 'Task',
  completion_frequency: TaskCompletionFrequencyJSON
}
export interface SpecMetricJSON {
  type: 'Spec',
  spec_type: SpecTypeJSON,
  delta_type: DeltaTypeJSON
}

export type TaskCompletionFrequencyJSON = 'Once' | 'Daily';
export type DeltaTypeJSON = 'Absolute' | 'Relative';
export type LeaderboardPrivacyJSON = 'public' | 'crew' | 'private'

export interface ChallengeSummaryJSON {
  id: ChallengeIdJSON,
  name: string,
  graphic_url: string,
  metric: ChallengeMetricJSON,
  progress: ChallengeProgressJSON | undefined,
  start_date: string,
  end_date: string,
  join_start_date: string,
  join_end_date: string,
  tz_id: string,
  player_count: number,
  my_team_id: TeamIdJSON | undefined,
  leaderboard_privacy: LeaderboardPrivacyJSON,
  challenge_step_count: number
}

export interface ChallengeTemplateJSON {
  id: string,
  name: string,
  graphic_url: string,
  metric: ChallengeMetricJSON,
  time_period_description: string,
  start_date: string,
  end_date: string
}

export type ChallengeProgressJSON
  = StepChallengeProgressJSON
  | HabitChallengeProgressJSON
  | SpecChallengeProgressJSON
  | TaskChallengeProgressJSON;

export interface StepChallengeProgressJSON {
  step_count: number,
  source?: string,
  source_icon?: string
}

export interface HabitChallengeProgressJSON {
  post_count: number
  last_post_time: string
}

export interface SpecChallengeProgressJSON {
  delta: number,
  current_value: number,
  last_post_time: string
}

export interface TaskChallengeProgressJSON {
  total_points: number,
  daily_points: { [date: string]: number }
}

export function isStepProgressJSON(
  json: ChallengeProgressJSON
): json is StepChallengeProgressJSON {
  return (json as StepChallengeProgressJSON).step_count !== undefined;
}

export function isHabitProgressJSON(
  json: ChallengeProgressJSON
): json is HabitChallengeProgressJSON {
  return (json as HabitChallengeProgressJSON).post_count !== undefined;
}

export function isSpecProgressJSON(
  json: ChallengeProgressJSON
): json is SpecChallengeProgressJSON {
  return (json as SpecChallengeProgressJSON).delta !== undefined;
}

export function isTaskProgressJSON(
  json: ChallengeProgressJSON
): json is TaskChallengeProgressJSON {
  return (json as TaskChallengeProgressJSON).daily_points !== undefined &&
    (json as TaskChallengeProgressJSON).total_points !== undefined;
}

// Challenge Details

export interface ChallengeDetailsJSON {
  summary: ChallengeSummaryJSON,
  action_details: ActionDetailsJSON,
  top_players: PlayerJSON[],
  milestones: MilestoneJSON[]

}

export type ActionDetailsJSON
  = StepActionDetailsJSON
  | HabitActionDetailsJSON
  | TaskActionDetailsJSON

interface StepActionDetailsJSON {
  steps: {}
}

interface HabitActionDetailsJSON {
  habit: {
    activities: ActivityJSON[]
  }
}

interface TaskActionDetailsJSON {
  task: {
    categorized_tasks: { category: TaskCategoryJSON, tasks: TaskJSON[] }[],
    task_points: { [taskId: number]: number }
  }
}

export function isTaskActionDetailsJSON(
  json: ActionDetailsJSON
): json is TaskActionDetailsJSON {
  return (json as TaskActionDetailsJSON).task !== undefined;
}

export interface PlayerJSON {
  rank: number,
  user_id: string
  user_name: string
  progress: ChallengeProgressJSON
}

export interface MilestoneJSON {
  name: string,
  miles: number,
  position: number
}

export interface TaskChallengeDaySummaryJSON {
  tz_id: string,
  date: DateJSON,
  category_points: { [categoryName: string]: number }
}

export interface TeamListResponseJSON {
  challenge_id: ChallengeIdJSON,
  teams: TeamJSON[]
}

export interface TeamResponseJSON {
  team: TeamJSON
}

export type TeamIdJSON = number;

export interface TeamJSON {
  id: TeamIdJSON
  captain_id: UserIdJSON
  name: string
}

export interface TeamFormDataJSON {
  name: string
}

export interface TeamPlayersResponseJSON {
  team: TeamJSON,
  players: PlayerJSON[]
}

export interface TeamPlayerJSON {
  rank: number,
  team: TeamJSON,
  progress: ChallengeProgressJSON
}

export interface TeamLeaderboardResponseJSON {
  teams: TeamPlayerJSON[],
  has_more: boolean
}
