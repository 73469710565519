import * as React from 'react';

import { HUDState } from './Data';

import Spinner from 'Shared/UI/Spinner';

interface Props {
  message?: string,
  state?: HUDState
}

export default function renderHUD(props: Props): React.ReactElement<Props> {
  const message = props.message || '';
  const state = props.state || 'closed';
  if (state === 'closed') {
    return <span></span>;
  } else {
    return (
      <div className="bios-hud-overlay">
        <div className="bios-hud-centerer">
          <div className="bios-hud-contents">
            {state === 'loading' ? renderSpinner() : null}
            {state === 'success' ? renderSuccess() : null}
            {state === 'error' ? renderError() : null}
            {message ? renderMessage(message) : null}
          </div>
        </div>
      </div>
    );
  }
}

function renderSpinner(): React.ReactNode {
  return <Spinner variant="hud"></Spinner>;
}

function renderSuccess(): React.ReactNode {
  return (
    <span className="hud-icon hud-success">
      <i className="icon-check-circle-o"></i>
    </span>
  );
}

function renderError(): React.ReactNode {
  return (
    <span className="hud-icon hud-error">
      <i className="icon-x-circle-o"></i>
    </span>
  );
}

function renderMessage(msg: string): React.ReactNode {
  return (
    <p className="hud-message">
      {msg}
    </p>
  );
}
