import * as React from 'react';
import * as classnames from 'classnames';
import {
  Biopost, Activity, ActivityId, RecommendedActivity, CategoryInfo,
  isRecommendedActivity
} from 'Post/Data';
import { connect } from 'react-redux';

import { ActionCreators } from 'Post/ActionCreator';

import * as Modal from 'react-modal';
import Icon from 'Shared/UI/Icon';
import Spinner from 'Shared/UI/Spinner';
import Logo from 'Shared/UI/Logo';
import Link from 'Shared/UI/Link';

import NoteForm from './NoteForm';

interface Props {
  activity: Activity | RecommendedActivity,
  category?: CategoryInfo,
  isFavorite?: boolean,
  onFavorite?: (activityId: ActivityId) => void,
  onUnfavorite?: (activityId: ActivityId) => void,
  afterPost?: (b: Biopost, a: Activity) => void,
  // When true, this activity can only be posted once
  oneShot?: boolean
}

interface PostFn {
  (a: Activity, note: string | undefined, onSuccess: PostCallback): void
}

type PostCallback = (b: Biopost, a: Activity) => void

interface ConnectedProps {
  post: PostFn
}

type AllProps = Props & ConnectedProps;

type Status = 'default' | 'loading' | 'posted';
type UIState = {
  status: Status,
  noteFormOpen: boolean
}

class ActivityUI extends React.Component<AllProps, UIState> {
  statusTimeout: number | undefined

  constructor(props: Props & ConnectedProps) {
    super(props);
    this.state = { status: 'default', noteFormOpen: false };
    this.toggleNoteForm = this.toggleNoteForm.bind(this);
    this.onFavorite = this.onFavorite.bind(this);
    this.onUnfavorite = this.onUnfavorite.bind(this);
  }

  postActivity = (note?: string) => {
    this.setState({ status: 'loading' });
    this.props.post(
      this.props.activity,
      note,
      () => {
        this.setState({ status: 'posted' });
        if (!this.props.oneShot) {
          this.statusTimeout =
            window.setTimeout(() => {
              this.statusTimeout = undefined;
              this.setState({ status: 'default' });
            }, 1000);
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.statusTimeout) { window.clearTimeout(this.statusTimeout); }
    this.statusTimeout = undefined;
  }

  toggleNoteForm() {
    this.setState({ noteFormOpen: !this.state.noteFormOpen });
  }

  closeNoteForm = () => {
    this.setState({ noteFormOpen: false });
  }

  onFavorite(e: React.SyntheticEvent<any>) {
    e.preventDefault();
    if (this.props.onFavorite) {
      this.props.onFavorite(this.props.activity.id);
    }
  }

  onUnfavorite(e: React.SyntheticEvent<any>) {
    e.preventDefault();
    if (this.props.onUnfavorite) {
      this.props.onUnfavorite(this.props.activity.id);
    }
  }

  render() {
    const { activity } = this.props;
    const doItClassNames =
      `post-activity__do-it post-activity__do-it--${this.state.status}`;
    const trClassNames = classnames(
      'post-activity',
      { 'post-activity--negative': isNegative(activity) }
    );
    return (
      <tbody>
        <tr className={trClassNames}>
          <td className="post-activity__favorite">
            {this.renderNoteForm()}
            {this.props.isFavorite ?
             this.renderUnfavoriteButton() : this.renderFavoriteButton()}
          </td>
          <td className="post-activity__name">
            {activity.name}
          </td>
          <td className="post-activity__notes">
            <div onClick={this.toggleNoteForm}>
              <Icon name="pencil-square-o" />
            </div>
          </td>
          <td className={doItClassNames}>
            {renderDoIt(() => this.postActivity(), this.state.status)}
          </td>
        </tr>
      </tbody>
    );
  }

  renderNoteForm() {
    return (
      <Modal
        isOpen={this.state.noteFormOpen}
        onRequestClose={this.toggleNoteForm}
        className="note-form__modal-content"
      >
        <NoteForm
          category={this.props.category}
          activity={this.props.activity}
          postActivity={this.postActivity}
          close={this.closeNoteForm}/>
      </Modal>
    );
  }

  renderFavoriteButton() {
    if (this.props.onFavorite && this.props.onUnfavorite) {
      return (
        <Link to={this.onFavorite}><Icon name="star-o" /></Link>
      );
    }
  }

  renderUnfavoriteButton() {
    return (
      <Link to={this.onUnfavorite}><Icon name="star" /></Link>
    );
  }
}

export default connect(
  (_, props) => props,

  (dispatch, props: Props) => ({
    post(a: Activity, note: string | undefined, onSuccess?: PostCallback) {
      let action;
      if (note) {
        action =
          ActionCreators.postActivity(a, note, (b: Biopost, a: Activity) => {
            if (onSuccess) { onSuccess(b, a); }
            if (props.afterPost) { props.afterPost(b, a); }
          });
      } else {
        action =
          ActionCreators.postActivity(a, (b: Biopost, a: Activity) => {
            if (onSuccess) { onSuccess(b, a); }
            if (props.afterPost) { props.afterPost(b, a); }
          });
      }
      dispatch(action);
    }
  })
)(ActivityUI) as React.ComponentType<Props>;



function renderDoIt(action: () => void, status: Status): React.ReactNode {
  switch(status) {
    case 'default':
      return (
        <a onClick={action}>
          <Logo modifier="small"/>
        </a>
      );
    case 'loading':
      return (
        <Spinner />
      );
    case 'posted':
      return (
        <div className="text-center theme__primary-color--dark5">
          <Icon name="check-circle-o"></Icon>
        </div>
      );
  }
}

function isNegative(a: Activity | RecommendedActivity): boolean {
  return isRecommendedActivity(a) && a.scoreChange < 0;
}
