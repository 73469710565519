import * as R from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';

import { State, DispatchProps } from 'Store';
import { HUD } from 'HUD';
import * as Urls from 'Shared/Urls';

import { ActionCreators as UserActionCreators } from 'User';
import { ActionCreators as NavAC } from 'Nav/ActionCreator';
import { ActionCreators as AC } from '../ActionCreator';
import {
  Provider, DeviceType, Connections, UserDevice, DeviceConnectionStatuses,
  isEnabled
} from '../Data';
import ConnectLink from './ConnectLink';

interface OwnProps {
}

interface StateProps {
  providers: Provider[] | undefined,
  userDevices?: UserDevice[],
  connections?: Connections,
  deviceConnectionStatuses: DeviceConnectionStatuses
}

type AllProps = OwnProps & StateProps & DispatchProps;

class ConnectionList extends React.Component<AllProps, {}> {
  componentDidMount() {
    this.loadCurrentUser();
    this.loadConfiguration();
  }

  loadConfiguration() {
    this.props.dispatch(AC.loadConfiguration());
  }

  loadCurrentUser() {
    this.props.dispatch(UserActionCreators.loadCurrentUser());
  }

  connectProvider = (p: Provider) => {
    this.props.dispatch(AC.connect(p));
  }

  disconnectProvider = (p: Provider) => {
    this.props.dispatch(AC.disconnect(p));
  }

  connectDevice = (p: Provider, dt: DeviceType) => {
    this.props.dispatch(
      NavAC.pushWithBreadcrumb(Urls.edgeConnectToDeviceUrl(p.key, dt.id))
    );
  }

  disconnectDevice = (p: Provider, ud: UserDevice) => {
    this.props.dispatch(AC.removeDevice(p, ud));
  }

  render() {
    const connections = this.props.connections;
    const providers = this.props.providers;
    const userDevices = this.props.userDevices;

    if (connections !== undefined &&
        providers !== undefined &&
        userDevices !== undefined) {
      const providersSorted = sortProviders(providers);
      return (
        <div className="edge-settings">
          {providersSorted.map(
             p =>
               <ConnectLink
                 key={p.key}
                 provider={p}
                 deviceStatuses={this.props.deviceConnectionStatuses}
                 userDevices={userDevices}
                 status={connections[p.key]}
                 connectProvider={this.connectProvider}
                 disconnectProvider={this.disconnectProvider}
                 connectDevice={this.connectDevice}
                 disconnectDevice={this.disconnectDevice}
               />
           )}
        </div>
      );
    } else {
      return <HUD state="loading"></HUD>;
    }
  }
}

export default connect(
  (state: State): StateProps => {
    const providers = state.edge.providers;

    return {
      providers,
      connections: state.edge.connections,
      userDevices: state.edge.userDevices,
      deviceConnectionStatuses: state.edge.deviceConnectionStatuses
    };
  }
)(ConnectionList);


function sortProviders(providers: Provider[]): Provider[] {
  // Filter out hidden ones
  providers = R.reject(p => p.hidden, providers);
  // Then separate disabled providers and put them on the bottom
  const [enabled, disabled] = R.partition(isEnabled, providers);
  return R.concat(enabled, disabled);
}
