import 'Main.scss';
import * as ReactDOM from 'react-dom';
import * as Modal from 'react-modal';
import { init as cordovaInit } from 'Cordova';
// polyfills
import 'whatwg-fetch';
import 'core-js/fn/object/assign';
import 'core-js/es6/promise';

declare const ENV_JSON: any;
(window as any)['ENV'] = ENV_JSON;

cordovaInit();

const makeRoot = require('App/Root').makeRoot;
Modal.setAppElement('#react-root');
ReactDOM.render(makeRoot(), document.getElementById('react-root'));

if (ENV_JSON.DEBUG) { require('Debug').setup(); }
