import * as React from 'react';

import { StoreDispatch } from 'Store';
import { bem } from 'css-util';
import { plural } from 'Shared/Text';
import { Reward, ActionStatus, isComplete } from 'Rewards/Data';
import { ActionCreators as NavAC } from 'Nav';
import * as Urls from 'Shared/Urls';
import StatusCircle, { Status } from 'Shared/UI/StatusCircle';

interface Props {
  reward: Reward,
  status: ActionStatus,
  dispatch: StoreDispatch
}

const CSS = bem('reward-list-item');

const RewardListItem = (props: Props) => {
  const url = Urls.rewardUrl(props.reward.id);
  const gotoReward =
    (e: React.MouseEvent<any>) => {
      e.preventDefault();
      props.dispatch(NavAC.pushWithBreadcrumb(url));
    };
  const earnLinkClasses =
    CSS('earn-link')([isComplete(props.status) ? 'complete' : 'not-complete']);

  return (
    <div className={CSS()()} onClick={gotoReward}>
      <div className={CSS('left')()}>
        {statusIcon(props.status)}
      </div>
      <div className={CSS('middle')()}>
        <span className={CSS('reward-name')()}>{props.reward.name}</span>
      </div>
      <div className={CSS('right')()}>
        <div className={earnLinkClasses}>
          {isComplete(props.status) ? 'You earned' : 'Earn'}
          <br/>
          <strong className="bold">
            {props.reward.points}
          </strong> {plural('point', props.reward.points, { textOnly: true })}
          <span className={CSS('earn-link-arrow')()}>&gt;</span>
        </div>
      </div>
    </div>
  );
}

export default RewardListItem;


function statusIcon(status: ActionStatus) {
  if (status === ActionStatus.COMPLETE) {
    return <StatusCircle status={Status.COMPLETE} />;
  } else {
    return <StatusCircle status={Status.AVAILABLE} />;
  }
}
