import * as React from 'react';
import { Biopost } from 'Post';
import {
  renderSource,
  renderScoreIncrement,
  renderNotesIcon
} from 'Post/UI/Biopost';
import { formatPostTime } from 'Post/Data';
import { UserId } from 'User';
import { bem } from 'css-util';
import Tile from './Tile';
import NoContent from './NoContent';
import DashboardIcon from 'Dashboard/UI/Icon';

interface Props {
  lastPost: Biopost | undefined
  showScore: boolean
  currentUserId: UserId
  linkUrl: string
  refreshDynamicPosts?: () => void
}

const NO_POSTS =
  <NoContent iconName="watch-fitness">no posts to display</NoContent>;

const DashboardCSS = bem('biopost-tile');

export default function renderPostTile(
  props: Props
): React.ReactElement<Props> {
  return (
    <Tile title="feed" linkUrl={props.linkUrl} linkLabel="view all posts">
      {renderInnerContent(props)}
    </Tile>
  );
}

function renderInnerContent(props: Props) {
  const { lastPost, showScore } = props;
  if (lastPost) {
    return renderDashboardTile(lastPost, showScore);
  } else {
    return NO_POSTS;
  }
}

function renderDashboardTile(post: Biopost, showScore: boolean) {
  const section = DashboardCSS('section');

  return (
    <div className="biopost-tile">
      <div className="dashboard-tile__icon">
        <DashboardIcon name="watch-fitness" />
      </div>
      <div className={section(['main'])}>
        <div className="biopost__title">
          {post.title}
        </div>
        <div className="biopost__time">
          {formatPostTime(post.time)}
          {renderSource(post.source)}
        </div>
      </div>
      <div className={section(['props', 'no-show-user'])}>
        {renderScoreIncrement(post.scoreIncrement, showScore, false)}
        Props {post.props.length}
      </div>
      <div className={section(['notes', 'no-show-user'])}>
        {renderNotesIcon(post)}
        Notes {post.commentCount}
      </div>
    </div>
  );
}
