import * as React from 'react';
import { isUrlExternal } from 'Shared/Urls';
import Link, { ExternalType } from 'Shared/UI/Link';
import { Quicklink } from 'Home/Data';

interface Props {
  quicklink: Quicklink
}

const QuicklinkLink = (props: Props) => {
  const ql = props.quicklink;

  return (
    <div className="home__ql">
      <Link
        className="home__ql-link theme__primary-bg"
        to={ql.url}
        themed={false}
        breadcrumb="push"
        external={externalTypeForUrl(ql.url)}
      >
        <span className="home__ql-link-label">{ql.title}</span>
      </Link>
    </div>
  );
}

export default QuicklinkLink;

function externalTypeForUrl(url: string): ExternalType {
  return isUrlExternal(url) ? '_system' : false;
}
