import * as Dialog from 'Shared/Dialog';

import { ActionCreatorThunk, StoreDispatch } from 'Store';
import { AppAPI } from 'App/Api';
import noop from 'Shared/Noop';

import { ActionCreators as HUD } from 'HUD';

import { goalFromJSON, goalStatusFromJSON } from './JSON';
import { activityFromJSON } from 'Post/JSON';

import { ActionType, NSAction, withNS } from './Action';

import { Biopost } from 'Post';
import { UserId } from 'User';
import { SEL, GoalId, GoalStatus } from './Data';

export interface ActionCreators {
  loadGoals(): ActionCreatorThunk,
  loadStatusesForCurrentUser(): ActionCreatorThunk,
  loadStatusesForUser(userId: UserId): ActionCreatorThunk,
  loadActivitiesForGoal(goalId: GoalId): ActionCreatorThunk,
  updateGoalStatus(
    goalId: GoalId, userId: UserId, status: GoalStatus
  ): NSAction,
  goalActivityWasPosted(goalId: GoalId, post: Biopost): ActionCreatorThunk,
  activateGoal(goalId: GoalId): ActionCreatorThunk,
  deactivateGoal(goalId: GoalId): ActionCreatorThunk,
  resetGoal(goalId: GoalId): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  loadGoals,
  loadStatusesForCurrentUser,
  loadStatusesForUser,
  loadActivitiesForGoal,
  updateGoalStatus,
  goalActivityWasPosted,
  activateGoal,
  deactivateGoal,
  resetGoal
}

/*------------------------------------------------------------*/

function loadGoals(): ActionCreatorThunk {
  return (dispatch, _getState, { api }) => {
    return api.goals.getGoals().then(({ goals: goalsJSON }) => {
      const goals = goalsJSON.map(goalFromJSON);
      dispatch(withNS({ type: ActionType.LOAD_GOALS__SUCCESS, goals }));
    });
  };
}

export function loadStatusesForCurrentUser(): ActionCreatorThunk {
  return loadStatuses();
}

export function loadStatusesForUser(userId: string): ActionCreatorThunk {
  return loadStatuses(userId);
}

function loadStatuses(userId?: string): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    return loadAndDispatchGoalStatuses(dispatch, api, userId);
  };
}

function loadActivitiesForGoal(goalId: GoalId): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    return api.goals.getGoalActivities(goalId).then(result => {
      dispatch(withNS({
        type: ActionType.LOAD_GOAL_ACTIVITIES__SUCCESS,
        activities: result.activities.map(activityFromJSON),
        goalId
      }));
    });
  };
}

function updateGoalStatus(
  goalId: GoalId, userId: UserId, status: GoalStatus
): NSAction {
  return withNS({
    type: ActionType.UPDATE_GOAL_STATUS,
    userId, goalId, status
  });
}

function goalActivityWasPosted(
  goalId: GoalId, biopost: Biopost
): ActionCreatorThunk {
  const userId = biopost.userId;
  return dispatch => {
    dispatch(withNS({
      type: ActionType.INCREMENT_POST_COUNT, goalId, userId
    }));

    return dispatch(pollForChange(goalId, userId));
  };
}

let POLL_TIMEOUT: number | undefined;

function pollForChange(
  goalId: GoalId, userId: UserId, retries=10
): ActionCreatorThunk {
  return (dispatch, getState, { api }) => {
    const original = SEL.status(goalId, userId, getState().goals);

    if (POLL_TIMEOUT) {
      window.clearTimeout(POLL_TIMEOUT);
      POLL_TIMEOUT = undefined;
    }

    dispatch(beginPolling());
    return go(retries);

    function go(retries: number) {
      if (original === undefined) { return Promise.resolve(); }
      return api.goals.getGoalStatus(goalId).then(result => {
        const newStatus = goalStatusFromJSON(result.goal_status);
        // "original" status has actually already been modified with a post
        // count increment.  We know we've received the right status when it
        // matches the one we've incremented
        if (newStatus.postCount !== original.postCount) {
          // Retry
          if (retries > 0) {
            POLL_TIMEOUT = window.setTimeout(() => go(retries - 1), 1000);
          } else {
            if (POLL_TIMEOUT) {
              POLL_TIMEOUT = undefined;
              dispatch(endPolling());
            }
          }
        } else {
          // Got an updated status!
          dispatch(withNS({
            type: ActionType.UPDATE_GOAL_STATUS,
            userId, goalId,
            status: newStatus
          }));
          if (POLL_TIMEOUT) {
            POLL_TIMEOUT = undefined;
            dispatch(endPolling());
          }
        }
      });
    }
  }
}

function beginPolling(): NSAction {
  return withNS({ type: ActionType.BEGIN_POLLING });
}

function endPolling(): NSAction {
  return withNS({ type: ActionType.END_POLLING });
}

function activateGoal(goalId: GoalId): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.goals.activate(goalId)
      .then(() => loadAndDispatchGoalStatuses(dispatch, api))
      .then(
        () => dispatch(HUD.success()),
        () => dispatch(HUD.error())
      );
  };
}

function deactivateGoal(goalId: GoalId): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    const confirmText = 'Are you sure you want to deactivate this goal?';
    return Dialog.confirm(confirmText).then(
      () => {
        dispatch(HUD.loading());
        api.goals.deactivate(goalId)
          .then(() => loadAndDispatchGoalStatuses(dispatch, api))
          .then(
            () => dispatch(HUD.success()),
            () => dispatch(HUD.error())
          );
      },
      noop
    );
  };
}

function resetGoal(goalId: GoalId): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    return Dialog.confirm('Are you sure you want to reset this goal?').then(
      () => {
        dispatch(HUD.loading());
        return api.goals.reset(goalId)
          .then(() => loadAndDispatchGoalStatuses(dispatch, api))
          .then(
            () => dispatch(HUD.success()),
            () => dispatch(HUD.error())
          );
      },
      noop
    );
  };
}

function loadAndDispatchGoalStatuses(
  dispatch: StoreDispatch, api: AppAPI, userId?: string
): Promise<{}> {
  return api.goals.getGoalStatuses(userId)
    .then(
      ({
        goal_statuses: statusesJSON, goals: goalsJSON, user_id: actualUserId
      }) => {
        const goalStatuses = statusesJSON.map(goalStatusFromJSON);
        const goals = goalsJSON.map(goalFromJSON);
        dispatch(withNS({
          type: ActionType.LOAD_GOAL_STATUSES__SUCCESS,
          userId: actualUserId,
          goalStatuses,
          goals
        }));
        return {};
      });
}
