import * as R from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

import { State, DispatchProps } from 'Store';
import * as Urls from 'Shared/Urls';
import { HUD } from 'HUD';

import featureGate from 'App/UI/FeatureGate';
import Spinner from 'Shared/UI/Spinner';
import { SEL as UserSEL, UserId, MinimalUser } from 'User';
import { ThreadType, validThreadType } from 'Inbox/Data';
import { ActionCreators } from 'Inbox/ActionCreator';
import { ActionCreators as UserAC } from 'User';
import { UI as NavUI } from 'Nav';
import CommentForm from 'Comments/UI/CommentForm';

type Props = RouteComponentProps<URLParams> & State & DispatchProps & {
};

interface URLParams {
  type: string,
  userId: string
}

interface UIState {
  submitting: boolean
}

class NewThread extends React.Component<Props, UIState> {
  constructor(props: Props) {
    super(props);
    this.state = { submitting: false };
    this.submitMessage = this.submitMessage.bind(this);
  }

  componentDidMount() {
    if (!this.validURLThreadType()) {
      this.props.dispatch(push(Urls.inboxUrl()))
    }

    if (!this.carePros()) {
      this.props.dispatch(UserAC.loadCurrentUser());
    }

    if (!this.crewMembers()) {
      this.props.dispatch(UserAC.loadCrew());
    }
  }

  validURLThreadType(): boolean {
    const urlType = this.props.match.params.type;
    return validThreadType(urlType);
  }

  threadType(): ThreadType {
    const urlType = this.props.match.params.type;
    if (validThreadType(urlType)) {
      return urlType;
    } else {
      // This shoudl never happen because we validate this in componentDidMount
      throw new Error('invalid thread type:' + urlType);
    }
  }

  recipientId(): UserId {
    return this.props.match.params.userId;
  }

  submitMessage(msg: string) {
    this.setState({ submitting: true });
    this.props.dispatch(
      ActionCreators.createThread(this.threadType(), this.recipientId(), msg)
    );
  }

  carePros(): MinimalUser[] | undefined {
    return UserSEL.carePros(this.props.currentUser).value;
  }

  crewMembers(): MinimalUser[] | undefined {
    return UserSEL.crewUsers(this.props.currentUser).value;
  }

  render() {
    const carePros = this.carePros();
    const crewMembers = this.crewMembers();
    let content;
    if (carePros && crewMembers) {
      content = this.renderContent(carePros, crewMembers);
    } else {
      content = <Spinner wrap="center" />;
    }

    return (
      <NavUI.NavBarLayout title="Messages" initBreadcrumb={Urls.inboxUrl()}>
        {content}
      </NavUI.NavBarLayout>
    );
  }

  renderContent(carePros: MinimalUser[], crewUsers: MinimalUser[]) {
    const recipient = findRecipient(this.recipientId(), carePros) ||
                      findRecipient(this.recipientId(), crewUsers);
    if (recipient) {
      return (
        <div className="inbox-container">
          <div>{recipient.name}</div>
          <CommentForm
            submit={this.submitMessage} placeholder="" buttonLabel="send"/>
          {this.state.submitting && <HUD state="loading" />}
        </div>
      );
    } else {
      return (
        <p>unknown user</p>
      );
    }
  }
}


export default featureGate(
  withRouter(connect(
    (state: State) => state
  )(NewThread)),
  ['inbox']
);

function findRecipient(
  userId: UserId, users: MinimalUser[]
): MinimalUser | undefined {
  return R.find(u => u.id === userId, users);
}
