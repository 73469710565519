import {
  AutoMonitoring, AutoMonitoringMode, DailyEnabledMap,
  byteToAutoMonitoringType
} from 'BLE/Protocols/JStyle1963YH/Data';

import { bcdDecode, decodeHighByteInBack } from './Util';
/**
Command response:
Check right and execute OK, then return:
0x2B AA BB CC DD EE FF GG HH 00 00 00 00 00 CRC
Check wrong then execute Fail then return:
0xAB 00 00 00 00 00 00 00 00 00 00 00 00 00 00 CRC
Response description:
AA: HR monitoring mode, 0: Turn off, 1: HR monitoring mode in time period, 2: HR
  monitoring in set time interval.
BB: Stands for the hour of the time start HR monitoring, (24-hour clock), BCD
  code format (such as 23:00, means BB = 0x23).
CC: Stands for the minute of the time start HR monitoring, (24-hour clock), BCD
  code format (such as 00:59, means CC = 0x59).
DD: Stands for the hour of the time end HR monitoring (24-hour clock), BCD code
  format (such as 23:00, means DD = 0x23).
EE: Stands for the minute of the time end HR monitoring, BCD code format (such
  as 00:59, means EE = 0x59).
FF: Stands for week enable bit.
Bit0 = 0 Sunday disable, Bit0 = 1 Sunday enable
Bit1 = 0 Monday Disable, Bit1 = 1 Monday enable
Bit2 = 0 Tuesday Disable, Bit2 = 1 Tuesday enable
Bit3 = 0 Wednesday Disable, Bit3 = 1 Wednesday enable
Bit4 = 0 Thursday Disable, Bit4 = 1 Thursday enable
Bit5 = 0 Friday Disable, Bit5 = 1 Friday enable
Bit6 = 0 Saturday Disable, Bit6 = 1 Saturday enable
GG HH: When HR monitoring mode is 2, namely, when AA=2, it means how often to
  test once, the unit is minute, and each measurement time is one minute.

In firmware version 1.5.9 and above, another byte follows HH
II -
=01 Heart rate automatic detection;
=02 Blood oxygen automatic detection;
=03 Body temperature automatic detection;
=04 Blood pressure automatic detection
**/
export function decode(packet: Uint8Array): AutoMonitoring {
  return {
    mode: mode(packet[1]),
    startHour: bcdDecode(packet[2]),
    startMinute: bcdDecode(packet[3]),
    endHour: bcdDecode(packet[4]),
    endMinute: bcdDecode(packet[5]),
    dailyEnabled: dailyEnabledMap(packet[6]),
    interval: decodeHighByteInBack(packet.slice(7, 9)),
    dataType: byteToAutoMonitoringType(packet[9])
  };
}

function mode(m: number): AutoMonitoringMode {
  switch(m) {
    case 0:
      return 'off';
    case 1:
      return 'period';
    case 2:
      return 'interval';
    default:
      throw new Error(`Unrecognized auto hr monitoring mode: ${m}`)
  }
}

function dailyEnabledMap(bits: number): DailyEnabledMap {
  return {
    sunday: getBit(bits, 0),
    monday: getBit(bits, 1),
    tuesday: getBit(bits, 2),
    wednesday: getBit(bits, 3),
    thursday: getBit(bits, 4),
    friday: getBit(bits, 5),
    saturday: getBit(bits, 6)
  }
}

function getBit(bits: number, i: number): boolean {
  return ((bits & (1 << i)) >> i) === 1
}
