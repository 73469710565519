import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { defaultConnect } from 'Shared/ReduxComponent';
import { State, StoreDispatch } from 'Store';
import { NavBarLayout } from 'Nav';
import * as Urls from 'Shared/Urls';

import { ActionCreators } from 'Challenges/ActionCreator';
import { SEL as UserSEL, ActionCreators as UserAC, CrewMember } from 'User';
import CrewList from 'Community/UI/CrewList';
import InviteButton, { Status as ButtonStatus } from 'Challenges/UI/InviteButton';

type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch
}

interface URLParams {
  challengeId: string
}

interface LocalState {
  loading: { [userId: string]: boolean }
  invited: { [userId: string]: boolean }
}


class Invite extends React.Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    this.state = { loading: {}, invited: {} };
  }

  componentDidMount() {
    this.props.dispatch(UserAC.loadCrew());
  }

  challengeId() {
    return parseInt(this.props.match.params.challengeId);
  }

  crew(): CrewMember[] | undefined {
    return UserSEL.crewMembers(this.props.currentUser).value;
  }

  inviteWasSent(member: CrewMember): boolean {
    return !!this.state.invited[member.user.id];
  }

  buttonStatus(member: CrewMember): ButtonStatus {
    const userId = member.user.id;
    if (this.inviteWasSent(member)) {
      return 'success';
    } else if (this.state.loading[userId]) {
      return 'loading';
    } else {
      return 'default';
    }
  }

  inviteMember = (member: CrewMember) => (e: React.MouseEvent<any>) => {
    e.preventDefault();
    this.setState({
      loading: { ...this.state.loading, [member.user.id]: true }
    });

    this.props.dispatch(
      ActionCreators.inviteCrewMember(this.challengeId(), member)
    ).then(() => this.setState({
      invited: { ...this.state.invited, [member.user.id]: true }
    }));
  }

  render() {
    return (
      <NavBarLayout
        title="Invite to challenge"
        initBreadcrumb={Urls.challengeUrl(this.challengeId())}>
        <p>You can invite anyone in your community</p>
        <CrewList crew={this.crew()} rightDetail={this.renderInviteButton}/>
      </NavBarLayout>
    );
  }

  renderInviteButton = (member: CrewMember) => {
    return (
      <InviteButton
        status={this.buttonStatus(member)}
        onClick={this.inviteMember(member)}>
        invite
      </InviteButton>
    );
  }
}

export default withRouter(defaultConnect(Invite));
