import * as moment from 'moment-timezone';

// In seconds from 00:00
export type TimeOfDay = number;
export type Minute = number;
export type Hour = number;


interface FormatOptions {
  shortTime: boolean
}

export function format(tod: TimeOfDay, opts?: FormatOptions): string {
  const m = minute(tod);
  const h = hour(tod) % 12;
  const mStr = m < 10 ? '0' + m : m;
  const hStr = h === 0 ? 12 : h;
  if (opts && opts.shortTime) {
    return `${hStr} ${ampm(tod)}`;
  } else {
    return `${hStr}:${mStr} ${ampm(tod)}`;
  }
}

/**
 * Parse time of day from one of the following formats:
 * "7:35 AM" === 27300
 * "7:35 PM" === 70500
 * "13:15"   === 47700
 * "5"       === 18000
 * "5 PM"    === 61200
 */
const TIME_PATTERN = /^([12]?\d)(:([0-6]\d))?\s?(AM|PM)?$/i;
export function parse(str: string): TimeOfDay | null {
  const result = str.match(TIME_PATTERN);
  if (result) {
    const parsedHour = parseInt(result[1]);
    const parsedMinute = result[3] ? parseInt(result[3]) : null;
    const parsedAMPM = result[4];

    if (parsedHour > 12 && parsedAMPM) { return null; }
    if (parsedMinute && parsedMinute > 59) { return null; }

    const hour = parsedAMPM ?
      applyAMPM((parsedAMPM.toUpperCase() as AMPM), parsedHour) :
      parsedHour;
    const minutes = parsedMinute || 0;
    return fromHourMinute(hour, minutes);
  } else {
    return null;
  }
}

export function fromHour(hour: number): TimeOfDay {
  return hour * 60 * 60;
}

export function fromHourMinute(hour: number, minute: number): TimeOfDay {
  return fromHour(hour) + 60 * minute;
}

/**
 * Return the hour component of the given time of day
 * ex: hour(0) == 0
 *     hour(8.5 * 60 * 60) == 8
 */
export function hour(tod: TimeOfDay): number {
  return Math.floor(tod / 60 / 60);
}

/**
 * Return the minute component of the given time of day
 */
export function minute(tod: TimeOfDay): number {
  return (tod / 60) % 60;
}

/**
 * Return the second component of the given time of day
 * ex: second(parse('9:30:24')) == 24
 */
export function second(tod: TimeOfDay): number {
  return tod % 60;
}

/**
 * Use the date portion of the given moment, and return a moment object of this
 * TOD on that date
 */
export function onDate(tod: TimeOfDay, date: moment.Moment): moment.Moment {
  return date.clone()
    .hours(hour(tod))
    .minutes(minute(tod))
    .seconds(second(tod));
}

type AMPM = "AM" | "PM";
function ampm(tod: TimeOfDay): AMPM {
  return Math.floor(hour(tod) / 12) % 2 === 0 ? 'AM' : 'PM';
}

function applyAMPM(ampm: AMPM, hour: number) {
  if (hour === 12) {
    return ampm === 'AM' ? 0 : 12;
  } else {
    if (ampm === 'PM') {
      return hour + 12;
    } else {
      return hour;
    }
  }
}
