import { History } from 'history';
import {
  Store, Dispatch, createStore, applyMiddleware
} from 'redux';
import {
  routerMiddleware, connectRouter //routerReducer
} from 'connected-react-router';
import thunkMiddleware, { ThunkAction } from 'redux-thunk'

import { loggerMiddleware } from 'App/Store';
import { State as AppState, initialState } from 'App/Data';
import { apiClient } from 'Api';
import { AppAPI, makeApi } from 'App/Api'
import { HealthKit } from 'iOS';
import * as BLE from 'BLE';
import { TokenStore } from 'Auth/TokenStore';
import hraExitGuard from 'Screenings/HRAExitGuard';

// Imports from modules that make up the app
import * as App from 'App';
import * as Dashboard from 'Dashboard';
import * as Challenges from 'Challenges';
import * as Post from 'Post';
import * as Settings from 'Settings';
import * as User from 'User';
import * as Edge from 'Edge';
import * as Goals from 'Goals';
import * as Screenings from 'Screenings';
import * as Inbox from 'Inbox';
import * as CMS from 'CMS';
import * as HUD from 'HUD';
import * as Auth from 'Auth';
import * as Nav from 'Nav';
import * as Appt from 'Appt';
import * as Community from 'Community';
import * as Trends from 'Trends';
import * as Onboard from 'Onboard';
import * as Rewards from 'Rewards';
import * as CarePlans from 'CarePlans';
import * as Network from 'Network';
import * as Maintenance from 'Maintenance';
import * as VersionGuard from 'VersionGuard';
import * as Home from 'Home';

/*============================================================*/
// Root state and store related types

export type State = AppState;

export type AppStore = Store<State>;
export interface ThunkServices {
  api: AppAPI
  healthKitFetcher: HealthKit.Fetcher,
  history: History,
  tokenStore: TokenStore,
  bluetooth: BLE.Service
}

export type StoreDispatch = Dispatch<State>;

export interface DispatchProps {
  dispatch: StoreDispatch
}

export type ActionCreatorThunk = ActionCreatorThunkThen<any>;
export type ActionCreatorThunkThen<T> =
  ThunkAction<Promise<T>, State, ThunkServices>;


/**
 * Top level union of actions defined in modules
 */

export type Action
  = Challenges.Action
  | Dashboard.NSAction
  | Post.NSAction
  | Settings.Action
  | User.Action
  | Edge.NSAction
  | Goals.NSAction
  | Screenings.NSAction
  | Inbox.NSAction
  | HUD.Action
  | Auth.NSAction
  | Nav.NSAction
  | CMS.NSAction
  | Appt.NSAction
  | App.NSAction
  | Community.NSAction
  | Trends.NSAction
  | Onboard.NSAction
  | Rewards.NSAction
  | CarePlans.NSAction
  | Maintenance.NSAction
  | Network.NSAction
  | Home.NSAction
  | BLE.NSAction;

/**
 * Store factory function
 */

export function makeStore(
  history: History, tokenStore: TokenStore
): Store<State> {
  const client = apiClient(tokenStore);
  const api = makeApi(client, tokenStore);
  const services: ThunkServices = {
    api,
    healthKitFetcher: HealthKit.makeFetcher(),
    history,
    tokenStore,
    bluetooth: BLE.makeService(window.bluetoothle)
  };

  const middlewares = [
    thunkMiddleware.withExtraArgument(services),
    loggerMiddleware,
    routerMiddleware(history)
  ];

  const store = createStore(
    rootReducer(history),
    initialState(),
    applyMiddleware(...middlewares)
  );

  history.listen(hraExitGuard(store));
  client.addMiddleware(Maintenance.makeApiMiddleware(store));
  client.addMiddleware(VersionGuard.makeApiMiddleware(store));
  client.addMiddleware(Auth.makeApiMiddleware(store));

  return store;
}

const rootReducer = (
  history: History
) => (
  state: State, action: Action
): State => {
  // TODO: figure out how to avoid this non-null assertion.  After a round of
  // upgrades on react-router and friends, this didn't type check anymore
  // without that.  Has to do w/ switching `router` to a possibly undefined
  // value
  state = {
    ...state,
    router: connectRouter(history)(state.router!, action)
  };
  // router: routerReducer(state.router, action) };
  switch(action.NS) {
  case App.NS:
    return App.reducer(state, action);

  case Challenges.NS:
    return {
        ...state,
      challenges: Challenges.reducer(state.challenges, action)
    };

  case Dashboard.NS:
    return {
        ...state,
      dashboard: Dashboard.reducer(state.dashboard, action)
    };

  case Trends.NS:
    return {
        ...state,
      trends: Trends.reducer(state.trends, action)
    };

  case Onboard.NS:
    return {
        ...state,
      onboard: Onboard.reducer(state.onboard, action)
    };

  case Maintenance.NS:
    return {
        ...state,
      maintenance: Maintenance.reducer(state.maintenance, action)
    };

  case Network.NS:
    return {
        ...state,
      network: Network.reducer(state.network, action)
    };

  case Post.NS:
    return {
        ...state,
      post: Post.reducer(state.post, action)
    };

  case Settings.NS:
    return {
        ...state,
      settings: Settings.reducer(state.settings, action)
    };

  case User.NS:
    return {
        ...state,
      currentUser: User.reducer(state.currentUser, action)
    };

  case Community.NS:
    return {
        ...state,
      currentUser: Community.reducer(state.currentUser, action)
    };

  case Edge.NS:
    return {
        ...state,
      edge: Edge.reducer(state.edge, action)
    };

  case Goals.NS:
    return {
        ...state,
      goals: Goals.reducer(state.goals, action)
    };

  case Screenings.NS:
    return {
        ...state,
      screenings: Screenings.reducer(state.screenings, action)
    };

  case Inbox.NS:
    return {
        ...state,
      inbox: Inbox.reducer(state.inbox, action)
    };

  case HUD.NS:
    return {
        ...state,
      hud: HUD.reducer(state.hud, action)
    };

  case Auth.NS:
    return {
        ...state,
      auth: Auth.reducer(state.auth, action)
    };
  case Nav.NS:
    return {
        ...state,
      nav: Nav.reducer(state.nav, action)
    };

  case CMS.NS:
    return {
        ...state,
      cms: CMS.reducer(state.cms, action)
    };

  case Appt.NS:
    return {
        ...state,
      appt: Appt.reducer(state.appt, action)
    };

  case Rewards.NS:
    return {
        ...state,
      rewards: Rewards.reducer(state.rewards, action)
    };

  case CarePlans.NS:
    return {
        ...state,
      carePlans: CarePlans.reducer(state.carePlans, action)
    };

  case Home.NS:
    return {
      ...state,
      home: Home.reducer(state.home, action)
    };

  case BLE.NS:
    return {
      ...state,
      ble: BLE.reducer(state.ble, action)
    };

  default:
    return state;
  }
}

/**------------------------------------------------------------**/

export function extractState(obj: State & {}): State {
  return obj;
}
