import * as React from 'react';
import * as moment from 'moment-timezone';
import { bem } from 'css-util';

import Link from 'Shared/UI/Link';
import AlertBox from 'Shared/UI/AlertBox';
import { RewardPlan } from 'Rewards/Data';
import ProgressBar from 'Rewards/UI/ProgressBar';

import * as Urls from 'Shared/Urls';
import Points from 'Rewards/UI/Points';
import RewardsIcon from 'Rewards/UI/Icon';

interface Props {
  rewardPlan: RewardPlan
}

const CSS = bem('rewards-summary');

const RewardPlanSummary = (props: Props) => {
  const plan = props.rewardPlan;

  return (
    <div className={CSS()()}>
      {plan.currentPoints >= plan.maxPoints &&
       (
         <AlertBox modifiers={['success', 'center', 'big']}>
           Congratulations, it looks like you've earned maximum points!
         </AlertBox>
       )
      }
      <h4 className="section-title">
        {formatDate(plan.startDate)} - {formatDate(plan.endDate)}
      </h4>

      <div className="simple-container">
        <div className={CSS('icon')()}>
          <RewardsIcon />
        </div>
        <div className={CSS('point-summary')()}>
          Your points: <Points>{plan.currentPoints}</Points>
          <br/>
          Maximum points: <Points>{plan.maxPoints}</Points>
        </div>

        <div className={CSS('progress-bar')()}>
          <ProgressBar rewardPlan={plan} />
        </div>

        <hr/>

        <Link to={Urls.availableRewardsUrl()}
          breadcrumb="push"
          className="button button--block theme__primary-bg">
          EARN REWARDS
        </Link>
        <Link
          to={Urls.aboutRewardsUrl()}
          breadcrumb="push"
          className="button button--block button--secondary theme__primary-color
                     theme__border-color">
          learn more about rewards
        </Link>
      </div>
    </div>
  );
};

export default RewardPlanSummary;

function formatDate(d: moment.Moment): string {
  return d.format('MMM D, YYYY');
}
