import * as moment from 'moment-timezone';

import { decodeMoment } from './Util';
import * as CRC from '../CRC';


// Command response:
// Check right and execute OK, then return:
//   0x41 AA BB CC DD EE FF WW GG HH II JJ KK LL 00 00 CRC
// Check error and execute Fail, then return:
//   0xC1 00 00 00 00 00 00 00 00 00 00 00 00 00 00 CRC
// Response description:
// AA = year, BB = month, CC = day, DD = hour, EE = minute, FF = second.
// The format is BCD, e.g., 12 year, AA = 0x12.
// GG: Maximum length of Bluetooth data packet.
// HH II JJ: Start time of ephemeris data validity.
// KK: The number of days the ephemeris data is valid.
// LL MM: Time difference setting. The unit is minute, the highest bit is
//   behind, and the highest bit is the sign bit. 1 represents negative number,
//   0 means positive number.
export function decode(packet: Uint8Array): moment.Moment {
  CRC.verify(packet);
  return decodeMoment(packet.slice(1, 7));
}
