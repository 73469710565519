import * as React from 'react';
import * as classnames from 'classnames';

interface Props {
  message?: string,
  children?: React.ReactNode
  className?: string
}

const NoContent = (props: Props) => {
  return (
    <p className={classnames('no-content', props.className)}>
      {props.children || props.message}
    </p>
  );
}

export default NoContent;
