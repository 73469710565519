import { HUDState } from './Data';
import { Action, ActionType } from './Action';

export function reducer(state: HUDState, action: Action): HUDState {
  switch(action.type) {
  case ActionType.SET_HUDSTATE:
    return action.hudState;
  default:
    return state;
  }
}
