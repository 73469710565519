import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { State, DispatchProps } from 'Store';
import { defaultConnect } from 'Shared/ReduxComponent';
import * as Urls from 'Shared/Urls';

import {
  ChallengeId,
  ChallengeDetails,
  Team,
  TeamId,
  LENSES,
  SEL,
  TeamFormData,
  userIsEnrolled,
  teamsEnabled
} from 'Challenges/Data'
import { ActionCreators } from 'Challenges/ActionCreator';

import { NavBarLayout } from 'Nav';
import { HUD, ActionCreators as HUDAC } from 'HUD';
import Spinner from 'Shared/UI/Spinner';

import GraphicHeader from 'Challenges/UI/GraphicHeader';
import TeamForm from 'Challenges/UI/Teams/TeamForm';
import TeamUrl from 'Challenges/UI/Teams/TeamUrl';

type Props = State & RouteComponentProps<URLParams> & DispatchProps;

interface URLParams {
  challengeId: string,
  teamId?: string
}

class ManageTeam extends React.Component<Props, {}> {
  componentDidMount() {
    let loadChallenge: Promise<ChallengeDetails>;
    const details = this.challengeDetails();
    if (details === undefined) {
      loadChallenge = this.props.dispatch(
        ActionCreators.loadChallenge(this.challengeId())
      );
    } else {
      loadChallenge = Promise.resolve(details);
    }

    loadChallenge
      .then(details => this.requireTeamsEnabled(details))
      .then(() => this.requireChallengeEnrollment())
      .then(() => this.loadTeam());
  }

  requireTeamsEnabled(details: ChallengeDetails): Promise<void> {
    return new Promise(resolve =>
      teamsEnabled(details) ? resolve() : this.gotoChallengePage()
    );
  }

  requireChallengeEnrollment(): Promise<void> {
    return new Promise(resolve => {
      const details = this.challengeDetails();
      if (!details || !userIsEnrolled(details)) {
        this.gotoChallengePage();
      } else {
        resolve();
      }
    });
  }

  gotoChallengePage() {
    this.props.dispatch(push(Urls.challengeUrl(this.challengeId())));
  }

  loadTeam() {
    const teamId = this.teamId();
    if (teamId) {
      this.props.dispatch(ActionCreators.loadTeams(this.challengeId()));
    }
  }

  challengeId(): ChallengeId {
    return parseInt(this.props.match.params.challengeId);
  }

  teamId(): TeamId | undefined {
    const urlId = this.props.match.params.teamId;
    return urlId ? parseInt(urlId) : undefined;
  }

  team(): Team | undefined {
    const teamId = this.teamId();
    if (teamId) {
      return SEL.team(this.challengeId(), teamId, this.props.challenges);
    }
  }

  challengeDetails(): ChallengeDetails | undefined {
    return LENSES.challenge(this.challengeId()).get(this.props.challenges);
  }

  submit = (data: TeamFormData) => {
    const teamId = this.teamId();
    if (teamId) {
      this.props.dispatch(
        ActionCreators.updateTeam(this.challengeId(), teamId, data)
      );
    } else {
      this.props.dispatch(ActionCreators.createTeam(this.challengeId(), data));
    }
  }

  hudSuccess = () => this.props.dispatch(HUDAC.success());

  render() {
    const details = this.challengeDetails();
    return (
      <NavBarLayout
        title="Challenge"
        initBreadcrumb={Urls.challengeUrl(this.challengeId())}>
        <HUD state={this.props.hud} />
        <div className="simple-container">
          {details ? this.renderContent(details) : <Spinner wrap="center" />}
        </div>
      </NavBarLayout>
    );
  }

  renderContent(details: ChallengeDetails) {
    const team = this.team();
    return (
      <div>
        <GraphicHeader challenge={details} />
        {team ?
         <TeamUrl
           team={team}
           challengeId={this.challengeId()}
           onSuccess={this.hudSuccess}
         /> : null
        }
        <TeamForm onSubmit={this.submit} team={team} />
      </div>
    );
  }
}

export default withRouter(defaultConnect(ManageTeam));
