import { OK, GET, POST, PUT, ApiClient } from 'Api';

import {
  InboxThreadsResponseJSON,
  InboxThreadResponseJSON
} from './JSON/Inbox';

export interface InboxAPI {
  getInboxThreads(page: number): Promise<InboxThreadsResponseJSON>

  getThread(threadId: string): Promise<InboxThreadResponseJSON>

  createThread(
    recipientType: string, recipientId: string, message: string
  ): Promise<InboxThreadResponseJSON>

  sendMessageInThread(threadId: string, message: string): OK
}

export function makeApi(client: ApiClient): InboxAPI {
  return {
    getInboxThreads,
    getThread,
    createThread,
    sendMessageInThread
  };

  function getInboxThreads(page: number): Promise<InboxThreadsResponseJSON> {
    return client.requestJSON(
      GET, { path: '/inbox_threads', query: { page }}
    );
  }

  function getThread(threadId: string): Promise<InboxThreadResponseJSON> {
    return client.requestJSON(GET, `/inbox_threads/${threadId}`);
  }

  function createThread(
    recipientType: string, recipientId: string, text: string
  ): Promise<InboxThreadResponseJSON> {
    return client.requestJSON(
      POST, `/inbox_threads`,
      { recipient_id: recipientId,
        recipient_type: recipientType,
        text
      }
    );
  }

  function sendMessageInThread(
    threadId: string, message: string
  ): OK {
    return client.requestJSON(
      PUT, `/inbox_threads/${threadId}`, { text: message }
    );
  }
}
