import * as React from 'react'

import { bem } from 'css-util';

import { NormalizableChallengeObject } from 'Challenges/Data';
import * as Data from 'Challenges/Data';
import ChallengeDates, { DateStyle } from 'Challenges/UI/ChallengeDates';

interface Props {
  challenge: NormalizableChallengeObject
}

const GraphicHeader = (props: Props) => {
  const data = Data.normalizedData(props.challenge);
  const css = bem(bemBlock(props.challenge));
  return (
    <div className="challenge-graphic-header">
      <img
        src={data.graphicUrl}
        className={css('graphic')()} />
      <h3 className={css('title')()}>
        {data.name}
      </h3>
      <ChallengeDates
        startDate={data.startDate}
        endDate={data.endDate}
        style={challengeDatesStyle(props.challenge)}/>
    </div>
  );
}

export default GraphicHeader;

function bemBlock(data: NormalizableChallengeObject): string {
  return enrolled(data) ? 'challenge-enrolled' : 'challenge-unenrolled';
}

function challengeDatesStyle(data: NormalizableChallengeObject): DateStyle {
  return enrolled(data) ? 'abbreviate' : 'verbose';
}

function enrolled(data: NormalizableChallengeObject): boolean {
  return (Data.isChallengeSummary(data) && Data.userIsEnrolled(data)) ||
         (Data.isChallengeDetails(data) && Data.userIsEnrolled(data));
}
