import { Device } from 'BLE/Data';
import {
  JStyle1963YHProtocol,
  makeProtocol as make1963
} from 'BLE/Protocols/JStyle1963YH/Protocol';

export function makeProtocol(
  ble: BluetoothlePlugin.Bluetoothle,
  device: Device
): JStyle1963YHProtocol {
  return make1963(ble, device);
}
