const STORAGE_KEY = 'bios:auth:token';

export interface TokenStore {
  get(): string | undefined,
  set(token: string, persistent: boolean): void,
  delete(): void,
  refresh(newToken: string): void
}

const store: TokenStore = {
  get, set, delete: deleteToken, refresh
};

export { store };

function get(): string | undefined {
  const token =
    window.localStorage.getItem(STORAGE_KEY) ||
    window.sessionStorage.getItem(STORAGE_KEY);

  if (token) return token;
}

function set(token: string, persistent: boolean) {
  const storage = persistent ? window.localStorage : window.sessionStorage;
  storage.setItem(STORAGE_KEY, token);
}

function deleteToken() {
  window.localStorage.removeItem(STORAGE_KEY);
  window.sessionStorage.removeItem(STORAGE_KEY);
}

function refresh(newToken: string) {
  set(newToken, true);
}
