import * as moment from 'moment-timezone';
import { ActionCreatorThunk, StoreDispatch } from 'Store';

import { SpecUpdate } from 'Specs';
import { ActionCreators as EdgeActions } from 'Edge';
import { ActionCreators as UserActions, UserId, SEL as UserSEL } from 'User';
import { ActionCreators as HUD } from 'HUD';
import {
  NS, Activity, Biopost, Comment, ActivityId, BiopostId
} from './Data';
import { pagingFromJSON } from 'Shared/Paging';
import { ActionType, withNS, NSAction } from './Action';
import * as Action from './Action';
import { GetActivitiesJSON } from 'Api/JSON/Post';
import {
  biopostFromJSON, dynamicBiopostFromJSON, specUpdateToJSON,
  recommendedActivityFromJSON, extractActivityDataFromJSON, commentFromJSON
} from './JSON';
import { profPicUrl } from 'Shared/ProfPic';

type PostSuccessFn = (b: Biopost, a: Activity) => void

export interface ActionCreators {
  resetActivities(): NSAction,

  loadActivities(): ActionCreatorThunk,

  loadOnboardActivities(): ActionCreatorThunk,

  loadGeniusRecommendations(): ActionCreatorThunk,

  loadLastBiopost(): ActionCreatorThunk,

  postActivity(
    a: Activity,
    onSuccess?: PostSuccessFn
  ): ActionCreatorThunk,

  postActivity(
    a: Activity,
    note: string,
    onSuccess?: PostSuccessFn
  ): ActionCreatorThunk,

  postSpecUpdate(
    u: SpecUpdate,
    onSuccess?: (b: Biopost, u: SpecUpdate) => void
  ): ActionCreatorThunk

  addFavorite(activityId: ActivityId): ActionCreatorThunk

  removeFavorite(activityId: ActivityId): ActionCreatorThunk

  loadMyFeed(page: number): ActionCreatorThunk

  loadMyDynamicPosts(): ActionCreatorThunk

  loadCrewFeed(page: number): ActionCreatorThunk

  loadUserFeed(userId: UserId, page: number): ActionCreatorThunk

  loadComments(biopostId: BiopostId): ActionCreatorThunk

  postComment(
    biopostId: BiopostId, commentText: string, onComplete?: () => void
  ): ActionCreatorThunk

  giveProps(biopostId: BiopostId): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  resetActivities,
  loadActivities,
  loadOnboardActivities,
  loadGeniusRecommendations,
  loadLastBiopost,
  postActivity,
  postSpecUpdate,
  addFavorite,
  removeFavorite,
  loadMyFeed,
  loadMyDynamicPosts,
  loadCrewFeed,
  loadUserFeed,
  loadComments,
  postComment,
  giveProps
};

function action(obj: Object): Action.Action {
  return {
    NS,
    ...obj
  } as Action.Action;
}

function resetActivities(): NSAction {
  return withNS({ type: ActionType.RESET_ACTIVITIES });
}

function loadActivities(): ActionCreatorThunk {
  return (dispatch, _, { api }) =>
    api.post.getActivities().then(jsonData =>
      handleActivitiesResponse(dispatch, jsonData)
    );
}

function loadOnboardActivities(): ActionCreatorThunk {
  return (dispatch, _, { api }) =>
    api.post.getActivitiesForOnboard().then(
      result => handleActivitiesResponse(dispatch, result)
    );
}

function handleActivitiesResponse(
  dispatch: StoreDispatch, jsonData: GetActivitiesJSON
) {
  const data = extractActivityDataFromJSON(jsonData);
  const action: Action.LoadActivities_Success = {
    NS,
    type: ActionType.LOAD_ACTIVITIES__SUCCESS,
    activities: data.activities,
    categories: data.categories,
    favorites: data.favorites,
    favoritesActivities: data.favoritesActivities,
    genius: data.genius,
    geniusRecommendations: data.geniusRecommendations,
    specsCategoryName: data.specsCategoryName
  };
  dispatch(action);
}

function loadGeniusRecommendations(): ActionCreatorThunk {
  return (dispatch, _, { api }) =>
    api.post.getGeniusRecommendations().then(result => {
      const recommendations =
        result.activities.map(recommendedActivityFromJSON);
      const action: Action.LoadGeniusRecommendations_Success = {
        NS,
        type: ActionType.LOAD_GENIUS_RECOMMENDATIONS__SUCCESS,
        recommendations
      };
      dispatch(action);
    });
}

function loadLastBiopost(): ActionCreatorThunk {
  return (dispatch, _, { api }) =>
    api.user.getCurrentUserLastBiopost().then(({ biopost: biopostJSON }) => {
      const biopost = biopostJSON ? biopostFromJSON(biopostJSON) : undefined;
      dispatch(withNS({
        type: ActionType.LOAD_LAST_BIOPOST__SUCCESS,
        biopost
      }));
    });
}

function postActivity(
  a: Activity,
  noteOrOnSuccess?: string | PostSuccessFn,
  onSuccessOrNil?: PostSuccessFn
): ActionCreatorThunk {
  let onSuccess: PostSuccessFn | undefined = undefined;
  let note: string | undefined = undefined;

  if (typeof noteOrOnSuccess === 'string') {
    note = noteOrOnSuccess;
    if (onSuccessOrNil) {
      onSuccess = onSuccessOrNil;
    }
  } else if (typeof noteOrOnSuccess === 'function') {
    onSuccess = noteOrOnSuccess;
  }

  return (dispatch, _getState, { api }) => {
    dispatch(action({
      type: ActionType.POST_ACTIVITY__BEGIN,
      activity: a
    }));

    return api.post.postActivityWithNote(a.id, note).then(
      ({ biopost: biopostJSON })  => {
        const biopost = biopostFromJSON(biopostJSON);

        dispatch(action({
          type: ActionType.POST_ACTIVITY__SUCCESS,
          activity: a,
          biopost
        }) as Action.PostActivity_Success);

        dispatch(loadGeniusRecommendations());

        if (onSuccess) { onSuccess(biopost, a); }
      })
  }
}

function postSpecUpdate(
  update: SpecUpdate,
  onSuccess?: (post: Biopost, update: SpecUpdate) => void
): ActionCreatorThunk {
  return (dispatch, _, { api }) =>
    api.post.postSpecUpdate(specUpdateToJSON(update))
      .then(({ biopost: biopostJSON }) => {
        const biopost = biopostFromJSON(biopostJSON);

        dispatch(withNS({
          type: ActionType.POST_SPEC_UPDATE__SUCCESS,
          specUpdate: update,
          biopost
        }));

        return Promise.all([
          dispatch(UserActions.loadSpecs(() => {
            if (onSuccess) { onSuccess(biopost, update); }
          })),

          dispatch(UserActions.loadCurrentUser(true))
        ]);
      });
}

function beginLoadFeed(page: number): NSAction {
  return withNS({ type: ActionType.LOAD_FEED__BEGIN, page });
}

function loadMyFeed(page: number): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(beginLoadFeed(page));
    return api.post.getMyActivityFeed(page).then(
      result => {
        dispatch(withNS({
          type: ActionType.LOAD_MY_FEED__SUCCESS,
          bioposts: result.bioposts.map(biopostFromJSON),
          paging: pagingFromJSON(result.paging)
        }));
      }
    );
  }
}

function loadMyDynamicPosts(): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.post.getMyDynamicPosts().then(result => {
      dispatch(HUD.close(0));
      dispatch(withNS({
        type: ActionType.LOAD_MY_DYNAMIC_POSTS__SUCCESS,
        bioposts: result.bioposts.map(dynamicBiopostFromJSON)
      }));
    });
  }
}

function loadCrewFeed(page: number): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(beginLoadFeed(page));
    return api.post.getCrewActivityFeed(page).then(
      result => {
        dispatch(withNS({
          type: ActionType.LOAD_CREW_FEED__SUCCESS,
          bioposts: result.bioposts.map(biopostFromJSON),
          paging: pagingFromJSON(result.paging)
        }));
      }
    );
  };
}

function loadUserFeed(userId: string, page: number): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(beginLoadFeed(page));
    return api.post.getUserActivityFeed(userId, page).then(
      result => {
        dispatch(withNS({
          type: ActionType.LOAD_USER_FEED__SUCCESS,
          bioposts: result.bioposts.map(biopostFromJSON),
          paging: pagingFromJSON(result.paging),
          userId
        }));
      }
    );
  };
}

function addFavorite(activityId: ActivityId): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(withNS({
      type: ActionType.ADD_FAVORITE__SUCCESS,
      activityId
    }));
    return api.post.addFavoriteActivity(activityId);
  };
}
function removeFavorite(activityId: ActivityId): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(withNS({
      type: ActionType.REMOVE_FAVORITE__SUCCESS,
      activityId
    }));
    return api.post.removeFavoriteActivity(activityId);
  };
}

function loadComments(biopostId: BiopostId): ActionCreatorThunk {
  return async (dispatch, getState, { api }) => {
    const result = await api.post.getComments(biopostId);
    await dispatch(EdgeActions.loadConfigurationOnce());
    const providers = getState().edge.providers || [];
    const comments = result.comments.map(c => commentFromJSON(providers, c));

    return dispatch(withNS({
      type: ActionType.LOAD_COMMENTS__SUCCESS,
      comments,
      biopostId
    }));
  }
}

function postComment(
  biopostId: BiopostId, commentText: string, onComplete?: () => void
): ActionCreatorThunk {
  return (dispatch, getState, { api }) =>
    api.post.postComment(biopostId, commentText).then(() => {
      const state = getState();
      const userName = UserSEL.userName(state.currentUser);
      const userId = UserSEL.userId(state.currentUser);
      const comment: Comment = {
        text: commentText,
        postedBy: userName || '',
        postedAt: moment(),
        picUrl: userId ? profPicUrl(userId) : undefined
      };
      const action: Action.PostComment_Success = {
        NS, type: ActionType.POST_COMMENT__SUCCESS,
        biopostId,
        comment
      };
      dispatch(action);
      if (onComplete) { onComplete(); }
    });
}

function giveProps(biopostId: BiopostId): ActionCreatorThunk {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const userId = UserSEL.userId(state.currentUser);
    const userName = UserSEL.userName(state.currentUser);

    if (userId && userName) {
      const begin: Action.GiveProps_Begin = {
        NS, type: ActionType.GIVE_PROPS__BEGIN, biopostId,
        prop: { giverId: userId }
      };
      dispatch(begin);
    }

    return api.post.giveProps(biopostId).then(
      () => {
        const success: Action.GiveProps_Success = {
          NS, type: ActionType.GIVE_PROPS__SUCCESS, biopostId
        };
        dispatch(success);
      },
      () => {
        const error: Action.GiveProps_Error = {
          NS, type: ActionType.GIVE_PROPS__ERROR, biopostId
        };
        dispatch(error);
      }
    );
  };
}
