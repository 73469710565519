export function randomByte(): number {
  return Math.floor(Math.random() * 0xff);
}

export function randomBytesUint8Array(count: number): Uint8Array {
  let result = new Uint8Array(count);
  for(let i = 0; i < count; i += 1) {
    result[i] = randomByte();
  }
  return result;
}
