import { ApiClient, OK, GET, POST } from 'Api';

import {
  RewardPlanResponseJSON,
  UserActionsResponseJSON
} from './JSON/Rewards';

export interface RewardsAPI {
  getRewardPlan(): Promise<RewardPlanResponseJSON>,

  getUserActions(): Promise<UserActionsResponseJSON>,

  performManualAction(rewardId: number): OK
}

export function makeApi(client: ApiClient): RewardsAPI {
  return {
    getRewardPlan,

    getUserActions,

    performManualAction
  };

  function getRewardPlan(): Promise<RewardPlanResponseJSON> {
    return client.requestJSON(GET, '/reward_plan');
  }

  function getUserActions(): Promise<UserActionsResponseJSON> {
    return client.requestJSON(GET, '/reward_user_actions');
  }

  function performManualAction(rewardId: number): OK {
    return client.requestJSON(POST, '/reward_user_actions', {
      reward_id: rewardId
    });
  }
}
