import * as React from 'react';
import * as classnames from 'classnames';
import { push } from 'connected-react-router';
import { bem } from 'css-util';
import { StoreDispatch } from 'Store';
import * as Urls from 'Shared/Urls';
import { MinimalUser, ScreeningStatus, InboxStatus } from 'User/Data';
import {
  ActionCreators as ChallengesAC, ChallengeInvitation, metricRequiresPreJoinSetup
} from 'Challenges';
import { ActionCreators as DashboardAC } from 'Dashboard/ActionCreator';
import { ActionCreators as CommunityAC } from 'Community';
import { ActionCreators as NavAC } from 'Nav';
import AlertBox from 'Shared/UI/AlertBox';
import Button from 'Shared/UI/Button';
import Icon from 'Shared/UI/Icon';

interface Props {
  crewRequests: MinimalUser[]
  screeningStatus: ScreeningStatus
  inboxStatus: InboxStatus
  challengeInvites: ChallengeInvitation[]
  dispatch: StoreDispatch
}

const CSS = bem('dashboard-notifications');

class Notifications extends React.Component<Props, {}> {
  reload = () => {
    this.props.dispatch(DashboardAC.loadMyDashboard());
  }
  acceptCrewInvite = (invite: MinimalUser) => {
    this.props.dispatch(CommunityAC.acceptCrewInvitation(invite))
        .then(this.reload);
  }

  declineCrewInvite = (invite: MinimalUser) => {
    this.props.dispatch(CommunityAC.declineCrewInvitation(invite))
        .then(this.reload);
  }

  acceptChallengeInvite = (invite: ChallengeInvitation) => {
    if (metricRequiresPreJoinSetup(invite.metric)) {
      this.props.dispatch(
        NavAC.pushWithBreadcrumb(Urls.challengeInvitationSetupUrl(invite.id)));
    } else {
      this.props.dispatch(
        ChallengesAC.acceptInvitation(invite.id, invite.challengeId)
      ).then(this.reload);
    }
  }

  declineChallengeInvite = (invite: ChallengeInvitation) => {
    this.props.dispatch(ChallengesAC.declineInvitation(invite.id))
        .then(this.reload);
  }

  gotoScreening = () => {
    this.props.dispatch(push(Urls.screeningFormUrl()));
  }

  gotoInbox = () => {
    this.props.dispatch(push(Urls.inboxUrl()));
  }

  render() {
    return (
      <div className={CSS()()}>
        {renderScreeningStatusNotification(
           this.props.screeningStatus, this.gotoScreening)}
        {renderCrewRequests(
           this.props.crewRequests,
           this.acceptCrewInvite,
           this.declineCrewInvite)}
        {renderChallengeInvites(
           this.props.challengeInvites,
           this.acceptChallengeInvite,
           this.declineChallengeInvite
         )}
      { renderInboxStatusNotification(this.props.inboxStatus, this.gotoInbox)}
      </div>
    );
  }
}

export default Notifications;

function renderCrewRequests(
  invites: MinimalUser[],
  accept: (u: MinimalUser) => void,
  decline: (u: MinimalUser) => void
) {
  return invites.map(u => renderCrewInvite(u, accept, decline));
}

function renderCrewInvite(
  user: MinimalUser,
  accept: (u: MinimalUser) => void,
  decline: (u: MinimalUser) => void
) {
  return (
    <div key={user.id}>
      {alertBox(`${user.name} has invited you to connect`)}

      <div className={CSS('action-buttons')()}>
        {actionButton('accept', () => accept(user))}
        {' '}
        {actionButton('decline', () => decline(user))}
      </div>
    </div>
  );
}

function actionButton(label: string, action: () => void) {
  return (
    <Button
      onClick={action}
      className={classnames('button button--green', CSS('action-button')())}>
      {label}
    </Button>
  );
}

function renderScreeningStatusNotification(
  status: ScreeningStatus,
  gotoScreening: () => void
) {
  if (status === ScreeningStatus.ACTIVE) {
    return renderActiveScreeningNotice(gotoScreening);
  }
}

function renderActiveScreeningNotice(gotoScreening: () => void) {
  return (
    <div key="screening">
      {alertBox('You have a Health Risk Assessment (HRA) invite')}
      <div className={CSS('action-buttons')()}>
        {actionButton('start now', gotoScreening)}
      </div>
    </div>
  );
}

function renderChallengeInvites(
  invites: ChallengeInvitation[],
  accept: (i: ChallengeInvitation) => void,
  decline: (i: ChallengeInvitation) => void
) {
  return invites.map(i => renderChallengeInvite(i, accept, decline));
}

function renderChallengeInvite(
  invite: ChallengeInvitation,
  accept: (i: ChallengeInvitation) => void,
  decline: (i: ChallengeInvitation) => void
) {
  return (
    <div key={`invite-${invite.id}`}>
      {alertBox(`You have a challenge invite for: ${invite.challengeName}`)}
      <div className={CSS('action-buttons')()}>
        {actionButton('accept', () => accept(invite))}
        {' '}
        {actionButton('decline', () => decline(invite))}
      </div>
    </div>
  );
}

function renderInboxStatusNotification(
  status: InboxStatus,
  gotoInbox: () => void
) {
  if (status === InboxStatus.NOT_ALL_READ) {
    return renderUnreadMessagesAlert(gotoInbox);
  }
}

function renderUnreadMessagesAlert(
  gotoInbox: () => void
) {
  return (
    <div>
      {alertBox('You have unread messages')}
      <div className={CSS('action-buttons')()}>
        {actionButton('go to messages', () => gotoInbox())}
      </div>
    </div>
  );
}

function alertBox(content: React.ReactNode) {
  return (
    <AlertBox modifiers="warning" className={CSS('alert-box')()}>
      <Icon name="exclamation-triangle"/>{' '}
      {content}
    </AlertBox>
  )
}
