import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { State, StoreDispatch } from 'Store';
import { SEL, State as InboxState, ThreadInfo, ThreadType } from 'Inbox/Data';
import { SEL as UserSEL, MinimalUser, UserId } from 'User/Data';

import * as Urls from 'Shared/Urls';
import { Paging } from 'Shared/Paging';
import Pager from 'Shared/UI/Pager';
import Spinner from 'Shared/UI/Spinner';

import { UI as NavUI } from 'Nav';
import { HUD } from 'HUD';
import featureGate from 'App/UI/FeatureGate';

import { ActionCreators } from 'Inbox/ActionCreator';
import { ActionCreators as UserAC } from 'User';
import { ActionCreators as NavAC } from 'Nav';
import NewMessageSelect from 'Inbox/UI/NewMessageSelect';
import ThreadList from 'Inbox/UI/ThreadList';

type Props = State & {
  dispatch: StoreDispatch
}

class MyInbox extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(UserAC.loadCrew());
    this.loadPage(this.paging().currentPage);
  }

  inboxState(): InboxState {
    return this.props.inbox;
  }

  threadInfos(): ThreadInfo[] {
    return SEL.getFeedThreadInfos(this.inboxState());
  }

  paging(): Paging {
    return SEL.getFeedPaging(this.inboxState());
  }

  carePros(): MinimalUser[] | undefined {
    return UserSEL.carePros(this.props.currentUser).value;
  }

  crewMembers(): MinimalUser[] | undefined {
    return UserSEL.crewUsers(this.props.currentUser).value;
  }

  isLoading(): boolean {
    return SEL.getFeedLoading(this.inboxState());
  }

  loadPage = (page: number) => {
    this.props.dispatch(ActionCreators.loadThreadList(page));
  }

  onStartNewMessage = (type: ThreadType, recipientId: UserId) => {
    this.props.dispatch(
      NavAC.pushWithBreadcrumb(
        Urls.inboxNewThreadUrl(type, recipientId)
      )
    );
  }

  render() {
    const carePros = this.carePros();
    const crewMembers = this.crewMembers();
    let content;

    if (!this.isLoading() &&
        carePros !== undefined &&
        crewMembers !== undefined
    ) {
      content = this.renderContent(carePros, crewMembers);
    } else {
      content = <Spinner wrap="center"/>;
    }

    return (
      <NavUI.NavBarLayout title="Messages">
        {content}
      </NavUI.NavBarLayout>
    );
  }

  renderContent(carePros: MinimalUser[], crewMembers: MinimalUser[]) {
    const paging = this.paging();
    return (
      <div className="inbox-container">
        <div className="small-12 medium-6 medium-offset-3 content columns">
          <NewMessageSelect
            carePros={carePros}
            crewMembers={crewMembers}
            onSelect={this.onStartNewMessage}
          />

          <ThreadList threadInfos={this.threadInfos()}/>

          <Pager
            nextPage={paging.nextPage}
            previousPage={paging.previousPage}
            gotoPage={this.loadPage}
          />
          <HUD state={this.props.hud} />
        </div>
      </div>
    );
  }
}

export default featureGate(
  defaultConnect(MyInbox), ['inbox']
);
