import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { State, StoreDispatch } from 'Store';
import ActivityFeed from './ActivityFeed';
import { ActionCreators } from 'Post/ActionCreator';
import { dashboardUrl } from 'Shared/Urls';
import { NavBarLayout } from 'Nav';

type Props = State & {
  dispatch: StoreDispatch
}

function renderMyActivityFeed(props: Props): React.ReactElement<Props> {
  return (
    <NavBarLayout title="Feed" initBreadcrumb={dashboardUrl()}>
      <ActivityFeed
        feedType="me"
        loadPage={loadPageFn(props)}
        loadDynamicPosts={loadDynamicPostsFn(props)}
        {...props}
      />
    </NavBarLayout>
  );
}

export default defaultConnect(renderMyActivityFeed);

function loadPageFn(props: Props): (page: number) => void {
  return page => props.dispatch(ActionCreators.loadMyFeed(page));
}

function loadDynamicPostsFn(props: Props): () => void {
  return () => props.dispatch(ActionCreators.loadMyDynamicPosts());
}
