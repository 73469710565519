import * as React from 'react';

interface Props {
  content: React.ReactNode,
  twoLine?: boolean
}

export default function renderHorizontalFieldLabel(
  props: Props
): React.ReactElement<Props> {
  const labelClasses = [
    'horizontal-form-field__label',
    props.twoLine ? 'horizontal-form-field__label--two-line' : ''
  ].join(' ');

  return (
    <label className={labelClasses}>
      {props.content}
    </label>
  );
}
