import * as React from 'react';

import { SpecUpdate } from '../Data';

import { Status } from './SpecFormLayout';
import SpecFormLayout from './SpecFormLayout';

interface Props {
  heightFeet: number | undefined,
  heightInches: number | undefined,
  submit: (update: SpecUpdate) => void
}

interface State {
  feetInputValue: string,
  inchesInputValue: string,
  status: Status
}

class HeightForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      feetInputValue: (props.heightFeet || '').toString(),
      inchesInputValue: (props.heightInches || '').toString(),
      status: 'default'
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.heightFeet !== this.props.heightFeet ||
        prevProps.heightInches !== this.props.heightInches) {
      const feetInputValue = (this.props.heightFeet || '').toString();
      const inchesInputValue = (this.props.heightInches || '').toString();
      this.setState({ feetInputValue, inchesInputValue });
    }

    if (this.state.status === 'loading') {
      this.setState({ ...this.state, status: 'success' })

      setTimeout(
        () => this.setState({ ...this.state, status: 'default' }),
        3000
      );
    }
  }

  onFeetChanged = (e: React.FormEvent<HTMLInputElement>) => {
    const el = e.target as HTMLInputElement;
    this.setState({ ...this.state, feetInputValue: el.value });
  }

  onInchesChanged = (e: React.FormEvent<HTMLInputElement>) => {
    const el = e.target as HTMLInputElement;
    this.setState({ ...this.state, inchesInputValue: el.value });
  }

  submit = () => {
    const value = parseValue(this.state);
    if (validValue(value)) {
      this.setState({ ...this.state, status: 'loading' });
      this.props.submit({
        heightFeet: value[0],
        heightInches: value[1]
      });
    }
  }

  submitIfChanged = () => {
    const value = parseValue(this.state);
    if (validValue(value) && valueChanged(value, this.props)) {
      this.submit();
    }
  }

  render() {
    return (
      <SpecFormLayout
        label="height"
        labelFor="spec_height_feet"
        status={this.state.status}
        onSubmit={this.submit}
      >
        <input type="text"
          id="spec_height_feet"
          value={this.state.feetInputValue}
          onChange={this.onFeetChanged}
          placeholder="feet"
          className="measurement-form__text-input measurement-form__height-feet-input" />
        <input type="text"
          value={this.state.inchesInputValue}
          onChange={this.onInchesChanged}
          placeholder="inches"
          className="measurement-form__text-input measurement-form__height-inches-input"
          onBlur={this.submitIfChanged} />
      </SpecFormLayout>
    );
  }
}

export default HeightForm;

function parseValue(state: State): [number, number] {
  const { feetInputValue, inchesInputValue } = state;
  return [parseInt(feetInputValue), parseFloat(inchesInputValue)];
}

function validValue([newFeet, newInches]: [number, number]): boolean {
  return !isNaN(newFeet)
      && !isNaN(newInches)
      && newFeet > 0
      && newInches > 0
      && newInches < 12;
}

function valueChanged(
  [newFeet, newInches]: [number, number],
  props: Props
): boolean {
  const { heightFeet: oldFeet, heightInches: oldInches } = props;
  return oldFeet === undefined || oldInches === undefined ||
         oldFeet !== newFeet || oldInches !== newInches;
}
