import * as React from 'react';
import * as Redux from 'redux';
import { RouteComponentProps } from 'react-router-dom';

import { defaultConnect } from 'Shared/ReduxComponent';

import { State as StoreState, extractState } from 'Store';
import { SEL } from 'Challenges/Data';
import { ActionCreators } from 'Challenges/ActionCreator';

import PreJoinSetup, { Props as PreJoinSetupProps } from './PreJoinSetup';

type Props = StoreState & RouteComponentProps<URLParams> & {
  dispatch: Redux.Dispatch<StoreState>
}

type URLParams = {
  challengeId: string
}

function renderPreJoinSetupChallenge(props: Props): React.ReactElement<Props> {
  const challengeId = parseInt(props.match.params.challengeId);
  const challengeSummary =
    SEL.getAvailableById(challengeId, props.challenges).value;
  const childProps: PreJoinSetupProps = {
    ...extractState(props),
    dispatch: props.dispatch,
    item: challengeSummary,
    enrollAction: enrollAction(props)
  };
  return (
    <PreJoinSetup {...childProps}>
    </PreJoinSetup>
  );
}

export default defaultConnect(
  renderPreJoinSetupChallenge as React.StatelessComponent<Props>
);


function enrollAction(props: Props): () => void {
  return () => props.dispatch(
    ActionCreators.joinChallenge(parseInt(props.match.params.challengeId))
  );
}
