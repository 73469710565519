export interface Paging {
  currentPage: number
  nextPage?: number
  previousPage?: number
}

export interface PagingJSON {
  current_page: number
  next_page?: number
  previous_page?: number
}

export function defaultPaging(): Paging {
  return { currentPage: 1 };
}

export function pagingFromJSON(json: PagingJSON): Paging {
  return {
    currentPage: json.current_page,
    nextPage: json.next_page,
    previousPage: json.previous_page
  };
}
