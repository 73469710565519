import { HEALTH_KIT_LOG } from 'Env';
import * as moment from 'moment-timezone';

import {
  Plugin, HKSampleType, HKQuantityType, HKCorrelationType,
  HKSumQuantityTypeQuery,
  HKSleepCategorySample,
  HKQuantitySample, HKCorrelation, HKSample, HKSum,
  SAMPLE_TYPES_FOR_MONITORING,
  isCorrelationType, isQuantityType, isCharacteristicType, isSleepAnalysisType,
  unitsFor,
  requestPermissions
} from './Plugin';


export interface Fetcher {
  fetchOnce(
    onSamples: (data: {
      sampleType: HKSampleType,
      samples: HKSample[]
    }) => void,
    onError: (error: string) => void
  ): Promise<void>,
  beginMonitoring(
    onSamples: (data: {
      sampleType: HKSampleType,
      samples: HKSample[]
    }) => void,
    onError: (error: string) => void
  ): Promise<void>,
  stopMonitoring(): void
}

export function makeFetcher(): Fetcher {
  let enabled = false;

  return {
    fetchOnce,
    beginMonitoring,
    stopMonitoring
  };

  async function fetchOnce(
    onSamples: (data: {
      sampleType: HKSampleType,
      samples: (
        HKQuantitySample[] |
        HKCorrelation[] |
        HKSum[] |
        HKSleepCategorySample[]
      )
    }) => void,
    onError: (error: string) => void
  ): Promise<void> {
    if (!enabled) { return; }

    const hk = await requestPermissions();

    SAMPLE_TYPES_FOR_MONITORING.forEach(sampleType => {
      queryHealthKit(hk, sampleType).then(
        samples => onSamples({ sampleType, samples }),
        onError
      )
    });
  }

  function beginMonitoring(
    onSamples: (data: {
      sampleType: HKSampleType,
      samples: (
        HKQuantitySample[] |
        HKCorrelation[] |
        HKSum[] |
        HKSleepCategorySample[]
      )
    }) => void,
    onError: (error: string) => void
  ): Promise<void> {
    enabled = true;
    return requestPermissions().then(
      hk => {
        SAMPLE_TYPES_FOR_MONITORING.forEach(sampleType => {
          log('Installing monitor for sample type:', sampleType);
          hk.monitorSampleType(
            { sampleType },
            () => {
              if (enabled) {
                queryHealthKit(hk, sampleType).then(
                  samples => onSamples({ sampleType, samples }),
                  onError
                );
              }
            }
          );
        });
      },
      onError
    );
  }

  function stopMonitoring() {
    enabled = false;
  }
}


function queryHealthKit(
  hk: Plugin, sampleType: HKSampleType
): Promise<
  HKQuantitySample[] |
  HKCorrelation[] |
  HKSum[] |
  HKSleepCategorySample[]
> {
  return new Promise((resolve, reject) => {
    // Special case sample types need to come first
    if (sampleType === 'HKQuantityTypeIdentifierStepCount') {
      querySteps(hk, resolve, reject);
    } else if (isQuantityType(sampleType)) {
      queryQuantityType(hk, sampleType, resolve, reject);
    } else if (isCorrelationType(sampleType)) {
      queryCorrelationType(hk, sampleType, resolve, reject);
    } else if (isCharacteristicType(sampleType)) {
      log('TODO: query characteristic types');
    } else if (isSleepAnalysisType(sampleType)) {
      querySleepAnalysis(hk, resolve, reject);
    } else {
      reject('Don\'t know how to query for ' + sampleType);
    }
  });
}

function querySteps(
  hk: Plugin,
  resolve: (sums: HKSum[]) => void,
  reject: (err: string) => void
): void {
  const [startDate, endDate] = queryDates();

  // Each day requires a separate query to healthkit, so we map each query into
  // an array of promises, then aggregate them into an array of HKSums
  Promise.all(
    mapEachDay(startDate, endDate, (dayStart, dayEnd) => {
      const params: HKSumQuantityTypeQuery<HKQuantityType> = {
        sampleType: 'HKQuantityTypeIdentifierStepCount',
        startDate: dayStart,
        endDate: dayEnd
      };
      return new Promise((resolveOneQuery, rejectOneQuery) => {
        hk.sumQuantityType(
          params,
          sum => resolveOneQuery({
            sampleType: params.sampleType,
            startDate: iso8601Format(dayStart),
            endDate: iso8601Format(dayEnd),
            sum
          }),
          rejectOneQuery
        );
      });
    })
  ).then(resolve, reject);
}

function queryQuantityType(
  hk: Plugin,
  t: HKQuantityType,
  resolve: (samples: HKQuantitySample[]) => void,
  reject: (err: string) => void
): void {
  const [startDate, endDate] = queryDates();
  const params = {
    sampleType: t,
    unit: unitsFor(t),
    startDate,
    endDate,
    limit: 0,
    ascending: false
  };

  log('Querying for HK Quantity Samples:', params);
  hk.queryQuantityType(params, samples => resolve(samples), reject);
}

function queryCorrelationType(
  hk: Plugin,
  t: HKCorrelationType,
  resolve: (samples: HKCorrelation[]) => void,
  reject: (err: string) => void
): void {
  const [startDate, endDate] = queryDates();
  const params = {
    correlationType: t, unit: unitsFor(t), startDate, endDate
  };

  log('Querying for HK Correlation Samples:', params);
  hk.queryCorrelationType(params, samples => resolve(samples), reject);
}

function querySleepAnalysis(
  hk: Plugin,
  resolve: (samples: HKSleepCategorySample[]) => void,
  reject: (err: string) => void
): void {
  const [startDate, endDate] = queryDates();
  const params = { startDate, endDate };

  log('Querying for HK Sleep Category Samples:', params);
  hk.querySleepAnalysis(params, resolve, reject);
}

function queryDates(): [Date, Date] {
  const startDate = moment().subtract(1, 'month').startOf('day').toDate();
  const endDate = new Date();
  return [startDate, endDate];
}

/**
 * For every day starting on startDate and ending on endDate, inclusive, execute
 * the callback function and return the results of each call in an array ordered
 * from start date to end date
 */
function mapEachDay<R>(
  startDate: Date,
  endDate: Date,
  cb: (dayStart: Date, dayEnd: Date) => R
): R[] {
  const start = moment(startDate).startOf('day');
  const end = moment(endDate).endOf('day');
  let result: R[] = [];
  while (start.isBefore(end)) {
    result.push(cb(start.toDate(), start.clone().endOf('day').toDate()));
    start.add(1, 'day');
  }
  return result;
}

function iso8601Format(date: Date): string {
  return moment(date).format();
}

function log(msg: string, ...more: any[]) {
  if (HEALTH_KIT_LOG) {
    console.log(`[HealthKit] ${msg}`, ...more);
  }
}
