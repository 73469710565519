import * as R from 'ramda';

export function capitalize(words: string): string {
  return words.split(' ').map(capitalizeWord).join(' ');
}

function capitalizeWord(word: string): string {
  const [first, rest] = R.splitAt(0, word);
  return R.toUpper(first) + R.toLower(rest);
}
