import * as React from 'react';
import ActionButton from './ActionButton';
import { BreadcrumbAction } from 'Shared/UI/Link';

interface Props {
  label: string,
  // Pass either a URL, or an onClick action
  action: string | (() => void),

  appendClasses?: string,
  breadcrumb?: BreadcrumbAction
}

export default function SecondaryButton(props: Props) {
  return <ActionButton {...props} themed={true} modifiers={['secondary']} />;
}
