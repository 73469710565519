import { ApiClientMiddleware, FetchParams } from 'Api';
import { Store } from 'redux';
import { State } from 'Store';
import { ActionCreators } from './ActionCreator';
import { SEL as NetworkSEL } from 'Network/Data';

export function makeApiMiddleware(
  store: Store<State>
): ApiClientMiddleware {
  return { request, response };

  function request(input: RequestInfo, init?: RequestInit): FetchParams {
    return [input, init];
  }

  function response(result: Promise<Response>): Promise<Response> {
    return result
      .then(
        response => {
          if (response.status === 502 || response.status === 503) {
            gotoMaintenanceMode();
          }
          return response;
        },
        response => {
          // This is a little bit heavy-handed to goto maintenance mode upon ANY
          // http error at all, but there is no way to really determine the
          // difference between various fetch errors. When we scale down the API
          // for maintenance, it will most likely trigger a CORS error and end
          // up here
          // Updated: check to make sure we are online, and ignore it if we are
          // just actually offline
          if (NetworkSEL.isOnline(store.getState().network)) {
            gotoMaintenanceMode();
          }
          return Promise.reject(response);
        }
      );
  }

  function gotoMaintenanceMode() {
    store.dispatch(ActionCreators.gotoMaintenanceMode());
  }
}
