import * as React from 'react';
import { ChallengeDetails, SpecMetric, isCurrent } from 'Challenges/Data';
import { UI as SpecsUI } from 'Specs';

interface Props {
  challenge: ChallengeDetails,
  metric: SpecMetric,
  afterPost: () => void,
  leaderboard: React.ReactNode
}

export default function renderSpecDetails(
  props: Props
): React.ReactElement<Props> {
  const challenge = props.challenge;
  return (
    <div>
      {isCurrent(challenge) ? renderSpecForms(props) : null}

      {props.leaderboard}
    </div>
  );
}

function renderSpecForms(props: Props): React.ReactNode {
  return (
    <SpecsUI.ConnectedSpecForms
      show={[props.metric.specType]}
      afterSpecUpdate={props.afterPost}
    />
  );
}
