import * as moment from 'moment-timezone';

import { ApptType, Appt, Slot, AvailableSlots } from 'Appt/Data';
import {
  ApptTypeJSON, ApptJSON, SlotJSON, AvailabilityResponseJSON
} from 'Api/JSON/Appt';
import { dateFromJSON, isoTimeToJSON } from 'Shared/JSON';

export function apptTypeFromJSON(json: ApptTypeJSON): ApptType {
  return {
    id: json.id,
    name: json.name,
    details: json.details,
    reservableStart:
      json.reservable_start ? moment(json.reservable_start) : undefined,
    reservableEnd:
      json.reservable_end ? moment(json.reservable_end) : undefined
  };
}

export function apptFromJSON(json: ApptJSON): Appt {
  return {
    id: json.id,
    type: apptTypeFromJSON(json.appt_type),
    userId: json.user_id,
    slot: slotFromJSON(json.slot)
  };
}

export function slotFromJSON(json: SlotJSON): Slot {
  return {
    startTime: moment(json.start_time),
    duration: json.duration
  };
}

export function slotToJSON(slot: Slot): SlotJSON {
  return {
    start_time: isoTimeToJSON(slot.startTime),
    duration: slot.duration
  };
}

export function availableSlotsFromJSON(
  json: AvailabilityResponseJSON
): AvailableSlots {
  return {
    slots: json.slots.map(slotFromJSON),
    dates: {
      start: dateFromJSON(json.dates.from_date),
      end: dateFromJSON(json.dates.to_date)
    }
  };
}
