import * as React from 'react';
import { bem } from 'css-util';
import Link from 'Shared/UI/Link';

interface Props {
  onNext: () => void
  onPrevious: () => void
  nextLabel?: string
  showPrevious: boolean
}

const DEFAULT_NEXT_LABEL = 'next';
const CSS = bem('screening-form-pager');
const LINK_CSS = CSS('link');

const Pager = (props: Props) => {
  const contents =
    props.showPrevious ? renderBoth(props) : renderNextOnly(props);
  return (
    <div className={CSS()()}>
      {contents}
    </div>
  );
}

function renderBoth(props: Props) {
  return (
    <div className={CSS()(['both-buttons'])}>
      <div className={CSS('nav-button')(['previous'])}>
        <Link to={props.onPrevious}
          className={LINK_CSS(['deemphasized'])}>
          prev
        </Link>
      </div>
      <div className={CSS('nav-button')(['next'])}>
        <Link to={props.onNext} className={`theme__primary-bg ${LINK_CSS()}`}>
          {props.nextLabel || DEFAULT_NEXT_LABEL}
        </Link>
      </div>
    </div>
  );
}
function renderNextOnly(props: Props) {
  return (
    <div className={CSS()(['only-next'])}>
      <div className={CSS('nav-button')(['one-button'])}>
        <Link to={props.onNext}
          className={`theme__primary-bg ${LINK_CSS()}`}>
          {props.nextLabel || DEFAULT_NEXT_LABEL}
        </Link>
      </div>
    </div>
  );
}

export default Pager;
