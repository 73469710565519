import * as R from 'ramda';
import { LENSES, State } from './Data';
import { Action, ActionType } from './Action';

export function reducer(state: State, action: Action): State {
  switch(action.type) {
  case ActionType.LOAD_NOTIFICATION_SETTINGS__SUCCESS:
    return R.set(LENSES.notificationSettings, action.settings, state);

  case ActionType.TOGGLE_FATLINE_ALERT__BEGIN:
    const lens = LENSES.fatlineAlert(action.alert, action.transmitter);
    return R.over(lens, R.not, state);

  case ActionType.UPDATE_DAILY_COACH__BEGIN:
    return R.set(LENSES.dailyCoachSettings, action.dailyCoach, state);

  default:
    return state;
  }
}
