import * as R from 'ramda';


export type WithNS<NSType, A> = A & { NS: NSType };


export function withNS<NSType, Action>(
  ns: NSType
): (a: Action) => WithNS<NSType, Action>;
export function withNS<NSType, Action>(
  ns: NSType, a: Action
): WithNS<NSType, Action>;
export function withNS<NSType, Action>(
  ns: NSType, a?: Action
): ((a: Action) => WithNS<NSType, Action>) | WithNS<NSType, Action> {
  // Note: Need to use this ramda stuff instead of just { ...action, NS }
  // because of bugs in Typescript spread operator, see for example
  // https://github.com/Microsoft/TypeScript/pull/13288
  if (a === undefined) {
    return (a: Action) => R.merge(a, { NS: ns });
  } else {
    return R.merge(a, { NS: ns });
  }
}
