export * from './Data';
export { NSAction, Action, ActionType } from './Action';
export { ActionCreators } from './ActionCreator';
export { reducer } from './Store';
export { makeApiMiddleware } from './ApiMiddleware';

import PrivateRoute from './PrivateRoute';
export { PrivateRoute };

import Login from './Page/Login';
import Signup from './Page/Signup';
import Logout from './Page/Logout';
import ForgotPassword from './Page/ForgotPassword';
import ResetPassword from './Page/ResetPassword';

export const Pages = {
  Login,
  Signup,
  Logout,
  ForgotPassword,
  ResetPassword
}

