import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { defaultConnect } from 'Shared/ReduxComponent';

import { State, StoreDispatch, extractState } from 'Store';
import { ChallengeInvitation, SEL } from 'Challenges/Data';
import { ActionCreators } from 'Challenges/ActionCreator';

import PreJoinSetup, { Props as PreJoinSetupProps } from './PreJoinSetup';

type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch
}

interface URLParams {
  invitationId: string
}

function renderPreJoinSetupInvitation(props: Props): React.ReactElement<Props> {
  const invitationId = props.match.params.invitationId;
  const invite = SEL.getInvitationById(invitationId, props.challenges).value;
  const childProps: PreJoinSetupProps = {
    ...extractState(props),
    dispatch: props.dispatch,
    item: invite,
    enrollAction: enrollAction(props, invite)
  };
  return (
    <PreJoinSetup {...childProps}>
    </PreJoinSetup>
  );
}

export default defaultConnect(
  renderPreJoinSetupInvitation as React.StatelessComponent<Props>
);


function enrollAction(
  props: Props, invite: ChallengeInvitation | undefined
): () => void {
  if (invite) {
    return () => props.dispatch(
      ActionCreators.acceptInvitation(invite.id, invite.challengeId)
    );
  } else { return () => {}; }
}
