import * as R from 'ramda';
import * as React from 'react';
import { Goal, GoalStatus } from 'Goals';
import NoContent from 'misc/UI/NoContent';
import ListItem from './GoalListItem';

interface Props {
  goals: Goal[]
  statuses: GoalStatus[],
  activateGoal: (goalId: string) => void,
  deactivateGoal: (goalId: string) => void,
  resetGoal: (goalId: string) => void
}

export default function renderGoalList(
  props: Props
): React.ReactElement<Props> {
  if (props.goals.length === 0) {
    return <NoContent message="no goals to display" />;
  } else {
    return (
      <ul className="settings-checklist">
        {props.goals.map(g => renderListItem(props, g))}
      </ul>
    );
  }
}


function renderListItem(props: Props, goal: Goal): React.ReactNode {
  const status = statusForGoal(props.statuses, goal);
  return (
    <ListItem
      key={goal.id}
      goal={goal}
      status={status}
      activateGoal={props.activateGoal}
      deactivateGoal={props.deactivateGoal}
      resetGoal={props.resetGoal}/>
  );
}

function statusForGoal(
  statuses: GoalStatus[],
  goal: Goal
): GoalStatus | undefined {
  return R.find(s => s.goalId === goal.id, statuses);
}
