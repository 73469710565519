import * as R from 'ramda';

import {
  CurrentUser, Profile, Program, MinimalUser, ScreeningStatus,
  CurrentUserPayload, LENSES
} from './Data';
import { loadable } from 'misc/Data/Loadable';

import { Action, ActionType } from './Action';

type ReducerState = CurrentUser

export function reducer(
  state: ReducerState,
  action: Action
): ReducerState {
  switch(action.type) {
  case ActionType.LOAD_CURRENT_USER__START:
    return R.compose(
      clearProfile,
      setLoader(action.loader)
    )(state);

  case ActionType.LOAD_CURRENT_USER__SUCCESS:
    return R.compose(
      R.compose(
        setProfile(action.profile),
        setProgram(action.program),
        setCarePros(action.carePros),
        setScreeningStatus(action.screeningStatus),
        setNativeAppSlug(action.nativeAppSlug)
      ),
      clearLoader
    )(state);

  case ActionType.LOAD_SPECS__SUCCESS:
    return R.set(LENSES.specs, action.specs, state);

  case ActionType.LOAD_CREW__SUCCESS:
    return { ...state, crew: action.crew };

  case ActionType.SAVE_PROFILE__START:
    return setProfile(action.profile, state);

  case ActionType.SAVE_PROFILE__SUCCESS:
    return setProfile(action.profile, state);

  default:
    return state;
  }
}

const setProfile = R.curry(_setProfile)
function _setProfile(profile: Profile, currentUser: CurrentUser): CurrentUser {
  return R.set(LENSES.profile, profile, currentUser);
}

function clearProfile(currentUser: CurrentUser): ReducerState {
  return R.set(LENSES.profile, undefined, currentUser);
}

const setProgram = R.curry(_setProgram);
function _setProgram(program: Program, currentUser: CurrentUser): CurrentUser {
  return R.set(LENSES.program, program, currentUser);
}

const setCarePros = R.curry(_setCarePros);
function _setCarePros(
  carePros: MinimalUser[], currentUser: CurrentUser
): CurrentUser {
  return { ...currentUser, carePros };
}

const setScreeningStatus = R.curry(_setScreeningStatus);
function _setScreeningStatus(
  screeningStatus: ScreeningStatus, currentUser: CurrentUser
): CurrentUser {
  return { ...currentUser, screeningStatus: loadable(screeningStatus) };
}

function setLoader(loader: Promise<CurrentUserPayload>): (u: CurrentUser) => CurrentUser {
  return u => ({ ...u, loader });
}

function clearLoader(u: CurrentUser): CurrentUser {
  return { ...u, loader: undefined };
}

const setNativeAppSlug = R.curry(_setNativeAppSlug);
function _setNativeAppSlug(slug: string, u: CurrentUser): CurrentUser {
  return { ...u, nativeAppSlug: loadable(slug) };
}
