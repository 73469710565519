import { UUID } from 'Shared/Data/UUID';
import { Device } from './Data';
import { makeLogger } from 'Shared/Log';
import { noop } from 'Shared/Noop';
import * as Timeout from 'Shared/Timeout';
import { isAndroid } from 'Cordova';

type PacketHandler = (packet: Uint8Array) => void

export interface DeviceAccess {
  device: Device,
  connect: (
    onDisconnect?: () => void,
    timeout?: number
  ) => Promise<BluetoothlePlugin.DeviceInfo>,
  reconnect: (
    onDisconnect?: () => void,
    timeout?: number
  ) => Promise<BluetoothlePlugin.DeviceInfo>,
  disconnect: () => Promise<void>,
  // Uses combination of connect, reconnect, is/was connected to just make sure
  // we are connected, not caring how it happens
  withConnection: (
    onDisconnect?: () => void,
    timeout?: number
  ) => Promise<void>,
  close: () => Promise<void>,
  isConnected: () => Promise<boolean>,
  wasConnected: () => Promise<boolean>,
  isDiscovered: () => Promise<boolean>,
  withDiscovery: () => Promise<void>,
  discover: () => Promise<BluetoothlePlugin.Device>,
  read: (service: UUID, characteristic: UUID) => Promise<Uint8Array>,
  write: (
    service: UUID, characteristic: UUID, value: Uint8Array
  ) => Promise<void>,
  writeWithoutResponse: (
    service: UUID, characteristic: UUID, value: Uint8Array
  ) => Promise<void>,
  subscribe: (
    service: UUID, characteristic: UUID, handler: PacketHandler
  ) => Promise<void>,
  unsubscribe: (service: UUID, characteristic: UUID) => Promise<void>,
  requestMTU: (mtu: number) => Promise<number>,
  requestConnectionPriority: (
    priority: 'low' | 'balanced' | 'high'
  ) => Promise<void>,
  silenceLogsIn<R>(fn?: () => Promise<R>): Promise<R>,
  silenceLogs(): Promise<void>
}

export function makeDeviceAccess(
  ble: BluetoothlePlugin.Bluetoothle, device: Device
): DeviceAccess {
  const address = device.address;
  const log = makeLogger(
    `BLE.DeviceAccess: ${device.name} @ ${address}`,
    'BLUETOOTH_LOG'
  );

  return {
    device,
    connect,
    reconnect,
    disconnect,
    close,
    isConnected,
    wasConnected,
    withConnection,
    isDiscovered,
    withDiscovery,
    discover,
    read,
    write,
    writeWithoutResponse,
    subscribe,
    unsubscribe,
    requestMTU,
    requestConnectionPriority,
    silenceLogsIn,
    silenceLogs
  };

  function connect(
    onDisconnect?: () => void,
    timeout=10000
  ): Promise<BluetoothlePlugin.DeviceInfo> {
    return Timeout.timeout(
      timeout,
      (resolve, reject) => {
        log('Attempting to connect');
        ble.connect(
          result => {
            if (result.status === 'connected') {
              log('Connect success');
              resolve(result);
            } else if (result.status === 'disconnected') {
              log('Unexpected disconnect.')
              onDisconnect && onDisconnect();
            }
          },
          handleError('connect', reject),
          { address }
        )
      }
    );
  }

  function reconnect(
    onDisconnect?: () => void,
    timeout=10000
  ): Promise<BluetoothlePlugin.DeviceInfo> {
    return Timeout.timeout(
      timeout,
      (resolve, reject) => {
      log('Attempting to reconnect');
        ble.reconnect(
          result => {
            if (result.status === 'connected') {
              log('Reconnect success');
              resolve(result);
            } else if (result.status === 'disconnected') {
              log('Unexpected disconnect.');
              onDisconnect && onDisconnect();
            }
          },
          handleError('reconnect', reject),
          { address }
        )
      }
    );
  }

  async function withConnection(
    onDisconnect?: () => void, timeout=10000
  ): Promise<any> {
    log('Attempting to ensure connection via `withConnection`');
    if (await isConnected()) {
      log('Already connected.');
      return;
    }
    if (await wasConnected()) {
      log('Was connected, reconnecting...');
      return reconnect(onDisconnect, timeout);
    } else {
      log('Was not connected, connecting...');
      return connect(onDisconnect, timeout);
    }
  }

  function close(timeout=10000): Promise<void> {
    return Timeout.timeout_(
      timeout,
      (resolve, reject) => {
        log('Attempting to close');
        ble.close(
          result => {
            if (result.status === 'closed') {
              log('Close success');
              resolve();
            }
          },
          handleError('close', reject),
          { address }
        )
      }
    );
  }

  function isConnected(): Promise<boolean> {
    return new Promise(resolve => {
      log('Checking isConnected');
      ble.isConnected(
        result => {
          if (result.hasOwnProperty('isConnected')) {
            log(`isConnected check success: ${result.isConnected}`);
            resolve(result.isConnected);
          }
        },
        () => {
          log(`isConnected check failed, implying not connected`);
          resolve(false);
        },
        { address }
      )
    });
  }

  function wasConnected(): Promise<boolean> {
    return new Promise(resolve => {
      log('Checking wasConnected');
      ble.wasConnected(
        result => {
          log(`wasConnected check success: ${result.wasConnected}`, result);
          resolve(result.wasConnected);
        },
        () => {
          log('wasConnected check failed, implying was not connected');
          resolve(false);
        },
        { address }
      )
    });
  }

  function isDiscovered(): Promise<boolean> {
    return new Promise((resolve) => {
      log('Checking isDiscovered');
      ble.isDiscovered(
        result => {
          log(`isDiscovered check success: ${result.isDiscovered}`, result);
          resolve(result.isDiscovered);
        },
        () => {
          log(`isDiscovered check failed, implying not discovered`);
          resolve(false)
        },
        { address }
      );
    });
  }

  async function withDiscovery(): Promise<void> {
    if (await isDiscovered()) {
      log('Already discovered, done.')
    } else {
      return discover().then(noop);
    }
  }

  function disconnect(): Promise<void> {
    return new Promise((resolve, reject) => {
      log('Attempting to disconnect');
      ble.disconnect(
        result => {
          if (result.status === 'disconnected') {
            log('Disconnect success')
            resolve();
          }
        },
        handleError('disconnect', reject),
        { address }
      )
    });
  }

  function discover(): Promise<BluetoothlePlugin.Device> {
    return new Promise((resolve, reject) => {
      log('Attempting to discover');
      ble.discover(
        result => {
          if (result.status === 'discovered') {
            log('Discover success:', result);
            resolve(result);
          }
        },
        handleError('discover', reject),
        { address }
      );
    });
  }

  function read(service: UUID, characteristic: UUID): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      log(
        `Reading value - service ${service}, characteristic ${characteristic}`
      );
      ble.read(
        result => {
          if (result.status === 'read') {
            log(`Value read: ${result.value}`);
            resolve(ble.encodedStringToBytes(result.value));
          }
        },
        handleError('read', reject),
        { address, service, characteristic }
      )
    });
  }

  function write(
    service: UUID, characteristic: UUID, value: Uint8Array,
    writeType: 'noResponse' | undefined = undefined
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const encodedValue = ble.bytesToEncodedString(value);
      log(
        `Writing value - service ${service}, characteristic `+
          `${characteristic}, value: ${encodedValue}`,
        value
      );
      ble.write(
        result => {
          if (result.status === 'written') {
            resolve();
          }
        },
        handleError('write', reject),
        {
          address,
          service,
          characteristic,
          value: encodedValue,
          type: writeType
        }
      )
    });
  }

  function writeWithoutResponse(
    service: UUID, characteristic: UUID, value: Uint8Array
  ): Promise<void> {
    return write(service, characteristic, value, 'noResponse')
  }

  function subscribe(
    service: UUID, characteristic: UUID,
    handler: (value: Uint8Array) => void
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      log(`Subscribing - service ${service}, characteristic ${characteristic}`);
      ble.subscribe(
        result => {
          if (result.status === 'subscribedResult') {
            const decoded = ble.encodedStringToBytes(result.value);
            log(`Subscribed value received: ${result.value}`, result, decoded);
            handler(decoded);
          } else if (result.status === 'subscribed') {
            log('Subscribe successful');
            resolve();
          }
        },
        handleError('subscribe', reject),
        { address, service, characteristic }
      );
    });
  }

  function unsubscribe(service: UUID, characteristic: UUID): Promise<void> {
    return new Promise((resolve, reject) => {
      log(
        `Unsubscribing - service ${service}, characteristic ${characteristic}`
      );
      ble.unsubscribe(
        result => {
          if (result.status === 'unsubscribed') {
            log(`Unsubscribe successful`);
            resolve();
          }
        },
        handleError('unsubscribe', reject),
        { address, service, characteristic }
      )
    });
  }

  function requestMTU(mtu: number): Promise<number> {
    return new Promise((resolve, reject) => {
      if (isAndroid()) {
        log(`Requesting MTU: ${mtu}`);
        ble.mtu(
          result => {
            if (result.status === 'mtu') {
              log(`MTU set to ${result.mtu}`);
              resolve(result.mtu);
            }
          },
          handleError('mtu', reject),
          { address, mtu }
        )
      } else {
        log.error('requestMTU not supported on this platform.')
        reject();
      }
    });
  }

  function requestConnectionPriority(
    priority: BluetoothlePlugin.ConnectionPriority
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (isAndroid()) {
        log(`Requesting connection priority: ${priority}`);
        ble.requestConnectionPriority(
          result => {
            if (result.status === 'connectionPriorityRequested') {
              log('Connection priority set.');
              resolve();
            }
          },
          handleError('requestConnectionPriority', reject),
          { address, connectionPriority: priority }
        )
      } else {
        log.error('requestConnectionPriority not supported on this platform.');
        reject();
      }
    });
  }

  async function silenceLogsIn<R>(fn: () => Promise<R>): Promise<R> {
    try {
      log.silence();
      return await fn();
    } finally {
      log.unsilence();
    }
  }

  async function silenceLogs(): Promise<void> {
    log.silence();
  }

  function handleError(
    label: string, reject: (reason?: any) => void
  ): (e: BluetoothlePlugin.Error) => void {
    return e => {
      log.debug(`Error during ${label}: (${e.code}) ${e.message}`);
      reject(e.message);
    };
  }
}
