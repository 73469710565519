import './ErrorPage.scss';
import * as React from 'react';
import Icon, { IconName } from 'Shared/UI/Icon';
import Link from 'Shared/UI/Link';

interface Props {
  title: string,
  detail: string,
  icon: IconName,
  tryAgainLabel?: string,
  tryAgainTarget?: (() => void) | string
}

const ErrorPage = (props: Props) => {
  return (
    <section className="offline-notice">
      <Icon name={props.icon} />

      <h1>{props.title}</h1>

      <p>{props.detail}</p>

      {props.tryAgainTarget &&
       <Link className="button" to={props.tryAgainTarget}>
         {props.tryAgainLabel || 'Try Again'}
       </Link>
      }
    </section>
  );
};

export default ErrorPage;
