import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { ActionCreators } from 'Challenges/ActionCreator';

import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { availableChallengesUrl, completedChallengesUrl } from 'Shared/Urls';
import Spinner from 'Shared/UI/Spinner';
import { isLoaded } from 'misc/Data/Loadable';
import SecondaryButton from 'Challenges/UI/SecondaryButton';
import TileList from 'Challenges/UI/TileList';
import SectionTitle from 'Challenges/UI/SectionTitle';

import { NavBarLayout } from 'Nav';

import { State, StoreDispatch } from 'Store';

type Props = State & RouteComponentProps<{}> & {
  dispatch: StoreDispatch
}

class MyChallenges extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(ActionCreators.loadChallenges());
  }

  render() {
    const currentAndUpcoming = this.props.challenges.enrolled;

    return (
      <NavBarLayout title="Challenges" className="my-challenges">
        <SectionTitle title='Active' />
        {isLoaded(currentAndUpcoming) ?
          <TileList
            challenges={currentAndUpcoming.value}
            noDataMessage={'no active challenges to display'}
          />
          :
          <Spinner wrap="center" />
        }
        <SecondaryButton
          label="start or join challenges"
          action={ availableChallengesUrl() }
          breadcrumb="push"
        />
        <SecondaryButton
          label="view completed challenges"
          action={ completedChallengesUrl() }
          breadcrumb="push"
        />

      </NavBarLayout>
    );
  }
}

export default withRouter(defaultConnect(MyChallenges));
