import * as moment from 'moment-timezone';
import * as React from 'react';
import {
  DailyStatus, ActionId, totalTasks, completedTasks
} from 'CarePlans/Data';

import { TaskId } from 'Tasks/Data';
import ActionStatusListItem from './ActionStatusListItem';

interface Props {
  status: DailyStatus,
  completeTask: (taskId: TaskId, time: moment.Moment) => Promise<void>
  undoTaskAction: (actionId: ActionId) => Promise<void>
  reload: () => void
}

const ActionStatusList = (props: Props) => {
  return (
    <div>
      {renderTitle(props)}
      <div>
        {props.status.statuses.map((s, i) =>
          <ActionStatusListItem
            status={s}
            key={i}
            date={props.status.date}
            completeTask={props.completeTask}
            undoTaskAction={props.undoTaskAction}
            reload={props.reload}
          />
        )}
      </div>
    </div>
  );
};

export default ActionStatusList;

function renderTitle(props: Props): React.ReactNode {
  return (
    <h2 className="text-center">
      Completed: {completedTasks(props.status)} of {totalTasks(props.status)}
    </h2>
  );
}
