import { ActionCreatorThunk } from 'Store';
import { UserId, ActionCreators as UserAC } from 'User';
import { withNS, ActionType } from './Action';
import { userDashboardFromJSON, myDashboardFromJSON } from './JSON';

export interface ActionCreators {
  loadMyDashboard(): ActionCreatorThunk
  loadUserDashboard(userId: UserId): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  loadMyDashboard,
  loadUserDashboard
};

function loadMyDashboard(): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(withNS({
      type: ActionType.LOAD_MY_DASHBOARD__BEGIN
    }));
    return Promise.all([
      dispatch(UserAC.loadCurrentUser()),
      api.dashboard.getMyDashboard().then(result => {
        dispatch(withNS({
          type: ActionType.LOAD_MY_DASHBOARD__SUCCESS,
          dashboard: myDashboardFromJSON(result.dashboard)
        }))
      })
    ]);
  };
}

function loadUserDashboard(userId: UserId): ActionCreatorThunk {
  return (dispatch, _, { api }) =>
    api.dashboard.getUserDashboard(userId).then(result => {
      dispatch(withNS({
        type: ActionType.LOAD_USER_DASHBOARD__SUCCESS,
        userId,
        dashboard: userDashboardFromJSON(result.dashboard)
      }));
    });
}
