import * as Urls from 'Shared/Urls';
import * as R from 'ramda';

export type NSType = 'HOME';
export const NS: NSType = 'HOME';

export interface State {
  quicklinks: Quicklink[]
}

export interface Quicklink {
  url: string
  title: string
}

export const DEFAULT_QUICKLINKS: Quicklink[] = [
  {
    title: 'Join health challenges',
    url: Urls.challengesUrl()
  },
  {
    title: 'Go to my dashboard',
    url: Urls.dashboardUrl()
  },
  {
    title: 'Connect health tracking device',
    url: Urls.edgeSettingsUrl()
  },
  {
    title: 'Activate a health goal',
    url: Urls.goalsUrl()
  }
];

export function initialState(): State {
  return {
    quicklinks: DEFAULT_QUICKLINKS
  };
}


export function setQuicklinksWithDefaults(
  quicklinks: Quicklink[], state: State
): State {
  const newQuicklinks = R.concat(
    quicklinks,
    R.drop(quicklinks.length, DEFAULT_QUICKLINKS)
  );
  return { ...state, quicklinks: newQuicklinks };
}
