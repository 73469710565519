import { State } from './Data';
import { Action, ActionType } from './Action';

export function reducer(state: State, action: Action): State {
  switch(action.type) {
  case ActionType.ENABLE_MAINTENANCE_MODE:
    return { ...state, enabled: true };
  case ActionType.DISABLE_MAINTENANCE_MODE:
    return { ...state, enabled: false };
  case ActionType.BEGIN_POLLING:
    return { ...state, pollInterval: action.interval };
  case ActionType.STOP_POLLING:
    return { ...state, pollInterval: undefined };
  }
}
