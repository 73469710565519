/**
 * Stolen from
 * https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
 * Not sure if this is necessary, but couldn't find solid info about IE/browser
 * support on the new Clipboard api
 * Update: to make this work on iOS had to add more weird hackery, this time
 * stolen from
 * https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
 */
export function copy(str: string): Promise<void> {
  return new Promise(resolve => {
    const el = document.createElement('textarea');
    el.value = str;
    el.readOnly = false;
    el.contentEditable = 'true';
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();

    // Commence iOS specific hackery
    const range = document.createRange();
    range.selectNodeContents(el);
    const s = window.getSelection();
    if (s) {
      s.removeAllRanges();
      s.addRange(range);
    }
    el.setSelectionRange(0, 999999);

    // Finally perform the command
    document.execCommand('copy');
    document.body.removeChild(el);
    resolve();
  });
};
