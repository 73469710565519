import * as R from 'ramda';

export type UUID = string;

export function equal(uuid1: UUID, uuid2: UUID): boolean {
  return withoutDashes(uuid1) === withoutDashes(uuid2);
}

function withoutDashes(uuid: UUID): string {
  return R.replace(/-/g, '', uuid);
}

export function withDashes(uuid: UUID): string {
  if (uuid.length !== 36) {
    const noDash = withoutDashes(uuid);
    return [
      noDash.substring(0, 8),
      noDash.substring(8, 12),
      noDash.substring(12, 16),
      noDash.substring(16, 20),
      noDash.substring(20, 32)
    ].join('-');
  } else {
    return uuid;
  }
}
