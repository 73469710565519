import { State } from './Data';
import { Action, ActionType } from './Action';
import { loadable } from 'misc/Data/Loadable';

export function reducer(state: State, action: Action): State {
  switch(action.type) {
  case ActionType.LOAD_REWARD_PLAN__SUCCESS:
    return { ...state, rewardPlan: loadable(action.rewardPlan) };
  case ActionType.LOAD_USER_ACTIONS__SUCCESS:
    return { ...state, userActions: loadable(action.userActions) };
  }
}
