import * as React from 'react';

import { Biopost, Activity, UI as PostUI } from 'Post';
import {
  ChallengeDetails, isHabitActionDetails, isCurrent
} from 'Challenges/Data';

interface Props {
  challenge: ChallengeDetails,
  afterPost: (b: Biopost, a: Activity) => void,
  leaderboard: React.ReactNode
}

type Status = 'have-posted' | 'have-not-posted';
type UIState = { status: Status };

class HabitDetails extends React.Component<Props, UIState> {
  constructor(props: Props) {
    super(props);
    this.state = { status: 'have-not-posted' };
  }

  afterPost(biopost: Biopost, activity: Activity) {
    this.setState({ status: 'have-posted' });
    this.props.afterPost(biopost, activity);
  }

  render() {
    const challenge = this.props.challenge;
    const ad = challenge.actionDetails;
    const activities = isHabitActionDetails(ad) ? ad.habit.activities : [];

    const activityList =
      <div className="challenge-details__habit-activity-list" key="activities">
        <PostUI.ActivityList
          activities={activities}
          afterPost={(b, a) => this.afterPost(b, a)}/>
      </div>;

    return (
      <div className="challenge-details__habit">
        {renderPostingSection(this.state.status, activityList, challenge)}

        {this.props.leaderboard}
      </div>
    );
  }
}

export default HabitDetails;

function renderActionMessage(
  status: Status
): React.ReactNode {
  if (status === 'have-posted') {
    return 'nice, got it!';
  } else {
    return 'post to this challenge';
  }
}

function renderPostingSection(
  status: Status, activityList: React.ReactNode, challenge: ChallengeDetails
): React.ReactNode {
  if (isCurrent(challenge.summary)) {
    return [
      <p className="challenge__action-message" key="msg">
        {renderActionMessage(status)}
      </p>,

      activityList
    ];
  }
}
