import { Config } from './Data';
import { OnboardConfigJSON } from 'Api/JSON/Onboard';

export function configFromJSON(json: OnboardConfigJSON): Config {
  return {
    edge: json.edge,
    post: json.post,
    goals: json.goals,
    specs: json.specs,
    careTasks: json.care_tasks,
    inbox: json.inbox
  };
}
