import * as React from 'react';
import { connect } from 'react-redux';
import { challengeUrl } from 'Shared/Urls';

import { State, StoreDispatch } from 'Store';

import {
  LENSES, ChallengeId, ChallengeDetails, TaskChallengeDaySummary
} from 'Challenges/Data';
import * as Data from 'Challenges/Data';
import { ActionCreators } from 'Challenges/ActionCreator';

import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { NavBarLayout } from 'Nav';
import { HUD } from 'HUD';
import NoContent from 'misc/UI/NoContent';
import SummaryTable from 'Challenges/UI/TaskChallengeSummaryTable';

type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch
};

type URLParams = {
  challengeId: string
}


class TaskChallengeSummary extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(ActionCreators.loadChallenge(this.challengeId()));
    this.props.dispatch(
      ActionCreators.loadTaskChallengeDailySummary(this.challengeId())
    );
  }

  challengeId(): ChallengeId {
    return parseInt(this.props.match.params.challengeId);
  }

  challenge(): ChallengeDetails | undefined {
    return LENSES.challenge(this.challengeId()).get(this.props.challenges);
  }

  daySummaries(): TaskChallengeDaySummary[] | undefined {
    return LENSES.currentTaskChallengeDaySummaries.get(this.props.challenges);
  }

  render() {
    const challenge = this.challenge();
    const summaries = this.daySummaries();
    return (
      <NavBarLayout
        title="Challenge"
        initBreadcrumb={challengeUrl(this.challengeId())}>
        <div className="simple-container">
          <h2 className="text-center">My points by category</h2>
          {challenge !== undefined && summaries !== undefined ?
           this.renderContent(challenge, summaries) : this.renderLoading()}
        </div>
      </NavBarLayout>
    );
  }

  renderLoading() {
    return <HUD state="loading" />;
  }

  renderContent(
    challenge: ChallengeDetails, summaries: TaskChallengeDaySummary[]
  ) {
    if (Data.isUpcoming(challenge)) {
      return this.renderNotStarted();
    } else {
      return <SummaryTable summaries={summaries} challenge={challenge} />;
    }
  }

  renderNotStarted() {
    return (
      <NoContent>This challenge has not yet started</NoContent>
    );
  }
}

export default withRouter(connect(
  state => state,
  dispatch => ({ dispatch })
)(TaskChallengeSummary));
