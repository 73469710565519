import './DailyNav.scss';
import * as moment from 'moment-timezone';
import * as classnames from 'classnames';
import * as React from 'react';
import { bem } from 'css-util';
import Link from 'Shared/UI/Link';

interface Props {
  currentDate: moment.Moment
  gotoDate: (date: moment.Moment) => void
  validDate: (date: moment.Moment) => boolean
}

const CSS = bem('daily-nav');

const DailyNav = (props: Props) => {
  const { currentDate } = props;

  const previousDate = currentDate.clone().subtract(1, 'day');
  const nextDate = currentDate.clone().add(1, 'day');

  const leftClasses = classnames(
    'button', 'button-gray', CSS('button')(['left'])
  );
  const rightClasses = classnames(
    'button', 'button-gray', CSS('button')(['right'])
  );


  return (
    <div className={CSS()()}>
      {props.validDate(previousDate) ?
       (
         <Link
           to={() => props.gotoDate(previousDate)}
           className={leftClasses}>
         &lt; Older
         </Link>
       ) : <span className={CSS('button')()}></span>
      }
      <h3 className={CSS('date')()}>
        {props.currentDate.format('MMM D, YYYY')}
      </h3>
      {props.validDate(nextDate) ?
       (
         <Link
           to={() => props.gotoDate(nextDate)}
           className={rightClasses}>
           Newer &gt;
         </Link>
       ) : <span className={CSS('button')()}></span>
      }
    </div>
  );
};

export default DailyNav;
