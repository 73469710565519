import * as React from 'react';
import { bem } from 'css-util';
import LoadSuccessButton, { Status } from 'Shared/UI/LoadSuccessButton';
import { sleep } from 'Shared/Timeout';

interface Props {
  onSubmit: (email: string) => Promise<{}>
}

interface State {
  email: string,
  status: Status
}

const CSS = bem('crew-invite-form');

class EmailInviteForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { email: '', status: 'default' };
  }

  onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value });
  }

  isValid(): boolean {
    return !!this.state.email.match(/.@./);
  }

  onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.isValid()) {
      this.setState({ status: 'loading' });
      this.props.onSubmit(this.state.email).then(() => {
        this.setState({ status: 'success', email: '' });
        sleep(1000).then(() => this.setState({ status: 'default' }));
      });
    }
  }

  render() {
    return (
      <form className={CSS()()} onSubmit={this.onSubmit} noValidate>
        <div className={CSS('field')()}>
          <input type="email"
            readOnly={this.state.status !== 'default'}
            className="text-field text-field--block"
            value={this.state.email}
            onChange={this.onEmailChange}
            required
            placeholder="email address"/>
        </div>
        <div className={CSS('submit')()}>
          <LoadSuccessButton
            className="button--block theme__primary-bg"
            disabled={!this.isValid()}
            status={this.state.status}
          >
            send
          </LoadSuccessButton>
        </div>
      </form>
    );
  }
}

export default EmailInviteForm;
