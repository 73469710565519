import { ApiClient, GET, POST, DELETE, OK } from 'Api';

import {
  ProviderKeyJSON, ConfigurationJSON, DeviceDataJSON, FirmwareCheckResponseJSON
} from './JSON/Edge';

import { UserDevice } from 'Edge/Data';

interface SendDataOptions {
  append?: boolean,
  userDevice?: UserDevice
}

export interface EdgeAPI {
  /**
   * Load user connections and program edge configuration
   */
  getConfiguration(): Promise<ConfigurationJSON>,

  connect(providerKey: ProviderKeyJSON): OK,

  disconnect(providerKey: ProviderKeyJSON): OK,

  addDevice(deviceTypeId: number, deviceId: string): OK,

  removeDevice(deviceTypeId: number): OK,

  sendData(
    providerIdOrKey: number | string,
    data: DeviceDataJSON,
    options?: SendDataOptions
  ): OK,

  checkForFirmwareUpdates(
    deviceTypeId: number
  ): Promise<FirmwareCheckResponseJSON>,

  getLatestFirmware(deviceTypeId: number): Promise<ArrayBuffer>
}

/** Implementation **/
export function makeApi(client: ApiClient): EdgeAPI {
  return {
    getConfiguration,
    connect,
    disconnect,
    addDevice,
    removeDevice,
    sendData,
    checkForFirmwareUpdates,
    getLatestFirmware
  };

  function getConfiguration(): Promise<ConfigurationJSON> {
    return client.requestJSON(GET, { path: '/edge/configuration', version: 3 });
  }

  function connect(k: ProviderKeyJSON): OK {
    return client.request(
      POST,
      { path: '/edge/configuration/add_connection', version: 3 },
      JSON.stringify({ provider: k })
    );
  }

  function disconnect(k: ProviderKeyJSON): OK {
    return client.request(
      DELETE,
      {
        path: `/edge/configuration/remove_connection`,
        version: 3,
        query: { provider: k }
      }
    );
  }

  function addDevice(deviceTypeId: number, deviceId: string): OK {
    return client.request(
      POST,
      {
        path: `/edge/configuration/add_device`,
        version: 3
      },
      JSON.stringify({ device_type_id: deviceTypeId, device_id: deviceId })
    );
  }

  function removeDevice(deviceTypeId: number): OK {
    return client.request(
      DELETE,
      {
        path: `/edge/configuration/remove_device`,
        version: 3,
        query: { device_type_id: deviceTypeId }
      }
    );
  }

  function sendData(
    providerIdOrKey: number | string,
    data: DeviceDataJSON,
    options: SendDataOptions = { append: false }
  ): OK {
    return client.request(
      POST,
      {
        path: '/edge/data',
        version: 3
      },
      JSON.stringify({
        provider_id: providerIdOrKey,
        user_device: options.userDevice,
        data,
        append: !!options.append
      })
    )
  }

  async function checkForFirmwareUpdates(
    deviceTypeId: number
  ): Promise<FirmwareCheckResponseJSON> {
    return client.requestJSON<FirmwareCheckResponseJSON>(
      GET,
      {
        path: `/edge/firmware_versions/${deviceTypeId}/check`,
        version: 3
      }
    ).catch(error => {
      if (typeof error === 'object') {
        let response = error.response;
        if (response instanceof Response && response.status === 404) {
          return { latest_version: undefined };
        }
      }
      return Promise.reject(error);
    });
  }

  function getLatestFirmware(deviceTypeId: number): Promise<ArrayBuffer> {
    return client.requestData(
      GET,
      {
        path: `/edge/firmware_versions/${deviceTypeId}/download`,
        version: 3
      }
    )
  }
}
