import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';
import { NSType, NS, RewardPlan, UserAction } from './Data';
import { Maybe } from 'misc/Data/Maybe';

export enum ActionType {
  LOAD_REWARD_PLAN__SUCCESS = 'LOAD_REWARD_PLAN__SUCCESS',
  LOAD_USER_ACTIONS__SUCCESS = 'LOAD_USER_ACTIONS__SUCCESS'
}

export type NSAction = WithNS<NSType, Action>;
export const withNS = originalWithNS<NSType, Action>(NS);

export type Action
  = LoadRewardPlan_Success
  | LoadUserActions_Success;

export interface LoadRewardPlan_Success {
  type: ActionType.LOAD_REWARD_PLAN__SUCCESS,
  rewardPlan: Maybe<RewardPlan>
}

export interface LoadUserActions_Success {
  type: ActionType.LOAD_USER_ACTIONS__SUCCESS,
  userActions: UserAction[]
}

