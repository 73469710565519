import { ActionCreatorThunk } from 'Store';
import { ActionType, withNS } from './Action';

export interface ActionCreators {
  loadPage(url: string): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  loadPage
}

function loadPage(url: string): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    return api.cms.getPage(url).then(result => {
      dispatch(withNS({
        type: ActionType.LOAD_PAGE__SUCCESS,
        page: result.page,
        url
      }));
    });
  };
}
