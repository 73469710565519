import { dateFromJSON } from 'Shared/JSON';
import { genderFromJSON } from 'User/JSON';

import { RiskLevelJSON } from 'Api/JSON/Risk';
import {
  ReportListItemJSON, ScreeningReportJSON, HistoricalTableRowJSON,
  RiskChartJSON, RiskFactorJSON, SurveyResponseJSON, ScreenJSON,
  ScreenStepJSON, ScreenFieldJSON, ScreenFieldGroupJSON,
  ScreenFieldTypeJSON, ScreenFieldConditionJSON, ScreenFieldConditionTypeJSON
} from 'Api/JSON/Screenings';
import {
  ReportListItem, Report, HistoricalTableRow, RiskChart,
  RiskFactor, SurveyResponse, Screen, ScreenStep, ScreenField,
  ScreenFieldGroup, ScreenFieldType, FieldCondition,
  FieldConditionType
} from './Data';

import { RiskLevel } from 'Risk/Data';

export function reportListItemFromJSON(
  json: ReportListItemJSON
): ReportListItem {
  const date = dateFromJSON(json.date);
  if (date) {
    return {
      id: json.id,
      date
    };
  } else {
    throw new Error(
      'Could not parse Screening/ReportListItemJSON: ' + JSON.stringify(json)
    );
  }
}

export function reportFromJSON(json: ScreeningReportJSON): Report {
  return {
    title: json.title,
    date: dateFromJSON(json.date),
    birthday: json.birthday ? dateFromJSON(json.birthday) : undefined,
    name: json.name,
    gender: json.gender ? genderFromJSON(json.gender) : undefined,
    infoContent: json.info_content,
    signature: json.signature,
    wellnessGoal: json.wellness_goal,
    historicalTable: historicalTableFromJSON(json.historical_table),
    riskCharts: json.risk_charts.map(riskChartFromJSON),
    riskFactors: json.risk_factors.map(riskFactorFromJSON),
    overallRiskLevel:
      json.risk_level ? riskLevelFromJSON(json.risk_level) : undefined,
    survey: json.survey.map(surveyResponseFromJSON)
  };
}

function surveyResponseFromJSON(json: SurveyResponseJSON): SurveyResponse {
  return json;
}

function riskFactorFromJSON(json: RiskFactorJSON): RiskFactor {
  return {
    title: json.title,
    riskLevel: riskLevelFromJSON(json.risk_level)
  };
}

function riskLevelFromJSON(json: RiskLevelJSON): RiskLevel {
  return json;
}

function historicalTableFromJSON(
  json: HistoricalTableRowJSON[]
): HistoricalTableRow[] {
  return json.map(jsonRow => ({
    date: dateFromJSON(jsonRow.date),
    weight: jsonRow.weight,
    height: jsonRow.height
  }));
}

function riskChartFromJSON(json: RiskChartJSON): RiskChart {
  return {
    title: json.title,
    range: {
      best: json.range.best,
      worst: json.range.worst,
      bestLabel: json.range.best_label,
      worstLabel: json.range.worst_label
    },
    data: json.data.map(d => ({
      date: dateFromJSON(d.date),
      value: d.value,
      valueLabel: d.value_label
    }))
  };
}

export function screenFromJSON(json: ScreenJSON): Screen {
  return {
    data: json.data,
    steps: json.steps.map(screenStepFromJSON),
    errors: []
  };
}

function screenStepFromJSON(json: ScreenStepJSON): ScreenStep {
  return json.map(screenFieldFromJSON);
}

function screenFieldFromJSON(json: ScreenFieldJSON): ScreenField {
  return {
      ...json,
    group: screenFieldGroupFromJSON(json.group),
    type: screenFieldTypeFromJSON(json.type),
    conditions: json.conditions.map(screenFieldConditionFromJSON)
  };
}

function screenFieldGroupFromJSON(
  json: ScreenFieldGroupJSON
): ScreenFieldGroup {
  switch(json) {
  case ScreenFieldGroupJSON.PROFILE:
    return ScreenFieldGroup.PROFILE;
  case ScreenFieldGroupJSON.SPEC:
    return ScreenFieldGroup.SPEC;
  case ScreenFieldGroupJSON.SURVEY:
    return ScreenFieldGroup.SURVEY;
  case ScreenFieldGroupJSON.META:
    return ScreenFieldGroup.META;
  case ScreenFieldGroupJSON.ANON:
    return ScreenFieldGroup.ANON;
  }
}

function screenFieldTypeFromJSON(
  json: ScreenFieldTypeJSON
): ScreenFieldType {
  switch(json) {
  case ScreenFieldTypeJSON.TEXT:
    return ScreenFieldType.TEXT;
  case ScreenFieldTypeJSON.DATE:
    return ScreenFieldType.DATE;
  case ScreenFieldTypeJSON.OPTION:
    return ScreenFieldType.OPTION;
  case ScreenFieldTypeJSON.BOOLEAN:
    return ScreenFieldType.BOOLEAN;
  case ScreenFieldTypeJSON.HTML:
    return ScreenFieldType.HTML;
  case ScreenFieldTypeJSON.OPTION_ARRAY:
    return ScreenFieldType.OPTION_ARRAY;
  }
}

function screenFieldConditionFromJSON(
  json: ScreenFieldConditionJSON
): FieldCondition {
  switch(json.type) {
  case ScreenFieldConditionTypeJSON.IS:
    return { ...json, type: FieldConditionType.IS };
  case ScreenFieldConditionTypeJSON.IS_NOT:
    return { ...json, type: FieldConditionType.IS_NOT };
  case ScreenFieldConditionTypeJSON.GT:
    return { ...json, type: FieldConditionType.GT };
  case ScreenFieldConditionTypeJSON.GTE:
    return { ...json, type: FieldConditionType.GTE };
  case ScreenFieldConditionTypeJSON.LT:
    return { ...json, type: FieldConditionType.LT };
  case ScreenFieldConditionTypeJSON.LTE:
    return { ...json, type: FieldConditionType.LTE };
  case ScreenFieldConditionTypeJSON.EXISTS:
    return { ...json, type: FieldConditionType.EXISTS };
  case ScreenFieldConditionTypeJSON.NOT_EXISTS:
    return { ...json, type: FieldConditionType.NOT_EXISTS };
  }
}
