import * as React from 'react';
import { connect } from 'react-redux';
import { State } from 'Store';
import Logo from 'Shared/UI/Logo';
import { SEL as UserSEL } from 'User/Data';

interface Props {
  logoUrl: string
}

const ProgramLogo = (props: Props) => {
  if (props.logoUrl) {
    return <img src={props.logoUrl} />;
  } else {
    return <Logo variant="text" />;
  }
}

export default connect(
  (s: State) => ({
    logoUrl: UserSEL.programLogoUrl(s.currentUser)
  }),
  null
)(ProgramLogo);
