import * as moment from 'moment-timezone';
export type DateJSON = string;

export function dateToJSON(date: moment.Moment): DateJSON {
  return date.format('YYYY-MM-DD');
}

// ISO 8601 / RFC 3339 formatted time
export type ISOTimeJSON = string;

// Unix timestamp in seconds
export type TimestampJSON = number;

export type MinutesJSON = number;

export type HourJSON = number;

export enum DayOfWeekJSON {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY
}

export type HTMLJSON = string;

export function timestampToJSON(time: moment.Moment): TimestampJSON {
  return time.unix();
}


export interface ErrorMessageJSON {
  message: string
}
