import * as moment from 'moment-timezone';
import * as R from 'ramda';
import * as Random from 'Shared/Random';

import * as BLEDebug from 'BLE/Debug';

export function setup() {
  Object.assign(window, {
    moment, R, Random
  });

  BLEDebug.setup();
}
