import * as R from 'ramda';
import { SEL, State, Goal, GoalStatus, GoalId } from './Data';
import { Activity } from 'Post';
import { UserId } from 'User';
import { Action, ActionType } from './Action';
import { IDMap, idMapFromList, idMapInsert } from 'Shared/Data/IDMap';
import * as UUID from 'Shared/Data/UUID';

export function reducer(state: State, action: Action): State {
  switch(action.type) {
  case ActionType.LOAD_GOALS__SUCCESS:
    return {
        ...state,
      goals: idMapFromList(g => g.id, action.goals)
    };
  case ActionType.LOAD_GOAL_STATUSES__SUCCESS:
    let s = insertGoals(action.goals, state);
    return insertGoalStatuses(
      action.goalStatuses, UUID.withDashes(action.userId), s
    );
  case ActionType.LOAD_GOAL_ACTIVITIES__SUCCESS:
    return insertGoalActivities(action.activities, action.goalId, state);
  case ActionType.INCREMENT_POST_COUNT:
    const status =
      SEL.status(action.goalId, UUID.withDashes(action.userId), state);
    if (status) {
      const updatedStatus = { ...status, postCount: status.postCount + 1 };
      return updateGoalStatus(
        action.goalId, UUID.withDashes(action.userId), updatedStatus, state
      );
    } else {
      return state;
    }
  case ActionType.UPDATE_GOAL_STATUS:
    return updateGoalStatus(
      action.goalId, UUID.withDashes(action.userId), action.status, state
    );


  case ActionType.BEGIN_POLLING:
    return { ...state, polling: true };
  case ActionType.END_POLLING:
    return { ...state, polling: false };
  default:
    return state;
  }
}

////

function insertGoals(goals: Goal[], state: State): State {
  return {
      ...state,
    goals: goals.reduce<IDMap<Goal>>(
      (goalMap, goal) => idMapInsert(goal.id, goal, goalMap) as IDMap<Goal>,
      state.goals
    )
  };
}

function insertGoalStatuses(
  goalStatuses: GoalStatus[], userId: string, state: State
): State {
  return {
      ...state,
    goalStatuses: { ...state.goalStatuses, [userId]: goalStatuses }
  };
}

function updateGoalStatus(
  goalId: GoalId, userId: UserId, status: GoalStatus, state: State
): State {
  const ss = SEL.statuses(userId, state) || [];
  const ss2 = R.reject(s => s.goalId === goalId, ss);
  const ss3 = R.append(status, ss2);
  return insertGoalStatuses(ss3, userId, state);
}

function insertGoalActivities(
  activities: Activity[], goalId: GoalId, state: State
): State {
  return {
      ...state,
    goalActivities: { ...state.goalActivities, [goalId]: activities }
  };
}
