import * as React from 'react';
import * as R from 'ramda';

import {
  SEL, ActivityId, Category, State
} from 'Post/Data';

import { ExpandedSpecType, SPEC_TYPES, UI as SpecsUI } from 'Specs';
import NoContent from 'misc/UI/NoContent';
import Icon from 'Shared/UI/Icon';
import PostMenuActivityCategory from 'Post/UI/PostMenuActivityCategory';
import PostMenuCategory from 'Post/UI/PostMenuCategory';
import PostMenuList from 'Post/UI/PostMenuList';
import PostMenuListItem from 'Post/UI/PostMenuListItem';

interface Props {
  state: State
  onFavorite?: (aid: ActivityId) => void,
  onUnfavorite?: (aid: ActivityId) => void,
  showSpecs?: boolean | ExpandedSpecType[]
  collapsable?: boolean
  // Only allow activities to be posted once
  oneShot?: boolean
}

const NO_FAVORITES_CONTENT = (
  <NoContent className="simple-container">
    To add your most frequent and important activities here just just tap
    the " <Icon name="star-o" />" next to any activity in the menus below.
  </NoContent>
);

export default function renderPostMenu(
  props: Props
): React.ReactElement<Props> {
  return (
    <PostMenuList>
      {renderGenius(props)}
      {renderFavorites(props)}
      {props.state.categories.map(c => renderCategory(props, c))}
      {renderSpecs(props)}
    </PostMenuList>
  );
}

function renderMenuItem(
  key: string, content: React.ReactNode
): React.ReactNode {
  return <PostMenuListItem key={key}>{content}</PostMenuListItem>;
}

function renderGenius(props: Props): React.ReactNode {
  const { state, onFavorite, onUnfavorite } = props;
  if (state.genius) {
    return renderMenuItem(
      'genius',
      <PostMenuActivityCategory
        category={state.genius}
        favoriteIds={SEL.favoriteActivityIds(state)}
        topActivities={state.geniusRecommendations}
        bottomActivities={undefined}
        onFavorite={onFavorite}
        onUnfavorite={onUnfavorite}
        collapsable={collapsable(props)}
        oneShot={props.oneShot}
      />
    );
  }
}

function renderFavorites(props: Props): React.ReactNode {
  const state = props.state;
  if (state.favorites) {
    return renderCategory(props, state.favorites, NO_FAVORITES_CONTENT);
  }
}

function renderSpecs(props: Props): React.ReactNode {
  const specsCategory = SEL.specsCategory(props.state);

  const {genius, favorites} = props.state;
  const isOnlyMeasurementsEnabled = !(!!genius || !!favorites);

  if (specsCategory && props.showSpecs !== false) {
    const specs = normalizeSpecTypes(props.showSpecs);
    return renderMenuItem(
      'specs',
      <PostMenuCategory
        category={specsCategory}
        initialOpen={isOnlyMeasurementsEnabled}
        collapsable={collapsable(props)}
      >
        <SpecsUI.ConnectedSpecForms show={specs}/>
      </PostMenuCategory>
    );
  }
}

function renderCategory(
  props: Props, category: Category, noActivitiesContent?: React.ReactNode
): React.ReactNode {
  const { state, onFavorite, onUnfavorite } = props;
  const noUndef = R.reject(R.isNil);
  const getActivity = (id: ActivityId) => SEL.activity(id, state);
  const top = noUndef(category.topActivityIds.map(getActivity));
  const bottom =
    category.bottomActivityIds ?
    noUndef(category.bottomActivityIds.map(getActivity)) :
    undefined;
  return renderMenuItem(
    category.name,
    <PostMenuActivityCategory
      category={category}
      favoriteIds={SEL.favoriteActivityIds(state)}
      topActivities={top}
      bottomActivities={bottom}
      onFavorite={onFavorite}
      onUnfavorite={onUnfavorite}
      collapsable={collapsable(props)}
      oneShot={props.oneShot}
      noActivitiesContent={noActivitiesContent}
    />
  );
}

function withDefault<T>(value: T | undefined, def: T): T {
  if (value === undefined) { return def; }
  else { return value; }
}

function normalizeSpecTypes(
  showSpecs: boolean | ExpandedSpecType[] | undefined
): ExpandedSpecType[] {
  if (showSpecs === undefined) {
    return SPEC_TYPES;
  } else if (typeof showSpecs === 'boolean') {
    return showSpecs ? SPEC_TYPES : [];
  } else {
    return showSpecs;
  }
}

function collapsable(props: Props): boolean {
  return withDefault(props.collapsable, true);
}
