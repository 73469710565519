import * as React from 'react';
import * as classnames from 'classnames';
import SpecFormLayout from './SpecFormLayout';

interface Props {
  bmi: number | undefined
}

export default function renderBMIForm(
  props: Props
): React.ReactElement<Props> {
  const label =
    props.bmi ? props.bmi : 'enter height and weight to calculate';

  const classes = classnames(
    'measurement-form__static-value',
    { 'measurement-form__static-value--placeholder': !props.bmi }
  );

  return (
    <SpecFormLayout label="bmi" status="default">
      <div className={classes}>
        {label}
      </div>
    </SpecFormLayout>
  );
}
