import {
  OK, HTTPMethod, GET, POST, PUT, ApiClient
} from 'Api';

import {
  GoalJSON, GoalStatusesResponse, GoalStatusResponse, GoalActivitiesResponse
} from './JSON/Goals';

export interface GoalsAPI {
  getGoals(): Promise<{ goals: GoalJSON[] }>,
  getGoalStatuses(
    userId?: string
  ): Promise<GoalStatusesResponse>,
  getGoalStatus(goalId: string): Promise<GoalStatusResponse>,
  getGoalActivities(goalId: string): Promise<GoalActivitiesResponse>,
  activate(goalId: string): OK,
  deactivate(goalId: string): OK,
  reset(goalId: string): OK
}

const GOAL_STATUSES_URL = '/goal_statuses';

export function makeApi(client: ApiClient): GoalsAPI {
  return {
    getGoals,
    getGoalStatuses,
    getGoalStatus,
    getGoalActivities,
    activate,
    deactivate,
    reset
  };


  function getGoals(): Promise<{ goals: GoalJSON[] }> {
    return client.requestJSON(GET, '/goals');
  }

  function getGoalStatuses(
    userId?: string
  ): Promise<GoalStatusesResponse> {
    const url = userId ?
      `${GOAL_STATUSES_URL}?user_id=${userId}` :
      GOAL_STATUSES_URL;
    return client.requestJSON(GET, url);
  }

  function getGoalStatus(
    goalId: string
  ): Promise<GoalStatusResponse> {
    const url = `${GOAL_STATUSES_URL}/get?goal_id=${goalId}`;
    return client.requestJSON(GET, url)
  }

  function getGoalActivities(
    goalId: string
  ): Promise<GoalActivitiesResponse> {
    return client.requestJSON(GET, `/goals/${goalId}/activities`);
  }

  function activate(goalId: string): OK {
    return goalStatusAction(POST, goalId, 'activate');
  }

  function deactivate(goalId: string): OK {
    return goalStatusAction(PUT, goalId, 'deactivate');
  }

  function reset(goalId: string): OK {
    return goalStatusAction(POST, goalId, 'reset');
  }

  function goalStatusAction(
    m: HTTPMethod,
    goalId: string,
    action: 'activate' | 'deactivate' | 'reset'
  ): OK {
    return client.request(m, `/goal_statuses/${action}?goal_id=${goalId}`);
  }
}
