import * as moment from 'moment-timezone';
import * as R from 'ramda';

const PRETTY_ZONES: { [k: string]: TZId } = {
  'Pacific/Honolulu': 'Hawaii',
  'America/Juneau': 'Alaska',
  'America/Los_Angeles': 'Pacific Time',
  'America/Phoenix': 'Arizona',
  'America/Denver': 'Mountain Time',
  'America/Chicago': 'Central Time',
  'America/New_York': 'Eastern Time',
  'America/Indiana/Indianapolis': 'Indiana (East)'
};

let _priorityZones: Zone[];
let _nonPriorityZones: Zone[];
let _allZones: Zone[];


export type TZId = string;

export interface Zone {
  id: TZId,
  label: string,
  group: 'US Zones' | 'Other Zones'
}

function friendlyName(name: TZId): string {
  const parts = name.split('/').map(n => n.replace('_', ' '));
  const region = parts.shift() || '';
  if (parts.length === 0) {
    return region;
  } else {
    return `${region} - ${parts.reverse().join(', ')}`;
  }
}

export function displayName(tzId: string): string {
  return PRETTY_ZONES[tzId] || friendlyName(tzId);
}

export function priorityZones(): Zone[] {
  if (!_priorityZones) {
    _priorityZones = R.map(
      (zoneId: TZId) => {
        return {
          id: zoneId,
          label: PRETTY_ZONES[zoneId],
          group: 'US Zones'
        } as Zone;
      },
      R.keys(PRETTY_ZONES)
    );
  }
  return _priorityZones;
}

export function nonPriorityZones(): Zone[] {
  if (!_nonPriorityZones) {
    _nonPriorityZones = moment.tz.names()
      .filter(name => !PRETTY_ZONES[name])
      .map(name => ({
        id: name,
        label: friendlyName(name),
        group: 'Other Zones'
      } as Zone));
  }
  return _nonPriorityZones;
}

export function allZones(): Zone[] {
  if (!_allZones) {
    _allZones = priorityZones()
      .concat(nonPriorityZones());
  }
  return _allZones;
};
