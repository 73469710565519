import { decodeHighByteInBack, chunks, decodeMoment } from './Util';
import { SleepDetail } from '../Data';

/**
Command response (can lengthen the command):

0x53 ID1 ID2 YY MM DD HH mm SS LEN SD1 SD2 SD3 ... SD24

The minimum unit is 34 bytes, the total number of pieces of total data contained
in a BLE packet can be dynamically controlled according to the actual maximum
length of the transmitted packet.

ID1 ID2: read the data number, high byte in the back.
YY MM DD HH mm SS: stands for the date, year / month / day / hour / minute /
  second.
LEN: means the length of the sleep data.
SD1 SD2 SD3 ... SD24: means the sleep quality in every 5 min, maximum 2-hour
  sleep data for every data piece.
**/
export function decode(packet: Uint8Array): SleepDetail[] {
  return chunks(34, packet).map(chunk => {
    const length = chunk[9];
    return {
      id: decodeHighByteInBack(chunk.slice(1, 3)),
      time: decodeMoment(chunk.slice(3, 9)),
      length: length,
      details: chunk.slice(10, 10 + length)
    };
  });
}
