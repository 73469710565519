import * as React from 'react';
import * as classnames from 'classnames';
import { connect } from 'react-redux';
import { RouterLocation } from 'connected-react-router';
import { State } from 'Store';
import * as Urls from 'Shared/Urls';

type Props = ConnectedProps & OwnProps;

interface OwnProps {
  className?: string
}
interface ConnectedProps {
  navIsOpen: boolean
  location: RouterLocation<any> | null
}

const EXCLUSIONS = {
  [Urls.screeningFormUrl()]: true,
  [Urls.maintenanceModeUrl()]: true,
  [Urls.forcedUpgradeUrl()]: true,
  [Urls.offlineModeUrl()]: true
};

class Footer extends React.Component<Props, {}> {
  isHidden(): boolean {
    const location = this.props.location;
    return location === null || EXCLUSIONS[location.pathname]
  }

  render() {
    if (this.isHidden()) { return null; }

    const classes = classnames(
      'app-footer clearfix',
      this.props.className,
      { 'nav-bar-layout__content--blur': this.props.navIsOpen }
    );
    return (
      <div className={classes}></div>
    );
  }
}

export default connect(
  (state: State) =>
    ({
      navIsOpen: state.nav.open,
      location: state.router ? state.router.location : null
    }),
  (() => ({}))
)(Footer);
