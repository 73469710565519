import * as React from 'react';
import { RiskLevel } from 'Risk/Data';

interface Props {
  riskLevel: RiskLevel
}

const RiskDot = (props: Props) => {
  return (
    <span className="risk-dot" style={{backgroundColor: props.riskLevel.color}}>
    </span>
  );
}

export default RiskDot
