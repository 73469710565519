import './AlertBox.scss';
import * as React from 'react';
import * as classnames from 'classnames';
import { bem } from 'css-util';

type Modifier
  = 'default'
  | 'info'
  | 'round'
  | 'error'
  | 'warning'
  | 'success'
  | 'center'
  | 'big'
  | 'icon';

interface Props {
  modifiers?: Modifier | Modifier[]
  children?: React.ReactNode
  className?: string
}

const CSS = bem('alert-box');

const AlertBox = (props: Props) => {
  const modifiers = extractModifiers(props.modifiers);
  const classes = classnames(CSS()(modifiers), props.className);
  return (
    <div className={classes}>
      {props.children}
    </div>
  );
}

export default AlertBox;


function extractModifiers(ms: undefined | Modifier | Modifier[]): Modifier[] {
  if (ms === undefined) {
    return [];
  } else if (typeof ms === 'string') {
    return [ms];
  } else {
    return ms;
  }
}
