import * as React from 'react';
import { ReportListItem } from 'Screenings';
import { screeningsSettingsUrl, screeningReportUrl } from 'Shared/Urls';
import SimpleTileContent from './SimpleTileContent';
import Tile from './Tile';
import NoContent from './NoContent';

interface Props {
  record: ReportListItem | undefined
}

const NO_REPORTS =
  <NoContent iconName="clipboard-list">no screenings to display</NoContent>;

export default function renderScreeningsState(
  props: Props
): React.ReactElement<Props> {
  return (
    <Tile title="screenings" linkUrl={screeningsSettingsUrl()}>
      {renderReportTile(props.record)}
    </Tile>
  );
}

function renderReportTile(report: ReportListItem | undefined) {
  if (report) {
    return (
      <SimpleTileContent
        title="Last screening completed" badge={report.date.format('L')}
        url={screeningReportUrl(report.id)}
        icon="clipboard-list" />
    );
  } else {
    return NO_REPORTS;
  }
}
