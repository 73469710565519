import { ApiClient, GET } from 'Api';

import { GetQuicklinksResponseJSON } from './JSON/Home';

export interface HomeAPI {
  getQuicklinks(): Promise<GetQuicklinksResponseJSON>
}

export function makeApi(client: ApiClient): HomeAPI {
  return {
    getQuicklinks
  };

  function getQuicklinks(): Promise<GetQuicklinksResponseJSON> {
    return client.requestJSON(GET, { path: '/quicklinks', version: 3 })
  }
}
