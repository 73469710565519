export * from './Action';
export * from './Data';
export * from './ActionCreator';

export { reducer } from './Store';

import DashboardPage from './Page/MyDashboard';
import CrewMemberDashboard from './Page/CrewMemberDashboard';

export const Pages = {
  MyDashboard: DashboardPage,
  CrewMemberDashboard
};
