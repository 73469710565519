import { ApiClientMiddleware, FetchParams } from 'Api';
import { AppStore } from 'Store';
import { ActionCreators } from 'Auth/ActionCreator';

export function makeApiMiddleware(
  store: AppStore
): ApiClientMiddleware {
  return { request, response };

  function request(input: RequestInfo, init?: RequestInit): FetchParams {
    return [input, init];
  }

  function response(result: Promise<Response>): Promise<Response> {
    return result
      .then(response => {
        if (response.status === 401) {
          store.dispatch(ActionCreators.logout());
        }
        return response;
      });
  }
}
