import { ActionCreatorThunk } from 'Store';
import { ActionType, NSAction, withNavNS } from './Action';
import { push } from 'connected-react-router';
import { peekBreadcrumb, breadcrumbUrl } from 'Nav/Data';

export interface ActionCreators {
  openNav(): NSAction
  closeNav(): NSAction

  /**
   * Saves current location as a breadcrumb.  If url is passed, saves that URL
   * as breadcrumb instead
   */
  saveBreadcrumb(url?: string): ActionCreatorThunk

  /**
   * Removes latest breadcrumb from the stack
   */
  popBreadcrumb(url?: string): NSAction

  /**
   * Pops the last breadcrumb and navigates to it.  If there are no
   * breadcrumbs, navigates to fallbackUrl
   */
  goBack(fallbackUrl: string): ActionCreatorThunk

  /**
   * Performs a history "push" of the given URL after saving the _current_
   * location as a breadcrumb
  */
  pushWithBreadcrumb(url: string): ActionCreatorThunk

  /**
   * Sets an initial breadcrumb if there are currently none
   */
  initBreadcrumb(url: string): ActionCreatorThunk

  /**
   * Clears all breadcrumbs
   */
  clearBreadcrumbs(): NSAction
}

export const ActionCreators: ActionCreators = {
  openNav,
  closeNav,
  saveBreadcrumb,
  popBreadcrumb,
  goBack,
  pushWithBreadcrumb,
  initBreadcrumb,
  clearBreadcrumbs
}

function openNav(): NSAction {
  return withNavNS({ type: ActionType.OPEN_NAV_MENU });
}

function closeNav(): NSAction {
  return withNavNS({ type: ActionType.CLOSE_NAV_MENU });
}

function pushWithBreadcrumb(url: string): ActionCreatorThunk {
  return (dispatch) => {
    dispatch(saveBreadcrumb());
    dispatch(push(url))
    return Promise.resolve();
  };
}
function saveBreadcrumb(url?: string): ActionCreatorThunk {
  return (dispatch, _, { history }) => {
    const location = url ? { pathname: url } : history.location;
    const breadcrumb = { location };
    dispatch(withNavNS({ type: ActionType.SAVE_BREADCRUMB, breadcrumb }));
    return Promise.resolve();
  }
}

function initBreadcrumb(url: string): ActionCreatorThunk {
  return (dispatch, getState) => {
    if (!peekBreadcrumb(getState().nav)) {
      dispatch(saveBreadcrumb(url));
    }
    return Promise.resolve();
  };
}

function popBreadcrumb(): NSAction {
  return withNavNS({ type: ActionType.POP_BREADCRUMB });
}

function goBack(fallbackUrl: string): ActionCreatorThunk {
  return (dispatch, getState) => {
    const crumb = peekBreadcrumb(getState().nav)
    const url = crumb ? breadcrumbUrl(crumb) : fallbackUrl;
    dispatch(popBreadcrumb());
    dispatch(push(url));
    return Promise.resolve();
  }
}

function clearBreadcrumbs(): NSAction {
  return withNavNS({ type: ActionType.CLEAR_BREADCRUMBS });
}
