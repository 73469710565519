import * as R from 'ramda';
import * as React from 'react';
import { UI as EdgeUI } from 'Edge';
const DefaultEdgeProviderIcon = EdgeUI.DefaultProviderIcon;

import {
  ChallengeDetails,
  ChallengeSummary,
  MetricType,
  StepChallengeProgress, HabitChallengeProgress, SpecChallengeProgress,
  TaskChallengeProgress,
  dailyPointsMapLookup,
  SpecMetric, DeltaType,
  isCurrent,
  ChallengePlayer, LeaderboardMode,
  playerName, isTeamPlayer, teamsEnabled, isTaskMetricWithDailyCompletion
} from 'Challenges/Data';

import {
  challengeLeaderboardUrl, challengeTeamLeaderboardUrl, challengeTeamUrl,
  challengeUrl, challengeTeamsUrl
} from 'Shared/Urls';

import Link from 'Shared/UI/Link';
import Spinner from 'Shared/UI/Spinner';
import StripedTable from 'Shared/UI/StripedTable';
import NoContent from 'misc/UI/NoContent';

import ActionButton from 'Challenges/UI/ActionButton';

interface Props {
  mode: LeaderboardMode,
  challenge: ChallengeDetails,
  players: ChallengePlayer[],
  hideLinkToFull?: boolean
}

export default function renderLeaderboard(
  props: Props
): React.ReactElement<Props> {
  if (props.challenge.reloading) {
    return (
      <p className="pad-20">
        <Spinner></Spinner>
      </p>
    );
  } else {
    return (
      <div>
        {props.players.length === 0 ? renderNoData(props) : renderTable(props)}
        {renderLeaderboardModeToggle(props.mode, props.challenge.summary)}
      </div>
    );
  }
}

function renderNoData(props: Props): React.ReactNode {
  return (
    <NoContent>
      No {props.mode} to display*
    </NoContent>
  );
}

function renderTable(props: Props): React.ReactNode {
  const summary = props.challenge.summary;
  return (
    <StripedTable
      className="challenge-leaderboard"
      headers={headers(props.mode, summary)}
      rows={props.players.map(row(summary))}
      rowClasses={rowClasses(summary)}
      headerClasses={rowClasses(summary)}
      tfoot={renderTFoot(props)}/>
  );
}

function headers(
  mode: LeaderboardMode, summary: ChallengeSummary
): React.ReactNode[] {
  const metric = summary.metric;
  const title = leaderboardTitle(mode, true);
  const lead = ['', title];
  switch(metric.type) {
    case MetricType.Steps:
      return [...lead, 'source', asterisksForTeamMode('total', 2, mode)];
    case MetricType.Habit:
      return [
        ...lead,
        'last post',
        asterisksForTeamMode('total posts', 2, mode)
      ];
    case MetricType.Spec:
      return [
        ...lead,
        'last post',
        'last ' + metric.specType,
        asterisksForTeamMode('change', 2, mode)
      ];
    case MetricType.Task:
      let headers = [...lead, asterisksForTeamMode('total points', 2, mode)];
      if (
        isCurrent(summary) &&
        isTaskMetricWithDailyCompletion(summary.metric)
      ) {
        headers = [...headers, asterisksForTeamMode("today's points", 3, mode)]
      };
      return headers;
  }
}

function leaderboardTitle(mode: LeaderboardMode, asterisk = false): string {
  switch(mode) {
    case 'teams':
      return `team${asterisk ? ' *' : ''}`;
    case 'players':
      return 'player';
  }
}

function rowClasses(summary: ChallengeSummary): string[] {
  let metricClasses: string[] = [];
  switch(summary.metric.type) {
    case MetricType.Steps:
      metricClasses = ['edge-source', 'step-count'];
      break;
    case MetricType.Habit:
      metricClasses = ['last-post-at', 'post-count'];
      break;
    case MetricType.Spec:
      metricClasses = ['last-post-at', 'current-value', 'delta'];
      break;
    case MetricType.Task:
      metricClasses = ['total-points', 'todays-points'];
  }
  const modifiers = ['rank', 'player-name', ...metricClasses];
  return modifiers.map(
    m => `challenge__metric-value challenge__metric-value--${m}`
  );
}

function row(
  summary: ChallengeSummary
): (player: ChallengePlayer) => React.ReactNode[] {
  return player => {
    let progressNodes, p
    const metric = summary.metric;
    switch(metric.type) {
      case MetricType.Steps:
        p = player.progress as StepChallengeProgress;
        progressNodes = [
          p.sourceIcon ?
          <img src={p.sourceIcon} className="edge-provider-icon" /> :
          <DefaultEdgeProviderIcon />,
          p.stepCount && p.stepCount.toLocaleString()
        ];
        break;
      case MetricType.Habit:
        p = player.progress as HabitChallengeProgress;
        progressNodes = [
          p.lastPostTime ? p.lastPostTime.format('MMM D') : '',
          p.postCount
        ];
        break;
      case MetricType.Spec:
        p = player.progress as SpecChallengeProgress;
        progressNodes = [
          p.lastPostTime ? p.lastPostTime.format('MMM D') : '',
          p.currentValue,
          formatDelta(metric, p.delta)
        ];
        break;
      case MetricType.Task:
        p = player.progress as TaskChallengeProgress;
        progressNodes = [p.totalPoints];
        if (
          isCurrent(summary) &&
          isTaskMetricWithDailyCompletion(summary.metric)
        ) {
          progressNodes =
            [...progressNodes, dailyPointsMapLookup(new Date(), p.dailyPoints)];
        }
        break;
      default:
        console.error('Unhandled metric type:', (metric as any).type);
        throw new Error('Unhandled metric type in Leaderboard/renderRow');
    }

    return [
      player.rank,
      <span className="challenge__player-name__text">
        {renderPlayerName(summary, player)}
      </span>,
      ...progressNodes
    ];
  }
}

function renderPlayerName(
  summary: ChallengeSummary, player: ChallengePlayer
): React.ReactNode {
  const text = playerName(player);
  if (isTeamPlayer(player)) {
    return (
      <Link
        to={challengeTeamUrl(summary.id, player.team.id)}
        breadcrumb="push"
        themed={true}
      >
        {text}
      </Link>
    );
  } else {
    return text;
  }
}

function renderTFoot(props: Props): React.ReactNode {
  if (props.hideLinkToFull) {
    return null;
  } else {
    const url = fullLeaderboardUrl(props);
    const label = `see full ${leaderboardTitle(props.mode)} leaderboard`;
    return (
      <tfoot className="challenge-leaderboard__footer">
        <tr>
          <td colSpan={5} className="text-center">
            <Link to={url}
              breadcrumb="push"
              themed={true}
              className="challenge-leaderboard__see-full-link"
            >
              {label}
            </Link>
          </td>
        </tr>
      </tfoot>
    );
  }
}

function fullLeaderboardUrl(props: Props): string {
  const challengeId = props.challenge.summary.id;
  switch(props.mode) {
    case 'teams':
      return challengeTeamLeaderboardUrl(challengeId);
    case 'players':
      return challengeLeaderboardUrl(challengeId);
  }
}

function formatDelta(
  metric: SpecMetric, delta: number
): React.ReactNode {
  switch(metric.deltaType) {
    case DeltaType.RELATIVE:
      return (delta * 100).toFixed(1) + '%';
    case DeltaType.ABSOLUTE:
      return delta.toFixed(1);
  }
}

function renderLeaderboardModeToggle(
  currentMode: LeaderboardMode, summary: ChallengeSummary
): React.ReactNode {
  if (!teamsEnabled(summary)) { return; }

  if (currentMode === 'players') {
    return (
      <ActionButton
        label="view team leaderboard"
        breadcrumb="push"
        action={challengeTeamsUrl(summary.id)}
        modifiers={['primary']}
        appendClasses="theme__primary-bg"
      />
    );
  } else {
    return (
      <ActionButton
        label="view player leaderboard"
        breadcrumb="push"
        action={challengeUrl(summary.id)}
        modifiers={['primary']}
        appendClasses="theme__primary-bg"
      />
    );
  }
}

function asterisksForTeamMode(
  text: string, count: number, mode: LeaderboardMode
): string {
  if (mode === 'teams') {
    const asterisks = R.repeat('*', count).join('');
    return `${text} ${asterisks}`;
  } else {
    return text;
  }
}
