import * as React from 'react';
import * as R from 'ramda';
import {
  challengeInviteUrl,
  challengeRulesUrl,
  challengeJoinTeamUrl,
  challengeTeamUrl,
} from 'Shared/Urls';

import {
  ChallengeId, ChallengeDetails, ChallengeSummary, MetricType, LeaderboardMode,
  isComplete, isJoinable, teamsEnabled, Milestone
} from 'Challenges/Data';

import GraphicHeader from './GraphicHeader';
import HabitDetails from './HabitDetails';
import ActionButton from './ActionButton';
import SecondaryButton from './SecondaryButton';
import StepDetails from './StepDetails';
import SpecDetails from './SpecDetails';
import TaskDetails from './TaskDetails';
import TeamLeaderboardExplanation from './Teams/LeaderboardExplanation';

interface Props {
  challenge: ChallengeDetails,
  leaveChallenge: (challengeId: ChallengeId) => void,
  addSteps: (challengeId: ChallengeId, count: number) => void,
  pollForUpdate: (challengeId: ChallengeId) => void,
  leaderboardMode: LeaderboardMode,
  leaderboard: React.ReactNode
}

interface Leg {
  previousStage: string,
  nextStage: string
}

export default function renderEnrolledDetails(
  props: Props
): React.ReactElement<Props> {
  const { summary, milestones } = props.challenge;
  const mileCount = challengeMileCount(summary.challengeStepCount);

  return (
    <div className="challenge-enrolled">
      <GraphicHeader challenge={summary} />
      <p className="challenge-enrolled__player-count">
        challenge participants: {summary.playerCount.toLocaleString()}
      </p>
      {renderStepMileage(summary, mileCount)}
      {renderMilestones(milestones, mileCount)}

      <hr className="clearfix challenge-enrolled__hr"/>

      {renderChallenge(props)}

      <div className="challenge__button-list">
        {renderJoinOrCreateTeamButton(summary)}

        {renderInviteButton(summary)}

        <SecondaryButton
          label="learn about this challenge"
          action={challengeRulesUrl(summary.id)}
          breadcrumb="push"
        />

        {renderLeaveChallengeButton(props)}

        {renderTeamLeaderboardExplanation(props)}
      </div>
    </div>
  );
}

function renderChallenge(
  props: Props
): React.ReactNode {
  const challenge = props.challenge;
  const challengeId = challenge.summary.id;
  const afterChallengeActivity = () => props.pollForUpdate(challengeId);
  const metric = challenge.summary.metric;

  switch(metric.type) {
    case MetricType.Steps:
      return (
        <StepDetails
          challenge={challenge}
          submitSteps={props.addSteps}
          leaderboard={props.leaderboard}
        />
      );
    case MetricType.Habit:
      return (
        <HabitDetails
          challenge={challenge}
          afterPost={afterChallengeActivity}
          leaderboard={props.leaderboard}
        />
      );
    case MetricType.Spec:
      return (
        <SpecDetails
          challenge={challenge}
          metric={metric}
          afterPost={afterChallengeActivity}
          leaderboard={props.leaderboard}
        />
      );
    case MetricType.Task:
      return (
        <TaskDetails
          challenge={challenge}
          metric={metric}
          leaderboard={props.leaderboard}
        />
      );
  }
}

function renderInviteButton(
  summary: ChallengeSummary
): React.ReactNode {
  if (!isComplete(summary) && isJoinable(summary)) {
    return (
      <SecondaryButton
        label="invite others to this challenge"
        action={challengeInviteUrl(summary.id)}
        breadcrumb="push" />
    );
  } else {
    return null;
  }
}

function renderLeaveChallengeButton(
  props: Props
): React.ReactNode {
  const summary = props.challenge.summary;
  if (!isComplete(summary)) {
    return (
      <SecondaryButton
        label="leave this challenge"
        action={() => props.leaveChallenge(summary.id)}/>
    );
  } else {
    return null;
  }
}

function renderJoinOrCreateTeamButton(
  summary: ChallengeSummary
): React.ReactNode {
  if (!isComplete(summary) && teamsEnabled(summary)) {
    let label: string;
    let url: string;

    if (summary.myTeamId) {
      label = 'go to my team';
      url = challengeTeamUrl(summary.id, summary.myTeamId);
    } else {
      label = 'join or create team';
      url = challengeJoinTeamUrl(summary.id);
    }

    return (
      <ActionButton
        label={label}
        action={url}
        breadcrumb="push"
        modifiers={['emphasize']}
      />
    );
  } else {
    return null;
  }
}

function renderTeamLeaderboardExplanation(props: Props) {
  if (props.leaderboardMode === 'teams') {
    return (
      <TeamLeaderboardExplanation metric={props.challenge.summary.metric} />
    );
  }
}

function challengeMileCount(challengeStepCount: number): number {
  return Math.floor(challengeStepCount / 2112);
}

function renderStepMileage(summary: ChallengeSummary, mileCount: number) {

  if (R.isNil(summary.challengeStepCount)) {
    return [];
  }
  else {
    return (
        <div className="challenge-stepmileage">
            <h1>Total Steps:
              <span className="challenge-stepmileage__total"> {summary.challengeStepCount.toLocaleString()}</span>
            </h1>
            <h1>Total Miles:
              <span className="challenge-stepmileage__total"> {mileCount.toLocaleString()}</span>
            </h1>
        </div>
    )
  }
}

function renderMilestones(
  milestones: Milestone[], challengeMileCount: number
): React.ReactNode {
  if (noMilestonesConfigured(milestones)) {
    return [];
  } else if (!milestonesCompleted(milestones, challengeMileCount)) {
    const currentLeg = findCurrentLeg(milestones, challengeMileCount)

    return renderMilestoneContent(
      currentLeg.previousStage,
      currentLeg.nextStage
    )
  } else if (milestonesCompleted(milestones, challengeMileCount)) {
    const lastMilestone = R.last(milestones) || {name: 'Unknown Milestone'};

    return renderMilestoneContent(
      lastMilestone.name,
      'No more milestones, CONGRATULATIONS, you made it!'
    )
  } else {
    return [];
  }
}

function renderMilestoneContent(
  lastMilestone: string, nextMilestone: string
): React.ReactNode {
    return (
      <div className="challenge-milestones">
        <p className="challenge-milestones__label">Last Milestone Reached:</p>
        <p className="challenge-milestones__name">{lastMilestone}</p>
        <p className="challenge-milestones__label">Next Milestone:</p>
        <p className="challenge-milestones__name">{nextMilestone}</p>
      </div>
    )
}

function noMilestonesConfigured(milestones: Milestone[]): boolean  {
  return milestones.length == 0;
}

function milestonesCompleted(
  milestones: Milestone[], challengeMileCount: number
): boolean {
  const lastMilestone = R.last(milestones) || {miles: 0}
  return (challengeMileCount >= lastMilestone.miles);
}

function findCurrentLeg(milestones: Milestone[], challengeMileCount: number): Leg {
  const milestoneTuples: [Milestone, Milestone][] = R.aperture(2, milestones);

  const currentTuple: [Milestone, Milestone] | undefined =
    R.find(isInsideLeg(challengeMileCount), milestoneTuples);

  if (R.isNil(currentTuple)) {
    const unknownLeg: Leg = {previousStage: 'Unknown', nextStage: 'Unknown'};
    return unknownLeg;
  }
  else {
    const currentLeg: Leg =
      {previousStage: currentTuple[0].name, nextStage: currentTuple[1].name};
    return currentLeg;
  }
}

const isInsideLeg = R.curry(_isInsideLeg);
function _isInsideLeg(
  challengeMileCount: number, milestoneTuple: [Milestone, Milestone]
): boolean {
  return (milestoneTuple[0].miles <= challengeMileCount &&
    challengeMileCount <= milestoneTuple[1].miles);
}
