import noop from 'Shared/Noop';

interface NotificationService {
  alert: (msg: string, cb: () => void, title?: string, button?: string) => void
  confirm: (
    msg: string,
    cb: (result: number) => void,
    title?: string,
    buttons?: string[]
  ) => void
}

export function alert(
  message: string, title?: string, buttonName?: string
): Promise<void> {
  return new Promise(resolve => {
    const native: NotificationService | undefined =
      (window.navigator as any).notification;
    if (native) {
      native.alert(message, resolve, title, buttonName);
    } else {
      window.alert(message);
      resolve();
    }
  });
}

/**
 * Present a confirmation dialog to the user w/ the given message and title
 * (where supported).  Returns a promise that is resolved if the user does
 * confirm, and is rejected if the user does not confirm.
 */
export function confirm(message: string, title?: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const native: NotificationService | undefined
      = (window.navigator as any).notification;
    if (native) {
      native.confirm(
        message,
        (result: number) => {
          if (result === 1) {
            // cancel
            reject();
          } else {
            // Ok
            resolve();
          }
        },
        title,
        ['Cancel', 'OK']
      );
    } else {
      setTimeout(() => {
        window.confirm(message) ? resolve() : reject();
      });
    }
  });
}

/**
 * Like `confirm`, but does nothing if the user selects "cancel" instead of
 * ok, instead of rejecting the promise
 */
export function confirm_(message: string, title?: string): Promise<void> {
  return new Promise(resolve => confirm(message, title).then(resolve, noop));
}

/**
 * Runs confirm dialog and returns boolean value (wrapped in promise), instead
 * of using resolve/reject to indicate the result.  This is maybe a more correct
 * way of translating `confirm` to promises, but the existing implementation is
 * arleady widely in use, so we are just adding this as a new function
 */
export function confirmValue(
  message: string, title?: string
): Promise<boolean> {
  return confirm(message, title).then(() => true, () => false);
}
