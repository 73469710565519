import * as React from 'react';
import { connect } from 'react-redux';
import { DispatchProps } from 'Store';
import { ActionCreators } from 'Auth/ActionCreator';
import { HUD } from 'HUD';

/**
 * Not really a component, but we do want to be able to have URL targetable way
 * to logout, meaning you can nav somewhere that logs you out, so this is the
 * simplest way to achieve that
 */
class LogoutPage extends React.Component<DispatchProps, {}> {
  componentDidMount() {
    this.props.dispatch(ActionCreators.logout()).then(() =>
      window.location.reload()
    );
  }

  render() {
    return <HUD state="loading"/>;
  }
}

export default connect(null, dispatch => ({ dispatch }))(LogoutPage);
