import * as moment from 'moment-timezone';
import { ApiClient, POST } from 'Api';
import { timestampToJSON } from './JSON/Shared';

export interface TasksAPI {
  completeTask: (taskId: number) => Promise<void>,
  completeTaskAtTime: (taskId: number, time: moment.Moment) => Promise<void>
}

export function makeApi(client: ApiClient): TasksAPI {
  return {
    completeTask,
    completeTaskAtTime
  };

  function completeTask(taskId: number): Promise<void> {
    return _completeTaskApiCall(taskId);
  }

  function completeTaskAtTime(
    taskId: number, time: moment.Moment
  ): Promise<void> {
    return _completeTaskApiCall(taskId, time);
  }

  function _completeTaskApiCall(
    taskId: number, time?: moment.Moment
  ): Promise<void> {
    let data: { task_id: number, time?: number } = { task_id: taskId };
    if (time) { data.time = timestampToJSON(time); }
    return client.requestJSON(
      POST,
      { version: 3, path: '/task_completions' },
      data
    );
  }
}
