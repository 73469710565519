import { State, LENSES } from './Data';
import { Action, ActionType } from './Action';

export function reducer(state: State, action: Action): State {
  switch(action.type) {
  case ActionType.LOAD_APPT_TYPES__SUCCESS:
    return LENSES.apptTypes.set(state, action.apptTypes);
  case ActionType.LOAD_APPTS__SUCCESS:
    return LENSES.appts.set(state, action.appts);
  case ActionType.LOAD_AVAILABILITY__BEGIN:
    return LENSES.availableSlots.set(state, undefined);
  case ActionType.LOAD_AVAILABILITY__SUCCESS:
    return LENSES.availableSlots.set(state, action.availableSlots);
  default:
    return state;
  }
}
