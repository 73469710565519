import './LoadSuccessButton.scss';
import * as React from 'react';
import * as classnames from 'classnames';

import Icon from 'Shared/UI/Icon';
import Spinner from 'Shared/UI/Spinner';

export interface Props {
  status?: Status
  className?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  children: React.ReactNode
}

export type Status = 'default' | 'loading' | 'success';

const LoadSuccessButton = (props: Props) => {
  const status = props.status || 'default';
  const disabled = props.disabled || status !== 'default';
  const classes = classnames(
    'button', 'load-success-button', props.className,
    `load-success-button--${status}`
  );

  return (
    <button className={classes} onClick={props.onClick} disabled={disabled}>
      {renderContents(props)}
    </button>
  );
}

export default LoadSuccessButton;


function renderContents(props: Props) {
  switch(props.status) {
    case 'loading':
      return <Spinner/>;
    case 'success':
      return <Icon name="check-circle-o" />;
    default:
      return props.children;
  }
}
