import {
  TokenProvider, GET, apiClient
} from 'Api';

import { CrewMemberJSON } from './JSON/User';
import { ScoreJSON, BiopostJSON } from './JSON/Post';
import { DateJSON } from './JSON/Shared';

export interface BiopostTimeSeriesResponseJSON {
  user: CrewMemberJSON,
  scores: ScoreJSON[],
  bioposts: BiopostJSON[],
  start_time: DateJSON,
  end_time: DateJSON
}

export interface TimeSeriesAPI {
  getBiopostData(userId?: string): Promise<BiopostTimeSeriesResponseJSON>
}

export function makeApi(token: TokenProvider): TimeSeriesAPI {
  const client = apiClient(token);
  return {
    getBiopostData
  };

  function getBiopostData(
    userId?: string
  ): Promise<BiopostTimeSeriesResponseJSON> {
    const url = userId ? `/time_series/${userId}` : '/time_series';
    return client.requestJSON(GET, url);
  }
}
