import * as React from 'react';

import ArrowLink from 'Shared/UI/ArrowLink';
import { screeningReportUrl } from 'Shared/Urls';

import { ReportListItem } from 'Screenings';

interface Props {
  reports: ReportListItem[]
}

export default function renderScreeningReportList(
  props: Props
): React.ReactElement<Props> {
  if (props.reports.length === 0) {
    return (
      <p className="no-data text-center">
        No reports to display.
      </p>
    );
  } else {
    return (
      <ul className="screening-report-list">
        {props.reports.map(renderListItem)}
      </ul>
    );
  }
}

const DATE_FORMAT = 'MMM D, YYYY';

function renderListItem(item: ReportListItem): React.ReactNode {
  return (
    <li key={item.id} className="screening-report-list__item">
      <ArrowLink
        to={screeningReportUrl(item.id)}
        themed={true}
        breadcrumb="push"
      >
        {item.date.format(DATE_FORMAT)}
      </ArrowLink>
    </li>
  );
}
