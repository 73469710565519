import * as R from 'ramda';
import * as moment from 'moment-timezone';

import {
  LENSES, State, Connections, newConnections, setAllConnections, setConnection
} from './Data';
import * as Edge from 'Edge/Data';

import { ActionType } from './Action';
import * as Action from './Action';

export function reducer(state: State, action: Action.Action): State {
  switch(action.type) {

    case ActionType.LOAD_CONFIGURATION__BEGIN:
      return {
        ...state,
        connections: newConnections(state.providers || [], 'pending')
      };

    case ActionType.LOAD_CONFIGURATION__SUCCESS:
      const providers = action.availableProviders;
      const userDevices = action.userDevices;
      const connections: Connections = setAllConnections(
        action.connectedProviderKeys,
        'connected',
        newConnections(providers, 'not_connected')
      );

      return { ...state, connections, providers, userDevices };

    case ActionType.CONNECT__BEGIN:
    case ActionType.DISCONNECT__BEGIN:
      return R.over(
        LENSES.connections,
        setConnection(action.provider.key, 'pending'),
        state
      );

    case ActionType.CONNECT__SUCCESS:
      return R.over(
        LENSES.connections,
        setConnection(action.provider.key, 'connected'),
        state
      );

    case ActionType.DISCONNECT__SUCCESS:
      return disconnectSuccess(state, action);

    case ActionType.DISCONNECT_FROM_DEVICES:
      return disconnectFromDevices(state, action);
    case ActionType.REMOVE_DEVICE__BEGIN:
      return removeDeviceBegin(state, action);
    case ActionType.REMOVE_DEVICE__COMPLETE:
      return removeDeviceComplete(state, action);
    case ActionType.BLE_CONNECT_TO_DEVICE:
      return bleConnectToDevice(state, action);
    case ActionType.BLE_SYNC_START:
      return bleSyncStart(state, action);
    case ActionType.BLE_SYNC_STOP__SUCCESS:
      return bleSyncStopSuccess(state, action);
    case ActionType.BLE_SYNC_STOP__ERROR:
      return bleSyncStopError(state, action);
    case ActionType.BLE_BACKGROUND_SYNC_START:
      return bleBackgroundSyncStart(state);
    case ActionType.BLE_BACKGROUND_SYNC_STOP:
      return bleBackgroundSyncStop(state);
    case ActionType.BLE_SYNC_ON_RESUME_START:
      return bleSyncOnResumeStart(state);
    case ActionType.BLE_SYNC_ON_RESUME_PENDING_STOP:
      return bleSyncOnResumePendingStop(state);
    case ActionType.BLE_SYNC_ON_RESUME_STOP:
      return bleSyncOnResumeStop(state);
  }
}

function disconnectSuccess(
  state: State, action: Action.Disconnect_Success
): State {
  state = R.over(
    LENSES.connections,
    setConnection(action.provider.key, 'not_connected'),
    state
  );

  return state;
}

function disconnectFromDevices(
  state: State, action: Action.DisconnectFromDevices
): State {
  if (state.userDevices === undefined) {
    return state;
  } else {
    const idsToRemove = action.devices.map(d => d.deviceId);
    const userDevices = R.reject(
      d => R.contains(d.deviceId, idsToRemove),
      state.userDevices
    );
    return { ...state, userDevices };
  }
}

function bleConnectToDevice(
  state: State, action: Action.BLEConnectToDevice
): State {
  const userDevices = R.append(action.userDevice, state.userDevices || []);
  return setDeviceConnectionStatus(
    { ...state, userDevices },
    action.userDevice.deviceTypeId,
    'connected'
  );
}

function bleSyncStart(state: State, action: Action.BLESyncStart): State {
  const syncingDevices = R.uniq(R.append(action.device, state.syncingDevices));
  return { ...state, syncingDevices };
}

function bleSyncStopSuccess(
  state: State, action: Action.BLESyncStop_Success
): State {
  const syncingDevices = R.without([action.device], state.syncingDevices);
  return Edge.markLastSyncTime(
    action.device,
    moment(),
    { ...state, syncingDevices }
  );
}

function bleSyncStopError(
  state: State, action: Action.BLESyncStop_Error
): State {
  const syncingDevices = R.without([action.device], state.syncingDevices);
  return { ...state, syncingDevices };
}

function bleBackgroundSyncStart(state: State): State {
  return { ...state, backgroundSyncInitialized: true };
}

function bleBackgroundSyncStop(state: State): State {
  return { ...state, backgroundSyncInitialized: false };
}

function bleSyncOnResumeStart(state: State): State {
  return { ...state, syncOnResumeInitialized: true };
}

function bleSyncOnResumePendingStop(state: State): State {
  return { ...state, syncOnResumePendingShutdown: true };
}

function bleSyncOnResumeStop(state: State): State {
  return {
    ...state, syncOnResumePendingShutdown: false, syncOnResumeInitialized: false
  };
}

function removeDeviceBegin(
  state: State, action: Action.RemoveDevice_Begin
): State {
  return setDeviceConnectionStatus(state, action.deviceType.id, 'pending');
}

function removeDeviceComplete(
  state: State, action: Action.RemoveDevice_Complete
): State {
  return setDeviceConnectionStatus(
    state, action.deviceType.id, 'not_connected'
  );
}

function setDeviceConnectionStatus(
  state: State, deviceTypeId: Edge.DeviceTypeId, status: Edge.ConnectionStatus
): State {
  return {
    ...state,
    deviceConnectionStatuses: {
      ...state.deviceConnectionStatuses,
      [deviceTypeId]: status
    }
  };
}
