import { compare } from 'Shared/Data/Version';
import { BodyTemp, DeviceVersion, DeviceFamily } from '../Data';
import { chunks, decodeHighByteInBack, decodeMoment } from './Util';

// Command response (can lengthen the command):
// 0x62 ID1 ID2 YY MM DD HH mm SS T1 T2
// YY MM DD HH mm SS: indicates the date of the data, year / month / day / hour
//   / minute / second.
// T1 T2: temperature value, the highest digit is behind, and one decimal point
//   is reserved.
/*
 * Firmware version 1.5.9 introduced a new byte at the end of the above defined
 * sequence, which indicates the "measurement method", which can be automatic or
 * manual.  We are ignoring this, but need to take it into account in order to
 * parse the rest of the data correctly, when using version 1.5.9 or above
 */

export function decode(dv: DeviceVersion): (packet: Uint8Array) => BodyTemp[] {
  return packet => {
    const size = chunkSize(dv);

    return chunks(size, packet).map(chunk => {
      const id = decodeHighByteInBack(chunk.slice(1, 3));
      const time = decodeMoment(chunk.slice(3, 9));
      const bodyTempC = decodeHighByteInBack(chunk.slice(9, 11)) / 10;
      const bodyTempF = round(celsToFaren(bodyTempC), 2);

      return { id, time, bodyTemp: bodyTempF };
    });
  };
}

function celsToFaren(c: number): number {
  return (c * 9/5) + 32;
}

function round(n: number, places: number): number {
  const factor = Math.pow(10, places);
  return Math.round(n * factor) / factor
}

function chunkSize(v: DeviceVersion): number {
  if (v.family === DeviceFamily.JStyle1963YH) {
    return compare(v.version, [1, 5, 9]) < 0 ? 11 : 12;
  } else {
    throw new Error(
      `Unsupported device family for Body Temp: ${JSON.stringify(v)}`
    );
  }
}
