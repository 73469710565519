import * as React from 'react';
import { AuthSettings } from 'Auth/Data';
import Link from 'Shared/UI/Link';

interface Props {
  settings: AuthSettings
  actionText: string
}

const AuthTermsAccept = (props: Props) => {
  const settings = props.settings;

  return (
    <p className="auth__note">
      By {props.actionText} you agree to the<br/>
      <Link to={settings.termsUrl}
            external="_blank">
        Terms of Service
      </Link>
      {' and '}
      <Link to={settings.privacyUrl}
            external="_blank">
        Privacy Policy
      </Link>
    </p>
  );
}

export default AuthTermsAccept;
