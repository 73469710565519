import './IconFontAwesome.scss';
import * as React from 'react';

interface Props {
  name: string,
  style?: Style
  className?: string
  animate?: Animation
}

type Style = 'solid' | 'regular' | 'light' | 'duotone' | 'brands';
type Animation = 'spin' | 'pulse';

export default function renderIcon(
  props: Props
): React.ReactElement<Props> {
  const classes = [
    styleClass(props.style || 'duotone'),
    `fa-${props.name}`,
    props.animate ? `fa-${props.animate}` : '',
    props.className
  ].filter(str => str).join(' ');

  return (
    <i className={classes} />
  );
}

function styleClass(style: Style): string {
  switch(style) {
    case 'solid':
      return 'fas';
    case 'regular':
      return 'far';
    case 'light':
      return 'fal';
    case 'duotone':
      return 'fad';
    case 'brands':
      return 'fab';
  }
}
