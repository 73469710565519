import * as R from 'ramda';
import * as React from 'react';

import * as TZ from 'Shared/Data/TZ';
import { profPicUrl } from 'Shared/ProfPic';
import { Errors, noErrors } from 'Shared/Data/Errors';
import { userGuideUrl } from 'Shared/Urls';
import {
  LENSES, Profile, ProfileLensKey, profileErrors, formatPhone
} from 'User/Data';

import Link from 'Shared/UI/Link';
import AlertBox from 'Shared/UI/AlertBox';
import HorizontalTextField from './HorizontalTextField';
import HorizontalGenericField from './HorizontalGenericField';
import HorizontalRadioField from './HorizontalRadioField';

interface Props {
  onSubmit: (profile: Profile, onError: (msg: string) => void) => void,
  initialProfile: Profile
}


interface State {
  profile: Profile,
  errors: Errors<Profile>
  errorResponse: string
};

class ProfileComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      profile: this.props.initialProfile,
      errors: {},
      errorResponse: ""
    };

    this.fieldChange = this.fieldChange.bind(this);
    this.onError = this.onError.bind(this);
  }

  onSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();

    const errors = profileErrors(this.state.profile);

    this.setState({ errors, errorResponse: '' });

    if (noErrors(errors)) {
      this.props.onSubmit(this.state.profile, this.onError)
    }
  }

  onError = (msg: string) => {
    this.setState({ errorResponse: msg });
  }

  fieldChange(fieldName: ProfileLensKey) {
    return (e: React.FormEvent<any>) => {
      const el = e.target as HTMLInputElement;

      this.setState(
        {
          profile: R.set(
            LENSES.profileField(fieldName),
            el.value,
            this.state.profile
          )
        }
      );

    };
  }

  render() {
    const { profile, errors, errorResponse } = this.state;

    let errorMsg = null;
    if (errorResponse !== "") {
      errorMsg = <AlertBox modifiers="error">{errorResponse}</AlertBox>;
    }

    return (
      <form onSubmit={this.onSubmit} className="profile-form simple-container">
        {errorMsg}

        <HorizontalTextField
          label="Name"
          type="text"
          id="profile_name"
          value={profile.name}
          onChange={this.fieldChange('name')}
          error={errors.name}>
        </HorizontalTextField>

        <HorizontalTextField
          label="Email"
          type="email"
          id="profile_email"
          required
          value={profile.email}
          error={errors.email}
          onChange={this.fieldChange('email')}>
        </HorizontalTextField>

        <HorizontalTextField
          label="Mobile"
          type="tel"
          id="profile_mobile_phone"
          placeholder="(xxx) xxx-xxxx"
          value={formatPhone(profile.mobilePhone)}
          error={errors.mobilePhone}
          onChange={this.fieldChange('mobilePhone')}>
        </HorizontalTextField>

        <HorizontalGenericField label="Photo">
          <div>
            <img
              width="40"
              height="40"
              src={profPicUrl(profile.id)}
              className="profile-form__prof-pic"
            />
            {' '}
            <Link
              to={userGuideUrl('avatar')}
              className="profile-form__change-prof-pic"
              themed={true}
              breadcrumb="push"
            >
              change photo
            </Link>
          </div>
        </HorizontalGenericField>

        <HorizontalRadioField
          label="Gender"
          name="profile-gender"
          value={profile.gender}
          options={['male', 'female']}
          optionLabels={{male: 'Male', female: 'Female'}}
          onChange={this.fieldChange('gender')}/>

        <HorizontalGenericField
          label="Time Zone">
          <select className="select select--block"
            value={profile.tzId}
            onChange={this.fieldChange('tzId')}>
            {tzSelectOptions()}
          </select>
        </HorizontalGenericField>

        <HorizontalGenericField label="">
          <button type="submit"
            className="button button-fixed-width button-gray">
            save profile
          </button>
        </HorizontalGenericField>
      </form>
    );
  }
}

export default ProfileComponent;

function tzSelectOptions(): React.ReactNode {
  return TZ.allZones().map((z: TZ.Zone) => (
    <option value={z.id} key={z.id}>
      {z.label}
    </option>
  ));
}
