import * as R from 'ramda';
import * as React from 'react';

import { FatlineSettings, FatlineAlert, FatlineTransmitter } from '../Data';
import { AppFeatures } from 'User';
import FatlineAlertRow from './FatlineAlert';

interface Props {
  settings: FatlineSettings,
  features: AppFeatures,
  toggleAlert: (alert: FatlineAlert, t: FatlineTransmitter) => void
}

export default function renderFatlineSettingsForm(
  props: Props
): React.ReactElement<Props> {
  const alertRow = renderAlert(props, R.curry(props.toggleAlert));

  return (
    <div className="fatline-settings simple-container">
      <div className="fatline-settings__header">
        <div className="fatline-settings__header-text">
          Notify me when someone:
        </div>
        <div className="fatline-settings__checkboxes">
          <div className="fatline-settings__right-box">
            email
          </div>
          <div className="fatline-settings__right-box">
            text
          </div>
        </div>
      </div>

      <ul className="fatline-settings__list">
        {props.features.inbox ? alertRow('newInboxMessage') : null}
        {alertRow('gotProps')}
        {alertRow('commentPosted')}
        {alertRow('commentReplyPosted')}
      </ul>

      {props.features.appts ? renderApptSettings(alertRow) : null}
    </div>
  );
}

const renderAlert = R.curry(_renderAlert);
function _renderAlert(
  props: Props,
  toggleAlert: (a: FatlineAlert) => (t: FatlineTransmitter) => void,
  alert: FatlineAlert
): React.ReactNode {
  const settings = props.settings[alert];
  return (
    <FatlineAlertRow
      alert={alert}
      settings={settings}
      toggle={toggleAlert(alert)}/>
  );
}

function renderApptSettings(
  renderRow: (s: string) => React.ReactNode
): React.ReactNode {
  return (
    <div>
      <div className="fatline-settings__header">
        <div className="fatline-settings__header-text">
          Send appointment notifications
        </div>
        <div className="fatline-settings__checkboxes">
          <div className="fatline-settings__right-box">
            email
          </div>
          <div className="fatline-settings__right-box">
            text
          </div>
        </div>
      </div>

      <ul className="fatline-settings__list">
        {renderRow('apptConfirmation')}
        {renderRow('apptCancellation')}
        {renderRow('apptReminder')}
      </ul>
    </div>
  );
}
