import * as React from 'react';

const SpecsInstructions = () => {
  return (
    <div>
      <p>
        <strong className="onboard-instructions__why-how">Why? </strong>
        Track and measure progress towards your best health.
      </p>
      <p>
        <strong className="onboard-instructions__why-how">How? </strong>
        Update your measurements any time from the "Post Activity" page - you
        can get started below.
      </p>
    </div>
  );
};

export default SpecsInstructions;
