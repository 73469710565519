import * as GetDailySummaries from './GetDailySummaries';
import * as GetDailyDetails from './GetDailyDetails';
import * as GetVersion from './GetVersion';
import * as GetTime from './GetTime';
import * as GetBattery from './GetBattery';
import * as GetHRData from './GetHRData';
import * as GetHRVData from './GetHRVData';
import * as GetSpO2Data from './GetSpO2Data';
import * as GetBodyTempData from './GetBodyTempData';
import * as GetSleepDetails from './GetSleepDetails';
import * as GetBPData from './GetBPData';
import * as GetAutoMonitoring from './GetAutoMonitoring';
import * as GetBPCalibration from './GetBPCalibration';
import * as DeviceSettings from './DeviceSettings';

export * from './Util';

export {
  GetDailySummaries,
  GetDailyDetails,
  GetVersion,
  GetTime,
  GetBattery,
  GetHRData,
  GetHRVData,
  GetSpO2Data,
  GetBodyTempData,
  GetSleepDetails,
  GetBPData,
  GetAutoMonitoring,
  GetBPCalibration,
  DeviceSettings
}
