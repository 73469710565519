import { GET, ApiClient } from 'Api';

import {
  MyDashboardResponseJSON,
  UserDashboardResponseJSON
} from './JSON/Dashboard';

export interface DashboardAPI {
  getMyDashboard(): Promise<MyDashboardResponseJSON>

  getUserDashboard(userId: string): Promise<UserDashboardResponseJSON>
}

export function makeApi(client: ApiClient): DashboardAPI {
  return {
    getMyDashboard,
    getUserDashboard
  };

  function getMyDashboard(): Promise<MyDashboardResponseJSON> {
    return client.requestJSON(GET, { version: 3, path: '/dashboard/me' });
  }

  function getUserDashboard(
    userId: string
  ): Promise<UserDashboardResponseJSON> {
    return client.requestJSON(
      GET, { version: 3, path: `/dashboard/${userId}` }
    );
  }
}
