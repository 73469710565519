import * as React from 'react';
import { DeviceFormFactor } from 'Edge/Data'
import FAIcon from 'Shared/UI/IconFontAwesome';

interface Props {
  formFactor: DeviceFormFactor,
  className?: string
}

const FormFactorIcon = (props: Props) => {
  return (
    <FAIcon
      name={iconName(props.formFactor)}
      style="light"
      className={props.className} />
  )
}

export default FormFactorIcon;

function iconName(ff: DeviceFormFactor): string {
  switch(ff) {
    case 'scale':
      return 'weight';
    case 'bp_cuff':
      return 'heartbeat';
    case 'glucometer':
      return 'first-aid';
    case 'watch':
    case 'other':
      return 'watch';
  }
}
