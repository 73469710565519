import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { defaultConnect } from 'Shared/ReduxComponent';
import { State, StoreDispatch } from 'Store';
import * as Urls from 'Shared/Urls';
import { NavBarLayout } from 'Nav';
import { ActionCreators } from 'Post/ActionCreator';
import ActivityFeed from './ActivityFeed';

type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch
}

interface URLParams {
  userId: string
}

class UserActivityFeed extends React.Component<Props, {}> {
  userId() {
    return this.props.match.params.userId;
  }

  loadPage = (page: number) => {
    this.props.dispatch(ActionCreators.loadUserFeed(this.userId(), page));
  }

  render() {
    const uid = this.userId();
    return (
      <NavBarLayout
        title="Community Feed"
        initBreadcrumb={Urls.crewMemberDashboardUrl(uid)}
      >
        <ActivityFeed
          feedType="user"
          showUser={true}
          loadPage={this.loadPage}
          {...this.props}
        />
      </NavBarLayout>
    );
  }
}

export default defaultConnect(withRouter(UserActivityFeed));
