import * as React from 'react';

type FieldValue = { value: string, label: string };

interface Props {
  data: FieldValue[]
}

export default function renderValueTable(
  props: Props
): React.ReactElement<Props> {
  if (props.data.length === 0) {
    return <span></span>;
  } else {
    return (
      <div className="row">
        <table className="small-12 medium-8 medium-offset-2 columns">
          <thead>
            <tr>
              <td></td>
              <td className="text-center">Value</td>
            </tr>
          </thead>
          <tbody>
            {props.data.map(renderRow)}
          </tbody>
        </table>
      </div>
    );
  }
}

function renderRow(row: FieldValue): React.ReactNode {
  return (
    <tr key={row.label}>
      <td>{row.label}</td>
      <td className="text-center">{row.value || '--'}</td>
    </tr>
  );
}
