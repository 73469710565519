import * as React from 'react';
import { bem } from 'css-util';
import { validEmail } from 'Shared/Data/Email';
import * as Maybe from 'misc/Data/Maybe';
import { SignupSubmission } from 'Auth/Data';

import AuthFormTextField from 'Auth/UI/AuthFormTextField';
import AuthFormRememberMe from 'Auth/UI/AuthFormRememberMe';
import AuthFormSubmit from 'Auth/UI/AuthFormSubmit';
import AuthFormErrors from 'Auth/UI/AuthFormErrors';

interface Props {
  onSignup: (creds: SignupSubmission) => Promise<Maybe.Maybe<string>>
}

interface PossibleError { error?: string }

type State = SignupSubmission & PossibleError

const CSS = bem('auth-form');

class SignupForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { name: '', email: '', password: '', rememberMe: false };
  }

  onNameChange = (name: string) => this.setState({ name })

  onEmailChange = (email: string) => this.setState({ email })

  onPasswordChange = (password: string) => this.setState({ password })

  onRememberMeChange = (rememberMe: boolean) => this.setState({ rememberMe })

  isValid(): boolean {
    return this.state.email !== '' &&
           this.state.password !== '' &&
           this.state.name !== '' &&
           validEmail(this.state.email);
  }

  onSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();
    this.setState({ error: undefined });
    if (this.isValid()) {
      this.props.onSignup(this.state).then(
        Maybe.exec(error => this.setState({ error }))
      );
    } else {
      this.setState({ error: 'Please fill out all of the fields' });
    }
  }

  render() {
    return (
      <form className={CSS()()} onSubmit={this.onSubmit}>
        <AuthFormErrors error={this.state.error} />
        <AuthFormTextField
          type="text"
          icon="user"
          placeholder="name"
          value={this.state.name}
          onChange={this.onNameChange} />
        <AuthFormTextField
          type="email"
          icon="email4"
          placeholder="email"
          value={this.state.email}
          onChange={this.onEmailChange} />
        <AuthFormTextField
          type="password"
          icon="lock"
          placeholder="password"
          value={this.state.password}
          onChange={this.onPasswordChange} />
        <AuthFormRememberMe
          checked={this.state.rememberMe}
          onChange={this.onRememberMeChange} />
        <AuthFormSubmit>Sign up</AuthFormSubmit>
      </form>
    )
  }
}

export default SignupForm;
