import * as R from 'ramda';
import { capitalize } from 'Shared/Data/String';

export enum DayOfWeek {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY
}

const ALL: DayOfWeek[] = [
  DayOfWeek.SUNDAY,
  DayOfWeek.MONDAY,
  DayOfWeek.TUESDAY,
  DayOfWeek.WEDNESDAY,
  DayOfWeek.THURSDAY,
  DayOfWeek.FRIDAY,
  DayOfWeek.SATURDAY
]

export function all(startOn = DayOfWeek.SUNDAY): DayOfWeek[] {
  const [p1, p2] = R.splitAt(R.indexOf(startOn, ALL), ALL);
  return R.concat(p2, p1)
}

export function name(d: DayOfWeek): string {
  return capitalize(DayOfWeek[d]);
}

export function initial(d: DayOfWeek): string {
  return name(d)[0];
}
