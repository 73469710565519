import * as React from 'react';
import * as R from 'ramda';
import * as moment from 'moment-timezone';
import * as classnames from 'classnames';
import { bem } from 'css-util';
import NoContent from 'misc/UI/NoContent';
import * as List from 'misc/Data/List';
import * as Data from 'Challenges/Data';
import { ChallengeDetails, TaskChallengeDaySummary } from 'Challenges/Data';

import StripedTable from 'Shared/UI/StripedTable';

interface Props {
  summaries: TaskChallengeDaySummary[],
  challenge: ChallengeDetails
}

const CSS = bem('task-challenge-summary');

const TaskChallengeSummaryTable = (props: Props) => {
  const summaries = rejectFutureSummaries(props.summaries);
  const first = List.head(summaries);
  if (first) {
    const categoryNames = R.keys(first.categoryPoints).map(s => s.toString());
    return (
      <StripedTable
        className={CSS()()}
        headers={['Date', ...categoryNames, 'Total']}
        rows={
          summaries.map(summary => ([
            summary.date.format('MMM D, YYYY'),
            ...categoryNames.map(categoryName =>
              summary.categoryPoints[categoryName] || 0
            ),
            Data.daySummaryTotalPoints(summary)
          ]))
        }
        headerClasses={[
          CSS('header')(),
          ...R.repeat(CSS('header')(), categoryNames.length),
          CSS('total')()
        ]}
        rowClasses={[
          classnames(CSS('row-header')(), CSS('date')()),
          ...R.repeat(CSS('points')(), categoryNames.length),
          CSS('total')()
        ]}
        tfoot={renderTfoot(summaries, categoryNames)}
      />
    );
  } else {
    return <NoContent>No data to display</NoContent>;
  }
}

export default TaskChallengeSummaryTable;



function rejectFutureSummaries(
  summaries: TaskChallengeDaySummary[]
): TaskChallengeDaySummary[] {
  return R.reject(s => s.date.isAfter(moment(), 'day'), summaries);
}

function renderTfoot(
  summaries: TaskChallengeDaySummary[],
  categoryNames: string[]
): React.ReactNode {
  const totals = categoryNames.map(cat =>
    R.sum(summaries.map(s => s.categoryPoints[cat] || 0))
  );
  return (
    <tfoot className={CSS('footer')()}>
      <tr>
        <td className={CSS('row-header')()}>Total</td>
        {totals.map((total, i) =>
          <td key={i}
            className={classnames('striped-table__td', CSS('points')())}>
            {total}
          </td>
        )}
        <td className={CSS('total')()}>{R.sum(totals)}</td>
      </tr>
    </tfoot>
  );
}
