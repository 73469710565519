import * as React from 'react';
import DashboardIcon from 'Dashboard/UI/Icon';

interface Props {
  iconName: string,
  children?: React.ReactNode
}

export default function renderNoContent(
  props: Props
): React.ReactElement<Props> {
  return (
    <div className="simple-dashboard-tile__main-text">
      <div className="dashboard-tile__left">
        <div className="dashboard-tile__left-content">
          <div className="dashboard-tile__icon">
            <DashboardIcon name={props.iconName} />
          </div>
          <div className="dashboard-tile__no-content">
            <p className="no-content">
              {props.children}
            </p>
          </div>
          <div className="dashboard-tile__right">
          </div>
        </div>
      </div>
    </div>
  );
}
