import { compare } from 'Shared/Data/Version';
import { SpO2, DeviceVersion, DeviceFamily } from '../Data';
import { chunks, decodeHighByteInBack, decodeMoment } from './Util';

// Command response (can lengthen the command):
// 0x60 ID1 ID2 YY MM DD HH mm SS HH
// The minimum unit is 10 bytes, the total number of pieces of total data
// contained in a BLE packet can be dynamically controlled according to the
// actual maximum length of the transmitted packet.
//
// ID1 ID2: Read the data number, the high byte is in the back.
// YY MM DD HH mm SS: indicates the date of the data, year / month / day / hour
//   / minute / second.
// HH: blood oxygen value.
/*
 * Firmware version 1.5.9 introduced a new byte at the end of the above defined
 * sequence, which indicates the "measurement method", which can be automatic or
 * manual.  We are ignoring this, but need to take it into account in order to
 * parse the rest of the data correctly, when using version 1.5.9 or above
 */
export function decode(dv: DeviceVersion): (packet: Uint8Array) => SpO2[] {
  return packet => {
    const size = chunkSize(dv);

    return chunks(size, packet).map(chunk => {
      const id = decodeHighByteInBack(chunk.slice(1, 3));
      const time = decodeMoment(chunk.slice(3, 9));
      const spo2 = chunk[9];

      return { id, time, spo2 };
    });
  };
}

function chunkSize(v: DeviceVersion): number {
  if (v.family === DeviceFamily.JStyle1963YH) {
    return compare(v.version, [1, 5, 9]) < 0 ? 10 : 11;
  } else {
    throw new Error(`unsupported device family for SpO2: ${JSON.stringify(v)}`);
  }
}
