import * as R from 'ramda';
import * as moment from 'moment-timezone';
import { Lens, Prism } from '@atomic-object/lenses';
import { Gender } from 'Specs';
import { IDMap, newIDMap, idMapLookup } from 'Shared/Data/IDMap';
import { HTML } from 'Shared/Data/HTML';
import { Binary } from 'Shared/Data/Binary';
import { RiskLevel } from 'Risk/Data';
import {
  Loadable, loadable, isLoaded, loadableMap
} from 'misc/Data/Loadable';

/*------------------------------------------------------------*/

export type NSType = 'SCREENINGS';
export const NS: NSType = 'SCREENINGS';

/*------------------------------------------------------------*/

export interface State {
  reportList: ReportListItem[] | undefined,
  reports: IDMap<Report>,
  screen: Loadable<Screen>,
  currentStepIndex: number,
  thankYouContent: HTML
}

/*------------------------------------------------------------*/
export type RecordId = string

/*------------------------------------------------------------*/
/* Screen reports */

export interface ReportListItem {
  id: RecordId
  date: moment.Moment
}

export interface Report {
  title: string,
  date: moment.Moment
  birthday: moment.Moment | undefined
  name: string | undefined
  gender: Gender | undefined
  infoContent: HTML
  signature: Binary | undefined
  wellnessGoal: string | undefined
  historicalTable: HistoricalTableRow[]
  riskCharts: RiskChart[],
  riskFactors: RiskFactor[],
  overallRiskLevel: RiskLevel | undefined,
  survey: SurveyResponse[]
}

export interface SurveyResponse {
  text: string
  options: SurveyResponseOption[]
}

export interface SurveyResponseOption {
  label: string
  selected: boolean
  color: string
}

export interface RiskFactor {
  title: string
  riskLevel: RiskLevel
}

export interface HistoricalTableRow {
  date: moment.Moment,
  weight: number | undefined,
  height: number | undefined
}

export interface RiskChart {
  title: string
  range: RiskChartRange
  data: RiskChartDatum[]
}

export interface RiskChartRange {
  best: any
  worst: any
  bestLabel: string
  worstLabel: string
}

export interface RiskChartDatum {
  date: moment.Moment,
  value: number | undefined
  valueLabel: string
}

/*------------------------------------------------------------*/
/* Screen form/definition */

export interface Screen {
  steps: ScreenStep[]
  data: ScreenData,
  errors: string[]
}

interface ScreenData {
  [key: string]: FieldValue
}

export type FieldValue = string | string[] | undefined;

type ScreenFieldKey = string
export type ScreenStep = ScreenField[]

export interface ScreenField {
  key: ScreenFieldKey
  label: string
  hint: string | undefined
  options: ScreenFieldOption[]
  group: ScreenFieldGroup
  type: ScreenFieldType
  conditions: FieldCondition[]
}

export interface ScreenFieldOption {
  label: string
  value: string
}

export enum ScreenFieldGroup {
  PROFILE = 'profile',
  SPEC = 'spec',
  SURVEY = 'survey',
  META = 'meta',
  ANON = 'anon'
}

export enum ScreenFieldType {
  TEXT = 'text',
  DATE = 'date',
  OPTION = 'option',
  BOOLEAN = 'boolean',
  HTML = 'html',
  OPTION_ARRAY = 'option_array'
}

export enum FieldConditionType {
  IS = 'answer_is',
  IS_NOT = 'answer_is_not',
  GT = 'answer_is_gt',
  GTE = 'answer_is_gte',
  LT = 'answer_is_lt',
  LTE = 'answer_is_lte',
  EXISTS = 'answer_exists',
  NOT_EXISTS = 'answer_not_exists'
}

export interface ScreenFieldConditionWithValue {
  type: FieldConditionType.GT
    | FieldConditionType.GTE
    | FieldConditionType.LT
    | FieldConditionType.LTE
    | FieldConditionType.IS
    | FieldConditionType.IS_NOT
  key: ScreenFieldKey
  value: string
}

export interface ScreenFieldConditionWithoutValue {
  type: FieldConditionType.EXISTS
    | FieldConditionType.NOT_EXISTS
  key: ScreenFieldKey
}

export type FieldCondition
  = ScreenFieldConditionWithValue
  | ScreenFieldConditionWithoutValue;

/*------------------------------------------------------------*/

export function initialState(): State {
  return {
    reportList: undefined,
    reports: newIDMap(),
    screen: loadable(),
    currentStepIndex: 0,
    thankYouContent: ''
  };
}

/*------------------------------------------------------------*/
// Working w/ screens

export function totalSteps(screen: Screen): number {
  return screen.steps.length;
}

export function nextStepIndex(state: State): number {
  const screen = state.screen.value;
  if (screen && state.currentStepIndex < totalSteps(screen)) {
    return state.currentStepIndex + 1;
  } else {
    return state.currentStepIndex;
  }
}

export function previousStepIndex(state: State): number {
  const screen = state.screen.value;
  if (screen && state.currentStepIndex > 0) {
    return state.currentStepIndex - 1;
  } else {
    return state.currentStepIndex;
  }
}

export function screeningIsDone(state: State): boolean {
  const screen = state.screen;
  if (
    isLoaded(screen) &&
      screen.value &&
      state.currentStepIndex >= screen.value.steps.length
  ) {
    return true;
  } else {
    return false;
  }
}

export function currentStepFields(state: State): ScreenStep {
  const screen = state.screen;
  return isLoaded(screen) ? getStep(screen.value, state.currentStepIndex) : [];
}

export function percentProgress(
  screen: Screen, currentStepIndex: number
): number {
  var percent = parseFloat((currentStepIndex / totalSteps(screen)).toFixed(2));
  if (isNaN(percent)) {
    return 1;
  } else {
    return percent;
  }
}

export function estimatedMinutes(screen: Screen): number {
  const exactMinutes = R.flatten(screen.steps).length * 0.333333;
  const nearest5 = Math.round(exactMinutes / 5) * 5;
  return Math.max(nearest5, 5);
}

export function fieldValue(data: ScreenData, fieldKey: string): FieldValue {
  return data[fieldKey];
}

export function getStep(screen: Screen, step: number): ScreenStep {
  const fields = screen.steps[step] || [];
  return R.filter(
    field => conditionsMet(screen.data, field.conditions),
    fields
  );
}

export function addErrorsOnCurrentStep(state: State): State {
  if (isLoaded(state.screen)) {
    const errors = errorsOnStep(state.screen.value, state.currentStepIndex);
    return LENSES.screeningErrors.set(state, errors);
  } else {
    return state;
  }
}

export function errorsOnStep(screen: Screen, step: number): string[] {
  const fields = getStep(screen, step);
  if (missingRequiredFields(fields, screen.data)) {
    return ['All fields are required'];
  } else if (hasInvalidDates(fields, screen.data)) {
    return ['Date must be formatted YYYY-MM-DD'];
  } else {
    return [];
  }
}

function missingRequiredFields(
  fields: ScreenField[], data: ScreenData
): boolean {
  return R.any(
    field => responseRequired(field) && data[field.key] === undefined,
    fields
  );
}

function responseRequired(field: ScreenField): boolean {
  return field.type !== ScreenFieldType.HTML;
}

function hasInvalidDates(fields: ScreenField[], data: ScreenData): boolean {
  return R.any(
    field => {
      const value = data[field.key];
      return (
        field.type === 'date' &&
          typeof value === 'string' &&
          !value.match(/\d{4}-\d{1,2}-\d{1,2}/)
      );
    },
    fields
  );
}

/*
 * Test if a field value "is" the given value.  For normal values this just
 * checkes equality, but for "array" values, such as a multiple option select
 * question, it checks if the field value "includes" the given value, vs
 * checking for an exact match.
 */
export function fieldValueIs(fieldValue: FieldValue, aValue: string): boolean {
  if (Array.isArray(fieldValue)) {
    return R.contains(aValue, fieldValue);
  } else {
    return fieldValue === aValue;
  }
}

export function isArrayType(fieldType: ScreenFieldType): boolean {
  return fieldType === ScreenFieldType.OPTION_ARRAY;
}

/**
 * Confusingly worded method either inserts or removes the "valueToToggle" from
 * the "fieldValue", which is assumed to be an array value (string[]). Most of
 * what goes down in this method is just satisfying the type checker, which
 * doesn't know for sure that the value is actually an string[] value
 */
export function toggleValueInArrayFieldValue(
  fieldValue: FieldValue, valueToToggle: FieldValue
): FieldValue {
  if (valueToToggle === undefined) { return fieldValue; }
  if (fieldValue === undefined) {
    return Array.isArray(valueToToggle) ? valueToToggle : [valueToToggle];
  }

  const fieldValueAsArray =
    Array.isArray(fieldValue) ? fieldValue : [fieldValue];
  const valueAsString =
    Array.isArray(valueToToggle) ? valueToToggle[0] : valueToToggle;
  if (valueAsString === undefined) { return fieldValueAsArray; }

  if (R.contains(valueAsString, fieldValueAsArray)) {
    return R.without([valueAsString], fieldValueAsArray);
  } else {
    return R.uniq(R.append(valueAsString, fieldValueAsArray));
  }
}

/*------------------------------------------------------------*/
// Field conditions

function conditionsMet(data: ScreenData): (cs: FieldCondition[]) => boolean;
function conditionsMet(data: ScreenData, cs: FieldCondition[]): boolean;
function conditionsMet(data: ScreenData, cs?: FieldCondition[]) {
  if (cs !== undefined) {
    return conditionsMet(data)(cs);
  } else {
    return (conditions: FieldCondition[]) => {
      if (conditions.length === 0) {
        return true;
      } else {
        return R.all(conditionMet(data), conditions);
      }
    };
  }
}

function conditionMet(data: ScreenData, c: FieldCondition): boolean;
function conditionMet(data: ScreenData): (c: FieldCondition) => boolean;
function conditionMet(
  data: ScreenData, c?: FieldCondition
): boolean | ((c: FieldCondition) => boolean)  {
  if (c !== undefined) {
    return conditionMet(data)(c);
  } else {
    return (condition: FieldCondition) => {
      let currentVal;
      switch(condition.type) {
      case FieldConditionType.IS:
        return fieldValueIs(data[condition.key], condition.value);
      case FieldConditionType.IS_NOT:
        return !fieldValueIs(data[condition.key], condition.value);
      case FieldConditionType.GT:
        currentVal = data[condition.key];
        return !!currentVal && typeof(currentVal) === 'string' &&
            parseFloat(currentVal) > parseFloat(condition.value);
      case FieldConditionType.GTE:
        currentVal = data[condition.key];
        return !!currentVal && typeof(currentVal) === 'string' &&
          parseFloat(currentVal) >= parseFloat(condition.value);
      case FieldConditionType.LT:
        currentVal = data[condition.key];
        return !!currentVal && typeof(currentVal) === 'string' &&
          parseFloat(currentVal) < parseFloat(condition.value);
      case FieldConditionType.LTE:
        currentVal = data[condition.key];
        return !!currentVal && typeof(currentVal) === 'string' &&
          parseFloat(currentVal) <= parseFloat(condition.value);
      case FieldConditionType.EXISTS:
        return fieldValueExists(data[condition.key]);
      case FieldConditionType.NOT_EXISTS:
        return !fieldValueExists(data[condition.key]);
      }
    };
  }
}

function fieldValueExists(value: any): boolean {
  return !R.isEmpty(value) && !R.isNil(value);
}

/*------------------------------------------------------------*/

interface Lenses {
  reportList: Prism<State, ReportListItem[]>

  fieldValue(fieldKey: ScreenFieldKey): Prism<State, FieldValue>

  screeningErrors: Lens<State, string[]>
}

function makeLenses(): Lenses {
  return {
    screeningErrors: Lens.of<State, string[]>({
      get(s: State) {
        return isLoaded(s.screen) ? s.screen.value.errors : [];
      },
      set(s: State, errors: string[]) {
        if (isLoaded(s.screen)) {
          return {
              ...s,
            screen: loadableMap(screen => ({ ...screen, errors }), s.screen)
          }
        } else {
          return s;
        }
      }
    }),
    reportList: Lens.from<State>().prop('reportList'),
    fieldValue(key: ScreenFieldKey): Prism<State, FieldValue> {
      return Prism.of<State, FieldValue>({
        get(s: State) {
          if (isLoaded(s.screen)) {
            return s.screen.value.data[key];
          }
        },
        set(s: State, value: FieldValue) {
          return {
              ...s,
            screen: loadableMap(
              screen => ({
                  ...screen,
                data: { ...screen.data, [key]: value }
              }),
              s.screen
            )
          };
        }
      });
    }
  };
}

export const LENSES: Lenses = makeLenses();

export const SEL = {
  reportList(state: State): ReportListItem[] | undefined {
    return state.reportList;
  },

  report(recordId: RecordId, state: State): Report | undefined {
    return idMapLookup(recordId, state.reports);
  },

  screen(state: State): Loadable<Screen | undefined> {
    return state.screen;
  },

  screenData(state: State): Loadable<ScreenData | undefined> {
    return loadableMap(screen => screen.data, state.screen);
  },

  errors(state: State): string[] {
    return LENSES.screeningErrors.get(state);
  },

  thankYouContent(state: State): HTML {
    return state.thankYouContent;
  }
};
