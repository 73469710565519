import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { State, StoreDispatch } from 'Store';
import { NavBarLayout } from 'Nav';
import * as Urls from 'Shared/Urls';
import { alert } from 'Shared/Dialog';
import { HUD } from 'HUD';
import { hudState } from 'App/Data';
import { ActionCreators } from 'Contact/ActionCreator';

type Props = State & {
  dispatch: StoreDispatch
}

interface FormState {
  text: string
}

class ContactPage extends React.Component<Props, FormState> {
  state = { text: '' }

  onSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();
    if (this.state.text !== '') {
      this.props.dispatch(
        ActionCreators.submitContactForm(
          this.state.text, () => this.setState({ text: '' })
        )
      );
    } else {
      alert('Please enter a message before submitting', 'Contact');
    }
  }

  onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ text: e.target.value });
  }

  render() {
    return (
      <NavBarLayout title="Contact" initBreadcrumb={Urls.dashboardUrl()}>
        <div className="simple-container">
          <p>Questions, requests, general inquiries? Get in touch:</p>

          <form noValidate onSubmit={this.onSubmit}>
            <div className="mb-1r">
              <textarea
                required
                rows={5}
                className="text-area"
                value={this.state.text}
                onChange={this.onTextChange}>
              </textarea>
            </div>

            <div>
              <input
                type="submit"
                className="button button--no-margin theme__primary-bg"
                value="submit"
              />
            </div>
          </form>
        </div>
        <HUD state={hudState(this.props)} />
      </NavBarLayout>
    );
  }
}

export default defaultConnect(ContactPage);
