import { ApiClient, GET } from 'Api';
import { OnboardConfigResponseJSON } from './JSON/Onboard';

export interface OnboardAPI {
  getConfig(): Promise<OnboardConfigResponseJSON>
}

export function makeApi(client: ApiClient): OnboardAPI {
  return {
    getConfig
  };

  function getConfig(): Promise<OnboardConfigResponseJSON> {
    return client.requestJSON(
      GET, { path: '/onboard_configs', version: 3 }
    );
  }
}
