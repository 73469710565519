import * as R from 'ramda';

export function addRoot(
  root: string | undefined,
  path: string[] | R.Lens
): R.Lens {
  if (root) {
    if (pathIsLens(path)) {
      return R.compose(R.lensProp(root), path) as R.Lens;
    } else {
      return R.lensPath(R.prepend(root, path));
    }
  } else {
    if (pathIsLens(path)) {
      return path;
    } else {
      return R.lensPath(path);
    }
  }

  function pathIsLens(p: string[] | R.Lens): p is R.Lens {
    return typeof p === 'function';
  }
}
