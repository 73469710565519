import * as React from 'react';

import * as Data from 'misc/UI/DataTable/Data';
import * as classnames from 'classnames';
import Pager from 'misc/UI/Pager/Pager';
import { Paging } from 'misc/Data/Paging';
import { bem } from 'css-util';
import 'misc/UI/DataTable/datatable.scss';

const CELL = bem('data-table')('cell');

interface Props {
  currentSortDir: Data.SortDir
  currentSortKey: string | null
  setSort: (key: string, dir: Data.SortDir) => void
  columns: Data.ColumnData[]
  cells: React.ReactElement[][]
  paging: Paging | undefined
  gotoPage: (page: number) => void
}

const DataTable = (props: Props): React.ReactElement<Props> => {
  const {
    cells,
    columns,
    currentSortKey,
    currentSortDir,
    setSort,
    paging,
    gotoPage
  } = props;

  return (
    <div>
      <table className="table data-table">
        {renderColumnHeaders(columns, currentSortKey, currentSortDir, setSort)}
        <tbody>
          {cells.map((row, i) => {
            return renderRow(row, i);
          })}
        </tbody>
      </table>
      {paging && showPaging(paging) &&
        <Pager
          paging={paging}
          gotoPage={gotoPage} />
      }
    </div>
  );
}

export default DataTable;

function renderColumnHeaders(
  columns: Data.ColumnData[],
  currentSortKey: string | null,
  currentSortDir: Data.SortDir,
  setSort: (key: string, dir: Data.SortDir) => void
): React.ReactElement {
  return (
    <thead>
      <tr>
        {columns.map((cell, i) => {
          return columnHeader(cell, i, currentSortKey, currentSortDir, setSort)
        })}
      </tr>
    </thead>
  )
}

function columnHeader(
  column: Data.ColumnData,
  i: number,
  currentSortKey: string | null,
  currentSortDir: Data.SortDir,
  setSort: (key: string, dir: Data.SortDir) => void
): React.ReactElement  {
  const { key, label } = column;
  const isSorting = (currentSortKey === key);

  const classes =
    classnames(
      fieldClasses(key),
      'data-table__header',
      {
        [`data-table__header--sorting-${currentSortDir}`]: isSorting,
        ['data-table__header--sortable']: column.isSortable
      }
    )

  return (
    <th key={i} className={classes}>
      {column.isSortable ?
        sortLink(column, currentSortDir, isSorting, setSort) : textSpan(label)
      }
    </th>
  );
}

function sortLink(
  column: Data.ColumnData,
  currentSortDir: Data.SortDir,
  isSorting: boolean,
  setSort: (key: string, dir: Data.SortDir) => void
): React.ReactElement {
  const { key, label, defaultSortDir } = column;
  const sortDir = isSorting ? flipSortDir(currentSortDir) : defaultSortDir;
  const handler = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    setSort(key, sortDir);
  };
  return (
    <a onClick={handler} href="#" className="data-table__header-link">
      <div className="data-table__header-link-label-container">
        <span className="data-table__header-link-label">{label}</span>
      </div>
    </a>
  );
}

function textSpan(label: string): React.ReactElement {
  return (
    <span className="data-table__header-text">
      <div className="data-table__header-link-label-container">
        <span className="data-table__header-link-label">{label}</span>
      </div>
    </span>
  )
}

function renderRow(rowCells: React.ReactNode[], i: number): React.ReactNode {
  return (
    <tr key={i}>{rowCells.map((cell, i) => <td key={i}>{cell}</td>)}</tr>
  )
}

function flipSortDir(dir: Data.SortDir): Data.SortDir {
  switch(dir) {
    case 'asc':
      return 'desc';
    case 'desc':
      return 'asc';
  }
}

function showPaging(paging: Paging): boolean {
  return (!!paging.nextPage || !!paging.previousPage);
}

function fieldClasses(fieldKey: string): string {
  const key = fieldKey.replace('_', '-');

  return CELL([key]);
}
