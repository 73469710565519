import { CUSTOM_BUILD } from 'Env';
import { Loadable, loadable } from 'misc/Data/Loadable';
import { HTML } from 'Shared/Data/HTML';

/*------------------------------------------------------------*/

export type NSType = 'AUTH';
export const NS: NSType = 'AUTH';

/*------------------------------------------------------------*/

export const DEFAULT_PROGRAM_SLUG = CUSTOM_BUILD.CANONICAL_PROGRAM_SLUG;

export interface State {
  token: string | undefined,
  settings: Loadable<AuthSettings>,
  /**
   * Used for loading Auth Settings.  This is separate from the "actual" program
   * slug stored on the user/program data because it may be needed even when the
   * user is logged out.  We attempt to store/load this from local storage when
   * we don't have definitive data about a logged in user.
   */
  programSlug?: string
}

export interface AuthSettings {
  helpHtml?: HTML
  logoUrl: string
  backgroundImageUrl: string | undefined
  programName: string
  programSlug: string
  publicSignup: boolean
  tagline?: string
  nativeApps: NativeAppLink[],
  termsUrl: string,
  privacyUrl: string
}

export interface NativeAppLink {
  platform: NativeAppPlatform
  downloadUrl: string
}

export enum NativeAppPlatform {
  IOS = 'IOS',
  ANDROID = 'ANDROID'
}

export interface LoginSubmission {
  email: string,
  password: string,
  rememberMe: boolean
}

export interface SignupSubmission {
  name: string
  email: string
  password: string
  rememberMe: boolean
}

export function initialState(): State {
  return {
    token: undefined,
    settings: loadable(),
    programSlug: DEFAULT_PROGRAM_SLUG
  };
}

export function isLoggedIn(state: State): boolean {
  return state.token !== undefined;
}
