import * as React from 'react';
import { bem } from 'css-util';
import { Props as FieldProps } from './FieldProps';
import {
  ScreenFieldType, ScreenFieldOption, fieldValueIs
} from 'Screenings/Data';

const FIELD_CSS = bem('screening-field');
const OPTION_FIELD_CSS = bem('screening-option-field');

type Props = FieldProps & {
  inline?: boolean
}

const OptionField = (props: Props) => {
  const modifiers = cssModifiers(props.inline);

  return (
    <div className={FIELD_CSS()()}>
      <div className={FIELD_CSS('label')(modifiers)}>
        <label>
          {renderFieldLabel(props)}
        </label>
      </div>

      <div className={FIELD_CSS('field')(modifiers)}>
        {renderOptions(props)}
      </div>
    </div>
  );
};

export default OptionField;

function cssModifiers(inline: undefined | boolean): string[] {
  return [inline ? 'inline' : 'survey'];
}

function renderFieldLabel(props: Props) {
  if (props.inline) {
    return <label>{props.field.label}</label>;
  } else {
    return <p>{props.field.label}</p>;
  }
}

function renderOptions(props: Props) {
  const limit = props.inline ? 2 : 20;
  if (props.field.type === ScreenFieldType.OPTION_ARRAY) {
    return renderOptionsAsCheckboxes(props);
  } else if (props.field.options.length > limit) {
    return renderOptionsAsSelect(props);
  } else {
    return renderOptionsAsRadios(props);
  }
}

function renderOptionsAsCheckboxes(props: Props) {
  return (
    <ul className={OPTION_FIELD_CSS('option-list')(['survey'])}>
      {props.field.options.map(opt => renderOptionAsCheckbox(props, opt))}
    </ul>
  );
}

function renderOptionAsCheckbox(props: Props, option: ScreenFieldOption) {
  const isSelected = fieldValueIs(props.value, option.value);
  const onClick = onClickOptionListItem(props, option.value);
  const liClasses =
    OPTION_FIELD_CSS('option-list-item')(cssModifiers(props.inline));
  return (
    <li key={option.value} className={liClasses} onClick={onClick}>
      <span
        className={OPTION_FIELD_CSS('checkbox')({ selected: isSelected })}
      />
      <span className={OPTION_FIELD_CSS('checkbox-label')()}>
        {option.label}
      </span>
    </li>
  );
}

function renderOptionsAsRadios(props: Props) {
  return (
    <ul className={OPTION_FIELD_CSS('option-list')(cssModifiers(props.inline))}>
      {props.field.options.map(opt => renderOptionRadio(props, opt))}
    </ul>
  );
}

function renderOptionRadio(props: Props, option: ScreenFieldOption) {
  const isSelected = props.value === option.value;
  const onClick = onClickOptionListItem(props, option.value);
  const liClasses =
    OPTION_FIELD_CSS('option-list-item')(cssModifiers(props.inline));
  return (
    <li key={option.value} className={liClasses} onClick={onClick}>
      <span className={OPTION_FIELD_CSS('radio')({ selected: isSelected })} />
      <span className={OPTION_FIELD_CSS('radio-label')()}>
        {option.label}
      </span>
    </li>
  );
}

function onClickOptionListItem(props: Props, optionValue: string) {
  return () => {
    props.onChange(props.field, optionValue);
  };
}

function renderOptionsAsSelect(props: Props) {
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.onChange(props.field, e.target.value);
  };
  return (
    <select
      value={props.value} onChange={onChange} className="select select--block">
      <option></option>
      {props.field.options.map(option => (
         <option key={option.value} value={option.value}>
           {option.label}
         </option>
       ))}
    </select>
  );
}
