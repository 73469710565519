import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { Loadable, loadable, isLoaded } from 'misc/Data/Loadable';
import Spinner from 'Shared/UI/Spinner';
import * as Urls from 'Shared/Urls';
import { State, StoreDispatch } from 'Store';
import {
  UI as UserUI,
  ActionCreators as UserAC,
  MinimalUser,
  SEL as UserSEL,
  ScreeningStatus, UserId,
  AppFeatureKey, AppFeatures,
  withLoadableFeatures
} from 'User';
import { ActionCreators } from 'Dashboard/ActionCreator';
import {
  ChallengeInvitation, SEL as ChallengesSEL
} from 'Challenges';
import { ActionCreators as EdgeAC } from 'Edge';

import { NavBarLayout, ActionCreators as NavAC } from 'Nav';
import { HUD } from 'HUD';

import { MyDashboard } from 'Dashboard/Data';

import Notifications from 'Dashboard/UI/Notifications';
import ChallengesTile from 'Dashboard/UI/ChallengesTile';
import GoalsTile from 'Dashboard/UI/GoalsTile';
import ScreeningsTile from 'Dashboard/UI/ScreeningsTile';
import PostTile from 'Dashboard/UI/PostTile';
import TrendsTile from 'Dashboard/UI/TrendsTile';
import HealthScoreTile from 'Dashboard/UI/HealthScoreTile';
import RewardsTile from 'Dashboard/UI/RewardsTile';
import CarePlanTile from 'Dashboard/UI/CarePlanTile';
import DeviceTiles from 'Dashboard/UI/DeviceTiles';

type Props = State & { dispatch: StoreDispatch }

class DashboardPage extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(UserAC.loadCurrentUser());
    this.props.dispatch(EdgeAC.loadConfiguration());
    this.loadDashboard();
  }

  loadDashboard = () =>  {
    this.props.dispatch(ActionCreators.loadMyDashboard());
  }

  currentUserId(): Loadable<UserId> {
    const id = UserSEL.userId(this.props.currentUser);
    return id ? loadable(id) : loadable();
  }

  crewInvites(): Loadable<MinimalUser[]> {
    return UserSEL.crewInvites(this.props.currentUser);
  }

  screeningStatus(): Loadable<ScreeningStatus> {
    return UserSEL.screeningStatus(this.props.currentUser);
  }

  challengeInvites(): Loadable<ChallengeInvitation[]> {
    return ChallengesSEL.invitations(this.props.challenges);
  }

  gotoUrl = (url: string) => {
    this.props.dispatch(NavAC.pushWithBreadcrumb(url));
  }

  dashboard(): Loadable<MyDashboard> {
    return this.props.dashboard.myDashboard;
  }

  appFeatures(): Loadable<AppFeatures> {
    return UserSEL.appFeatures(this.props.currentUser);
  }

  withFeature<R>(key: AppFeatureKey, yes: R, no?: R) {
    return withLoadableFeatures(this.appFeatures(), [key], yes, no);
  }

  featureEnabled(key: AppFeatureKey): boolean {
    return !!this.withFeature<boolean>(key, true, false);
  }

  render() {
    const userId = this.currentUserId();
    const dashboard = this.dashboard();
    return (
      <NavBarLayout title="My Dashboard">
        <HUD state={this.props.hud} />
        {this.renderUserBanner()}
        {isLoaded(userId) && isLoaded(dashboard) ?
         this.renderTiles(userId.value, dashboard.value) :
         <Spinner wrap="center" />
        }
      </NavBarLayout>
    );
  }

  renderUserBanner() {
    const profile = this.props.currentUser.profile;
    if (profile) {
      return <UserUI.UserBanner user={profile} variant="prof_pic"/>;
    }
  }

  renderTiles(userId: UserId, dashboard: MyDashboard) {
    return (
      <div>
        <Notifications
          dispatch={this.props.dispatch}
          screeningStatus={dashboard.screeningStatus}
          inboxStatus={dashboard.inboxStatus}
          crewRequests={dashboard.crewRequests}
          challengeInvites={dashboard.challengeInvitations}
        />
        <div className="tile-grid">
          <DeviceTiles
            edgeState={this.props.edge}
            dispatch={this.props.dispatch} />
          {dashboard.carePlanStatus && this.withFeature(
             'carePlans',
             <CarePlanTile dailyStatus={dashboard.carePlanStatus} />
          )}
          {dashboard.rewardPlan && <RewardsTile rewardPlan={dashboard.rewardPlan} />}
          {this.withFeature(
             'challenges',
            <ChallengesTile
              challenge={dashboard.challenge}
              isMe={true}
              onDashboard={true} />
          )}
          {this.withFeature(
             'goals',
             <GoalsTile
               activeGoal={dashboard.activeGoal} isMe={true} userId={userId} />
          )}
          {this.withFeature(
             'screeningReports',
             <ScreeningsTile record={dashboard.lastScreening} />
          )}
          <PostTile
            linkUrl={Urls.myFeedUrl()}
            showScore={this.featureEnabled('healthScore')}
            lastPost={dashboard.lastPost}
            currentUserId={userId}
            refreshDynamicPosts={this.loadDashboard} />
          {this.withFeature(
             'userAppTrends',
             <TrendsTile userId={userId} />
          )}
          {this.withFeature(
             'healthScore',
             <HealthScoreTile
               currentUser={this.props.currentUser}
               lastPost={dashboard.lastPost}/>
           )}
        </div>
      </div>
    );
  }
}

export default defaultConnect(DashboardPage);
