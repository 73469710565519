export * from './Data';
export { ActionType, Action, NSAction } from './Action';
export { ActionCreators } from './ActionCreator';
export { reducer } from './Store';
import NavBar from './UI/NavBar';
import NavMenu from './UI/NavMenu';
import NavBarLayout from './UI/NavBarLayout';

export const UI = {
  NavBar,
  NavMenu,
  NavBarLayout
};

export { NavBarLayout };
