import { decodeHighByteInBack, chunks, decodeMoment } from './Util';
import { DailyActivityDetail } from '../Data';

/**
Command response (can lengthen the command):
0x52 ID1 ID2 YY MM DD HH mm SS S1 S2 K1 K2 D1 D2 SD1 SD2 SD3 ... SD10
The minimum unit is 25 bytes, the total number of pieces of total data contained
in a BLE packet can be dynamically controlled according to the actual maximum
length of the transmitted packet.

ID1 ID2: read the data number, high byte in the back.
YY MM DD HH mm SS: stands for the date, year / month / day / hour / minute /
  second.
S1 S2: steps, high byte in the back.
K1 K2: calories, high byte in the back, unit is 0.01KCAL.
D1 D2: distance, high byte in the back, unit is 0.01KM.
SD1 SD2 SD3 ... SD10: stands for every minute’s steps within this 10 min.
**/
export function decode(packet: Uint8Array): DailyActivityDetail[] {
  return chunks(25, packet).map(chunk => {
    return {
      id: decodeHighByteInBack(chunk.slice(1, 3)),
      time: decodeMoment(chunk.slice(3, 9)),
      stepCount: decodeHighByteInBack(chunk.slice(9, 11)),
      calories: decodeHighByteInBack(chunk.slice(11, 13)),
      distance: decodeHighByteInBack(chunk.slice(13, 15)),
      steps: chunk.slice(15, 25)
    };
  });
}
