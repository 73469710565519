import * as React from 'react';
import { Goal, GoalStatus, GoalStatusSummary } from 'Goals/Data';

import Spinner from 'Shared/UI/Spinner';
import StripedTable from 'Shared/UI/StripedTable';

interface Props {
  goal: Goal,
  status: GoalStatus,
  polling?: boolean
}

const HEADERS = ['days', 'posts', 'status'];

export default function renderGoalDetailTable(
  props: Props
): React.ReactElement<Props> {
  return (
    <StripedTable
      headers={HEADERS} rows={rows(props)} className="goal-detail-table" />
  );
}

function rows(props: Props): React.ReactNode[][] {
  const status = props.status;
  const polling = !!props.polling;

  return [[
    status.days,
    status.postCount,
    spinPolling(polling, renderStatusSummary(status.summary))
  ]];
}

function spinPolling(polling: boolean, content: React.ReactNode) {
  return polling ? <Spinner /> : content;
}

function renderStatusSummary(
  summary: GoalStatusSummary | undefined
): React.ReactNode {
  const classes = [
    'goal-status-summary',
    `goal-status-summary--${summary}`
  ].join(' ');
  return (
    <span className={classes}>·</span>
  );
}
