import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { State, StoreDispatch } from 'Store';
import { NavBarLayout } from 'Nav';
import * as Urls from 'Shared/Urls';

import { ReportListItem, SEL } from 'Screenings/Data';
import { ActionCreators } from 'Screenings/ActionCreator';

import { HUD } from 'HUD';
import Spinner from 'Shared/UI/Spinner';
import ScreeningReportList from 'Screenings/UI/ScreeningReportList';

type Props = State & {
  dispatch: StoreDispatch
}

class ScreeningReports extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(ActionCreators.loadReportList());
  }

  reportList(): ReportListItem[] | undefined {
    return SEL.reportList(this.props.screenings);
  }

  render() {
    return (
      <NavBarLayout title="screenings" initBreadcrumb={Urls.dashboardUrl()}
        className="settings-screenings-page">
        <div className="simple-container">
          <p className="settings__section-explanation">
            View health screening reports.
          </p>
          {this.renderContent()}
          <HUD state={this.props.hud} />
        </div>
      </NavBarLayout>
    );
  }

  renderContent(): React.ReactNode {
    const list = this.reportList();
    if (list) {
      return <ScreeningReportList reports={list}></ScreeningReportList>;
    } else {
      return <Spinner wrap="center"></Spinner>;
    }
  }

}

export default defaultConnect(ScreeningReports);
