import { ActionCreatorThunk } from 'Store';
import { TimeRange } from 'Shared/Data/Range';
import { ActionType, withNS } from './Action';
import { ApptTypeId, ApptId, Slot } from './Data';
import {
  apptTypeFromJSON, apptFromJSON, availableSlotsFromJSON, slotToJSON
} from './JSON';

import * as Urls from 'Shared/Urls';
import { push } from 'connected-react-router';
import { ActionCreators as HUD } from 'HUD';

export interface ActionCreators {
  loadApptTypes(): ActionCreatorThunk
  loadMyAppts(): ActionCreatorThunk
  loadAvailability(typeId: ApptTypeId, dates?: TimeRange): ActionCreatorThunk
  reserveSlot(apptTypeId: ApptTypeId, slot: Slot): ActionCreatorThunk
  cancelAppt(apptId: ApptId): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  loadApptTypes,
  loadMyAppts,
  loadAvailability,
  reserveSlot,
  cancelAppt
}

function loadApptTypes(): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.appt.getApptTypes().then(result => {
      dispatch(HUD.close(0));
      const apptTypes = result.appt_types.map(apptTypeFromJSON);
      dispatch(withNS({
        type: ActionType.LOAD_APPT_TYPES__SUCCESS,
        apptTypes
      }));
    });
  };
}

function loadMyAppts(): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.appt.getAppts().then(result => {
      dispatch(HUD.close(0));
      const appts = result.appts.map(apptFromJSON);
      dispatch(withNS({
        type: ActionType.LOAD_APPTS__SUCCESS,
        appts
      }));
    });
  };
}

function loadAvailability(
  typeId: ApptTypeId, dates?: TimeRange
): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    const startDate = dates ? dates.start : undefined;
    const endDate = dates ? dates.end : undefined;
    dispatch(withNS({
      type: ActionType.LOAD_AVAILABILITY__BEGIN,
      apptTypeId: typeId
    }));
    return api.appt.getAvailability(typeId, startDate, endDate).then(result => {
      const availableSlots = availableSlotsFromJSON(result);
      dispatch(withNS({
        type: ActionType.LOAD_AVAILABILITY__SUCCESS,
        apptTypeId: typeId,
        availableSlots
      }));
    });
  };
}

function reserveSlot(apptTypeId: ApptTypeId, slot: Slot): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.appt.createAppt(apptTypeId, slotToJSON(slot)).then(
      result => {
        const appt = apptFromJSON(result.appt);
        dispatch(HUD.success())
        dispatch(withNS({
          type: ActionType.RESERVE_SLOT__SUCCESS,
          appt
        }));
        dispatch(push(Urls.apptUrl(appt.id)));
      },
      () => dispatch(HUD.error())
    );
  };
}

function cancelAppt(apptId: ApptId): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.appt.cancelAppt(apptId).then(() => {
      dispatch(HUD.success());
      dispatch(withNS({
        type: ActionType.CANCEL_APPT__SUCCESS,
        apptId
      }));
      dispatch(push(Urls.apptsUrl()));
    });
  };
}
