import {
  ActionCreatorThunk, ActionCreatorThunkThen, StoreDispatch
} from 'Store';
import { ActionCreators as HUD } from 'HUD';

import { ActionType } from './Action';
import * as Action from './Action';
import { ActionCreators as AuthAC } from 'Auth';

import { NS, Profile, CurrentUserPayload } from './Data';
import {
  CurrentUserJSON,
  profileToJSON,
  profileFromJSON,
  minimalUserFromJSON,
  programFromJSON,
  crewMemberFromJSON,
  screeningStatusFromJSON
} from './JSON';
import { Specs, specsFromJSON } from 'Specs';

/*------------------------------------------------------------*/

export interface ActionCreators {
  saveProfile(
    profile: Profile, onError: (msg: string) => void
  ): ActionCreatorThunk,
  loadCurrentUser(
    forceReload?: boolean
  ): ActionCreatorThunkThen<CurrentUserPayload>,
  loadSpecs(onSuccess?: (specs: Specs) => void): ActionCreatorThunk,
  loadCrew(): ActionCreatorThunk,
  changePassword(password: string, onSuccess?: () => void): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  saveProfile,
  loadCurrentUser,
  loadSpecs,
  loadCrew,
  changePassword
}

/*------------------------------------------------------------*/
/* private */


function saveProfile(profile: Profile, onError: (msg: string) => void): ActionCreatorThunk {
  return (dispatch, _getState, { api }) => {
    dispatch({
      NS,
      type: ActionType.SAVE_PROFILE__START,
      profile
    } as Action.SaveProfile_Start);
    dispatch(HUD.loading());

    return api.user.updateProfile(profileToJSON(profile))
      .then(({ errors: err }) => {
        if (err.length == 0) {
          dispatch({
            NS,
            type: ActionType.SAVE_PROFILE__SUCCESS,
            profile
          } as Action.SaveProfile_Success);
          dispatch(HUD.success());
        } else {
          onError(err.join(' '));
          dispatch(HUD.error());
        }
      });
  };
}

function loadCurrentUser(forceReload?: boolean): ActionCreatorThunk {
  return (dispatch, getState, { api }) => {
    const loader = getState().currentUser.loader;
    if (loader) { return loader; }

    const profile = getState().currentUser.profile;

    if (!profile || forceReload) {
      const newLoader = api.user.getCurrentUser().then(
        ({ current_user: currentUserJSON, token }) => {
          const data = handleCurrentUserJSON(dispatch, currentUserJSON);
          handleAuthTokenUpdate(dispatch, token)
          return data;
        }
      );
      const begin: Action.LoadCurrentUser_Start = {
        NS, type: ActionType.LOAD_CURRENT_USER__START, loader: newLoader
      };
      dispatch(begin);
      return newLoader;
    } else {
      return Promise.resolve();
    }
  };
}

function handleCurrentUserJSON(
  dispatch: StoreDispatch, currentUserJSON: CurrentUserJSON
) {
  const profile = profileFromJSON(currentUserJSON.profile);
  const program =
    programFromJSON(currentUserJSON.program);
  const carePros = currentUserJSON.care_pros.map(minimalUserFromJSON);
  const screeningStatus =
    screeningStatusFromJSON(currentUserJSON.has_active_screening);
  const nativeAppSlug = currentUserJSON.native_app_slug;
  const success: Action.LoadCurrentUser_Success = {
    NS,
    type: ActionType.LOAD_CURRENT_USER__SUCCESS,
    profile,
    program,
    carePros,
    screeningStatus,
    nativeAppSlug
  };
  dispatch(success);
  return { profile, program, screeningStatus, carePros, nativeAppSlug };
}

function handleAuthTokenUpdate(
  dispatch: StoreDispatch, token: string | undefined
) {
  if (token) { dispatch(AuthAC.refreshToken(token)); }
}

function loadSpecs(onSuccess?: (specs: Specs) => void): ActionCreatorThunk {
  return (dispatch, _, { api }) =>
    api.user.getCurrentUserSpecs().then(({ specs: specsJSON }) => {
      const specs = specsFromJSON(specsJSON);

      const action: Action.LoadSpecs_Success = {
        NS,
        type: ActionType.LOAD_SPECS__SUCCESS,
        specs
      };

      dispatch(action)

      if (onSuccess) { onSuccess(specs); }
    });
}

function loadCrew(): ActionCreatorThunk {
  return (dispatch, _, { api }) =>
    api.user.getCurrentUserCrew().then(({ crew: crewJSON }) => {
      const crew = crewJSON.map(crewMemberFromJSON);
      const success: Action.LoadCrew_Success = {
        NS,
        type: ActionType.LOAD_CREW__SUCCESS,
        crew
      };
      dispatch(success);
    });
}

function changePassword(
  password: string,
  onSuccess?: () => void
): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.user.changePassword(password).then(
      () => dispatch({
        NS,
        type: ActionType.CHANGE_PASSWORD__SUCCESS
      } as Action.ChangePassword_Success)
    ).then(() => {
      if (onSuccess) { onSuccess(); }
      dispatch(HUD.success());
    });
  };
}
