import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { defaultConnect } from 'Shared/ReduxComponent';
import { State, StoreDispatch } from 'Store';
import { NavBarLayout } from 'Nav';
import * as Urls from 'Shared/Urls';
import { TimeRange } from 'Shared/Data/Range';
import { ActionCreators } from 'Appt/ActionCreator';
import {
  SEL, ApptType, ApptTypeId, Slot, AvailableSlots, slotLabel
} from 'Appt/Data';
import { SEL as UserSEL, ActionCreators as UserAC } from 'User';
import { HUD } from 'HUD';
import { confirm } from 'Shared/Dialog';
import * as Scroll from 'Shared/Scroll';
import Spinner from 'Shared/UI/Spinner';
import featureGate from 'App/UI/FeatureGate';
import EventDetails from 'Appt/UI/EventDetails';
import TimezoneNotice from 'Appt/UI/TimezoneNotice';
import SlotSelector from 'Appt/UI/SlotSelector';


type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch
}

interface URLParams {
  apptTypeId: ApptTypeId
}

class NewAppt extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(UserAC.loadCurrentUser());
    if (this.apptType() === undefined) {
      this.props.dispatch(ActionCreators.loadApptTypes());
    }
    this.props.dispatch(
      ActionCreators.loadAvailability(this.apptTypeId())
    );
  }

  apptTypeId(): ApptTypeId {
    return this.props.match.params.apptTypeId;
  }

  apptType(): ApptType | undefined {
    return SEL.apptType(this.apptTypeId(), this.props.appt);
  }

  availableSlots(): AvailableSlots | undefined {
    return SEL.availableSlots(this.props.appt);
  }

  tzId(): string {
    return UserSEL.userTzId(this.props.currentUser);
  }

  gotoPage = (dates: TimeRange) => {
    const apptType = this.apptType();
    if (apptType) {
      this.props.dispatch(
        ActionCreators.loadAvailability(this.apptTypeId(), dates)
      );
      Scroll.toTop();
    }
  }

  selectSlot = (slot: Slot) => {
    const confirmationMsg =
      'Please confirm you would like to reserve the following appointment ' +
      'time: ' + slotLabel(slot, this.tzId());

    confirm(confirmationMsg).then(
      () => {
        this.props.dispatch(
          ActionCreators.reserveSlot(this.apptTypeId(), slot)
        )
      },
      () => {}
    );
  }

  render() {
    const apptType = this.apptType();
    const availableSlots = this.availableSlots();
    return (
      <NavBarLayout
        title="New Appointment"
        initBreadcrumb={Urls.apptsUrl()}>
        <div className="simple-container">
          {apptType && this.renderApptDetails(apptType)}
          {apptType && availableSlots ?
           this.renderSlotSelector(apptType, availableSlots) :
           <Spinner wrap="center" />
          }
        </div>
        <HUD state={this.props.hud} />
      </NavBarLayout>
    );
  }

  renderApptDetails(apptType: ApptType) {
    return (
      <div>
        <EventDetails apptType={apptType} userZoneId={this.tzId()} />
        <TimezoneNotice userZoneId={this.tzId()}/>
        <p className="appt__sidenote">Pick a date a time</p>
      </div>
    );
  }

  renderSlotSelector(apptType: ApptType, availableSlots: AvailableSlots) {
    return (
      <SlotSelector
        selectSlot={this.selectSlot}
        apptType={apptType}
        availableSlots={availableSlots}
        gotoPage={this.gotoPage}
        userZoneId={this.tzId()} />
    );
  }
}

export default featureGate(
  withRouter(defaultConnect(NewAppt)),
  ['appts']
);
