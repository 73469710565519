import * as moment from 'moment-timezone';
import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { NavBarLayout } from 'Nav';
import { State, DispatchProps } from 'Store';
import * as Urls from 'Shared/Urls';

import { RouteComponentProps } from 'react-router-dom';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import { ActionCreators as UserAC, SEL as UserSEL } from 'User';

import { HUD } from 'HUD';
import DailyNav from 'Shared/UI/DailyNav';
import ConnectedActionStatusList from 'CarePlans/UI/ConnectedActionStatusList';

type Props = State & RouteComponentProps<URLParams> & DispatchProps;

type URLParams = {
  date?: string
}

class DailyCareTasks extends React.Component<Props, {}> {
  componentDidMount() {
    this.ensureValidDateInUrl();
    this.props.dispatch(UserAC.loadCurrentUser());
  }

  componentDidUpdate() {
    this.ensureValidDateInUrl();
  }

  ensureValidDateInUrl(props?: Props) {
    props = props || this.props;
    // Wait for user TZ
    if (this.props.currentUser.profile && !this.validDateInUrl()) {
      const url = Urls.carePlanCareTasksUrl(moment.tz(this.tzId()));
      this.props.dispatch(push(url));
    }
  }

  dateInUrl(props?: Props): string | undefined {
    return (props || this.props).match.params.date;
  }

  date(props?: Props): moment.Moment | undefined {
    props = props || this.props;
    const urlDate = props.match.params.date;
    if (!urlDate) { return; }
    let date = moment.tz(urlDate, 'YYYY-MM-DD', this.tzId());
    return date.isValid() ? date : undefined;
  }

  tzId(): string {
    return UserSEL.userTzId(this.props.currentUser);
  }

  validDate = (date: moment.Moment): boolean => {
    const now = moment.tz(this.tzId());
    return date.isSameOrBefore(now, 'day');
  }

  validDateInUrl(): boolean {
    const date = this.date();
    if (!date) { return false; }
    return this.validDate(date);
  }

  gotoDate = (date: moment.Moment) => {
    this.props.dispatch(push(Urls.carePlanCareTasksUrl(date)));
  }

  render() {
    const date = this.date();
    return (
      <NavBarLayout title="Care Tasks" initBreadcrumb={Urls.dashboardUrl()}>
        <div className="simple-container">
          {date ? this.renderDailyStatus(date) : this.renderLoading()}
        </div>
      </NavBarLayout>
    );
  }

  renderDailyStatus(date: moment.Moment) {
    return (
      <div>
        <DailyNav
          currentDate={date}
          gotoDate={this.gotoDate}
          validDate={this.validDate}
        />
        <ConnectedActionStatusList date={date} />
      </div>
    );
  }

  renderLoading() {
    return <HUD state="loading" />;
  }
}

export default withRouter(defaultConnect(DailyCareTasks));
