import * as React from 'react';
import * as R from 'ramda';
import { push } from 'connected-react-router';
import { StoreDispatch } from 'Store';
import { Step, isLastStep, totalSteps } from 'Onboard/Data';
import * as Urls from 'Shared/Urls';
import { NavBarLayout } from 'Nav';
import ProgressBar from 'Shared/UI/ProgressBar';
import Pager from 'Shared/UI/Pager';
import StepInstructions from 'Onboard/UI/StepInstructions';

interface Props {
  steps: Step[],
  step: Step,
  dispatch: StoreDispatch,
  children?: React.ReactNode
}

const Layout = (props: Props) => {
  const { steps, step } = props;
  return (
    <NavBarLayout title="Onboard">
      <div className="simple-container">
        {renderTop(steps, step)}
        {props.children}
        {renderPagination(props.dispatch, steps, step)}
      </div>
    </NavBarLayout>
  )
}

export default Layout;


function renderTop(steps: Step[], step: Step) {
  return (
    <div className="onboard-instructions">
      {step.number === 1 && (
         <h2 className="text-center">
           Welcome, let's get started!
         </h2>
       )}

      <h3 className="text-center">
        STEP {step.number}: {step.title}
      </h3>

      {<StepInstructions stepKey={step.key} />}

      <ProgressBar percent={percentDone(steps, step)} />

      <p className="no-content">{step.helpText}</p>
    </div>
  );
}

function renderPagination(dispatch: StoreDispatch, steps: Step[], step: Step) {
  const nextStep = step.number + 1;

  let nextUrl: string;
  if (isLastStep(steps, step)) {
    nextUrl = Urls.onboardingCompleteUrl();
  } else {
    nextUrl = Urls.onboardingUrl(nextStep);
  }

  return (
    <Pager nextPage={nextStep}
      previousPage={undefined}
      nextLabel="next"
      gotoPage={() => dispatch(push(nextUrl))} />
  );
}

function percentDone(steps: Step[], step: Step): number {
  const n = step.number / (totalSteps(steps) + 1);
  return R.clamp(0, 1, n);
}
