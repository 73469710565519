import * as R from 'ramda';
/**
 * Represents a value that can be loaded, but may be undefined even after
 * loading.  Helps distinguish between the states of "loading is complete, but
 * there is no vablue" vs "has not yet been loaded".
*/

export enum LoadState {
  NotLoaded = 'not-loaded',
  Loaded = 'loaded'
}

export type Loadable<T>
  = LoadableNotLoaded
  | LoadableLoaded<T>;

interface LoadableNotLoaded {
  state: 'not-loaded',
  value: undefined
}

interface LoadableLoaded<T> {
  state: 'loaded',
  value: T
}

const NOT_LOADED: LoadableNotLoaded =
  { state: LoadState.NotLoaded, value: undefined };

export function loadable<T>(): Loadable<T>;
export function loadable<T>(val: T): Loadable<T>;
export function loadable<T>(val?: T): Loadable<T> {
  /**
   * This sort of awkward construct required so that you can create a loaded
   * value of undefined, ie: `loadable(undefined)` should be in the Loaded state
   * with a value of `undefined`
   */
  if (arguments.length === 1) {
    return { state: LoadState.Loaded, value: (val as T) };
  } else {
    return NOT_LOADED;
  }
}


export function isLoaded<T>(l: Loadable<T>): l is LoadableLoaded<T> {
  return l.state === 'loaded';
}

export function isNotLoaded<T>(l: Loadable<T>): l is LoadableNotLoaded {
  return !isLoaded(l);
}

export function value<T>(l: Loadable<T>): T | undefined {
  return isLoaded(l) ? l.value : undefined;
}

export function loadableMap<T, U>(
  fn: (t: T) => U
): (l: Loadable<T>) => Loadable<U>;
export function loadableMap<T, U>(
  fn: (t: T) => U, l: Loadable<T>
): Loadable<U>;
export function loadableMap<T, U>(
  fn: (t: T) => U, l?: Loadable<T>
): (Loadable<U>) | ((l: Loadable<T>) => Loadable<U>) {
  if (l) {
    return loadableMap(fn)(l);
  } else {
    return (l2: Loadable<T>) => {
      if (isLoaded(l2)) {
        return { ...l2, value: fn(l2.value) };
      } else {
        return l2;
      }
    };
  }
}

export function fromLoadable<T, U>(
  loadedFn: (t: T) => U,
  elseFn: () => U
): (l: Loadable<T>) => U;
export function fromLoadable<T, U>(
  loadedFn: (t: T) => U,
  elseFn: () => U,
  l: Loadable<T>
): U;
export function fromLoadable<T, U>(
  loadedFn: (t: T) => U,
  elseFn: () => U,
  l?: Loadable<T>
) {
  if (l !== undefined) {
    return fromLoadable(loadedFn, elseFn)(l);
  } else {
    return (val: Loadable<T>) =>
      isLoaded(val) ? loadedFn(val.value) : elseFn()
  }
}

export function loadableSequence<T>(
  loadables: Loadable<T>[]
): Loadable<T[]> {
  if (R.all(isLoaded, loadables)) {
    return loadable(
      (loadables as LoadableLoaded<T>[]).map(l => l.value)
    );
  } else {
    return loadable();
  }
}
