import * as moment from 'moment-timezone';
import * as R from 'ramda';
import * as React from 'react'
import { bem } from 'css-util';
import { Report } from 'Screenings/Data';
import { RiskLevel, RiskDot } from 'Risk';

interface Props {
  report: Report
}

type DemographicData = {
  'Name'?: string,
  'Age'?: string,
  'Sex'?: string,
  'Group'?: React.ReactNode
};

const ReportSummaryTable = (props: Props) => {
  const CSS = bem('screen-report__summary');
  const report = props.report;
  const demographicData = demographicDataFromReport(report);

  const riskFactorsData = report.riskFactors.reduce(
    (result, rf) => ({
      ...result,
      [rf.title]: <RiskDot riskLevel={rf.riskLevel} />
    }), {}
  );
  return (
    <div className={CSS()()}>
      <div className={CSS('left')()}>
        <KeyValueTable data={demographicData} />
      </div>
      <div className={CSS('right')()}>
        <KeyValueTable data={riskFactorsData} />
      </div>
    </div>
  );
}


export default ReportSummaryTable;

interface KVTableProps {
  data: { [label: string]: React.ReactNode }
}

const KeyValueTable = (props: KVTableProps) => {
  return (
    <table className="screen-report__summary-dl">
      <tbody>
        {R.keys(props.data).map(label =>
          renderKVTableRow(label.toString(), props.data[label]))
        }
      </tbody>
    </table>
  );
}

function renderKVTableRow(label: string, value: React.ReactNode) {
  return (
    <tr key={label}>
      <th className="screen-report__kv-key">{label}</th>
      <td className="screen-report__kv-value">{value}</td>
    </tr>
  );
}

function formatAge(
  bday: moment.Moment,
  onDate: moment.Moment
): string {
  return moment.duration(onDate.diff(bday)).years().toString();
}

function riskGroup(level: RiskLevel): React.ReactNode {
  return (
    <span style={{ color: level.color }}>
      {level.label}
    </span>
  );
}

function demographicDataFromReport(report: Report): DemographicData {
  let data: DemographicData = {};

  if (report.name) {
    data['Name'] = report.name;
  }
  if (report.birthday) {
    data['Age'] = formatAge(report.birthday, report.date);
  }
  if (report.gender) {
    data['Sex'] = report.gender;
  }
  if (report.overallRiskLevel) {
    data['Group'] = riskGroup(report.overallRiskLevel)
  }

  return data;
}
