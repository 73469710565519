import { NS, NSType, Config } from './Data';
import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';

export enum ActionType {
  LOAD_CONFIG__BEGIN = 'LOAD_CONFIG__BEGIN',
  LOAD_CONFIG__SUCCESS = 'LOAD_CONFIG__SUCCESS'
}

export type Action
  = LoadConfig_Begin
  | LoadConfig_Success;

export type NSAction = WithNS<NSType, Action>;
export const withNS = originalWithNS<NSType, Action>(NS);

export interface LoadConfig_Begin {
  type: ActionType.LOAD_CONFIG__BEGIN
}

export interface LoadConfig_Success {
  type: ActionType.LOAD_CONFIG__SUCCESS,
  config: Config
}
