import * as React from 'react';
import './Tile.scss';
import { bem } from 'css-util';
import Link, { LinkTarget, BreadcrumbAction } from 'Shared/UI/Link';

export interface Props {
  title: string
  linkUrl?: LinkTarget | undefined
  linkLabel?: React.ReactNode,
  children?: React.ReactNode,
  breadcrumb?: BreadcrumbAction,
  modifier?: string
}

const CSS = bem('tile');
export default function renderTile(
  props: Props
): React.ReactElement<Props> {
  return (
    <div className={CSS()([props.modifier])}>
      <div className="tile__top">
        <h3 className="tile__title">{props.title}</h3>
        <div className="tile__link">
          {renderTileCornerLink(props)}
        </div>
      </div>
      <div className="tile__hr" />
      {props.children}
    </div>
  );
}

function renderTileCornerLink(props: Props) {
  if (props.linkUrl) {
    return (
      <Link
        to={props.linkUrl}
        className="tile__corner-button theme__primary-bg"
        themed={false}
        breadcrumb={props.breadcrumb}
      >
        {props.linkLabel || `view all ${props.title}`}
      </Link>
    );
  }
}
