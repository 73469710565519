import * as moment from 'moment-timezone';
import { ThreadInfoJSON, ThreadJSON, MessageJSON } from 'Api/JSON/Inbox';
import { ThreadInfo, Thread, Message } from 'Inbox/Data';
import { minimalUserFromJSON } from 'User/JSON';

export function threadInfoFromJSON(json: ThreadInfoJSON): ThreadInfo {
  return {
    id: json.id,
    participants: json.participants.map(minimalUserFromJSON),
    lastMessageAt: moment(json.last_message_at),
    unreadCount: json.unread_count,
    subject: json.subject,
    closed: json.closed
  };
}

export function threadFromJSON(json: ThreadJSON): Thread {
  return {
    info: threadInfoFromJSON(json),
    messages: json.messages.map(messageFromJSON)
  };
}

function messageFromJSON(json: MessageJSON): Message {
  return {
    userId: json.user_id,
    postedAt: moment(json.posted_at),
    text: json.text
  };
}
