import * as React from 'react';
import * as classnames from 'classnames';
import { CategoryInfo, Activity } from 'Post/Data';
import { bem } from 'css-util';
import Icon from 'Shared/UI/Icon';
import { IconName } from 'Shared/UI/Icon';
import Logo from 'Shared/UI/Logo';

interface Props {
  category: CategoryInfo | undefined
  activity: Activity
  postActivity: (note: string) => void
  close: () => void
}

interface FormState {
  note: string
}

const CSS = bem('note-form');
const DEFAULT_ICON: IconName = 'rocket';

class NoteForm extends React.Component<Props, FormState> {
  state = { note: '' }

  onSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();
    this.props.postActivity(this.state.note);
    this.props.close();
  }

  onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ note: e.target.value });
  }

  render() {
    return (
      <div className={CSS()()}>
        <div className={CSS('top')()}>
          {this.renderBiopostHeader()}
        </div>
        <div className={CSS('bottom')()}>
          {this.renderForm()}
        </div>
      </div>
    );
  }

  renderBiopostHeader() {
    const css = bem('biopost');
    const { activity, category } = this.props;
    return (
      <div className={css()()}>
        <div className={css('section')(['left'])}>
          <span className={css('icon')(['no-show-user'])}>
            <Icon name={category ? category.icon : DEFAULT_ICON} />
          </span>
        </div>
        <div className={css('section')(['main'])}>
          <div className="biopost__title">
            {activity.name}
          </div>
          <div className="biopost__time">
            Right now
          </div>
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <form onSubmit={this.onSubmit} className={CSS('form')()}>
        <label className={CSS('title')()}>
          <Icon name="pencil-square" className={CSS('notes-icon')()} />
          Notes
        </label>
        <textarea className={classnames('text-area', CSS('textarea')())}
          onChange={this.onTextChange}
          value={this.state.note}
          placeholder="Add notes">
        </textarea>
        <button type="submit" className={CSS('submit')()}>
          <span>Add Notes &amp; Post</span>
          <Logo />
        </button>
        <a className={CSS('close-button')()}
          onClick={this.props.close}>
          Close
        </a>
      </form>
    );
  }
}

export default NoteForm;
