import { NSType, NS, Page } from './Data';
import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';

export enum ActionType {
  LOAD_PAGE__SUCCESS = 'LOAD_PAGE__SUCCESS'
}

export interface LoadPage_Success {
  type: ActionType.LOAD_PAGE__SUCCESS,
  url: string,
  page: Page
}

export type Action = LoadPage_Success;

export type NSAction = WithNS<NSType, Action>;
export const withNS: (a: Action) => NSAction = originalWithNS(NS);
