import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';
import { NS, NSType } from './Data';

export enum ActionType {
  ENABLE_MAINTENANCE_MODE = 'ENABLE_MAINTENANCE_MODE',
  DISABLE_MAINTENANCE_MODE = 'DISABLE_MAINTENANCE_MODE',
  BEGIN_POLLING = 'BEGIN_POLLING',
  STOP_POLLING = 'STOP_POLLING'
}

export type Action
  = EnableMaintenanceMode
  | DisableMaintenanceMode
  | BeginPolling
  | StopPolling
;

export interface EnableMaintenanceMode {
  type: ActionType.ENABLE_MAINTENANCE_MODE
}

export interface DisableMaintenanceMode {
  type: ActionType.DISABLE_MAINTENANCE_MODE
}

export interface BeginPolling {
  type: ActionType.BEGIN_POLLING,
  interval: number
}

export interface StopPolling {
  type: ActionType.STOP_POLLING
}

export type NSAction = WithNS<NSType, Action>;
export const withNS = originalWithNS<NSType, Action>(NS);
