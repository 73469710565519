import * as React from 'react';
import Link from 'Shared/UI/Link';

interface Props {
  onClick: () => void
}

const UncrewButton = (props: Props) => {
  return (
    <Link to={props.onClick} className="uncrew-button">
      remove from my community
    </Link>
  )
};

export default UncrewButton;
