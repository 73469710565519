import * as R from 'ramda';
import * as moment from 'moment-timezone';
import { Loadable, loadable } from 'misc/Data/Loadable';
import { Maybe } from 'misc/Data/Maybe';
import { HTML } from 'Shared/Data/HTML';

import {
  ChallengeId,
  TemplateId,
} from 'Challenges/Data';

export type NSType = 'REWARDS';
export const NS: NSType = 'REWARDS';

export interface State {
  rewardPlan: Loadable<Maybe<RewardPlan>>,
  userActions: Loadable<UserAction[]>
}

export interface RewardPlan {
  startDate: moment.Moment,
  endDate: moment.Moment,
  maxPoints: number,
  currentPoints: number
}

export interface Reward {
  id: number,
  name: string
  points: number,
  action: Action,
  endDate: moment.Moment,
  rules: HTML
}

export type Action
  = ManualActionPro
  | ManualActionApp
  | DeviceConnected
  | ScreeningComplete
  | ChallengeJoined
  | ChallengeTemplateJoined;

export enum ActionType {
  ManualActionPro,
  ManualActionApp,
  DeviceConnected,
  ScreeningComplete,
  ChallengeJoined,
  ChallengeTemplateJoined
}

interface ManualActionPro {
  type: ActionType.ManualActionPro
}

interface ManualActionApp {
  type: ActionType.ManualActionApp
}

interface DeviceConnected {
  type: ActionType.DeviceConnected
}

interface ScreeningComplete {
  type: ActionType.ScreeningComplete
}

interface ChallengeJoined {
  type: ActionType.ChallengeJoined,
  challengeId: ChallengeId
}

interface ChallengeTemplateJoined {
  type: ActionType.ChallengeTemplateJoined,
  templateId: TemplateId
}

export enum ActionStatus {
  AVAILABLE = 'AVAILABLE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE'
}

export interface UserAction {
  reward: Reward,
  status: ActionStatus
}

export function initialState(): State {
  return {
    rewardPlan: loadable(),
    userActions: loadable()
  };
}

export function completedActions(actions: UserAction[]): UserAction[] {
  return R.filter(
    a => isComplete(a.status),
    actions
  );
}

export function isComplete(status: ActionStatus): boolean {
  return status === ActionStatus.COMPLETE;
}
