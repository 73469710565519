import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';
import { HTML } from 'Shared/Data/HTML';
import {
  NSType, NS, ReportListItem, Report, RecordId, Screen, ScreenField, FieldValue
} from './Data';

export enum ActionType {
  LOAD_REPORT_LIST__SUCCESS = 'LOAD_REPORT_LIST__SUCCESS',
  LOAD_REPORT__SUCCESS = 'LOAD_REPORT__SUCCESS',
  LOAD_SCREEN__BEGIN = 'LOAD_SCREEN__BEGIN',
  LOAD_SCREEN__SUCCESS = 'LOAD_SCREEN__SUCCESS',
  VALIDATE_CURRENT_STEP = 'VALIDATE_CURRENT_STEP',
  GOTO_NEXT_STEP = 'GOTO_NEXT_STEP',
  GOTO_PREVIOUS_STEP = 'GOTO_PREVIOUS_STEP',
  SET_FIELD_VALUE = 'SET_FIELD_VALUE',
  SET_THANK_YOU_CONTENT = 'SET_THANK_YOU_CONTENT'
}

export type Action
  = LoadReportList_Success
  | LoadReport_Success
  | LoadScreen_Begin
  | LoadScreen_Success
  | ValidateCurrentStep
  | GotoNextStep
  | GotoPreviousStep
  | SetFieldValue
  | SetThankYouContent;

export interface LoadReportList_Success {
  type: ActionType.LOAD_REPORT_LIST__SUCCESS,
  reports: ReportListItem[]
}

export interface LoadReport_Success {
  type: ActionType.LOAD_REPORT__SUCCESS,
  report: Report,
  recordId: RecordId
}

export interface LoadScreen_Begin {
  type: ActionType.LOAD_SCREEN__BEGIN
}

export interface LoadScreen_Success {
  type: ActionType.LOAD_SCREEN__SUCCESS,
  screen: Screen
}

export interface ValidateCurrentStep {
  type: ActionType.VALIDATE_CURRENT_STEP
}

export interface GotoNextStep {
  type: ActionType.GOTO_NEXT_STEP
}

export interface GotoPreviousStep {
  type: ActionType.GOTO_PREVIOUS_STEP
}

export interface SetFieldValue {
  type: ActionType.SET_FIELD_VALUE,
  field: ScreenField,
  value: FieldValue
}

export interface SetThankYouContent {
  type: ActionType.SET_THANK_YOU_CONTENT,
  html: HTML
}

export type NSAction = WithNS<NSType, Action>;
export const withNS: (a: Action) => NSAction = originalWithNS(NS);
