import { CUSTOM_BUILD } from 'Env';
import { ActionCreatorThunk, ActionCreatorThunkThen } from 'Store';
import SettingsStore from 'Auth/SettingsStore';
import { push } from 'connected-react-router';

import * as Either from 'Shared/Data/Either';
import * as Maybe from 'misc/Data/Maybe';
import { ActionType, withNS, NSAction } from './Action';
import * as Action from './Action';
import { LoginResponseJSON } from 'Api/JSON/Auth';
import { authSettingsFromJSON } from './JSON';
import {
  DEFAULT_PROGRAM_SLUG,
  AuthSettings,
  LoginSubmission,
  SignupSubmission,
  isLoggedIn
} from './Data';
import { ActionCreators as AppAC } from 'App';
import { ActionCreators as UserAC } from 'User';
import {
  loginUrl, rootUrl, onboardingUrl, forgotPasswordUrl
} from 'Shared/Urls';
import { ActionCreators as HUD } from 'HUD';

/*============================================================*/

export interface ActionCreators {
  initializeAuthState(): ActionCreatorThunk,
  login(creds: LoginSubmission): PossibleErrorThunk,
  signup(programSlug: string, creds: SignupSubmission): PossibleErrorThunk,
  logout(): ActionCreatorThunk,
  loadAuthSettings(slug: string | undefined): ActionCreatorThunk,
  refreshToken(token: string): ActionCreatorThunk,
  forgotPassword(email: string): ActionCreatorThunk
  resetPassword(code: string, password: string): ActionCreatorThunk
  checkResetPasswordCode(code: string): ActionCreatorThunk
}

type PossibleErrorThunk = ActionCreatorThunkThen<Maybe.Maybe<string>>;

export const ActionCreators: ActionCreators = {
  initializeAuthState,
  login,
  signup,
  logout,
  loadAuthSettings,
  refreshToken,
  forgotPassword,
  resetPassword,
  checkResetPasswordCode
}

/*============================================================*/

function initializeAuthState(): ActionCreatorThunk {
  return (dispatch, _, { tokenStore }) => {
    const token = tokenStore.get();
    const localSlug =
      SettingsStore.getSlug() || CUSTOM_BUILD.CANONICAL_PROGRAM_SLUG;
    token ? dispatch(setToken(token)) : dispatch(logout());
    if (localSlug) { dispatch(setAuthProgramSlug(localSlug)); }
    return Promise.resolve();
  };
}

function login(creds: LoginSubmission): PossibleErrorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.auth.login(creds.email, creds.password).then(
      result => Either.either(
        response => dispatch(handleLoginFailureResponse(response)),
        success => dispatch(handleLoginSuccess(success, creds.rememberMe)),
        result
      )
    );
  };
}

function signup(
  programSlug: string, creds: SignupSubmission
): PossibleErrorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.auth.signup(
      creds.email, creds.password, creds.name, programSlug
    ).then(
      result => Either.either(
        response => dispatch(handleLoginFailureResponse(response)),
        success => dispatch(handleLoginSuccess(success, creds.rememberMe)),
        result
      )
    );
  };
}

function handleLoginFailureResponse(response: Response): PossibleErrorThunk {
  return dispatch =>
    response.json().then(
      ({ message }) => dispatch(handleLoginFailure(message))
    );
}

function handleLoginFailure(errorMessage?: string): PossibleErrorThunk {
  const message: string = errorMessage || 'Invalid email/password';
  return (dispatch) => {
    dispatch(HUD.close(0));
    return Promise.resolve(Maybe.just(message));
  }
}

function handleLoginSuccess(
  result: LoginResponseJSON, rememberMe: boolean
): PossibleErrorThunk {
  return (dispatch, _, { tokenStore }) => {
    const token = result.token;
    tokenStore.set(token, rememberMe);
    dispatch(withNS({ type: ActionType.LOGIN__SUCCESS, token }));
    dispatch(AppAC.initializeAppOnLogin());
    dispatch(UserAC.loadCurrentUser(true)).then(({ program }) => {
      dispatch(setAuthProgramSlug(program.slug));
    });
    dispatch(HUD.success());
    const landingUrl = result.new_user ? onboardingUrl() : rootUrl();
    dispatch(push(landingUrl));
    return Promise.resolve(Maybe.nothing());
  };
}

function logout(): ActionCreatorThunk {
  return async (dispatch, getState, { tokenStore }) => {
    if (isLoggedIn(getState().auth)) {
      tokenStore.delete();
      dispatch(withNS({ type: ActionType.LOGOUT }));
      const routerState = getState().router;
      const location = routerState && routerState.location;
      if (location && location.pathname !== loginUrl()) {
        dispatch(push(loginUrl()));
      }
      await dispatch(AppAC.resetApp());

      // Reset auth settings slug that just got cleared out
      const authSlug = SettingsStore.getSlug();
      if (authSlug) { dispatch(setAuthProgramSlug(authSlug)); }
    }

    return Promise.resolve();
  }
}

function setToken(token: string): Action.NSAction {
  return withNS({
    type: ActionType.LOGIN__SUCCESS,
    token
  });
}

function loadAuthSettings(slug: string | undefined): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    const domain = window.location.hostname;
    return api.auth.getAuthSettings(slug, domain).then(
      result => {
        const settings = authSettingsFromJSON(result.settings);
        dispatch(setAuthProgramSlug(settings.programSlug));
        dispatch(authSettingsLoaded(settings));
      },
      (data: ({ response: Response }))  => {
        if (
          data.response.status !== 503 &&
            DEFAULT_PROGRAM_SLUG !== undefined &&
            slug !== DEFAULT_PROGRAM_SLUG
        ) {
          return dispatch(loadAuthSettings(DEFAULT_PROGRAM_SLUG));
        }
      });
  }
}

function authSettingsLoaded(settings: AuthSettings): NSAction {
  return withNS({
    type: ActionType.LOAD_AUTH_SETTINGS__SUCCESS,
    settings
  });
}

function setAuthProgramSlug(slug: string): ActionCreatorThunk {
  return (dispatch) => {
    SettingsStore.setSlug(slug);
    dispatch(withNS({
      type: ActionType.SET_AUTH_PROGRAM_SLUG, slug
    }));
    return Promise.resolve();
  }
}

function refreshToken(token: string): ActionCreatorThunk {
  return (dispatch, _, { tokenStore }) => {
    tokenStore.refresh(token);
    dispatch(setToken(token));
    return Promise.resolve();
  };
}

function forgotPassword(email: string): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.auth.forgotPassword(email).then(
      () => dispatch(HUD.success()),
      () => dispatch(HUD.error())
    );
  }
}

function resetPassword(code: string, password: string): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.auth.resetPassword(code, password).then(
      () => {
        dispatch(HUD.success());
        dispatch(push(loginUrl()));
      },
      () => dispatch(HUD.error())
    );
  }
}

function checkResetPasswordCode(code: string): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(HUD.loading());
    return api.auth.checkResetCode(code).then(
      data => {
        if (!data.exists) {
          dispatch(push(forgotPasswordUrl() + '?code_invalid=1'));
        } else if (data.expired) {
          dispatch(push(forgotPasswordUrl() + '?code_expired=1'));
        }
        dispatch(HUD.close());
      },
      () => dispatch(HUD.error())
    )
  };
}
