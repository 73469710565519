import './BackgroundImage.scss';
import * as React from 'react';

const DEFAULT_BG_IMAGE = require('images/home-bg-image.jpg').default;

interface Props {
  src?: string
}

class BackgroundImage extends React.Component<Props, {}> {
  src(): string {
    return this.props.src || DEFAULT_BG_IMAGE;
  }

  imageUrl(): string {
    return `url(${this.src()})`;
  }

  reactRoot() {
    return document.getElementById('react-root') || document.body;
  }

  componentDidMount() {
    this.updateImageSrc();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.src !== this.props.src) {
      this.updateImageSrc();
    }
  }

  componentWillUnmount() {
    this.reactRoot().style.backgroundImage = 'none';
  }

  updateImageSrc() {
    this.reactRoot().style.backgroundImage = this.imageUrl();
  }

  render() {
    return '';
  }
}

export default BackgroundImage;
