import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { State, DispatchProps } from 'Store';
import { defaultConnect } from 'Shared/ReduxComponent';
import { withRouter } from 'react-router-dom';

import Spinner from 'Shared/UI/Spinner';

interface URLParams {
  deviceTypeId: string
  deviceId: string
}

type Props = State & DispatchProps & RouteComponentProps<URLParams>;

class FirmwareUpdateProgress extends React.Component<Props, {}> {
  progress(): number {
    return this.props.ble.progress;
  }

  render() {
    return (
      <div className="text-center m-2r pt-1r fs-1-5 bold">
        <h1>Updating Firmware</h1>

        <Spinner wrap="center" />

        <p>
          Please do not lock your device or leave this screen until the update
          is complete.
        </p>

        <h2>
          {this.progress()} %
        </h2>
      </div>
    );
  }
}

export default defaultConnect(withRouter(FirmwareUpdateProgress));
