import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { defaultConnect } from 'Shared/ReduxComponent';
import { State, StoreDispatch } from 'Store';
import { NavBarLayout } from 'Nav';
import * as Urls from 'Shared/Urls';
import { isCordova } from 'Cordova';
import { confirm } from 'Shared/Dialog';
import { ActionCreators } from 'Appt/ActionCreator';
import {
  SEL, ApptId, Appt
} from 'Appt/Data';
import { SEL as UserSEL, ActionCreators as UserAC } from 'User';

import FeatureGate from 'App/UI/FeatureGate';
import Spinner from 'Shared/UI/Spinner';
import Link from 'Shared/UI/Link';
import EventDetails from 'Appt/UI/EventDetails';

type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch
}

interface URLParams {
  apptId: ApptId
}
class ApptDetails extends React.Component<Props, {}> {
  componentDidMount() {
    if (!this.appt()) {
      this.props.dispatch(ActionCreators.loadMyAppts());
    }
    this.props.dispatch(UserAC.loadCurrentUser());
  }

  apptId(): ApptId {
    return this.props.match.params.apptId;
  }

  appt(): Appt | undefined {
    return SEL.appt(this.apptId(), this.props.appt);
  }

  tzId(): string {
    return UserSEL.userTzId(this.props.currentUser);
  }

  downloadIcal = () => {
    const token = this.props.auth.token;
    if (token) {
      window.location.replace(
        Urls.apptIcalUrl(this.apptId(), token)
      );
    }
  }

  cancelAppt = () => {
    const confirmationMsg = 'Are you sure you want to cancel this appointment?';
    confirm(confirmationMsg).then(
      () =>
        this.props.dispatch(ActionCreators.cancelAppt(this.apptId())),
      () => {}
    );
  }

  render() {
    const appt = this.appt();
    return (
      <NavBarLayout title="Appointment" initBreadcrumb={Urls.apptsUrl()}>
        <div className="simple-container">
          {appt ? this.renderContents(appt) : <Spinner wrap="center"/>}
        </div>
      </NavBarLayout>
    );
  }

  renderContents(appt: Appt) {
    return (
      <div>
        <EventDetails apptType={appt.type} slot={appt.slot} userZoneId={this.tzId()}/>
        {this.renderIcalButton()}
        {this.renderCancelButton()}
      </div>
    );
  }

  renderIcalButton() {
    if (!isCordova()) {
      return (
        <div>
          <Link to={this.downloadIcal}
            className="theme__primary-bg appt__ical-button">
            add to calendar
          </Link>
          <p className="appt__sidenote">(Outlook, Google, iCal, Other)</p>
        </div>
      );
    }
  }

  renderCancelButton() {
    return (
      <div>
        <Link to={this.cancelAppt}
          className="theme__primary-bg appt__cancel-button">
          cancel
        </Link>

        <p className="appt__sidenote">
          (you can schedule another available time after you cancel)
        </p>
      </div>
    );
  }
}

export default FeatureGate(
  defaultConnect(withRouter(ApptDetails)),
  ['appts']
);
