import './index.scss';

export enum Platform {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web'
}

export function ready(): Promise<{}> {
  return new Promise(resolve => {
    window.document.addEventListener('deviceready', resolve, false);
  });
}

export function isCordova(): boolean {
  return (window as any).__CORDOVA__ !== undefined;
}

export function isAndroid(): boolean {
  return platform() === Platform.ANDROID;
}

export function platform(): Platform {
  const device = window.device;
  if (device) {
    switch (device.platform.toLowerCase()) {
    case 'ios':
      return Platform.IOS;
    case 'android':
      return Platform.ANDROID;
    default:
      throw new Error(
        `unknown device platform: ${device.platform.toLowerCase()}`
      );
    }
  } else {
    return Platform.WEB;
  }
}

export function init() {
  if (isCordova()) {
    ready().then(() => {
      const splashscreen = window.navigator.splashscreen;
      const device = window.device || { platform: '' };
      splashscreen.hide();

      document.body.classList.add('cordova');
      document.body.classList.add(`cordova--${device.platform.toLowerCase()}`);
    });
  }
}
