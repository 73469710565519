import * as React from 'react';
import { State, StoreDispatch } from 'Store';
import { NavBarLayout } from 'Nav';
import * as Urls from 'Shared/Urls';
import { defaultConnect } from 'Shared/ReduxComponent';
import featureGate from 'App/UI/FeatureGate';

import {
  CrewMember, SEL as UserSEL, ActionCreators,
  loadableFeaturesEnabled
} from 'User';
import { ActionCreators as NavAC } from 'Nav';

import Tile from 'Shared/UI/Tile';

import CrewList from 'Community/UI/CrewList';

type Props = State & {
  dispatch: StoreDispatch
}

class Dashboard extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(ActionCreators.loadCrew());
  }

  crew(): CrewMember[] | undefined {
    return UserSEL.crewMembers(this.props.currentUser).value;
  }

  gotoCrewMember = (member: CrewMember) => {
    this.props.dispatch(
      NavAC.pushWithBreadcrumb(Urls.crewMemberDashboardUrl(member.user.id)));
  }

  showScore(): boolean {
    return loadableFeaturesEnabled(
      UserSEL.appFeatures(this.props.currentUser), ['healthScore']
    );
  }

  render() {
    return (
      <NavBarLayout title="Community" initBreadcrumb={Urls.dashboardUrl()}>
        <Tile
          title="feed"
          linkUrl={Urls.crewFeedUrl()}
          linkLabel="view feed"
          breadcrumb="push" />
        <Tile
          title="invite"
          linkUrl={Urls.communityInviteUrl()}
          linkLabel="invite"
          breadcrumb="push" />
        <CrewList
          showScore={this.showScore()}
          crew={this.crew()}
          onClick={this.gotoCrewMember}
          noCrew={this.renderNoCrew()}/>
      </NavBarLayout>
    );
  }

  renderNoCrew() {
    return (
      <p className="no-crew">
        Click invite above to invite people to your community!
      </p>
    );
  }
}

export default featureGate(
  defaultConnect(Dashboard),
  ['crew']
);

