import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { State, StoreDispatch } from 'Store';
import ActivityFeed from './ActivityFeed';
import { ActionCreators } from 'Post/ActionCreator';
import { dashboardUrl } from 'Shared/Urls';
import { NavBarLayout } from 'Nav';
import featureGate from 'App/UI/FeatureGate';

type Props = State & {
  dispatch: StoreDispatch
}

function renderCrewActivityFeed(props: Props): React.ReactElement<Props> {
  return (
    <NavBarLayout title="Community Feed" initBreadcrumb={dashboardUrl()}>
      <ActivityFeed
        feedType="crew"
        showUser={true}
        loadPage={loadPageFn(props)}
        {...props}
      />
    </NavBarLayout>
  );
}

export default featureGate(
  defaultConnect(renderCrewActivityFeed),
  ['crew']
);

function loadPageFn(props: Props): (page: number) => void {
  return page => props.dispatch(ActionCreators.loadCrewFeed(page));
}

