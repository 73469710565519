import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { bem } from 'css-util';
import { State, DispatchProps } from 'Store';
import {
  AuthSettings,
  SignupSubmission,
  State as AuthState
} from 'Auth/Data';
import * as Urls from 'Shared/Urls';
import Link from 'Shared/UI/Link';
import { isLoaded } from 'misc/Data/Loadable';
import { ActionCreators } from 'Auth/ActionCreator';

import AuthPage from './AuthPage';
import SignupForm from 'Auth/UI/SignupForm';
import AuthHelp from 'Auth/UI/AuthHelp';
import NativeAppLinks from 'Auth/UI/NativeAppLinks';
import AuthTermsAccept from 'Auth/UI/AuthTermsAccept';

type Props = DispatchProps & RouteComponentProps<URLParams> & {
  auth: AuthState
}

interface URLParams {
  programSlug: string
}

const CSS = bem('login-page');

class SignupPage extends React.Component<Props, {}> {
  componentDidMount() {
    this.checkIfSignupAllowed();
  }

  componentDidUpdate() {
    this.checkIfSignupAllowed();
  }

  checkIfSignupAllowed() {
    const settings = this.props.auth.settings;
    if (isLoaded(settings) &&
        settings.value.programSlug === this.props.match.params.programSlug &&
        !settings.value.publicSignup
    ) {
      this.props.dispatch(push(Urls.loginUrl()));
    }
  }

  submitSignup = (creds: SignupSubmission) => {
    return this.props.dispatch(
      ActionCreators.signup(this.props.match.params.programSlug, creds)
    );
  }

  render() {
    return (
      <AuthPage contents={this.renderForm} />
    );
  }

  renderForm = (settings: AuthSettings) => {
    return (
      <div>
        {settings.tagline &&
         <p className={CSS('tagline')()}>{settings.tagline}</p>}
        <SignupForm onSignup={this.submitSignup} />
        <NativeAppLinks links={settings.nativeApps} />
        {this.renderLoginLink(settings)}
        <AuthHelp settings={settings} />
        <AuthTermsAccept settings={settings} actionText="signing up" />
      </div>
    );
  }

  renderLoginLink(settings: AuthSettings) {
    return (
      <p className="auth__note">
        Already signed up?
        {' '}
        <Link to={Urls.loginUrl(settings.programSlug)}>
          Login
        </Link>
      </p>
    );
  }
}

export default withRouter(
  connect(
    (state: State) => ({ auth: state.auth }),
    dispatch => ({ dispatch })
  )(SignupPage)
);
