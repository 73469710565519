import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface Props {
  blur: boolean
}

class NavBarBodyOverlay extends React.Component<Props, {}> {
  overlayContainer: HTMLDivElement
  portalRoot: HTMLDivElement

  constructor(props: Props) {
    super(props);
    this.overlayContainer = document.createElement('div');
    this.portalRoot = (
      document.getElementById('portal-root') || makeOverlayRoot()
    ) as HTMLDivElement;
  }
  componentDidMount() {
    this.portalRoot.appendChild(this.overlayContainer);
  }

  componentWillUnmount() {
    this.portalRoot.removeChild(this.overlayContainer);
  }

  render() {
    return ReactDOM.createPortal(
      this.renderOverlay(),
      this.overlayContainer
    );
  }

  renderOverlay() {
    if (this.props.blur) {
      return <div className="nav-bar__body-overlay"/>;
    } else {
      return null;
    }
  }
}

export default NavBarBodyOverlay;

function makeOverlayRoot(): HTMLDivElement {
  const div = document.createElement('div') as HTMLDivElement;
  div.id = 'portal-root';
  document.body.appendChild(div);
  return div;
}
