import { push } from 'connected-react-router';
import { StoreDispatch } from 'Store'
import { isCordova } from 'Cordova';

export function initialize(dispatch: StoreDispatch) {
  if (!isCordova()) { return; }

  (window as any).handleOpenURL = function(url: string) {
    const path = getPath(url);
    if (path) { dispatch(push(path)); }
  }

  const URL_REGEX = /^onebios:\/(.*)$/;
  function getPath(url: string): string | undefined {
    const matches = url.match(URL_REGEX);
    if (matches) {
      return matches[1];
    }
  }
}
