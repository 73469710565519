import './index.scss';

export * from './Data';

export { ActionCreators } from './ActionCreator';
export { NSAction, Action, ActionType } from './Action';
export { reducer } from './Store';
// export { connectCallback } from './ConnectCallback';

import DefaultProviderIcon from './UI/DefaultProviderIcon';
import ConnectionList from './UI/ConnectionList';
export const UI = {
  DefaultProviderIcon,
  ConnectionList
};

import ConnectedDevices from './Page/ConnectedDevices';
import ConnectCallbackRenderFn from './Page/ConnectCallback';
import DeviceScan from './Page/DeviceScan';
import BLEDeviceSettings from './Page/BLEDeviceSettings'

export const Pages = {
  ConnectedDevices,
  ConnectCallbackRenderFn,
  DeviceScan,
  BLEDeviceSettings
};
