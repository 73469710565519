export function timeout<R>(
  ms: number,
  fn: (
    resolve: (r: R) => void,
    reject: (e?: any) => void
  ) => void,
  timeoutMessage = 'Timed out.'
): Promise<R> {
  return new Promise((resolve, reject) => {
    const t = window.setTimeout(() => { reject(timeoutMessage) }, ms);
    const resolveT = (r: R) => {
      window.clearTimeout(t);
      resolve(r);
    };
    const rejectT = (e?: any) => {
      window.clearTimeout(t);
      reject(e);
    }
    try {
      fn(resolveT, rejectT);
    } catch (e) {
      rejectT(e);
    }
  });
}

export function timeout_(
  ms: number,
  fn: (
    resolve: () => void,
    reject: (e?: any) => void
  ) => void
): Promise<void> {
  return timeout(ms, fn);
}

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => window.setTimeout(resolve, ms));
}

export type CancellationFunction = () => void;
export function cancellableTimeout(
  handler: () => void, ms: number
): CancellationFunction {
  let t = window.setTimeout(handler, ms);
  return () => window.clearTimeout(t);
}
