import * as React from 'react';
import { displayName as tzDisplayName } from 'Shared/TZ';

import * as Urls from 'Shared/Urls';

import AlertBox from 'Shared/UI/AlertBox';
import Link from 'Shared/UI/Link';

interface Props {
  userZoneId: string
}

const TimezoneNotice = (props: Props) => {
  return (
    <AlertBox modifiers={['info', 'round']}>
      Times are in: {tzDisplayName(props.userZoneId)} {changeLink()}
    </AlertBox>
  );
}

export default TimezoneNotice;

function changeLink(): React.ReactNode {
  return (
    <Link to={Urls.profileSettingsUrl()} breadcrumb="push" themed={true}>
      change
    </Link>
  );
}
