import * as React from 'react';
import { State, StoreDispatch } from 'Store';
import { hudState } from 'App/Data';
import { Goal, GoalStatus, SEL } from '../Data';
import { ActionCreators } from '../ActionCreator';
import { defaultConnect } from 'Shared/ReduxComponent';
import Spinner from 'Shared/UI/Spinner';

import { HUD } from 'HUD';
import GoalList from './GoalList';

type Props = State & {
  dispatch: StoreDispatch
}

class ConnectedGoalList extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(ActionCreators.loadGoals());
    this.props.dispatch(ActionCreators.loadStatusesForCurrentUser());
  }

  goals(): Goal[] | undefined {
    return SEL.goals(this.props.goals);
  }

  goalStatuses(): GoalStatus[] | undefined {
    const prof = this.props.currentUser.profile;
    if (prof) {
      return SEL.statuses(prof.id, this.props.goals);
    }
  }

  activateGoal = (goalId: string): void => {
    this.props.dispatch(ActionCreators.activateGoal(goalId));
  }

  deactivateGoal = (goalId: string): void => {
    this.props.dispatch(ActionCreators.deactivateGoal(goalId));
  }

  resetGoal = (goalId: string): void => {
    this.props.dispatch(ActionCreators.resetGoal(goalId));
  }

  render() {
    const goals = this.goals();
    const statuses = this.goalStatuses();

    if (goals === undefined || statuses === undefined) {
      return <p className="text-center"><Spinner></Spinner></p>;
    } else {
      return (
        <div>
          <GoalList
            goals={goals}
            statuses={statuses}
            activateGoal={this.activateGoal}
            deactivateGoal={this.deactivateGoal}
            resetGoal={this.resetGoal}/>
          <HUD state={hudState(this.props)}></HUD>
        </div>
      );
    }
  }
}

export default defaultConnect<{}>(ConnectedGoalList);
