import { DateJSON } from './Shared';
import { GenderJSON } from './User';
import { RiskLevelJSON } from './Risk';


/*------------------------------------------------------------*/
/* Screening reports */

export interface ReportListItemJSON {
  id: string,
  date: DateJSON
}

export interface ScreeningReportResponseJSON {
  report: ScreeningReportJSON
}

export interface ScreeningReportJSON {
  title: string
  date: DateJSON
  birthday: DateJSON | undefined
  name: string | undefined
  gender: GenderJSON | undefined
  wellness_goal: string | undefined
  signature: string | undefined
  risk_factors: RiskFactorJSON[]
  risk_charts: RiskChartJSON[]
  risk_level: RiskLevelJSON | undefined
  historical_table: HistoricalTableRowJSON[]
  value_table: ValueTableJSON
  survey: SurveyResponseJSON[]
  info_content: string
}

export interface SurveyResponseJSON {
  text: string,
  options: SurveyResponseOptionJSON[]
}
export interface SurveyResponseOptionJSON {
  label: string
  selected: boolean
  color: string
}

export type ValueTableJSON =
  { label: string, value: number }[]

export interface HistoricalTableRowJSON {
  date: DateJSON
  weight: number | undefined
  height: number | undefined
}

export interface RiskFactorJSON {
  title: string
  risk_level: RiskLevelJSON
}

export interface RiskChartJSON {
  title: string
  range: RiskChartRangeJSON,
  data: RiskChartDatumJSON[]
}

export interface RiskChartRangeJSON {
  best: number
  best_label: string
  worst: number
  worst_label: string
}

export interface RiskChartDatumJSON {
  date: DateJSON
  value: number
  value_label: string
}

/*------------------------------------------------------------*/
/* User screen definition */

export interface ScreenResponseJSON {
  screen: ScreenJSON
}

type ScreenFieldKeyJSON = string;
type ScreenFieldValueJSON = string | string[] | undefined;

export interface ScreenDataJSON {
  [key: string]: ScreenFieldValueJSON
}
export interface ScreenJSON {
  steps: ScreenStepJSON[],
  data: ScreenDataJSON
}

export type ScreenStepJSON = ScreenFieldJSON[]

export interface ScreenFieldJSON {
  key: ScreenFieldKeyJSON
  label: string
  hint: string | undefined
  options: ScreenFieldOptionJSON[]
  group: ScreenFieldGroupJSON
  type: ScreenFieldTypeJSON
  conditions: ScreenFieldConditionJSON[]
}

export interface ScreenFieldOptionJSON {
  label: string
  value: string
}

export enum ScreenFieldGroupJSON {
  PROFILE = 'profile',
  SPEC = 'spec',
  SURVEY = 'survey',
  META = 'meta',
  ANON = 'anon'
}

export enum ScreenFieldTypeJSON {
  TEXT = 'text',
  DATE = 'date',
  OPTION = 'option',
  BOOLEAN = 'boolean',
  HTML = 'html',
  OPTION_ARRAY = 'option_array'
}

export enum ScreenFieldConditionTypeJSON {
  IS = 'answer_is',
  IS_NOT = 'answer_is_not',
  GT = 'answer_is_gt',
  GTE = 'answer_is_gte',
  LT = 'answer_is_lt',
  LTE = 'answer_is_lte',
  EXISTS = 'answer_exists',
  NOT_EXISTS = 'answer_not_exists'
}

export interface ScreenFieldConditionWithValueJSON {
  type: ScreenFieldConditionTypeJSON.GT
    | ScreenFieldConditionTypeJSON.GTE
    | ScreenFieldConditionTypeJSON.LT
    | ScreenFieldConditionTypeJSON.LTE
    | ScreenFieldConditionTypeJSON.IS
    | ScreenFieldConditionTypeJSON.IS_NOT
  key: ScreenFieldKeyJSON
  value: string
}

export interface ScreenFieldConditionWithoutValueJSON {
  type: ScreenFieldConditionTypeJSON.EXISTS
    | ScreenFieldConditionTypeJSON.NOT_EXISTS
  key: ScreenFieldKeyJSON
}

export type ScreenFieldConditionJSON
  = ScreenFieldConditionWithValueJSON
  | ScreenFieldConditionWithoutValueJSON;
