import * as moment from 'moment-timezone';
import { ActionId } from './Data'
import { ActionCreatorThunk } from 'Store';
import { withNS, ActionType, NSAction } from './Action';
import { actionStatusFromJSON } from './JSON';

export interface ActionCreators {
  clearActionStatuses(): NSAction,
  loadActionStatuses(date: moment.Moment): ActionCreatorThunk
  undoTaskAction(date: moment.Moment, actionId: ActionId): ActionCreatorThunk
}

export const ActionCreators: ActionCreators = {
  clearActionStatuses,
  loadActionStatuses,
  undoTaskAction
};

function clearActionStatuses(): NSAction {
  return withNS({ type: ActionType.CLEAR_ACTION_STATUSES });
}

function loadActionStatuses(date: moment.Moment): ActionCreatorThunk {
  return (dispatch, _, { api }) => {
    dispatch(withNS({
      type: ActionType.LOAD_ACTION_STATUSES__BEGIN,
      date
    }));

    return api.carePlans.getActionStatuses(date).then(result => {
      const statuses = result.action_statuses.map(actionStatusFromJSON);

      dispatch(withNS({
        type: ActionType.LOAD_ACTION_STATUSES__SUCCESS,
        dailyStatus: { date, statuses }
      }));
    });
  }
}

function undoTaskAction(
  date: moment.Moment, actionId: ActionId
): ActionCreatorThunk {
  return (_dispatch, _getState, { api }) =>
    api.carePlans.undoTaskAction(date, actionId);
}
