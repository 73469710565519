import * as React from 'react';
import * as moment from 'moment-timezone';
import { plural } from 'Shared/Text';
import { challengeUrl, challengeTemplateUrl } from 'Shared/Urls';
import Badge from 'Shared/UI/Badge';
import ArrowLink from 'Shared/UI/ArrowLink';
import Icon from 'Dashboard/UI/Icon';
import ArrowLinkCaret from 'Shared/UI/ArrowLinkCaret';


import {
  ChallengeSummary,
  ChallengeTemplate,
  ChallengeProgress
} from 'Challenges/Data';
import * as Challenges from 'Challenges/Data';

type TileItem = ChallengeSummary | ChallengeTemplate

interface Props {
  item: TileItem
  noLink?: boolean
  onDashboard?: boolean
}

export default function renderTile(props: Props): React.ReactElement<Props> {
  const c = props.item;

  return (
    <div className={tileClassNames(c)} >
      {props.noLink ? tileContents(props) : tileLink(props)}
    </div>
  );
}

function tileLink(props: Props) {
  return (
    <ArrowLink to={itemUrl(props.item)}
      breadcrumb="push"
      className="challenge-tile__arrow-link">
      {tileContents(props)}
    </ArrowLink>
  );
}

function tileContents(props: Props) {
  const {item, onDashboard} = props;
  return (
    <div className ="dashboard-tile__left">
      <div className="dashboard-tile__left-content">
        {tileImage(item.graphicUrl, onDashboard)}
        <div className="challenge-tile__center">
          <h3 className="challenge-tile__name">
            {item.name}
          </h3>
          <div>
            {quickStat(item)}
          </div>
        </div>
      </div>
      <ArrowLinkCaret />
    </div>
  );
}

function itemUrl(item: TileItem): string {
  if (Challenges.isChallengeSummary(item)) {
    return challengeUrl(item.id);
  } else if (Challenges.isTemplate(item)) {
    return challengeTemplateUrl(item.id);
  } else {
    throw new Error('unreachable')
  }
}

function tileClassNames(
  c: ChallengeSummary | ChallengeTemplate
): string {
  const base = ['challenge-tile'];
  if (Challenges.isChallengeSummary(c)) {
    base.push('challenge-tile--challenge');
    base.push(`challenge-tile--${Challenges.timeStatus(c)}`);
  } else if (Challenges.isTemplate(c)) {
    base.push('challenge-tile--template');
  }
  return base.join(' ');
}

function quickStat(
  c: ChallengeSummary | ChallengeTemplate
): React.ReactNode {
  if (Challenges.isChallengeSummary(c)) {
    return challengeSummaryQuickStat(c);
  } else if (Challenges.isTemplate(c)) {
    return templateSummaryQuickStat(c);
  }
}

function challengeSummaryQuickStat(
  c: ChallengeSummary
): React.ReactNode {
  const dates = formatChallengeDates(c);
  if (Challenges.isUpcoming(c) || c.progress === undefined) {
    // Future challenge
    return dates;
  } else {
    return [
      dates,
      <br key="br" />,
      <span
        key="progress"
        className="simple-dashboard-tile__quick-stat challenge-tile__quick-stat"
      >
        {describeProgress(c.progress)}
      </span>
    ];
  }
}

function formatChallengeDates(c: ChallengeSummary): React.ReactNode {
  const start = formatDate(c.startDate);
  const end = formatDate(c.endDate);
  return dateBadge(start === end ? start : `${start} - ${end}`);
}

function templateSummaryQuickStat(
  tpl: ChallengeTemplate
): React.ReactNode {
  return dateBadge(tpl.description);
}

function dateBadge(content: string): React.ReactNode {
  return (
    <Badge key="dates" className="challenge-tile__dates">
      {content}
    </Badge>
  );
}

function formatDate(
  date: moment.Moment
): string {
  return date.format('MMM D, YYYY');
}

function describeProgress(
  progress: ChallengeProgress
): React.ReactNode {
  if (Challenges.isStepProgress(progress)) {
    return `${progress.stepCount.toLocaleString()} steps`;
  } else if (Challenges.isHabitProgress(progress)) {
    const count = progress.postCount;
    return plural('post', count);
  } else if (Challenges.isTaskProgress(progress)) {
    return plural('point', progress.totalPoints);
  }
}

function tileImage(graphicUrl: string, onDashboard?: boolean): React.ReactNode {
  if (onDashboard) {
    return(
      <div className="dashboard-tile__icon">
        <Icon name="trophy-alt" />
      </div>
    );
  } else {
    return(
      <div className="challenge-tile__left">
        <img src={graphicUrl} />
      </div>
    )
  }
}
