import * as moment from 'moment-timezone';
import * as R from 'ramda';

const PRETTY_ZONES: { [zoneId: string]: string } = {
  'Pacific/Honolulu': 'Hawaii',
  'America/Juneau': 'Alaska',
  'America/Los_Angeles': 'Pacific Time',
  'America/Phoenix': 'Arizona',
  'America/Denver': 'Mountain Time',
  'America/Chicago': 'Central Time',
  'America/New_York': 'Eastern Time',
  'America/Indiana/Indianapolis': 'Indiana (East)'
};

let _priorityZones: ZoneInfo[];
let _nonPriorityZones: ZoneInfo[];
let _allZones: ZoneInfo[];

type ZoneId = string;
type ZoneGroup = 'US Zones' | 'Other Zones';

interface ZoneInfo {
  id: ZoneId,
  label: string,
  group: ZoneGroup
}

function friendlyName(name: ZoneId): string {
  const parts = name.split('/').map(n => n.replace('_', ' '));
  const region = parts.shift();
  if (parts.length === 0) {
    return region ? region : '';
  } else {
    return `${region} - ${parts.reverse().join(', ')}`;
  }
}

export function priorityZones() {
  if (!_priorityZones) {
    _priorityZones = R.map<string, ZoneInfo>(
      zoneId => ({
        id: zoneId, label: PRETTY_ZONES[zoneId], group: 'US Zones'
      }),
      R.keys(PRETTY_ZONES).map(z => z.toString())
    )
  }
  return _priorityZones;
}

export function nonPriorityZones() {
  if (!_nonPriorityZones) {
    _nonPriorityZones = moment.tz.names()
      .filter(name => !PRETTY_ZONES[name])
      .map<ZoneInfo>(name => ({
        id: name,
        label: friendlyName(name),
        group: 'Other Zones'
      }));
  }
  return _nonPriorityZones;
}

export function allZones() {
  if (!_allZones) {
    _allZones = priorityZones()
      .concat(nonPriorityZones());
  }
  return _allZones;
}

export function displayName(tzId: ZoneId): string {
  return PRETTY_ZONES[tzId] || friendlyName(tzId);
}
