import { IDMap, newIDMap, idMapLookup } from 'Shared/Data/IDMap';
import { Loadable, loadable } from 'misc/Data/Loadable';
import { UserId, MinimalUser, ScreeningStatus, InboxStatus } from 'User';
import { ChallengeInvitation } from 'Challenges';
import { ReportListItem as ScreeningReportListItem } from 'Screenings/Data';
import { Biopost } from 'Post';
import { ActiveGoal } from 'Goals';
import { ChallengeSummary } from 'Challenges';
import { RewardPlan } from 'Rewards';
import { DailyStatus as CarePlanDailyStatus } from 'CarePlans/Data';

export type NSType = 'DASHBOARD';
export const NS: NSType = 'DASHBOARD';

export interface State {
  myDashboard: Loadable<MyDashboard>
  userDashboards: IDMap<UserDashboard>
}

export type MyDashboard = UserDashboard & {
  crewRequests: MinimalUser[],
  challengeInvitations: ChallengeInvitation[],
  lastScreening: ScreeningReportListItem | undefined,
  screeningStatus: ScreeningStatus,
  inboxStatus: InboxStatus,
  rewardPlan: RewardPlan | undefined,
  carePlanStatus: CarePlanDailyStatus | undefined
}

export interface UserDashboard {
  user: MinimalUser,
  lastPost: Biopost | undefined,
  activeGoal: ActiveGoal | undefined,
  challenge: ChallengeSummary | undefined
}

export function initialState(): State {
  return {
    myDashboard: loadable(),
    userDashboards: newIDMap()
  }
}

export const SEL = {
  userDashboard(userId: UserId, state: State): Loadable<UserDashboard> {
    const d = idMapLookup(userId, state.userDashboards);
    return d ? loadable(d) : loadable();
  }
};
