export enum OpName {
  PROTOCOL_VERSION = 'PROTOCOL_VERSION',
  SELECT = 'SELECT',
  CREATE = 'CREATE',
  CRC = 'CRC',
  EXECUTE = 'EXECUTE',
  MTU = 'MTU',
  ABORT = 'ABORT'
}

export const Op: { [k in OpName]: number } = {
  SELECT: 0x06,
  CREATE: 0x01,
  CRC: 0x03,
  EXECUTE: 0x04,
  MTU: 0x07,
  ABORT: 0x0C,
  PROTOCOL_VERSION: 0x00
}

export enum ObjectType {
  INIT_PACKET = 0x01,
  FIRMWARE_IMAGE = 0x02
}

export enum Result {
  SUCCESS = 0x01
}

export interface SelectData {
  maxSize: number,
  crc32: number,
  offset: number
}

export interface CRCData {
  offset: number,
  crc32: number
}

export interface MTUData {
  mtu: number
}

export interface VersionData {
  version: number
}

export const SERVICE_UUID = 'FE59';
export const CONTROL_UUID = "8ec90001-f315-4f60-9fb8-838830daea50";
export const PACKET_UUID = "8ec90002-f315-4f60-9fb8-838830daea50";
