import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';
import { NSType, NS, AuthSettings } from './Data';

export enum ActionType {
  LOGIN__SUCCESS = 'LOGIN__SUCCESS',
  LOGOUT = 'LOGOUT',
  SET_AUTH_PROGRAM_SLUG = 'SET_AUTH_PROGRAM_SLUG',
  LOAD_AUTH_SETTINGS__SUCCESS = 'LOAD_AUTH_SETTINGS__SUCCESS',
  REFRESH_TOKEN = 'REFRESH_TOKEN'
}

export type Action
  = Login_Success
  | Logout
  | LoadAuthSettings_Success
  | SetAuthProgramSlug
  | RefreshToken;

export interface Login_Success {
  type: ActionType.LOGIN__SUCCESS,
  token: string
}

export interface Logout {
  type: ActionType.LOGOUT
}

export interface LoadAuthSettings_Success {
  type: ActionType.LOAD_AUTH_SETTINGS__SUCCESS,
  settings: AuthSettings
}

export interface SetAuthProgramSlug {
  type: ActionType.SET_AUTH_PROGRAM_SLUG,
  slug: string
}

export interface RefreshToken {
  type: ActionType.REFRESH_TOKEN,
  token: string
}

export type NSAction = WithNS<NSType, Action>;
export const withNS = originalWithNS<NSType, Action>(NS)
