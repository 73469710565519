import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { Loadable, fromLoadable } from 'misc/Data/Loadable';
import { Maybe, maybe } from 'misc/Data/Maybe';
import { NavBarLayout } from 'Nav';
import { State, DispatchProps } from 'Store';
import { RewardPlan, UserAction } from 'Rewards/Data';
import { ActionCreators } from 'Rewards/ActionCreator';
import Spinner from 'Shared/UI/Spinner';

import * as Urls from 'Shared/Urls';

import RewardPlanSummary from 'Rewards/UI/RewardPlanSummary';
import RewardActivity from 'Rewards/UI/RewardActivity';

type Props = State & DispatchProps;

class RewardsDashboard extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(ActionCreators.loadRewardPlan());
    this.props.dispatch(ActionCreators.loadUserActions());
  }

  loadableRewardPlan(): Loadable<Maybe<RewardPlan>> {
    return this.props.rewards.rewardPlan;
  }

  loadableUserActions(): Loadable<UserAction[]> {
    return this.props.rewards.userActions;
  }

  render() {
    return (
      <NavBarLayout title="Rewards" initBreadcrumb={Urls.dashboardUrl()}>
        {fromLoadable<Maybe<RewardPlan>, React.ReactNode>(
           this.renderMaybePlanInfo,
           this.renderSpinner,
           this.loadableRewardPlan()
         )}
        {fromLoadable<UserAction[], React.ReactNode>(
           this.renderActivity,
           this.renderSpinner,
           this.loadableUserActions()
         )}
      </NavBarLayout>
    );
  }

  renderMaybePlanInfo = (maybePlan: Maybe<RewardPlan>) => {
    return maybe<RewardPlan, React.ReactNode>(
      () => 'no plan!',
      this.renderPlanInfo,
      maybePlan
    );
  }

  renderPlanInfo = (plan: RewardPlan) => {
    return <RewardPlanSummary rewardPlan={plan} />;
  }

  renderActivity = (actions: UserAction[]) => {
    return (
      <RewardActivity userActions={actions} dispatch={this.props.dispatch} />
    );
  }

  renderSpinner = () => <Spinner wrap="center" />
}

export default defaultConnect(RewardsDashboard);
