import * as React from 'react';

import { ScreenFieldType } from 'Screenings/Data';
import { Props } from './FieldProps';

import TextField from './TextField';
import OptionField from './OptionField';
import DateField from './DateField';
import HTMLField from './HTMLField';

const Field = (props: Props) => {
  const field = props.field;

  if (field.type === 'date') {
    return <DateField {...props} />;
  } else if (
    field.type === ScreenFieldType.OPTION ||
    field.type === ScreenFieldType.BOOLEAN ||
    field.type === ScreenFieldType.OPTION_ARRAY
  ) {
    if (
      field.group === 'survey' ||
      field.type === ScreenFieldType.OPTION_ARRAY
    ) {
      return <OptionField {...props} inline={false} />;
    } else {
      return <OptionField {...props} inline={true}/>;
    }
  } else if (field.type === ScreenFieldType.HTML) {
    return <HTMLField {...props}/>;
  } else {
    return <TextField {...props}/>;
  }
}

export default Field;
