import * as moment from 'moment-timezone';
import * as R from 'ramda';
import * as Post from 'Post';
import { Lens, Prism } from '@atomic-object/lenses';
import { Loadable, loadable, loadableMap } from 'misc/Data/Loadable';
import {
  IDMap, IDMapWithKey,
  newIDMap, idMapLookupPrism, idMapLookup, idMapValuesIsomorphism
} from 'Shared/Data/IDMap';
import { HTML } from 'Shared/Data/HTML';
import { ProviderKey as EdgeProviderKey } from 'Edge/Data';
import { CategoryWithTasks, TaskId } from 'Tasks/Data';
import { UserId } from 'User/Data';
import { SpecType } from 'Specs';

/** Namespace constant **/

export type NSType = 'CHALLENGES';
export const NS: NSType = 'CHALLENGES';

/** State data types **/

export type ChallengeId = number;

export type TemplateId = string;

export interface State {
  enrolled: Loadable<ChallengeSummary[]>,
  available: Loadable<ChallengeSummary[]>,
  completed: {
    challenges: Loadable<ChallengeSummary[]>,
    hasMorePages: boolean
  },
  templates: ChallengeTemplate[],
  invitations: Loadable<ChallengeInvitation[]>,
  rules: {
    challenge: RulesMap,
    template: RulesMap
  },
  challenges: { [id: string]: ChallengeDetails },
  currentLeaderboard: ChallengeLeaderboard,
  currentTaskChallengeDayDetail: undefined | TaskChallengeDayDetail,
  currentTaskChallengeDaySummaries: undefined | TaskChallengeDaySummary[],

  // IDMap of challengeId -> (IDMap of team id -> Team)
  teams: IDMap<IDMap<Team>>,
  // Map teamId -> player list
  teamPlayers: IDMap<Player[]>,

  // Map ChallengeId -> TeamProgress[]
  teamLeaderboards: IDMapWithKey<ChallengeId, TeamLeaderboard>
}


type RulesMap = IDMap<HTML>;

export interface TaskChallengeDayDetail {
  date: moment.Moment,
  locked: boolean,
  completedTaskIds: TaskId[],
  availableTaskIds: TaskId[]
}

export interface ChallengeLeaderboard {
  challengeId: ChallengeId | undefined,
  players: Player[] | undefined,
  page: number,
  hasMore: boolean
}

export interface TeamLeaderboard {
  challengeId: ChallengeId
  players: TeamPlayer[],
  page: number,
  hasMore: boolean
}

export type Leaderboard = ChallengeLeaderboard | TeamLeaderboard;

export type LeaderboardPrivacy = 'public' | 'crew' | 'private';

export interface ChallengeInvitation {
  id: string,
  challengeId: ChallengeId,
  challengeName: string,
  metric: ChallengeMetric,
  startDate: moment.Moment,
  endDate: moment.Moment,
  graphicUrl: string
}

export interface ChallengeSummary {
  id: ChallengeId,
  name: string,
  graphicUrl: string,
  metric: ChallengeMetric,
  progress: ChallengeProgress | undefined,
  startDate: moment.Moment,
  endDate: moment.Moment,
  joinStartDate: moment.Moment,
  joinEndDate: moment.Moment,
  tzId: string,
  playerCount: number,
  myTeamId: TeamId | undefined,
  leaderboardPrivacy: LeaderboardPrivacy,
  challengeStepCount: number
}

export interface ChallengeTemplate {
  id: string,
  name: string,
  graphicUrl: string,
  metric: ChallengeMetric,
  description: string,
  startDateRightNow: moment.Moment,
  endDateRightNow: moment.Moment
}

export interface ChallengeDetails {
  reloading: boolean
  summary: ChallengeSummary
  actionDetails: ActionDetails
  topPlayers: Player[]
  milestones: Milestone[]
}

export enum MetricType {
  Steps,
  Habit,
  Spec,
  Task
}

export type ChallengeMetric
  = StepMetric
  | HabitMetric
  | SpecMetric
  | TaskMetric;

export interface StepMetric {
  type: MetricType.Steps
}

export interface HabitMetric {
  type: MetricType.Habit
}

export interface TaskMetric {
  type: MetricType.Task
  completionFrequency: TaskCompletionFrequency
}

export interface SpecMetric {
  type: MetricType.Spec
  deltaType: DeltaType
  specType: SpecType
}

export enum DeltaType {
  RELATIVE,
  ABSOLUTE
}

export enum TaskCompletionFrequency {
  DAILY,
  ONCE
}

export type ChallengeProgress
  = StepChallengeProgress
  | HabitChallengeProgress
  | SpecChallengeProgress
  | TaskChallengeProgress;

export interface StepChallengeProgress {
  stepCount: number,
  source: EdgeProviderKey,
  sourceIcon?: string | undefined // url
}

export interface HabitChallengeProgress {
  postCount: number,
  lastPostTime: moment.Moment | undefined
}

export interface SpecChallengeProgress {
  delta: number,
  currentValue: number,
  lastPostTime: moment.Moment | undefined
}

export interface TaskChallengeProgress {
  totalPoints: number,
  dailyPoints: DailyPointsMap
}

type DailyPointsMap = { [date: string]: number };

export type TimeStatus = 'upcoming' | 'current' | 'complete';

export type ActionDetails
  = StepActionDetails
  | HabitActionDetails
  | TaskActionDetails;

export interface StepActionDetails {
  steps: {}
}

export interface HabitActionDetails {
  habit: {
    activities: Post.Activity[]
  }
}

export interface TaskActionDetails {
  task: {
    categorizedTasks: CategoryWithTasks[],
    taskPoints: { [taskId: number]: number }
  }
}

export interface Milestone {
  name: string,
  miles: number,
  position: number
}

export interface Player {
  rank: number
  userId: string
  userName: string
  progress: ChallengeProgress
}

export interface TaskChallengeDaySummary {
  date: moment.Moment,
  categoryPoints: { [categoryName: string]: number }
}

// Teams
export type TeamId = number

export interface Team {
  id: TeamId
  name: string,
  captainId: UserId
}

export interface TeamPlayer {
  rank: number,
  team: Team
  progress: ChallengeProgress
}

export interface TeamFormData {
  name: string
}

// Used to work with teams or players polymorphically in leaderboard. TODO:
// Player should be renamed to UserPlayer, and this union should jsut be called
// Player
export type ChallengePlayer = Player | TeamPlayer;

export function newTeamFormData(team?: Team | undefined): TeamFormData {
  return { name: team ? team.name : '' };
}

export type LeaderboardMode = 'players' | 'teams';

// Some normalized data between challenges and templates

export type NormalizableChallengeObject
  = ChallengeDetails
  | ChallengeSummary
  | ChallengeTemplate
  | ChallengeInvitation;

export interface NormalizedChallengeData {
  name: string,
  graphicUrl: string,
  startDate: moment.Moment,
  endDate: moment.Moment,
  metric: ChallengeMetric
}

export function normalizedData(
  item: NormalizableChallengeObject
): NormalizedChallengeData {
  if (isChallengeDetails(item)) {
    return normalizedData(item.summary);
  } else if (isChallengeSummary(item)) {
    return {
      name: item.name,
      graphicUrl: item.graphicUrl,
      startDate: item.startDate,
      endDate: item.endDate,
      metric: item.metric
    };
  } else if (isTemplate(item)) {
    return {
      name: item.name,
      graphicUrl: item.graphicUrl,
      startDate: item.startDateRightNow,
      endDate: item.endDateRightNow,
      metric: item.metric
    };
  } else if (isInvitation(item)) {
    return {
      name: item.challengeName,
      graphicUrl: item.graphicUrl,
      startDate: item.startDate,
      endDate: item.endDate,
      metric: item.metric
    };
  } else {
    console.error('Item is neither summary nor template (should not happen)');
    throw new Error('Item is neither summary nor template (should not happen)');
  }
}

/** Functions **/

export function initialState(): State {
  return {
    enrolled: loadable(),
    available: loadable(),
    completed: {
      challenges: loadable(),
      hasMorePages: false
    },
    templates: [],
    invitations: loadable(),
    rules: {
      challenge: {},
      template: {}
    },
    challenges: {},
    currentLeaderboard: {
      challengeId: undefined,
      players: undefined,
      page: 1,
      hasMore: false
    },
    currentTaskChallengeDayDetail: undefined,
    currentTaskChallengeDaySummaries: undefined,
    teams: newIDMap(),
    teamPlayers: newIDMap(),
    teamLeaderboards: newIDMap()
  };
}

export function timeStatus(c: ChallengeSummary): TimeStatus {
  if (isComplete(c)) {
    return 'complete';
  } else if (isUpcoming(c)) {
    return 'upcoming';
  } else {
    return 'current';
  }
}

export function isComplete(c: ChallengeSummary | ChallengeDetails): boolean {
  const { endDate } = normalizedData(c);
  return endDate.isBefore(moment());
}

export function isUpcoming(c: ChallengeSummary | ChallengeDetails): boolean {
  const { startDate } = normalizedData(c);
  return startDate.isAfter(moment());
}

export function isCurrent(
  c: ChallengeSummary | ChallengeDetails,
  now?: moment.Moment
): boolean {
  now = now || moment();
  const { startDate, endDate } = normalizedData(c);
  return startDate.isSameOrBefore(now) && endDate.isSameOrAfter(now);
}

export function isJoinable(c: ChallengeSummary): boolean {
  const now = moment();
  return c.joinStartDate.isBefore(now) && c.joinEndDate.isAfter(now);
}

export function isChallengeSummary(
  c: NormalizableChallengeObject
): c is ChallengeSummary {
  const summ = c as ChallengeSummary;
  return summ.startDate !== undefined && summ.playerCount !== undefined;
}

export function isInvitation(
  c: NormalizableChallengeObject
): c is ChallengeInvitation {
  return (c as ChallengeInvitation).challengeName !== undefined;
}

export function isTemplate(
  c: NormalizableChallengeObject
): c is ChallengeTemplate {
  return (c as ChallengeTemplate).startDateRightNow !== undefined;
}

export function isChallengeDetails(
  c: NormalizableChallengeObject
): c is ChallengeDetails {
  return (c as ChallengeDetails).topPlayers !== undefined;
}

export function isStepProgress(
  progress: ChallengeProgress | undefined
): progress is StepChallengeProgress {
  return progress !== undefined &&
    (progress as StepChallengeProgress).stepCount !== undefined;
}

export function isHabitProgress(
  progress: ChallengeProgress | undefined
): progress is HabitChallengeProgress {
  return progress !== undefined &&
    (progress as HabitChallengeProgress).postCount !== undefined;
}

export function isSpecProgress(
  progress: ChallengeProgress | undefined
): progress is SpecChallengeProgress {
  return progress !== undefined &&
    (progress as SpecChallengeProgress).delta !== undefined;
}

export function isTaskProgress(
  progress: ChallengeProgress | undefined
): progress is TaskChallengeProgress {
  return progress !== undefined &&
    (progress as TaskChallengeProgress).totalPoints !== undefined &&
    (progress as TaskChallengeProgress).dailyPoints !== undefined;
}

export function isStepActionDetails(
  actionDetails: ActionDetails
): actionDetails is StepActionDetails {
  return (actionDetails as StepActionDetails).steps !== undefined;
}

export function isHabitActionDetails(
  actionDetails: ActionDetails
): actionDetails is HabitActionDetails {
  return (actionDetails as HabitActionDetails).habit !== undefined;
}

export function isTaskActionDetails(
  actionDetails: ActionDetails
): actionDetails is TaskActionDetails {
  return (actionDetails as TaskActionDetails).task !== undefined;
}

export function isTaskMetric(
  metric: ChallengeMetric
): metric is TaskMetric {
  return metric.type === MetricType.Task;
}

export function isTaskMetricWithDailyCompletion(
  metric: ChallengeMetric
): metric is TaskMetric {
  return isTaskMetric(metric) &&
    metric.completionFrequency === TaskCompletionFrequency.DAILY;
}

export function isTaskMetricWithOnceCompletion(
  metric: ChallengeMetric
): metric is TaskMetric {
  return isTaskMetric(metric) &&
    metric.completionFrequency === TaskCompletionFrequency.ONCE;
}

export function metricTypeFromProgress(
  progress: ChallengeProgress
): MetricType {
  if (isStepProgress(progress)) {
    return MetricType.Steps;
  } else if (isHabitProgress(progress)) {
    return MetricType.Habit;
  } else if (isSpecProgress(progress)) {
    return MetricType.Spec;
  } else {
    throw new Error(`Unhandled progress type: ${JSON.stringify(progress)}`);
  }
}

export function isSameProgress(
  p1: ChallengeProgress | undefined,
  p2: ChallengeProgress | undefined
): boolean {
  if (p1 === undefined || p2 === undefined) {
    return false;
  } else if (
    isStepProgress(p1) &&
      isStepProgress(p2) &&
      p1.stepCount === p2.stepCount
  ) {
    return true;
  } else if (
    isHabitProgress(p1) &&
      isHabitProgress(p2) &&
      p1.postCount === p2.postCount
  ) {
    return true;
  } else if (
    isSpecProgress(p1) && isSpecProgress(p2) && p1.delta === p2.delta
  ) {
    return true;
  } else {
    return false;
  }
}

export function requiresPreJoinSetup(
  c: NormalizableChallengeObject
): boolean {
  return metricRequiresPreJoinSetup(normalizedData(c).metric);
}

export function metricRequiresPreJoinSetup(
  metric: ChallengeMetric
): boolean {
  return metric.type === MetricType.Spec;
}

export function dailyPointsMapLookup(
  date: string | Date | moment.Moment, map: DailyPointsMap
): number {
  let key;
  if (typeof date === 'string') {
    key = date;
  } else {
    const m = moment(date);
    key = m.format('YYYY-MM-DD')
  }
  return map[key] || 0;
}

export function taskPointsLookup(
  taskId: TaskId, points: { [taskId: number]: number }
): number {
  return points[taskId] || 0;
}

export function totalAvailableDailyPoints(
  actionDetails: TaskActionDetails
): number {
  const tasks = R.chain(v => v.tasks, actionDetails.task.categorizedTasks);
  return R.sum(
    tasks.map(t => taskPointsLookup(t.id, actionDetails.task.taskPoints))
  );
}

export function taskIsCompleted(
  taskId: TaskId, dayDetail: TaskChallengeDayDetail
): boolean {
  return R.contains(taskId, dayDetail.completedTaskIds);
}

export function withTaskCompleted(
  taskId: TaskId, dayDetail: TaskChallengeDayDetail
): TaskChallengeDayDetail {
  return {
    ...dayDetail,
    availableTaskIds: R.without([taskId], dayDetail.availableTaskIds),
    completedTaskIds: R.uniq(R.append(taskId, dayDetail.completedTaskIds))
  };
}

export function withTaskUndone(
  taskId: TaskId, dayDetail: TaskChallengeDayDetail
): TaskChallengeDayDetail {
  return {
    ...dayDetail,
    completedTaskIds: R.without([taskId], dayDetail.completedTaskIds),
    availableTaskIds: R.uniq(R.append(taskId, dayDetail.availableTaskIds))
  };
}

export function withChallengeProgressPointsAdded(
  challenge: ChallengeDetails,
  taskId: TaskId,
  day: moment.Moment
): ChallengeDetails {
  return challengeWithUpdatedProgress(challenge, taskId, day, R.add);
}

export function withChallengeProgressPointsSubtracted(
  challenge: ChallengeDetails,
  taskId: TaskId,
  day: moment.Moment
): ChallengeDetails {
  return challengeWithUpdatedProgress(challenge, taskId, day, R.subtract);
}

export function challengeWithUpdatedProgress(
  challenge: ChallengeDetails,
  taskId: TaskId,
  day: moment.Moment,
  mathOperation: (a: number, b: number) => number
): ChallengeDetails {
  if (
    isTaskProgress(challenge.summary.progress)
      && isTaskActionDetails(challenge.actionDetails)
  ) {
    const summary = challenge.summary;
    const progress = challenge.summary.progress;
    const dailyPoints = progress.dailyPoints;

    const taskPoints = challenge.actionDetails.task.taskPoints[taskId];
    const dayIndex = day.format('YYYY-MM-DD');
    let newDayPointTotal = taskPoints;

    if (dailyPoints[dayIndex] !== undefined) {
      newDayPointTotal = mathOperation(dailyPoints[dayIndex], taskPoints)
    }

    return {
      ...challenge,
      summary: {
        ...summary,
        progress: {
          ...progress,
          dailyPoints: R.assoc(dayIndex, newDayPointTotal, dailyPoints)
        }
      }
    };
  } else {
    return challenge;
  }
}

export function daySummaryTotalPoints(
  summary: TaskChallengeDaySummary
): number {
  return R.sum(R.values(summary.categoryPoints));
}

export function userIsEnrolled(
  c: ChallengeDetails | ChallengeSummary
): boolean {
  const summary = isChallengeDetails(c) ? c.summary : c;
  return summary.progress !== undefined;
}

export function isUserPlayer(player: ChallengePlayer): player is Player {
  return (player as Player).userId !== undefined;
}

export function isTeamPlayer(player: ChallengePlayer): player is TeamPlayer {
  return (player as TeamPlayer).team !== undefined;
}

export function playerName(player: ChallengePlayer): string {
  if (isUserPlayer(player)) {
    return player.userName;
  } else {
    return player.team.name;
  }
}

export function teamsEnabled(c: ChallengeSummary | ChallengeDetails): boolean {
  const summary = isChallengeDetails(c) ? c.summary : c;
  return summary.leaderboardPrivacy === 'public';
}

/** Lenses **/

export interface Lenses {
  rules: (
    objectType: 'challenge' | 'template',
    objectId: string | ChallengeId
  ) => Prism<State, HTML>,
  challenge: (id: ChallengeId) => Lens<State, ChallengeDetails | undefined>,
  invitations: Lens<State, Loadable<ChallengeInvitation[]>>,
  leaderboard: Lens<State, ChallengeLeaderboard>,
  leaderboardPage: Lens<State, number>,
  leaderboardPlayers: Lens<State, Player[] | undefined>,
  leaderboardHasMore: Lens<State, boolean>,
  currentTaskChallengeDayDetail:
    Lens<State, TaskChallengeDayDetail | undefined>,
  currentTaskChallengeDaySummaries:
    Lens<State, TaskChallengeDaySummary[] | undefined>,
  teams: (id: ChallengeId) => Lens<State, Team[] | undefined>
}

function makeLenses(): Lenses {
  const base = Lens.from<State>();
  const leaderboard = base.prop('currentLeaderboard');
  const leaderboardPage = base.prop('currentLeaderboard', 'page');
  const leaderboardPlayers = base.prop('currentLeaderboard', 'players');
  const leaderboardHasMore = base.prop('currentLeaderboard', 'hasMore');

  return {
    leaderboardPage,
    leaderboardPlayers,
    leaderboardHasMore,
    leaderboard,
    invitations: base.prop('invitations'),
    challenge: (cid: ChallengeId): Lens<State, ChallengeDetails | undefined> =>
      base.prop('challenges', cid.toString()),
    rules: (
      objectType: 'challenge' | 'template',
      objectId: string | ChallengeId
    ): Prism<State, HTML> =>
      base.prop('rules', objectType, objectId.toString()),
    currentTaskChallengeDayDetail:
      base.prop('currentTaskChallengeDayDetail'),
    currentTaskChallengeDaySummaries:
      base.prop('currentTaskChallengeDaySummaries'),
    teams: (cid: ChallengeId) => Lens.map(
      Lens.comp(
        base.prop('teams'),
        idMapLookupPrism(cid.toString())
      ),
      idMapValuesIsomorphism(t => t.id.toString())
    )
  };
};

export const LENSES = makeLenses();

const getRules = R.curry(_getRules);
function _getRules(
  type: 'challenge' | 'template',
  id: string | number,
  state: State
): HTML | undefined {
  return state.rules[type][id.toString()];
}

const getTemplate = R.curry(_getTemplate);
function _getTemplate(
  templateId: TemplateId, state: State
): ChallengeTemplate | undefined {
  return R.find(
    t => t.id === templateId,
    state.templates
  );
}

const getAvailableById = R.curry(_getAvailableById);
function _getAvailableById(
  challengeId: ChallengeId, state: State
): Loadable<ChallengeSummary | undefined> {
  return loadableMap(
    availables => R.find(c => c.id === challengeId, availables),
    state.available
  );
}

const getInvitationById = R.curry(_getInvitationById);
function _getInvitationById(
  inviteId: string, state: State
): Loadable<ChallengeInvitation | undefined> {
  return loadableMap(
    invites => R.find(i => i.id === inviteId, invites),
    state.invitations
  );
}

export const SEL = {
  invitations(state: State): Loadable<ChallengeInvitation[]> {
    return state.invitations;
  },

  invitationById(
    inviteId: string, state: State
  ): Loadable<ChallengeInvitation | undefined> {
    return getInvitationById(inviteId, state);
  },

  getRules,

  getTemplate,

  getAvailableById,

  getInvitationById,

  teams(challengeId: ChallengeId, state: State): Loadable<Team[]> {
    const list = LENSES.teams(challengeId).get(state);
    return list === undefined ? loadable() : loadable(list);
  },

  team(
    challengeId: ChallengeId, teamId: TeamId, state: State
  ): Team | undefined {
    const teamsById = idMapLookup(challengeId, state.teams);
    if (teamsById !== undefined) {
      return idMapLookup(teamId, teamsById);
    }
  },

  teamPlayers(teamId: TeamId, state: State): Player[] | undefined {
    return idMapLookup(teamId, state.teamPlayers);
  },

  leaderboard(
    challengeId: ChallengeId, mode: LeaderboardMode, state: State
  ): TeamLeaderboard | ChallengeLeaderboard | undefined {
    switch(mode) {
      case 'teams':
        return idMapLookup(challengeId, state.teamLeaderboards);
      case 'players':
        return state.currentLeaderboard;
    }
  }
};
