import * as React from 'react';
import { bem } from 'css-util';

type Props = {
  label: React.ReactNode
  field: React.ReactNode,
  error?: React.ReactNode
}

const CSS = bem('horizontal-form-field');

function renderHorizontalFormField(props: Props): React.ReactElement<Props> {
  return (
    <div className={CSS()()}>
      <div className={CSS('left-col')()}>
        {props.label}
      </div>

      <div className={CSS('right-col')()}>
        {props.field}
      </div>
      {renderError(props.error)}
    </div>
  );
}

export default renderHorizontalFormField;

function renderError(error: React.ReactNode | undefined): React.ReactNode {
  if (error) {
    return (
      <div className="small-9 small-offset-3 columns">
        <p className="horizontal-form-field__error">
          {error}
        </p>
      </div>
    );
  }
}
