import { loadable } from 'misc/Data/Loadable';
import * as Data from './Data';
import { Action, ActionType } from './Action'

export function reducer(state: Data.State, action: Action): Data.State {
  switch(action.type) {
    case ActionType.LOAD_CONFIG__BEGIN:
      return { ...state, config: loadable(), steps: loadable() };
    case ActionType.LOAD_CONFIG__SUCCESS:
      return {
        ...state,
        config: loadable(action.config),
        steps: loadable(Data.steps(action.config))
      };
  }
}
