import { push } from 'connected-react-router';
import { BUILD_NUMBER } from 'Env';
import * as Urls from 'Shared/Urls';
import { AppStore } from 'Store';
import { ApiClientMiddleware, FetchParams } from 'Api';

export function makeApiMiddleware(store: AppStore): ApiClientMiddleware {
  return { request, response };

  function request(input: RequestInfo, init?: RequestInit): FetchParams {
    init = init || {};
    const headers = { ...(init.headers || {}), 'X-Bios-Build': BUILD_NUMBER };
    init = { ...init, headers };
    return [input, init];
  }

  function response(result: Promise<Response>): Promise<Response> {
    return result.then(r => {
      if (r.status === 410) {
        store.dispatch(push(Urls.forcedUpgradeUrl()));
      }
      return r;
    })
  }
}
