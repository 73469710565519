import * as React from 'react';
import { State, StoreDispatch } from 'Store';
import { RouteComponentProps } from 'react-router-dom';
import { defaultConnect } from 'Shared/ReduxComponent';

import * as Urls from 'Shared/Urls';
import Spinner from 'Shared/UI/Spinner';
import Tile from 'Shared/UI/Tile';

import { SEL as UserSEL } from 'User';
import { ActionCreators } from 'Goals/ActionCreator';
import { State as GoalState, ActiveGoal, SEL as GoalSEL } from 'Goals/Data';

import { NavBarLayout } from 'Nav';
import FeatureGate from 'App/UI/FeatureGate';
import ActiveGoalList from 'Goals/UI/ActiveGoalList';

type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch
};

interface URLParams {
  userId?: string
}

class Dashboard extends React.Component<Props, {}> {
  componentDidMount() {
    this.loadData(this.props.match.params.userId);
  }

  componentDidUpdate(prevProps: Props) {
    const oldUserId = prevProps.match.params.userId;
    const newUserId = this.props.match.params.userId;
    if (oldUserId !== newUserId) {
      this.loadData(newUserId);
    }
  }

  loadData(userId?: string) {
    if (userId) {
      this.props.dispatch(ActionCreators.loadStatusesForUser(userId));
    } else {
      this.props.dispatch(ActionCreators.loadStatusesForCurrentUser());
    }
  }

  goalState(): GoalState {
    return this.props.goals;
  }

  currentUserId(): string | undefined {
    const currentUser = this.props.currentUser;
    return UserSEL.userId(currentUser);
  }

  userId(): string | undefined {
    const userIdFromURL = this.props.match.params.userId;
    return userIdFromURL || this.currentUserId();
  }

  isMe(): boolean {
    return this.props.match.params.userId === undefined;
  }

  activeGoals(): ActiveGoal[] | undefined {
    const uid = this.userId();
    if (uid) {
      return GoalSEL.activeGoals(uid, this.goalState());
    } else {
      return undefined;
    }
  }

  initBreadcrumb(): string | undefined {
    const userId = this.userId();
    if (this.isMe()) {
      return Urls.dashboardUrl();
    } else if (userId) {
      return Urls.crewMemberDashboardUrl(userId);
    }
  }

  render() {
    const activeGoals = this.activeGoals();
    const userId = this.userId();
    if (activeGoals && userId) {
      return this.renderLayout(
        <div>
          {this.isMe() &&
           <Tile
             title="set a goal"
             linkUrl={Urls.goalsSettingsUrl()}
             linkLabel="goal settings"
             breadcrumb="push"/>
          }
          <ActiveGoalList activeGoals={activeGoals} isMe={this.isMe()}/>
        </div>
      );
    } else {
      return this.renderLayout(<Spinner wrap="center" />);
    }
  }

  renderLayout(content: React.ReactNode) {
    return (
      <NavBarLayout title="goals" initBreadcrumb={this.initBreadcrumb()}>
        {content}
      </NavBarLayout>
    );
  }
}

export default FeatureGate(
  defaultConnect(Dashboard),
  ['goals']
);
