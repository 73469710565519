import * as React from 'react';
import { State, DispatchProps } from 'Store';
import { DeviceType } from 'Edge/Data'
import * as BLE from 'BLE/Data';
import BPCalibrationForm from 'Edge/UI/DeviceSettings/BloodPressureCalibration';
import FirmwareUpdate from 'Edge/UI/DeviceSettings/FirmwareUpdate';
import { ActionCreators as BLEAC } from 'BLE/ActionCreator';
import NoBluetooth from 'BLE/UI/NoBluetooth';

type Props = State & DispatchProps & {
  deviceType: DeviceType,
  deviceId: string
}

class JStyle1963YHDeviceSettings extends React.Component<Props, {}> {
  componentDidMount() {
    this.recheckFirmwareStatus();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.hasBLE() && !this.hasBLE(prevProps)) {
      this.recheckFirmwareStatus();
    }
  }

  submitBPCalibration = (systolic: number, diastolic: number) => {
    return this.props.dispatch(
      BLEAC.JStyle1963YH.sendBPCalibration(
        this.props.deviceType, this.device(), systolic, diastolic
      )
    );
  }

  performFirmwareUpgrade = () => {
    return this.props.dispatch(
      BLEAC.JStyle1963YH.upgradeFirmware(
        this.props.deviceType, this.device()
      )
    );
  }

  recheckFirmwareStatus = () => {
    if (this.hasBLE()) {
      return this.props.dispatch(
        BLEAC.JStyle1963YH.checkFirmware(
          this.props.deviceType, this.device()
        )
      );
    }
  }

  device(): BLE.Device {
    return {
      name: this.props.deviceType.name,
      address: this.props.deviceId
    };
  }

  deviceProtocol(): BLE.DeviceProtocol | undefined {
    return this.props.deviceType.protocol;
  }

  hasBLE(props?: Props): boolean {
    props ||= this.props;
    return BLE.hasBLE(props.ble);
  }

  firmwareStatus(): BLE.FirmwareStatus | undefined {
    return this.props.ble.firmwareStatuses[this.device().address];
  }

  render() {
    if (this.hasBLE()) {
      return this.renderWithBluetooth();
    } else {
      return <div className="simple-container"><NoBluetooth /></div>;
    }
  }

  renderWithBluetooth() {
    return (
      <>
        <h4 className="section-title">Blood Pressure Calibration</h4>
        <BPCalibrationForm submit={this.submitBPCalibration} />

        <h4 className="section-title">Firmware Update</h4>
        <FirmwareUpdate
          firmwareStatus={this.firmwareStatus()}
          upgrade={this.performFirmwareUpgrade}
          refresh={this.recheckFirmwareStatus}
        />
      </>
    )
  }
}

export default JStyle1963YHDeviceSettings;
