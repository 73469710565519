import { ObjectType, Op } from './Data';
import { lsbEncode } from 'BLE/Util';

class CommandBuilder {
  select(objectType: ObjectType): Uint8Array {
    return Uint8Array.of(Op.SELECT, objectType);
  }

  create(objectType: ObjectType, size: number): Uint8Array {
    let cmd = new Uint8Array(6)
    cmd.set([Op.CREATE, objectType]);
    cmd.set(lsbEncode(size, 4), 2);
    return cmd;
  }

  mtu(): Uint8Array {
    return Uint8Array.of(Op.MTU);
  }

  crc(): Uint8Array {
    return Uint8Array.of(Op.CRC);
  }

  execute(): Uint8Array {
    return Uint8Array.of(Op.EXECUTE);
  }

  abort(): Uint8Array {
    return Uint8Array.of(Op.ABORT);
  }

  protocolVersion(): Uint8Array {
    return Uint8Array.of(Op.PROTOCOL_VERSION);
  }
}

export default CommandBuilder;
