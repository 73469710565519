import { DeviceSettings } from '../Data';

/*
Command response:
Check right and execute OK, then return:
0x04 AA BB CC DD EE FF GG HH II JJ KK LL MM NN CRC
AA: Distance unit: 0x01: MILE 0x00: KM
BB: 12 hours and 24 hours display, 0x01: 12-hour display, 0x00: 24-hour display.
CC: Raise your wrist to brighten the screen, 0x01: enable. 0x00: close
DD: Temperature unit switch, 0x01: Fahrenheit, 0x00: Celsius, default setting is
  Celsius.
EE: Night mode, 0x01 on, 0x00: off
  (After night mode is turned on, from 6 pm to 7 am, the display brightness is
  automatically adjusted to the darkest.)
FF: ANCS enable switch, 0x01: enable, 0x00: close
GG HH: ANCS information switch, totally 15 bytes. The highest byte is used to
  control if needed to set separate information switch. The detailed
  sub-switches are arranged as follows, the top one is the lowest byte:
  0. Incoming call (the lowest byte), 1. Mobile phone information, 2. WeChat,
  3. Facebook, 4. Instagram, 5. Skype, 6. telegram, 7. tweetie2, 8. Vkclient, 9,
  WhatsApp, 10. QQ 11. LinkedIn
II: Basic heart rate setting, the highest byte is 1 and the value must exceed
  40.
JJ: Reserved byte.
KK: Screen brightness adjustment, adjustment range 0-f (0 is the brightest, f is
the darkest).
LL: Watch main interface replacement 0-04 (for model 1963-01); 0-05 (for model
  1963YH & 1963D)
MM: Social distance alert switch, 0x01 on; 0x00 off.
NN: Chinese and English language switching, 00 for English (default), 01 for
  Chinese.
  */
export function decode(packet: Uint8Array): DeviceSettings {
  return {
    distanceUnit: packet[1] === 0 ? 'km' : 'mile',
    timeFormat: packet[2] === 0 ? 24 : 12,
    raiseWristEnabled: packet[3] === 1,
    temperatureUnit: packet[4] === 0 ? 'C' : 'F',
    nightModeEnabled: packet[5] === 1,
    basicHeartRate: packet[9],
    screenDarkness: packet[11],
    watchFace: packet[12],
    socialDistanceAlertEnabled: packet[13] === 1
  };
}

/**
 * Command Format:
 0x03 AA BB CC DD EE FF GG HH II JJ KK LL MM NN CRC
 Function: Write the basic parameters to the device.
Description:
AA: Distance unit, 0x81: MILE, 0x80: KM.
BB: 12hour & 24hour display, 0x81: 12hour display, 0x80: 24hour display.
CC: Raise your wrist to brighten the screen, 0x81: enable, 0x80: disable.
DD: Temperature unit switch, 0x81: Fahrenheit, 0x80: Celsius, default setting is
  Celsius.
EE: Night mode, 0x81 on, 0x80: off.  (After night mode is turned on, from 6 pm
  to 7 am, the display brightness is automatically adjusted to the darkest.)
FF: ANCS enable switch, 0x81: enable, 0x80: close.
GG HH: ANCS information switch, totally 15 bytes. The highest byte is used to
  control if needed to set separate information switch. The detailed
  sub-switches are arranged as follows, the top one is the lowest byte:
  0. Incoming call (the lowest byte), 1. Mobile phone information, 2. WeChat,
  3. Facebook, 4. Instagram, 5. Skype, 6. telegram, 7. tweetie2, 8. Vkclient, 9,
  WhatsApp, 10. QQ, 11. LinkedIn
II: Basic heart rate setting, the highest byte is 1 and the value must exceed
  40.
JJ: Reserved byte.
KK: Screen brightness adjustment, adjustment range 80-8f (0 is the brightest, f
  is the darkest).
LL: Watch main interface replacement 80-84 (for model 1963-01); 80-85 (for model
  1963YH & 1963D)
MM: Social distance alert switch, 0x81 on; 0x80 off.
NN: Chinese and English language switching, 80 for English (default), 81 for
  Chinese.
 * Convert the device settings object into the byte sequence that represents it
 * in the "set device settings" command.  This does NOT include the initial
 * "command" byte, only the parameters, in other words, per the documentation
 * above, this is the AA BB CC DD ... etc part
 */
export function encodeParams(settings: DeviceSettings): Uint8Array {
  return Uint8Array.from([
    /* AA */ settings.distanceUnit === 'km' ? 0x80 : 0x81,
    /* BB */ settings.timeFormat === 24 ? 0x80 : 0x81,
    /* CC */ settings.raiseWristEnabled ? 0x81 : 0x80,
    /* DD */ settings.temperatureUnit === 'C' ? 0x80 : 0x81,
    /* EE */ settings.nightModeEnabled ? 0x81 : 0x80,
    /* FF */ 0x80,
    /* GG */ 0,
    /* HH */ 0,
    /* II */ settings.basicHeartRate,
    /* JJ */ 0,
    /* KK */ settings.screenDarkness,
    /* LL */ settings.watchFace,
    /* MM */ settings.socialDistanceAlertEnabled ? 0x81 : 0x80,
    /* NN */ 0x80
  ]);
}
