import * as React from 'react';
import { DispatchProps } from 'Store';
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom';

import * as Urls from 'Shared/Urls';
import Link from 'Shared/UI/Link';
import { AuthSettings } from 'Auth/Data';
import { ActionCreators } from 'Auth/ActionCreator';
import AuthPage from './AuthPage';
import ResetPasswordForm from 'Auth/UI/ResetPasswordForm';

type Props = DispatchProps & RouteComponentProps<URLParams>;

interface URLParams {
  code: string
  programSlug?: string
}

interface State {}

class ResetPassword extends React.Component<Props, State> {
  componentDidMount() {
    this.props.dispatch(ActionCreators.checkResetPasswordCode(this.code()));
  }

  submit = (password: string) => {
    return this.props.dispatch(
      ActionCreators.resetPassword(this.props.match.params.code, password)
    );
  }

  code(): string {
    return this.props.match.params.code;
  }

  render() {
    return <AuthPage contents={this.renderForm} />;
  }

  renderForm = (settings: AuthSettings) => {
    return (
      <div>
        <ResetPasswordForm onSubmit={this.submit} />
        <p className="auth__note">
          Don't need to reset your password?
          {' '}<Link to={Urls.loginUrl(settings.programSlug)}>Login</Link>
        </p>
      </div>
    );
  }
}

export default withRouter(
  connect(null, dispatch => ({ dispatch }))(ResetPassword)
);
