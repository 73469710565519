import * as React from 'react';
import { goalsUrl, goalUrl } from 'Shared/Urls';
import { plural } from 'Shared/Text';
import { ActiveGoal } from 'Goals';
import Tile from './Tile';
import SimpleTileContent from './SimpleTileContent';
import NoContent from './NoContent';

interface Props {
  activeGoal: ActiveGoal | undefined
  // Indicates if these are rendered as the users own goals, or looking at
  // someone elses
  isMe: boolean
  userId: string
}

const NO_GOALS =
  <NoContent iconName="bullseye-pointer">no goals to display</NoContent>;

export default function renderGoalsTile(
  props: Props
): React.ReactElement<Props> {
  const url = props.isMe ? goalsUrl() : goalsUrl(props.userId);
  return (
    <Tile title="goals" linkUrl={url} linkLabel="view goals">
      {renderGoal(props)}
    </Tile>
  );
}

function renderGoal(props: Props) {
  const { activeGoal, isMe } = props;
  if (activeGoal) {
    return (
      <SimpleTileContent
        title={activeGoal.goal.name}
        badge={plural('day', activeGoal.status.days)}
        url={isMe ? goalUrl(activeGoal.goal.id) : undefined}
        icon="bullseye-pointer"
        quickStat={plural('post', activeGoal.status.postCount)}
      />
    );
  } else {
    return NO_GOALS;
  }
}
