import * as React from 'react';
import AuthFormErrors from 'Auth/UI/AuthFormErrors';
import AuthFormSubmit from 'Auth/UI/AuthFormSubmit';
import AuthFormTextField from 'Auth/UI/AuthFormTextField';

interface Props {
  onSubmit: (password: string) => void
}

interface State {
  password: string,
  passwordConfirmation: string
  error?: string
}

class ResetPasswordForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { password: '', passwordConfirmation: '' };
  }

  onPasswordChange = (password: string) => {
    this.setState({ password });
  }

  onPasswordConfirmationChange = (passwordConfirmation: string) => {
    this.setState({ passwordConfirmation });
  }

  onSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();
    const messages = this.errorMessages();
    if (messages.length > 0) {
      this.setState({ error: messages.join('  ') });
    } else {
      this.setState({ error: undefined });
      this.props.onSubmit(this.state.password);
    }
  }

  errorMessages() {
    let messages = [];
    const pwd = this.state.password;
    const pwdConf = this.state.passwordConfirmation;
    if (pwd === '' || pwdConf === '') {
      messages.push('All fields are required.');
    }
    if (pwd.length < 8) {
      messages.push('Password must be at least 8 characters long.');
    }
    if (pwd !== pwdConf) {
      messages.push('Password confirmation does not match.');
    }
    return messages;
  }

  render() {
    return (
      <form className="auth-form" onSubmit={this.onSubmit}>
        <p className="auth__note">
          Enter a new password below
        </p>
        <AuthFormErrors error={this.state.error} />
        <AuthFormTextField
          type="password"
          icon="lock"
          placeholder="New Password"
          value={this.state.password}
          onChange={this.onPasswordChange}
        />
        <AuthFormTextField
          type="password"
          icon="lock"
          placeholder="Re-enter your password"
          value={this.state.passwordConfirmation}
          onChange={this.onPasswordConfirmationChange}
        />
        <AuthFormSubmit>Submit</AuthFormSubmit>
      </form>
    );
  }
}

export default ResetPasswordForm;
